/* eslint-disable no-unused-expressions */
<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    persistent
  >
    <v-card>
      <v-card-title>
        <v-col v-if="inventoryTag != null">
          <h2>
            Inventory Moving
          </h2>
        </v-col>
        <v-col class="text-right">
          <v-btn
            icon
            @click="close"
          >
            <v-icon
              large
              dark
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-container fluid>
        <v-container
          v-if="inventoryTag != null"
          style="font-size: 1.3em !important;"
          fluid
        >
          <v-row
            class="pa-0"
          >
            <v-col
              class="pa-0"
              cols="1"
              md="1"
              sm="2"
              style="text-align:right"
            >
              <v-row>
                <v-col class="text-center">
                  <h5 class="h5">
                    QR Code
                  </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <h6>
                    Inventory Class
                  </h6> <span style="font-size: 1.5em;"> {{ inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.name : '-' }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="ml-3 mb-5 text-center">
                  <h6 class="mb-3">
                    Supplier Label
                  </h6>
                  <a

                    @click="openItemShowImageForm(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
                  >
                    <v-img
                      :src="convertedPhoto(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
                      class="profile-photo-mini ml-3"
                    />
                  </a>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="pa-0"
              cols="1"
              md="1"
              sm="2"
              style="text-align:center"
            >
              <div
                style="border-left: 1px solid;
            height: 90%; margin-left:30px;"
              />
            </v-col>
            <v-col
              class="pa-0"
              cols="3"
              md="3"
              sm="8"
            >
              <v-img
                :src="convertedPhoto(photo, mime_type)"
                contain
                height="100%"
                width="100%"
                class="profile-photo"
              />
            </v-col>
            <v-col
              class="pa-0"
            >
              <v-row>
                <v-col
                  cols="3"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Item Material Id
                  </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.item.material_id : '' }}</span>
                </v-col>
                <v-col
                  cols="3"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Inventory Item
                  </h5>
                  <span class="value"> {{ inventoryTag != null ? inventoryTag.item.name : '' }}
                  </span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass)"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Length
                  </h5>
                  <span class="value"> {{ inventoryTag != null ? inventoryTag.length.name : '' }}</span>
                </v-col>
                <v-col
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    UOM
                  </h5>
                  <span class="value"> {{ inventoryTag != null ? inventoryTag.uom.name : '' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass)"
                  cols="3"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Colour
                  </h5>
                  <span class="value"> {{ inventoryTag != null ? inventoryTag.color.code : '' }}</span>
                </v-col>
                <v-col
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    PO#
                  </h5>
                  <span class="value"> {{ inventoryTag ? inventoryTag.po_number : '-' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Vendor Batch#
                  </h5>
                  <span class="value"> {{ inventoryTag ? inventoryTag.batch_number : '-' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
                  cols="2"
                  class="text-center"
                >
                  <h5 class="h5">
                    Production Date
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.production_date_simple : '' }}</span>
                </v-col>
                <v-col
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Received Qty
                  </h5>
                  <span class="value"> {{ inventoryTag != null ? inventoryTag.quantity : '' }}</span>
                </v-col>
                <v-col
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Qty on Hand
                  </h5> <span class="value"> {{ inventoryTag != null ? (inventoryTag.quantity_on_hand ? inventoryTag.quantity_on_hand : 0) : '' }}</span>
                </v-col>
                <v-col
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Qty Available
                  </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.quantity_available : 0 }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-end">
                  <v-btn
                    color="primary"
                    class="mr-4"
                    x-large
                    @click="historyRedirect"
                  >
                    History
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-row>
                <v-col
                  class="mt-3"
                >
                  <h3>Moving from: </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0 pt-0">
                  <span class="font-weight-bold">
                    Location
                  </span>
                  <span class="value"> {{ inventoryTag != null ? inventoryTag.location.name : '' }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <span class="font-weight-bold">
                    Shelf
                  </span>
                  <span class="value"> {{ inventoryTag != null ? inventoryTag.inventory_location.name : '' }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="mt-3">
                  <h3>Moving to: </h3>
                </v-col>
              </v-row>
              <v-form ref="form">
                <v-row>
                  <v-col class="mt-2">
                    <v-row>
                      <v-row>
                        <v-col
                          cols="3"
                          md="3"
                          sm="6"
                          class="pb-0"
                          style="text-align:center"
                        >
                          <h5 class="h5">
                            Moving Quantity<RedAsterisk />
                          </h5>
                          <v-text-field
                            v-model="quantity"
                            :rules="[(v) => !!v || 'Moving Quantity is required']"
                            required
                            outlined
                            single-line
                            type="number"
                          >
                            <template #label>
                              Enter Moving Quantity
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <span
                          v-if="scannedQrCode"
                        ><span class="font-weight-bold">IMPORTANT!</span> QR Code <span class="font-weight-bold">{{ scannedQrCode }} </span>will be used for Inventory Movement</span>
                        <span
                          v-else
                          class="red--text"
                        >{{ errorMessage }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-btn
                          :disabled="!isScanQrMoveActive"
                          color="primary"
                          x-large
                          class="mr-4"
                          @click="scanQrCode"
                        >
                          scan qr
                        </v-btn>
                        <v-btn
                          :disabled="!isSubmitBtnActive"
                          color="primary"
                          x-large
                          class="mr-4"
                          @click="submit"
                        >
                          submit
                        </v-btn>
                        <v-btn
                          color="primary"
                          class="mr-4"
                          outlined
                          x-large
                          @click="close"
                        >
                          cancel
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <h2 v-if="isActivatedVisible">
          <v-row>
            <v-col>
              This QR Code does not exist in AWD ERP. Please check one more time.
            </v-col>
          </v-row>
        </h2>
        <v-dialog
          v-model="qrCodeDialog"
          fullscreen
          hide-overlay
          persistent
        >
          <v-card>
            <v-card-title>
              <v-col class="text-right">
                <v-btn
                  icon
                  @click="qrCodeDialog = false"
                >
                  <v-icon
                    large
                    dark
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-card-title>
            <v-container fluid>
              <inventory-qr
                :is-moving-qr-partially="true"
                @return-qr-code-move="returnQrCode"
              />
            </v-container>
          </v-card>
        </v-dialog>

        <InventoryItemShowImageForm
          ref="itemShowImageForm"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import { mapState } from 'vuex';

export default {
  components: {
    InventoryItemShowImageForm,
    InventoryQr,
  },
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    quantity: 0,
    photo: null,
    mime_type: null,
    inventoryLocation: null,
    location: null,
    inventoryItem: null,
    inventoryLocations: [],
    scannedQrCode: null,

    onTruckLocationId: 9,
    errorMessage: '',
    isSubmitBtnActive: true,
    isScanQrMoveActive: false,
    isActivatedVisible: false,
    qrCodeDialog: false,
    isCreateNewInventoryTag: false,
    isMovingAll: 0,
    dialog: false,
    batch: null,
    existingItem: null,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
  }),
  computed: {
    ...mapState({
      user: 'user',
    }),
  },
  watch: {
    quantity(value) {
      const qtyOnHand = parseInt(this.inventoryTag.quantity_on_hand, 10);
      const quantity = parseInt(value, 10);

      this.isSubmitBtnActive = true;
      this.isMovingAll = 1;
      this.errorMessage = '';
      this.isScanQrMoveActive = false;
      this.scannedQrCode = null;
      this.isCreateNewInventoryTag = false;
      if (qtyOnHand < 0) {
        this.errorMessage = 'ERROR! Moving Quantity On Hand must be greater than 0';
        this.isSubmitBtnActive = false;
      } else if (quantity < 0) {
        this.errorMessage = 'ERROR! Moving Quantity must be greater than 0';
        this.isSubmitBtnActive = false;
      // eslint-disable-next-line eqeqeq
      } else if (quantity == 0) {
        this.errorMessage = 'ERROR! Moving Quantity must be greater than 0';
        this.isSubmitBtnActive = false;
      } else if (quantity > qtyOnHand) {
        this.errorMessage = 'ERROR! Moving Quantity cannot be greater than Quantity On Hand';
        this.isSubmitBtnActive = false;

      // If moving partially, then scan a qr code
      } else if (quantity < qtyOnHand) {
        this.errorMessage = 'IMPORTANT! You\'re moving an incomplete bundle. Please SCAN QR using button below to activate new code.';
        this.isScanQrMoveActive = true;
        this.isSubmitBtnActive = false;
      }
    },
  },

  methods: {

    // QR CODE IS THE SAME AS INVENTORY TAG
    async getInventoryTag() {
      if (!this.qrCode) return;
      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data || !res.data[0]) {
        this.isActivatedVisible = true;
        this.inventoryTag = null;
        return;
      }
      const data = res.data[0];
      this.isActivatedVisible = false;
      this.inventoryTag = data;
      this.photo = data.item && data.item.photo ? data.item.photo : null;
      this.mime_type = data.item && data.item.mime_type ? data.item.mime_type : null;

      if (this.existingItem) {
        this.quantity = this.existingItem.quantity;

        this.$nextTick(async () => {
          if (this.existingItem && this.existingItem.inventory_tag !== this.existingItem.moving_to_tag) {
            this.scannedQrCode = this.existingItem.moving_to_tag;
            this.isSubmitBtnActive = true;
          }
        });
      } else {
        this.quantity = data.quantity_on_hand;
      }
    },

    async submit() {
      if (!await this.$refs.form.validate()) return;
      if (!this.batch) return;

      this.$bus.$emit('loading');

      // If moving partially and new Qr Code being used, else use the old QR Code
      const qrCode = this.scannedQrCode ? this.scannedQrCode : this.qrCode;
      const existingItemId = this.existingItem && this.existingItem.id ? this.existingItem.id : 0;
      const submitData = {
        id: existingItemId,
        inventory_movement_batch_id: this.batch.id,
        inventory_tag: this.qrCode,
        quantity: this.quantity,
        moving_to_tag: qrCode,
        created_by: this.user.id,
      };

      let res;
      if (existingItemId) {
        res = await this.$api.tagToMovementbatch.update(submitData);
      } else {
        res = await this.$api.tagToMovementbatch.store(submitData);
      }
      this.$bus.$emit('loading-stop');
      if (!res) {
        this.$bus.$emit('showError', 'QR Code can\'t be added while the same QR Code in a Batch with status \'In Progress\'');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('item-success');
      this.close();
    },

    async open(code, location, batch, item = null) {
      this.qrCode = code;
      this.location = location;
      this.batch = batch;
      this.existingItem = item;

      if (!item) {
        const submitModel = {
          filters: {
            inventory_tag: code,
            batch_id: batch.id,
          },
        };
        const checkBatch = await this.$api.tagToMovementbatch.read(submitModel);
        if (checkBatch && checkBatch.length > 0) {
          this.$bus.$emit('showError', 'This QR Code is already in the Batch list');
          return;
        }
      }

      this.getInventoryTag();
      this.dialog = true;
    },

    close() {
      this.existingItem = null;
      this.dialog = false;
    },

    scanQrCode() {
      this.qrCodeDialog = true;
    },

    // Can be two options if moving partially:
    // merge moving tag into existing one (parameters must be the same)
    // or create a new tag
    async returnQrCode(qrCode) {
      if (!qrCode) return;
      this.qrCodeDialog = false;
      this.scannedQrCode = null;
      this.isSubmitBtnActive = false;
      this.isCreateNewInventoryTag = false;
      // Get Scanned qr code if moving partially
      const submitModel = {
        filters: {
          inventory_tag: qrCode,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);

      // If scanned tag exist
      if (res && res.data && res.data[0]) {
        if (this.location && this.location.id === this.onTruckLocationId) {
          this.isSubmitBtnActive = false;
          this.errorMessage = 'ERROR! Existing Qr Code can\'t be used for Moving to ON TRUCK';
        } else {
          this.isSubmitBtnActive = true;
          this.scannedQrCode = qrCode;
        }
      // else scanned tag is missing
      } else {
        this.isCreateNewInventoryTag = true;
        this.isScanQrMoveActive = false;
        this.isSubmitBtnActive = true;
        this.scannedQrCode = qrCode;
      }

      this.isMovingAll = 0;
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    historyRedirect() {
      const transactionsTabIndex = 2;
      const transactionsTabId = 3;
      this.$store.commit('storeInventoryTab', transactionsTabIndex);
      this.$store.commit('storeInventoryTabId', transactionsTabId);
      this.$router.push({
        name: 'Inventory',
        params: {
          searchParam: `qr=${this.qrCode}`,
        },
      });
    },
  },
};
</script>
<style scoped>
.profile-photo-mini{
  max-width: 80px;

}
.profile-photo{

  max-height: 400px;
  max-width: 400px;

}
.v-text-field >>> input {
    font-size: 1.5em !important;
}
.v-btn {
    font-size: 1.1em !important;
}

</style>
