<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        class="text-end mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Time Off Leave Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.name`]="{ item }">
              <v-chip
                v-if="item.color"
                small
                :color="item.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.name }}
              </v-chip>
            </template>
            <template #[`item.created_at`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.created_at }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.created_by }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEdit(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <TimeOffLeaveTypeForm
      ref="itemForm"
      @success="getLeaveTypes"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import TimeOffLeaveTypeForm from '@/components/forms/timeOffs/TimeOffLeaveTypeForm';
import { mapState } from 'vuex';

export default {
  components: {
    TimeOffLeaveTypeForm,
  },
  data: () => ({
    items: [],
    inventoryClassId: null,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    loading: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getLeaveTypes();
    },

    async getLeaveTypes() {
      this.loading = true;
      const submitModel = {
        filters: {
        },
      };
      this.items = await this.$api.timeOffLeaveType.read(submitModel);
      this.loading = false;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.timeOffLeaveType.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.itemForm.open();
    },

    openEdit(id) {
      this.$refs.itemForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },
  },
};
</script>
