<template>
  <the-dialog-box
    ref="dialogBox"
    :submit-btn-text="'Submit'"
    :is-submit-btn="false"
  >
    <template slot="header">
      <span>Select Packing Slip Number</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-list
            class="pt-0 pb-0"
          >
            <template v-for="(packaging_slip, index) in items">
              <v-list-item
                :key="index"
                class="mb-2"
                style="border: 1px solid rgba(0,0,0,0.6); border-radius: 4px; cursor: pointer;"
                @click="submit(packaging_slip)"
              >
                <v-list-item-content>
                  <span style="font-weight: bold; font-size: 16px;">{{ packaging_slip }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              />
            </template>
          </v-list>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    items: [],
    inventoryPoId: null,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
  },
  methods: {

    async submit(packagingSlip) {
      this.$bus.$emit('loading');
      const webhookModuleIdInventoryPoReceivingSlip = 40;
      const packagingSLipNumber = packagingSlip === 'All' ? '' : packagingSlip;
      const webhookData = {
        module_id: webhookModuleIdInventoryPoReceivingSlip,
        id: this.inventoryPoId,
        packaging_slip: packagingSLipNumber,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${packagingSlip}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.$refs.dialogBox.close();
    },

    async open(id) {
      this.items = [];
      const res = await this.$api.inventoryPo.get(`packaging-slips/${id}`);
      if (!res || !res.length) {
        this.$bus.$emit('showError', 'No Packing Slips found for this Inventory PO');
        return;
      }
      this.items = res;
      this.items.push('All');
      this.inventoryPoId = id;
      this.$refs.dialogBox.open();
    },
  },
};
</script>
