var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"is-submit-btn":false,"full-screen":true}},[_c('template',{slot:"header"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v("PO# - "+_vm._s(_vm.poNumber))])]),_c('v-spacer'),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pr-2 pt-4"},[_c('span',{style:({color: _vm.isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'})},[_vm._v("Decimal")])]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":"","label":"Fraction"},model:{value:(_vm.uomSwitch),callback:function ($$v) {_vm.uomSwitch=$$v},expression:"uomSwitch"}})],1)],1)],1)],1)],1),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.glassHeaders,"items":_vm.glassItems,"hide-default-footer":"","loading":_vm.loading,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.inventory_class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.job",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_code ? ("" + (item.job_code)) : ''))])],1),_vm._v(" - "),_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_name ? item.job_name : item.job_name))]),_vm._v(" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_reference ? ("; " + (item.job_reference)) : ''))])],1)],1)]}},{key:"item.glass_thickness",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.glass_thickness ? item.glass_thickness : '-')+" ")])]}},{key:"item.po_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.po_number ? item.po_number : '')+" ")])],1)]}},{key:"item.batch_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_c('text-highlight',{staticStyle:{"font-size":"14px !important"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.batch_number ? item.batch_number : '')+" ")])],1)]}},{key:"item.job_code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.job_code ? item.job_code : '-')+" ")])]}},{key:"item.g_code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.g_code ? item.g_code : '-')+" ")])]}},{key:"item.packaging_slip",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.packaging_slip ? item.packaging_slip : '-')+" ")])]}},{key:"item.tag_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.tag_number ? item.tag_number : '-')+" ")])]}},{key:"item.width",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.width ? (_vm.uomSwitch ? _vm.convertToFraction(item.width) : item.width) : '-')+" ")])]}},{key:"item.height",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.height ? (_vm.uomSwitch ? _vm.convertToFraction(item.height) : item.height) : '-')+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.quantity ? item.quantity : '-')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"width":"100%","max-width":"140px","white-space":"nowrap"},attrs:{"small":"","color":item && item.status ? item.status.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.status ? item.status.name : '')+" ")])]}},{key:"item.item_index",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.item_index ? item.item_index : '-')+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? _vm.convertDateFormat(item.updated_at) : '')+" ")]}}],null,true)})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }