<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <span style="font-size: 20px; font-weight: 500;">{{ title }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-if="isAddBtn"
                class="text-end"
              >
                <v-btn
                  color="primary"
                  outlined
                  @click="isAddBtn = false"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >
                    fas fa-plus
                  </v-icon>
                  New Attachments
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="pb-0">
                <v-file-input
                  v-model="attachments"
                  capture="user"
                  outlined
                  small-chips
                  multiple
                >
                  <template slot="label">
                    Attachments<RedAsterisk />
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="text-end pt-0 pb-0">
                <v-btn
                  color="normal"
                  class="mr-2"
                  @click="isAddBtn = true"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="submit"
                >
                  SUBMIT
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="pb-0 pt-0 ">
                <zig-zag />
              </v-col>
            </v-row>
            <v-row v-if="items.length > 0">
              <v-col>
                <v-row>
                  <v-col>
                    <v-card-text style="max-height: 500px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
                      <v-list
                        two-line
                        class="pt-0 pb-0"
                      >
                        <div
                          v-for="(file, index) in items"
                          :key="file.id + '_' + index"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              {{ file.file_name }}
                              <v-list-item-subtitle style="padding-top: 10px;">
                                {{ file.created_at }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{ `${file.user.first_name ? file.user.first_name : ''} ${file.user.last_name ? file.user.last_name : ''}` }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle class="text-end">
                                <v-btn
                                  color="purple"
                                  class="mr-2"
                                  outlined
                                  small
                                  @click="download(file)"
                                >
                                  <v-icon
                                    small
                                    class="mr-1"
                                  >
                                    fas fa-download
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  color="error"
                                  outlined
                                  small
                                  @click="softDelete(file)"
                                >
                                  <v-icon
                                    small
                                    class="mr-1"
                                  >
                                    fas fa-trash
                                  </v-icon>
                                </v-btn>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            v-if="index < items.length - 1"
                          />
                        </div>
                      </v-list>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm-form ref="confirmForm" />
  </v-row>
</template>

<script>
import { saveAs } from 'file-saver';
import ZigZag from '@/components/elements/ZigZag';

export default {
  components: {
    ZigZag,
  },
  props: {
    title: {
      type: String,
      default: 'Attachments',
    },
  },
  data: () => ({
    item: null,
    items: [],
    attachments: [],
    itemDto: {
      id: 0,
      time_off_requests_id: null,
    },
    dialog: false,
    isAddBtn: true,
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get() {
      const submitModel = {
        filters: {
          record_id: this.item.record_id,
          type_id: this.item.type_id,
        },
      };
      const res = await this.$api.attachment.read(submitModel);
      if (!res) return;
      this.items = res;
    },

    async submit() {
      if (!this.$refs.form.validate()) return;

      if (this.attachments.length <= 0) return;
      const submitModel = {
        record_id: this.item.record_id,
        type_id: this.item.type_id,
      };
      for (let i = 0; i < this.attachments.length; i++) {
        const formData = new FormData();
        formData.append('file', this.attachments[i]);
        formData.append('record_id', submitModel.record_id);
        formData.append('type_id', submitModel.type_id);
        // eslint-disable-next-line no-await-in-loop
        const res = await this.$api.attachment.store(formData);
        if (!res) {
          this.$bus.$emit('showError');
          return;
        }
      }

      this.$bus.$emit('showSuccess');
      this.get();
      this.isAddBtn = true;
      this.item.text = '';
    },

    async download(file) {
      const res = await this.$api.attachment.get(`download/${file.id}`, {
        responseType: 'blob',
      });
      const downloadedFile = new Blob([res]);
      saveAs(downloadedFile, file.file_name);
    },

    async softDelete(data) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this attachment?',
      });
      if (!confirm) return;
      const res = await this.$api.attachment.softDelete(data.id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open(recordId, typeId) {
      this.item = { ...this.itemDto };
      this.isAddBtn = true;
      this.item.record_id = recordId;
      this.item.type_id = typeId;
      this.items = [];
      this.attachments = [];
      this.get();
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>
