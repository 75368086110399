import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
class OrderAcknowledgmentApi extends Api {
  constructor() {
    super('order-acknowledgments', 'order-acknowledgments');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const orderAcknowledgmentApi = new OrderAcknowledgmentApi();

export default orderAcknowledgmentApi;
