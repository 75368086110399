import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;

class FileParserApi extends Api {
  constructor() {
    super('file-parsers', 'file-parsers');
  }

  async readCancel(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const fileParserApi = new FileParserApi();

export default fileParserApi;
