<template>
  <div>
    <span class="ml-2">{{ label }}<RedAsterisk v-if="required" />
      <slot name="append-label" />
    </span>
    <v-row>
      <v-col class="pb-0">
        <v-text-field
          v-if="!mobile"
          v-model="hour"
          :disabled="disabled"
          type="number"
          outlined
          dense
          max="12"
          min="1"
          @input="emitTime"
          :background-color="backgroundColor"
          :rules="required ? [rules.required] : [rules.none]"
        >
          hour<RedAsterisk v-if="required" />
        </v-text-field>
        <v-select
          v-else
          v-model="hour"
          :disabled="disabled"
          :items="hours"
          outlined
          dense
          label="hour"
          @input="emitTime"
          :background-color="backgroundColor"
          :rules="required ? [rules.required] : [rules.none]"
        >
          hour<RedAsterisk v-if="required" />
        </v-select>
      </v-col>
      <v-col class="pb-0">
        <v-text-field
          v-if="!mobile"
          v-model="minute"
          :disabled="disabled"
          type="number"
          dense
          label="minute"
          max="59"
          min="0"
          outlined
          @input="emitTime"
          :background-color="backgroundColor"
          :rules="required ? [rules.required] : [rules.none]"
        >
          minute<RedAsterisk v-if="required" />
        </v-text-field>
        <v-select
          v-else
          v-model="minute"
          :disabled="disabled"
          :items="minutes"
          outlined
          dense
          label="minute"
          @input="emitTime"
          :background-color="backgroundColor"
          :rules="required ? [rules.required] : [rules.none]"
        >
          minute<RedAsterisk v-if="required" />
        </v-select>
      </v-col>
      <v-col class="pb-0">
        <v-select
          v-model="type"
          :disabled="disabled"
          :items="types"
          outlined
          dense
          @input="emitTime"
          :background-color="backgroundColor"
          :rules="required ? [rules.required] : [rules.none]"
        />
      </v-col>
    </v-row>
    <span class="hint">{{ hint }}</span>
  </div>
</template>

<script>
import { min } from 'moment';

export default {
  components: {},

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    inputTime: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    time: '',
    hour: 0,
    minute: 0,
    type: 'AM',
    types: ['AM', 'PM'],
    hours: Array.from(Array(13).keys()),
    // minutes: ['00',...Array.from(Array(59).keys(), x => x + 1)],
    minutes: Array.from(Array(60).keys()),
  }),
  computed: {
    selectedTime() {
      return `${this.hour}:${this.minute} ${this.type}`;
    },
    selectedTime24HourFormat() {
      if (this.hour !== null && this.minute !== null && this.type !== null) {
        let hour = parseInt(this.hour, 10);
        const minute = parseInt(this.minute, 10);

        // Adjust hour based on AM/PM
        if (this.type.toLowerCase() === 'pm' && hour !== 12) {
          hour += 12;
        } else if (this.type.toLowerCase() === 'am' && hour === 12) {
          hour = 0;
        }

        // Format hour and minute to have leading zeros if needed
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');

        return `${formattedHour}:${formattedMinute}:00`;
      }
      return null;
    },
    rules() {
      return {
        required: (value) => value !== null && value !== undefined && value !== '' || 'Required.',
        none: (value) => true
      };
    },
  },
  watch: {
    inputTime(newTime) {
      if (newTime) {
        this.parseInputTime();
      }
    },
  },
  mounted() {
    if (this.inputTime) {
      this.parseInputTime();
    }
  },
  methods: {
    fixMinutes() {
      if (!this.minute) this.minute = '00';
      else if (this.minute > 59) this.minute = '59';
      else if (this.minute.length === 1) this.minute = `0${this.minute}`;
      this.updateTime();
    },
    fixHours() {
      if (!this.hour || this.hour === '0' || this.hour > 23) {
        this.hour = '12';
        this.type = 'AM';
      } else if (this.hour > 12) {
        this.hour %= 12;
        this.type = 'PM';
      } else if (this.hour <= 12) this.type = 'AM';

      this.updateTime();
    },
    updateTime() {
      this.$emit('timeChanged', this.getTime());
    },
    getTime() {
      return this.selectedTime;
    },
    getTime24HourFormat() {
      return this.selectedTime24HourFormat;
    },
    emitTime() {
      this.$emit('time-selected', this.selectedTime24HourFormat);
    },
    parseInputTime() {
      const [hour, minute, second] = this.inputTime.split(':');
      this.hour = parseInt(hour, 10) % 12 || 12; // Convert to 12-hour format
      this.minute = parseInt(minute, 10);
      this.type = parseInt(hour, 10) >= 12 ? 'PM' : 'AM';
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.hint {
  position: relative;
  top: -15px;
  font-size: .8rem;
}
</style>
