import axios from 'axios';

export const login = ({ commit }, data) => new Promise((resolve, reject) => {
  commit('auth_request');
  axios({
    url: '/users/authenticate',
    data,
    method: 'POST',
  })
    .then((resp) => {
      const { user } = resp.data;
      const { token } = resp.data;
      axios.defaults.headers.common.Authorization = token;
      commit('auth_success', { user, token });
      resolve(resp);
    })
    .catch((err) => {
      localStorage.removeItem('token');
      reject(err);
    });
});
// export const setClientEnv = ({ commit }, clientEnv) => {
//   return new Promise((resolve) => {
//     commit('set_client_env', clientEnv);
//     resolve();
//   });
// };

export const logout = ({ commit }) => new Promise((resolve) => {
  commit('logout');
  localStorage.removeItem('token');
  delete axios.defaults.headers.common.Authorization;
  resolve();
});

export default {
  login,
  logout,
};
