var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"submit-btn-text":"Submit","full-screen":true,"warning-text":_vm.warningText,"is-submit-btn":_vm.isSubmitBtn,"persistent":true}},[_c('template',{slot:"header"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-6 pb-0",attrs:{"cols":"auto"}},[_c('span',[_vm._v("Imported Glass Items")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is4500":false,"is4800":false,"is-inst":false,"is-glass":false,"is-panels":false,"is-sid":false,"is-add-all":false,"is-rail-part":false,"is-railings":_vm.inventoryClassId !== _vm.classVWDGlass,"is-commercial":_vm.inventoryClassId !== _vm.classVWDGlass,"is-vwd-glass":_vm.inventoryClassId !== _vm.classCommercialId && _vm.inventoryClassId !== _vm.classRailingsId,"is-parts":false,"is-required":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),(_vm.inventoryClassId === _vm.classCommercialId || _vm.inventoryClassId === _vm.classRailingsId)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Supplier","items":_vm.suppliers,"rules":[function (v) { return !!v || 'Supplier is required'; }],"item-text":function (item) { return ((item.code) + " " + (item.name)); },"item-value":"id","clearable":"","single-select":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.code))]),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.code))]),_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,2187861628),model:{value:(_vm.supplierId),callback:function ($$v) {_vm.supplierId=$$v},expression:"supplierId"}},[_c('template',{slot:"label"},[_vm._v(" Supplier"),_c('RedAsterisk')],1)],2)],1):_vm._e()],1)],1),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":-1,"dense":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.g_code",fn:function(ref){
var item = ref.item;
return [(item.g_code)?_c('span',{staticStyle:{"color":"green","font-weight":"bold"}},[_vm._v(_vm._s(item.g_code))]):_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error","dark":""}},on),[_vm._v(" fas fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v("This Glass Code is not in the System!")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('v-chip',{attrs:{"small":"","color":item.status ? item.status.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(item.status ? item.status.name : '')+" ")]):_c('v-chip',{attrs:{"small":"","color":"success","text-color":"white"}},[_vm._v(" READY FOR IMPORT ")])]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-end pr-10"},[_c('span',{staticStyle:{"font-weight":"bold","color":"black","font-size":"18px"}},[_vm._v("Total Records: "+_vm._s(_vm.items.length))])])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }