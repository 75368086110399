<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Client</span>
    </template>
    <template slot="body">
      <!-- <v-row>
        <v-col
          cols="6"
          class="pb-0"
        >
          <inventory-class-select-element
            v-model="item.inventory_class_id"
            :is-add-all="false"
            :is-required="true"
          />
        </v-col>
      </v-row> -->
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-text-field
            v-model="item.name"
            label="Name"
            :rules="[(v) => !!v || `Client Name is required`, (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
          >
            <template slot="label">
              Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.phone_number"
            label="Phone Number"
            :rules="[(v) => (v === null || v.length <= 20) || 'Max 20 characters']"
            counter="20"
            @blur="formatPhoneNumber"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  data: () => ({
    item: null,
    itemId: 0,
    itemDto: {
      id: 0,
      name: '',
      // inventory_class_id: null,
      phone_number: '',
      created_by: 0,
    },
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.client.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.client.update(this.item);
      } else {
        const userId = this.$store.getters.user.id;
        if (userId) this.item.created_by = userId;
        res = await this.$api.client.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },

    formatPhoneNumber() {
      this.item.phone_number = FormatHelpers.formatPhoneNumber(this.item.phone_number);
    },
  },
};
</script>
