<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
    :full-screen="true"
  >
    <template slot="header">
      <v-row class="align-center">
        <v-col cols="auto">
          <span>PO# - {{ poNumber }}</span>
        </v-col>
        <v-spacer />
        <v-col
          cols="auto"
          class="pb-0 pt-0"
        >
          <v-row class="align-center">
            <v-col class="pr-2 pt-4">
              <span :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}">Decimal</span>
            </v-col>
            <v-col
              class="pl-0"
              cols="auto"
            >
              <v-switch
                v-model="uomSwitch"
                hide-details
                label="Fraction"
                class="mt-0"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="glassHeaders"
              :items="glassItems"
              hide-default-footer
              :loading="loading"
              :items-per-page="-1"
              dense
            >
              <template #[`item.inventory_class`]="{ item }">
                <v-chip
                  small
                  :color="item && item.class ? item.class.color : 'black'"
                  text-color="white"
                >
                  {{ item && item.class ? item.class.name : '' }}
                </v-chip>
              </template>
              <template #[`item.job`]="{ item }">
                <span>
                  <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                </span>
              </template>
              <template #[`item.glass_thickness`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.glass_thickness ? item.glass_thickness : '-' }}
                </text-highlight>
              </template>
              <template #[`item.po_number`]="{ item }">
                <span class="font-weight-bold">
                  <text-highlight :queries="search">
                    {{ item.po_number ? item.po_number : '' }}
                  </text-highlight>
                </span>
              </template>
              <template #[`item.batch_number`]="{ item }">
                <span
                  class="font-weight-bold"
                >
                  <text-highlight
                    style="font-size: 14px !important;"
                    :queries="search"
                  >

                    {{ item.batch_number ? item.batch_number : '' }}
                  </text-highlight></span>
              </template>
              <template #[`item.job_code`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.job_code ? item.job_code : '-' }}
                </text-highlight>
              </template>
              <template #[`item.g_code`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.g_code ? item.g_code : '-' }}
                </text-highlight>
              </template>
              <template #[`item.packaging_slip`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.packaging_slip ? item.packaging_slip : '-' }}
                </text-highlight>
              </template>
              <template #[`item.tag_number`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.tag_number ? item.tag_number : '-' }}
                </text-highlight>
              </template>
              <template #[`item.width`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.width ? (uomSwitch ? convertToFraction(item.width) : item.width) : '-' }}
                </text-highlight>
              </template>
              <template #[`item.height`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.height ? (uomSwitch ? convertToFraction(item.height) : item.height) : '-' }}
                </text-highlight>
              </template>
              <template #[`item.quantity`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.quantity ? item.quantity : '-' }}
                </text-highlight>
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  small
                  style="width: 100%; max-width: 140px; white-space: nowrap; "
                  :color="item && item.status ? item.status.color : 'black'"
                  text-color="white"
                >
                  {{ item && item.status ? item.status.name : '' }}
                </v-chip>
              </template>
              <template #[`item.item_index`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.item_index ? item.item_index : '-' }}
                </text-highlight>
              </template>
              <template #[`item.updated_at`]="{ item }">
                {{ item ? convertDateFormat(item.updated_at) : '' }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
  },
  data: () => ({
    glassItems: [],
    glassHeaders: [
      {
        text: 'Class', value: 'inventory_class', align: 'center', width: '5%',
      },
      { text: 'Job', value: 'job', width: '20%' },
      { text: 'PO#', value: 'po_number', width: '7%' },
      { text: 'Batch', value: 'batch_number', width: '5%' },
      { text: 'G Code', value: 'g_code', width: '5%' },
      { text: 'Pack. Slip#', value: 'packaging_slip', width: '5%' },
      { text: 'Glass Thickness', value: 'glass_thickness', width: '10%' },
      { text: 'Tag#', value: 'tag_number', width: '5%' },
      { text: 'Width', value: 'width', width: '7%' },
      { text: 'Height', value: 'height', width: '7%' },
      { text: 'Qty', value: 'quantity', width: '3%' },
      {
        text: 'Status', value: 'status', width: '10%', align: 'center',
      },
      {
        text: 'Line#', value: 'item_index', width: '3%',
      },
      {
        text: 'Updated At', value: 'updated_at', width: '10%', align: 'end',
      },
    ],
    footer: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    poNumber: '',
    loading: false,
    uomSwitch: false,
    total: 0,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
      glassItemsState: (state) => state.glassItemsState,
    }),
  },
  methods: {
    init() {
    },

    async getGlass(poNumber, statusIds) {
      this.loading = true;
      this.glassItems = [];
      this.total = 0;
      const submitModel = {
        filters: {
          po_number: poNumber,
          status_ids: statusIds,
        },
      };
      const res = await this.$api.glass.readGlass(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.glassItems = [];
        this.total = 0;
        return;
      }
      this.glassItems = res.data;
      this.total = res.total;
    },

    open(poNumber, statusId) {
      this.poNumber = poNumber;
      this.getGlass(poNumber, statusId);
      this.$refs.dialogBox.open();
    },

    convertToFraction(decimal) {
      const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
      };

      // If the decimal is an exact match to any fraction, return the fraction directly
      if (decimal in fractions) {
        return fractions[decimal];
      }

      const wholeNumber = Math.floor(decimal);
      // eslint-disable-next-line no-param-reassign
      decimal -= wholeNumber; // Remove the whole number part

      let closestFraction = Object.keys(fractions)[0];

      // eslint-disable-next-line no-restricted-syntax
      for (const fraction of Object.keys(fractions)) {
        if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
          closestFraction = fraction;
        }
      }

      if (wholeNumber > 0) {
        return `${wholeNumber} - ${fractions[closestFraction]}"`;
      }
      return fractions[closestFraction];
    },

    convertDateFormat(inputDate) {
      // Parse the input date
      const date = new Date(inputDate);

      // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      return formattedDate;
    },

  },
};
</script>

  <style scoped>
  </style>
