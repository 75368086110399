var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-5"},[_c('h3',[_vm._v("Truck Types")])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openTruckTypeForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Truck Type ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.truckTypeHeaders,"items":_vm.truckTypes,"footer-props":_vm.truckTypeFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.truckTypeLoading,"dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.weight_limit",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.weight_limit ? item.weight_limit.toLocaleString() : '-')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEditTruckTypeForm(item.id)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteTruckType(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-10"},[_c('h3',[_vm._v("Trucks")])]),_c('v-col',{staticClass:"text-end mt-8"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openTruckForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Truck ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.truckHeaders,"items":_vm.trucks,"footer-props":_vm.truckFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.truckLoading,"dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.truck_type",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.truck_type)+" ")])]}},{key:"item.type_weight_limit",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.type_weight_limit ? item.type_weight_limit.toLocaleString() : '-')+" ")])]}},{key:"item.plate_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.plate_number)+" ")])]}},{key:"item.truck_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.truck_number)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEditTruckForm(item.id)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteTruck(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('truck-schedule-truck-form',{ref:"truckForm",on:{"success":_vm.getTrucks}}),_c('truck-schedule-truck-type-form',{ref:"truckTypeForm",on:{"success":_vm.getTruckTypes}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }