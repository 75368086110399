<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :max-width="800"
  >
    <template slot="header">
      <span>{{ item.id ? `Update Record #${item.id}` : 'Create New Record' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
          cols="4"
        >
          <inventory-class-select-element
            v-model="item.inventory_class_id"
            :is-required="true"
            :is-add-all="false"
            :is-inst="false"
            :is-glass="false"
            :is-vwd-glass="false"
            :is-panels="false"
            :disabled="isDisabled"
            :is-parts="false"
            :is-sid="false"
            :is-rail-part="false"
            :is-railings="false"
          />
        </v-col>
        <!-- <v-col
          cols="4"
        >
          <v-text-field
            v-model="item.wo_number"
            :rules="[(v) => !isJobManual() || (!!v || 'WO# is required')]"
            :disabled="!isJobManual()"
            clearable
          >
            <template slot="label">
              WO#<span v-if="isJobManual()"><RedAsterisk /></span>
            </template>
          </v-text-field>
        </v-col> -->
        <v-col cols="8">
          <v-autocomplete
            v-model="item.location_id"
            :items="locations"
            item-text="name"
            item-value="id"
            label="Location"
            clearable
            :rules="[(v) => !!v || 'Location is required']"
          >
            <template slot="label">
              Location<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pt-0"
        >
          <v-autocomplete
            v-model="item.job_id"
            :items="jobs"
            :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
            item-value="id"
            :no-data-text="!item.inventory_class_id ? 'Select an Inventory Class first' : 'No data available'"
            :rules="[(v) => !!v || 'Project/Job is required']"
            :disabled="isDisabled || item.id != 0"
            clearable
            :loading="loadingJob"
          >
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                v-if="item && item.sub_class"
                small
                :color="item.sub_class.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.sub_class.name }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <v-chip
                v-if="item && item.sub_class"
                small
                :color="item.sub_class.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.sub_class.name }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <template slot="label">
              Project/Job<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="auto"
          class="text-end pb-0 pt-6 pl-0 pr-0"
        >
          <v-btn
            v-if="!item.id"
            color="primary"
            outlined
            small
            @click="openJobForm()"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="primary"
            outlined
            small
            @click="openUpdateJobForm()"
          >
            <v-row>
              <v-col
                cols="auto"
                class="pr-0 pl-2"
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-col>
              <v-col
                cols="auto"
                class="pl-1"
              >
                <span style="font-size: 15px;">
                  EDIT</span>
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
        >
          <v-text-field
            v-model="item.window_verticals"
            type="number"
            :disabled="isDisabled || isDisabledVertical"
          >
            <template slot="label">
              Window Verticals
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-text-field
            v-model="item.patio_doors"
            type="number"
            :disabled="isDisabled || isDisabledVertical"
          >
            <template slot="label">
              Patio Doors
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-text-field
            v-model="item.euro_doors"
            type="number"
            :disabled="isDisabled"
          >
            <template slot="label">
              Euro Doors
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-text-field
            v-model="windowsSum"
            disabled
            label="Total Release Qty"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="!isJobManual()"
          cols="3"
        >
          <v-autocomplete
            v-model="item.sp_po_number"
            label="SP PO#"
            :items="poNumbers"
            clearable
          >
            <template slot="label">
              SP PO#
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-else
          cols="3"
        >
          <v-text-field
            v-model="item.sp_po_number"
            clearable
          >
            <template slot="label">
              SP PO#
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-select
            v-model="item.sp_note_status_id"
            label="SP PO# Glass Note"
            item-value="id"
            item-text="name"
            :items="noteStatuses"
            :disabled="!item.sp_po_number"
            clearable
          >
            <template slot="label">
              SP PO# Glass Note
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="!isJobManual()"
          cols="3"
        >
          <v-select
            v-model="item.su_po_number"
            item-value="id"
            item-text="name"
            :items="poNumbers"
            clearable
          >
            <template slot="label">
              S/U PO#
            </template>
          </v-select>
        </v-col>
        <v-col
          v-else
          cols="3"
        >
          <v-text-field
            v-model="item.su_po_number"
            clearable
          >
            <template slot="label">
              S/U PO#
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-select
            v-model="item.su_note_status_id"
            label="SP PO# Glass Note"
            item-value="id"
            item-text="name"
            :items="noteStatuses"
            :disabled="!item.su_po_number"
            clearable
          >
            <template slot="label">
              SU PO# Glass Note
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="item.sp_note_status_id === noteStatusIdShort || item.su_note_status_id === noteStatusIdShort">
        <v-col
          cols="3"
          class="pt-0"
        />
        <v-col
          cols="3"
          class="pt-0"
        >
          <v-text-field
            v-if="item.sp_note_status_id === noteStatusIdShort"
            v-model="item.sp_note"
            label="SP PO# Missing"
            type="number"
          />
        </v-col>
        <v-col
          cols="3"
          class="pt-0"
        />
        <v-col
          class="pt-0"
          cols="3"
        >
          <v-text-field
            v-if="item.su_note_status_id === noteStatusIdShort"
            v-model="item.su_note"
            label="S/U PO# Missing"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
        >
          <v-menu
            ref="menuShipDate"
            v-model="dateMenuShipDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="item.planned_ship_date"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                :rules="[(v) => !!v || 'Ship Date is required']"
                :disabled="isDisabled"
                v-on="on"
                @change="$refs.menuShipDate.save(item.planned_ship_date)"
              >
                <template slot="label">
                  Ship Date<RedAsterisk />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.planned_ship_date"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="item.planned_ship_date = null"
              >
                Clear
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="item.planned_ship_time"
            type="time"
            :rules="[(v) => !!v || 'Ship Time is required']"
          >
            <template slot="label">
              Ship Time<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-text-field
            v-model="item.glass_required_by"
            label="Glass Req. by"
            readonly
            append-icon="mdi-calendar"
            :disabled="true"
          />
        </v-col>
        <!-- <v-col
          v-if="item.id"
          cols="3"
        >
          <v-text-field
            v-model="item.production_backlog"
            label="Production Backlog"
          />
        </v-col>
        <v-col
          v-if="item.id"
          cols="3"
        >
          <v-text-field
            v-model="item.shipping_backlog"
            label="Shipping Backlog"
          />
        </v-col> -->
      </v-row>
      <v-row v-if="!item.id">
        <v-col class="pb-0">
          <span>Note</span>
        </v-col>
      </v-row>
      <v-row v-if="!item.id">
        <v-col class="pt-0">
          <v-textarea
            v-model="item.note_text"
            outlined
            rows="3"
          />
        </v-col>
      </v-row>
      <job-form
        ref="jobForm"
        :is-supervisor="false"
        :is-client="false"
        :is-sub-class="true"
        :is-show-pending="true"
        :is-job-name="false"
        :is-address="false"
        @success="jobSuccess()"
      />
      <production-schedule-job-update-form
        ref="updateJobForm"
        @success="get(item.id)"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import JobForm from '@/components/forms/jobs/JobForm';
import ProductionScheduleJobUpdateForm from '@/components/forms/productionSchedules/ProductionScheduleJobUpdateForm';

export default {
  components: {
    JobForm,
    ProductionScheduleJobUpdateForm,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      inventory_class_id: 2,
      job_id: null,
      wo_number: null,
      window_verticals: null,
      patio_doors: 0,
      euro_doors: 0,
      production_backlog: null,
      shipping_backlog: null,
      sp_po_number: null,
      sp_note_status_id: 3,
      sp_note: null,
      su_po_number: null,
      su_note_status_id: 3,
      su_note: null,
      glass_required_by: null,
      planned_ship_date: null,
      note_text: null,
      location_id: null,
      sub_class_id: null,
      planned_ship_time: '00:00',
    },

    jobs: [],
    noteStatuses: [],
    poNumbers: [],
    roles: [],
    locations: [],

    noteStatusIdShort: 2,
    dateMenuRequiredBy: false,
    dateMenuShipDate: false,
    isDisabled: true,
    isDisabledVertical: false,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdProdAdmin: 22,
    isUpdate: false,
    windowsSum: 0,

    classId4500: 1,
    classId4800: 2,
    subClassIdCornerPost: 2,
    subClassIdEuroDoor: 3,
    isManual: 0,
    loadingJob: false,

  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_class_id': function (value) {
      if (value == null) return;
      this.getJobs();
      this.getLocations();
    },
    // eslint-disable-next-line func-names
    'item.job_id': function (value) {
      this.isDisabledVertical = false;
      if (value == null) return;

      const job = this.jobs.find((x) => x.id === value);
      if (job == null) return;

      if (job.sub_class) {
        this.item.sub_class_id = job.sub_class.id;

        if (job.sub_class.id === this.subClassIdEuroDoor) {
          this.item.window_verticals = 0;
          this.item.patio_doors = 0;
          const locationId14Precidio = 3;
          this.item.location_id = locationId14Precidio;
          this.isDisabledVertical = true;
        } else if (job.sub_class.id === this.subClassIdCornerPost) {
          const locationId14Precidio = 3;
          this.item.location_id = locationId14Precidio;
        }
      }

      this.getGlassPos();

      if (this.isUpdate) return;

      if (job.sub_class && job.sub_class.id === this.subClassIdEuroDoor) {
        this.item.euro_doors = job.total_euro_doors ? job.total_euro_doors : 0;
        this.item.window_verticals = 0;
        this.item.patio_doors = 0;
      } else {
        this.item.window_verticals = job.total_windows ? job.total_windows : 0;
        this.item.patio_doors = job.total_patio_doors ? job.total_patio_doors : 0;
        this.item.euro_doors = 0;
      }
      this.item.wo_number = job.code;

      this.getPatioDoors(job.code);
    },
    // eslint-disable-next-line func-names
    'item.window_verticals': function (value) {
      if (value == null) return;

      this.windowsSum = this.getSum();
    },
    // eslint-disable-next-line func-names
    'item.patio_doors': function (value) {
      if (value == null) return;

      this.windowsSum = this.getSum();
    },
    // eslint-disable-next-line func-names
    'item.euro_doors': function (value) {
      if (value == null) return;

      this.windowsSum = this.getSum();
    },
    // eslint-disable-next-line func-names
    'item.sp_po_number': function (value) {
      if (value == null) {
        this.item.sp_note_status_id = null;
        this.item.sp_note = null;
      }
    },
    // eslint-disable-next-line func-names
    'item.su_po_number': function (value) {
      if (value == null) {
        this.item.su_note_status_id = null;
        this.item.su_note = null;
      }
    },
    // eslint-disable-next-line func-names
    'item.planned_ship_date': function (value) {
      if (value === null) return;
      const date = new Date(value);
      date.setDate(date.getDate() - 5);
      this.item.glass_required_by = date.toISOString().slice(0, 10);
    },
  },
  created() {
    this.item = { ...this.itemDto };
    this.roles = this.user.roles.map((x) => x.id);
    this.isDisabled = !(this.roles.includes(this.userRoleIdAdmin) || this.roles.includes(this.userRoleIdApprover) || this.roles.includes(this.userRoleIdProdAdmin));
  },
  methods: {
    init() {
      this.getNoteStatuses();
      this.getJobs();
      this.getGlassPos();
      this.getLocations();
    },

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.productionSchedule.read(submitModel);
      if (!res || !res.data || res.data.length === 0) return;
      const firstIndex = 0;
      this.item = res.data[firstIndex];
      this.$nextTick(() => {
        this.isUpdate = false;
      });
    },

    async getNoteStatuses() {
      this.noteStatuses = await this.$api.productionScheduleNoteStatus.get();
    },

    async getJobs(jobCode) {
      this.loadingJob = true;
      const submitModel = {
        filters: {
          inventory_class_id: this.item.inventory_class_id, is_sub_class: 1, is_manual: 1, is_patio_doors: 1,
        },
      };
      const res = await this.$api.job.readDataSecondary(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;

      if (jobCode) {
        const job = this.jobs.find((x) => x.code === jobCode);
        if (job) {
          this.item.job_id = job.id;
        }
      }
      this.loadingJob = false;
    },

    async getGlassPos() {
      if (!this.item.job_id) return;
      const job = this.jobs.find((x) => x.id === this.item.job_id);
      if (!job) return;
      const res = await this.$api.glass.get(`po-numbers/${this.item.inventory_class_id}/${job.code}`);
      if (!res) {
        this.poNumbers = [];
        return;
      }
      this.poNumbers = res;
      const firstIndex = 0;
      const secondIndex = 1;
      if (this.poNumbers.length === 1) {
        this.item.sp_po_number = this.poNumbers[firstIndex];
        this.item.su_po_number = this.poNumbers[firstIndex];
      } else if (this.poNumbers.length === 2) {
        this.item.sp_po_number = this.poNumbers[firstIndex];
        this.item.su_po_number = this.poNumbers[secondIndex];
      }
    },

    async getLocations() {
      // const locationId460Hanlan = 10;
      const locationId99Vinyl = 3;
      const locationId141Adesso = 8;
      const locationId300Chrislea = 2;
      const locations = await this.$api.location.getList();
      if (this.item.inventory_class_id === this.classId4800) { this.locations = locations.filter((x) => x.id === locationId99Vinyl || x.id === locationId141Adesso || x.id === locationId300Chrislea); } else if (this.item.inventory_class_id === this.classId4500) { this.locations = locations.filter((x) => x.id === locationId141Adesso); } else { this.locations = locations; }
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.productionSchedule.update(this.item);
      } else {
        res = await this.$api.productionSchedule.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      if (res !== true) {
        this.$bus.$emit('showError', res);
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.isUpdate = true;
        this.item.id = id;
        this.isManual = 1;
        this.get(id);
        this.getJobs();
      } else {
        this.item = { ...this.itemDto };

        this.isManual = 0;
      }
      this.$refs.dialogBox.open();
      this.init();
      if (!id) {
        this.item.planned_ship_time = '00:00';
        this.item.sp_note_status_id = 3;
        this.item.su_note_status_id = 3;
      }
    },

    async openJobForm() {
      const res = await this.$api.job.get('generate-manual-code');
      if (!res) return;
      this.$refs.jobForm.open();
      this.$nextTick(() => {
        this.$refs.jobForm.item.code = res;
        this.$refs.jobForm.item.is_manual = 1;
        if (this.item.inventory_class_id) this.$refs.jobForm.item.inventory_class_id = this.item.inventory_class_id;
      });
    },

    openUpdateJobForm() {
      this.$refs.updateJobForm.open(this.item);
    },

    async jobSuccess() {
      this.isManual = 1;
      await this.getJobs();
      const latestItemId = Math.max(...this.jobs.map((x) => x.id));
      if (latestItemId) { this.item.job_id = latestItemId; }
    },

    getSum() {
      return parseInt(this.item.window_verticals === null ? 0 : this.item.window_verticals, 10) + parseInt(this.item.euro_doors === null ? 0 : this.item.euro_doors, 10);
    },

    isJobManual() {
      const job = this.jobs.find((x) => x.id === this.item.job_id);
      if (!job) return false;
      return job.is_manual;
    },

    async getPatioDoors(jobCode) {
      const res = await this.$api.job.get(`patio-doors/${jobCode}`);
      if (!res) return;
      this.item.patio_doors = res;
    },
  },
};
</script>
