<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Submit'"
  >
    <template slot="header">
      <span>Add Part Pick List Item</span>
    </template>
    <template slot="body">
      <v-row v-if="item">
        <v-col>
          <v-row>
            <v-col
              class="pb-0"
            >
              <v-autocomplete
                v-model="item.inventory_tag"
                label="QR Code"
                :items="qrCodes"
                :item-text="item => `${item.inventory_tag} - ${item.item_full_name}`"
                item-value="inventory_tag"
                clearable
                :single-select="false"
                :loading="loadingQrCode"
                :rules="[(v) => !!v || 'QR Code is required']"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <span><span style="font-weight: bold;">{{ item.inventory_tag ? `${item.inventory_tag}` : '' }}</span> - {{ item.item_full_name ? item.item_full_name : '' }}</span>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #item="{ item }">
                  <span><span style="font-weight: bold;">{{ item.inventory_tag ? `${item.inventory_tag}` : '' }}</span> - {{ item.item_full_name ? item.item_full_name : '' }}; <span style="color: blue;">Qty On Hand: {{ item.quantity_on_hand ? item.quantity_on_hand : '' }}</span></span>
                </template>
                <template #label>
                  QR Code<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6">
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px' }">Quantity On Hand: {{ item.quantity_on_hand }}</span>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.quantity_required"
                :rules="[(v) => !!v && item.quantity_required <= parseInt(item.quantity_on_hand) || 'Quantity Required is required and should not exceed quantity on hand']"
                type="number"
              >
                <template #label>
                  Quantity Required<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      inventory_tag: null,
      quantity_required: null,
      quantity_on_hand: null,
    },
    loadingQrCode: false,
    qrCodes: [],
    inventoryClassIdParts: 11,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_tag': function (val) {
      const item = this.qrCodes.find((x) => x.inventory_tag === val);
      if (item) {
        this.item.quantity_on_hand = item.quantity_on_hand;
      }
    },

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async submit() {
      this.$emit('success', this.item);
      return true;
    },

    async open(item, itemIndex) {
      this.$refs.dialogBox.open();
      const res = await this.getQrCodes();
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));
        this.item.quantity_on_hand = this.qrCodes.find((x) => x.inventory_tag === item.inventory_tag).quantity_on_hand;
      } else {
        this.item = { ...this.itemDto };
      }
      if (itemIndex) {
        this.item.index = itemIndex;
      }
    },

    async getQrCodes() {
      this.loadingQrCode = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassIdParts,
          is_qty_on_hand_o: 0,
        },
      };
      const res = await this.$api.inventoryTag.readData(readModel);

      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loadingQrCode = false;
      if (!res || !res.data || res.data.length === 0) {
        this.qrCodes = [];
        return;
      }
      this.qrCodes = res.data;
    },
  },
};
</script>
