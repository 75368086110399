<template>
  <v-dialog
    v-model="dialog"
    persistent
    min-width="675px"
    max-width="675px"
  >
    <v-card>
      <v-card-title class="title">
        <span>Column Setup</span>
      </v-card-title>
      <v-card-text class="table">
        <v-form
          ref="resetForm"
          v-model="valid"
          lazy-validation
        >
          <v-row
            row
            mt-3
          >
            <div
              ref="tableSettings"
              style="width: 100%"
            >
              <DataTable
                :class="(isDarkMode ? 'theme--dark' : '')"
                :value="tableHeaders"
                :selection.sync="tempHeaders"
                :row-class="rowClass"
                data-key="text"
                edit-mode="cell"
                class="editable-cells-table"
                responsive-layout="scroll"
                @cell-edit-complete="onCellEditComplete"
                @cell-edit-init="onCellEditInit"
                @row-reorder="onRowReorder"
                @row-select="onRowSelect"
              >
                <Column
                  selection-mode="multiple"
                  :header-style="{ width: '3em' }"
                  header-class="disabled-header-checkbox"
                />
                <Column
                  field="text"
                  header="Title"
                />
                <!-- getting a warning because two columns have the field="headerStyle" -->
                <Column
                  field="headerStyle"
                  header="Width Pixels"
                >
                  <template #body="slotProps">
                    <v-text-field
                      class="placeholder-color"
                      :disabled="slotProps.data.value === 'expander'"
                      :placeholder="roundNumber(slotProps.data[slotProps.column.field].pixelWidth)"
                    />
                    <!-- <span class="underline">
                      {{ slotProps.data[slotProps.column.field].pixelWidth | roundNumber }}
                    </span> -->
                  </template>
                  <template #editor="slotProps">
                    <!-- <InputText
                      v-model="slotProps.data[slotProps.column.field].pixelWidth"
                    /> -->
                    <!-- <v-text-field
                      v-if="slotProps.data.value === 'actions'"
                      v-model="slotProps.data[slotProps.column.field].pixelWidth"
                      :rules="[actionInput]"
                      @focus="$event.target.select()"
                    />
                    <v-text-field
                      v-else
                      v-model="slotProps.data[slotProps.column.field].pixelWidth"
                      :rules="[rules.widthInput]"
                      :disabled="slotProps.data.value === 'expander'"
                      @focus="$event.target.select()"
                    /> -->
                    <InputText
                      v-if="slotProps.data.value === 'actions'"
                      v-model="slotProps.data[slotProps.column.field].pixelWidth"
                      :rules="[actionInput]"
                      @keypress="validateNumberInput"
                      @focus="$event.target.select()"
                    />
                    <InputText
                      v-else
                      v-model="slotProps.data[slotProps.column.field].pixelWidth"
                      :rules="[rules.widthInput]"
                      :disabled="slotProps.data.value === 'expander'"
                      @keypress="validateNumberInput"
                      @focus="$event.target.select()"
                    />
                    <small
                      v-if="slotProps.data.value === 'actions'"
                      style="display: block"
                    >Enter a value greater than {{ defaultActionColumnPixelWidth }}</small>
                  </template>
                  <template #footer>
                    {{ `Total: ${footerPixelTotal()}` }}
                  </template>
                </Column>
                <Column field="headerStyle">
                  <template #header>
                    <div style="display: flex; justify-content: space-between !important; width: 100%;">
                      <span style="top: 11px; position: relative;">Width % <div class="header-hint">100% represents default value of the page without scroll bar</div></span>
                      <v-tooltip
                        top
                        color="black"
                      >
                        <template #activator="{ on }">
                          <v-icon
                            color="#747474"
                            dark
                            small
                            v-on="on"
                          >
                            fas fa-question-circle
                          </v-icon>
                        </template>
                        <span>Percentages are based on default table width: {{ defaultTableWidth }}px</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template #body="slotProps">
                    <!-- <v-text-field
                      v-model="(slotProps.data[slotProps.column.field].width)"
                    /> -->
                    <v-text-field
                      class="placeholder-color"
                      :disabled="slotProps.data.value === 'expander'"
                      :placeholder="roundPercentage(slotProps.data[slotProps.column.field].width)"
                    />
                    <!-- {{ slotProps.data[slotProps.column.field].width | roundPercentage }} -->
                  </template>
                  <template #editor="slotProps">
                    <div>
                      <InputText
                        v-model="slotProps.data[slotProps.column.field].width"
                        :rules="[rules.widthPercentage]"
                        :disabled="slotProps.data.value === 'expander'"
                        @keypress="validateNumberInput"
                        @focus="$event.target.select()"
                      />
                      <small
                        v-if="slotProps.data.value === 'actions'"
                        style="display: block"
                      >Enter a value greater than {{ defaultActionColumnPercentageWidth }}%</small>
                    <!-- <v-text-field
                      v-model="slotProps.data[slotProps.column.field].width"
                      :rules="[rules.widthInput]"
                      :disabled="slotProps.data.value === 'expander'"
                    /> -->
                    </div>
                  </template>
                  <template #footer>
                    {{ `Total: ${footerPercentageTotal()}` }}
                  </template>
                </Column>
                <Column
                  :row-reorder="true"
                  :header-style="{'width': '3rem'}"
                  :reorderable-column="false"
                />
              </DataTable>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="normal"
          class="mr-2"
          @click="resetHeaders"
        >
          RESET TO DEFAULT
        </v-btn>
        <v-spacer />
        <v-btn
          color="normal"
          class="mr-2"
          @click="cancel"
        >
          CANCEL
        </v-btn>
        <v-btn
          color="primary"
          @click="saveSettings"
        >
          SAVE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  name: 'TableSettings',

  components: {
    DataTable,
    Column,
    InputText,
  },

  filters: {
    editRoundNumber: (item) => {
      if (item) {
        return Math.round(parseFloat(item));
      }
    },
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
    defaultTableWidth: {
      type: Number,
      required: true,
    },
    // defaultHeaders are used to restore the default column widths after re-selecting them
    defaultHeaders: {
      type: Array,
      required: true,
    },
    defaultActionColumnPixelWidth: {
      type: Number,
      required: true,
    },
    defaultActionColumnPercentageWidth: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    tableHeaders: [],
    tempHeaders: [],
    saveSettingsEvent: false,
    rules: {
      widthInput: (value) => {
        const number = parseInt(value, 10);
        return (!isNaN(number) && number > 0) || 'Must be a number greater than 0';
      },
    },
  }),

  computed: {
    ...mapState({
      // search: (state) => state.search,
      isDarkMode: 'isDarkMode',
    }),
    actionInput() {
      return (value) => {
        if (!value || isNaN(value)) {
          return 'Value must be a number';
        }
        if (parseFloat(value) < this.defaultActionColumnPixelWidth) {
          return `Value must be greater than or equal to ${this.defaultActionColumnPixelWidth}`;
        }
        return true; // validation passed
      };
    },

    // computedTableHeaders and computedTempHeaders could be used instead of trimming on update but might need to refactor other parts of code
    // computedTableHeaders() {
    //   const newTableHeaders = [];
    //   this.tableHeaders.forEach((header) => {
    //     const trimmedWidth = parseFloat(header.headerStyle.width).toFixed(2);
    //     const newHeader = JSON.parse(JSON.stringify(header));
    //     if (isNaN(trimmedWidth)) {
    //       newHeader.headerStyle.width = '';
    //     } else {
    //       newHeader.headerStyle.width = `${trimmedWidth}%`;
    //     }
    //     const trimmedPixelWidth = parseFloat(header.headerStyle.pixelWidth).toFixed(2);
    //     if (isNaN(trimmedPixelWidth)) {
    //       newHeader.headerStyle.pixelWidth = 0;
    //     } else {
    //       newHeader.headerStyle.pixelWidth = trimmedPixelWidth;
    //     }
    //     newTableHeaders.push(newHeader);
    //   });
    //   return newTableHeaders;
    // },

    // computedTempHeaders() {
    //   const newTableHeaders = [];
    //   this.tempHeaders.forEach((header) => {
    //     const trimmedWidth = parseFloat(header.headerStyle.width).toFixed(2);
    //     const newHeader = JSON.parse(JSON.stringify(header));
    //     if (isNaN(trimmedWidth)) {
    //       newHeader.headerStyle.width = '';
    //     } else {
    //       newHeader.headerStyle.width = `${trimmedWidth}%`;
    //     }
    //     const trimmedPixelWidth = parseFloat(header.headerStyle.pixelWidth).toFixed(2);
    //     if (isNaN(trimmedPixelWidth)) {
    //       newHeader.headerStyle.pixelWidth = 0;
    //     } else {
    //       newHeader.headerStyle.pixelWidth = trimmedPixelWidth;
    //     }
    //     newTableHeaders.push(newHeader);
    //   });
    //   return newTableHeaders;
    // },
  },

  updated() {
    this.$nextTick(() => {
      // Code that will run only after the entire view has been re-rendered
      if (this.saveSettingsEvent) {
        // We need a saveSettingsEvent because the function below will take the header settings from sessionSettings
        this.setHeaderWidthPercentages();
        this.saveSettingsEvent = false;
      }
      // The function below ensure that after an update (say you input 400px to a column width) both tableHeaders receives the change from tempHeaders
      // and to set tableHeader widths to 0 if it's unselected
      this.syncTempHeadersAndTableHeaders();
      this.trimDecimals();
    });
  },

  mounted() {
    this.setHeaderWidthPercentages();
  },

  methods: {
    setHeaderWidthPercentages() {
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (this.headers.length > 0 && this.selectedHeaders.length > 0 && sessionSettings && sessionSettings.columnWidths && sessionSettings.tableWidth) {
        const parsedColumnWidths = sessionSettings.columnWidths.split(',').map(Number);
        this.tableHeaders.forEach((header, index) => {
          if (parsedColumnWidths[index] !== undefined) {
            const newWidth = parsedColumnWidths[index] / this.defaultTableWidth;
            header.headerStyle.width = `${(newWidth * 100).toFixed(2)}%`;
          } else {
            header.headerStyle.width = '0%';
          }
        });
        this.tempHeaders.forEach((header, index) => {
          if (parsedColumnWidths[index] !== undefined) {
            const newWidth = parsedColumnWidths[index] / this.defaultTableWidth;
            header.headerStyle.width = `${(newWidth * 100).toFixed(2)}%`;
          }
        });
      }
    },

    syncTempHeadersAndTableHeaders() {
      const selectedHeaders = [];
      this.tempHeaders.forEach((header) => {
        selectedHeaders.push(header.value);
      });
      this.tableHeaders.forEach((header) => {
        if (!selectedHeaders.includes(header.value)) {
          header.headerStyle.width = '0%';
          header.headerStyle.pixelWidth = 0;
        }
      });
    },

    trimDecimals() {
      // another way could be trimming it by using computed properties but not sure what the consequences of that are
      this.tableHeaders.forEach((header) => {
        const trimmedWidthPercentage = parseFloat(header.headerStyle.width).toFixed(2);
        if (isNaN(trimmedWidthPercentage)) {
          header.headerStyle.width = '';
        } else {
          header.headerStyle.width = `${trimmedWidthPercentage}%`;
        }
        const trimmedPixelWidth = parseFloat(header.headerStyle.pixelWidth).toFixed(2);
        if (isNaN(trimmedPixelWidth)) {
          header.headerStyle.pixelWidth = 0;
        } else {
          header.headerStyle.pixelWidth = trimmedPixelWidth;
        }
      });
    },

    open() {
      this.dialog = true;
      this.tableHeaders = JSON.parse(JSON.stringify(this.headers));
      this.tempHeaders = JSON.parse(JSON.stringify(this.selectedHeaders));
      this.setWidths();
    },

    cancel() {
      this.dialog = false;
    },

    syncHeaders() {
      this.$nextTick(() => {
        this.tempHeaders = JSON.parse(JSON.stringify(this.selectedHeaders));
      });
    },

    resetHeaders() {
      this.$emit('tableReset');
      this.tableHeaders = [];
      this.tempHeaders = [];
      this.dialog = false;
    },
    async saveSettings() {
      // if (!this.$refs.resetForm.validate()) return; // not working
      this.saveSettingsEvent = true;
      this.$emit('tableSave', this.tempHeaders);
      this.dialog = false;
    },

    onRowReorder(event) {
      this.rowReorderEvent = true;
      this.tableHeaders = event.value;
      const reorderedHeaders = JSON.parse(JSON.stringify(this.tempHeaders));
      this.tableHeaders.forEach((header, index) => {
        const headerIndex = reorderedHeaders.findIndex(
          (h) => h.text === header.text,
        );
        if (
          headerIndex < this.selectedHeaders.length // used to be headerInex > this.selectedHeaders.length + 1 but doing this is always false
          && headerIndex !== index
          && headerIndex !== -1
        ) {
          const temp = reorderedHeaders[index];
          reorderedHeaders[index] = JSON.parse(JSON.stringify(header));
          reorderedHeaders[headerIndex] = JSON.parse(JSON.stringify(temp));
        }
      });
      this.tempHeaders = reorderedHeaders;
    },

    onRowSelect(event) {
      let index;
      if (this.selectedHeaders.length > 2) {
        index = this.selectedHeaders.length - 1;
      } else if (this.selectedHeaders.length <= 1) {
        index = 0;
      }
      const item = JSON.parse(JSON.stringify(this.tempHeaders.pop()));
      const defaultHeader = this.defaultHeaders.find((header) => header.value === item.value);
      item.headerStyle.width = defaultHeader.headerStyle.width;
      item.headerStyle.pixelWidth = defaultHeader.headerStyle.pixelWidth;
      this.tempHeaders.splice(index, 0, item);
    },

    onCellEditInit(event) {
      //
    },

    onCellEditComplete(event) {
      const { newValue, field, index } = event;
      // if (event.data.value === 'actions' && (parseInt(newValue.pixelWidth, 10) < this.defaultActionColumnPixelWidth || Math.round(parseFloat(newValue.width)) < parseFloat(this.defaultActionColumnPercentageWidth))) {
      //   this.tableHeaders[index][field] = { ...this.tempHeaders[index][field] };
      //   console.log('here');
      //   return;
      // }
      // just used 4000 to limit the upper range
      if (isNaN(newValue.pixelWidth) || !(newValue.pixelWidth > 0 && newValue.pixelWidth < 4000) || isNaN(newValue.width.replace(/%$/, '')) || !(newValue.width.replace(/%$/, '') > 0 && newValue.width.replace(/%$/, '') < 100)) {
        this.tableHeaders[index][field] = { ...this.tempHeaders[index][field] };
        return;
      }
      const changedProperty = this.getChangedProperty(event);
      if (Object.keys(changedProperty).length <= 0) {
        return;
      }
      if (changedProperty.pixelWidth && !isNaN(newValue.pixelWidth)) {
        // changed pixels, so need to change percentage
        if (event.data.value === 'actions' && parseFloat(newValue.pixelWidth) < this.defaultActionColumnPixelWidth) {
          this.tableHeaders[index][field] = { ...this.tempHeaders[index][field] };
          return;
        }
        const newChangedValue = { ...newValue };
        newChangedValue.pixelWidth = changedProperty.pixelWidth;
        newChangedValue.width = `${((newChangedValue.pixelWidth / (this.defaultTableWidth)) * 100).toFixed(2)}%`;
        this.tempHeaders[index][field] = { ...newChangedValue };
        this.tableHeaders[index][field] = { ...newChangedValue };
      } else if (changedProperty.width && newValue.width.trim().length > 0 && !isNaN(newValue.width.replace(/%$/, ''))) {
        // changed percentage, so need to change pixels
        if (event.data.value === 'actions' && Math.round(parseFloat(newValue.width)) < parseFloat(this.defaultActionColumnPercentageWidth)) {
          this.tableHeaders[index][field] = { ...this.tempHeaders[index][field] };
          return;
        }
        const newChangedValue = { ...newValue };
        newChangedValue.width = `${newValue.width.trim().replace(/%$/, '')}%`;
        newChangedValue.pixelWidth = ((parseFloat(newChangedValue.width, 10) / 100) * this.defaultTableWidth).toFixed(2);
        this.tempHeaders[index][field] = { ...newChangedValue };
        this.tableHeaders[index][field] = { ...newChangedValue };
      }
    },

    getChangedProperty(event) {
      const { newValue, field, index } = event;
      const oldHeaderStyle = { ...this.tempHeaders[index][field] };
      const changedProperty = {};
      Object.keys(oldHeaderStyle).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(oldHeaderStyle, key) && parseFloat(oldHeaderStyle[key]) !== parseFloat(newValue[key])) {
          changedProperty[key] = newValue[key];
        }
      });
      return changedProperty;
    },
    setWidths() {
      this.tableHeaders.forEach((header) => {
        const tempHeaderIndex = this.tempHeaders.findIndex((h) => h.text === header.text);
        if (this.tempHeaders[tempHeaderIndex] !== undefined) header.headerStyle.width = this.tempHeaders[tempHeaderIndex].headerStyle.width;
      });
    },

    footerPixelTotal() {
      let pixelTotal = 0;
      this.tableHeaders.forEach((header) => {
        const parsedInt = parseInt(header.headerStyle.pixelWidth, 10);
        if (parsedInt !== undefined && parsedInt !== null && !isNaN(parsedInt)) {
          pixelTotal += parsedInt;
        }
      });
      return `${pixelTotal.toLocaleString()}px`;
    },

    footerPercentageTotal() {
      let percentageTotal = 0;
      this.tableHeaders.forEach((header) => {
        const parsedFloat = parseFloat(header.headerStyle.width);
        if (parsedFloat !== undefined && parsedFloat !== null && !isNaN(parsedFloat)) {
          percentageTotal += parsedFloat;
        }
      });
      return `${Math.round(percentageTotal).toLocaleString()}%`;
    },

    rowClass(data) {
      if (data.value === 'actions') {
        return 'frozen-row';
      }
      if (data.value === 'expander') {
        return 'disabled-row';
      }
      return null;
    },

    roundNumber(item) {
      if (item) {
        return Math.round(parseFloat(item)).toString();
      }
      return '';
    },
    roundPercentage(item) {
      if (item) {
        return `${Math.round(parseFloat(item))}%`;
      }
      return '';
    },
    validateNumberInput(event) {
      const charCode = event.which ? event.which : event.keyCode;
      // Allow only numbers (0-9) and backspace (8)
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
/deep/.p-editable-column {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/deep/.placeholder-color input::placeholder {
  color: #495057 !important;
  opacity: 1;
}
/deep/.placeholder-color.theme--dark input::placeholder {
  color: white !important;
  opacity: 1;
}
.title {
  margin-bottom: 1rem !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}
/deep/.p-checkbox-icon.pi.pi-check::before {
  display: none !important;
}
/deep/.p-checkbox-icon.pi.pi-check::after {
  content: "\f00c" !important; /* Unicode character for Font Awesome chevron-right */
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important; /* Adjust font weight if needed */
  font-style: normal !important;
  cursor: pointer !important;
}
/deep/.p-datatable-reorderablerow-handle.pi.pi-bars::before {
  display: none !important;
}
/deep/.p-datatable-reorderablerow-handle.pi.pi-bars::after {
  content: "\f0C9" !important; /* Unicode character for Font Awesome chevron-right */
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important; /* Adjust font weight if needed */
  font-style: normal !important;
  cursor: pointer !important;
}
/deep/.p-datatable.p-datatable-gridlines.theme--dark .p-datatable-tbody > tr > td {
  border: thin solid rgba(255, 255, 255, 0.12);
}
/deep/.p-datatable.theme--dark .p-datatable-tbody > tr {
  background: #1e1e1e;
  color: white;
  border: thin solid rgba(255, 255, 255, 0.12);
}
/deep/.p-datatable.theme--dark .p-datatable-tbody > tr.p-highlight {
  background: #00eeff73 !important;
  /* color: #2c2c2c; */
}
/deep/.p-datatable.theme--dark .p-datatable-thead > tr > th{
  background: #323232;
  color: white;
  border: thin solid rgba(255, 255, 255, 0.12);
}
/deep/.p-datatable.theme--dark .p-datatable-tfoot > tr > td{
  background: #323232;
  color: white;
  border: thin solid rgba(255, 255, 255, 0.12);
}
/deep/.p-input-icon-right {
  position: relative !important;
  display: inline-block !important;
}
.header-hint {
  font-size: .8rem;
  color: var(--v-error-base);
}
/deep/tr.disabled-row > td:last-child > .p-datatable-reorderablerow-handle.pi.pi-bars {
  display: none;
}
/deep/tr.disabled-row > td:nth-child(1) > div:nth-child(1) {
  display: none;
}
/deep/tr.frozen-row > td:last-child > .p-datatable-reorderablerow-handle.pi.pi-bars {
  display: none;
}
/deep/tr.frozen-row > td:nth-child(1) > div:nth-child(1) {
  display: none;
}
/* /deep/tr.disabled-checkbox > td:nth-child(1) > div:nth-child(1) {
  display: none;
} */
/deep/th.disabled-header-checkbox > div.p-column-header-content > div.p-checkbox  {
  display: none;
}
</style>
