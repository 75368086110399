import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;

class AlertApi extends Api {
  constructor() {
    super('alerts', 'alerts');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const alertApi = new AlertApi();

export default alertApi;
