<template>
  <div :style="{backgroundColor: isDarkMode ? '#121212' : ''}">
    <v-row
      row
      justify-end
      pb-1
    >
      <tableSettings
        ref="tableSettings"
        :key="tableSettingsKey"
        :headers="headers"
        :selected-headers="selectedHeaders"
        :table="table"
        :default-table-width="defaultTableWidth"
        :default-headers="defaultHeadersWithPixels"
        :default-action-column-pixel-width="defaultActionColumnPixelWidth"
        :default-action-column-percentage-width="defaultActionColumnPercentageWidth"
        @tableSave="tableSave"
        @tableReset="tableReset"
      />
    </v-row>
    <div ref="dataTable">
      <DataTable
        id="table"
        :key="componentKey"
        :value="data"
        :class="'p-datatable-sm ' + (isDarkMode ? 'theme--dark' : '')"
        :row-class="rowClass"
        show-gridlines
        striped-rows
        removable-sort
        :scrollable="false"
        :loading="loading"
        :resizable-columns="true"
        column-resize-mode="expand"
        :reorderable-columns="true"
        state-storage="session"
        :state-key="table"
        :expanded-rows.sync="expandedRows"
        @row-expand="onRowExpand"
        @row-collapse="onRowCollapse"
        @column-reorder="onColReorder"
        @column-resize-end="onColResize"
        @sort="onPage($event)"
      >
        <template #empty>
          No results found.
        </template>
        <template #loading>
          Loading data, please wait.
        </template>
        <Column
          v-if="expander"
          :expander="expander"
          :reorderable-column="false"
          :frozen="true"
          :header-style="{'width': '2%'}"
          field="expander"
        />
        <Column
          v-for="(col, index) of displayedSelectedHeaders"
          :key="col.value + '_' + index"
          :field="col.value"
          :sortable="col.sortable ? true : false"
          :sort-field="col.sortField"
          :header-style="col.headerStyle"
          :frozen="col.frozen"
          :reorderable-column="col.reorderableColumn"
        >
          <template #header>
            <v-tooltip
              v-if="col.fullText"
              bottom
            >
              <template #activator="{ on }">
                <span v-on="on">{{ col.text }}</span>
              </template>
              <span>{{ col.fullText }}</span>
            </v-tooltip>
            <span v-else>{{ col.text }}</span>
          </template>
          <template #body="slotProps">
            <!-- TEXT COLUMNS -->
            <div
              v-if="col.type === 'text'"
              :class="col.class"
              class="text"
            >
              <text-highlight :queries="searchHighlight">
                {{
                  slotProps.data[col.value] ? slotProps.data[col.value] : col.defaultValue ? col.defaultValue : ''
                }}
              </text-highlight>
              <slot
                name="text-append"
                :data="{...JSON.parse(JSON.stringify(slotProps.data)), headers: {...JSON.parse(JSON.stringify(col))}}"
              />
            </div>
            <div
              v-if="col.type === 'deepText'"
              :class="col.class"
              class="text"
            >
              <text-highlight :queries="searchHighlight">
                {{
                  searchObject(slotProps.data, col.value) ? searchObject(slotProps.data, col.value) : col.defaultValue ? col.defaultValue : ''
                }}
              </text-highlight>
            </div>
            <div
              v-if="col.type === 'tooltipText'"
              :class="col.class"
              class="text"
            >
              <slot
                name="text-tooltip"
                :data="{...JSON.parse(JSON.stringify(slotProps.data)), headers: {...JSON.parse(JSON.stringify(col))}}"
              />
            </div>
            <div
              v-if="col.type === 'number'"
              :class="col.class"
              class="text"
            >
              <text-highlight :queries="searchHighlight">
                {{
                  slotProps.data[col.value] ? slotProps.data[col.value] : '0'
                }}
              </text-highlight>
              <slot
                name="number-append"
                :data="{...JSON.parse(JSON.stringify(slotProps.data)), headers: {...JSON.parse(JSON.stringify(col))}}"
              />
            </div>
            <div
              v-if="col.type === 'uppercaseText'"
              :class="col.class"
            >
              <text-highlight :queries="searchHighlight">
                {{
                  slotProps.data[col.value].name | uppercase
                }}
              </text-highlight>
            </div>
            <div
              v-if="col.type === 'textWithCopy'"
              :class="col.class"
            >
              <text-highlight :queries="searchHighlight">
                {{
                  slotProps.data[col.value]
                    ? formatText(slotProps.data[col.value], col.formatFunction)
                    : col.defaultText
                }}
              </text-highlight>
              <v-tooltip
                :v-model="
                  slotProps.data[col.value]
                    ? slotProps.data[col.value].copyItem
                    : null
                "
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-if="slotProps.data[col.value]"
                    class="copy-icon text-xs-right pr-0 ml-2"
                    v-bind="attrs"
                    color="#4caf50"
                    small
                    @click="copyClick(on, col.copyMethod, slotProps.data)"
                  >
                    far fa-copy
                  </v-icon>
                </template>
                <span>Copied!</span>
              </v-tooltip>
            </div>
            <div
              v-if="col.type === 'formattedText'"
              :class="col.class"
            >
              {{
                slotProps.data[col.value]
                  ? formatText(slotProps.data[col.value], col.formatFunction)
                  : col.defaultText
              }}
            </div>
            <div
              v-if="col.type === 'currency'"
              :class="
                slotProps.data[col.value] < 0
                  ? col.negativeAmountColor
                  : col.positiveAmountColor
              "
            >
              {{
                slotProps.data[col.value]
                  ? formatText(slotProps.data[col.value], col.formatFunction)
                  : col.defaultText
              }}
            </div>
            <div
              v-if="col.type === 'truncTextWithTooltip'"
              :class="col.class"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">
                    <text-highlight :queries="searchHighlight">{{
                      formatText(slotProps.data[col.value], col.formatFunction)
                    }}</text-highlight>
                  </span>
                </template>
                <span>{{ slotProps.data[col.value] }}</span>
              </v-tooltip>
            </div>
            <div
              v-if="col.type === 'address'"
              :class="col.class"
            >
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <text-highlight :queries="searchHighlight">{{
                      slotProps.data.address_simple
                        ? slotProps.data.address_simple
                        : '-'
                    }}
                    </text-highlight>
                  </span>
                </template>

                <text-highlight :queries="searchHighlight">
                  {{
                    slotProps.data.address_full | uppercase
                  }}
                </text-highlight>
              </v-tooltip>
            </div>
            <div
              v-if="col.type === 'client'"
              :class="col.class"
            >
              <text-highlight :queries="searchHighlight">
                {{
                  slotProps.data[col.value] ? slotProps.data[col.value].name : '-'
                }}
              </text-highlight>
            </div>
            <!-- BUTTON COLUMNS -->
            <div
              v-if="col.type === 'button'"
              :class="col.class"
            >
              <Button
                v-if="slotProps.data[col.value]"
                class="p-button-sm p-button-outlined ban-button"
                @click="handleClick(col, slotProps)"
              >
                {{ slotProps.data[col.value] }}
              </Button>
            </div>
            <div
              v-if="col.type === 'iconButton'"
              :class="col.class"
            >
              <Button
                :icon="col.icon"
                class="p-button-rounded p-button-text icon-button"
                @click="handleClick(col, slotProps)"
              />
            </div>
            <div
              v-if="col.type === 'buttonLink'"
              :class="col.class"
            >
              <router-link
                v-if="slotProps.data[col.value]"
                class="RouterLinkButton"
                :to="{ name: col.pathName, params: slotProps.data }"
              >
                {{ slotProps.data[col.value] }}
              </router-link>
            </div>
            <div
              v-if="col.type === 'link'"
              :class="col.class"
            >
              <router-link
                v-if="slotProps.data[col.value]"
                style="text-decoration: none"
                :to="{ name: col.pathName, params: slotProps.data }"
              >
                {{ slotProps.data[col.value] }}
              </router-link>
            </div>
            <!-- CHIP COLUMNS -->
            <div
              v-if="col.type === 'chip'"
              :class="col.class"
            >
              <v-chip
                v-if="slotProps.data[col.value]"
                class="white-chip"
                :color="slotProps.data[col.value].color"
              >
                <text-highlight :queries="searchHighlight">
                  {{ slotProps.data[col.value].name | uppercase }}
                </text-highlight>
              </v-chip>
            </div>
            <div
              v-if="col.type === 'numberChip'"
              :class="col.class"
            >
              <v-chip
                v-if="slotProps.data[col.value] !== 0"
                class="white-chip"
                :color="col.chipColor"
              >
                <text-highlight :queries="searchHighlight">
                  {{ slotProps.data[col.value] }}
                </text-highlight>
              </v-chip>
              <span v-else>
                <text-highlight :queries="searchHighlight">
                  0
                </text-highlight>
              </span>
            </div>
            <div
              v-if="col.type === 'boolChip'"
              :class="col.class"
            >
              <v-chip
                class="white-chip"
                :color="
                  slotProps.data[col.chipColor] === true ? 'red' : 'green'
                "
              >
                {{
                  slotProps.data[col.value].name
                    ? col.boolTrueText
                    : col.boolFalseText | uppercase
                }}
              </v-chip>
            </div>
            <div
              v-if="col.type === 'activeChip'"
              :class="col.class"
            >
              <v-chip
                class="white-chip"
                :color="
                  slotProps.data[col.value] === 1 ? 'green' : 'red'
                "
              >
                {{
                  slotProps.data[col.value] === 1 ? 'ACTIVE' : 'ARCHIVED'
                }}
              </v-chip>
            </div>
            <!-- ICON BUTTON COLUMNS -->
            <a
              v-if="col.type == 'iconButton'"
              @click="handleClick(col, slotProps)"
            >
              <v-icon
                :color="col.color"
                small
              >
                {{ col.icon }}
              </v-icon>
            </a>
            <a
              v-if="col.type == 'checkExistsConditionalIconButton' && slotProps.data[col.checkFrom]"
              @click="handleClick(col, slotProps)"
            >
              <v-icon
                :color="col.color"
                small
              >
                {{ col.icon }}
              </v-icon>
            </a>
            <!-- ACTIONS COLUMN -->
            <div
              v-if="col.type === 'actions'"
              :class="(col.conditionalButtons ? 'conditional-actions-column ' : 'actions-column ') + 'frozen'"
            >
              <div
                v-for="action of col.actions"
                :key="action.text"
              >
                <!-- <Button
                  v-if="action.type == 'buttonLink' && checkCondition(slotProps, action.check, action.condition, action.conditionParams)"
                  :class="'p-button-link'"
                  @click="handleClick(action, slotProps)"
                >{{ action.text }}</Button> -->
                <v-btn
                  v-if="action.type == 'button'"
                  class="ml-1 mt-1 mb-1 font-weight-bold"
                  :color="action.color"
                  outlined
                  small
                  @click="handleClick(action, slotProps)"
                >
                  {{ action.text }}
                </v-btn>
                <!-- <Button
                  v-if="action.type == 'button'"
                  class="p-button-outlined"
                  :severity="action.color"
                  @click="handleClick(action, slotProps)"
                >{{ action.text }}</Button> -->
                <v-btn
                  v-if="action.type == 'conditionalButton' && checkCondition(slotProps, action.checkFrom, action.check, action.condition, action.conditionParams)"
                  class="ml-1 mt-1 mb-1 font-weight-bold"
                  :color="action.color"
                  outlined
                  small
                  @click="handleClick(action, slotProps)"
                >
                  {{ action.text }}
                </v-btn>
                <v-btn
                  v-if="action.type == 'userRoleConditionalButton' && checkUserRoleConditionalButton(slotProps, action.checkFrom, action.check, action.condition, action.conditionParams)"
                  class="ml-1 mt-1 mb-1 font-weight-bold"
                  :color="action.color"
                  outlined
                  small
                  @click="handleClick(action, slotProps)"
                >
                  {{ action.text }}
                </v-btn>
                <v-btn
                  v-if="action.type == 'userRoleOnlyConditionalButton' && checkUserRoleOnlyConditionalButton(action.condition, action.conditionParams)"
                  class="ml-1 mt-1 mb-1 font-weight-bold"
                  :color="action.color"
                  outlined
                  small
                  @click="handleClick(action, slotProps)"
                >
                  {{ action.text }}
                </v-btn>
                <a
                  v-if="action.type == 'iconButton'"
                  @click="handleClick(action, slotProps)"
                >
                  <v-icon
                    :color="action.color"
                    small
                  >
                    {{ action.icon }}
                  </v-icon>
                </a>
                <a
                  v-if="action.type == 'checkExistsConditionalIconButton' && slotProps.data[col.checkFrom]"
                  @click="handleClick(action, slotProps)"
                >
                  <v-icon
                    :color="action.color"
                    small
                  >
                    {{ action.icon }}
                  </v-icon>
                </a>
                <a
                  v-if="action.type == 'userRoleOnlyConditionalIconButton' && checkUserRoleOnlyConditionalButton(action.condition, action.conditionParams)"
                  @click="handleClick(action, slotProps)"
                >
                  <v-icon
                    :color="action.color"
                    small
                  >
                    {{ action.icon }}
                  </v-icon>
                </a>
                <!-- <Button
                  v-if="action.type == 'conditionalButton' && checkCondition(slotProps, action.checkFrom, action.check, action.condition, action.conditionParams)"
                  @click="handleClick(action, slotProps)"
                >{{ action.text }}</Button> -->
                <Button
                  v-if="action.type == 'borderButton' && checkCondition(slotProps, action.check, action.condition, action.conditionParams)"
                  class="p-button-outlined"
                  @click="handleClick(action, slotProps)"
                >
                  {{ action.text }}
                </Button>
                <!-- <router-link
                  v-if="action.type == 'link'"
                  :class="action.class"
                  :to="{name: action.pathName, params: slotProps.data}"
                >{{ action.text }}</router-link> -->
              </div>
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <!-- use slot if slotting in DataTable from parent component -->
          <!-- <slot
            name="expansion"
            :data="{...JSON.parse(JSON.stringify(slotProps.data))}"
          /> -->
          <div class="pt-3 pb-3">
            <!-- <DataTable
              v-if="expansionDisplay === 'DataTable'"
              :value="slotProps.data[expansionItems]"
            >
              <Column
                v-for="(col, index) of expansionDataTableHeaders"
                :key="col.value + '_' + index"
                :field="col.value"
                :header="col.text"
                :sortable="col.sortable ? true : false"
                :sort-field="col.sortField"
              >
                <template #body="expansionSlotProps">
                  <div
                    v-if="col.type === 'text'"
                    :class="col.class"
                    class="text"
                  >
                    <text-highlight :queries="searchHighlight">
                      {{
                        expansionSlotProps.data[col.value] ? expansionSlotProps.data[col.value] : col.defaultValue ? col.defaultValue : ''
                      }}
                    </text-highlight>
                  </div>
                </template>
              </Column>
            </DataTable> -->
            <v-data-table
              v-if="expansionDisplay === 'DataTable'"
              id="expansion-panel-table"
              :headers="expansionDataTableHeaders"
              :items="slotProps.data[expansionItems]"
              :items-per-page="-1"
              :search="searchHighlight"
              disable-sort
              hide-default-footer
            >
              <template
                v-for="(header) in expansionDataTableHeaders"
                #[`item.${header.value}`]="{item}"
              >
                <text-highlight
                  :key="header.value"
                  :queries="searchHighlight"
                >
                  {{ item[header.value] ? item[header.value] : '' }}
                </text-highlight>
              </template>
              <template #[`item.actions`]="{ header, item }">
                <div
                  v-for="action of header.actions"
                  :key="action.text"
                >
                  <a
                    v-if="action.type == 'userRoleOnlyConditionalIconButton' && checkUserRoleOnlyConditionalButton(action.condition, action.conditionParams)"
                    @click="handleClick(action, {data: item})"
                  >
                    <v-icon
                      :color="action.color"
                      small
                    >
                      {{ action.icon }}
                    </v-icon>
                  </a>
                </div>
              </template>
            </v-data-table>
          </div>
        </template>
        <template
          v-if="footer"
          #footer
        >
          <div
            v-for="(foot, index) of footer"
            :key="index"
          >
            <strong>
              {{ foot.title }}
            </strong>
            {{ foot.value }}
          </div>
        </template>
      </DataTable>
    </div>
    <Paginator
      :rows="pagination.itemsPerPage"
      :class="(isDarkMode ? 'theme--dark' : '')"
      :total-records="totalRecords"
      template="RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink"
      current-page-report-template="{first} - {last} of {totalRecords}"
      :rows-per-page-options="rowsPerPageOptions"
      @page="onPage($event)"
    >
      <template #start />
      <template #end>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon
                class="fas fa-cog"
                @click="openSettings"
              />
            </span>
          </template>
          <span>Column Setup</span>
        </v-tooltip>
      </template>
    </Paginator>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Paginator from 'primevue/paginator';
import TextHighlight from 'vue-text-highlight';
import TableSettings from './TableSettings.vue';

export default {
  name: 'PrimeDataTable',

  components: {
    DataTable,
    'text-highlight': TextHighlight,
    Column,
    TableSettings,
    Button,
    Paginator,
  },

  filters: {
    uppercase(item) {
      if (item) {
        return item.toUpperCase();
      }
      return item;
    },
  },

  props: {
    table: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    defaultHeaders: {
      type: Array,
      required: true,
    },
    footer: {
      type: Array,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    rowsPerPageOptions: {
      type: Array,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    searchHighlight: {
      type: String,
      required: false,
    },
    expander: {
      type: Boolean,
      default: false,
      required: false,
    },
    expansionDisplay: {
      type: String,
      default: '',
      required: false,
    },
    expansionDataTableHeaders: {
      type: Array,
      required: false,
    },
    expansionItems: {
      type: String,
      default: '',
      required: false,
    },
    rowClassCheckFrom: {
      type: String,
      default: '',
      required: false,
    },
    rowClassCheckAgainst: {
      type: Number,
      default: 0,
      required: false,
    },
    rowClassName: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: () => ({
    componentKey: 0,
    selectedHeaders: [],
    sessionSettings: null,
    forceColumns: false,
    defaultTableWidth: 0,
    tableSettingsKey: 0,
    defaultHeadersWithPixels: [],
    columnResizeIndex: null,
    columnResizeEvent: false,
    columnReorderEvent: false,
    tableSettingsEvent: false,
    defaultActionColumnPixelWidth: 0,
    defaultActionColumnPercentageWidth: 0,
    expandedRows: null,
    reRenderFlag: false,

    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAccountant: 24,
    userRoleIdHR: 25,
  }),
  computed: {
    getTableWidth() {
      return { width: `${(this.defaultTableWidth)}px !important` };
    },
    ...mapState({
      isDarkMode: 'isDarkMode',
      user: (state) => state.user,
    }),
    displayedSelectedHeaders() {
      return this.selectedHeaders.filter((item) => item.value !== 'expander');
    },
  },

  watch: {
    headers: {
      handler(newHeaders, oldHeaders) {
      // this watcher is necessary if you use the same PrimeDataTable component but dynamically change the headers
      // check if all headers are the same, if yes, call table-reset, else don't do anything
        if (oldHeaders !== undefined && oldHeaders.length !== 0) { // the watcher does two things 1. if headers have changed, call tableReset 2. when you refresh the page, the oldHeaders are initially [] but will get reinitialized, so we don't want to do anything first but now the question is, why is it initially [] while in ProjectsView, it's initialized to the defaultHeaders...
          const res = this.areHeadersEqual(newHeaders, oldHeaders);
          if (!res) {
            this.tableReset();
          }
        }
      },
    },
  },

  updated() {
    this.$nextTick(async () => {
      // Code that will run only after the entire view has been re-rendered
      if (this.columnReorderEvent) {
        this.columnReorderEvent = false;
        this.sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      }
      this.forceNewColumns();
    });
  },

  created() {
    this.roles = this.user.roles.map((x) => x.id);
    if (this.expander) {
      this.expandedRows = [];
    }
  },

  mounted() {
    const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
    if (sessionSettings && sessionSettings.columnWidths) {
      this.sessionSettings = sessionSettings;
    } else {
      this.tableReset();
      this.sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
    }
    this.$nextTick(() => {
      this.defaultTableWidth = this.$refs.dataTable.clientWidth; // needs to be in this.$nextTick() otherwise this.$refs.dataTable.clientWidth will be off
      // console.log('this.refs: ', this.$refs);
      // console.log('this.refs clientWidth: ', this.$refs.dataTable.clientWidth);
      // console.log('this.refs offsetWidth: ', this.$refs.dataTable.offsetWidth);
      sessionStorage.setItem(this.table, JSON.stringify(this.sessionSettings));
      this.init();
      this.setDefaultActionColumnWidths();
      if (this.reRenderFlag) {
        this.reRenderFlag = false;
        this.forceRerender();
      }
    });
  },

  methods: {
    async forceNewColumns() {
      if (this.sessionSettings) {
        sessionStorage.setItem(this.table, JSON.stringify(this.sessionSettings));
      }
      if (this.columnResizeEvent) {
        this.columnResizeEvent = false;
        await this.init();
        this.forceRerender();
      }
      if (this.tableSettingsEvent) {
        this.tableSettingsEvent = false;
        await this.init();
        this.forceRerender();
      }
      this.setHeaderWidthPixels();
    },

    onColResize(event) {
      this.columnResizeIndex = event.element.cellIndex;
      this.columnResizeEvent = true;
      this.tableSettingsEvent = false;
      const changedColumnWidthIndex = event.element.cellIndex;
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table)); // unaltered session storage
      const parsedColumnWidths = sessionSettings.columnWidths.split(',').map(Number); // unaltered column widths
      const startingTableWidth = parseInt(sessionSettings.tableWidth, 10);
      let newColumnWidth = parsedColumnWidths[changedColumnWidthIndex] + Math.round(event.delta); // the column we just resized + the change in width to get the new width
      let sum = 0; // sum of all the columns except for the one we changed
      // parsedColumnWidths.forEach((columnWidth, index) => {
      //   if (index !== changedColumnWidthIndex) {
      //     sum += columnWidth;
      //   }
      // });
      for (let i = 0; i < this.selectedHeaders.length; i++) {
        if (i !== changedColumnWidthIndex) {
          sum += parsedColumnWidths[i];
        }
      }
      let newTableWidth = sum + newColumnWidth; // new table width after resizing the column
      const newColumnWidths = [...parsedColumnWidths];
      if (newColumnWidths.length === 1) {
        newColumnWidths[0] = this.defaultTableWidth;
        newTableWidth = this.defaultTableWidth;
      } else if (newTableWidth < this.defaultTableWidth) { // if new table width is less than default table with we need to expand actions column
        if (parsedColumnWidths.length >= 2 && changedColumnWidthIndex === parsedColumnWidths.length - 1) {
          // if we have at least 2 columns and we're reducing the size of the actions column
          if (newColumnWidth < this.defaultActionColumnPixelWidth && startingTableWidth > this.defaultTableWidth) {
            newTableWidth = this.defaultTableWidth;
            newColumnWidth = this.defaultActionColumnPixelWidth;
            newColumnWidths[parsedColumnWidths.length - 1] = newColumnWidth;
          } else if (newColumnWidth < this.defaultActionColumnPixelWidth && startingTableWidth <= this.defaultTableWidth) {
            const diff = this.defaultActionColumnPixelWidth - newColumnWidth;
            newColumnWidth += diff;
            newColumnWidths[parsedColumnWidths.length - 2] -= (diff + Math.round(event.delta));
            newColumnWidths[parsedColumnWidths.length - 1] = newColumnWidth;
          } else {
            newColumnWidths[parsedColumnWidths.length - 2] -= Math.round(event.delta);
            newColumnWidths[parsedColumnWidths.length - 1] += Math.round(event.delta);
          }
          newTableWidth = this.defaultTableWidth;
        } else {
          const tableWidthDiff = this.defaultTableWidth - newTableWidth;
          const newActionColumnWidth = parsedColumnWidths[parsedColumnWidths.length - 1] + tableWidthDiff;
          newColumnWidths[parsedColumnWidths.length - 1] = newActionColumnWidth;
          newColumnWidths[changedColumnWidthIndex] = newColumnWidth;
          newTableWidth += tableWidthDiff; // since newTableWidth has decreased, we need to increase it because we also increased actions column
        }
      } else { // else we just need to change the new column width
        // if we're reducing the size of the action's column
        if (parsedColumnWidths.length >= 2 && changedColumnWidthIndex !== parsedColumnWidths.length - 1 && event.delta > 0) {
          if (parsedColumnWidths[parsedColumnWidths.length - 1] === this.defaultActionColumnPixelWidth) {
            // do nothing??
          } else {
            // actionsColumn is greater than defaultActionColumnPixelWidth
            const actionColumnWidth = parsedColumnWidths[parsedColumnWidths.length - 1];
            const newActionColumnWidth = actionColumnWidth - Math.round(event.delta);
            if (newActionColumnWidth < this.defaultActionColumnPixelWidth) {
              // const diff = this.defaultActionColumnPixelWidth - newActionColumnWidth;
              // newActionColumnWidth += diff;
              // newColumnWidth -= diff;
              // newTableWidth -= diff;
              // newColumnWidths[parsedColumnWidths.length - 1] = newActionColumnWidth;
              const diff = actionColumnWidth - this.defaultActionColumnPixelWidth;
              // newColumnWidth -= diff;
              newTableWidth -= diff;
              newColumnWidths[parsedColumnWidths.length - 1] = this.defaultActionColumnPixelWidth;
              newColumnWidths[changedColumnWidthIndex] = newColumnWidth;
            } else {
              //
              newColumnWidths[parsedColumnWidths.length - 1] = newActionColumnWidth;
              newColumnWidths[changedColumnWidthIndex] = newColumnWidth;
              newTableWidth -= Math.round(event.delta);
            }
          }
        } else if (parsedColumnWidths.length >= 2 && changedColumnWidthIndex === parsedColumnWidths.length - 1 && event.delta < 0) {
          const actionColumnWidth = parsedColumnWidths[parsedColumnWidths.length - 1];
          const newActionColumnWidth = actionColumnWidth + Math.round(event.delta);
          if (newActionColumnWidth < this.defaultActionColumnPixelWidth) {
            newTableWidth -= Math.round(event.delta);
            newColumnWidth = this.defaultActionColumnPixelWidth;
          }
        }
        newColumnWidths[changedColumnWidthIndex] = newColumnWidth;
      }
      sessionSettings.tableWidth = `${newTableWidth}px`;
      sessionSettings.columnWidths = newColumnWidths.join(',');
      this.sessionSettings = sessionSettings;
      this.forceRerender();
    },

    setHeaderWidthPixels() {
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (this.headers.length > 0 && this.selectedHeaders.length > 0 && sessionSettings && sessionSettings.columnWidths) {
        const parsedColumnWidths = sessionSettings.columnWidths.split(',').map(Number);
        this.headers.forEach((header, index) => {
          header.headerStyle.pixelWidth = parsedColumnWidths[index];
        });
        this.selectedHeaders.forEach((header, index) => {
          header.headerStyle.pixelWidth = parsedColumnWidths[index];
        });
      }
    },
    setHeaderWidthPercentages() {
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (this.headers.length > 0 && this.selectedHeaders.length > 0 && sessionSettings && sessionSettings.columnWidths && sessionSettings.tableWidth) {
        const parsedColumnWidths = sessionSettings.columnWidths.split(',').map(Number);
        this.headers.forEach((header, index) => {
          const newWidth = parsedColumnWidths[index] / this.defaultTableWidth;
          header.headerStyle.width = `${newWidth * 100}%`;
        });
        this.selectedHeaders.forEach((header, index) => {
          const newWidth = parsedColumnWidths[index] / this.defaultTableWidth;
          header.headerStyle.width = `${newWidth * 100}%`;
        });
      }
    },
    async init() {
      // console.log('init: this.refs: ', this.$refs);
      // console.log('init: this.refs clientWidth: ', this.$refs.dataTable.clientWidth);
      // console.log('init: this.refs offsetWidth: ', this.$refs.dataTable.offsetWidth);
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (this.$refs.dataTable.clientWidth !== this.defaultTableWidth) { // for some reason, this.$refs.dataTable.clientWidth changes when going from mounted() to init()
        this.defaultTableWidth = this.$refs.dataTable.clientWidth;
        sessionSettings.tableWidth = `${this.defaultTableWidth}px`;
        sessionStorage.setItem(this.table, JSON.stringify(this.sessionSettings));
        this.reRenderFlag = true;
      }
      if (sessionSettings === null || parseInt(sessionSettings.tableWidth, 10) === 0) {
        this.sessionSettings = null;
      } else {
        this.sessionSettings = sessionSettings;
      }
      if (sessionSettings && sessionSettings.columnOrder) {
        const newHeaders = [];
        sessionSettings.columnOrder.forEach((header) => {
          const headerIndex = this.headers.findIndex((h) => h.value === header);
          newHeaders.push(this.headers[headerIndex]);
        });
        this.selectedHeaders = newHeaders;
        this.setHeaderWidthPixels();
        this.setHeaderWidthPercentages();
        await this.$emit('syncHeadersOrder', this.selectedHeaders);
      } else if (this.sessionSettings === null) {
        this.selectedHeaders = JSON.parse(JSON.stringify(this.headers));
        this.initSessionSettings();
        this.setHeaderWidthPixels();
        this.setHeaderWidthPercentages();
      } else {
        this.selectedHeaders = JSON.parse(JSON.stringify(this.headers));
        this.setHeaderWidthPixels();
        this.setHeaderWidthPercentages();
        // await this.$emit('syncHeadersOrder', this.selectedHeaders);
      }
      this.setDefaultHeadersWithPixels();
    },
    initSessionSettings() {
      const columnWidths = [];
      const columnOrder = [];
      this.headers.forEach((header) => {
        const percentage = parseInt(header.headerStyle.width, 10);
        const pixels = Math.round((percentage / 100) * this.defaultTableWidth);
        columnWidths.push(pixels);
        columnOrder.push(header.value);
      });
      const sessionSettings = {};
      sessionSettings.columnWidths = columnWidths.join(',');
      sessionSettings.tableWidth = `${this.defaultTableWidth}px`;
      sessionSettings.columnOrder = columnOrder;
      sessionSettings.multiSortMeta = [];
      sessionStorage.removeItem(this.table);
      sessionStorage.setItem(this.table, JSON.stringify(sessionSettings));
      this.sessionSettings = sessionSettings;
    },
    setDefaultHeadersWithPixels() {
      this.defaultHeadersWithPixels = JSON.parse(JSON.stringify(this.defaultHeaders));
      this.defaultHeadersWithPixels.forEach((header, index) => {
        const defaultHeaderPercentage = parseInt(this.defaultHeaders[index].headerStyle.width, 10);
        header.headerStyle.pixelWidth = Math.round((defaultHeaderPercentage / 100) * this.defaultTableWidth);
      });
    },
    setDefaultActionColumnWidths() {
      const actionsColumn = this.defaultHeaders.find((column) => column.value === 'actions');
      // If object is found, return the value of the key
      if (actionsColumn) {
        const defaultActionColumnPercentageWidth = parseInt(actionsColumn.headerStyle.width, 10);
        this.defaultActionColumnPercentageWidth = defaultActionColumnPercentageWidth;
        this.defaultActionColumnPixelWidth = Math.round((defaultActionColumnPercentageWidth / 100) * this.defaultTableWidth);
      } else {
        this.defaultActionColumnPercentageWidth = 0;
        this.defaultActionColumnPixelWidth = 0;
      }
    },
    openSettings() {
      this.$refs.tableSettings.open();
    },
    async saveSettings() {
      this.$refs.tableSettings.syncHeaders();
    },

    async tableSave(newHeaders) {
      this.tableSettingsEvent = true;
      this.columnResizeEvent = false;
      this.selectedHeaders = JSON.parse(JSON.stringify(newHeaders));
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      const newColumnOrder = [];
      const newColumnWidths = [];
      let newTableWidth = 0;
      newHeaders.forEach((header) => {
        newColumnOrder.push(header.value);
        newColumnWidths.push(parseInt(header.headerStyle.pixelWidth, 10));
        newTableWidth += parseInt(header.headerStyle.pixelWidth, 10);
      });
      const newColumnWidthsLength = newColumnWidths.length;
      if (newTableWidth < this.defaultTableWidth) {
        const tableWidthDiff = this.defaultTableWidth - newTableWidth;
        newColumnWidths[newColumnWidthsLength - 1] += tableWidthDiff;
        sessionSettings.columnWidths = newColumnWidths.join(',');
      } else {
        sessionSettings.columnWidths = newColumnWidths.join(',');
      }
      newTableWidth = 0;
      for (let i = 0; i < newColumnWidthsLength; i++) {
        newTableWidth += newColumnWidths[i];
      }
      sessionSettings.columnOrder = newColumnOrder;
      sessionSettings.tableWidth = `${newTableWidth}px`;
      this.sessionSettings = sessionSettings;
      await this.saveSettings(JSON.parse(JSON.stringify(newHeaders)));
      await this.$emit('syncHeadersOrder', JSON.parse(JSON.stringify(newHeaders)));
      this.forceRerender();
    },
    async tableReset() {
      this.selectedHeaders = JSON.parse(JSON.stringify(this.defaultHeaders));
      await this.saveSettings(this.defaultHeaders);
      await this.$emit('resetHeaders');
      const defaultHeaders = [];
      this.defaultHeaders.forEach((header) => {
        defaultHeaders.push(header.value);
      });
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (sessionSettings.columnOrder) sessionSettings.columnOrder = defaultHeaders;
      if (this.defaultTableWidth) sessionSettings.tableWidth = `${this.defaultTableWidth}px`;
      sessionStorage.removeItem(this.table);
      sessionStorage.setItem(this.table, JSON.stringify(sessionSettings));
      this.sessionSettings = null;
      this.forceRerender();
    },
    updateHeaderWidthPercentage(headers) {
      const newHeaders = JSON.parse(JSON.stringify(headers));
      let newTableWidth = 0;
      newHeaders.forEach((header) => {
        newTableWidth += parseInt(header.headerStyle.pixelWidth, 10);
      });
      newHeaders.forEach((header) => {
        const newPixelWidth = parseInt(header.headerStyle.pixelWidth, 10);
        const newWidth = newPixelWidth / newTableWidth;
        header.headerStyle.width = `${newWidth * 100}%`;
      });
      return newHeaders;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceTableSettingsRerender() {
      this.tableSettingsKey += 1;
    },
    onColReorder(event) {
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (event.dropIndex >= this.selectedHeaders.length) {
        // don't think this condition will ever be true
        let check = event.dropIndex - this.selectedHeaders.length + 1;
        while (check--) {
          this.selectedHeaders.push(undefined);
        }
      }
      if (event.dropIndex === this.selectedHeaders.length - 1) {
        this.sessionSettings = sessionSettings;
        this.forceColumns = true;
        this.forceRerender();
      } else {
        this.columnReorderEvent = true;
        const elementAtDragIndex = this.selectedHeaders.splice(event.dragIndex, 1)[0];
        // re-order columnWidths
        this.selectedHeaders.splice(
          event.dropIndex,
          0,
          elementAtDragIndex,
        );
        this.saveSettings(this.selectedHeaders);
        this.$emit('syncHeadersOrder', this.selectedHeaders);
      }
    },
    handleClick(action, props) {
      this.$emit(action.emit, props.data);
    },
    onPage(event) {
      const pagination = { ...this.pagination };
      if (event.rows) pagination.itemsPerPage = event.rows;
      if (event.page || event.page === 0) pagination.page = event.page + 1;
      if (event.sortField) {
        pagination.sortBy = event.sortField;
        pagination.descending = event.sortOrder < 0;
      }
      this.$emit('paginationUpdated', pagination);
    },
    checkCondition(data, checkFrom, check, condition, params) {
      if (!data || !checkFrom || !check || !condition || !params) return false;
      if (checkFrom === 'slotProps') {
        const paramChecks = [];
        switch (condition) {
          case 'equals':
            params.forEach((param) => {
              const result = data.data[check] === param;
              paramChecks.push(result);
            });
            break;
          case 'not-equals':
            params.forEach((param) => {
              const result = data.data[check] !== param;
              paramChecks.push(result);
            });
            break;
          default:
            return false;
        }
        if (paramChecks.includes(true)) return true;
        return false;
      }
      if (checkFrom === 'action') {
        const paramChecks = [];
        switch (condition) {
          case 'equals':
            params.forEach((param) => {
              const result = check === param;
              paramChecks.push(result);
            });
            break;
          case 'not-equals':
            params.forEach((param) => {
              const result = check !== param;
              paramChecks.push(result);
            });
            break;
          default:
            return false;
        }
        if (paramChecks.includes(true)) return true;
        return false;
      }
      return true;
    },

    // Data is arranged like this:
    // slotProps: {
    //   data: {
    //     // data from row in the table
    //   }
    // }

    // checkUserRoleConditionalButton(data, checkFrom, checks, condition, conditionParams) {
    //   if (!data || !checkFrom || !checks || !condition || !conditionParams) return false;
    //   if (checkFrom === 'slotProps') {
    //     // initialize paramChecks
    //     const paramChecks = {};
    //     checks.forEach((check) => {
    //       paramChecks[check] = [];
    //     });

    //     // check userRole
    //     if (checks.includes('user_role_id')) {
    //       // eslint-disable-next-line dot-notation
    //       const userCheckConditionParams = conditionParams['user_role_id'];
    //       userCheckConditionParams.forEach((check) => {
    //         console.log('roles: ', this.roles);
    //         const result = this.roles.includes(check);
    //         // eslint-disable-next-line dot-notation
    //         paramChecks['user_role_id'].push(result);
    //       });
    //     }

    //     // check rest of the checks
    //     switch (condition) {
    //       case 'equals':
    //         checks.forEach((check) => {
    //           if (check !== 'user_role_id') {
    //             const checkConditionParams = conditionParams[check];
    //             checkConditionParams.forEach((param) => {
    //               const result = data.data[check] === param;
    //               paramChecks[check].push(result);
    //             });
    //           }
    //         });
    //         break;
    //       default:
    //         return false;
    //     }

    //     // iterate through paramCheck object and if each array in paramCheck includes a true then return true
    //     let conditionsSatisfied = true;
    //     Object.keys(paramChecks).forEach((key) => {
    //       // console.log(key + " -> " + obj[key]);
    //       if (!paramChecks[key].includes(true)) {
    //         conditionsSatisfied = false;
    //       }
    //     });
    //     console.log('paramChecks: ', paramChecks);
    //     return conditionsSatisfied;
    //   }
    //   return true;
    // },

    checkUserRoleConditionalButton(data, checkFrom, check, condition, conditionParams) {
      if (!data || !checkFrom || !check || !condition || !conditionParams) return false;
      if (checkFrom === 'slotProps') {
        let flag = false;
        // O(n^3) - will refactor later but practically n shouldn't be that big I hope
        this.roles.forEach((role) => {
          conditionParams.forEach((conditionParam) => {
            if (role === conditionParam.user_role_id) {
              conditionParam[check].forEach((status) => {
                if (data.data[check] === status) {
                  flag = true;
                }
              });
            }
          });
        });
        return flag;
      }
      return false;
    },

    checkUserRoleOnlyConditionalButton(condition, conditionParams) {
      if (!condition || !conditionParams) return false;
      let flag = false;
      this.roles.forEach((role) => {
        conditionParams.forEach((conditionParam) => {
          if (conditionParam === role) {
            flag = true;
          }
        });
      });
      return flag;
    },

    rowClass(rowData) {
    // only supports .no-expansion
      const className = rowData[this.rowClassCheckFrom] === this.rowClassCheckAgainst ? '' : `${this.rowClassName}`;

      return className;
    },

    searchObject(obj, path) {
      if (obj === null || path === '' || path === null) return null;
      const keys = path.split('.'); // Split the string by '.'
      let value = obj;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        if (value[key]) {
          value = value[key];
        } else {
          return null;
        }
      }

      return value;
    },

    areHeadersEqual(header1, header2) {
      if (header1.length !== header2.length) {
        return false;
      }

      const sortedHeader1 = header1.slice().sort((a, b) => a.value.localeCompare(b.value));
      const sortedHeader2 = header2.slice().sort((a, b) => a.value.localeCompare(b.value));

      for (let i = 0; i < sortedHeader1.length; i++) {
        if (sortedHeader1[i].value !== sortedHeader2[i].value) {
          return false;
        }
      }

      return true;
    },

    onRowExpand(event) {
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (sessionSettings) {
        if (!sessionSettings.expandedRows) {
          sessionSettings.expandedRows = [];
        }
        sessionSettings.expandedRows.push(event.data);
      }
      this.sessionSettings = sessionSettings;
      sessionStorage.setItem(this.table, JSON.stringify(sessionSettings));
    },

    onRowCollapse(event) {
      const sessionSettings = JSON.parse(sessionStorage.getItem(this.table));
      if (sessionSettings && sessionSettings.expandedRows) {
        const newExpandedRows = sessionSettings.expandedRows.filter((obj) => obj.id !== event.data.id);
        sessionSettings.expandedRows = newExpandedRows;
      }
      this.sessionSettings = sessionSettings;
      sessionStorage.setItem(this.table, JSON.stringify(sessionSettings));
    },

  },
};
</script>
<style scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #5f5f5f;
  height: 27px !important;
}
/deep/tr.no-expansion > td > .p-row-toggler {
 display: none !important;
}
.text {
  height: 24px;
  line-height: 24px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.white-chip {
  height: 24px;
  color: white !important;
  font-size: 95%;
  /* font-weight: bold; */
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.RouterLinkButton {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  min-width: 50px !important;
  cursor: pointer;
  padding: 0.4rem 0.75rem 0.4rem 0.75rem !important;
  border: 1px solid #4caf50;
  text-decoration: none;
  color: #4caf50 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.RouterLinkButton:hover {
  background-color: #4caf50 !important;
  color: white !important;
}
.RouterLinkText {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.375rem 0.75rem 0.4rem 0.75rem !important;
  text-decoration: none !important;
  font-family: 'Segoe UI', Helvetica, Arial, sans-serif !important;
  font-size: 113% !important;
  color: #0b7ad1 !important;
}
.ban-button {
  width: 74px !important;
  justify-content: center !important;
}
.icon-button {
  color: #4caf50 !important;
  height: 19px !important;
  width: 19px !important;
}

.p-button-sm {
  height: 19px !important;
  font-size: 80% !important;
  font-weight: bold !important;
  background-color: #4caf50;
  border-color: #4caf50;
  border-radius: 0px !important;
  color: white;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.p-button-sm:hover {
  background-color: white;
  border-color: #4caf50;
  color: #4caf50;
}
.p-button.p-component {
  margin-top: 5px;
  margin-bottom: 5px;
}
/* Sticky header not working because ancestor has overflow: auto but removing overflow: auto removes the table overflow functionality that's crucial */
/* /deep/.p-datatable-thead {
  position: sticky;
  z-index: 100;
  top: 64px;
} */
/* /deep/.p-datatable-resizable > .p-datatable-wrapper {
  overflow-y: visible !important;
  overflow-x: scroll;
} */
/deep/.p-paginator-right-content > span > .v-icon {
  margin-bottom: 1px;
}
/deep/.p-paginator-right-content {
  margin-left: 8px;
}
/deep/.p-paginator.theme--dark .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ffffff;
  color: #495057;
}
/deep/.p-paginator.p-component.theme--dark {
  background: #121212;
  color: rgba(255, 255, 255, 0.7);
}
/deep/.p-datatable.p-component.theme--dark > .p-datatable-wrapper {
  background: #121212;
}
/deep/.p-paginator.p-component.theme--dark > span {
  color: rgba(255, 255, 255, 0.7);
}
/deep/.p-paginator.p-component.theme--dark > span > button {
  color: rgba(255, 255, 255, 0.7);
}
/deep/.p-paginator.p-component.theme--dark > button {
  color: rgba(255, 255, 255, 0.7);
}
/deep/.p-dropdown {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ced4da;
}
/deep/.p-paginator.p-component.theme--dark > .p-dropdown.p-component.p-inputwrapper > .p-dropdown-panel > .p-dropdown-items-wrapper {
  background: #121212;
}
/deep/.p-paginator.p-component.theme--dark > .p-dropdown.p-component.p-inputwrapper > .p-dropdown-panel > .p-dropdown-items-wrapper > ul > li {
  color: rgba(255, 255, 255, 0.7);
}
/deep/.p-paginator.p-component.theme--dark > .p-dropdown.p-component.p-inputwrapper > .p-dropdown-panel > .p-dropdown-items-wrapper > ul > li.p-highlight {
  background: #ffffff;
  color: #495057;
}
/deep/.p-paginator.p-component.theme--dark > .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled{
  background: #121212;
}
/deep/.p-paginator.p-component.theme--dark > .p-dropdown.p-component > .p-dropdown-label{
  color: rgba(255, 255, 255, 0.7);
}
/deep/.p-datatable .p-datatable-thead > tr > th{
  background: #323232;
  color: white;
  border: thin solid rgba(255, 255, 255, 0.12);
}
/deep/.p-datatable.p-datatable-sm.theme--dark .p-datatable-tbody > tr > td {
  background: #1e1e1e;
  color: white;
}
/deep/.p-datatable.p-datatable-gridlines.theme--dark .p-datatable-tbody > tr > td {
  border: thin solid rgba(255, 255, 255, 0.12);
}
/* Hide the default PrimeIcons styles */
/deep/.pi-chevron-right::before {
  display: none !important;
}
/deep/.pi-chevron-right::after {
  content: "\f054" !important; /* Unicode character for Font Awesome chevron-down */
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.pi-chevron-down::before {
  display: none !important;
}
/deep/.pi-chevron-down::after {
  content: "\f107" !important; /* Unicode character for Font Awesome chevron-down */
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.pi-sort-alt::before {
  display: none !important;
}
/deep/.pi-sort-alt::after {
  content: "\f338" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.pi-sort-amount-down::before {
  display: none !important;
}
/deep/.pi-sort-amount-down::after {
  content: "\f160" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.pi-sort-amount-up-alt::before {
  display: none !important;
}
/deep/.pi-sort-amount-up-alt::after {
  content: "\f885" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.p-paginator-icon.pi.pi-angle-right::before {
  display: none !important;
}
/deep/.p-paginator-icon.pi.pi-angle-right::after {
  content: "\f105" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.p-paginator-icon.pi.pi-angle-left::before {
  display: none !important;
}
/deep/.p-paginator-icon.pi.pi-angle-left::after {
  content: "\f104" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.p-paginator-icon.pi.pi-angle-double-right::before {
  display: none !important;
}
/deep/.p-paginator-icon.pi.pi-angle-double-right::after {
  content: "\f101" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.p-paginator-icon.pi.pi-angle-double-left::before {
  display: none !important;
}
/deep/.p-paginator-icon.pi.pi-angle-double-left::after {
  content: "\f100" !important;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
}
/deep/.centered-column {
  text-align: center !important;
}
/deep/.bold-text {
  font-weight: bold !important;
}
/deep/.actions-column {
  display: flex;
  justify-content: flex-end;
}
/deep/.conditional-actions-column {
  display: flex;
  justify-content: flex-end;
}
/deep/.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  height: 12px !important;
  line-height: 7px !important;
  padding: 0px 2px 0px 2px !important;
  font-size: 14px !important;
  overflow: hidden !important;
  text-overflow: clip !important;
  white-space: nowrap !important;
}
/deep/th.p-resizable-column {
  white-space: pre-line !important;
  padding: 2px 5px 2px 5px !important;
}
.copy-icon {
  font-size: 10px !important;
}
/deep/div.p-datatable-wrapper:nth-child(1)
  > table:nth-child(1)
  > tbody:nth-child(2) {
  height: 20px !important;
  line-height: 20px !important;
}
/deep/.p-datatable-sm
  > div:nth-child(2)
  > table:nth-child(1)
  > tbody:nth-child(2)
  > tr:nth-child(1) {
  height: 20px !important;
  line-height: 20px !important;
}
/deep/.p-datatable-sm {
  top: 24px;
}
/deep/div.p-datatable-wrapper:nth-child(1)
  > table:nth-child(1)
  > tbody:nth-child(2)
  > tr:nth-child(17)
  > td:nth-child(8)
  > div:nth-child(2)
  > span:nth-child(1) {
  padding-bottom: 0%;
  margin-bottom: 0%;
}
/deep/.p-datatable-footer {
  display: flex;
  justify-content: space-between;
}
/deep/.p-paginator.p-component {
  position: relative;
  top: 24px;
}
/deep/#table {
  width: 100% !important;
}
/deep/.p-frozen-column {
  position: sticky;
  right: 0;
  background-color: #FAFAFA;
}
/deep/td:has(> .frozen) {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  z-index: 1;
  background-color: #FAFAFA;
}
/deep/.v-chip__content {
  position: relative;
  top: -.5px;
}
</style>
