/* eslint-disable no-alert */
<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          outlined

          @click="physCountAdjust"
        >
          Adjust Inventory with Physical Count Qty
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          outlined

          @click="openPhysicalCountForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Physical Count
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            ref="pcTable"
            :headers="headers"
            :items="physicalCountLists"
            :footer-props="itemsTableFooterProps"
            :items-per-page="itemsPerPageProps"
            :loading="loading"
          >
            <template #[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template #[`item.inventory_class`]="{ item }">
              <v-row v-if="item.classes && item.classes.length === 11">
                <v-col
                  cols="auto"
                  class="pr-0"
                >
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    color="#000000"
                  >
                    ALL
                  </v-chip>
                </v-col>
              </v-row>
              <v-tooltip
                v-else-if="item.classes && item.classes.length > 2"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    style="color: #1976d2;"
                    v-on="on"
                  >CLASSES</span>
                </template>
                <v-row>
                  <v-col class="text-center pt-6 pb-6">
                    <v-row
                      v-for="inventroyClass in item.classes"
                      :key="inventroyClass.id"
                    >
                      <v-col
                        cols="auto"
                        class="pr-1 pb-1 pt-1"
                      >
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="inventroyClass ? inventroyClass.color : 'white'"
                        >
                          {{ inventroyClass ? inventroyClass.name : '' }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
              <v-row v-else>
                <v-col
                  v-for="inventroyClass in item.classes"
                  :key="inventroyClass.id"
                  cols="auto"
                  class="pr-0"
                >
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="inventroyClass ? inventroyClass.color : 'white'"
                  >
                    {{ inventroyClass ? inventroyClass.name : '' }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>

            <template #[`item.created_at`]="{ item }">
              {{ item.created_at }}
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ `${item.user.first_name} ${item.user.last_name}` }}
            </template>
            <template #[`item.activated`]="{ item }">
              <v-chip
                v-if="item.is_active"
                color="#bf0000"

                text-color="white"
                class="mr-10 mt-1 mb-1"
              >
                ACTIVATED
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="!item.is_active"
                class="ml-1 mt-1 mb-1"
                color="success"
                outlined
                small
                @click="activate(item)"
              >
                ACTIVATE
              </v-btn>

              <v-btn

                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="openPhysicalCountTable(item)"
              >
                VIEW
              </v-btn>
              <v-btn

                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="editForm(item)"
              >
                EDIT
              </v-btn>
              <v-btn

                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <physical-count-list-form
      ref="listForm"
      :physical-count-prop="physicalCount"
      @list-form-success="listFormSuccess"
    />
    <physical-count-table-form
      ref="physCountTableForm"
      :physical-count-list="physicalCountList"
    />
    <physical-count-adjustment-confirm-form ref="adjustForm" />
    <Snackbar ref="snackbar" />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import PhysicalCountListApi from '@/api/PhysicalCountListApi';
import PhysicalCountListForm from '@/components/forms/physicalCounts/PhysicalCountListForm';
import PhysicalCountTableForm from '@/components/forms/physicalCounts/PhysicalCountTableForm';
import PhysicalCountAdjustmentConfirmForm from '@/components/forms/physicalCounts/PhysicalCountAdjustmentConfirmForm';
import Snackbar from '@/components/Snackbar';

export default {
  components: {
    PhysicalCountListForm,
    PhysicalCountTableForm,
    PhysicalCountAdjustmentConfirmForm,
    Snackbar,
  },
  data: () => ({
    loading: false,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    headers: [
      { text: 'Physical Count Name', value: 'name', width: '20%' },
      { text: 'Classes', value: 'inventory_class', width: '20%' },
      { text: 'Date', value: 'date', width: '10%' },
      { text: 'Created By', value: 'created_by', width: '10%' },
      { text: '', value: 'activated', width: '5%' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '30%',
      },

    ],
    itemsPerPageProps: 20,
    physicalCountLists: [],
    physicalCount: null,
    activePhysicalCount: null,
    physicalCountList: null,

  }),
  watch: {
    physicalCountLists() {
      window.scrollTo(1000, 1000);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getPhysicalCountList();
    },

    async getPhysicalCountList() {
      this.loading = true;
      this.physicalCountLists = await PhysicalCountListApi.getList();
      this.activePhysicalCount = this.physicalCountLists.find((x) => x.is_active === 1);
      this.loading = false;
    },

    async activate(item) {
      if (!item) return;

      let confirmMsg = '';

      if (this.physicalCount) { confirmMsg = `Are you sure that you want to activate this Physical Count "${item.name}"? This will deactivate the current Physical Count "${this.physicalCount.name}".`; } else { confirmMsg = `Are you sure that you want to activate this Physical Count "${item.name}"?`; }

      const confirm = await this.$refs.confirmForm.open({
        title: confirmMsg,
      });
      if (!confirm) return;
      const response = await PhysicalCountListApi.activate(item.id);
      if (!response) {
        this.$refs.snackbar.showError();
        return;
      }

      this.$refs.snackbar.showSuccess(`Physical Count "${item.name}" has been Activated`);
      this.$store.commit('storeActivatedPhysicalCount', item);
      this.getPhysicalCountList();
    },

    openPhysicalCountForm() {
      this.$refs.listForm.openPhysicalCountForm();
    },

    listFormSuccess(message) {
      this.$refs.snackbar.showSuccess(message);
      this.getPhysicalCountList();
    },

    editForm(item) {
      this.physicalCount = JSON.parse(JSON.stringify(item));
      this.$refs.listForm.openPhysicalCountForm();
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (item.is_active) {
        this.$refs.snackbar.showError('Active Physical Count cannot be deleted');
        return;
      }
      const response = await PhysicalCountListApi.delete(item.id);
      if (!response) {
        this.$refs.snackbar.showError();
        return;
      }

      this.getPhysicalCountList();
      this.$refs.snackbar.showSuccess('Physical Count has been deleted');
    },

    physCountAdjust() {
      if (!this.activePhysicalCount) return;
      this.$refs.adjustForm.open(this.activePhysicalCount);
    },

    openPhysicalCountTable(item) {
      this.physicalCountList = item;
      this.$refs.physCountTableForm.open();
    },

  },

};

</script>
