<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <span style="font-size: 20px; font-weight: 500;">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-0 pb-0">
          <v-row v-if="items.length > 0">
            <v-col>
              <v-row>
                <v-col>
                  <v-card-text style="max-height: 600px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
                    <v-list
                      two-line
                      class="pt-0 pb-0"
                    >
                      <template v-for="(timeOffRequestHistory, index) in items">
                        <v-list-item :key="timeOffRequestHistory.id">
                          <!-- Display each timeOffRequestHistory -->
                          <v-list-item-content>
                            <v-row class="px-4 pt-4">
                              <v-chip
                                v-if="timeOffRequestHistory && timeOffRequestHistory.from_time_off_status"
                                small
                                :color="timeOffRequestHistory && timeOffRequestHistory.from_time_off_status && timeOffRequestHistory.from_time_off_status.color ? timeOffRequestHistory.from_time_off_status.color : ''"
                                text-color="white"
                              >
                                {{ timeOffRequestHistory && timeOffRequestHistory.from_time_off_status && timeOffRequestHistory.from_time_off_status.name ? timeOffRequestHistory.from_time_off_status.name : '' }}
                              </v-chip>
                              <v-icon v-if="timeOffRequestHistory && timeOffRequestHistory.from_time_off_status">
                                mdi-arrow-right
                              </v-icon>
                              <v-chip
                                small
                                :color="timeOffRequestHistory && timeOffRequestHistory.to_time_off_status && timeOffRequestHistory.to_time_off_status.color ? timeOffRequestHistory.to_time_off_status.color : ''"
                                text-color="white"
                              >
                                {{ timeOffRequestHistory && timeOffRequestHistory.to_time_off_status && timeOffRequestHistory.to_time_off_status.name ? timeOffRequestHistory.to_time_off_status.name : 'NO STATUS' }}
                              </v-chip>
                            </v-row>
                            <v-list-item-subtitle style="padding-top: 10px;">
                              {{ timeOffRequestHistory.created_at ? timeOffRequestHistory.created_at : ''}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="timeOffRequestHistory.created_by_name">
                              {{ timeOffRequestHistory.created_by_name ? timeOffRequestHistory.created_by_name : '' }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider
                          v-if="index < items.length - 1"
                          :key="timeOffRequestHistory.id + '_' + index"
                        />
                      </template>
                    </v-list>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: 'Time Off Request Status History',
    },
  },
  data: () => ({
    items: [],
    dialog: false,
  }),
  created() {
  },
  methods: {

    async get(time_off_request_id) {
      const submitModel = {
        filters: {
          time_off_request_id: time_off_request_id,
        },
      };
      const res = await this.$api.timeOffRequestHistory.read(submitModel);
      if (!res) return;
      this.items = res.data;
    },

    open(time_off_request_id) {
      this.get(time_off_request_id);
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
::v-deep .v-card__text {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
