<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="6" />
      <v-col
        cols="6"
        class="text-right mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="openReasonForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Scrap Reason
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="reasons"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
            dense
          >
            <template #[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditReasonForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteReason(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <supply-to-line-reason-form
      ref="reasonForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import SupplyToLineReasonForm from '@/components/forms/supplyToLine/SupplyToLineReasonForm';

export default {
  components: {
    SupplyToLineReasonForm,
  },
  data: () => ({
    reasons: [],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    loading: false,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getReasons();
    },

    async getReasons() {
      this.loading = true;
      this.reasons = await this.$api.supplyToLineScrapReason.get();
      this.loading = false;
    },

    async deleteReason(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.supplyToLineScrapReason.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getReasons();
    },

    openReasonForm() {
      this.$refs.reasonForm.open();
    },

    openEditReasonForm(id) {
      this.$refs.reasonForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.getReasons();
    },
  },
};
</script>
