<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :persistent="formPersistent"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="formAlert"
              :type="formAlertType"
            >
              {{ formAlertText }}
            </v-alert>
            <v-container>
              <v-row v-show="!loading">
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="equipment"
                    :items="equipmentList"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Equipment is required']"
                    required
                    @change="fetchStatus"
                  >
                    <template slot="label">
                      Equipment<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="showHoursSpent"
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="hoursSpent"
                    type="number"
                    label="Time spent to fix (hours)"
                    placeholder="Please enter time spent (hours)"
                  />
                </v-col>

                <v-col
                  v-if="showJobFrom"
                  cols="12"
                  class="pb-0"
                >
                  <v-list-item-title>Current Job</v-list-item-title>
                  <v-list-item-subtitle v-if="jobFrom != ' '">
                    {{
                      jobFrom
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="jobFrom == ' '"
                  >
                    Warehouse
                  </v-list-item-subtitle>
                </v-col>
                <v-col
                  v-if="showJobs"
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="job"
                    :items="jobsList"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Job is required']"
                  >
                    <template slot="label">
                      {{ jobsTitle }}<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  v-if="showShelf"
                  cols="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="shelf"
                    :rules="[(v) => !!v || 'Shelf is required']"
                    required
                  >
                    <template slot="label">
                      Shelf<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  v-if="showCondition"
                  cols="4"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="condition"
                    :items="conditionsList"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Condition is required']"
                    @change="updateCondition(condition)"
                  >
                    <template slot="label">
                      Condition<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  v-if="showPhotoUpload"
                  :cols="photoUploadWidth"
                  class="pb-0"
                >
                  <v-file-input
                    v-model="photo"
                    accept="image/*"
                    label="Photo"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Image is required']"
                  >
                    <template slot="label">
                      Photo<RedAsterisk />
                    </template>
                  </v-file-input>
                </v-col>
                <v-col
                  v-if="showMaintenanceTypes"
                  cols="12"
                  class="pb-0"
                >
                  <v-row>
                    <v-col
                      cols="3"
                      class="pb-0"
                    >
                      <v-checkbox
                        v-model="isInspect"
                        label="Inspect"
                      />
                    </v-col>
                    <v-col
                      cols="3"
                      class="pb-0"
                    >
                      <v-checkbox
                        v-model="isMaintenance"
                        label="Maintenance"
                      />
                    </v-col>
                    <v-col
                      cols="3"
                      class="pb-0"
                    >
                      <v-checkbox
                        v-model="isService"
                        label="Service"
                      />
                    </v-col>
                    <v-col
                      cols="3"
                      class="pb-0"
                    >
                      <v-checkbox
                        v-model="isRepair"
                        label="Repair"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="showComments"
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="comments"
                    label="Comments"
                    placeholder="Please leave comments on the equipment condition"
                  />
                </v-col>
                <v-col
                  v-if="showLastComment"
                  cols="12"
                  class="pb-0"
                >
                  <v-list-item-title>Last comment</v-list-item-title>
                  <v-list-item-subtitle>{{ lastComment }}</v-list-item-subtitle>
                </v-col>
                <v-col
                  v-if="showStockPhoto"
                  cols="12"
                  class="pb-0"
                >
                  <v-list-item-title>Stock Photo</v-list-item-title>
                  <v-img
                    :src="convertedPhoto"
                    class="stock-photo"
                    style="cursor: pointer;"
                    @click="openItemShowImageForm()"
                  />
                </v-col>
              </v-row>
              <v-row v-if="loading">
                <v-col>
                  <v-progress-linear
                    color="primary"
                    indeterminate
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
  </v-row>
</template>

<script>
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import RedAsterisk from '../RedAsterisk';
import EquipmentItemsApi from '../../api/EquipmentItemsApi';
import EquipmentMovementsApi from '../../api/EquipmentMovementsApi';

export default {
  components: {
    RedAsterisk,
    InventoryItemShowImageForm,
  },

  data: () => ({
    // Form
    dialog: false,
    loading: false,
    dialogTitle: '',
    jobsTitle: '',
    valid: false,
    formAlert: false,
    formAlertText: '',
    formAlertType: 'error',
    btnSubmitName: '',
    formPersistent: true,
    showCondition: true,
    showJobFrom: false,
    showJobs: true,
    showPhotoUpload: true,
    showComments: true,
    showShelf: false,
    showLastComment: true,
    showHoursSpent: false,
    showMaintenanceTypes: false,
    showStockPhoto: false,
    photoUploadWidth: null,
    isInspect: false,
    isMaintenance: false,
    isService: false,
    isRepair: false,
    equipmentList: [],
    jobsList: [],
    conditionsList: [],
    // Equipment movement parameters
    id: null,
    equipment: null,
    jobFrom: null,
    job: null,
    condition: null,
    photo: null,
    stockPhoto: null,
    stockPhotoMimeType: null,
    lastComment: null,
    comments: null,
    shelf: null,
    hoursSpent: null,
    movementType: null,
  }),

  computed: {
    isOpen() {
      return this.$store.getters.equipmentMovementFormType;
    },
    convertedPhoto() {
      return `data:${this.stockPhotoMimeType};base64, ${this.stockPhoto}`;
    },
  },

  watch: {
    isOpen: {
      handler() {
        const movementFormType = this.$store.getters.equipmentMovementFormType;
        this.openDialog(movementFormType);
      },
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.$bus.$on('openEquipmentMovementForm', this.openDialog);
  },

  methods: {
    async initialize() {
      this.equipmentList = await this.$store.getters.equipmentList;
      this.conditionsList = await this.$store.getters.conditionsList;
      // this.jobsList = await this.$store.getters.jobsList;
    },

    async openDialog(type) {
      this.loading = true;
      this.dialog = true;

      this.equipmentList = await this.$store.getters.equipmentList;

      if (type === 'REPAIR DONE') {
        this.photoUploadWidth = 12;
        this.showJobs = false;
      } else if (type === 'REQUEST REPAIR') {
        this.showJobs = false;
        this.photoUploadWidth = 6;
        this.showMaintenanceTypes = true;
      } else {
        await this.fetchJobs();
        this.photoUploadWidth = 4;
        this.showJobs = true;
      }

      if (type === 'CHANGE SITE') {
        this.jobsTitle = 'Job To';
        this.showJobFrom = true;
      } else {
        this.jobsTitle = 'Job';
        this.showJobFrom = false;
      }

      if (type === 'REPAIR DONE') {
        this.showHoursSpent = true;
        this.showMaintenanceTypes = true;
      } else {
        this.showHoursSpent = false;
        this.showMaintenanceTypes = false;
      }

      if (type === 'CHECK - IN') {
        this.showShelf = true;
      } else {
        this.showShelf = false;
      }

      if (type === 'CHECK - IN') {
        const data = { equipment_movement_type_id: [2, 3] };
        this.equipmentList = await EquipmentItemsApi.getFilteredList(data);
        this.equipmentList.map((x) => {
          x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
            x.model
          } / ${x.serial_number ? x.serial_number : '-'}`;
        });

        this.movementType = 1;
        await this.$nextTick(() => {
          this.dialogTitle = 'Check - in equipment';
          this.btnSubmitName = 'Check - in';
          this.showCondition = true;
          this.showPhotoUpload = true;
          this.showComments = true;
          this.showLastComment = true;
          this.showJobs = false;
          this.clearForm();
        });
      } else if (type === 'CHECK - OUT') {
        const data = {
          equipment_movement_type_id: [1, 5],
          equipment_condition_id: [1],
        };
        this.equipmentList = await EquipmentItemsApi.getFilteredList(data);
        this.equipmentList.map((x) => {
          x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
            x.model
          } / ${x.serial_number ? x.serial_number : '-'}`;
        });

        this.movementType = 2;
        await this.$nextTick(() => {
          this.dialogTitle = 'Check - out equipment';
          this.btnSubmitName = 'Check-out';
          this.showCondition = false;
          this.showPhotoUpload = false;
          this.showComments = false;
          this.showLastComment = false;
          this.clearForm();
        });
      } else if (type === 'CHANGE SITE') {
        const data = { equipment_movement_type_id: [2, 3] };
        this.equipmentList = await EquipmentItemsApi.getFilteredList(data);
        this.equipmentList.map((x) => {
          x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
            x.model
          } / ${x.serial_number ? x.serial_number : '-'}`;
        });

        this.movementType = 3;
        await this.$nextTick(() => {
          this.dialogTitle = 'Change equipment site';
          this.btnSubmitName = 'Change site';
          this.showCondition = false;
          this.showPhotoUpload = false;
          this.showComments = false;
          this.showLastComment = false;
          this.clearForm();
        });
      } else if (type === 'REQUEST REPAIR') {
        const data = {
          equipment_movement_type_id: [1, 2, 5],
          equipment_condition_id: [1, 2, 3, 4, 5, 6],
        };
        this.equipmentList = await EquipmentItemsApi.getFilteredList(data);
        this.equipmentList.map((x) => {
          x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
            x.model
          } / ${x.serial_number ? x.serial_number : '-'}`;
        });

        this.movementType = 4;
        await this.$nextTick(() => {
          this.dialogTitle = 'Request Repair';
          this.btnSubmitName = 'Begin';
          this.showCondition = true;
          this.showPhotoUpload = true;
          this.showMaintenanceTypes = true;
          this.showComments = true;
          this.showLastComment = true;
          this.clearForm();
        });
      } else if (type === 'REPAIR DONE') {
        const data = { equipment_movement_type_id: [4] };
        this.equipmentList = await EquipmentItemsApi.getFilteredList(data);
        this.equipmentList.map((x) => {
          x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
            x.model
          } / ${x.serial_number ? x.serial_number : '-'}`;
        });

        this.movementType = 5;
        await this.$nextTick(() => {
          this.dialogTitle = 'Repair Done';
          this.btnSubmitName = 'Complete';
          this.showCondition = false;
          this.showPhotoUpload = true;
          this.showComments = true;
          this.showLastComment = true;
          this.clearForm();
        });
      }

      await this.$nextTick(() => {
        this.loading = false;
      });
    },
    updateCondition(value) {
      if (value === 1) {
        this.showMaintenanceTypes = false;
      } else {
        this.showMaintenanceTypes = true;
      }
      // how can I have here the index value?
    },
    async closeDialog() {
      await this.$store.commit('closeEquipmentMovementForm');
      this.dialog = false;
    },

    async submitForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const userId = this.$store.getters.user.id;

        if (this.movementType === 5) {
          this.condition = 1;
        }

        if (this.movementType === 1) {
          // this.movementType == 2;
          this.job = null;
        }

        if (this.movementType === 2) {
          // this.movementType == 2;
          this.condition = 1;
          this.shelf = null;
        }

        const data = new FormData();

        if (this.photo) {
          data.append('photo', this.photo);
        }

        if (this.job) {
          data.append('job_id', this.job);
        }

        if (this.shelf) {
          data.append('warehouse_shelf', this.shelf);
        }

        if (this.comments) {
          data.append('description', this.comments);
        }

        if (this.hoursSpent) {
          data.append('hours_spent', this.hoursSpent);
        }

        if (this.isInspect) {
          data.append('is_inspect', 1);
        }

        if (this.isMaintenance) {
          data.append('is_maintenance', 1);
        }

        if (this.isService) {
          data.append('is_service', 1);
        }

        if (this.isRepair) {
          data.append('is_repair', 1);
        }

        data.append('equipment_condition_id', this.condition);
        data.append('equipment_item_id', this.equipment);
        data.append('equipment_movement_type_id', this.movementType);
        data.append('user_id', userId);

        const res = await EquipmentMovementsApi.storeFormData(data);

        if (res) {
          this.$emit('movement-success');
        } else {
          this.$emit('movement-fail');
        }

        await this.$store.commit('closeEquipmentMovementForm');
        this.loading = false;
        this.dialog = false;
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    showFormAlert(message, type) {
      this.formAlert = true;
      this.formAlertText = message;
      this.formAlertType = type;
      setTimeout(() => {
        this.formAlert = false;
      }, 3000);
    },

    async clearForm() {
      if (this.$refs.form) { await this.$refs.form.reset(); }
      this.loading = false;
      this.id = null;
      this.photo = null;
      this.equipment = null;
      this.condition = null;
      this.jobFrom = null;
      this.job = null;
      this.comments = null;
      this.isInspect = null;
      this.is_maintenance = null;
      this.is_service = null;
      this.is_repair = null;
      this.showStockPhoto = false;
      this.stockPhotoMimeType = null;
      this.stockPhoto = null;
      this.shelf = null;
    },

    async fetchJobs() {
      const readModel = {
        filters:
        {
        },
      };
      this.jobsList = await this.$api.job.read(readModel);
      this.jobsList.map((x) => (x.name = `Job ${x.id}: ${x.address}`));
    },

    async fetchStatus() {
      const data = {
        equipment_item_id: this.equipment,
      };

      const status = await EquipmentMovementsApi.getCurrentStatus(data);

      this.shelf = status.warehouse_shelf;
      this.condition = status.equipment_condition_id;
      this.job = status.job_id;
      this.lastComment = status.description
        ? `${status.created_at_short}: ${status.description}`
        : 'No comments';

      if (this.dialogTitle === 'Change equipment site') {
        const jobFrom = this.jobsList.find((x) => x.id === status.job_id);
        this.jobFrom = `Job ${jobFrom.id}: ${jobFrom.address}`;
      }

      if (
        this.dialogTitle === 'Check - out equipment'
        || this.dialogTitle === 'Change equipment site'
      ) {
        const stockPhotoResult = await EquipmentItemsApi.getPhoto(data);

        if (stockPhotoResult) {
          this.stockPhoto = stockPhotoResult.photo;
          this.stockPhotoMimeType = stockPhotoResult.mime_type;
          this.showStockPhoto = true;
        } else {
          this.showStockPhoto = false;
          this.stockPhoto = null;
        }
      }
    },
    openItemShowImageForm() {
      this.$refs.itemShowImageForm.openDialog(this.stockPhoto, this.stockPhotoMimeType);
    },
  },
};
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}

.stock-photo {
  border: 1px solid;
  max-height: 20rem;
  max-width: 20rem;
}
</style>
