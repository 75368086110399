import Api from './Api';

class InventoryClassesApi extends Api {
  constructor() {
    super('inventory_classes', 'inventory_classes');
  }
}
const inventoryClassesApi = new InventoryClassesApi();

export default inventoryClassesApi;
