<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    submit-btn-text="Submit"
  >
    <template slot="header">
      <span>Run Current Levels Webhook</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <inventory-class-select-element
            v-model="item.inventory_class_id"
            :is-add-all="false"
            :is-required="true"
            :is-inst="false"
            :is-railings="false"
            :is-glass="false"
            :is-commercial="false"
            :is-vwd-glass="false"
            :is-panels="false"
          />
        </v-col>
      </v-row>
      <v-row v-if="item.sub_module_id">
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.location_name"
            :items="locations"
            item-text="name"
            item-value="name"
            :rules="[(v) => !!v || 'Location is required']"
          >
            <template slot="label">
              Location<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0"
        >
          <v-checkbox
            v-model="item.is_qty_on_hand_o"
            label="Hide Zero 'Qty On Hand'"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  data: () => ({
    item: null,
    itemDto: {
      module_id: 0,
      inventory_class_id: 0,
      is_qty_on_hand_o: false,
      location_name: null,
      sub_module_id: null,
    },
    fileType: 'pdf',
    locations: [],
  }),
  created() {
    this.item = { ...this.itemDto };
    this.getLocations();
  },
  methods: {

    async submit() {
      this.$bus.$emit('loading');
      this.item.inventory_class = this.item.inventory_class_id === 1 ? '4500' : '4800';
      const res = await this.$api.webhook.store(this.item, 'run');
      if (!res) {
        this.$bus.$emit('loading');
        return false;
      }

      const report = await this.$api.report.download(res.id, this.fileType);
      if (!report) {
        this.$bus.$emit('showError', 'Report Failed');
        this.$bus.$emit('loading');
        return false;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${res.report_title}_${now}`;
      if (this.fileType === 'pdf') {
        const file = new Blob([report], {
          type: 'application/pdf',
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        saveAs(file, fileName);
      } else {
        const file = new Blob([report], {
          type: 'application/vnd.ms-excel',
        });
        saveAs(file, fileName);
      }

      this.$bus.$emit('loading');
      return true;
    },

    async getLocations() {
      this.locations = await this.$api.location.get();
    },

    open(moduleId, fileType, subModuleId) {
      this.$refs.dialogBox.open();
      this.item = { ...this.itemDto };
      this.item.module_id = moduleId;
      this.item.sub_module_id = subModuleId;
      this.fileType = fileType;
    },
  },
};
</script>
