<template>
  <v-container fluid>
    <v-card
      elevation="0"
      outlined
    >
      <v-card-title>Parse File</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
        >
          <v-row>
            <v-col
              cols="2"
              class="pb-0"
            >
              <inventory-class-select-element
                v-model="item.inventory_class_id"
                :is-inst="false"
                :is-glass="false"
                :is-panels="false"
                :is-sid="false"
                :is-add-all="false"
                :is-rail-part="false"
                :is-railings="false"
                :is-commercial="false"
                :is-vwd-glass="false"
                :is-required="false"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="item.name"
                :rules="[(v) => (!!v || 'Work Order is required')]"
              >
                <template slot="label">
                  Work Order<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                class="mt-3"
                color="primary"
                outlined
                @click="submit"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col class="mt-5">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.class`]="{ item }">
              <v-chip
                class="mt-0 mb-0"
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ item.user? item.user.first_name + ' ' + item.user.last_name : '' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-row v-if="item.files">
                <v-spacer />
                <v-col
                  v-for="file in item.files"
                  :key="file.id"
                  class="pt-5 pb-5 pl-0 pr-0"
                  cols="auto"
                >
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="download(file)"
                  >
                    {{ file.type ? file.type.name : '' }}
                  </v-btn>
                </v-col>
                <v-col
                  class="pt-5 pb-5 pl-0"
                  cols="auto"
                >
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    @click="softDelete(item.id)"
                  >
                    DELETE
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';
import { mapState } from 'vuex';

export default {
  data: () => ({
    item: {
      name: null,
      inventory_class_id: null,
    },
    inventoryClassId: null,
    items: [],
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Class', value: 'class' },
      { text: 'Work Order', value: 'name' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Created At', value: 'created_at' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '60%',
      },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    types: [],
    loading: false,
  }),
  computed:
  {
    ...mapState({
      search: (state) => state.search,
    }),
  },

  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_class_id': function (val) {
      if (!val) return;
      this.get();
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.get();
      this.getTypes();
    },
    async get() {
      this.loading = true;
      this.items = [];
      const submitModel = {
        filters: {
          inventory_class_id: this.item.inventory_class_id,
        },

      };
      const res = await this.$api.fileParser.readCancel(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.items = [];
        return;
      }
      this.items = res;
      this.loading = false;
    },

    async getTypes() {
      this.types = await this.$api.fileParserType.get();
    },

    async submit() {
      if (!await this.$refs.form.validate()) return;
      this.$bus.$emit('loading');
      const res = await this.$api.fileParser.store(this.item);

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }
      if (res === '1001') {
        this.$bus.$emit('showError', `CNC File for Work Order ${this.item.name} already exists`);
        this.$bus.$emit('loading');
        return;
      }

      this.$bus.$emit('showSuccess');
      const inventoryClassId = this.item.inventory_class_id;
      await this.$refs.form.reset();
      this.item.inventory_class_id = inventoryClassId;
      this.get();
      this.$bus.$emit('loading');
    },

    async download(item) {
      this.$bus.$emit('loading');
      const csv = await this.$api.fileParserFile.get(`download/${item.id}`);
      if (!csv) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.name}_${now}`;
      const file = new Blob([csv], {
        type: 'text/csv',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this item?',
      });
      if (!confirm) return;
      const res = await this.$api.fileParser.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },
  },
};
</script>
<style scoped>
/deep/.v-data-table__wrapper {
  overflow-x: hidden !important;
}
</style>
