import Api from '@/api/Api';

class ReportTemplateApi extends Api {
  constructor() {
    super('reports/templates', 'reports/templates');
  }

  async update(data) {
    const res = await this.store(data, 'update');
    return res;
  }

  async download(id) {
    const res = await this.get(`download/${id}`, { responseType: 'blob' });
    return res;
  }
}
const reportTemplateApi = new ReportTemplateApi();

export default reportTemplateApi;
