<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Webhook</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.module_id"
            :items="modules"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Module is required']"
          >
            <template slot="label">
              Module<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-text-field
            v-model="item.description"
          >
            <template slot="label">
              Description
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="10"
          class="pb-0"
        >
          <v-file-input
            v-model="item.file"
            accept=".rpt"
            :rules="[(v) => !!item.id || (!!v || 'Crystal File is required')]"
          >
            <template slot="label">
              File<RedAsterisk v-if="!item.id" />
            </template>
          </v-file-input>
        </v-col>
        <v-col
          v-if="item.id"
          cols="2"
          class="mt-5"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                text
                @click="download"
                v-on="on"
              >
                <v-icon
                  v-if="item.id"
                  color="primary"
                >
                  fas fa-download
                </v-icon>
              </v-btn>
            </template>
            <span>{{ item.file_name }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { saveAs } from 'file-saver';

export default {
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      module_id: 0,
      description: '',
      file: null,
    },
    modules: [],
  }),
  created() {
    this.item = { ...this.itemDto };
    this.getModules();
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.webhook.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getModules() {
      this.modules = await this.$api.webhook.get('modules');
    },

    async submit() {
      if (!this.item) return false;

      const data = new FormData();
      if (this.item.id) {
        data.append('id', this.item.id);
      }
      data.append('module_id', this.item.module_id);
      data.append('description', this.item.description ? this.item.description : '');
      if (this.item.file) {
        data.append('file', this.item.file);
      }

      let res;
      if (this.item.id) {
        // put not working with formData
        res = await this.$api.webhook.store(data, 'update');
      } else {
        res = await this.$api.webhook.store(data);
      }

      if (res !== true) {
        this.$bus.$emit('showError', res);
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },

    async download() {
      if (!this.item.report_template_id) return;
      const res = await this.$api.webhook.get(`download/${this.item.report_template_id}`, { responseType: 'blob' });
      const file = new Blob([res], {
        type: 'application/octet-stream',
      });
      saveAs(file, this.item.file_name);
    },
  },
};
</script>
