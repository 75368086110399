import Api from './Api';

class InventoryCategoriesApi extends Api {
  constructor() {
    super('inventory_category', 'inventory/categories');
  }
}
const inventoryCategoriesApi = new InventoryCategoriesApi();

export default inventoryCategoriesApi;
