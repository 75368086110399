import Api from './Api';

class EquipmentConditionsApi extends Api {
  constructor() {
    super('equipment_conditions', 'equipment/conditions');
  }
}
const equipmentConditionsApi = new EquipmentConditionsApi();

export default equipmentConditionsApi;
