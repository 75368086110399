var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index,attrs:{"sm":"auto"}},[_c('v-checkbox',{key:'m' + index,attrs:{"label":item.name.toUpperCase(),"input-value":item.is_active},on:{"click":function($event){return _vm.submit(item)}}})],1)}),_c('v-col',{staticClass:"text-end"},[_c('a',{attrs:{"href":"https://app.powerbi.com/groups/b21fb517-8ca2-4267-8963-23ca228bc885/list?experience=power-bi"}},[_vm._v("POWER BI URL")])])],2),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v("User Access")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [(item.roles && item.roles.length > 1)?_c('v-row',[_c('v-col',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("ROLES")])]}}],null,true)},_vm._l((item.roles),function(p){return _c('span',{key:p.id},[_vm._v(" "+_vm._s(p.name)),_c('br')])}),0)],1)],1):_c('v-row',_vm._l((item.roles),function(p,idx){return _c('v-col',{key:p.id,staticClass:"pr-0 pl-1",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(p.name)),(idx !== item.roles.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])])}),1)]}},{key:"item.glass_purchase_order",fn:function(ref){
var item = ref.item;
return [(item.dashboard_ids)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.dashboard_ids.includes(_vm.glassPurchseOrderId),"hide-details":""},on:{"change":function($event){return _vm.handleCheckboxChange($event, item, _vm.glassPurchseOrderId)}}}):_vm._e()]}},{key:"item.chrislea_inventory",fn:function(ref){
var item = ref.item;
return [(item.dashboard_ids)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.dashboard_ids.includes(_vm.chrisleaInventoryId),"hide-details":""},on:{"change":function($event){return _vm.handleCheckboxChange($event, item, _vm.chrisleaInventoryId)}}}):_vm._e()]}},{key:"item.procore_correspondence",fn:function(ref){
var item = ref.item;
return [(item.dashboard_ids)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.dashboard_ids.includes(_vm.procoreCorrespondenceId),"hide-details":""},on:{"change":function($event){return _vm.handleCheckboxChange($event, item, _vm.procoreCorrespondenceId)}}}):_vm._e()]}},{key:"item.awd_breakdown",fn:function(ref){
var item = ref.item;
return [(item.dashboard_ids)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.dashboard_ids.includes(_vm.awdBreakdownId),"hide-details":""},on:{"change":function($event){return _vm.handleCheckboxChange($event, item, _vm.awdBreakdownId)}}}):_vm._e()]}},{key:"item.paint_consumption",fn:function(ref){
var item = ref.item;
return [(item.dashboard_ids)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":item.dashboard_ids.includes(_vm.awdPaintConsumptionId),"hide-details":""},on:{"change":function($event){return _vm.handleCheckboxChange($event, item, _vm.awdPaintConsumptionId)}}}):_vm._e()]}}],null,true)})],1)],1)],1),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }