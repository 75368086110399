<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="1000px"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="formAlert"
              :type="formAlertType"
            >
              {{ formAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-spacer />
                <v-col
                  cols="10"
                  class="pb-0"
                >
                  <v-img
                    :src="convertedPhoto(photo, type)"
                    height="100%"
                    width="100%"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  components: {},
  data: () => ({
    // Form
    dialog: false,
    dialogTitle: '',
    valid: false,
    formAlert: false,
    formAlertText: '',
    formAlertType: 'error',
    btnSubmitName: '',
    formPersistent: true,
    isEdit: false,
    material_id: '',
    photo: '',
    type: '',
  }),
  watch: {},
  methods: {
    openDialog(photo, type, code = null) {
      this.dialog = true;
      if (photo) { this.photo = photo; }
      if (type) { this.type = type; }
      if (code) { this.code = code; this.dialogTitle = `Item #${this.code} Image`; }
    },
    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },
  },
};
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}
</style>
