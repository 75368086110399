<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        cols="6"
        class="mt-6"
      >
        <h4>Report Categories</h4>
      </v-col>
      <v-col
        cols="6"
        class="text-right mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="openCategoryForm(0)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new category
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="categoryHeaders"
            :items="categories"
            hide-default-footer
            :items-per-page="-1"
            dense
            :loading="loadingCategory"
          >
            <template #item="{ item }">
              <tr
                :style="{ backgroundColor: hexToRgb(item.color) }"
              >
                <td>
                  <v-avatar
                    :color="item.category_status ? 'success' : 'error'"
                    size="10"
                  />
                  {{ item.category_title }}
                </td>
                <td>
                  {{ item.category_desc }}
                </td>
                <td class="text-end">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openCategoryForm(item.id)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    v-if="item.category_status"
                    class="ml-1 mt-1 mb-1"
                    color="purple"
                    outlined
                    small
                    @click="toggleCategory(item.id, item.category_status)"
                  >
                    DISABLE
                  </v-btn>
                  <v-btn
                    v-else
                    class="ml-1 mt-1 mb-1 pl-5"
                    color="purple"
                    outlined
                    small
                    @click="toggleCategory(item.id, item.category_status)"
                  >
                    ENABLE
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    @click="deleteCategory(item.id)"
                  >
                    DELETE
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        class="pb-0 mt-2"
      >
        <h4>Report Templates</h4>
      </v-col>
      <v-col
        cols="6"
        class="d-flex justify-end pb-0"
      >
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="openTemplateForm(0)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new report template
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="templateHeaders"
            :items="templates"
            hide-default-footer
            :items-per-page="-1"
            dense
            sort-by="name"
            group-by="category_group"
            :loading="loadingTemplate"
          >
            <template #group="{ items }">
              <tr :style="{ backgroundColor: hexToRgb(items[0] && items[0].category && items[0].category.color ? items[0].category.color : '#FFFFFF') }">
                <td class="text-xs-right">
                  <span
                    class="font-weight-bold"
                    style="font-size: 1.1em"
                  >{{ items[0].category_group }}</span>
                </td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr
                v-for="item in items"
                v-show="!item.hide"
                :key="item.id"
              >
                <td>
                  <v-avatar
                    class="ml-5"
                    :color="item.status ? 'success' : 'error'"
                    size="10"
                  /> <span class="font-weight-bold">R{{ item.id }}</span> {{ item.template_title }}
                </td>
                <td>
                  {{ item.parameter_names }}
                </td>
                <td>
                  <v-tooltip
                    v-if="item.template_description && item.template_description.length > 15"
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span v-on="on">{{ item.template_description.substring(0, 14) }}...</span>
                    </template>
                    <span>{{ item.template_description }}</span>
                  </v-tooltip>
                  <span v-else>{{ item.template_description }}</span>
                </td>
                <td>
                  <v-tooltip
                    v-if="item.user_names && item.user_names.length > 1"
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span v-on="on"><v-icon>fas fa-user-friends</v-icon></span>
                    </template>
                    <span>{{ item.user_names.join(', ') }}</span>
                  </v-tooltip>
                  <span v-else>{{ item.user_names ? item.user_names.toString() : '' }}</span>
                </td>
                <td>
                  {{ item.data_base }}
                </td>
                <td>
                  {{ item.file_type_names }}
                </td>
                <td>
                  {{ item.updated_at }}
                </td>
                <td>
                  {{ item.user_full_name }}
                </td>
                <td class="text-end pr-0">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openTemplateForm(item.id)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    v-if="item.status"
                    class="ml-1 mr-1 mt-1 mb-1"
                    color="purple"
                    outlined
                    small
                    @click="toggleTemplate(item.id, item.status)"
                  >
                    DISABLE
                  </v-btn>
                  <v-btn
                    v-else
                    class="ml-1 mr-1 mt-1 mb-1 pl-5"
                    color="purple"
                    outlined
                    small
                    @click="toggleTemplate(item.id, item.status)"
                  >
                    ENABLE
                  </v-btn>
                  <v-btn
                    class="mr-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    @click="deleteTemplate(item.id)"
                  >
                    DELETE
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        class="pb-0 mt-2"
      >
        <h4>Report Parameters</h4>
      </v-col>
      <v-col
        cols="6"
        class="d-flex justify-end pb-0"
      >
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="openParameterForm(0)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new report parameter
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="parameterHeaders"
            :items="parameters"
            hide-default-footer
            :items-per-page="-1"
            dense
            :loading="loadingParameter"
          >
            <template #[`item.type`]="{ item }">
              <v-chip
                small
                :color="getChipColor(item.re_parameter_type_id)"
                text-color="white"
              >
                {{ item && item.type ? item.type.re_parameter_type : '' }}
              </v-chip>
            </template>
            <template #[`item.type_info`]="{ item }">
              <v-tooltip
                v-if="item.re_parameter_type_id === paramPicklistTypeID"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    fa-info-circle
                  </v-icon>
                </template>
                <span style="font-weight: bold;">Format: </span>
                <span>{{ item.static_dynamic === parameterFormatId_Static ? 'Static' : 'Dynamic' }}</span><br>
                <span style="font-weight: bold;">Parameter Prompt Type: </span>
                <span>{{ item.single_multiple === '1' ? 'Single Choice' : 'Multiple Choice' }}</span><br>
                <span style="font-weight: bold;">{{ item.static_dynamic === parameterFormatId_Static ? 'Static Parameters: ' : 'Dynamic Parameter SQL Query: ' }}</span>
                <span>{{ item.static_dynamic === parameterFormatId_Static ? item.default_value : item.dynamic_param_query }}</span><br>
              </v-tooltip>
            </template>
            <template #[`item.updated_by`]="{ item }">
              {{ item && item.updated_by ? item.user.first_name + ' ' + item.user.last_name : '' }}
            </template>
            <template #[`item.description`]="{ item }">
              <v-tooltip
                v-if="item.description && item.description.length > 30"
                top
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ item.description.substring(0, 29) }}...</span>
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
              <span v-else>{{ item.description }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openParameterForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteParameter(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <report-category-form
      ref="categoryForm"
      @success="successCategory"
    />
    <report-template-form
      ref="templateForm"
      @success="successTemplate"
    />
    <report-parameter-form
      ref="parameterForm"
      @success="successParameter"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ReportCategoryForm from '@/components/forms/reports/ReportCategoryForm';
import ReportTemplateForm from '@/components/forms/reports/ReportTemplateForm';
import ReportParameterForm from '@/components/forms/reports/ReportParameterForm';

export default {
  components: {
    ReportCategoryForm,
    ReportTemplateForm,
    ReportParameterForm,
  },
  data: () => ({
    categories: [],
    templates: [],
    parameters: [],
    categoryHeaders: [
      { text: 'Category', value: 'category_title', width: '30%' },
      { text: 'Description', value: 'category_desc' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '30%',
      },
    ],
    templateHeaders: [
      { text: 'Report Category/Template', value: 'report_title', width: '20%' },
      { text: 'Parameters', value: 'parameter_names', width: '20%' },
      { text: 'Description', value: 'category_desc', width: '6%' },
      { text: 'Users', value: 'users', width: '6%' },
      { text: 'DataBase', value: 'database', width: '6%' },
      { text: 'File Types', value: 'file_types', width: '6%' },
      { text: 'Last Modified', value: 'updated_at', width: '6%' },
      { text: 'Modified By', value: 'user_full_name', width: '6%' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '25%',
      },
    ],
    parameterHeaders: [
      { text: 'Parameter Name', value: 'userFriendlyName' },
      { text: 'Crystal Report Parameter Name', value: 'crystalParameterName' },
      { text: 'Description', value: 'description' },
      { text: 'Type', value: 'type', align: 'center' },
      {
        text: '', value: 'type_info', align: 'center', width: '1%',
      },
      { text: 'Last Modified', value: 'updated_at' },
      { text: 'Modified By', value: 'updated_by' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '30%',
      },
    ],
    paramPicklistTypeID: 5,
    parameterFormatId_Static: '1',
    parameterFormatId_Dynamic: '2',
    loadingCategory: false,
    loadingTemplate: false,
    loadingParameter: false,
  }),

  computed: {
    ...mapState({
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getCategories();
      this.getTemplates();
      this.getParameters();
    },

    async getCategories() {
      this.loadingCategory = true;
      this.categories = await this.$api.reportCategory.get();
      this.loadingCategory = false;
    },
    async getTemplates() {
      this.loadingTemplate = true;
      const readModel = {
        sort: {
          order_by_first: 'created_at',
        },
      };
      this.templates = await this.$api.reportTemplate.read(readModel);
      this.loadingTemplate = false;
    },

    async getParameters() {
      this.loadingParameter = true;
      this.parameters = await this.$api.reportParameter.get();
      this.loadingParameter = false;
    },

    async deleteCategory(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this category?',
      });
      if (!confirm) return;
      const res = await this.$api.reportCategory.delete(id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.getCategories();
      this.getTemplates();
      this.$refs.templateForm.getCategories();
    },

    async deleteTemplate(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this template?',
      });
      if (!confirm) return;
      const res = await this.$api.reportTemplate.delete(id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.getTemplates();
    },

    async deleteParameter(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this parameter? This action can brake templates that are using this parameter!',
      });
      if (!confirm) return;
      const res = await this.$api.reportParameter.delete(id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.getTemplates();
      this.getParameters();
      this.$refs.templateForm.getParameters();
    },

    openCategoryForm(id) {
      this.$refs.categoryForm.open(id);
    },

    openTemplateForm(id) {
      this.$refs.templateForm.open(id);
    },

    openParameterForm(id) {
      this.$refs.parameterForm.open(id);
    },

    successCategory() {
      this.$bus.$emit('showSuccess');
      this.getCategories();
      this.getTemplates();
      this.$refs.templateForm.getCategories();
    },
    successTemplate() {
      this.$bus.$emit('showSuccess');
      this.getTemplates();
    },

    successParameter() {
      this.$bus.$emit('showSuccess');
      this.getParameters();
      this.$refs.templateForm.getParameters();
    },

    // Disable/enable
    async toggleCategory(id, isEnable) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${isEnable ? 'Disable' : 'Enable'} this category?`,
      });
      if (!confirm) return;
      const res = await this.$api.reportCategory.get(`toggle/${id}`);
      if (!res) return;
      this.getCategories();
      this.$refs.templateForm.getCategories();
    },
    // Disable/enable
    async toggleTemplate(id, isEnable) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${isEnable ? 'Disable' : 'Enable'} this template?`,
      });
      if (!confirm) return;
      const res = await this.$api.reportTemplate.get(`toggle/${id}`);
      if (!res) return;
      this.getTemplates();
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .1)`;
        return rgba;
      }
      return null;
    },

    getChipColor(id) {
      const dateTypeId = 1;
      const stringTypeId = 2;
      const numberTypeId = 3;
      const booleanTypeId = 4;
      const picklistTypeID = 5;
      const currentDateTypeId = 7;
      const currentTimeTypeId = 8;

      let color = '';

      switch (id) {
        case dateTypeId:
          color = 'success';
          break;
        case stringTypeId:
          color = 'orange';
          break;
        case numberTypeId:
          color = 'indigo';
          break;
        case booleanTypeId:
          color = 'teal';
          break;
        case picklistTypeID:
          color = 'cyan';
          break;
        case currentDateTypeId:
          color = 'purple';
          break;
        case currentTimeTypeId:
          color = 'blue-grey';
          break;
        default:
          color = 'success';
      }
      return color;
    },
  },

};

</script>
