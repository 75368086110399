import Api from './Api';

class WarehousesApi extends Api {
  constructor() {
    super('warhouse', 'warehouses');
  }
}
const warehousesApi = new WarehousesApi();

export default warehousesApi;
