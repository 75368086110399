var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"statuses-table","headers":_vm.statusesHeaders,"items":_vm.statuses,"footer-props":_vm.statusesTableFooterProps,"items-per-page":_vm.statusesPerPage,"search":_vm.search,"loading":_vm.loading,"calculate-widths":"","dense":"","server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"status-code"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.code)+" ")])],1)]),_c('td',{staticClass:"status-category",staticStyle:{"padding":"0 5px"}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.category)+" ")])],1),_c('td',{staticClass:"status-model",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.model))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.manufacturer))])])],1),_c('td',{staticClass:"status-serial-number",staticStyle:{"padding":"0 5px"}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.serial_number ? item.serial_number : '-')+" ")])],1),_c('td',{staticClass:"status-user",staticStyle:{"padding":"0 5px"}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user_name)+" ")])],1),_c('td',{staticClass:"status-location",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({color: item.locationColor})},on),[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.location_simple))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.location))])])],1),_c('td',{staticClass:"status-condition text-center",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({ color: item.colour })},on),[_c('v-chip',{attrs:{"small":"","color":item && item.colour ? item.colour : '#BDBDBD',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.condition ? item.condition : '')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description ? item.description : 'No description'))])])],1),_c('td',{staticClass:"status-type text-center",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({ color: item.statusColour })},on),[_c('v-chip',{attrs:{"small":"","color":item && item.statusColour ? item.statusColour : '#BDBDBD',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.status ? item.status : '')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.hours_spent ? ((item.hours_spent) + " hours") : 'No information'))])])],1),_c('td',{staticClass:"status-date",staticStyle:{"padding":"0 5px"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"href":"#"},on:{"click":function () { return _vm.openEquipmentInfo(item.equipment_item_id); }}},on),[_vm._v(" "+_vm._s(item.created_at_short)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.created_at_full))])])],1)])]}}])})],1)],1),_c('EquipmentItem',{ref:"equipmentItem"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }