<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-btn
          text
          @click="goBack"
        >
          <v-icon>
            fas fa-arrow-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        md="3"
        sm="12"
        class="text-center mt-1"
      >
        <h3 color="primary">
          Paint Purchase Order #{{ inventoryPo ? inventoryPo.po_number : '' }}
        </h3>
      </v-col>
      <v-col
        cols="7"
        md="7"
        sm="12"
        class="text-right"
      >
        <v-btn
          color="purple"
          class="mr-2"
          outlined
          @click="openAttachmentForm"
        >
          Attachments
        </v-btn>
        <v-btn
          color="primary"
          class="mr-2"
          outlined
          @click="scanQr"
        >
          SCAN QR
        </v-btn>
        <v-btn
          color="success"
          outlined
          @click="emailNotification"
        >
          OPT {{ isOptedInEmailNotification ? 'OUT' : 'IN'}} EMAIL
        </v-btn>
      </v-col>
    </v-row>
    <v-row :style="`background-color: ${hexToRgb('#E0E0E0')}; margin:10px 0 0 0`">
      <v-col>
        <v-row>
          <v-col
            cols="2"
            class="text-center"
          >
            <span
              class="h5 font-weight-bold"
              style="font-size: 16px !important;"
            >
              Class:
            </span> <v-chip
              v-if="inventoryPo.class"
              class="ml-1"
              small
              :color="inventoryPo && inventoryPo.class ? inventoryPo.class.color : 'grey'"
              text-color="white"
            >
              {{ inventoryPo && inventoryPo.class ? inventoryPo.class.name : '' }}
            </v-chip>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span
              style="font-size: 16px !important;"
              class="h5 font-weight-bold"
            >
              Repaint to Color:
            </span> <span style="font-size: 16px !important;"> {{ inventoryPo ? inventoryPo.color_code : '' }}</span>
          </v-col>
          <v-col
            cols="7"
            class="text-center"
          >
            <span
              class="h5 font-weight-bold"
              style="font-size: 16px !important;"
            >
              Job:
            </span>
            <span
              v-if="inventoryPo"
              style="font-size: 16px !important;"
            >
              <span style="font-weight: bold; font-size: 16px !important;">{{ inventoryPo.job_code ? `${inventoryPo.job_code}` : '' }}</span> - {{ inventoryPo.job_name ? inventoryPo.job_name : inventoryPo.job_name }} <span style="color: #1976D2; font-size: 16px !important;">{{ inventoryPo.job_reference ? `; ${inventoryPo.job_reference}` : '' }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="3"
            class="text-center"
          >
            <span
              style="font-size: 16px !important;"
              class="h5 font-weight-bold"
            >
              Supplier:
            </span>
            <span style="font-size: 16px !important;">{{ inventoryPo.supplier_name ? inventoryPo.supplier_name : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span
              style="font-size: 16px !important;"
              class="h5 font-weight-bold"
            >
              Location:
            </span>
            <span style="font-size: 16px !important;">{{ inventoryPo.location_name ? inventoryPo.location_name : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span
              style="font-size: 16px !important;"
              class="h5 font-weight-bold"
            >
              Required by:
            </span>
            <span style="font-size: 16px !important;">{{ inventoryPo.required_by ? inventoryPo.required_by : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span
              style="font-size: 16px !important;"
              class="h5 font-weight-bold"
            >
              Created at:
            </span>
            <span style="font-size: 16px !important;">{{ inventoryPo.created_at_short ? inventoryPo.created_at_short : '' }}</span>
          </v-col>
        </v-row>
        <v-row v-if="loading">
          <v-col>
            <v-progress-linear
              indeterminate
              rounded
              height="4"
              color="primary"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="tableHeaders"
            :items="purchaseOrderItems"
            :footer-props="footer"
            :items-per-page="100"
            :search="search"
            :loading="loadingItems"
            dense
            :item-class="itemRowBackground"
            show-expand
            :expanded.sync="expandedItems"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <v-btn
                    icon
                    @click="toggleExpand(item)"
                  >
                    <v-icon v-if="expandedItems.includes(item)">
                      mdi-chevron-up
                    </v-icon>
                    <v-icon v-else>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </td>
                <td>
                  <span style="font-weight: bold;"><text-highlight :queries="search">
                    {{ item.item_full_name ? item.item_full_name : '' }}
                  </text-highlight>
                  </span>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.note ? item.note : '' }}
                  </text-highlight>
                  <v-icon
                    small
                    color="primary"
                    @click="openNoteEdit(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </td>
                <td>
                  <span><text-highlight :queries="search">
                    {{ item.color_names ? item.color_names : '' }}
                  </text-highlight>
                  </span>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.length_name ? item.length_name : '' }} {{ item.uom_name ? item.uom_name : '' }}
                  </text-highlight>
                </td>
                <td>{{ item.quantity }}</td>
                <td><span style="color: red;">{{ item.quantity_remained }}</span></td>
                <td><span>{{ item.quantity_supplied }}</span></td>
                <td class="text-center">
                  <v-chip
                    small
                    :color="item && item.supply_to_paint_status ? item.supply_to_paint_status.color : ''"
                    text-color="white"
                  >
                    {{ item && item.supply_to_paint_status ? item.supply_to_paint_status.name : '' }}
                  </v-chip>
                </td>
                <td class="text-end">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="primary"
                        outlined
                        small
                        :disabled="item.quantity_remained == 0"
                        @click="openLocateForm(item)"
                      >
                        Locate
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pl-0 pr-0"
              >
                <v-container
                  fluid
                  :style="{backgroundColor: isDarkMode ? item.index % 2 === 0 ? '#000000' : '#556B2F' : item.index % 2 === 0 ? 'white' : '#E8F5E9'}"
                >
                  <v-row
                    v-for="supply_to_paint_tag, index in item.tags"
                    :key="supply_to_paint_tag.id"
                    align="center"
                    justify="center"
                    :style="`border-bottom: ${index === item.tags.length - 1 ? 'none' : '1px solid #c1c1c1'};`"
                  >
                    <v-col
                      class="pt-2 pb-2 ml-9"
                      cols="2"
                    >
                      <span>Qr Code: </span><span style="font-size: 16px; font-weight: bold">{{ supply_to_paint_tag.inventory_tag }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Color: </span><span style="font-weight: bold">{{ supply_to_paint_tag.tag && supply_to_paint_tag.tag.color ? supply_to_paint_tag.tag.color.code : '' }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Location: </span><span style="font-weight: bold">{{ supply_to_paint_tag.tag && supply_to_paint_tag.tag.location ? supply_to_paint_tag.tag.location.name : '' }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Shelf: </span><span style="font-weight: bold">{{ supply_to_paint_tag.tag && supply_to_paint_tag.tag.inventory_location ? supply_to_paint_tag.tag.inventory_location.name : '' }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Quantity Used: </span><span style="font-weight: bold">{{ supply_to_paint_tag.quantity }}</span>
                    </v-col>
                    <v-spacer />
                    <v-col
                      class="pt-0 pb-0 pr-1"
                      cols="auto"
                    >
                      <v-btn
                        color="error"
                        outlined
                        small
                        @click="deleteTag(supply_to_paint_tag.id)"
                      >
                        DELETE
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col
        class="pb-0"
      >
        <span style="font-size: 18px; color: #000000;">Notes</span>
      </v-col>
      <v-col
        class="text-end"
      >
        <v-btn
          color="orange"
          outlined
          @click="openNoteForm"
        >
          SEE ALL NOTES
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0"
      >
        <!-- <v-textarea
          v-model="inventoryPo.latest_note.text"
          outlined
          rows="3"
        /> -->
        <v-card-text style="max-height: 300px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
          <v-list
            two-line
            class="pt-0 pb-0"
          >
            <template v-for="(note, index) in inventoryPo.notes">
              <v-list-item :key="note.id">
                <!-- Display each note -->
                <v-list-item-content>
                  {{ note.text }}
                  <v-list-item-subtitle style="padding-top: 10px;">
                    {{ note.created_at }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>{{ `${note.user.first_name ? note.user.first_name : ''} ${note.user.last_name ? note.user.last_name : ''}` }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < inventoryPo.notes.length - 1"
                :key="index"
              />
            </template>
          </v-list>
        </v-card-text>
      </v-col>
    </v-row>
    <v-dialog
      v-model="qrCodeDialog"
      fullscreen
      persistent
      hide-overlay
    >
      <v-card>
        <v-card-title>
          <v-col class="text-right">
            <v-btn
              icon
              @click="qrCodeDialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-container fluid>
          <inventory-qr
            :is-paint-supply="true"
            @return-qr-code="returnQrCode"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <supply-to-paint-qr-form
      ref="paintQrForm"
      @success="getInventoryPoItems"
    />
    <supply-to-paint-note-form
      ref="noteForm"
      @success="getInventoryPoItems"
    />
    <supply-to-paint-locate-form
      ref="locateForm"
      @success="getInventoryPoItems"
      @scanqr="scanQr"
    />
    <note-form ref="noteForm" @success="sendNoteEmailNotification"/>
    <attachment-form ref="attachmentForm" />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import SupplyToPaintQrForm from '@/components/forms/supplyToPaints/SupplyToPaintQrForm';
import SupplyToPaintNoteForm from '@/components/forms/supplyToPaints/SupplyToPaintNoteForm';
import SupplyToPaintLocateForm from '@/components/forms/supplyToPaints/SupplyToPaintLocateForm';
import NoteForm from '@/components/forms/notes/NoteForm';
import AttachmentForm from '@/components/elements/AttachmentForm';

export default {
  components: {
    InventoryQr,
    SupplyToPaintQrForm,
    SupplyToPaintNoteForm,
    SupplyToPaintLocateForm,
    NoteForm,
    AttachmentForm,
  },
  props: {

  },
  data: () => ({
    purchaseOrderItems: [],
    inventoryPoId: null,
    inventoryPo: {},
    colorCode: '',
    poNumber: null,
    inventoryClassId: null,
    loading: false,
    qrCodeDialog: false,
    expandedItems: [],
    tableHeaders: [
      { text: 'Item', value: 'item_name' },
      { text: 'Note', value: 'note' },
      { text: 'From Colors', value: 'color_names', width: '15%' },
      { text: 'Length', value: 'length_name' },
      { text: 'Qty Required', value: 'quantity_required' },
      { text: 'Qty Not Fulfilled', value: 'quantity_remained' },
      { text: 'Qty Fulfilled', value: 'quantity_fulfilled' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    purchaseOrderLoading: false,
    selectedItems: [],
    noteTypeIdInventoryPo: 1,
    attachmentTypeIdInventoryPo: 3,
    usersToEmail: [],
    isOptedInEmailNotification: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
      search: (state) => state.search,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  created() {
    this.inventoryPoId = this.$route.params.id;
    this.init();
  },
  methods: {
    init() {
      this.getInventoryPo();
      this.getInventoryPoItems();
    },

    async getInventoryPo() {
      this.loading = true;
      const submitModel = {
        filters: {
          id: this.inventoryPoId,
        },
      };
      const res = await this.$api.inventoryPo.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.poItems = [];
        this.loading = false;
        return;
      }
      const firstIndex = 0;
      this.inventoryPo = res.data[firstIndex];
      this.isOptedInEmailNotification = res.data[firstIndex].user_email.find((x) => x.id === this.user.id);
      this.loading = false;
    },

    async getInventoryPoItems() {
      this.loadingItems = true;
      const submitModel = {
        filters: {
          inventory_po_id: this.inventoryPoId,
          is_include_tags: 1,
        },
      };
      const res = await this.$api.inventoryPoItem.read(submitModel);
      if (!res) {
        this.purchaseOrderItems = [];
        this.loadingItems = false;
        return;
      }
      this.purchaseOrderItems = res;
      this.expandedItems = res.filter((x) => x.tags.length > 0);
      this.loadingItems = false;
    },

    async deleteTag(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.supplyToPaint.delete(id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getInventoryPoItems();
    },

    scanQr() {
      this.qrCodeDialog = true;
    },

    async emailNotification() {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${this.isOptedInEmailNotification ? 'opt out of emails' : 'opt in for emails'}?`,
      });
      if (!confirm) return;
      const res = await this.$api.inventoryPo.get(`toggle/email-notification/${this.inventoryPoId}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.isOptedInEmailNotification = !this.isOptedInEmailNotification;
      this.$bus.$emit('showSuccess');
    },

    sendNoteEmailNotification(noteId) {
      this.$api.inventoryPo.get(`send-note-email/${this.inventoryPo.class.id}/${this.inventoryPoId}/${noteId}`);
    },

    openAttachmentForm() {
      this.$refs.attachmentForm.open(this.inventoryPoId, this.attachmentTypeIdInventoryPo);
    },

    goBack() {
      this.$router.push('/supply-to-paints');
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .5)`;
        return rgba;
      }
      return null;
    },

    returnQrCode(qrCode) {
      this.qrCodeDialog = false;
      this.$refs.paintQrForm.open(qrCode, this.inventoryPoId, this.inventoryPo.po_number);
    },

    itemRowBackground(item) {
      let itemClass = '';
      itemClass += item.index % 2 === 0 ? 'row-even' : 'row-odd';
      itemClass += ` ${item.status_id === 1 ? 'hide-icon' : ''}`;
      return itemClass;
    },

    openNoteEdit(item) {
      this.$refs.noteForm.open(item.id, this.noteTypeIdInventoryPo);
    },

    openLocateForm(item) {
      this.$refs.locateForm.open(item.id, this.inventoryPo.po_number);
    },

    openNoteForm() {
      this.$refs.noteForm.open(this.inventoryPo.id, this.noteTypeIdInventoryPo);
    },

    toggleExpand(item) {
      if (!item) return;
      const index = this.expandedItems.indexOf(item);
      if (index > -1) {
        this.expandedItems.splice(index, 1);
      } else {
        this.expandedItems.push(item);
      }
    },
  },
};
</script>

<style scoped>
span
{
  font-size: 14px;
}
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    /* -webkit-box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%), inset 0px -4px 8px -5px rgb(50 50 50 / 75%); */
    box-shadow: none !important;
}

::v-deep .row-odd{
  background-color: #E8F5E9 !important;
}

::v-deep .row-even{
  background-color: white !important;
}

::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .hide-icon.v-icon.v-icon.v-icon--link {
    display: none !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td
{
  padding: 0 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    padding-right: 4px;
    padding-left: 4px;
}
</style>
