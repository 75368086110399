var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" Physical Count \""+_vm._s(_vm.physicalCountList ? _vm.physicalCountList.name : '')+", as of "+_vm._s(_vm.physicalCountList ? _vm.physicalCountList.date : '')+"\" "),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-add-all":false,"is-state":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Location","items":_vm.locations,"item-text":"name","item-value":"id","clearable":true},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.profilesHeaders,"items":_vm.physicalCounts,"items-per-page":_vm.pagination.itemsPerPage,"footer-props":_vm.footerProps,"loading":_vm.loading,"search":_vm.search,"height":_vm.windowHeight,"fixed-header":"","dense":"","server-items-length":_vm.totalRecords},on:{"update:options":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.inventory_tag",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticClass:"font-weight-bold",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item ? item.inventory_tag : '')+" ")])]}},{key:"item.item_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item ? item.item_name : '')+" ")])]}},{key:"item.length",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.length ? item.length.name : '')+" ")]}},{key:"item.uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.uom ? item.uom.name : '')+" ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.location ? item.location.name : '')+" ")]}},{key:"item.color_code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item ? item.color_code : '')+" ")])]}},{key:"item.is_fritted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_fritted === null ? '-' : item.is_fritted ? 'Fritted' : 'Non-Fritted')+" ")]}},{key:"item.inventory_location",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.inventory_location ? item.inventory_location.name : '')+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? item.quantity : '-')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? item.created_at : '')+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? item.user_full_name : '')+" ")]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{staticClass:"text-end mt-2",attrs:{"cols":"2"}},[_c('h3',{staticClass:"font-weight-bold",style:({color: _vm.isDarkMode ? '' : 'black'})},[_vm._v(" Total Records: "+_vm._s(_vm.totalRecords)+" ")])]),_c('v-col',{staticClass:"text-end mt-2",attrs:{"cols":"2"}},[_c('h3',{staticClass:"font-weight-bold",style:({color: _vm.isDarkMode ? '' : 'black'})},[_vm._v(" Total Quantity: "+_vm._s(_vm.totalQuantity)+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }