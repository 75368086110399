<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="selectedLocationId"
          :items="locations"
          item-value="id"
          item-text="name"
        />
      </v-col>
      <v-col
        class="text-end mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="openForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New moving batch
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="rowsPerPage"
            :search="search"
            :loading="loading"
            dense
            @pagination="updatePagination"
          >
            <template #[`item.is_moved`]="{ item }">
              <v-chip
                small
                :color="item.is_moved ? '#3BA424' : 'orange'"
                text-color="white"
              >
                {{ item.is_moved ? 'MOVED' : 'IN PROGRESS' }}
              </v-chip>
            </template>
            <template #[`item.location`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item && item.location ? item.location.name : '' }}
              </text-highlight>
            </template>
            <template #[`item.inventory_location`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item && item.inventory_location ? item.inventory_location.name : '' }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item && item.user ? item.user.first_name + ' ' + item.user.last_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="webhook"
                class="ml-1 mt-1 mb-1"
                color="orange"
                outlined
                small
                @click="runWebhook(item.id)"
              >
                PDF
              </v-btn>
              <v-btn
                v-if="item && item.is_moved === 0"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="move(item)"
              >
                MOVE
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="success"
                outlined
                small
                @click="redirectItem(item.id)"
              >
                OPEN
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <movement-batch-form
      ref="batchForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import MovementBatchForm from '@/components/forms/movements/MovementBatchForm';
import TextHighlight from 'vue-text-highlight';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  components: {
    MovementBatchForm,
    TextHighlight,
  },
  data: () => ({
    items: [],
    locations: [],
    selectedLocationId: 0,
    rowsPerPage: 20,
    headers: [
      { text: 'Batch#', value: 'id' },
      { text: 'Moving To Location', value: 'location' },
      { text: 'Shelf', value: 'inventory_location' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Records#', value: 'records_number' },
      { text: 'Status', value: 'is_moved', align: 'center' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    loading: false,
    webhook: null,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      movingBatchState: (state) => state.movingBatchState,
    }),
  },
  watch: {
    selectedLocationId(value) {
      this.storeState('location_id', value)
      this.get();
    },

    pagination() {
      //might not need this
      const state = this.movingBatchState;
      state.rowsPerPage = this.pagination.itemsPerPage;
      this.$store.commit('storeMovingBatchState', state);
      this.get();
    },
  },
  created() {
    this.init();
    if (this.movingBatchState) {
      if (this.movingBatchState.rowsPerPage) { this.rowsPerPage = this.movingBatchState.rowsPerPage; }
      if (this.movingBatchState.location_id) { this.selectedLocationId = this.movingBatchState.location_id; }
    }
  },
  methods: {
    init() {
      this.get();
      this.getLocations();
      this.getWebhook();
    },

    storeState(property, value){
      const state = this.movingBatchState;
      state[property] = value;
      this.$store.commit('storeMovingBatchState', state);
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          location_id: this.selectedLocationId,
        },
      };
      this.items = await this.$api.inventoryMovementBatch.read(submitModel);
      this.loading = false;
    },

    async getLocations() {
      this.locations.push({ name: 'All', id: 0 });
      const res = await this.$api.location.get();
      this.locations.push(...res);
    },

    async getWebhook() {
      const movingBatchModuleId = 19;
      const readModel = {
        filters:
        {
          module_id: movingBatchModuleId,
        },
      };
      const res = await this.$api.webhook.read(readModel);
      if (!res || !res[0]) return;
      const firstIndex = 0;
      this.webhook = res[firstIndex];
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.inventoryMovementBatch.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    redirectItem(id) {
      this.$router.push(`/moving-qr-batch/${id}`);
    },

    openForm() {
      this.$refs.batchForm.open();
    },

    openEditForm(id) {
      this.$refs.batchForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async move(item) {
      if (item.records_number === 0) {
        this.$bus.$emit('showError', 'There are no records to move');
        return;
      }
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to move all items within Record# ${item.id} to Location: ${item.location.name}?`,
      });
      if (!confirm) return;
      const res = await this.$api.inventoryMovementBatch.get(`move/${item.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async runWebhook(id) {
      this.$bus.$emit('loading');
      const movingBatchModuleId = 19;
      const submitModel = { id, module_id: movingBatchModuleId };
      const res = await this.$api.webhook.store(submitModel, 'run');
      if (!res) return;
      const pdf = await this.$api.report.download(res.id, 'pdf');
      if (!pdf) {
        this.$bus.$emit('showError', 'Report Failed');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${res.report_title}_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    updatePagination(pagination) {
      this.storeState('rowsPerPage', pagination.itemsPerPage)
    },
  },
};
</script>
