var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-start",attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"ml-2",attrs:{"label":"Packaging Slip#","placeholder":"Enter Packaging Slip#"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.applyPackagingSlipNumberToAll}},[_vm._v(" APPLY ")])]},proxy:true}]),model:{value:(_vm.applyPackagingSlipNumber),callback:function ($$v) {_vm.applyPackagingSlipNumber=$$v},expression:"applyPackagingSlipNumber"}})],1),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.toggleSelectAll}},[_vm._v(" "+_vm._s(_vm.allItemsSelected ? 'Unselect All' : 'Select All')+" ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsToBeReceived,"hide-default-footer":"","items-per-page":-1,"loading":_vm.loading,"dense":"","show-select":""},scopedSlots:_vm._u([{key:"item.history.to_item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.history && item.history.to_item ? ((item.history.to_item.material_id) + " - " + (item.history.to_item.name)) : '')+" ")]}},{key:"item.glass_paint_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.history && item.history.to_glass_paint_code ? ((item.history.to_glass_paint_code.code) + " - " + (item.history.to_glass_paint_code.description)) : '')+" ")]}},{key:"item.fritted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_fritted !== null && item.is_fritted === 1 ? 'Fritted' : 'Non-Fritted')+" ")]}},{key:"item.quantity_to_be_received",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Qty to be Received","placeholder":"Qty to be Received","rules":[function (v) { return /^\d+$/.test(v) || 'Please enter a whole number'; }],"input":"number","min":"0","step":"1"},model:{value:(item.quantity_to_be_received),callback:function ($$v) {_vm.$set(item, "quantity_to_be_received", $$v)},expression:"item.quantity_to_be_received"}})]}},{key:"item.packaging_slip",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Packing Slip#","placeholder":"Packing Slip#"},model:{value:(item.packaging_slip),callback:function ($$v) {_vm.$set(item, "packaging_slip", $$v)},expression:"item.packaging_slip"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.toggleSelection(item)}}},[_vm._v(" "+_vm._s(_vm.isSelected(item) ? 'Unselect' : 'Select')+" ")])]}}],null,true),model:{value:(_vm.selectedItemsReceived),callback:function ($$v) {_vm.selectedItemsReceived=$$v},expression:"selectedItemsReceived"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }