<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        class="text-end mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="openForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Webhook
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.module_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.module_name }}
              </text-highlight>
            </template>
            <template #[`item.description`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.description }}
              </text-highlight>
            </template>
            <template #[`item.file_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.file_name }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.created_by_name }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <webhook-form
      ref="webhookForm"
      @success="get"
    />
    <confirm-form
      ref="confirmForm"
    />
  </v-container>
</template>

<script>
import WebhookForm from '@/components/forms/webhooks/WebhookForm';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    WebhookForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Module', value: 'module_name', width: '15%' },
      { text: 'Description', value: 'description' },
      { text: 'File Name', value: 'file_name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Created By', value: 'created_by' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    loading: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
        },
      };
      this.items = await this.$api.webhook.read(submitModel);
      this.loading = false;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.webhook.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    openForm() {
      this.$refs.webhookForm.open();
    },

    openEditForm(id) {
      this.$refs.webhookForm.open(id);
    },
  },
};
</script>

<style scoped>

</style>
