import Api from '@/api/Api';

class ReportApi extends Api {
  constructor() {
    super('reports', 'reports');
  }

  async download(id, type) {
    const res = await this.get(`download/${type}/${id}`, { responseType: 'blob' });
    return res;
  }
}
const reportApi = new ReportApi();

export default reportApi;
