<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-spacer />
      <v-col
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Color Price
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.code`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.code }}
              </text-highlight>
            </template>
            <template #[`item.extrusion_price`]="{ item }">
              <v-row>
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.extrusion_price }}/ft
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.sheet_price`]="{ item }">
              <v-row>
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.sheet_price }}/sqft
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.min_setup_price`]="{ item }">
              <v-row>
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.min_setup_price ? parseFloat(item.min_setup_price).toFixed(2) : '0.00' }}
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.min_order_price`]="{ item }">
              <v-row>
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.min_order_price ? parseFloat(item.min_order_price).toFixed(2) : '0.00' }}
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.additional_expense`]="{ item }">
              <v-row>
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.additional_expense }}
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.inventory_hollow_price`]="{ item }">
              <v-row v-if="item.inventory_hollow_price">
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.inventory_hollow_price }}
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.inventory_solid_price`]="{ item }">
              <v-row v-if="item.inventory_solid_price">
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.inventory_solid_price }}
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.weight_adjustment_price`]="{ item }">
              <v-row v-if="item.weight_adjustment_price">
                <v-col class="text-end">
                  <text-highlight
                    :queries="search"
                  >
                    ${{ item.weight_adjustment_price }}
                  </text-highlight>
                </v-col>
              </v-row>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEdit(item.id)"
              >
                EDIT
              </v-btn>
              <!-- <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn> -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <inventory-po-color-price-form
      ref="form"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import InventoryPoColorPriceForm from '@/components/forms/inventoryPos/InventoryPoColorPriceForm';
import { mapState } from 'vuex';

export default {
  components: {
    InventoryPoColorPriceForm,
  },
  data: () => ({
    items: [],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Code', value: 'code' },
      { text: 'Extrusion', value: 'extrusion_price', class: 'text-end' },
      { text: 'Sheet', value: 'sheet_price', class: 'text-end' },
      { text: 'Min. Setup Price', value: 'min_setup_price', class: 'text-end' },
      { text: 'Min. Order Price', value: 'min_order_price', class: 'text-end' },
      { text: 'Additional Expense', value: 'additional_expense', class: 'text-end' },
      { text: 'Hollow Price', value: 'inventory_hollow_price', class: 'text-end' },
      { text: 'Solid Price', value: 'inventory_solid_price', class: 'text-end' },
      { text: 'Adjustment Price', value: 'weight_adjustment_price', class: 'text-end' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    loading: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
        },
      };
      this.items = await this.$api.inventoryPoColorPrice.read(submitModel);
      this.loading = false;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.inventoryPoColorPrice.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.form.open();
    },

    openEdit(id) {
      this.$refs.form.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },
  },
};
</script>
