import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;

class InventoryMPQRsApi extends Api {
  constructor() {
    super('inventory_tags/multi-products', 'inventory_tags/multi-products');
  }

  async submitMovement(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post(`/${this.endpoint}/movement`, data);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }
}
const inventoryMPQRsApi = new InventoryMPQRsApi();

export default inventoryMPQRsApi;
