<template>
  <v-container
    fluid
    class="px-4 pt-0"
  >
    <v-row class="py-3">
      <v-col
        cols="4"
        class="pb-0"
      >
        <v-tabs
          v-model="selectedTab"
          @change="changeTab"
        >
          <v-tab> current statuses </v-tab>
          <v-tab> movements </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        class="text-end"
      >
        <v-row fill-height>
          <v-col class="d-flex justify-end align-end">
            <v-btn
              class="mr-2"
              color="primary"
              outlined
              @click="generatePdf"
            >
              pdf
            </v-btn>
            <v-btn
              color="primary"
              outlined
              @click="generateExcel"
            >
              excel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="selectedTab === 0">
      <v-col
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="activeJobsSelect"
          label="Active Jobs"
          :items="activeJobsList"
          item-text="location_simple"
          item-value="id"
          clearable
          :loading="activeJobsLoad"
        />
      </v-col>
      <v-col
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="activeUserPossessionsSelect"
          label="Active User Possessions"
          :items="activeUserPossessionsList"
          item-text="name"
          item-value="id"
          clearable
          :loading="activeUserPossessionsLoad"
        />
      </v-col>
      <v-col
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="activeWarehouseShelfsSelect"
          label="Active Warehouse Shelfs"
          :items="activeWarehouseShelfsList"
          clearable
          :loading="activeWarehouseShelfsLoad"
        />
      </v-col>
      <v-col
        cols="2"
        class="pt-0 pb-0"
      >
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="tableFilters.from"
              class="date-filter"
              label="From"
              readonly
              v-bind="attrs"
              clearable
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="tableFilters.from"
            @input="fromMenu = false"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="2"
        class="pt-0 pb-0"
      >
        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="tableFilters.to"
              class="date-filter"
              label="To"
              readonly
              v-bind="attrs"
              clearable
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="tableFilters.to"
            @input="toMenu = false"
          />
        </v-menu>
      </v-col>
    </v-row>
    <CurrentStatuses
      v-if="selectedTab === 0"
      ref="currentStatuses"
    />
    <Movements
      v-if="selectedTab === 1"
      ref="movements"
    />
    <Snackbar ref="snackbar" />
    <EquipmentMovementForm
      ref="equipmentMovementForm"
      @movement-success="notifyMovementSuccess"
      @movement-fail="notifyMovementFail"
    />
  </v-container>
</template>

<script>
import CurrentStatuses from '@/components/Equipment/CurrentStatuses';
import Movements from '@/components/Equipment/Movements';
import EquipmentMovementForm from '@/components/forms/EquipmentMovementForm';
import Snackbar from '@/components/Snackbar';
import EquipmentItemsApi from '@/api/EquipmentItemsApi';
import EquipmentConditionsApi from '@/api/EquipmentConditionsApi';
import { mapState } from 'vuex';
import equipmentMovementsApi from '../api/EquipmentMovementsApi';

export default {
  components: {
    CurrentStatuses,
    Movements,
    EquipmentMovementForm,
    Snackbar,
  },
  data: () => ({
    selectedTab: 0,
    equipmentList: [],
    jobsList: [],
    conditionsList: [],
    fromMenu: false,
    toMenu: false,
    tableFilters: {
      from: null,
      to: null,
    },
    activeJobsSelect: undefined,
    activeUserPossessionsSelect: undefined,
    activeWarehouseShelfsSelect: undefined,
    activeJobsList: [],
    activeUserPossessionsList: [],
    activeWarehouseShelfsList: [],
    activeJobsLoad: false,
    activeUserPossessionsLoad: false,
    activeWarehouseShelfsLoad: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
    tableFilters: {
      deep: true,
      handler() {
        // this.updateFilters();
        this.$refs.currentStatuses.updateFilters(this.tableFilters);
      },
    },
    selectedTab: {
      handler() {
        if (this.selectedTab == 0) {
          this.activeWarehouseShelfsSelect = undefined;
          this.activeUserPossessionsSelect = undefined;
          this.activeJobsSelect = undefined;
        }
      },
    },

    activeJobsSelect() {
      this.$refs.currentStatuses.loadJobStatuses(this.activeJobsSelect);
    },
    activeUserPossessionsSelect() {
      this.$refs.currentStatuses.loadUserPossessionStatuses(this.activeUserPossessionsSelect);
    },
    activeWarehouseShelfsSelect() {
      this.$refs.currentStatuses.loadWarehouseShelfStatuses(this.activeWarehouseShelfsSelect);
    },
    search() {
      this.updateSearch();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchEquipmentList();
      this.fetchConditions();
      this.fetchJobs();
      this.getActiveJobs();
      this.getActiveUserPossessions();
      this.getActiveWarehouseShelfs();

      const selectedTab = this.$store.getters.selectedEquipmentTab;

      if (selectedTab) {
        this.selectedTab = selectedTab;
      }
    },
    changeTab() {
      // Keep current tab in local storage
      this.$store.commit('storeEquipmentTab', this.selectedTab);
    },
    async fetchEquipmentList() {
      this.equipmentList = await EquipmentItemsApi.getList();
      this.equipmentList.map((x) => {
        x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
          x.model
        } / ${x.serial_number ? x.serial_number : '-'}`;
      });
      this.$store.commit('storeEquipmentList', this.equipmentList);
    },
    async fetchConditions() {
      this.conditionsList = await EquipmentConditionsApi.getList();
      this.$store.commit('storeConditionsList', this.conditionsList);
    },
    async fetchJobs() {
      const readModel = {
        filters:
        {
        },
      };
      this.jobsList = await this.$api.job.read(readModel);
      this.jobsList.map((x) => (x.name = `Job ${x.code}: ${x.address}`));
      this.$store.commit('storeJobsList', this.jobsList);
    },
    async getActiveJobs() {
      this.activeJobsLoad = true;
      this.activeJobsList = await equipmentMovementsApi.getActiveJobs();
      this.activeJobsLoad = false;
    },
    async getActiveUserPossessions() {
      this.activeUserPossessionsLoad = true;
      this.activeUserPossessionsList = await equipmentMovementsApi.getActiveUserPossessions();
      this.activeUserPossessionsLoad = false;
    },
    async getActiveWarehouseShelfs() {
      this.activeWarehouseShelfsLoad = true;
      this.activeWarehouseShelfsList = await equipmentMovementsApi.getActiveWarehouseShelfs();
      this.activeWarehouseShelfsLoad = false;
    },

    generatePdf() {
      if (this.selectedTab === 0) {
        this.$refs.currentStatuses.generatePdf();
      } else if (this.selectedTab === 1) {
        this.$refs.movements.generatePdf();
      }
    },
    generateExcel() {
      if (this.selectedTab === 0) {
        this.$refs.currentStatuses.generateExcel();
      } else if (this.selectedTab === 1) {
        this.$refs.movements.generateExcel();
      }
    },
    // updateFilters() {
    //   if (this.tableFilters.from != null && this.tableFilters.to != null) this.$refs.currentStatuses.updateFilters(this.tableFilters);
    //   else if (this.tableFilters.from == null && this.tableFilters.to == null) this.$refs.currentStatuses.fetchStatuses();
    // },
    updateSearch() {
      if (this.selectedTab === 0) {
        this.$refs.currentStatuses.updateSearch(this.search);
      } else if (this.selectedTab === 1) {
        this.$refs.movements.updateSearch(this.search);
      }
    },
    notifyMovementSuccess() {
      this.$refs.snackbar.showSuccess('Success!');

      // Refresh the page to see the updated status or added movement
      this.selectedTab === 0
        ? this.$refs.currentStatuses.fetchStatuses()
        : this.$refs.movements.fetchMovements();
    },
    notifyMovementFail() {
      this.$refs.snackbar.showError('Equipment movement could not be saved.');
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filter {
  display: flex;
  flex: 0 1 10rem;
}
</style>
