import Api from './Api';

class RegionsApi extends Api {
  constructor() {
    super('regions', 'regions');
  }
}
const regionsApi = new RegionsApi();

export default regionsApi;
