<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <inventory-class-select-element
          v-model="selectedClassId"
          :is-state="true"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="selectedProblemId"
          label="Problem"
          :items="problems"
          item-text="name"
          item-value="id"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="selectedTypeId"
          label="Type"
          :items="types"
          item-text="name"
          item-value="id"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="watchlistHeaders"
          :items="items"
          :footer-props="itemsTableFooterProps"
          :items-per-page="pagination.itemsPerPage"
          :loading="loading"
          dense
          :search="search"
          :server-items-length="total"
          @pagination="updatePagination"
        >
          <template #item="{ item }">
            <tr
              :style="{ backgroundColor: item && item.problem ? hexToRgb(item.problem.color) : 'white' }"
            >
              <td
                class="text-center"
                style="font-weight: bold;"
              >
                <text-highlight
                  :queries="search"
                >
                  {{ item.id }}
                </text-highlight>
              </td>
              <td class="text-center">
                <v-chip
                  small
                  :color="item && item.class ? item.class.color : 'black'"
                  text-color="white"
                >
                  {{ item && item.class ? item.class.name : '' }}
                </v-chip>
              </td>
              <td
                style="font-weight: bold;"
                class="text-center"
              >
                {{ item && item.problem ? item.problem.name : '' }}
              </td>

              <td
                class="text-center"
              >
                <v-chip
                  small
                  :color="item && item.type ? item.type.color : 'black'"
                  text-color="white"
                >
                  {{ item && item.type ? item.type.name : '' }}
                </v-chip>
              </td>

              <td class="text-center">
                <text-highlight
                  :queries="search"
                >
                  {{ item.name ? item.name : '' }}
                </text-highlight>
              </td>
              <td>
                <text-highlight
                  :queries="search"
                >
                  {{ item.fpgest_name ? item.fpgest_name : '' }}
                </text-highlight>
              </td>
              <td>
                <v-tooltip
                  v-if="item.is_info"
                  top
                  color="black"
                >
                  <template #activator="{ on }">
                    <v-icon
                      color="green"
                      dark
                      v-on="on"
                      @click="openDialog(item)"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                  <span>{{ item.record_info }}</span>
                </v-tooltip>
              </td>
              <td>
                {{ item.created_at }}
              </td>
              <td>
                <v-row>
                  <v-col class="mt-1">
                    {{ item.status }}
                  </v-col>
                  <v-col cols="3">
                    <v-tooltip
                      v-if="item.status_tip"
                      top
                      color="black"
                    >
                      <template #activator="{ on }">
                        <v-icon
                          color="orange"
                          dark
                          v-on="on"
                        >
                          fas fa-question-circle
                        </v-icon>
                      </template>
                      <span>{{ item.status_tip }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </td>
              <td class="text-end">
                <v-btn
                  v-if="item.is_delete"
                  class="ml-1 mt-1 mb-1"
                  color="error"
                  outlined
                  small
                  @click="softDelete(item)"
                >
                  REMOVE
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="pb-4">
          <span class="headline">ERP Identifier <span class="font-weight-bold">{{ dialogERPIdentifier }}</span></span>
          <span
            v-if="dialogFPGESTIdentifier"
            class="headline"
          >FPGEST Identifier <span class="font-weight-bold">{{ dialogFPGESTIdentifier }}</span></span>
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="(it, index) in dialogTextElements"
            :key="index"
          >
            <v-col class="pt-0 pb-0">
              <span style="color: black; font-size: 14px;">{{ it }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';

import { mapState } from 'vuex';

export default {
  components: {
    TextHighlight,
  },
  data: () => ({
    items: [],
    watchlistHeaders: [
      { text: 'Alert#', value: 'id', align: 'center' },
      { text: 'Class', value: 'inventory_class', align: 'center' },
      { text: 'Problem', value: 'problem', align: 'center' },
      { text: 'Type', value: 'data_type', align: 'center' },
      { text: 'ERP Identifier', value: 'name', align: 'center' },
      { text: 'FPGEST Identifier', value: 'fpgest_name' },
      { text: '', value: 'record_info' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Status', value: 'status', width: '25%' },
      {
        text: 'Actions', value: 'action', align: 'end', width: '5%',
      },
    ],
    itemsTableFooterProps: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    types: [],
    problems: [],
    selectedTypeId: 0,
    selectedClassId: null,
    selectedProblemId: 0,
    alertProblemIdDuplicate: 1,
    alertProblemIdMissing: 2,
    alertProblemIdDataSync: 3,
    loading: false,
    dialog: false,
    dialogERPIdentifier: '',
    dialogFPGESTIdentifier: '',
    dialogTextElements: [],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    total: 0,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      alertsState: (state) => state.alertsState
    }),
  },
  watch: {
    selectedTypeId(value) {
      this.storeState('type_id', value)
      this.get();
    },
    selectedProblemId(value) {
      this.storeState('problem_id', value)
      this.get();
    },
    selectedClassId() {
      this.get();
    },
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
  },

  created() {
    if(this.alertsState){
      if(this.alertsState.pagination) {this.pagination = this.alertsState.pagination}
      if(this.alertsState.type_id) {this.selectedTypeId = this.alertsState.type_id}
      if(this.alertsState.problem_id) {this.selectedProblemId = this.alertsState.problem_id}
    }
    this.init();
  },
  methods: {
    init() {
      this.get();
      this.getTypes();
      this.getProblems();
    },

    storeState(property, value){
      const state = this.alertsState;
      state[property] = value;
      this.$store.commit('storeAlertsState', state);
    },

    async get() {
      if (this.selectedClassId === null) return;
      this.loading = true;
      this.items = [];
      this.readModel = {
        filters:
        {
          inventory_class_id: this.selectedClassId,
          type_id: this.selectedTypeId,
          problem_id: this.selectedProblemId,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.alert.readData(this.readModel);
      this.loading = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.items = [];
        this.total = 0;
        return;
      }
      this.items = res.data;
      this.total = res.total;
    },

    async getTypes() {
      const res = await this.$api.alertType.get();
      this.types.push({ id: 0, name: 'All' });
      this.types.push(...res);
    },

    async getProblems() {
      const res = await this.$api.alertProblem.get();
      this.problems.push({ id: 0, name: 'All' });
      this.problems.push(...res);
    },

    async softDelete(item) {
      const propVal = confirm(`Are you sure that you want to remove '${item.name}' from this list only?`);
      if (!propVal) return;

      const res = await this.$api.alert.delete(item.id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },

    openDialog(item) {
      this.dialog = true;
      this.dialogERPIdentifier = item.name;
      this.dialogFPGESTIdentifier = item.fpgest_name;
      this.dialogTextElements = item.record_info.split(', ');
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .2)`;
        return rgba;
      }
      return null;
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { 
        this.pagination = pagination; 
        this.storeState('pagination', pagination)
      }
    },
  },
};

</script>
