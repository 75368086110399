var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"dense":"","server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.glass_purchase_order_item && item.glass_purchase_order_item.class ? item.glass_purchase_order_item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.glass_purchase_order_item && item.glass_purchase_order_item.class ? item && item.glass_purchase_order_item && item.glass_purchase_order_item.class.name : '')+" ")])]}},{key:"item.po_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"500"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.glass_purchase_order_item.po_number ? item.glass_purchase_order_item.po_number : '')+" ")])]}},{key:"item.line",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"500"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.glass_purchase_order_item.item_index ? item.glass_purchase_order_item.item_index : '')+" ")])]}},{key:"item.job",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_code ? ("" + (item.job_code)) : ''))])],1),_vm._v(" - "),_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_name ? item.job_name : item.job_name))]),_vm._v(" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_reference ? ("; " + (item.job_reference)) : ''))])],1)],1)]}},{key:"item.moved_from",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-size":"16px","font-weight":"600"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.move_from ? item.move_from.slip_number : '')+" ")])]}},{key:"item.arrow",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"#000","size":"20"}},[_vm._v(" mdi-arrow-right ")])]}},{key:"item.moved_to",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-size":"16px","font-weight":"600"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.move_to ? item.move_to.slip_number : '')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at ? item.created_at : '')+" ")])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_by_full_name ? item.created_by_full_name : '')+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }