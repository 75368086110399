<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        class="pa-6"
        style="background-color: #FFFDE7; border: 1.5px solid #E8E8DD;"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pr-0 pt-2"
          >
            <v-btn
              color="primary"
              icon
              @click="isOpen = !isOpen"
            >
              <v-icon v-if="isOpen">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <span style="color: #000000; font-size: 16px; font-weight: bold;">OTHER (SHIPPING, HANDLING, ETC.) ({{ items.length }})</span>
          </v-col>
          <v-col
            v-if="!isItemOpen"
            class="text-end"
          >
            <v-btn
              :disabled="disabled"
              color="primary"
              outlined
              @click="open()"
            >
              <v-icon
                small
                class="mr-1"
              >
                fas fa-plus
              </v-icon>
              New Other Charge
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="isOpen">
          <v-col>
            <v-row
              v-if="isItemOpen || items.length > 0"
            >
              <v-col
                cols="12"
                class=""
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :items-per-page="-1"
                    dense
                    item-key="temp_id"
                  >
                    <template #[`item.part`]="{ item }">
                      <span v-if="item.inventory_po_other_charge">
                        {{ item.inventory_po_other_charge.name }}
                      </span>
                    </template>
                    <template #[`item.price`]="{ item }">
                      {{ currency ? currency.symbol_short: '' }} {{ parseFloat(item.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </template>
                    <template #[`item.quantity`]="{ item }">
                      {{ item.quantity }} pcs.
                    </template>
                    <template #[`item.subtotal`]="{ item }">
                      {{ currency ? currency.symbol_short: '' }} {{ parseFloat(item.price * item.quantity).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </template>
                    <template #[`item.tax`]="{ item }">
                      {{ currency ? currency.symbol_short: '' }} {{ parseFloat(getItemTax(item)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </template>
                    <template #[`item.total`]="{ item }">
                      {{ currency ? currency.symbol_short: '' }} {{ parseFloat(getItemTotal(item)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        :disabled="disabled"
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="openEdit(item)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        :disabled="disabled"
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        @click="softDelete(item)"
                      >
                        DELETE
                      </v-btn>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <th />
                        <th />
                        <th>
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ getTotalQuantityItems() }} pcs.</span>
                        </th>
                        <th
                          class="text-end pr-1"
                        >
                          <span style="font-weight: bold; font-size: 14px;">{{ currency ? currency.symbol_short: '' }} {{ parseFloat(getTotalUnitPrice()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </th>
                        <th class="text-end pr-1">
                          <span style="font-weight: bold; font-size: 14px;">{{ currency ? currency.symbol_short: '' }} {{ parseFloat(getSubtotal()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </th>
                        <th class="text-end pr-1">
                          <span style="font-weight: bold; font-size: 14px;">{{ currency ? currency.symbol_short: '' }} {{ parseFloat(getTotalTax()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </th>
                        <th class="text-end pr-1">
                          <span style="font-weight: bold; font-size: 14px;">{{ currency ? currency.symbol_short: '' }} {{ parseFloat(getTotal()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </th>
                        <th />
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="isItemOpen">
              <v-col>
                <v-row>
                  <v-col class="pb-0">
                    <zig-zag bg-color="yellow" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span style="font-size: 16px; color: #000000; font-weight: bold;"> {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'NEW' }} OTHER CHARGE</span>
                  </v-col>
                </v-row>
                <v-form ref="form">
                  <v-row>
                    <v-col
                      md="6"
                      sm="8"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0">
                          <span style="color: #000000;">Part Type</span><RedAsterisk />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-1">
                          <v-autocomplete
                            v-model="item.inventory_item_id"
                            :items="inventoryPoOtherCharges"
                            item-text="name"
                            item-value="id"
                            solo
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="3"
                      md="3"
                      sm="6"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0">
                          <span style="color: #000000;">Quantity</span><RedAsterisk />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-1">
                          <v-text-field
                            v-model="item.quantity"
                            placeholder="Enter Quantity"
                            solo
                            suffix="pcs"
                            type="number"
                            min="0"
                            :rules="[(v) => !!v || 'Quantity is required']"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="3"
                      md="3"
                      sm="6"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0">
                          <span style="color: #000000;">Unit Price</span><RedAsterisk />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-1">
                          <v-text-field
                            v-model="item.price"
                            placeholder="Enter Unit Price"
                            solo
                            prefix="$"
                            type="number"
                            min="0"
                            :rules="[(v) => !!v || 'Unit Price is required']"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-col class="pt-0">
                    <v-row>
                      <v-col
                        class="pb-0"
                      >
                        <span style="color: #000000;">Note</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="pt-0"
                      >
                        <v-textarea
                          v-model="item.note"
                          outlined
                          rows="3"
                          :style="{backgroundColor: isDarkMode ? '#1E1E1E' : '#FFFFFF'}"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      class="mr-2"
                      @click="close()"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="pl-2"
                  >
                    <v-btn
                      color="primary"
                      @click="addItem"
                    >
                      {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'CREATE' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ZigZag from '@/components/elements/ZigZag';

export default {
  components: {
    ZigZag,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isTax: {
      type: Number,
      default: 1,
    },
    currency: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    items: [],
    inventoryPoOtherCharges: [],
    item: null,
    itemDto: {
      id: 0,
      inventory_po_other_charge_id: null,
      price: null,
      quantity: null,
      note: null,
    },
    isOpen: true,
    isItemOpen: false,
    tax: 0.13,
    headers: [
      { text: 'Part Type', value: 'part', width: '15%' },
      { text: 'Note', value: 'note', width: '20%' },
      {
        text: 'Quantity', value: 'quantity', width: '10%',
      },
      {
        text: 'Unit Price', value: 'price', width: '10%', align: 'end',
      },
      {
        text: 'Subtotal', value: 'subtotal', width: '10%', align: 'end',
      },
      {
        text: 'Tax', value: 'tax', width: '10%', align: 'end',
      },
      {
        text: 'Total', value: 'total', width: '10%', align: 'end',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      },
    ],
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.init();
    this.item = { ...this.itemDto };
    this.getInventoryPoOtherCharges();
  },
  methods: {
    init() {

    },

    async get(inventoryPoId) {
      const readModel = {
        filters:
        {
          inventory_po_id: inventoryPoId,
        },
      };

      const res = await this.$api.inventoryPoExpense.read(readModel);
      if (!res) {
        this.items = [];
        return;
      }
      this.items = res;
    },

    async getInventoryPoOtherCharges() {
      const res = await this.$api.inventoryPoOtherCharge.get();
      if (!res || res.length === 0) {
        this.inventoryPoOtherCharges = [];
        return;
      }
      this.inventoryPoOtherCharges = res;
      const firstIndex = 0;
      this.item.inventory_item_id = res[firstIndex].id;
    },

    addItem() {
      if (!this.$refs.form.validate()) return;
      const item = this.items.find((x) => x.temp_id === this.item.temp_id);
      if (item) {
        const index = this.items.findIndex((x) => x.temp_id === this.item.temp_id);
        this.items.splice(index, 1, { ...this.item });
      } else {
        const inventoryItem = this.inventoryPoOtherCharges.find((x) => x.id === this.item.inventory_item_id);
        if (inventoryItem) {
          this.item.inventory_po_other_charge = inventoryItem;
          this.item.inventory_po_other_charge_id = inventoryItem.id;
        }
        this.items.push(JSON.parse(JSON.stringify(this.item)));
      }

      this.close();
    },

    open() {
      this.getInventoryPoOtherCharges();
      this.isOpen = true;
      this.isItemOpen = true;
      this.item = { ...this.itemDto };
      this.item.temp_id = Date.now();
    },

    close() {
      this.isItemOpen = false;
    },

    openEdit(item) {
      this.item = { ...item };
      this.isItemOpen = true;
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (!confirm) return;
      if (item.id) {
        const res = await this.$api.inventoryPoExpense.delete(item.id);
        if (!res) {
          this.$bus.$emit('showError');
          return;
        }
      }
      const index = this.items.findIndex((x) => x.temp_id === item.temp_id);
      this.items.splice(index, 1);
    },

    getItemTax(item) {
      const tax = this.isTax ? this.tax : 0;
      return item.price * item.quantity * tax;
    },

    getItemTotal(item) {
      const tax = this.isTax ? this.tax : 0;
      return (item.price * item.quantity) + (item.price * item.quantity * tax);
    },

    getTotalUnitPrice() {
      let totalUnitPrice = 0;
      this.items.forEach((item) => {
        totalUnitPrice += item.price;
      });
      return totalUnitPrice;
    },

    getSubtotal() {
      let subtotal = 0;
      this.items.forEach((item) => {
        subtotal += item.price * item.quantity;
      });
      return subtotal;
    },

    getTotal() {
      let total = 0;
      const tax = this.isTax ? this.tax : 0;
      this.items.forEach((item) => {
        total += (item.price * item.quantity) + (item.price * item.quantity * tax);
      });
      return total;
    },

    getTotalTax() {
      let totalTax = 0;
      const tax = this.isTax ? this.tax : 0;
      this.items.forEach((item) => {
        totalTax += item.price * item.quantity * tax;
      });
      return totalTax;
    },

    getTotalQuantityItems() {
      let totalQuantity = 0;
      this.items.forEach((item) => {
        totalQuantity += parseInt(item.quantity, 10);
      });
      return totalQuantity;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #FFFFFF !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th > span {
  color: #000000;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot
{
  border: 1px solid rgba(0,0,0,0.4) !important;
}
</style>
