import Api from './Api';

class TimeOffRequestsApi extends Api {
  constructor() {
    super('time-offs/requests', 'time-offs/requests');
  }
}
const timeOffRequestsApi = new TimeOffRequestsApi();

export default timeOffRequestsApi;
