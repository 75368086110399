import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
let cancelReadSecondary;
class GlassBatchApi extends Api {
  constructor() {
    super('glasses/batches', 'glasses/batches');
  }

  async readBatches(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }

  async readBatchHistory(data) {
    if (cancelReadSecondary !== undefined) {
      cancelReadSecondary();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadSecondary = c;
      }),

    }, 'read-history');
    return res;
  }
}
const glassBatchApi = new GlassBatchApi();

export default glassBatchApi;
