<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="pb-0 pt-0"
        cols="auto"
      >
        <v-tabs
          v-model="selectedTab"
        >
          <v-tab>
            ACTIVE
          </v-tab>
          <v-tab>
            ARCHIVED
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
          :is-inst="false"
          :is-vwd-glass="false"
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobs"
          hide-details="true"
          item-value="id"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-spacer />
      <v-col
        class="mt-4 pb-0 pr-0"
        cols="auto"
      >
        <v-menu
          v-model="filterMenu"
          transition="scale-transition"
          offset-y
          min-width="350"
          nudge-left="50"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="filterMenu = !filterMenu"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-filter
              </v-icon>
              FILTERS
            </v-btn>
          </template>
          <v-card
            class="pa-5"
            style="border-radius: 7px;"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <span style="font-size: 22px !important; font-weight: bold;">Filters</span>
                  </v-col>
                  <v-col class="text-end pr-1">
                    <v-btn
                      icon
                      :style="{color: isDarkMode ? '' : '#000000'}"
                      @click="filterMenu = !filterMenu"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterSupplierId"
                      label="Supplier"
                      :items="suppliers"
                      item-value="id"
                      clearable
                      hide-details="true"
                    >
                      <template #selection="{ item }">
                        <span>
                          <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                        </span>
                      </template>
                      <template #item="{ item }">
                        <span>
                          <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterPoTypeId"
                      :items="inventoryPoTypes"
                      item-text="name"
                      item-value="id"
                      :clearable="true"
                      hide-details="true"
                    >
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <v-chip
                          v-if="item"
                          small
                          :color="item.color"
                          text-color="white"
                          class="mr-2"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #item="{ item }">
                        <v-chip
                          v-if="item"
                          small
                          :color="item.color"
                          text-color="white"
                          class="mr-2"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                      <template slot="label">
                        Type
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterColorId"
                      :items="colors"
                      item-text="code"
                      item-value="id"
                      :clearable="true"
                      hide-details="true"
                    >
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #item="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <template slot="label">
                        Color
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterLocationId"
                      :items="locations"
                      item-text="name"
                      item-value="id"
                      :clearable="true"
                      hide-details="true"
                    >
                      <template slot="label">
                        Location
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterStatusId"
                      :items="statuses"
                      item-text="name"
                      item-value="id"
                      :clearable="true"
                      hide-details="true"
                    >
                      <template #selection="{ item }">
                        <v-chip
                          v-if="item"
                          small
                          :color="item.color"
                          text-color="white"
                          class="mr-2"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #item="{ item }">
                        <v-chip
                          v-if="item"
                          small
                          :color="item.color"
                          text-color="white"
                          class="mr-2"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                      <template slot="label">
                        Status
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menuRequiredBy"
                      v-model="dateMenuRequiredBy"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterRequiredBy"
                          label="Required By Date"
                          readonly
                          v-bind="attrs"
                          append-icon="mdi-calendar"
                          v-on="on"
                          @change="$refs.menuRequiredBy.save(filterRequiredBy)"
                        />
                      </template>
                      <v-date-picker
                        v-model="filterRequiredBy"
                        no-title
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="filterRequiredBy = null"
                        >
                          Clear
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      @click="filterMenu = !filterMenu"
                    >
                      <span style="font-size: 14px !important;">Close</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      @click="clearAllFilters"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
      <v-col
        cols="auto"
        class="mt-4 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="openNewPo"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Item
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="poHeaders"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <span class="font-weight-bold">
                    <text-highlight :queries="search">
                      {{ item.po_number ? item.po_number : '-' }}
                    </text-highlight>
                  </span>
                  <!-- <v-chip
                    v-if="item.is_cloned == 1"
                    small
                    color="#42A5F5"
                    text-color="white"
                  >
                    CLONE
                  </v-chip> -->
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </td>
                <td>
                  <span>
                    <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                  </span>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.supplier_name ? item.supplier_name : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.color_code ? item.color_code : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.location_name ? item.location_name : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.shipping_option_name ? item.shipping_option_name : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.fob_option_name ? item.fob_option_name : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.required_by ? item.required_by : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.total_quantity_items ? item.total_quantity_items : '0' }}
                  </text-highlight>
                </td>
                <td class="text-end">
                  <text-highlight
                    :queries="search"
                    style="white-space: nowrap;"
                  >
                    {{ item.currency ? item.currency.symbol : '$' }} {{ item.total ? parseFloat(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.created_at_short ? item.created_at_short : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.created_by_name ? item.created_by_name : '' }}
                  </text-highlight>
                </td>
                <td class="text-center">
                  <div class="d-flex justify-center">
                    <v-select
                      v-model="item.status_id"
                      :items="statuses"
                      hide-details
                      item-text="name"
                      item-value="id"
                      dense
                      solo
                      class="select-status"
                      :background-color="item.status ? item.status.color : ''"
                      style="max-width: 140px; border-radius: 20px; font-size: 12px; color: white !important;"
                      @change="changeStatus(item)"
                    >
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #item="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          style="cursor: pointer;"
                          :color="item ? item.color : 'white'"
                        >
                          <span style="white-space:nowrap; color: #FFFFFF">{{ item.name }}</span>
                        </v-chip>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <span style="color: white; white-space:nowrap;">{{ item.name }}</span>
                      </template>
                    </v-select>
                  </div>
                </td>
                <td
                  class="text-end"
                  style="white-space: nowrap;"
                >
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="open(item)"
                  >
                    OPEN
                  </v-btn>
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        elevation="0"
                        fab
                        outlined
                        x-small
                        v-bind="attrs"
                        tile
                        color="primary"
                        style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="pa-3"
                    >
                      <v-list-item
                        v-if="item.type_id == 2"
                        @click="redirectSupplyToPaint(item)"
                      >
                        <v-list-item-title :style="`color: orange; font-weight: 600;`">
                          <v-row align="center">
                            <v-col class="pt-4">
                              REDIRECT SUPPLY TO PAINT
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="archive(item.id)"
                      >
                        <v-list-item-title :style="`color: purple; font-weight: 600;`">
                          <v-row align="center">
                            <v-col>
                              {{ selectedTab === 0 ? 'ARCHIVE' : 'ACTIVATE' }}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="clone(item.id)"
                      >
                        <v-list-item-title :style="`color: #00897B; font-weight: 600;`">
                          <v-row align="center">
                            <v-col>
                              CLONE
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="runWebhook(item)"
                      >
                        <v-list-item-title :style="`color: green; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                color="green"
                              >
                                fas fa-file
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              PDF
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openPackagingSlipsForm(item)"
                      >
                        <v-list-item-title :style="`color: #2196F3; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                color="blue"
                              >
                                fas fa-file-alt
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              RECEIVING SLIP
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.total_quantity_items > 0 && item.supply_to_paint_status_id != supplyToPaintStatusIdNew"
                        @click="softDelete(item)"
                      >
                        <v-list-item-title :style="`color: ${item.total_quantity_items > 0 && item.supply_to_paint_status_id != supplyToPaintStatusIdNew ? 'rgba(0,0,0,0.26)' : 'red'}; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                :color="item.total_quantity_items > 0 && item.supply_to_paint_status_id != supplyToPaintStatusIdNew ? 'rgba(0, 0, 0, 0.26)' : 'red'"
                              >
                                fas fa-trash
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              DELETE
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
    <inventory-po-form
      ref="inventoryPoForm"
      :good-entrance="goodEntrance"
      @changeInventoryPoStatus="changeStatus"
      @success="success"
      @close-form="closeForm"
    />
    <inventory-po-receiving-slip-form
      ref="inventoryPoReceivingSlipForm"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InventoryPoForm from '@/components/forms/inventoryPos/InventoryPoForm.vue';
import InventoryPoReceivingSlipForm from '@/components/forms/inventoryPos/InventoryPoReceivingSlipForm.vue';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    InventoryPoForm,
    InventoryPoReceivingSlipForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    jobs: [],
    poHeaders: [
      {
        text: 'PO#', value: 'po_number', width: '2%', class: 'pl-1 pr-1',
      },
      {
        text: 'Class', value: 'inventory_class', align: 'center', width: '5%', class: 'pl-1 pr-1',
      },
      {
        text: 'Job', value: 'job_full_name', width: '15%', class: 'pl-1 pr-1',
      },
      {
        text: 'Supplier', value: 'supplier_code', width: '10%', class: 'pl-1 pr-1',
      },
      {
        text: 'Color', value: 'color_code', width: '10%', class: 'pl-1 pr-1',
      },
      {
        text: 'Ship To', value: 'location_name', width: '10%', class: 'pl-1 pr-1',
      },
      {
        text: 'Ship Via', value: 'fob_option_name', width: '7%', class: 'pl-1 pr-1',
      },
      {
        text: 'FOB', value: 'shipping_option_name', width: '7%', class: 'pl-1 pr-1',
      },
      {
        text: 'Required By', value: 'required_by', width: '10%', class: 'pl-1 pr-1',
      },
      {
        text: 'Qty', value: 'total_quantity_items', width: '2%', class: 'pl-1 pr-1',
      },
      {
        text: 'Total', value: 'total', width: '5%', class: 'text-end pl-1 pr-1',
      },
      {
        text: 'Created At', value: 'created_at', width: '8%', class: 'pl-1 pr-1',
      },
      {
        text: 'Created By', value: 'created_by', width: '15%', class: 'pl-1 pr-1',
      },
      {
        text: 'Status', value: 'status', width: '10%', align: 'center', class: 'pl-1 pr-1',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '15%', class: 'pl-1 pr-1',
      },
    ],
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    loading: false,
    inventoryClassId: null,
    filterJob: null,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    topSpaceHeight: 290,
    windowHeight: 1000,
    total: 0,
    loadingJobs: false,
    suppliers: [],
    filterSupplierId: null,
    statuses: [],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    selectedTab: 0,
    colors: [],
    locations: [],
    inventoryPoTypes: [],
    filterColorId: null,
    filterLocationId: null,
    filterPoTypeId: null,
    filterRequiredBy: null,
    filterStatusId: null,
    dateMenuRequiredBy: false,
    filterMenu: false,
    supplyToPaintStatusIdNew: 1,
    supplyToPaintStatusIdCanceled: 2,
    inventoryPoStatusIdToBeIssued: 1,
    inventoryPoStatusIdIssued: 3,
    inventoryPoStatusIdPartiallyReceived: 5,
    inventoryPoStatusIdFullyReceived: 6,

    // for good entrance
    goodEntrance: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      inventoryPoState: (state) => state.inventoryPoState,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    inventoryClassId() {
      this.get();
      this.getJobs();
    },
    filterJob(value) {
      this.storeState('job_id', value);
      this.get();
    },
    filterSupplierId(value) {
      this.storeState('supplier_id', value);
      this.get();
    },
    search() {
      this.get();
    },
    pagination() {
      // not sure if we need the state here because we already have updatePagination()
      const state = this.inventoryPoState;
      state.pagination = this.pagination;
      this.$store.commit('storeInventoryPoState', state);
      // this.getGlass(); should be this.get() ?
      this.get();
    },
    selectedTab() {
      this.get();
    },
    filterColorId(value) {
      this.storeState('color_id', value);
      this.get();
    },
    filterLocationId(value) {
      this.storeState('location_id', value);
      this.get();
    },
    filterPo() {
      this.get();
    },
    filterRequiredBy(value) {
      this.storeState('required_by', value);
      this.get();
    },
    filterPoTypeId(value) {
      this.storeState('type_id', value);
      this.get();
    },
    filterStatusId(value) {
      this.storeState('status_id', value);
      this.get();
    },
  },
  created() {
    this.init();
    if (this.inventoryPoState) {
      if (this.inventoryPoState.pagination) { this.pagination = this.inventoryPoState.pagination; }
      if (this.inventoryPoState.job_id) { this.filterJob = this.inventoryPoState.job_id; }
      if (this.inventoryPoState.supplier_id) { this.filterSupplierId = this.inventoryPoState.supplier_id; }
      if (this.inventoryPoState.color_id) { this.filterColorId = this.inventoryPoState.color_id; }
      if (this.inventoryPoState.location_id) { this.filterLocationId = this.inventoryPoState.location_id; }
      if (this.inventoryPoState.required_by) { this.filterRequiredBy = this.inventoryPoState.required_by; }
      if (this.inventoryPoState.type_id) { this.filterPoTypeId = this.inventoryPoState.type_id; }
      if (this.inventoryPoState.status_id) { this.filterStatusId = this.inventoryPoState.status_id; }
    }
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.get();
    },

    storeState(property, value) {
      const state = this.inventoryPoState;
      state[property] = value;
      this.$store.commit('storeInventoryPoState', state);
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          job_id: this.filterJob,
          supplier_id: this.filterSupplierId,
          is_archived: this.selectedTab,
          color_id: this.filterColorId,
          location_id: this.filterLocationId,
          po_number: this.filterPo,
          required_by: this.filterRequiredBy,
          type_id: this.filterPoTypeId,
          status_id: this.filterStatusId,
        },
        search: this.search,
        pagination: this.pagination,
      };
      const res = await this.$api.inventoryPo.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
      this.getJobs();
      this.getSuppliers();
      this.getStatuses();
      this.getColors();
      this.getLocations();
      this.getTypes();
    },

    async getJobs() {
      if (this.inventoryClassId === null) return;
      this.loadingJobs = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };
      const res = await this.$api.job.readDataSecondary(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobs = [];
        this.loadingJobs = false;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },

    async getSuppliers() {
      this.suppliers = await this.$api.supplier.get();
    },

    async getStatuses() {
      this.statuses = await this.$api.inventoryPoStatus.get();
    },

    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    async getTypes() {
      this.inventoryPoTypes = await this.$api.inventoryPoType.get();
    },

    openNewPo() {
      this.$refs.inventoryPoForm.open(null, this.inventoryClassId);
    },

    open(item) {
      if ((item.total_quantity_items > 0 && item.supply_to_paint_status_id != this.supplyToPaintStatusIdNew) || item.status_id != this.supplyToPaintStatusIdCanceled && item.status_id != this.supplyToPaintStatusIdNew) {
        this.openGoodsEntrance(item);
      } else {
        this.openEdit(item);
      }
    },

    openEdit(item) {
      this.$refs.inventoryPoForm.open(item.id);
    },

    openGoodsEntrance(item) {
      this.goodEntrance = true;
      this.$refs.inventoryPoForm.open(item.id, item.inventory_class_id);
    },

    closeForm() {
      this.goodEntrance = false;
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
      this.getJobs();
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this PO#?',
      });
      if (!confirm) return;
      const res = await this.$api.inventoryPo.delete(`${item.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    async changeStatus(item) {
      const res = await this.$api.inventoryPo.get(`status/${item.id}/${item.status_id}`);
      if (!res) return;
      this.get();
    },

    async runWebhook(item) {
      this.$bus.$emit('loading');
      const typeIdRepaint = 2;
      const calculationTypeIdWeight = 2;
      const webhookModuleIdInventoryPo = 36;
      const webhookModuleIdInventoryPoRepaint = 37;
      const webhookModuleIdInventoryPoWeigth = 39;
      const webhookData = {};
      webhookData.subtotal = parseFloat(item.subtotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      webhookData.discount = parseFloat(item.discount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      webhookData.tax = parseFloat(item.tax).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      webhookData.total = parseFloat(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      if (item.type_id === typeIdRepaint && item.supplier && item.supplier.inventory_po_calculation_type_id === calculationTypeIdWeight) {
        webhookData.module_id = webhookModuleIdInventoryPoWeigth;
        webhookData.id = item.id;
        webhookData.is_show_price = 'True';
      } else if (item.type_id === typeIdRepaint) {
        webhookData.module_id = webhookModuleIdInventoryPoRepaint;
        webhookData.id = item.id;
        webhookData.is_show_price = 'True';
      } else {
        webhookData.module_id = webhookModuleIdInventoryPo;
        webhookData.po_number = item.po_number;
        webhookData.is_show_price = 'True';
      }

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.po_number}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async openPackagingSlipsForm(item) {
      this.$refs.inventoryPoReceivingSlipForm.open(item.id);
    },

    async archive(id) {
      const res = await this.$api.inventoryPo.get(`archive/${id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async clone(id) {
      const res = await this.$api.inventoryPo.get(`clone/${id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
        this.pagination = pagination;
        this.storeState('pagination', pagination);
      }
    },

    clearAllFilters() {
      this.filterSupplierId = null;
      this.filterPoTypeId = null;
      this.filterColorId = null;
      this.filterLocationId = null;
      this.filterRequiredBy = null;
      this.filterJob = null;
      this.filterStatusId = null;
      this.get();
    },

    redirectSupplyToPaint(item) {
      this.$router.push(`/supply-to-paints/${item.id}`);
    },
  },
};
</script>

<style scoped>
span
{
  font-size: 12px;
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 20px;
}
::v-deep .fa-caret-down:before {
    font-size: 14px;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 0 3px 0;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-left: 0px !important;
}
::v-deep .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner {
    width: 15px;
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding-left: 10px;
    padding-right: 10px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
    width: 12px;
}
::v-deep .select-status .theme--light.v-icon {
    color: white;
    padding-right: 6px !important;
}

td{
  padding-left: 4px !important;
  padding-right: 4px !important;
}
</style>
