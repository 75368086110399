<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col>
        <v-data-table
          id="movements-table"
          class="elevation-1"
          :headers="movementsHeaders"
          :items="movements"
          :footer-props="movementsTableFooterProps"
          :items-per-page="movementsPerPage"
          :search="search"
          :loading="loading"
          calculate-widths
          dense
        >
          <template #item="{ item }">
            <tr>
              <td>
                <span><TextHighlight :queries="search">
                  {{ item.id }}
                </TextHighlight></span>
              </td>
              <td
                class="movement-code"
              >
                <span style="font-weight: bold;"><TextHighlight :queries="search">
                  {{ item.code }}
                </TextHighlight></span>
              </td>
              <td
                class="movement-category"
                style="padding: 0 5px"
              >
                <TextHighlight :queries="search">
                  {{ item.category }}
                </TextHighlight>
              </td>
              <td
                class="movement-model"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.model }}</span>
                  </template>
                  <span>{{ item.manufacturer }}</span>
                </v-tooltip>
              </td>
              <td
                class="movement-serial-number"
                style="padding: 0 5px"
              >
                {{ item.serial_number ? item.serial_number : '-' }}
              </td>
              <td
                class="movement-user"
                style="padding: 0 5px"
              >
                <TextHighlight :queries="search">
                  {{ item.user_name }}
                </TextHighlight>
              </td>
              <td
                class="movement-location"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span
                      :style="{ color: item.locationColor }"
                      v-on="on"
                    >{{ item.location_simple }}</span>
                  </template>
                  <span>{{ item.location }}</span>
                </v-tooltip>
              </td>
              <td
                class="movement-condition text-center"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span
                      :style="{ color: item.colour }"
                      v-on="on"
                    ><v-chip
                      small
                      :color="item && item.colour ? item.colour : '#BDBDBD'"
                      text-color="white"
                    >
                      {{ item && item.condition ? item.condition : '' }}
                    </v-chip></span>
                  </template>
                  <span>{{
                    item.description ? item.description : 'No description'
                  }}</span>
                </v-tooltip>
              </td>
              <td
                class="movement-type text-center"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span
                      :style="{ color: item.statusColour} "
                      v-on="on"
                    > <v-chip
                      small
                      :color="item && item.statusColour ? item.statusColour : '#BDBDBD'"
                      text-color="white"
                    >
                      {{ item && item.status ? item.status : '' }}
                    </v-chip></span>
                  </template>
                  <span>{{
                    item.hours_spent
                      ? `${item.hours_spent} hours`
                      : 'No information'
                  }}</span>
                </v-tooltip>
              </td>
              <td
                class="movement-date"
                style="padding: 0 5px"
              >
                <v-tooltip left>
                  <template #activator="{ on }">
                    <a
                      href="#"
                      v-on="on"
                      @click="
                        () => openEquipmentInfo(item.equipment_item_id)
                      "
                    >
                      {{ item.created_at_short }}
                    </a>
                  </template>
                  <span>{{ item.created_at_full }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <EquipmentItem ref="equipmentItem" />
  </v-container>
</template>

<script>
import EquipmentItem from '@/components/Equipment/EquipmentItem';
import TextHighlight from 'vue-text-highlight';
import EquipmentMovementsApi from '../../api/EquipmentMovementsApi';
import ExcelHelper from '../../helpers/ExcelHelper';
import PdfHelper from '../../helpers/PdfHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'Movements',
  components: {
    EquipmentItem,
    TextHighlight,
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    movements: [],
    movementsHeaders: [
      { text: 'Mov#', value: 'id', width: '5%' },
      { text: 'QR Code', value: 'code', width: '5%' },
      { text: 'Category', value: 'category', width: '10%' },
      { text: 'Model', value: 'model', width: '15%' },
      {
        text: 'Serial number',
        value: 'serial_number',
        filterable: false,
        width: '10%',
      },
      { text: 'User', value: 'user_name', width: '10%' },
      { text: 'Location', value: 'location_simple', width: '10%' },
      {
        text: 'Condition (Comments)', value: 'condition', width: '20%', align: 'center',
      },
      {
        text: 'Movement (Hours Spent)',
        value: 'movement',
        filterable: false,
        width: '10%',
        align: 'center',
      },
      { text: 'Date', value: 'created_at_full', width: '8%' },
    ],
    movementsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    movementsPerPage: 20,
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchMovements();
    },
    updateSearch(search) {
      this.search = search;
    },
    generateExcel() {
      const excelData = this.movements.map((el) => ({
        'Unique Item Code': el.code,
        Category: el.category,
        Manufacturer: el.manufacturer,
        Model: el.model,
        'Serial number': el.serial_number,
        Movement: el.status,
        'Hours Spent': el.hours_spent,
        Date: el.created_at_full,
        Comment: el.description,
        'Supervisor Signature': el.supervisor_sign,

      }));
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Movement');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Movement_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      const pdf = PdfHelper.generateFromTable(
        '#movements-table > div > table',
        'landscape',
        'Equipment Movements',
        'movements',
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Movements_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },
    async fetchMovements() {
      this.loading = true;
      this.movements = await EquipmentMovementsApi.getList();

      this.movements.map((item) => {
        if (item.condition === 'Good (Working)') {
          item.colour = 'green';
        } else if (item.condition === 'Bad (Broken)') {
          item.colour = 'red';
        } else {
          item.colour = 'orange';
        }

        if (item.movement === 'Check - in') {
          item.status = 'Checked - in';
          item.statusColour = '#3DA129';
        } else if (item.movement === 'Check - out') {
          item.status = 'Checked - out';
          item.statusColour = '#BF3434';
        } else if (item.movement === 'Change site') {
          item.status = 'Changed site';
        } else if (item.movement === 'Request Repair') {
          item.status = 'Repair requested';
          item.statusColour = '#3462BF';
        } else if (item.movement === 'Repair Done') {
          item.status = item.movement;
          item.statusColour = '#562C78';
        } else if (item.movement === 'Change User') {
          item.status = 'Changed user';
          item.statusColour = '#A035EF';
        } else {
          item.status = item.movement;
        }

        item.locationColor = 'black';
        if (item.warehouse_shelf != null) {
          item.location = `Warehouse, shelf ${item.warehouse_shelf}`;
          item.location_simple = `Warehouse, shelf ${item.warehouse_shelf}`;
        } else if (item.job_id == null) {
          item.location_simple = 'User Possession';
          item.location = `User Possession (${item.user_name})`;
          item.locationColor = '#E19300';
        }

        // if (
        //   item.movement === 'Request Repair' ||
        //   item.movement === 'Repair Done'
        // ) {
        //   if (item.warehouse_shelf) {
        //     item.location = item.location + `, shelf ${item.warehouse_shelf}`;
        //     item.location_simple =
        //       item.location_simple + `, shelf ${item.warehouse_shelf}`;
        //   }
        // }
      });

      this.loading = false;
    },
    openEquipmentInfo(equipmentId, movementId) {
      this.$refs.equipmentItem.openDialog(equipmentId, movementId);
    },
  },
};
</script>

<style scoped>
.movement-serial-number,
.movement-location,
.movement-date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movement-code {
  max-width: '7%';
}

.movement-category,
.movement-serial-number,
.movement-user,
.movement-location,
.movement-type {
  max-width: '10%';
}

.movement-model {
  max-width: '15%';
}

.movement-condition {
  max-width: '20%';
}

.movement-date {
  max-width: '8%';
}
</style>
