import moment from 'moment';

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

export const getFileTimestamp = () => moment().format('YYYY-MM-DD_HH-mm-ss');

export const getFileTimestamp2 = () => moment().format('YYYYMMDDHHmmss');

export const currentDateTimeSimple = () => moment().format('MMM Do h:mm a');

export const convertDateTimeFormat = (inputDate) => {
    // Parse the input date
    const date = new Date(inputDate);

    // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  
    return formattedDate;
;}

export const convertToFraction = (decimal) => {
  // check if  a decimal number has numbers after the decimal point
  const decimalString = decimal.toString();
  const decimalPointIndex = decimalString.indexOf('.');

  if (decimalPointIndex === -1) {
    return decimal;
  }
  //

  const fractions = {
    0.0625: '1/16',
    0.125: '1/8',
    0.1875: '3/16',
    0.25: '1/4',
    0.3125: '5/16',
    0.375: '3/8',
    0.4375: '7/16',
    0.5: '1/2',
    0.5625: '9/16',
    0.625: '5/8',
    0.6875: '11/16',
    0.75: '3/4',
    0.8125: '13/16',
    0.875: '7/8',
    0.9375: '15/16',
  };

  // If the decimal is an exact match to any fraction, return the fraction directly
  if (decimal in fractions) {
    return fractions[decimal];
  }

  const wholeNumber = Math.floor(decimal);
  // eslint-disable-next-line no-param-reassign
  decimal -= wholeNumber; // Remove the whole number part

  let closestFraction = Object.keys(fractions)[0];

  // eslint-disable-next-line no-restricted-syntax
  for (const fraction of Object.keys(fractions)) {
    if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
      closestFraction = fraction;
    }
  }

  if (wholeNumber > 0) {
    return `${wholeNumber} - ${fractions[closestFraction]}"`;
  }
  return fractions[closestFraction];
}

export default {
  formatPhoneNumber,
  getFileTimestamp,
  currentDateTimeSimple,
  convertDateTimeFormat,
  convertToFraction,
};
