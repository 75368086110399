import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
class ClientAddressApi extends Api {
  constructor() {
    super('clients/addresses', 'clients/addresses');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const clientAddressApi = new ClientAddressApi();

export default clientAddressApi;
