<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Update'"
  >
    <template slot="header">
      <span>Update Location</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.location_id"
            :items="locations"
            item-text="name"
            item-value="id"
            :loading="loading"
            :rules="[(v) => !!v || 'Location is required']"
          >
            <template slot="label">
              Location<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.inventory_location_id"
            :items="inventoryLocations"
            item-text="name"
            item-value="id"
            :loading="loading"
            :rules="[(v) => !!v || 'Shelf is required']"
          >
            <template slot="label">
              Shelf<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    item: null,
    locations: [],
    inventoryLocations: [],
    loading: false,
  }),
  watch: {
    // eslint-disable-next-line func-names
    'item.location_id': function (value, oldValue) {
      if (oldValue && value !== oldValue) {
        this.item.inventory_location_id = null;
        this.getInventoryLocations(value);
      }
    },
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(tag) {
      if (!tag) return;
      this.loading = true;
      const submitModel = {
        filters: {
          inventory_tag: tag,
        },
      };
      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data || !res.data[0]) {
        this.loading = false;
        this.$bus.$emit('showError');
        this.dialogBox = false;
        return;
      }
      const firstIndex = 0;
      this.item = res.data[firstIndex];
      this.getLocations();
      this.getInventoryLocations(this.item.location_id);
    },

    async submit() {
      if (!this.item) return false;
      const res = await this.$api.inventoryTag.updateTag(this.item);

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async getInventoryLocations(locationId) {
      const readModel = {
        filters:
        {
          location_id: locationId,
        },
      };
      this.inventoryLocations = await this.$api.inventoryLocation.read(readModel);
      this.loading = false;
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    open(tag) {
      this.get(tag);
      this.$refs.dialogBox.open();
    },
  },
};
</script>
