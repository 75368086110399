<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #[`item.class`]="{ item }">
              <v-chip
                small
                :color="item && item.glass_purchase_order_item && item.glass_purchase_order_item.class ? item.glass_purchase_order_item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.glass_purchase_order_item && item.glass_purchase_order_item.class ? item && item.glass_purchase_order_item && item.glass_purchase_order_item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.po_number`]="{ item }">
              <text-highlight
                style="font-weight: 500;"
                :queries="search"
              >
                {{ item.glass_purchase_order_item.po_number ? item.glass_purchase_order_item.po_number : '' }}
              </text-highlight>
            </template>
            <template #[`item.line`]="{ item }">
              <text-highlight
                style="font-weight: 500;"
                :queries="search"
              >
                {{ item.glass_purchase_order_item.item_index ? item.glass_purchase_order_item.item_index : '' }}
              </text-highlight>
            </template>
            <template #[`item.job`]="{ item }">
              <span>
                <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
              </span>
            </template>
            <template #[`item.moved_from`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-size: 16px; font-weight: 600;"
              >
                {{ item.move_from ? item.move_from.slip_number : '' }}
              </text-highlight>
            </template>
            <template #[`item.arrow`]="{ item }">
              <v-icon
                color="#000"
                size="20"
              >
                mdi-arrow-right
              </v-icon>
            </template>
            <template #[`item.moved_to`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-size: 16px; font-weight: 600;"
              >
                {{ item.move_to ? item.move_to.slip_number : '' }}
              </text-highlight>
            </template>
            <template #[`item.created_at`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.created_at ? item.created_at : '' }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.created_by_full_name ? item.created_by_full_name : '' }}
              </text-highlight>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  props: {

  },
  data: () => ({
    items: [],
    users: [],
    footer: {
      'items-per-page-options': [20, 100, 500, 1000, -1],
    },
    loading: false,
    total: 0,
    pagination: {
      itemsPerPage: 20,
      page: 1,
    },

    headers: [
      {
        text: 'Class', value: 'class', align: 'center', width: '5%',
      },
      {
        text: 'PO#', value: 'po_number', width: '7%',
      },
      { text: 'Line#', value: 'line', width: '5%' },
      { text: 'Job', value: 'job' },
      {
        text: 'Moved From', value: 'moved_from', align: 'center', width: '10%',
      },
      {
        text: '', value: 'arrow', align: 'center', width: '5%',
      },
      {
        text: 'Moved To', value: 'moved_to', align: 'center', width: '10%',
      },
      {
        text: 'Created At', value: 'created_at', width: '15%',
      },

      {
        text: 'Created By', value: 'created_by',
      },
    ],

    roles: [],
    userRoleIdAdmin: 3,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
    isAdmin() {
      return this.roles.includes(this.userRoleIdAdmin);
    },
  },
  watch: {
    search() {
      this.get();
    },
    pagination() {
      this.get();
    },
  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.init();
  },
  methods: {
    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
        },
        search: this.search,
        pagination: this.pagination,
      };
      const res = await this.$api.glassPackagingSlipItemHistory.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

  },
};
</script>

  <style scoped>
  </style>
