<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :max-width="700"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Price Color</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || 'Name is required']"
          >
            <template slot="label">
              Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.code"
            hint="IMPORTANT! This Code will be used to identify the color and price in the system. Please make sure to use a unique code."
            persistent-hint
            :rules="[(v) => !!v || 'Code is required']"
          >
            <template slot="label">
              Code<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 18px; font-weight: 600; color: #000">Price by Length</span>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-6"
        >
          <v-text-field
            v-model="item.extrusion_price"
            type="number"
            prefix="$"
          >
            <template slot="label">
              Extrusion Price
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-6"
        >
          <v-text-field
            v-model="item.sheet_price"
            type="number"
            prefix="$"
          >
            <template slot="label">
              Sheet Price
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.min_setup_price"
            type="number"
            prefix="$"
          >
            <template slot="label">
              Min. Setup Price
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.min_order_price"
            type="number"
            prefix="$"
          >
            <template slot="label">
              Min. Order Price
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.additional_expense"
            type="number"
            prefix="$"
          >
            <template slot="label">
              Additional Expense
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <span style="font-size: 18px; font-weight: 600; color: #000">Price by Weight</span>
        </v-col>
      </v-row>
      <v-divider />

      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-6"
        >
          <v-text-field
            v-model="item.inventory_hollow_price"
            type="number"
            prefix="$"
          >
            <template slot="label">
              Inventory Hollow Price
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-6"
        >
          <v-text-field
            v-model="item.inventory_solid_price"
            type="number"
            prefix="$"
          >
            <template slot="label">
              Inventory Solid Price
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="item.weight_adjustment_price"
            type="number"
            hint="This is the price multiplier for the color adjustment. If the color of the item is different from the standard color, this multiplier will be used to adjust the price."
            persistent-hint
          >
            <template slot="label">
              Adjustment Price Multiplier
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: null,
      code: null,
      extrusion_price: null,
      sheet_price: null,
      min_setup_price: null,
      min_order_price: null,
      additional_expense: null,
      inventory_hollow_code: 'AH',
      inventory_hollow_price: null,
      inventory_solid_code: 'AS',
      inventory_solid_price: null,
      weight_adjustment_price: null,
    },
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.inventoryPoColorPrice.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.inventoryPoColorPrice.update(this.item);
      } else {
        res = await this.$api.inventoryPoColorPrice.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
