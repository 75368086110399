/* eslint-disable vue/no-unused-vars */
<template>
  <v-container
    class="pa-0 mt-0"
    fluid
  >
    <v-row>
      <v-col
        cols="auto"
        class="pb-0 mt-4"
      >
        <v-checkbox
          v-model="isHideQtyOnHand"
          class="mt-0 mb-0"
          label="Hide Zero 'Qty On Hand'"
          hide-details
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-add-all="false"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <v-autocomplete
          v-if="inventoryClassId === inventoryClassIdPaint"
          v-model="filterGlassPaintCodeId"
          clearable
          :items="glassPaintCodes"
          item-value="id"
          :loading="loadingInventoryItem"
          :item-text="
            (item) => item.code + ': ' + item.description
          "
        >
          <template slot="label">
            Paint Code
          </template>
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              color="#8dbf8d"
            >
              PAINT
            </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              color="#8dbf8d"
            >
              PAINT
            </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-else
          v-model="filterItemId"
          label="Item"
          :items="inventoryItems"
          :item-text="item => `${item.material_id} ${item.name}`"
          item-value="id"
          hide-details="true"
          clearable
          :loading="loadingInventoryItem"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
      >
        <v-autocomplete
          v-model="filterColorId"
          :label="inventoryClassId === inventoryClassIdPatioDoor ? 'Interior Color' : 'Color'"
          :items="colors"
          item-text="code"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #selection="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
          <template #item="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="inventoryClassId === inventoryClassIdPatioDoor"
        cols="2"
      >
        <v-autocomplete
          v-model="filterExteriorColorId"
          label="Exterior Colour"
          :items="colors"
          item-text="code"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #selection="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
          <template #item="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-spacer />
      <v-col
        class="mt-4 pb-0"
        cols="auto"
      >
        <v-menu
          v-model="filterMenu"
          transition="scale-transition"
          offset-y
          min-width="350"
          nudge-left="50"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="filterMenu = !filterMenu"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-filter
              </v-icon>
              FILTERS <span>{{ activeFilterCount }}</span>
            </v-btn>
          </template>
          <v-card
            class="pa-5"
            style="border-radius: 7px;"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <span style="font-size: 22px !important; font-weight: bold;">Filters</span>
                  </v-col>
                  <v-col class="text-end pr-1">
                    <v-btn
                      icon
                      :style="{ color: isDarkMode ? '' : '#000000' }"
                      @click="filterMenu = !filterMenu"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-if="inventoryClassId === inventoryClassIdPatioDoor"
                >
                  <v-col>
                    <v-autocomplete
                      v-model="filterIsFrittedId"
                      label="Fritted"
                      :items="isFritted"
                      item-text="name"
                      item-value="id"
                      hide-details="true"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="inventoryClassId === inventoryClassIdPatioDoor"
                >
                  <v-col>
                    <v-autocomplete
                      v-model="filterDoorScreenId"
                      label="Door Screen"
                      :items="doorScreens"
                      item-text="name"
                      item-value="id"
                      hide-details="true"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="inventoryClassId !== inventoryClassIdPatioDoor"
                >
                  <v-col>
                    <v-autocomplete
                      v-model="filterLengthId"
                      label="Length"
                      :items="lengths"
                      item-text="name"
                      item-value="id"
                      hide-details="true"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterUomId"
                      label="UOM"
                      :items="uoms"
                      item-text="name"
                      item-value="id"
                      hide-details="true"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterLocationId"
                      label="Location"
                      :items="locations"
                      item-text="name"
                      item-value="id"
                      hide-details="true"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterShelfId"
                      label="Shelf"
                      :items="shelves"
                      item-text="name"
                      item-value="id"
                      hide-details="true"
                      clearable
                      no-data-text="Select a Location First"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="inventoryClassId !== inventoryClassIdPatioDoor"
                >
                  <v-col>
                    <v-autocomplete
                      v-model="filterProjectId"
                      label="Project"
                      :items="projects"
                      item-text="name"
                      item-value="id"
                      hide-details="true"
                      clearable
                      no-data-text="No Projects for this Class"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      @click="filterMenu = !filterMenu"
                    >
                      <span style="font-size: 14px !important;">Close</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      @click="clearAllMenuFilters"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0"
      >
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="inventory-tags-table"
            :headers="inventoryTagsHeaders"
            :items="inventoryTags"
            :footer-props="inventoryTagsTableFooterProps"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            fixed-header
            :height="windowHeight"
            dense
            :server-items-length="total"
            disable-sort
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortByDesc"
            @pagination="updatePagination"
          >
            <template
              v-for="h in inventoryTagsHeaders"
              #[`header.${h.value}`]="{ header }"
            >
              <v-tooltip
                :key="h.value"
                bottom
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>{{ header.fullText }}</span>
              </v-tooltip>
            </template>
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                class="mt-0 mb-0"
                small
                :color="item.item && item.item.classes ? item.item.classes.color : 'black'"
                text-color="white"
              >
                {{ item.item && item.item.classes ? item.item.classes.name : '' }}
              </v-chip>
            </template>
            <template #[`item.unique_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.unique_code ? item.unique_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.item_full_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.item_full_name }}
              </text-highlight>
            </template>
            <template #[`item.inventory_tag`]="{ item }">
              <span style="font-weight: bold;">
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on }">
                    <span
                      :style="{color: item && parseInt(item.inventory_tag, 10) > 99999 && parseInt(item.inventory_tag, 10) < 199999 ? '#0288D1' : ''}"
                      class="font-weight-bold"
                      v-on="on"
                    ><text-highlight :queries="search">{{ item.inventory_tag ? item.inventory_tag : '-' }}</text-highlight></span>
                  </template>
                  <span
                    v-if="item && parseInt(item.inventory_tag, 10) > 99999 && parseInt(item.inventory_tag, 10) < 199999"
                  > UPC Code</span>
                  <span v-else>QR Code</span>
                </v-tooltip>
              </span>
            </template>
            <template #[`item.manufacturers`]="{ item }">
              {{ item.manufacturer ? item.manufacturer : '-' }}
            </template>
            <template #[`item.material_property`]="{ item }">
              {{ item.material_property ? item.material_property: '-' }}
            </template>
            <template #[`item.length`]="{ item }">
              {{ item.length_name ? item.length_name : '-' }}
            </template>
            <template #[`item.color_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.color_code ? item.color_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.finishing`]="{ item }">
              {{ item.finishing ? item.finishing : '-' }}
            </template>
            <template #[`item.is_fritted`]="{ item }">
              {{ item.is_fritted === null ? '-' : item.is_fritted ? 'Fritted' : 'Non-Fritted' }}
            </template>
            <template #[`item.uom`]="{ item }">
              {{ item.uom_name }}
            </template>
            <template #[`item.door_size`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_size? item.door_size.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.door_hinge`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_hinge ? item.door_hinge.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.door_swing`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_swing ? item.door_swing.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.door_lite`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_lite ? item.door_lite.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.batch_number ? item.batch_number: '' }}
              </text-highlight>
            </template>
            <template #[`item.production_date_simple`]="{ item }">
              <text-highlight :queries="search">
                {{ item.production_date_simple ? item.production_date_simple: '' }}
              </text-highlight>
            </template>
            <template #[`item.in_color`]="{ item }">
              <text-highlight :queries="search">
                {{ item.in_color ? item.in_color.code: '' }}
              </text-highlight>
            </template>
            <template #[`item.ex_color`]="{ item }">
              <text-highlight :queries="search">
                {{ item.ex_color ? item.ex_color.code: '' }}
              </text-highlight>
            </template>
            <template #[`item.project`]="{ item }">
              <text-highlight :queries="search">
                {{ item.project ? item.project.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              {{ item.quantity }}
            </template>
            <template #[`item.quantity_stl`]="{ item }">
              {{ item.quantity_stl }}
            </template>

            <template #[`item.quantity_on_hand`]="{ item }">
              <v-row>
                <v-col
                  cols="6"
                  class="text-center"
                >
                  <span style="white-space:nowrap;">{{ item.quantity_on_hand }}</span>
                </v-col>
                <v-col cols="6">
                  <v-icon
                    v-if="item.quantity_on_hand != item.quantity"
                    color="primary"
                    class="mr-1"
                    @click="openSupplyForm(item)"
                  >
                    fa-info-circle
                  </v-icon>
                </v-col>
              </v-row>
            </template>

            <template #[`item.quantity_available`]="{ item }">
              {{ item.quantity_available ? item.quantity_available : '-' }}
            </template>
            <template #[`item.inventory_location`]="{ item }">
              <text-highlight :queries="search">
                {{ item.inventory_location_name ? item.inventory_location_name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.location_code`]="{ item }">
              {{ item.location_code ? item.location_code : '-' }}
            </template>
            <template #[`item.po_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.po_number ? item.po_number : '-' }}
              </text-highlight>
            </template>
            <template #[`item.supplier_label`]="{ item }">
              <a
                v-if="item.supplier_label_name"
                class=""
                @click="getSupplierLabel(item)"
              >
                <v-icon
                  small
                  color="primary"
                >
                  fa-image
                </v-icon>
              </a>
            </template>
            <template #[`item.actions`]="{ item }">
              <a
                v-show="roles.includes(roleIdAdmin)"
                class=""
                @click="deleteTag(item)"
              ><v-icon
                color="error"
                small
              >
                fa-trash
              </v-icon></a>
            </template>
            <template slot="body.append">
              <tr>
                <th>
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">Totals: </span>
                </th>
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th>
                  <span style="font-weight: bold; font-size: 14px;">{{ getTotalErpOnHand() }}</span>
                </th>
                <th />
                <th />
                <th />
                <th />
                <th />
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col
        class="pt-0"
      >
        <v-card
          elevation="0"
          outlined
          style="height: calc(100% + 24px)"
        >
          <prime-data-table
            id="inventory-tags-table"
            :table="table"
            :headers="headers"
            :default-headers="defaultHeaders"
            :data="inventoryTags"
            :search-highlight="search"
            :loading="loading"
            :pagination="pagination"
            :rows-per-page-options="rowsPerPageItems"
            :total-records="total"
            :expander="true"
            expansion-display="DataTable"
            :expansion-data-table-headers="expansionDataTableHeaders"
            expansion-items="multi_products"
            row-class-check-from="inventory_class_id"
            :row-class-check-against="inventoryClassIdPaint"
            row-class-name="no-expansion"
            @paginationUpdated="updatePagination"
            @syncHeadersOrder="syncHeadersOrder"
            @resetHeaders="resetHeaders"
            @softDelete="deleteTag"
            @softDeleteSubRecord="deleteMPQR"
            @openImage="getSupplierLabel"
          >
            <template #number-append="{ data }">
              <v-icon
                v-if="data.quantity_on_hand != data.quantity && data.headers.value === 'quantity_on_hand'"
                color="primary"
                class="mr-1"
                @click="openSupplyForm(data)"
              >
                fa-info-circle
              </v-icon>
            </template>
            <template #text-tooltip="{ data }">
              <span style="font-weight: bold;">
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on }">
                    <span
                      :style="{color: data && parseInt(data.inventory_tag, 10) > 99999 && parseInt(data.inventory_tag, 10) < 199999 ? '#0288D1' : parseInt(data.inventory_tag, 10) > 199999 && parseInt(data.inventory_tag, 10) < 209999 ? '#FFA000' : '#000000'}"
                      class="font-weight-bold"
                      v-on="on"
                    ><text-highlight :queries="search">{{ data.inventory_tag ? data.inventory_tag : '-' }}</text-highlight></span>
                  </template>
                  <span
                    v-if="data && parseInt(data.inventory_tag, 10) > 99999 && parseInt(data.inventory_tag, 10) < 199999"
                  > UPC Code</span>
                  <span v-else-if="data && parseInt(data.inventory_tag, 10) > 199999 && parseInt(data.inventory_tag, 10) < 209999">MPQR</span>
                  <span v-else>QR Code</span>
                </v-tooltip>
              </span>
            </template>
          </prime-data-table>
        </v-card>
      </v-col>
    </v-row> -->
    <supply-inventory-tag-table-form
      ref="supplyForm"
      :inventory-tag="selectedInvTag"
      :qr-code="selectedQrCode"
      :material-id="selectedMaterialId"
    />
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import SupplyInventoryTagTableForm from '@/components/forms/inventoryTags/SupplyInventoryTagTableForm';
// import PrimeDataTable from '@/components/PrimeDataTable/PrimeDataTable';
import ExcelHelper from '@/helpers/ExcelHelper';
import PdfHelper from '@/helpers/PdfHelper';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  name: 'InventoryTags',
  components: {
    SupplyInventoryTagTableForm,
    InventoryItemShowImageForm,
    // PrimeDataTable,
  },

  data: () => ({
    // Page
    search: '',
    loading: true,
    inventoryTags: [],
    inventoryTagsTableFooterProps: {
      'items-per-page-options': [100, 500, 1000, 2000, -1],
    },
    inventoryTagsPerPage: 1000,
    selectedInvTag: 0,
    selectedQrCode: 0,
    selectedMaterialId: '',
    readModel: null,
    pagination: {
      itemsPerPage: 1000,
      page: 1,
    },
    sort: {
      order_by_first: '',
      direction_sort: 0,
    },
    filters: null,
    total: 0,
    inventoryClasses: [],
    inventoryClassId: null,
    inventoryItems: [],
    lengths: [],
    colors: [],
    uoms: [],
    locations: [],
    shelves: [],
    projects: [],
    isFritted: [{ id: 0, name: 'Non-Fritted' }, { id: 1, name: 'Fritted' }],
    doorScreens: [],
    filterItemId: null,
    filterLengthId: null,
    filterColorId: null,
    filterUomId: null,
    filterLocationId: null,
    filterShelfId: null,
    filterProjectId: null,
    filterGlassPaintCodeId: null,
    filterExteriorColorId: null,
    filterIsFrittedId: null,
    filterDoorScreenId: null,
    topSpaceHeight: 280,
    windowHeight: 1000,
    loadingInventoryItem: false,
    roleIdAssociate: 6,
    roleIdAdmin: 3,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdPanelParts: 10,
    inventoryClassIdPaint: 12,
    inventoryClassIdPatioDoor: 14,
    filterMenu: false,

    // DATA BELOW FOR PRIMEVUE DATATABLE
    table: 'inventoryTags',
    expandedRows: [],
    rowsPerPageItems: [50, 100, 500, 1000],
    headers: [],
    glassPaintCodes: [],
    expansionDataTableHeaders: [
      {
        text: 'Name',
        value: 'item_code',
        sortable: false,
        type: 'text',
      },
      {
        text: 'Description',
        value: 'item_description',
        sortable: false,
        type: 'text',
      },
      {
        text: 'Quantity',
        value: 'quantity',
        sortable: false,
        type: 'number',
      },
      {
        text: 'UOM',
        value: 'uom_name',
        sortable: false,
        type: 'text',
      },
      {
        text: 'Project',
        value: 'project_name',
        sortable: false,
        type: 'text',
      },
      {
        text: 'Updated On',
        value: 'updated_at',
        sortable: false,
        type: 'text',
      },
      {
        text: 'Updated By',
        value: 'updated_by_name',
        sortable: false,
        type: 'text',
      },
      {
        text: '',
        value: 'actions',
        filterable: false,
        width: '3%',
        align: 'end',
        sortable: false,
        actions: [
          {
            type: 'userRoleOnlyConditionalIconButton',
            color: 'error',
            emit: 'softDeleteSubRecord',
            icon: 'fa-trash',
            condition: 'equals',
            conditionParams: [3],
          },
        ],
      },
    ],
  }),

  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      invClasses: 'invClasses',
      inventoryTagsState: (state) => state.inventoryTagsState,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),

    isHideQtyOnHand: {
      get() { return this.$store.state.isInvTagQtyOnHandEqualsToZero; },
      set(value) { this.$store.commit('storeisInvTagQtyOnHandEqualsToZero', value); this.get(); },
    },

    activeFilterCount() {
      let count = 0;
      if (this.filterLengthId !== null) count += 1;
      if (this.filterUomId !== null) count += 1;
      if (this.filterLocationId !== null) count += 1;
      if (this.filterShelfId !== null) count += 1;
      if (this.filterProjectId !== null) count += 1;
      if (count) {
        return `(${count})`;
      }
      return '';
    },

    inventoryTagsHeaders() {
      const headers = [];
      // headers.push({
      //   text: 'Class', value: 'inventory_class', width: '5%', sortable: false, align: 'center',
      // });
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_inventory_tag)) {
        headers.push({
          text: 'QR Code', value: 'inventory_tag', filterable: true, width: `${this.columnSetup && this.columnSetup.inventory_tag_width ? this.columnSetup.inventory_tag_width : '5'}%`, sortable: false,
        });
      }

      const uniqueItemCodeBRName = 'Unique Item Code';
      const uniqueItemCodeBRValue = this.businessRulesGetBoolean(uniqueItemCodeBRName);
      if (uniqueItemCodeBRValue != null && uniqueItemCodeBRValue !== 0) headers.push({ text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' });

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_inventory_item)) {
        headers.push({
          text: 'Inv. Item', value: 'item_full_name', width: `${this.columnSetup && this.columnSetup.inventory_item_width ? this.columnSetup.inventory_item_width : '17'}%`, sortable: false,
        });
      }

      const manufacturerIdBRName = 'Manufacturer';
      const manufacturerIdBRValue = this.businessRulesGetBoolean(manufacturerIdBRName);
      if (manufacturerIdBRValue != null && manufacturerIdBRValue !== 0) {
        headers.push({
          text: 'Manufacturer', value: 'manufacturer', filterable: false, sortable: false,
        });
      }

      const materialPropertyIdBRName = 'Material Property';
      const materialPropertyIdBRValue = this.businessRulesGetBoolean(materialPropertyIdBRName);
      if (materialPropertyIdBRValue != null && materialPropertyIdBRValue !== 0) {
        headers.push({
          text: 'Material Property', value: 'material_property', filterable: false, sortable: false,
        });
      }

      // if (this.inventoryClassId === this.inventoryClassIdPatioDoor) {
      //   headers.push({
      //     text: 'Glass', value: 'glass', filterable: false, sortable: false, width: '5%',
      //   });
      // }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_color)) && this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass) {
        headers.push({
          text: this.inventoryClassId === this.inventoryClassIdPatioDoor ? 'Interior Color' : 'Color', value: 'color_code', filterable: false, width: `${this.columnSetup && this.columnSetup.color_width ? this.columnSetup.color_width : '15'}%`, sortable: false,
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdPatioDoor) {
        headers.push({
          text: 'Exterior Color', value: 'ex_color', filterable: false, sortable: false, width: '15%',
        });
        headers.push({
          text: 'Door Height', value: 'height', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Door Width', value: 'width', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Fritted', value: 'is_fritted', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Door Screen', value: 'door_screen.name', filterable: false, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_length)) && this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass && this.inventoryClassId !== this.inventoryClassIdPanels && this.inventoryClassId !== this.inventoryClassIdPatioDoor) {
        headers.push({
          text: 'Length', value: 'length', filterable: false, width: `${this.columnSetup && this.columnSetup.length_width ? this.columnSetup.length_width : '5'}%`, sortable: false,
        });
      }

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_uom)) {
        headers.push({
          text: 'UOM', value: 'uom', width: `${this.columnSetup && this.columnSetup.uom_width ? this.columnSetup.uom_width : '5'}%`, sortable: false,
        });
      }

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_location)) {
        headers.push({
          text: 'Location', fullText: 'Location Code', value: 'location_code', filterable: false, width: `${this.columnSetup && this.columnSetup.location_width ? this.columnSetup.location_width : '4'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_shelf)) {
        headers.push({
          text: 'Shelf', value: 'inventory_location', filterable: false, width: `${this.columnSetup && this.columnSetup.shelf_width ? this.columnSetup.shelf_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_size)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Size', value: 'door_size', filterable: false, width: `${this.columnSetup && this.columnSetup.size_width ? this.columnSetup.size_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_hinge)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Hinge', value: 'door_hinge', filterable: false, width: `${this.columnSetup && this.columnSetup.hinge_width ? this.columnSetup.hinge_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_swing)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Swing', value: 'door_swing', filterable: false, width: `${this.columnSetup && this.columnSetup.swing_width ? this.columnSetup.swing_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_lite)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Lite', value: 'door_lite', filterable: false, width: `${this.columnSetup && this.columnSetup.lite_width ? this.columnSetup.lite_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_in_color)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'In Color', value: 'in_color', filterable: false, width: `${this.columnSetup && this.columnSetup.in_color_width ? this.columnSetup.in_color_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_ex_color)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Ex Color', value: 'ex_color', filterable: false, width: `${this.columnSetup && this.columnSetup.ex_color_width ? this.columnSetup.ex_color_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_batch)) && (this.inventoryClassId === this.inventoryClassIdGlass || this.inventoryClassId === this.inventoryClassIdVWDGlass)) {
        headers.push({
          text: 'Vendor Batch#', value: 'batch_number', filterable: false, width: `${this.columnSetup && this.columnSetup.batch_width ? this.columnSetup.batch_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_prod_date)) && (this.inventoryClassId === this.inventoryClassIdGlass || this.inventoryClassId === this.inventoryClassIdVWDGlass)) {
        headers.push({
          text: 'Prod. Date', value: 'production_date_simple', filterable: false, width: `${this.columnSetup && this.columnSetup.prod_date_width ? this.columnSetup.prod_date_width : '8'}%`, sortable: false,
        });
      }

      const finishingIdBRName = 'Finishing';
      const finishingIdBRValue = this.businessRulesGetBoolean(finishingIdBRName);
      if (finishingIdBRValue != null && finishingIdBRValue !== 0) {
        headers.push({
          text: 'Finishing', value: 'finishing', filterable: false, sortable: false,
        });
      }

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_quantity_received)) {
        headers.push({
          text: 'ERP Received', fullText: 'ERP Quantity Received ', value: 'quantity', align: 'center', width: `${this.columnSetup && this.columnSetup.quantity_received_width ? this.columnSetup.quantity_received_width : '5'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_quantity_stl)) {
        headers.push({
          text: 'ERP STL', fullText: 'ERP Quantity Supply To Line', align: 'center', value: 'quantity_stl', width: `${this.columnSetup && this.columnSetup.quantity_stl_width ? this.columnSetup.quantity_stl_width : '5'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_quantity_on_hand)) {
        headers.push({
          text: 'ERP On Hand', fullText: 'ERP Quantity On Hand', value: 'quantity_on_hand', width: `${this.columnSetup && this.columnSetup.quantity_on_hand_width ? this.columnSetup.quantity_on_hand_width : '5'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_po)) {
        headers.push({
          text: 'PO', value: 'po_number', filterable: false, width: `${this.columnSetup && this.columnSetup.po_width ? this.columnSetup.po_width : '4'}%`, sortable: false,
        });
      }

      headers.push({
        text: 'Project', value: 'project', filterable: false, width: '8%', sortable: false,
      });
      // if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_created)) {
      //   headers.push({
      //     text: 'Created', value: 'created_at', width: `${this.columnSetup && this.columnSetup.created_width ? this.columnSetup.created_width : '10'}%`, sortable: false,
      //   });
      // }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_updated)) {
        headers.push({
          text: 'Updated', value: 'updated_at', width: `${this.columnSetup && this.columnSetup.updated_width ? this.columnSetup.updated_width : '10'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_supplier_label)) {
        headers.push({
          text: '', value: 'supplier_label', filterable: false, width: `${this.columnSetup && this.columnSetup.supplier_label_width ? this.columnSetup.supplier_label_width : '3'}%`, sortable: false,
        });
      }
      headers.push({
        text: '', value: 'actions', filterable: false, width: '3%', align: 'end', sortable: false,
      });

      return headers;
    },

    defaultHeaders() {
      const defaultHeaders = [];

      defaultHeaders.push({
        text: 'Expander',
        value: 'expander',
        frozen: true,
        expander: true,
        reorderableColumn: false,
        headerStyle: {
          width: '2%', // expander MUST be 2%
        },
      });

      defaultHeaders.push({
        text: 'QR Code',
        value: 'inventory_tag',
        type: 'tooltipText',
        class: 'bold-text',
        // filterable: true, // need to ask what this is for
        sortable: false,
        headerStyle: {
          width: '5%',
        },
      });

      const uniqueItemCodeBRName = 'Unique Item Code';
      const uniqueItemCodeBRValue = this.businessRulesGetBoolean(uniqueItemCodeBRName);
      if (uniqueItemCodeBRValue != null && uniqueItemCodeBRValue !== 0) {
        defaultHeaders.push({
          text: 'Unique Item Code',
          value: 'unique_code',
          type: 'text',
          // class: 'unique_item_code',
        }); // no width in the old header
      }

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'Inv. Item',
          value: 'item_full_name',
          type: 'text',
          sortable: false,
          headerStyle: {
            width: '18%',
          },
        });
      }

      const manufacturerIdBRName = 'Manufacturer';
      const manufacturerIdBRValue = this.businessRulesGetBoolean(manufacturerIdBRName);
      if (manufacturerIdBRValue != null && manufacturerIdBRValue !== 0) {
        defaultHeaders.push({
          text: 'Manufacturer',
          value: 'manufacturer',
          type: 'text',
          // filterable: false,
          sortable: false,
        }); // no width in the old header
      }

      const materialPropertyIdBRName = 'Material Property';
      const materialPropertyIdBRValue = this.businessRulesGetBoolean(materialPropertyIdBRName);
      if (materialPropertyIdBRValue != null && materialPropertyIdBRValue !== 0) {
        defaultHeaders.push({
          text: 'Material Property',
          value: 'material_property',
          type: 'text',
          // filterable: false,
          sortable: false,
        }); // no width in the old header
      }

      if (this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass && this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'Colour',
          value: 'color_code',
          type: 'text',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '12%',
          },
        });
      }

      if (this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass && this.inventoryClassId !== this.inventoryClassIdPanels && this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'Length',
          value: 'length_name',
          type: 'text',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '5%',
          },
        });
      }

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'UOM',
          value: 'uom_name',
          type: 'text',
          sortable: false,
          headerStyle: {
            width: '5%',
          },
        });
      }

      defaultHeaders.push({
        text: 'Location',
        fullText: 'Location Code',
        value: 'location_code',
        type: 'text',
        // filterable: false,
        sortable: false,
        headerStyle: {
          width: '5%',
        },
      });

      defaultHeaders.push({
        text: 'Shelf',
        value: 'inventory_location_name',
        type: 'text',
        // filterable: false,
        sortable: false,
        headerStyle: {
          width: '5%',
        },
      });

      if (this.inventoryClassId === this.inventoryClassIdSID) {
        defaultHeaders.push({
          text: 'Size',
          value: 'door_size.name',
          type: 'deepText',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdSID) {
        defaultHeaders.push({
          text: 'Hinge',
          value: 'door_hinge.name',
          type: 'deepText',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdSID) {
        defaultHeaders.push({
          text: 'Swing',
          value: 'door_swing.name',
          type: 'deepText',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdSID) {
        defaultHeaders.push({
          text: 'Lite',
          value: 'door_lite.name',
          type: 'deepText',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdSID) {
        defaultHeaders.push({
          text: 'In Color',
          value: 'in_color.code',
          type: 'deepText',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdSID) {
        defaultHeaders.push({
          text: 'Ex Color',
          value: 'ex_color.code',
          type: 'deepText',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdGlass || this.inventoryClassId === this.inventoryClassIdVWDGlass) {
        defaultHeaders.push({
          text: 'Vendor Batch#',
          value: 'batch_number',
          type: 'text',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdGlass || this.inventoryClassId === this.inventoryClassIdVWDGlass) {
        defaultHeaders.push({
          text: 'Prod. Date',
          value: 'production_date_simple',
          type: 'text',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      const finishingIdBRName = 'Finishing';
      const finishingIdBRValue = this.businessRulesGetBoolean(finishingIdBRName);
      if (finishingIdBRValue != null && finishingIdBRValue !== 0) {
        defaultHeaders.push({
          text: 'Finishing',
          value: 'finishing',
          type: 'text',
          // filterable: false,
          sortable: false,
        });
      }

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'ERP Received',
          fullText: 'ERP Quantity Received ',
          value: 'quantity',
          type: 'number',
          class: 'centered-column',
          sortable: false,
          headerStyle: {
            width: '5%',
          },
        });
      }

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'ERP STL',
          fullText: 'ERP Quantity Supply To Line',
          value: 'quantity_stl',
          type: 'number',
          class: 'centered-column',
          sortable: false,
          headerStyle: {
            width: '5%',
          },
        });
      }

      defaultHeaders.push({
        text: 'ERP On Hand',
        fullText: 'ERP Quantity On Hand',
        value: 'quantity_on_hand',
        type: 'number',
        class: 'centered-column',
        sortable: false,
        headerStyle: {
          width: '5%',
        },
      });

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'PO',
          value: 'po_number',
          type: 'text',
          defaultValue: '-',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '4%',
          },
        });
      }

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'Project',
          value: 'project_name',
          type: 'text',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'Total Items',
          value: 'total_items',
          type: 'number',
          // filterable: false,
          sortable: false,
          headerStyle: {
            width: '8%',
          },
        });
      }

      defaultHeaders.push({
        text: 'Updated',
        value: 'updated_at',
        type: 'text',
        sortable: false,
        headerStyle: {
          width: '8%',
        },
      });

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'Supplier Label',
          value: 'supplier_label',
          type: 'checkExistsConditionalIconButton',
          checkFrom: 'supplier_label_name',
          icon: 'fa-image',
          emit: 'openImage',
          color: 'primary',
          sortable: false,
          headerStyle: {
            width: '3%',
          },
        });
      }

      defaultHeaders.push({
        text: 'Actions',
        value: 'actions',
        sortable: false,
        type: 'actions',
        headerStyle: { width: '5%', position: 'sticky', right: 0 },
        className: 'actions-column',
        conditionalButtons: true,
        reorderableColumn: false,
        // frozen: true, // if set to true, this won't allow you to resize the column
        actions: [
          {
            type: 'userRoleOnlyConditionalIconButton',
            color: 'error',
            emit: 'softDelete',
            icon: 'fa-trash',
            condition: 'equals',
            conditionParams: [3],
          },
        ],
      });

      const headers = this.sumWidthsTo100(defaultHeaders);

      return headers;
    },

  },
  watch: {
    inventoryClassId(value) {
      const state = this.inventoryTagsState;
      state.class_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.clearAllFilters();
      this.get();
      this.getItems();
      this.getProjects();
      this.$emit('toggle-inventory-class', value);
    },
    search() {
      this.get();
    },
    pagination() {
      const state = this.inventoryTagsState;
      state.perPage = this.pagination.itemsPerPage;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    sort: {
      handler() {
        this.get();
      },
      deep: true,
    },
    filterItemId(value) {
      const state = this.inventoryTagsState;
      state.inventory_item_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterLengthId(value) {
      const state = this.inventoryTagsState;
      state.length_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterColorId(value) {
      const state = this.inventoryTagsState;
      state.color_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterUomId(value) {
      const state = this.inventoryTagsState;
      state.uom_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterLocationId(value) {
      const state = this.inventoryTagsState;
      state.location_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
      this.getShelves();
    },
    filterShelfId(value) {
      const state = this.inventoryTagsState;
      state.shelf_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterProjectId(value) {
      const state = this.inventoryTagsState;
      state.project_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterGlassPaintCodeId() {
      this.get();
    },
    filterExteriorColorId() {
      this.get();
    },
    filterIsFrittedId() {
      this.get();
    },
    filterDoorScreenId() {
      this.get();
    },
  },

  created() {
    if (this.inventoryTagsState) {
      if (this.inventoryTagsState.perPage) { this.inventoryTagsPerPage = this.inventoryTagsState.perPage; }
      if (this.inventoryTagsState.inventory_item_id) { this.filterItemId = this.inventoryTagsState.inventory_item_id; }
      if (this.inventoryTagsState.length_id) { this.filterLengthId = this.inventoryTagsState.length_id; }
      if (this.inventoryTagsState.color_id) { this.filterColorId = this.inventoryTagsState.color_id; }
      if (this.inventoryTagsState.uom_id) { this.filterUomId = this.inventoryTagsState.uom_id; }
      if (this.inventoryTagsState.location_id) { this.filterLocationId = this.inventoryTagsState.location_id; }
      if (this.inventoryTagsState.shelf_id) { this.filterShelfId = this.inventoryTagsState.shelf_id; }
      if (this.inventoryTagsState.project_id) { this.filterProjectId = this.inventoryTagsState.project_id; }
      if (this.inventoryTagsState.class_id) { this.inventoryClassId = this.inventoryTagsState.class_id; }
    }
    this.initialize();
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async initialize() {
      this.get();
      this.getItems();
      this.getUoms();
      this.getColors();
      this.getLengths();
      this.getShelves();
      this.getLocations();
      this.getProjects();
      this.getGlassPaintCodes();
      this.getDoorScreens();
    },

    async get() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.inventoryTags = [];
      const readModel = {
        pagination: this.pagination,
        filters:
        {
          is_qty_on_hand_o: this.isHideQtyOnHand === true ? 1 : 0,
          inventory_class_id: this.inventoryClassId,
          inventory_item_id: this.filterItemId,
          color_id: this.filterColorId,
          length_id: this.filterLengthId,
          location_id: this.filterLocationId,
          shelf_id: this.filterShelfId,
          project_id: this.filterProjectId,
          uom_id: this.filterUomId,
          glass_paint_code_id: this.filterGlassPaintCodeId,
          ex_color_id: this.filterExteriorColorId,
          is_fritted: this.filterIsFrittedId,
          door_screen_id: this.filterDoorScreenId,
        },
        sort: this.sort,
        search: this.search,
      };

      // this.headers = JSON.parse(JSON.stringify(this.defaultHeaders)); // need this for primevue datatable
      const res = await this.$api.inventoryTag.readData(readModel);

      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryTags = [];
        this.total = 0;
        return;
      }
      this.inventoryTags = res.data;
      this.total = res.total;
    },

    async getSupplierLabel(item) {
      const supplierLabel = await this.$api.inventoryTag.get(`supplier-label/${item.inventory_tag}`);
      if (!supplierLabel) {
        this.$bus.$emit('showError', 'Supplier label not found...');
        return;
      }
      this.openItemShowImageForm(supplierLabel.supplier_label, supplierLabel.supplier_label_mime_type, item.inventory_tag);
    },

    async getItems() {
      this.loadingInventoryItem = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },
    async getUoms() {
      this.uoms = await this.$api.uom.getList();
    },
    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
    },
    async getLengths() {
      this.lengths = await this.$api.length.getList();
    },
    async getShelves() {
      if (!this.filterLocationId) return;
      this.$nextTick(async () => {
        const readModel = {
          filters:
          {
            location_id: this.filterLocationId,
          },
        };
        this.shelves = await this.$api.inventoryLocation.read(readModel);
      });
    },
    async getLocations() {
      this.locations = await this.$api.location.getList();
    },
    async getProjects() {
      const submitModel = {
        filters: {
        },
      };
      const res = await this.$api.project.read(submitModel);
      this.projects = res.data;
    },
    async getGlassPaintCodes() {
      const submitModel = {
        filters: {
          is_awd_paint: true,
        },
      };
      this.glassPaintCodes = await this.$api.glassPaintCode.read(submitModel);
    },
    async getDoorScreens() {
      this.doorScreens = await this.$api.doorScreen.get();
    },

    async deleteTag(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.inventoryTag.delete(item.inventory_tag);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },

    async deleteMPQR(mpqr) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.inventoryMPQR.delete(mpqr.id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    updateSearch(search) {
      this.search = search;
    },
    updateSortBy(sortBy) {
      if (!sortBy || !sortBy[0]) return;
      const firstIndex = 0;
      this.sort.order_by_first = sortBy[firstIndex];
    },
    updateSortByDesc(isDesc) {
      if (!isDesc || !isDesc[0]) return;
      const firstIndex = 0;
      const direction = isDesc[firstIndex];
      if (direction) {
        this.sort.direction_sort = 'DESC';
      } else {
        this.sort.direction_sort = 'ASC';
      }
    },
    generateExcel() {
      if (this.inventoryClassId === this.inventoryClassIdPaint) {
        this.runWebhook('csv');
        return;
      }

      const excelData = this.inventoryTags.map((el) => ({
        Class: el.item && el.item.classes ? el.item.classes.name : '',
        'QR Code': el.inventory_tag,
        'Die #': el.material_id,
        'Item Name': el.name,
        Color: el.color_code,
        Length: el.length_name,
        UOM: el.uom_name,
        Location: el.location_name,
        Shelf: el.inventory_location_name,
        'ERP Received Quantity': parseInt(el.quantity ? el.quantity : 0, 10),
        'ERP STL Quantity': parseInt(el.quantity_stl ? el.quantity_stl : 0, 10),
        'ERP Quantity On Hand': parseInt(el.quantity_on_hand ? el.quantity_on_hand : 0, 10),
        'PO #': el.po_number,
        'Created At': el.created_at,
      }));
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Inventory Tags');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Inventory-Tags_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      if (this.inventoryClassId === this.inventoryClassIdPaint) {
        this.runWebhook('pdf');
        return;
      }

      await this.$nextTick();
      const pdf = PdfHelper.generateFromTable(
        '#inventory-tags-table > div > table',
        'landscape',
        'Inventory Tags',
        'inventory-tags',
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Inventory-Tags_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },

    async runWebhook(type) {
      this.$bus.$emit('loading');
      const webhookModuleIdInventoryTags = 38;
      const webhookData = {
        module_id: webhookModuleIdInventoryTags,
        type,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `MPQR_Inventory_Tags_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    openSupplyForm(record) {
      if (!record) return;
      this.selectedInvTag = record.inventory_tag;
      this.selectedQrCode = record.qr_code ? parseInt(record.qr_code, 10) : 0;
      this.selectedMaterialId = record.material_id;
      this.$refs.supplyForm.open();
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },
    convertedPhoto(photo, mimeType) {
      if (!photo || !mimeType) return '';
      return `data:${mimeType};base64, ${photo}`;
    },
    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },
    clearAllFilters() {
      this.filterItemId = null;
      this.filterColorId = null;
      this.filterLengthId = null;
      this.filterLocationId = null;
      this.filterShelfId = null;
      this.filterProjectId = null;
      this.filterUomId = null;
      this.filterExteriorColorId = null;
      this.filterIsFrittedId = null;
      this.filterDoorScreenId = null;
      this.isHideQtyOnHand = true;
    },
    clearAllMenuFilters() {
      this.filterLengthId = null;
      this.filterLocationId = null;
      this.filterShelfId = null;
      this.filterProjectId = null;
      this.filterUomId = null;
      this.filterIsFrittedId = null;
      this.filterDoorScreenId = null;
      this.get();
    },
    getTotalErpOnHand() {
      return this.inventoryTags.reduce((acc, tag) => {
        return acc + parseInt(tag.quantity_on_hand);
      }, 0);
    },

    // METHODS BELOW FOR PRIMEVUE DATATABLE
    updatePagination(pagination) {
      if (pagination && this.pagination && (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage)) {
        this.pagination = pagination;
        // this.itemsPerPage = this.pagination.itemsPerPage;
      }
    },

    syncHeadersOrder(reorderedHeaders) {
      const headers = [...this.headers];
      reorderedHeaders.forEach((header, index) => {
        const headerIndex = headers.findIndex((h) => h.text === header.text);
        if (headerIndex !== index) {
          const temp = headers[index];
          headers[index] = header;
          headers[headerIndex] = temp;
        }
      });
      this.headers = headers;
    },

    async resetHeaders() {
      this.headers = JSON.parse(JSON.stringify(this.defaultHeaders));
    },

    sumWidthsTo100(headers) {
      let totalWidthPercentage = 0;

      headers.forEach((header) => {
        const widthPercentage = parseFloat(header.headerStyle.width);

        if (!isNaN(widthPercentage)) {
          totalWidthPercentage += widthPercentage;
        }
      });

      if (totalWidthPercentage < 100 && headers.length > 2) {
        const diff = 100 - totalWidthPercentage;
        // distribute diff among the headers
        this.distributeDiff(diff, headers);
      }
      return headers;
    },

    distributeDiff(diff, headers) {
      // if there's an expander column we don't want to add to it and we also don't want to add to actions
      let denominator = headers.length - 1;
      let start = 0;
      if (headers[0].value === 'expander') {
        denominator = headers.length - 2;
        start = 1;
      }

      const initialValue = Math.floor(diff / denominator);
      const remainingDiff = diff - (initialValue * denominator);

      for (let i = start; i < headers.length - 1; i++) {
        const initialWidth = parseInt(headers[i].headerStyle.width, 10);
        headers[i].headerStyle.width = `${initialWidth + initialValue}%`;
      }

      // Distribute the remaining difference among the headers
      for (let i = start; i < remainingDiff; i++) {
        let initialWidth = parseInt(headers[i].headerStyle.width, 10);
        headers[i].headerStyle.width = `${initialWidth += 1}%`;
      }
    },
  },
};
</script>
<style>
.profile-photo-mini{
  max-width: 40px;
}
.text-bold{
  background: red;
}

</style>
