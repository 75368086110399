<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <span style="font-size: 20px; font-weight: 500;">History Production Schedule# {{ schedule ? schedule.id : '' }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row v-if="schedule">
              <v-col>
                <v-list
                  style="border: 1px solid rgba(0 ,0 ,0, 0.2); border-radius: 4px;"
                  class="pb-0 pt-0"
                >
                  <v-list-item v-if="schedule.created_by">
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                      >
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 32px; height: 32px; border: 1.5px solid #4caf50;"
                            >
                              <span
                                style="color: #000000;"
                              >C</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 20px;">Created</span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="schedule">
                      <v-list-item-action-text
                        style="font-size: 16px; color: #000000;"
                        v-text="schedule.created_at"
                      />
                      <v-list-item-action-text
                        style="font-size: 16px; color: #1976d2;"
                        v-text="schedule.created_by_name"
                      />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="schedule.is_splander_paint" />
                  <v-list-item v-if="schedule.is_splander_paint">
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                      >
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 32px; height: 32px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >S</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 20px;">Spandrel Paint Finished</span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="schedule">
                      <v-list-item-action-text
                        style="font-size: 16px; color: #000000;"
                        v-text="schedule.splander_paint_finished_at"
                      />
                      <v-list-item-action-text
                        style="font-size: 16px; color: #1976d2;"
                        v-text="`${schedule.splander_paint_finished_by && schedule.splander_paint_finished_by.first_name ? schedule.splander_paint_finished_by.first_name : ''} ${schedule.splander_paint_finished_by && schedule.splander_paint_finished_by.last_name ? schedule.splander_paint_finished_by.last_name : ''}`"
                      />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="schedule.is_panels" />
                  <v-list-item v-if="schedule.is_panels">
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                      >
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 32px; height: 32px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >P</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 20px;">Panels Finished</span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text
                        style="font-size: 16px; color: #000000;"
                        v-text="schedule.panels_finished_at"
                      />
                      <v-list-item-action-text
                        style="font-size: 16px; color: #1976d2;"
                        v-text="`${schedule.panels_finished_by.first_name ? schedule.panels_finished_by.first_name : ''} ${schedule.panels_finished_by.last_name ? schedule.panels_finished_by.last_name : ''}`"
                      />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="schedule.is_cut" />
                  <v-list-item v-if="schedule.is_cut">
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                      >
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 32px; height: 32px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >C</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 20px;">Cutting Finished</span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text
                        style="font-size: 16px; color: #000000;"
                        v-text="schedule.cut_finished_at"
                      />
                      <v-list-item-action-text
                        style="font-size: 16px; color: #1976d2;"
                        v-text="`${schedule.cut_finished_by.first_name ? schedule.cut_finished_by.first_name : ''} ${schedule.cut_finished_by.last_name ? schedule.cut_finished_by.last_name : ''}`"
                      />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="schedule.is_assemble" />
                  <v-list-item v-if="schedule.is_assemble">
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                      >
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 32px; height: 32px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >A</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 20px;">Assembling Finished</span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text
                        style="font-size: 16px; color: #000000;"
                        v-text="schedule.assemble_finished_at"
                      />
                      <v-list-item-action-text
                        style="font-size: 16px; color: #1976d2;"
                        v-text="`${schedule.assemble_finished_by.first_name ? schedule.assemble_finished_by.first_name : ''} ${schedule.assemble_finished_by.last_name ? schedule.assemble_finished_by.last_name : ''}`"
                      />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="schedule.is_t_and_b" />
                  <v-list-item v-if="schedule.is_t_and_b">
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                      >
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 32px; height: 32px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >T</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 20px;">T&B Finished</span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text
                        style="font-size: 16px; color: #000000;"
                        v-text="schedule.t_and_b_finished_at"
                      />
                      <v-list-item-action-text
                        style="font-size: 16px; color: #1976d2;"
                        v-text="`${schedule.t_and_b_finished_by.first_name ? schedule.t_and_b_finished_by.first_name : ''} ${schedule.t_and_b_finished_by.last_name ? schedule.t_and_b_finished_by.last_name : ''}`"
                      />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="schedule.is_glaze" />
                  <v-list-item v-if="schedule.is_glaze">
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                      >
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 32px; height: 32px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >G</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 20px;">Glazing Finished</span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text
                        style="font-size: 16px; color: #000000;"
                        v-text="schedule.glaze_finished_at"
                      />
                      <v-list-item-action-text
                        style="font-size: 16px; color: #1976d2;"
                        v-text="`${schedule.glaze_finished_by.first_name ? schedule.glaze_finished_by.first_name : ''} ${schedule.glaze_finished_by.last_name ? schedule.glaze_finished_by.last_name : ''}`"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
    dialog: false,
    schedule: null,
  }),
  created() {
  },
  methods: {

    async getSchedule(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.productionSchedule.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.schedule = res.data[firstIndex];
    },

    open(id) {
      this.getSchedule(id);
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>
