<template>
  <v-switch
    v-model="isDark"
    class="hover"
    :prepend-icon="isDarkMode ? 'mdi-brightness-7' : 'mdi-brightness-3'"
    color="grey lighten-2"
    style="width: 50px;"
    inset
    hide-details
    @change="storeDarkMode"
    @click.stop
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    isDark: false,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
  },
  created() {
    this.isDark = this.isDarkMode;
  },
  methods: {
    storeDarkMode() {
      this.$vuetify.theme.dark = !this.isDarkMode;
      this.$store.commit('storeDarkMode', !this.isDarkMode);
    },
  },
};
</script>
