<template>
  <v-container>
    <v-row>
      <v-col
        cols="5"
        class="pb-0 text-center"
      >
        <span
          :style="{ color: isDarkMode ? '' : '#000000', fontSize: `${titleSize}px` }"
        >{{ itemsTitle }}</span>
      </v-col>
      <v-col cols="1" />
      <v-col
        cols="5"
        class="pb-0 text-center"
      >
        <span
          :style="{ color: isDarkMode ? '' : '#000000', fontSize: `${titleSize}px` }"
        >{{ selectedItemsTitle }}</span>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card :height="height">
          <v-list
            :height="height"
            style="overflow-y:auto;"
          >
            <v-list-item-group
              v-model="selectedItem"
              :height="height"
              color="primary"
            >
              <v-list-item
                v-for="item in availableItems"
                :key="item.id"
                :value="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="1"
        class="pt-0 text-center"
        :style="{marginTop: `${height / 2 - 30}px`}"
      >
        <v-btn
          text
          class="pa-1"
          style="min-width: 0;"
          @click="rightArrowClicked"
        >
          <v-icon small>
            fas fa-arrow-right
          </v-icon>
        </v-btn>
        <v-btn
          text
          class="pa-1"
          style="min-width: 0;"
          @click="leftArrowClicked"
        >
          <v-icon small>
            fas fa-arrow-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="5">
        <v-card :height="height">
          <v-list
            :height="height"
            style="overflow-y:auto;"
          >
            <v-list-item-group
              v-model="selectedItem"
              :height="height"
              color="primary"
            >
              <v-list-item
                v-for="item in selectedItems"
                :key="item.id"
                :value="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if="isOrder"
        cols="1"
      >
        <v-col
          cols="1"
          class="pt-0 pl-2"
          :style="{marginTop: `${height / 2 - 40}px`}"
        >
          <v-btn
            text
            class="pa-1"
            style="min-width: 0;"
            @click="upArrowClicked"
          >
            <v-icon small>
              fas fa-arrow-up
            </v-icon>
          </v-btn>
          <v-btn
            text
            class="pa-1"
            style="min-width: 0;"
            @click="downArrowClicked"
          >
            <v-icon small>
              fas fa-arrow-down
            </v-icon>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    // v-model
    value: {
      type: Array,
      default: () => [],
    },
    // id
    itemValue: {
      type: String,
      default: '',
    },
    // name
    itemText: {
      type: String,
      default: '',
    },
    itemsTitle: {
      type: String,
      default: '',
    },
    selectedItemsTitle: {
      type: String,
      default: '',
    },
    titleSize: {
      type: Number,
      default: 18,
    },
    height: {
      type: Number,
      default: 300,
    },
    isOrder: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedItem: null,
    selectedItems: [],
    availableItems: [],
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    })
  }
  ,
  watch: {
    value: {
      immediate: true,
      handler() {
        const listItems = this.renameKeys();
        if (this.value.length < 1) {
          this.selectedItems = [];
          this.availableItems = listItems;
        } else {
          const selectedItems = listItems.filter((x) => this.value.includes(x.id));
          // sort for order change (up, down arrows)
          this.selectedItems = selectedItems.sort((a, b) => this.value.indexOf(a.id) - this.value.indexOf(b.id));
          this.availableItems = listItems.filter((x) => !this.value.includes(x.id));
        }
      },
    },
  },
  methods: {
    renameKeys() {
    // if itemText or itemValue are not specified than using name and id as default values
      const idValue = this.itemValue ? this.itemValue : 'id';
      const nameValue = this.itemText ? this.itemText : 'name';

      return this.items.map((x) => ({ id: x[idValue], name: x[nameValue] }));
    },

    rightArrowClicked() {
      if (this.selectedItem === null) return;
      const selectedItemIds = this.selectedItems.map((x) => x.id);
      selectedItemIds.push(this.selectedItem);
      // v-model change
      this.$emit('input', selectedItemIds);
    },

    leftArrowClicked() {
      if (this.selectedItem === null) return;
      const selectedItemIds = this.selectedItems.map((x) => x.id);
      const index = selectedItemIds.indexOf(this.selectedItem);
      if (index !== -1) {
        selectedItemIds.splice(index, 1);
      }
      // v-model change
      this.$emit('input', selectedItemIds);
    },

    upArrowClicked() {
      if (this.selectedItem === null) return;
      const selectedItemIds = this.selectedItems.map((x) => x.id);
      const index = selectedItemIds.indexOf(this.selectedItem);
      if (index !== -1 && index !== 0) {
        selectedItemIds.splice(index, 1);
        selectedItemIds.splice(index - 1, 0, this.selectedItem);
      } else return;
      // v-model change
      this.$emit('input', selectedItemIds);
    },

    downArrowClicked() {
      if (this.selectedItem === null) return;
      const selectedItemIds = this.selectedItems.map((x) => x.id);
      const index = selectedItemIds.indexOf(this.selectedItem);
      if (index !== -1 && index < this.selectedItems.length) {
        selectedItemIds.splice(index, 1);
        selectedItemIds.splice(index + 1, 0, this.selectedItem);
      } else return;
      // v-model change
      this.$emit('input', selectedItemIds);
    },
  },

};

</script>

<style scoped>
</style>
