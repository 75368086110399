var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.goBack}},[_c('v-icon',[_vm._v(" fas fa-arrow-left ")])],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(_vm._s(_vm.reportTemplate ? 'R' + _vm.reportTemplate.id + ' - ' + _vm.reportTemplate.template_title : ''))])]),_c('v-col',{staticClass:"pb-0 text-end",attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"mt-1 mb-1",attrs:{"color":"success","outlined":"","large":""},on:{"click":function($event){return _vm.openRunForm(_vm.reportTemplate.id)}}},[_vm._v(" RUN ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.recordHeaders,"items":_vm.records,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.status ? 'success' : 'error',"text-color":"white"}},[_vm._v(" "+_vm._s(item.status ? 'Succeeded' : 'Failed')+" ")])]}},{key:"item.params",fn:function(ref){
var item = ref.item;
return _vm._l((item.params),function(p){return _c('span',{key:p.name},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(p.name))]),_vm._v(" : "+_vm._s(p.value)+" "),_c('br')])})}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.first_name + ' ' + item.user.last_name : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status && _vm.reportTemplate && _vm.reportTemplate.file_type_ids && _vm.reportTemplate.file_type_ids.includes(_vm.fileTypePdfId))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openPdf(item)}}},[_vm._v(" PDF ")]):_vm._e(),(item.status && _vm.reportTemplate && _vm.reportTemplate.file_type_ids && _vm.reportTemplate.file_type_ids.includes(_vm.fileTypeXlsId))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"warning","outlined":"","small":""},on:{"click":function($event){return _vm.openXLS(item)}}},[_vm._v(" XLS ")]):_vm._e(),(item.status && _vm.reportTemplate && _vm.reportTemplate.file_type_ids && _vm.reportTemplate.file_type_ids.includes(_vm.fileTypeCSVId))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"warning","outlined":"","small":""},on:{"click":function($event){return _vm.openCSV(item)}}},[_vm._v(" CSV ")]):_vm._e(),(item.status && _vm.reportTemplate && _vm.reportTemplate.file_type_ids && _vm.reportTemplate.file_type_ids.includes(_vm.fileTypeTxtId))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"warning","outlined":"","small":""},on:{"click":function($event){return _vm.openTxt(item)}}},[_vm._v(" TXT ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteRecord(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('report-run-form',{ref:"reportRunForm",on:{"success":_vm.showSuccess}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }