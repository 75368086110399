<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row align="center">
      <v-col
        v-if="businessRulesGetBoolean('Inventory Classes')"
        cols="2"
        class="pb-0 pt-4"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <!-- <v-col
        cols="2"
        class="pb-0 pt-4"
      >
        <v-select
          v-model="statusId"
          :items="statuses"
          label="Status"
          item-text="name"
          item-value="id"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : ''"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col> -->
      <v-col
        cols="auto"
        class="pb-0 pt-4 pl-6"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pr-0 pt-4"
          >
            <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0,0.6)', fontSize: '16px' }">Hide</span>
          </v-col>
          <v-col cols="auto">
            <v-switch
              v-model="isShowManual"
              hide-details
              class="mt-0"
              label="Show Manual"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="canEdit"
        class="text-end pb-0 pt-7"
      >
        <v-btn
          color="primary"
          outlined
          @click="openJobForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Job
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="jobsHeaders"
            :items="jobs"
            :footer-props="itemsTableFooterProps"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @update:options="updateOptions"
          >
            <template #[`item.id`]="{ item }">
              <span style="font-weight: bold;"><text-highlight :queries="search">
                {{ item.id ? item.id : '-' }}
              </text-highlight></span>
            </template>
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.code`]="{ item }">
              <text-highlight
                style="font-weight: bold;"
                :queries="search"
              >
                {{ item.code ? item.code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.name ? item.name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.project_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.project_name ? item.project_name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.reference`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.reference ? item.reference : '-' }}
              </text-highlight>
            </template>
            <template #[`item.client_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.client_name ? item.client_name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.address`]="{ item }">
              <text-highlight :queries="search">
                {{ item.address ? item.address : '-' }}
              </text-highlight>
            </template>
            <template #[`item.location`]="{ item }">
              <text-highlight :queries="search">
                {{ item.location ? item.location.name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.supervisor`]="{ item }">
              <text-highlight :queries="search">
                {{ item.supervisor ? item.supervisor : '-' }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <!-- <v-chip
                small
                :color="item && item.is_active ? 'green' : 'red'"
                text-color="white"
              >
                {{ item && item.is_active ? 'ACTIVE' : 'ARCHIVED' }}
              </v-chip> -->
              <v-chip
                v-if="item.status"
                small
                :color="item.status.color"
                text-color="white"
              >
                {{ item.status.name }}
              </v-chip>
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ item.created_by ? item.created_by : '-' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="toggle(item)"
              >
                {{ item.is_active === 1 ? 'ARCHIVE' : 'ACTIVATE' }}
              </v-btn>
              <v-btn
                v-if="canEdit"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditJobForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
              <a
                v-if="enabled_bom_rma == 1"
                class="mr-2 mt-2 mb-2 ml-1"
                @click="showBoms(item, 'bom')"
              > BOMs </a>
              <a
                v-if="enabled_bom_rma == 1"
                class="mr-2 mt-2 mb-2"
                @click="showBoms(item, 'rma')"
              > RMAs </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <job-form
      ref="jobForm"
      :is-job-name="!isJobCode"
      :is-work-order="isJobCode === 0 ? false : true"
      :is-address="!isJobCode"
      :is-project="isJobCode === 0 ? false : true"
      :is-location="isJobCode === 0 ? false : true"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import JobForm from '@/components/forms/jobs/JobForm';

export default {
  components: {
    JobForm,
  },
  data: () => ({
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    jobs: [],
    statuses: [],
    statusId: 1,
    canEdit: false,
    enabled_bom_rma: 0,
    inventoryClassId: null,
    isShowManual: false,
    total: 0,
    pagination: {
      itemsPerPage: 100,
      page: 1,
      sortBy: 'id',
      sortDesc: true,
    },
    windowHeight: 1000,
    topSpaceHeight: 230,
    isJobCode: false,
  }),
  computed: {
    jobsHeaders() {
      const headers = [];
      const invClassesBRName = 'Inventory Classes';
      const invClassesBRValue = this.businessRulesGetBoolean(invClassesBRName);
      if (invClassesBRValue != null && invClassesBRValue !== 0) {
        headers.push({
          text: 'Class', value: 'inventory_class', width: '3%', align: 'center',
        });
      }
      const jobCodeBRName = 'Job Code';
      const jobCodeBRValue = this.businessRulesGetBoolean(jobCodeBRName);
      if (jobCodeBRValue != null && jobCodeBRValue !== 0) {
        headers.push({ text: 'WO#', value: 'code', width: '5%' });
      } else {
        headers.push({ text: 'Job#', value: 'id' });
      }
      if (jobCodeBRValue != null && jobCodeBRValue !== 0) {
        headers.push({ text: 'Project Name', value: 'project_name', width: '15%' });
      } else {
        headers.push({ text: 'Job name', value: 'name' });
      }
      if (jobCodeBRValue != null && jobCodeBRValue !== 0) { headers.push({ text: 'Reference', value: 'reference', width: '7%' }); }
      if (jobCodeBRValue != null && jobCodeBRValue !== 0) { headers.push({ text: 'Client Name', value: 'client_name', width: '15%' }); }
      if (jobCodeBRValue != null && jobCodeBRValue !== 0) { headers.push({ text: 'Location', value: 'location', width: '7%' }); }
      if (jobCodeBRValue == null || jobCodeBRValue === 0) headers.push({ text: 'Address', value: 'address' });
      headers.push({ text: 'Project Manager', value: 'supervisor', width: '7%' });
      headers.push({
        text: 'Status', value: 'status', align: 'center', width: '7%',
      });
      headers.push({ text: 'Created On', value: 'created_at', width: '7%' });
      if (jobCodeBRValue == null || jobCodeBRValue === 0) headers.push({ text: 'Created By', value: 'created_by', width: '7%' });
      headers.push({
        text: 'Actions', value: 'actions', width: '20%', align: 'end',
      });

      return headers;
    },
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      search: (state) => state.search,
      isDarkMode: 'isDarkMode',
      jobsState: (state) => state.jobsState,
    }),
  },
  watch: {
    inventoryClassId() {
      this.get();
      this.getStatuses();
    },
    statusId(value) {
      this.storeState('status_id', value);
      this.get();
    },
    isShowManual() {
      this.get();
    },
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
  },
  created() {
    if (this.jobsState) {
      if (this.jobsState.pagination) { this.pagination = this.jobsState.pagination; }
      if (this.jobsState.status_id !== null) { this.statusId = this.jobsState.status_id; }
    }
    this.canEdit = this.$store.getters.userCanEdit;
    const { businessRules } = this.$store.getters;
    this.enabled_bom_rma = businessRules.find((x) => x.name === 'Bom/rma code').value;
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    const jobCodeBRName = 'Job Code';
    this.isJobCode = this.businessRulesGetBoolean(jobCodeBRName);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {
      this.get();
      this.getStatuses();
    },

    storeState(property, value) {
      const state = this.jobsState;
      state[property] = value;
      this.$store.commit('storeJobsState', state);
    },

    async get() {
      this.loading = true;
      this.jobs = [];
      this.total = 0;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
          status_id: this.statusId,
          is_glass_separate: false,
          is_manual: this.isShowManual === true ? 1 : 0,
          is_status: true,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.job.readData(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.jobs = [];
        this.loading = false;
        return;
      }
      this.jobs = res.data;
      this.total = res.total;
      this.loading = false;
    },

    async getStatuses() {
      const res = await this.$api.jobStatus.get();
      if (!res) return;
      this.statuses = res;
      this.statuses.unshift({ id: 0, name: 'ALL', color: '#000' });
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;

      const res = await this.$api.job.delete(id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async toggle(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure you want to ${item.is_active === 1 ? 'Archive' : 'Active'} this Job?`,
      });
      if (!confirm) return;
      const isActive = item.is_active === 1 ? 0 : 1;
      const res = await this.$api.job.get(`toggle/${item.id}/${isActive}`);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.get();
    },

    openConsumption(item) {
      this.$router.push(`/consumption/${item.id}`);
    },
    openJobForm() {
      this.$refs.jobForm.open();
    },

    openEditJobForm(id) {
      this.$refs.jobForm.open(id);
    },

    success() {
      this.get();
    },

    showBoms(item, type) {
      this.$emit('show-boms', item.id, type);
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
      this.storeState('pagination', newOptions);
    },
  },
};
</script>
<style scoped>

::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}
</style>
