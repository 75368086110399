<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Submit'"
    :is-submit-btn="false"
  >
    <template slot="header">
      <span>Update contacts</span>
    </template>
    <template slot="body">
      <div class="d-flex flex-column pa-2">
        <div
          v-for="contact in items"
          :key="contact.id"
          class="d-flex flex-column pb-2"
        >
          <div
            v-if="editId !== contact.id"
            class="d-flex align-center justify-space-between pa-2"
            style="border: 1px solid rgba(0,0,0,0.6); border-radius: 4px;"
          >
            <span style="font-size: 18px;">{{ contact.name }}</span>

            <div
              class="d-flex align-center"
            >
              <v-btn
                outlined
                small
                class="mr-1"
                color="error"
                @click="softDelete(contact.id)"
              >
                Delete
              </v-btn>
              <v-btn
                color="primary"
                outlined
                small
                @click="edit(contact.id)"
              >
                Edit
              </v-btn>
            </div>
          </div>
          <v-text-field
            v-else
            v-model="item.name"
            label="Name"
            outlined
            dense
            :rules="[(v) => !!v || 'Contact Name is required']"
          >
            <template #append>
              <div
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  small
                  class="mr-1"
                  @click="cancel"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  small
                  @click="submit"
                >
                  Submit
                </v-btn>
              </div>
            </template>
          </v-text-field>
        </div>
        <div>
          <v-btn
            v-if="editId == null"
            block
            class="elevation-0"
            outlined
            color="primary"
            @click="openNew"
          >
            Add Contact
          </v-btn>
        </div>
      </div>
      <confirm-form ref="confirmForm" />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: null,
    },
    items: [],
    editId: null,
    supplierId: null,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(supplierId) {
      const submitModel = {
        filters: {
          supplier_id: supplierId,
        },
      };
      const res = await this.$api.supplierContact.read(submitModel);
      if (!res || res.length < 1 || !res[0]) {
        this.items = [];
        return;
      }
      this.items = res;
    },

    async submit() {
      if (!this.item) return false;
      let res;
      this.item.supplier_id = this.supplierId;
      if (this.item.id) {
        res = await this.$api.supplierContact.update(this.item);
      } else {
        res = await this.$api.supplierContact.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      this.item = { ...this.itemDto };
      this.get(this.supplierId);
      this.editId = null;

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(supplierId) {
      if (supplierId) {
        this.get(supplierId);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
      this.supplierId = supplierId;
    },

    openNew() {
      this.editId = 0;
      this.item = { ...this.itemDto };
      this.items.push(this.item);
    },

    edit(id) {
      this.editId = id;
      this.item = { ...this.items.find((i) => i.id === id) };
    },

    cancel() {
      this.item = { ...this.itemDto };
      this.editId = null;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.supplierContact.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.get(this.supplierId);
    },
  },
};
</script>

<style scoped>
::v-deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner
{
  margin-top: 6px;
}
</style>
