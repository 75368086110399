<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    submit-btn-text="Create"
    :full-screen="true"
    :warning-text="'*Red ones are still to be created'"
  >
    <template slot="header">
      <span>Create Remake PO</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
            dense
          >
            <template #[`item.inventory_class_id`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <span
                style="font-size: 14px !important; color: red;"
                class="font-weight-bold"
              >

                {{ item.batch_number ? item.batch_number : '' }}
              </span>
            </template>
            <template #[`item.po_number`]="{ item }">
              {{ item.po_number }}
            </template>
            <template #[`item.g_code`]="{ item }">
              {{ item.g_code }}
            </template>
            <template #[`item.glass_thickness`]="{ item }">
              {{ item.glass_thickness }}
            </template>
            <template #[`item.total_glass`]="{ item }">
              {{ item.total_glass }}
            </template>
            <template #[`item.job_code`]="{ item }">
              {{ item.job && item.job.code ? item.job.code : '-' }}
            </template>
            <template #[`item.job_name`]="{ item }">
              {{ item.job && item.job.name ? item.job.name : '-' }}
            </template>
            <template #[`item.remake_reason_id`]="{ item }">
              <v-select
                v-model="item.remake_reason_id"
                :items="remakeReasons"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Remake Reason is required']"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    <span style="white-space:nowrap; color: #FFFFFF">{{ item.name }}</span>
                  </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    <span style="white-space:nowrap; color: #FFFFFF">{{ item.name }}</span>
                  </v-chip>
                </template>
              </v-select>
            </template>
            <template #[`item.status`]="{ }">
              <v-chip
                small
                color="error"
                text-color="white"
              >
                TO BE CREATED
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    groupedBatches: [],
    items: [],
    selectedIds: [],
    inventoryClassId: null,
    remakeReasons: [],
    headers: [
      { text: 'Class', value: 'inventory_class_id' },
      { text: 'WO#', value: 'job_code' },
      { text: 'Project', value: 'job_name' },
      { text: 'PO#', value: 'po_number' },
      { text: 'Batch#', value: 'batch_number' },
      { text: 'Glass Code', value: 'g_code' },
      { text: 'Glass Thickness', value: 'glass_thickness' },
      { text: 'Glass Qty', value: 'total_glass' },
      { text: 'Max Glass Qty', value: 'max_total_glass' },
      { text: 'Remake Reason', value: 'remake_reason_id', width: '15%' },
      { text: 'Status', value: 'status', align: 'center' },
    ],
    loading: false,
    isRemake: false,
  }),
  methods: {

    async getGroupedBatches() {
      if (!this.selectedIds || this.selectedIds.length === 0) return;
      this.loading = true;
      const submitModel = {
        selected_ids: this.selectedIds,
        inventory_class_id: this.inventoryClassId,
      };
      const res = await this.$api.glassBatch.store(submitModel, 'grouped-manual');
      if (!res) return;
      this.groupedBatches = res;

      if (this.isRemake) {
        const date = new Date();
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        const formattedDate = day + month + year;
        this.groupedBatches.forEach((x) => {
          // eslint-disable-next-line no-param-reassign
          x.po_number = `${formattedDate}R`;
        });
      }
      this.loading = false;
    },

    async get() {
      if (!this.selectedIds || this.selectedIds.length === 0) return;
      this.loading = true;
      const submitModel = {
        filters: {
          selected_ids: this.selectedIds,
        },
      };

      const res = await this.$api.glass.readGlass(submitModel);
      if (!res) return;

      this.items = res.data;
      this.loading = false;
    },

    async submit() {
      if (this.items.length === 0) {
        this.$bus.$emit('showError', 'There are no Glass items to add to a Batch!');
        return false;
      }

      this.$bus.$emit('loading');

      const res = await this.$api.glassPurchaseOrderItem.store(this.items, 'store-remake');
      this.$bus.$emit('loading-stop');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(selectedIds, inventoryClassId, isRemake) {
      this.$refs.dialogBox.open();
      this.groupedBatches = [];
      this.items = [];
      this.selectedIds = selectedIds;
      this.inventoryClassId = inventoryClassId;
      this.isRemake = isRemake;
      this.get();
      this.getReasons();
    },

    async getReasons() {
      const res = await this.$api.glassRemakeReason.get();
      if (!res) return;
      this.remakeReasons = res;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}
</style>
