var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":500,"loading":_vm.loading,"fixed-header":"","height":_vm.windowHeight,"dense":""},scopedSlots:_vm._u([{key:"item.row",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.row)+" ")])]}},{key:"item.glass_description",fn:function(ref){
var item = ref.item;
return [(item.exterior_glass)?_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("E: ")]),_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.exterior_glass)+" "+_vm._s(item.ext_heat_treatment)+" ")])],1):_vm._e(),(item.center_glass)?_c('span',[(item.exterior_glass)?_c('span',[_vm._v("/")]):_vm._e(),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" C: ")]),_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.center_glass)+" ")])],1):_vm._e(),(item.interior_glass)?_c('span',[(item.exterior_glass || item.center_glass)?_c('span',[_vm._v("/")]):_vm._e(),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" I: ")]),_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.interior_glass)+" ")])],1):_vm._e(),(item.single_glass)?_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("S: ")]),_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.single_glass)+" ")])],1):_vm._e()]}},{key:"item.le_surface",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.le_surface)+" ")])]}},{key:"item.argon",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.argon)+" ")])]}},{key:"item.awd_spacer",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.awd_spacer)+" ")])]}},{key:"item.spandrel_paint",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.spandrel_paint)+" ")])]}},{key:"item.stc",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.stc)+" ")])]}},{key:"item.glass_type",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.searchArray}},[_vm._v(" "+_vm._s(item.glass_type)+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }