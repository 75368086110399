import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelReadJobs;
let cancelReadJobsSecondary;
let cancelReadPos;
let cancelReadPoGrouped;
let cancelReadPosSecondary;
let cancelReadGlass;
class GlassApi extends Api {
  constructor() {
    super('glasses', 'glasses');
  }

  async readJobs(data) {
    if (cancelReadJobs !== undefined) {
      cancelReadJobs();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadJobs = c;
      }),

    }, 'read/jobs');
    return res;
  }

  async readJobsSecondary(data) {
    if (cancelReadJobsSecondary !== undefined) {
      cancelReadJobsSecondary();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadJobsSecondary = c;
      }),

    }, 'read/jobs');
    return res;
  }

  async readPos(data) {
    if (cancelReadPoGrouped !== undefined) {
      cancelReadPoGrouped();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadPoGrouped = c;
      }),

    }, 'read/pos');
    return res;
  }

  async readPoGrouped(data) {
    if (cancelReadPos !== undefined) {
      cancelReadPos();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadPos = c;
      }),

    }, 'read/po-items');
    return res;
  }

  async readPoGroupedSecondary(data) {
    if (cancelReadPosSecondary !== undefined) {
      cancelReadPosSecondary();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadPosSecondary = c;
      }),

    }, 'read/po-items');
    return res;
  }

  async readGlass(data) {
    if (cancelReadGlass !== undefined) {
      cancelReadGlass();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadGlass = c;
      }),

    }, 'read/glasses');
    return res;
  }

  async readGlassPaintCode(data) {
    if (cancelReadGlass !== undefined) {
      cancelReadGlass();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadGlass = c;
      }),

    }, 'read/glass-paint-codes');
    return res;
  }

  async readSpecifications(data) {
    const res = await this.read(data, null, 'read/specifications');
    return res;
  }

  async download(poNumber, typeId) {
    const res = await this.get(`download-po/${poNumber}/${typeId}`, { responseType: 'blob' });
    return res;
  }
}
const glassApi = new GlassApi();

export default glassApi;
