<template>
  <v-container fluid>
    <v-row v-if="isActivatedVisible">
      <v-col>
        <v-row>
          <v-col
            class="text-center"
          >
            <span style="color:red; font-size: 1.5em">{{ topErrorMessage }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="text-center"
          >
            <v-btn
              color="success"
              x-large
              outlined
              @click="scanNewQrCode"
            >
              Scan new qr
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container fluid v-if="isActivated">
      <v-row>
        <v-col cols="2">
          <v-btn
            text
            @click="goBack"
          >
            <v-icon>
              fas fa-arrow-left
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          md="3"
          sm="12"
          class="text-center mt-1"
        >
          <h3 color="primary">
            {{ isMovingQr ? 'Moving ' : '' }}Multi-Product QR #{{ qrCode }}
          </h3>
        </v-col>
        <v-col
          v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate)"
          cols="7"
          md="7"
          sm="12"
          class="text-right"
        >
          <v-btn
            v-if="!isEdit && !isMovingQr"
            color="primary"
            outlined
            @click="edit"
          >
            EDIT
          </v-btn>
          <v-btn
            v-if="isEdit"
            class="mr-2"
            color="error"
            outlined
            @click="cancel"
          >
            CANCEL
          </v-btn>
          <v-btn
            v-if="isEdit"
            color="success"
            outlined
            @click="submit"
          >
            SAVE
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        :style="`${!isEdit ? `background-color: ${hexToRgb('#E0E0E0')}` : ''}; margin:10px 0 0 0`"
      >
        <v-col>
          <v-row class="align-center">
            <v-col
              cols="4"
              class="text-center"
            >
              <span
                class="h5 font-weight-bold"
                style="font-size: 18px !important;"
              >
                Class:
              </span>
              <v-chip
                class="mt-1 mb-1"
                text-color="white"
                style="cursor: pointer;"
                color="#8dbf8d"
              >
                <span style="font-size: 20px; color: #fff !important;">PAINT</span>
              </v-chip>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              <span
                v-if="!isEdit"
                style="font-size: 18px !important;"
                class="h5 font-weight-bold"
              >
                Location:
              </span>
              <v-autocomplete
                v-if="isEdit"
                v-model="inventoryTag.location_id"
                :items="locations"
                :loading="loadingLocation"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Location is required']"
              >
                <template slot="label">
                  Location<RedAsterisk />
                </template>
              </v-autocomplete>
              <span
                v-else
                style="font-size: 18px !important;"
              > {{ inventoryTag ? inventoryTag.location_name : '' }}</span>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              <span
                v-if="!isEdit"
                style="font-size: 18px !important;"
                class="h5 font-weight-bold"
              >
                Shelf:
              </span>
              <v-autocomplete
                v-if="isEdit"
                v-model="inventoryTag.inventory_location_id"
                :items="shelfs"
                :loading="loadingShelf"
                item-text="name"
                item-value="id"
                :clearable="true"
                :rules="[(v) => !!v || 'Shelf is required']"
              >
                <template slot="label">
                  Shelf<RedAsterisk />
                </template>
              </v-autocomplete>
              <span
                v-else
                style="font-size: 18px !important;"
              > {{ inventoryTag ? inventoryTag.inventory_location_name : '' }}</span>
            </v-col>
          </v-row>
          <v-row v-if="loading">
            <v-col>
              <v-progress-linear
                indeterminate
                rounded
                height="4"
                color="primary"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
        >
          <v-autocomplete
            v-model="glassPaintCodeId"
            label="Description"
            :items="glassPaintCodes"
            item-value="glass_paint_code_id"
            :item-text="item => item.glass_paint_code && item.glass_paint_code.code && item.glass_paint_code.description ? `${item.glass_paint_code.code} ${item.glass_paint_code.description}`: ''"
            clearable
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <v-autocomplete
            v-model="projectId"
            :items="projects"
            item-value="id"
            item-text="name"
            label="Project"
            clearable
            :loading="loading"
          />
        </v-col>
        <v-spacer />
        <v-col
          v-if="!isMovingQr"
          class="text-end"
        >
          <v-btn
            color="primary"
            outlined
            @click="open"
          >
            <v-icon
              small
              class="mr-1"
            >
              fas fa-plus
            </v-icon>
            New Item
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="multiProducts"
              :footer-props="footer"
              :items-per-page="20"
              :search="search"
              :loading="loading"
              dense
            >
              <template #[`item.name`]="{ item }">
                <text-highlight
                  style="font-weight: bold;"
                  :queries="search"
                >
                  {{ item.item_code }}
                </text-highlight>
              </template>
              <template #[`item.description`]="{ item }">
                <text-highlight
                  style="font-weight: bold;"
                  :queries="search"
                >
                  {{ item.item_description }}
                </text-highlight>
              </template>
              <template #[`item.project`]="{ item }">
                <text-highlight
                  :queries="search"
                >
                  {{ item.project_name }}
                </text-highlight>
              </template>
              <template #[`item.uom`]="{ item }">
                <text-highlight
                  :queries="search"
                >
                  {{ item.uom_name }}
                </text-highlight>
              </template>
              <template #[`item.updated_at`]="{ item }">
                <text-highlight
                  :queries="search"
                >
                  {{ item.updated_at }}
                </text-highlight>
              </template>
              <template #[`item.quantity`]="{ item }">
                <text-highlight
                  :queries="search"
                >
                  {{ item.quantity }}
                </text-highlight>
              </template>
              <template #[`item.updated_by`]="{ item }">
                <text-highlight
                  :queries="search"
                >
                  {{ item.updated_by_name }}
                </text-highlight>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="isMovingQr"
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="moveToMPQR(item)"
                >
                  MOVE TO MPQR
                </v-btn>
                <v-btn
                  v-if="!isMovingQr"
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="openEdit(item.id)"
                >
                  EDIT
                </v-btn>
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate)) && !isMovingQr"
                  class="ml-1 mt-1 mb-1"
                  color="error"
                  outlined
                  small
                  :disabled="multiProducts.length === 1"
                  @click="softDelete(item.id)"
                >
                  DELETE
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <MovingMPQRForm
      ref="movingMPQRForm"
      :is-rm-supply="true"
      :is-moving-qr-partially="true"
      @success="getInventoryTag"
    />
    <InventoryMPQRForm
      ref="itemForm"
      @success="getInventoryTag"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InventoryMPQRForm from '@/components/forms/inventoryQrs/InventoryMPQRForm';
import MovingMPQRForm from '@/components/forms/movements/MovingMPQRForm';

export default {
  components: {
    InventoryMPQRForm,
    MovingMPQRForm,
  },
  props: {
    qrType: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    inventoryTag: null,
    qrCode: null,
    multiProducts: [],
    locations: [],
    shelfs: [],
    headers: [
      { text: 'Item', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Project', value: 'project' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'UOM', value: 'uom' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Updated By', value: 'updated_by' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    topSpaceHeight: 235,
    windowHeight: 1000,
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    loading: false,
    isEdit: false,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    glassPaintCodes: [],
    projects: [],
    glassPaintCodeId: null,
    projectId: null,

    isActivated: false,
    isActivatedVisible: false,
    topErrorMessage: 'THIS QR CODE HAS NOT BEEN ACTIVATED YET. PLEASE ACTIVATE IT FIRST.',

    isMovingQr: false,
    movingMPQRDialog: false,
    qrCodeDialog: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'inventoryTag.location_id'() {
      this.getShelves();
    },
    glassPaintCodeId() {
      this.getInventoryTag();
    },
    projectId() {
      this.getInventoryTag();
    },
  },
  created() {
    this.qrCode = this.$route.params.code;
    this.init();
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
    if (this.qrType === 'moving-qr') {
      this.isMovingQr = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {
      await this.getInventoryTag();
      this.glassPaintCodes = JSON.parse(JSON.stringify(this.multiProducts));
      this.getProjects();
    },

    async getInventoryTag() {
      if (!this.qrCode) return;
      this.loading = true;

      const isQtyOnHandZero = 0;
      const getMpqrNonZeroQuantity = 1;

      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
          is_qty_on_hand_o: isQtyOnHandZero,
          exclusive_glass_paint_code_id: this.glassPaintCodeId,
          exclusive_project_id: this.projectId,
          get_mpqr_non_zero_quantity: getMpqrNonZeroQuantity,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (res && res.status === 505) {
        this.loading = false;
        this.topErrorMessage = res.message;
        this.isActivatedVisible = true;
        return;
      }

      if (!res || !res.data || !res.data[0]) {
        this.loading = false;
        this.isActivatedVisible = true;
        return;
      }

      const firstIndex = 0;
      this.inventoryTag = res.data[firstIndex];
      this.multiProducts = this.inventoryTag.multi_products;
      this.loading = false;
      this.isActivatedVisible = false;
      this.isActivated = true;
      this.getLocations();
      this.getShelves();
    },

    async getProjects() {
      this.loading = true;
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.projects = [];
        return;
      }
      this.projects = res.data;
      this.loading = false;
    },

    async getLocations() {
      this.loadingLocation = true;
      this.locations = await this.$api.location.getList();
      this.loadingLocation = false;
    },

    async getShelves() {
      this.loadingShelf = true;
      const readModel = {
        filters:
        {
          location_id: this.inventoryTag.location_id,
        },
      };
      this.shelfs = await this.$api.inventoryLocation.read(readModel);
      this.loadingShelf = false;
    },

    edit() {
      this.isEdit = true;
    },

    async submit() {
      this.loading = true;
      const res = await this.$api.inventoryTag.updateTag(this.inventoryTag);
      if (!res) {
        this.$bus.$emit('showError');
        this.loading = false;
        return;
      }
      this.$bus.$emit('showSuccess');
      this.loading = false;
      this.isEdit = false;
      this.getInventoryTag();
    },

    cancel() {
      this.isEdit = false;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.inventoryMPQR.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getInventoryTag();
    },

    moveToMPQR(item) {
      this.$refs.movingMPQRForm.open(item);
    },

    open() {
      this.$refs.itemForm.open(this.qrCode);
    },

    openEdit(id) {
      this.$refs.itemForm.open(this.qrCode, id);
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .5)`;
        return rgba;
      }
      return null;
    },

    scanNewQrCode() {
      this.$router.push('/inventory-qr');
    },

    goBack() {
      this.isMovingQr ? this.$router.push('/moving-qr') : this.$router.push('/inventory-qr');
      // this.$router.push('/inventory-qr');
    },
  },
};
</script>

<style scoped>

</style>
