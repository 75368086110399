import Api from './Api';

class DangerLevelsApi extends Api {
  constructor() {
    super('danger level', 'danger_levels');
  }
}
const dangerLevelsApi = new DangerLevelsApi();

export default dangerLevelsApi;
