<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :persistent="true"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="errorAlert"
              type="error"
            >
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="item"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Item is required']"
                    required
                  >
                    <template slot="label">
                      Item<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="quantity"
                    type="number"
                    :rules="[(v) => !!v || 'Quantity is required']"
                    required
                  >
                    <template slot="label">
                      Quantity<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import BomRmaItemsApi from '../../api/BomRmaItemsApi';

export default {
  components: {
    RedAsterisk,
  },
  props: ['bomId'],
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    items: [],
    id: null,
    item: null,
    quantity: null,
  }),
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchInventoryItems()]);
    },
    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          this.dialogTitle = `Edit Bom Item #${item.id}`;
          this.btnSubmitName = 'Update';
          this.loadItemToForm(item);
        } else {
          this.dialogTitle = 'New Bom Item';
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        const data = {
          id: this.id,
          bom_rma_id: this.bomId,
          inventory_item_id: this.item,
          quantity: this.quantity,
        };
        const res = await BomRmaItemsApi.store(data);
        if (res) {
          this.$emit('bom-item-creation-success');
        } else {
          this.$emit('bom-item-creation-fail');
        }
        this.dialog = false;
      }
    },
    async loadItemToForm(item) {
      await this.$refs.form.reset();
      this.id = item.id;
      this.item = item.item_id;
      this.quantity = item.quantity;
    },
    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.job = null;
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    async fetchInventoryItems() {
      this.items = await InventoryItemsApi.getList();
    },
  },
};
</script>
