<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Move'"
  >
    <template slot="header">
      <span v-if="item">Glass Item# - {{ item.po_number }}-{{ item.item_index }}</span>
    </template>
    <template slot="body">
      <v-row v-if="item">
        <v-col>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Class</span>
            </v-col>
            <v-col>
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Job</span>
            </v-col>
            <v-col>
              <span style="font-weight: bold;">{{ item.job_code ? `${item.job_code}` : '' }}</span> - {{ item.job_name ? item.job_name : item.job_name }} <span style="color: #1976D2;">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">PO#</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.po_number }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Batch</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.batch_number }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px; font-size: 18px;">Glass Code</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.g_code }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Glass Thickness</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.glass_thickness }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px; font-size: 18px;">Packaging Slip#</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.packaging_slip }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Tag#</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.tag_number }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Width</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ convertToFraction(item.width) }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Height</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ convertToFraction(item.height) }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Quantity</span>
            </v-col>
            <v-col>
              <span style="color: #000; font-size: 18px;">{{ item.quantity }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span style="font-size: 18px;">Current Status</span>
            </v-col>
            <v-col>
              <v-chip
                small
                :color="item && item.status ? item.status.color : 'black'"
                text-color="white"
              >
                {{ item && item.status ? item.status.name : '' }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="auto">
              <span style="font-size: 18px;">Move to Status</span>
            </v-col>
            <v-col>
              <v-select
                v-model="selectedStatusId"
                label="Status"
                :items="statuses"
                item-text="name"
                item-value="id"
                hide-details="true"
                outlined
                clearable
                :rules="[(v) => !!v || 'Move to Status is required']"
              >
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    statusIdNew: 1,
    statusIdShipped: 6,
    statusIdReceived: 7,
    statusIdCutting: 10,
    statusIdTempering: 11,
    selectedStatusId: null,

  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.getStatuses();
  },
  methods: {

    async get(qrCode) {
      this.item = null;
      this.$bus.$emit('loading');
      const poNumberAndItemIndexMap = this.extractPoNumberAndItemIndex(qrCode);
      if (!poNumberAndItemIndexMap) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'QR code not valid');
        return;
      }
      const submitModel = {
        filters: {
          po_number: poNumberAndItemIndexMap.po_number,
          item_index: poNumberAndItemIndexMap.item_index,
          is_pack_slip_null: 1,
        },
      };
      const res = await this.$api.glass.readGlass(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'Glass item not found');
        this.$refs.dialogBox.close();
        return;
      }
      const firstIndex = 0;

      if (res.data[firstIndex].status_id === this.statusIdShipped || res.data[firstIndex].status_id === this.statusIdReceived) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'Glass item already shipped or received');
        this.$refs.dialogBox.close();
        return;
      }
      this.item = res.data[firstIndex];
      this.$bus.$emit('loading');
    },

    async getStatuses() {
      const res = await this.$api.glassPoStatus.get();
      this.statuses = res.filter((x) => x.id === this.statusIdNew || x.id === this.statusIdCutting || x.id === this.statusIdTempering);
    },

    async submit() {
      const submitModel = {
        po_number: this.item.po_number,
        item_index: this.item.item_index,
        status_id: this.selectedStatusId,
      };
      const res = await this.$api.glassPurchaseOrderItem.store(submitModel, 'move');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(qrCode) {
      if (!qrCode) return;
      this.get(qrCode);
      this.$refs.dialogBox.open();
    },

    extractPoNumberAndItemIndex(glassItemQr) {
      if (!glassItemQr) return false;
      const [poNumber, itemIndex] = glassItemQr.split('-');
      const poNumberAndItemIndexMap = {
        po_number: parseInt(poNumber, 10),
        item_index: parseInt(itemIndex, 10),
      };
      if (!poNumber || !itemIndex) return false;
      return poNumberAndItemIndexMap;
    },

    convertToFraction(decimal) {
      const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
      };

      // If the decimal is an exact match to any fraction, return the fraction directly
      if (decimal in fractions) {
        return fractions[decimal];
      }

      const wholeNumber = Math.floor(decimal);
      // eslint-disable-next-line no-param-reassign
      decimal -= wholeNumber; // Remove the whole number part

      let closestFraction = Object.keys(fractions)[0];

      // eslint-disable-next-line no-restricted-syntax
      for (const fraction of Object.keys(fractions)) {
        if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
          closestFraction = fraction;
        }
      }

      if (wholeNumber > 0) {
        return `${wholeNumber} - ${fractions[closestFraction]}"`;
      }
      return fractions[closestFraction];
    },
  },
};
</script>
