<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        class="pa-6"
        style="background-color: #E5F8FF; border: 1.5px solid #E8E8DD;"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pr-0 pt-2"
          >
            <v-btn
              color="primary"
              icon
              @click="isOpen = !isOpen"
            >
              <v-icon v-if="isOpen">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <span style="color: #000000; font-size: 16px; font-weight: bold;">DISCOUNTS ({{ items.length }})</span>
          </v-col>
          <v-col
            v-if="!isItemOpen"
            class="text-end"
          >
            <v-btn
              :disabled="disabled"
              color="primary"
              outlined
              @click="open()"
            >
              <v-icon
                small
                class="mr-1"
              >
                fas fa-plus
              </v-icon>
              New Discount
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="isOpen">
          <v-col>
            <v-row
              v-if="isItemOpen || items.length > 0"
            >
              <v-col
                cols="12"
                class=""
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :items-per-page="-1"
                    dense
                    item-key="temp_id"
                  >
                    <template #[`item.part`]="{ item }">
                      <span v-if="item.inventory_po_other_charge">
                        {{ item.inventory_po_other_charge.name }}
                      </span>
                    </template>
                    <template #[`item.price`]="{ item }">
                      <span v-if="item.is_percent == 1">
                        {{ currency ? currency.symbol_short: '' }} {{ getAmount(item).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} ({{ parseFloat(item.percent != null ? item.percent : 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}%)
                      </span>
                      <span v-else>
                        {{ currency ? currency.symbol_short: '' }} {{ parseFloat(item.price != null ? item.price : 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} ({{ parseFloat(getPercent(item)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}%)
                      </span>
                    </template>
                    <template #[`item.type`]="{ item }">
                      <v-chip
                        class="mt-1 mb-1 mr-3"
                        small
                        :color="item.is_percent == 1 ? '#F57F17' : '#006064'"
                      >
                        <span style="color: #fff">{{ item.is_percent == 1 ? 'PERCENT' : 'AMOUNT' }}</span>
                      </v-chip>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        :disabled="disabled"
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="openEdit(item)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        :disabled="disabled"
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        @click="softDelete(item)"
                      >
                        DELETE
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="isItemOpen">
              <v-col>
                <v-row>
                  <v-col class="pb-0">
                    <zig-zag bg-color="blue" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span style="font-size: 16px; color: #000000; font-weight: bold;"> {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'NEW' }} DISCOUNT</span>
                  </v-col>
                </v-row>
                <v-form ref="form">
                  <v-row>
                    <v-col
                      md="6"
                      sm="8"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0">
                          <span style="color: #000000;">Description</span><RedAsterisk />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-1">
                          <v-text-field
                            v-model="item.description"
                            placeholder="Enter Description"
                            solo
                            :rules="[(v) => !!v || 'Description is required']"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="3"
                      md="3"
                      sm="6"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0 pt-1">
                          <v-radio-group
                            v-model="item.is_percent"
                            hide-details
                            dense
                          >
                            <v-radio
                              :value="0"
                              small
                            >
                              <template slot="label">
                                <span style="color: #000000;">Amount Discount</span><RedAsterisk />
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row @click="item.is_percent = 0">
                        <v-col class="pt-1">
                          <v-text-field
                            v-model="item.price"
                            placeholder="Enter Amount Discount"
                            :disabled="item.is_percent === 1"
                            solo
                            prefix="$"
                            type="number"
                            min="0"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="3"
                      md="3"
                      sm="6"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0 pt-1">
                          <v-radio-group
                            v-model="item.is_percent"
                            hide-details
                            dense
                          >
                            <v-radio
                              :value="1"
                              small
                            >
                              <template slot="label">
                                <span style="color: #000000;">Percent Discount</span><RedAsterisk />
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row @click="item.is_percent = 1">
                        <v-col class="pt-1">
                          <v-text-field
                            v-model="item.percent"
                            :disabled="item.is_percent === 0"
                            placeholder="Enter Percent Discount"
                            solo
                            prefix="%"
                            type="number"
                            min="0"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-col class="pt-0">
                    <v-row>
                      <v-col
                        class="pb-0"
                      >
                        <span style="color: #000000;">Note</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="pt-0"
                      >
                        <v-textarea
                          v-model="item.note"
                          outlined
                          rows="3"
                          :style="{backgroundColor: isDarkMode ? '#1E1E1E' : '#FFFFFF'}"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      class="mr-2"
                      @click="close()"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="pl-2"
                  >
                    <v-btn
                      color="primary"
                      @click="addItem"
                    >
                      {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'CREATE' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ZigZag from '@/components/elements/ZigZag';

export default {
  components: {
    ZigZag,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isTax: {
      type: Number,
      default: 1,
    },
    currency: {
      type: Object,
      default: null,
    },
    subTotal: {
      type: Number,
      default: 0,
    },
    otherSubTotal: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    items: [],
    item: null,
    itemDto: {
      id: 0,
      description: null,
      price: null,
      percent: null,
      is_percent: 0,
      note: null,
    },
    isOpen: true,
    isItemOpen: false,
    tax: 0.13,
    headers: [
      { text: 'Description', value: 'description' },
      { text: 'Note', value: 'note' },
      {
        text: 'Applied Amount', value: 'price',
      },
      {
        text: 'Type', value: 'type',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '20%',
      },
    ],
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    'item.is_percent': {
      handler() {
        if (this.item.is_percent === 0) {
          this.item.percent = null;
        } else {
          this.item.price = null;
        }
      },
    },
  },
  created() {
    this.init();
    this.item = { ...this.itemDto };
  },
  methods: {
    init() {
    },

    async get(inventoryPoId) {
      const readModel = {
        filters:
        {
          inventory_po_id: inventoryPoId,
        },
      };

      const res = await this.$api.inventoryPoDiscount.read(readModel);
      if (!res) {
        this.items = [];
        return;
      }
      this.items = res;
    },

    addItem() {
      if (!this.$refs.form.validate()) return;
      const item = this.items.find((x) => x.temp_id === this.item.temp_id);
      if (item) {
        const index = this.items.findIndex((x) => x.temp_id === this.item.temp_id);
        this.items.splice(index, 1, { ...this.item });
      } else {
        this.items.push(JSON.parse(JSON.stringify(this.item)));
      }

      this.close();
    },

    open() {
      this.isOpen = true;
      this.isItemOpen = true;
      this.item = { ...this.itemDto };
      this.item.temp_id = Date.now();
    },

    close() {
      this.isItemOpen = false;
    },

    openEdit(item) {
      this.item = { ...item };
      this.isItemOpen = true;
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (!confirm) return;
      if (item.id) {
        const res = await this.$api.inventoryPoExpense.delete(item.id);
        if (!res) {
          this.$bus.$emit('showError');
          return;
        }
      }
      const index = this.items.findIndex((x) => x.temp_id === item.temp_id);
      this.items.splice(index, 1);
    },

    getAmount(item) {
      const subtotal = this.getSubtotal(item);
      if (subtotal === 0) return 0;
      return (subtotal * item.percent) / 100;
    },

    getPercent(item) {
      const subtotal = this.getSubtotal(item);
      if (subtotal === 0) return 0;
      return (item.price / subtotal) * 100;
    },

    getSubtotal(item) {
      // appry discount based on item order in the list, so if it's the first item, apply discount to subtotal, if it's the second item, apply discount to subtotal - first item, and so on
      if (!item) return 0;
      let subtotal = this.subTotal + this.otherSubTotal;
      if (subtotal === 0) return 0;
      const index = this.items.findIndex((x) => x.temp_id === item.temp_id);
      for (let i = 0; i < index; i++) {
        const discount = this.items[i].is_percent === 0 ? this.items[i].price : subtotal * (this.items[i].percent / 100);
        subtotal -= discount;
      }
      return subtotal;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #FFFFFF !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th > span {
  color: #000000;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot
{
  border: 1px solid rgba(0,0,0,0.4) !important;
}

::v-deep .v-input--selection-controls
{
    margin-top: 2px !important;
}
</style>
