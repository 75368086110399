<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="templateHeaders"
            :items="templates"
            hide-default-footer
            :items-per-page="-1"
            :search="search"
            :loading="loading"
            dense
          >
            <template #item="{ item }">
              <tr
                :style="{ backgroundColor: hexToRgb(item && item.category && item.category.color ? item.category.color : '#FFFFFF') }"
              >
                <td>
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.category_group }}
                  </text-highlight>
                </td>
                <td style="font-weight: bold;">
                  <text-highlight
                    :queries="search"
                  >
                    R{{ item.id }}
                  </text-highlight>
                </td>
                <td style="font-weight: bold;">
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.template_title }}
                  </text-highlight>
                </td>
                <td>
                  <v-tooltip
                    v-if="item.template_description && item.template_description.length > 20"
                    top
                  >
                    <template #activator="{ on }">
                      <span v-on="on">{{ item.template_description.substring(0, 19) }}...</span>
                    </template>
                    <span>{{ item.template_description }}</span>
                  </v-tooltip>
                  <span v-else>{{ item.template_description }}</span>
                </td>
                <td>
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.last_submitted }}
                  </text-highlight>
                </td>
                <td>
                  {{ item.parameter_names }}
                </td>
                <td class="text-end">
                  <v-btn
                    v-if="item.last_record_id && item.file_type_ids && item.file_type_ids.includes(fileTypePdfId)"
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openPdf(item)"
                  >
                    PDF
                  </v-btn>
                  <v-btn
                    v-if="item.last_record_id && item.file_type_ids && item.file_type_ids.includes(fileTypeXlsId)"
                    class="ml-1  mt-1 mb-1"
                    color="warning"
                    outlined
                    small
                    @click="openXLS(item)"
                  >
                    XLS
                  </v-btn>
                  <v-btn
                    v-if="item.last_record_id && item.file_type_ids && item.file_type_ids.includes(fileTypeCSVId)"
                    class="ml-1 mt-1 mb-1"
                    color="warning"
                    outlined
                    small
                    @click="openCSV(item)"
                  >
                    CSV
                  </v-btn>
                  <v-btn
                    v-if="item.last_record_id && item.file_type_ids && item.file_type_ids.includes(fileTypeTxtId)"
                    class="ml-1 mt-1 mb-1"
                    color="warning"
                    outlined
                    small
                    @click="openTxt(item)"
                  >
                    TXT
                  </v-btn>
                  <v-btn
                    v-if="item.has_records"
                    class="ml-1 mt-1 mb-1"
                    color="purple"
                    outlined
                    small
                    @click="history(item.id)"
                  >
                    HISTORY
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="success"
                    outlined
                    small
                    @click="openRunForm(item.id)"
                  >
                    RUN
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <report-run-form
      ref="reportRunForm"
      @success="showSuccess"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TextHighlight from 'vue-text-highlight';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';
import ReportRunForm from '@/components/forms/reports/ReportRunForm';
import ExcelHelper from '@/helpers/ExcelHelper';

export default {
  components: {
    TextHighlight,
    ReportRunForm,
  },
  data: () => ({
    templates: [],
    templateHeaders: [
      { text: 'Category', value: 'category_group', width: '9%' },
      { text: 'ID', value: 'id', width: '2%' },
      { text: 'Name', value: 'template_title', width: '20%' },
      { text: 'Description', value: 'template_description', width: '9%' },
      { text: 'Last Submitted', value: 'updated_at', width: '10%' },
      { text: 'Parameters', value: 'status', width: '25%' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '25%',
      },
    ],
    loading: false,
    fileTypePdfId: 1,
    fileTypeXlsId: 2,
    fileTypeCSVId: 3,
    fileTypeTxtId: 4,
  }),
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
      search: (state) => state.search,
    }),
    ...mapGetters([
      'checkUserPermission',
    ]),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTemplates();
    },
    async getTemplates() {
      this.loading = true;
      // Admin can see all the templates
      const isUserAdmin = this.checkUserPermission(['Admin']);
      const statusEnabled = 1;
      const submitModel = {
        filters: {
          status: statusEnabled,
          user_id: isUserAdmin ? 0 : this.userId,
        },
        sort: {
          order_by_first: 'last_submitted',
        },
      };
      this.templates = await this.$api.reportTemplate.read(submitModel);
      this.loading = false;
    },

    openRunForm(id) {
      this.$refs.reportRunForm.open(id);
    },

    history(id) {
      this.$router.push(`/reports/${id}`);
    },

    showSuccess(id) {
      // Just for user experience
      setTimeout(() => {
        this.$router.push(`/reports/${id}`);
      }, 1000);
    },

    async openPdf(item) {
      const pdf = await this.$api.report.download(item.last_record_id, 'pdf');
      if (!pdf) {
        this.$bus.$emit('showError', 'Report Failed');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.template_title}_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
    },

    async openXLS(item) {
      const xls = await this.$api.report.download(item.last_record_id, 'xls');
      if (!xls) {
        this.$bus.$emit('showError', 'Report Failed');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.template_title}_${now}`;
      const file = new Blob([xls], {
        type: 'application/vnd.ms-excel',
      });
      saveAs(file, fileName);
    },

    async openCSV(item) {
      const csv = await this.$api.report.download(item.last_record_id, 'csv');
      if (!csv) return;
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.template_title}_${now}`;
      const file = new Blob([csv], {
        type: 'text/csv',
      });
      saveAs(file, fileName);
    },

    async openTxt(item) {
      const xls = await this.$api.report.download(item.last_record_id, 'xls');
      if (!xls) return;
      const file = new File([xls], 'name');
      const result = await ExcelHelper.excelToCSV(file);
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.template_title}_${now}`;
      const blobFile = new Blob([result], {
        type: 'text/plain',
      });
      saveAs(blobFile, fileName);
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .1)`;
        return rgba;
      }
      return null;
    },
  },

};

</script>
