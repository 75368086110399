<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <span style="font-size: 20px; font-weight: 500;">{{ title }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-if="isAddBtn"
                class="text-end"
              >
                <v-btn
                  color="primary"
                  outlined
                  @click="addNew"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >
                    fas fa-plus
                  </v-icon>
                  New Note
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="pb-0">
                <v-textarea
                  v-model="item.text"
                  outlined
                  rows="3"
                  :rules="[(v) => !!v || 'Note is required']"
                >
                  <template slot="label">
                    Note<RedAsterisk />
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="text-end pt-0 pb-0">
                <v-btn
                  v-if="!simple"
                  color="normal"
                  class="mr-2"
                  @click="isAddBtn = true"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="submit"
                >
                  SUBMIT
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn && !simple">
              <v-col class="pb-0 pt-0 ">
                <zig-zag />
              </v-col>
            </v-row>
            <v-row v-if="items.length > 0 && !simple">
              <v-col>
                <v-row>
                  <v-col>
                    <v-card-text style="max-height: 600px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
                      <v-list
                        two-line
                        class="pt-0 pb-0"
                      >
                        <template v-for="(note, index) in items">
                          <v-list-item :key="index">
                            <!-- Display each note -->
                            <v-list-item-content>
                              {{ note.text }}
                              <v-list-item-subtitle style="padding-top: 10px;">
                                {{ note.created_at }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="note.created_by_name">
                                {{ note.created_by_name }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="note.user && note.user.roles">
                                <v-chip
                                  v-for="(role, index) in note.user.roles"
                                  :key="index"
                                  small
                                  :color="note.user.department && note.user.department.color ? note.user.department.color : ''"
                                  text-color="white"
                                  class="mr-1"
                                >
                                  {{ role.name ? role.name : '' }}
                                </v-chip>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle class="text-end">
                                <v-btn
                                  color="primary"
                                  class="mr-2"
                                  outlined
                                  small
                                  @click="edit(note)"
                                >
                                  <v-icon
                                    small
                                    class="mr-1"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  color="error"
                                  outlined
                                  small
                                  @click="softDelete(note)"
                                >
                                  <v-icon
                                    small
                                    class="mr-1"
                                  >
                                    fas fa-trash
                                  </v-icon>
                                </v-btn>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            v-if="index < items.length - 1"
                            :key="note.id"
                          />
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm-form ref="confirmForm" />
  </v-row>
</template>

<script>
import ZigZag from '@/components/elements/ZigZag';

export default {
  components: {
    ZigZag,
  },
  props: {
    title: {
      type: String,
      default: 'Notes',
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: null,
    items: [],
    itemDto: {
      id: 0,
      text: '',
      record_id: null,
      type_id: null,
    },
    dialog: false,
    isAddBtn: true,
    noteId: null,
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get() {
      const submitModel = {
        filters: {
          record_id: this.item.record_id,
          type_id: this.item.type_id,
        },
      };
      const res = await this.$api.note.read(submitModel);
      if (!res) {
        this.items = [];
        this.item = { ...this.itemDto };
        return;
      }
      this.items = res;

      if (this.simple) {
        const note = this.items[0];
        if (note) this.edit(note);
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) return;
      let res = false;

      if (this.item.id) res = await this.$api.note.update(this.item);
      else res = await this.$api.note.store(this.item);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
      this.item.text = '';
      this.isAddBtn = true;
      if (this.simple) this.dialog = false;
      if (this.item.id) {
        // on update, it will return res.id for id
        this.$emit('success', res.id);
      } else {
        // on store, it will return the id in res
        this.$emit('success', res);
      }
    },

    addNew() {
      this.item.id = 0;
      this.item.text = '';
      this.isAddBtn = false;
    },

    edit(data) {
      this.item = { ...data };
      this.isAddBtn = false;
    },

    open(recordId, typeId) {
      this.item = { ...this.itemDto };
      this.isAddBtn = true;
      this.item.record_id = recordId;
      this.item.type_id = typeId;
      this.get();
      this.dialog = true;
    },

    async softDelete(data) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.note.softDelete(data.id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.get();
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>
