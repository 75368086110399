/* eslint-disable import/extensions */
<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        cols="auto"
      >
        <v-tabs
          v-model="selectedTab"
        >
          <v-tab>
            ACTIVE
          </v-tab>
          <v-tab>
            ARCHIVED
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          @click="openUserForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new user
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="users"
            :footer-props="usersTableFooterProps"
            :items-per-page="usersPerPage"
            :search="search"
            :loading="loading"
            dense
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <TextHighlight :queries="search">
                    {{ item.id }}
                  </TextHighlight>
                </td>
                <td>
                  <TextHighlight :queries="search">
                    {{ item.username ? item.username : '-' }}
                  </TextHighlight>
                </td>
                <td>
                  <TextHighlight :queries="search">
                    {{ `${item.first_name} ${item.last_name}` }}
                  </TextHighlight>
                </td>
                <td class="pl-6">
                  <v-row v-if="item.roles && item.roles.length > 1">
                    <v-col class="pl-1">
                      <v-tooltip
                        top
                        color="black"
                      >
                        <template #activator="{ on }">
                          <span
                            style="color: #1976d2;"
                            v-on="on"
                          >ROLES</span>
                        </template>
                        <span
                          v-for="p in item.roles"
                          :key="p.id"
                        >
                          {{ p.name }}<br>
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-row v-else>
                    <v-col
                      v-for="p, idx in item.roles"
                      :key="p.id"
                      cols="auto"
                      class="pr-0 pl-1"
                    >
                      <span>
                        {{ p.name }}<span v-if="idx !== item.roles.length - 1">,</span>
                      </span>
                    </v-col>
                  </v-row>
                </td>
                <td v-if="businessRulesGetBoolean('Inventory Classes')">
                  <v-row v-if="item && (item.role_ids.includes(userRoleIdAdmin) || item.inventory_classes && item.inventory_classes.length == totalClasses - 1)">
                    <v-col
                      cols="auto"
                      class="pr-0"
                    >
                      <v-chip
                        class="mt-1 mb-1"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        color="#000000"
                      >
                        ALL
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-tooltip
                    v-else-if="item.inventory_classes && item.inventory_classes.length > 2"
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span
                        style="color: #1976d2;"
                        v-on="on"
                      >CLASSES</span>
                    </template>
                    <v-row>
                      <v-col class="text-center pt-6 pb-6">
                        <v-row
                          v-for="inventroyClass in item.inventory_classes"
                          :key="inventroyClass.id"
                        >
                          <v-col
                            cols="auto"
                            class="pr-1 pb-1 pt-1"
                          >
                            <v-chip
                              class="mt-1 mb-1"
                              small
                              text-color="white"
                              style="cursor: pointer;"
                              :color="inventroyClass ? inventroyClass.color : 'white'"
                            >
                              {{ inventroyClass ? inventroyClass.name : '' }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-tooltip>
                  <v-row v-else>
                    <v-col
                      v-for="inventroyClass in item.inventory_classes"
                      :key="inventroyClass.id"
                      cols="auto"
                      class="pr-0"
                    >
                      <v-chip
                        class="mt-1 mb-1"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="inventroyClass ? inventroyClass.color : 'white'"
                      >
                        {{ inventroyClass ? inventroyClass.name : '' }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <TextHighlight :queries="search">
                    {{ item.email ? item.email : '-' }}
                  </TextHighlight>
                </td>
                <td>
                  <TextHighlight :queries="search">
                    {{ item.phone_number ? item.phone_number : '-' }}
                  </TextHighlight>
                </td>
                <td>
                  <TextHighlight :queries="search">
                    {{ item.location ? item.location.name : '-' }}
                  </TextHighlight>
                </td>
                <td>
                  {{ item.updated_at }}
                </td>
                <td class="text-right">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openEditUserForm(item)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    @click="changeUserStatus(item)"
                  >
                    {{ item.enabled ? "DISABLE" : "ENABLE" }}
                  </v-btn>
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        elevation="0"
                        fab
                        outlined
                        x-small
                        v-bind="attrs"
                        tile
                        color="primary"
                        style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="pa-3"
                    >
                      <v-list-item
                        @click="softDelete(item)"
                      >
                        <v-list-item-title :style="`color: red; font-weight: 600;`">
                          <v-row align="center">
                            <v-col class="pt-4">
                              DELETE
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <user-form
      ref="userForm"
      :user-p="user"
      :title="formTitle"
      @user-creation="get"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { mapState, mapGetters } from 'vuex';
import UserForm from '@/components/forms/UserForm';

export default {
  name: 'Users',
  components: {
    TextHighlight,
    UserForm,
  },
  data: () => ({
    // Page
    loading: true,
    users: [],
    usersTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    usersPerPage: 50,
    user: null,
    formTitle: '',
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    classes: [],
    totalClasses: 0,
    selectedTab: 0,
  }),

  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    headers() {
      const returnHeaders = [];

      returnHeaders.push({ text: 'Id', value: 'id', width: '3%' });
      returnHeaders.push({ text: 'Username', value: 'username', width: '7%' });
      returnHeaders.push({ text: 'Full Name', value: 'full_name', width: '13%' });
      returnHeaders.push({ text: 'Roles', value: 'role.name', width: '10%' });
      if (this.businessRulesGetBoolean('Inventory Classes')) returnHeaders.push({ text: 'Classes', value: 'classes', width: '5%' });
      returnHeaders.push({ text: 'Email', value: 'email', width: '15%' });
      returnHeaders.push({ text: 'Phone', value: 'phone_number', width: '10%' });
      returnHeaders.push({ text: 'Location', value: 'location', width: '10%' });
      returnHeaders.push({
        text: 'Updated At',
        value: 'updated_at',
        filterable: false,
        width: '10%',
      });
      returnHeaders.push({
        text: 'Actions',
        align: 'end',
        filterable: false,
        sortable: false,
        width: '20%',
      });

      return returnHeaders;
    },
  },
  watch: {
    selectedTab() {
      this.get();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.get();
      this.getClasses();
    },
    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          is_archived: this.selectedTab,
          is_username: false,
        },
      };
      this.users = await this.$api.user.read(submitModel);
      this.loading = false;
    },

    async getClasses() {
      const submitModel = {
        filters: {
        },
      };
      this.classes = await this.$api.inventoryClass.read(submitModel);
      this.totalClasses = this.classes.length;
    },
    openEditUserForm(item) {
      this.user = JSON.parse(JSON.stringify(item));
      this.formTitle = `Edit User #${item.id}`;
      this.$refs.userForm.openDialog();
    },
    openUserForm() {
      this.user = null;
      this.formTitle = 'New User';
      this.$refs.userForm.openDialog();
    },
    async changeUserStatus(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${item.enabled ? 'DISABLE' : 'ENABLE'} this user?`,
      });
      if (!confirm) return;
      const res = await this.$api.user.changeStatus(item.id);
      if (res) {
        this.$bus.$emit('showSuccess');
        this.get();
      } else {
        this.$bus.$emit('showError');
      }
    },
    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to DELETE this user?',
      });
      if (!confirm) return;
      const res = await this.$api.user.softDelete(item.id);
      if (res) {
        this.$bus.$emit('showSuccess');
        this.get();
      } else {
        this.$bus.$emit('showError');
      }
    },
  },
};
</script>
<style scoped></style>
