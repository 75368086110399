import Api from './Api';

class OrganizationsApi extends Api {
  constructor() {
    super('admin_organization', 'admin_organizations');
  }
}
const organizationsApi = new OrganizationsApi();

export default organizationsApi;
