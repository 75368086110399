<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
    :max-width="1500"
  >
    <template slot="header">
      <span v-if="item">Job: {{ item.wo_number }} - {{ item.project }}; {{ item.project_floor }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
              :items-per-page="-1"
              :loading="loading"
              dense
            >
              <template #[`item.cut_height`]="{ item }">
                <span>
                  {{ item.cut_height ? item.cut_height : '-' }}
                </span>
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item && item.status"
                  small
                  :color="item.status.color ? item.status.color : '#000000'"
                  text-color="white"
                >
                  {{ item.status.name }}
                </v-chip>
              </template>
              <template #[`item.note`]="{ item }">
                <span
                  v-if="item.notes && item.notes.length > 0"
                  style="white-space: nowrap; color: rgb(255, 152, 0);"
                >
                  <v-btn
                    icon
                    small
                    @click="openNoteForm(item.id)"
                  >
                    <v-icon
                      small
                    >
                      mdi-eye
                    </v-icon>
                  </v-btn>
                  <span>{{ item.notes && item.notes.length > 0 ? item.notes[0].text : '' }}</span>
                </span>
                <v-btn
                  v-else
                  color="primary"
                  small
                  icon
                  @click="openNoteForm(item.id)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-end">
          <span style="font-size: 20px; font-weight: bold; color: #000000;">Total Items: {{ items.length }}</span>
        </v-col>
      </v-row>
      <NoteForm
        ref="noteForm"
        :simple="true"
        @success="get(item, statusId)"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NoteForm from '@/components/forms/notes/NoteForm.vue';

export default {
  components: {
    NoteForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    loading: false,
    item: null,
    noteTypeIdFinishedGood: 3,
    finishedGoodStatusIdReturned: 12,
    statusId: null,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
    headers() {
      const headers = [
        { text: 'Serial#', value: 'index' },
        { text: 'Tag Id', value: 'tag_id' },
        { text: 'Tag Number', value: 'tag_number' },
        { text: 'Work Order', value: 'work_order' },
        { text: 'Reference', value: 'floor_number' },
        { text: 'Cut Width', value: 'cut_width' },
        { text: 'Cut Height', value: 'cut_height' },
        { text: 'Status', value: 'status', align: 'center' },
      ];

      if (this.isReturnBacklog) {
        headers.push({
          text: 'Return Reason', value: 'return_reason'
        })
      }

      headers.push({
        text: 'Note', value: 'note', align: 'center'
      })

      return headers;
    },
    isReturnBacklog() {
      return this.statusId === this.finishedGoodStatusIdReturned;
    },
  },
  watch: {

  },
  created() {
  },
  methods: {

    async get(item, statusId) {
      this.loading = true;
      this.items = [];
      const submitModel = {
        filters: {
          production_schedule_id: item.id,
          status_id: statusId,
        },
      };
      const res = await this.$api.finishedGood.read(submitModel);
      this.loading = false;
      if (!res) return;
      this.items = res;
    },

    open(item, statusId) {
      this.item = item;
      this.statusId = statusId;
      this.get(item, statusId);
      this.$refs.dialogBox.open();
    },

    openNoteForm(itemId) {
      this.$refs.noteForm.open(itemId, this.noteTypeIdFinishedGood);
    },
  },
};
</script>
