var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"auto"}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',[_vm._v(" ACTIVE ")]),_c('v-tab',[_vm._v(" ARCHIVED ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-4",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-state":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-end"},[(_vm.roles.includes(_vm.userRoleIdAdmin))?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.open}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Project ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{staticStyle:{"height":"calc(100% + 24px)"},attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.windowHeight,"server-items-length":_vm.total,"sort-by":_vm.sort.order_by_first,"sort-desc":_vm.sort.direction_sort},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "order_by_first", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "order_by_first", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "direction_sort", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "direction_sort", $event)},"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.class",fn:function(ref){
var item = ref.item;
return [(item.class)?_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'grey',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.client ? item.client.name : '')+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.address_simple ? item.address_simple : ''))])]}}],null,true)},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.address_full ? item.address_full : '')+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.roles.includes(_vm.userRoleIdAdmin))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.toggle(item)}}},[_vm._v(" "+_vm._s(item.is_archived === 0 ? 'ARCHIVE' : 'ACTIVATE')+" ")]):_vm._e(),(_vm.roles.includes(_vm.userRoleIdAdmin))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_vm._v(" EDIT ")]):_vm._e(),(_vm.roles.includes(_vm.userRoleIdAdmin))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1 mr-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1),_c('project-form',{ref:"projectForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }