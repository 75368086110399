<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        cols="2"
        class="pb-0 pt-4"
      >
        <v-select
          v-model="isActive"
          :items="statusesFilter"
          label="Status"
          item-text="name"
          item-value="id"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : ''"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="pr-0"
      >
        <v-btn
          color="success"
          outlined
          @click="generateExcel()"
        >
          EXCEL
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
      >
        <v-btn
          color="primary"
          outlined
          @click="
            () => {
              $refs.equipmentItemForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new equipment
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="itemsHeaders"
            :items="items"
            :footer-props="itemsTableFooterProps"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.id`]="{ item }">
              <text-highlight :queries="search">
                {{ item.id }}
              </text-highlight>
            </template>
            <template #[`item.code`]="{ item }">
              <text-highlight
                style="font-weight: bold;"
                :queries="search"
              >
                {{ item.code }}
              </text-highlight>
            </template>
            <template #[`item.category`]="{ item }">
              <text-highlight :queries="search">
                {{ item.category_name }}
              </text-highlight>
            </template>
            <template #[`item.manufacturer`]="{ item }">
              <text-highlight :queries="search">
                {{ item.manufacturer_name }}
              </text-highlight>
            </template>
            <template #[`item.model`]="{ item }">
              <text-highlight :queries="search">
                {{ item.model }}
              </text-highlight>
            </template>
            <template #[`item.serial_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.serial_number ? item.serial_number : '-' }}
              </text-highlight>
            </template>
            <template #[`item.item_reference`]="{ item }">
              <text-highlight :queries="search">
                {{ item.item_reference ? item.item_reference : '-' }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <v-select
                v-model="item.is_active"
                :items="statuses"
                hide-details
                item-text="name"
                item-value="id"
                dense
                solo
                class="select-status"
                :background-color="item.is_active ? '#4CAF50' : '#F44336'"
                style="max-width: 140px; border-radius: 20px; font-size: 12px; color: white !important;"
                @change="changeStatus(item)"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    <span style="white-space:nowrap; color: #FFFFFF">{{ item.name }}</span>
                  </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <span style="color: white; white-space:nowrap;">{{ item.name }}</span>
                </template>
              </v-select>
              <!-- <v-chip
                v-if="item"
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item.status_id === 1 ? 'suceess' : 'error'"
              >
                {{ item.status_id === 1 ? 'ACTIVE' : 'NOT ACTIVE' }}
              </v-chip> -->
            </template>
            <template #[`item.updated_by`]="{ item }">
              <text-highlight :queries="search">
                {{ item.updated_by_name }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="open(item, true)"
              >
                CLONE
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="open(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <EquipmentItemForm
      ref="equipmentItemForm"
      @item-creation-success="success"
      @item-creation-fail="fail"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import EquipmentItemForm from '../../components/forms/EquipmentItemForm';
import EquipmentItemsApi from '../../api/EquipmentItemsApi';
import ExcelHelper from '../../helpers/ExcelHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'Equipment',
  components: {
    EquipmentItemForm,
  },
  filters: {},
  data: () => ({
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    itemsHeaders: [
      { text: 'Equip#', value: 'id', width: '2%' },
      { text: 'QR Code', value: 'code', width: '3%' },
      { text: 'Category', value: 'category', width: '10%' },
      { text: 'Manufacturer', value: 'manufacturer', width: '10%' },
      { text: 'Model', value: 'model', width: '5%' },
      { text: 'Serial Number', value: 'serial_number', width: '5%' },
      { text: 'Item Reference', value: 'item_reference', width: '10%' },
      { text: 'Status', value: 'status', width: '10%', align: 'center' },
      { text: 'Updated By', value: 'updated_by', width: '10%' },
      { text: 'Updated At', value: 'updated_at', width: '10%' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      },
    ],
    items: [],
    isActive: 3,
    statuses: [
      { id: 1, name: 'ACTIVE', color: '#4CAF50' },
      { id: 0, name: 'NOT ACTIVE', color: '#F44336' },
    ],
    statusesFilter: [
      { id: 3, name: 'ALL', color: '#000000' },
      { id: 1, name: 'ACTIVE', color: '#4CAF50' },
      { id: 0, name: 'NOT ACTIVE', color: '#F44336' },
    ],
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
    isActive() {
      this.get();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.get();
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          is_active: this.isActive,
        },
      };
      this.items = await EquipmentItemsApi.read(submitModel);
      this.loading = false;
    },

    open(item, isClone = false) {
      if (isClone) {
        this.$refs.equipmentItemForm.openDialog(item, isClone);
      } else {
        this.$refs.equipmentItemForm.openDialog(item);
      }
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;

      const res = await EquipmentItemsApi.get(`soft_delete/${item.id}`);
      if (res && res.errDesc === 'equipment_not_empty') {
        this.$bus.$emit('showError', 'Equipment for this item is not empty.');
      } else if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },
    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },
    fail() {
      this.$bus.$emit('showError');
    },

    generateExcel() {
      let excelData = null;
      excelData = this.items.map((el) => ({
        'Equipment#': el.id,
        'QR COde': el.code,
        Category: el.category_name ? el.category_name : '',
        Manufacturer: el.manufacturer_name ? el.manufacturer_name : '',
        Model: el.model ? el.model : '',
        'Serial Number': el.serial_number ? el.serial_number : '',
        'Item Reference': el.item_reference ? el.item_reference : '',
        Location: el.location ? el.location : '',
        'Warehouse Shelf': el.current_status && el.current_status.warehouse_shelf ? el.current_status.warehouse_shelf : '',
        Condition: el.current_status && el.current_status.equipment_condition ? el.current_status.equipment_condition.name : '',
        Status: el.current_status && el.current_status.equipment_movement_type ? el.current_status.equipment_movement_type.name : '',
        'Hours Spent': el.current_status && el.current_status.hours_spent ? el.current_status.hours_spent : '',
        Description: el.current_status && el.current_status.description ? el.current_status.description : '',
        'Active Status': el.is_active ? 'ACTIVE' : 'NOT ACTIVE',
        'Updated By': el.updated_by_name,
        'Updated At': el.updated_at,
      }));
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Equipment');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Equipment_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },

    async changeStatus(item) {
      const res = await EquipmentItemsApi.get(`active/${item.id}/${item.is_active}`);
      if (!res) return;
      this.get();
    },
  },
};
</script>
<style scoped>

::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 20px;
}
::v-deep .fa-caret-down:before {
    font-size: 14px;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 0 3px 0;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-left: 0px !important;
}
::v-deep .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner {
    width: 15px;
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding-left: 10px;
    padding-right: 10px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
    width: 12px;
}
::v-deep .select-status .theme--light.v-icon {
    color: white;
    padding-right: 6px !important;
}

</style>
