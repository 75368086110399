<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :min-width="600"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Holiday</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || 'Name is required', (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
          >
            <template slot="label">
              Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-6"
        >
          <v-date-picker
            v-model="item.date"
            full-width
            @input="() => showRequiredMessage = false"
          />
        </v-col>
      </v-row>
      <div
        class="v-text-field__details v-messages error--text pl-3 pr-3"
      >
        <span
          v-if="showRequiredMessage"
          class="v-messages__message"
        >
          Date is required
        </span>
      </div>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: null,
      date: null,
    },
    showRequiredMessage: false,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.timeOffHoliday.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async submit() {
      if (!this.item.date) {
        this.showRequiredMessage = true;
        return false;
      }
      let res = false;

      if (this.item.id) {
        res = await this.$api.timeOffHoliday.update(this.item);
      } else {
        res = await this.$api.timeOffHoliday.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
