<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :persistent="true"
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="locationData.unit"
                    label="Unit"
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="locationData.building"
                    :rules="[(v) => !!v || 'Building is required']"
                  >
                    <template slot="label">
                      Building<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="locationData.street"
                    :rules="[(v) => !!v || 'Street is required']"
                  >
                    <template slot="label">
                      Street<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="locationData.city"
                    :rules="[(v) => !!v || 'City is required']"
                  >
                    <template slot="label">
                      City<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="locationData.region_id"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Province/State is required']"
                  >
                    <template slot="label">
                      Province/State<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="locationData.postal_code"
                    label="Postal Code"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="locationData.country_id"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Country is required']"
                  >
                    <template slot="label">
                      Country<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="locationData.name"
                    :rules="[(v) => !!v || 'Location Name is required']"
                  >
                    <template slot="label">
                      Location Name<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="locationData.code"
                    :rules="[(v) => !!v || 'Location Code is required']"
                  >
                    <template slot="label">
                      Location Code<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="toggleForm"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submit"
            >
              submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import LocationsApi from '@/api/LocationsApi';
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    location: {
      type: Object,
      default: () => {},
    },
    dialogTitle: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dialog: false,
    locationData: null,
  }),
  computed: {
    ...mapState({
      countries: 'countries',
      regions: 'regions',
    }),
  },
  watch: {
    location: {
      immediate: true,
      handler(value) {
        this.locationData = { ...value }
          || {
            id: 0,
            name: '',
            unit: '',
            building: '',
            street: '',
            city: '',
            region_id: 0,
            country_id: 0,
            postal_code: '',
            code: '',
          };
      },
    },
  },
  created() {
    if (!this.countries.length) this.getCountries();

    if (!this.regions.length) this.getRegions();
  },
  methods: {
    toggleForm() {
      this.dialog = !this.dialog;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      const userId = this.$store.getters.user.id;

      const data = {
        id: this.locationData.id,
        name: this.locationData.name,
        unit: this.locationData.unit,
        building: this.locationData.building,
        street: this.locationData.street,
        city: this.locationData.city,
        region_id: this.locationData.region_id,
        country_id: this.locationData.country_id,
        postal_code: this.locationData.postal_code,
        code: this.locationData.code,
        created_by: userId,
      };

      const res = await LocationsApi.store(data);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      const msg = this.locationData.id ? 'Location Updated' : 'Location Created';
      this.$emit('location-success', msg);
      this.resetForm();
      this.toggleForm();
    },

    resetForm() {
      this.locationData = {
        id: 0,
        name: '',
        unit: '',
        building: '',
        street: '',
        city: '',
        region_id: 0,
        country_id: 0,
        postal_code: '',
        code: '',
      };
    },

    async getCountries() {
      const data = await this.$api.country.getList();
      this.$store.commit('storeCountries', data);
    },

    async getRegions() {
      const data = await this.$api.region.getList();
      this.$store.commit('storeRegions', data);
    },

  },
};
</script>
