<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    submit-btn-text="Ship"
    :max-width="1000"
  >
    <template slot="header">
      <span>Ship Request# {{ request ? request.id : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col cols="5">
          <v-row>
            <v-col>
              <span style="font-size: 16px;">Inventory Class: <v-chip
                small
                :color="request && request.class ? request.class.color : ''"
                text-color="white"
              >
                {{ request && request.class ? request.class.name : '' }}
              </v-chip></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size: 16px;">Status: <v-chip
                small
                :color="request && request.status ? request.status.color : ''"
                text-color="white"
              >
                {{ request && request.status ? request.status.name : '' }}
              </v-chip></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size: 16px;">Request Delivery Date: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.date : '' }}</span></span>
            </v-col>
          </v-row>
          <v-row v-if="request">
            <v-col>
              <!-- <span style="font-size: 16px;">Job: <span style="color: black; font-size: 18px;">{{ request ? request.job_code : '' }} - {{ request ? request.job_name : '' }}</span></span> -->
              <span style="font-size: 16px;">Job: </span><span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}"> <span style="font-weight: bold;">{{ request.job_code ? `${request.job_code}` : '' }}</span> - {{ request.job_name ? request.job_name : '' }} <span style="color: #1976D2;">{{ request.reference ? `; ${request.reference}` : '' }}</span></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size: 16px;">Floor: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.floor_number : '' }}</span></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size: 16px;">Total Quantity Required: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.total_windows : '' }}</span></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size: 16px;">Total Moving Quantity: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.quantity : '' }}</span></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-size: 16px;">Installer: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.installer_name : '' }}</span></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7">
          <v-row>
            <v-col class="pb-0">
              <span style="font-size: 16px;">Truck Type: <v-chip
                small
                :color="request && request.truck_type ? request.truck_type.color : ''"
                text-color="white"
              >
                {{ request && request.truck_type ? request.truck_type.name : '' }}
              </v-chip></span>
            </v-col>
          </v-row>
          <v-row v-if="request && request.trucks">
            <v-col class="pb-0">
              <span style="font-size: 16px;">Trucks:</span>
            </v-col>
          </v-row>
          <v-row v-if="request && request.trucks && request.trucks.length > 0">
            <v-col>
              <v-row
                v-for="(truck, idx) in request.trucks"
                :key="truck.id"
                align="center"
                justify="center"
              >
                <v-col
                  class="pt-0 pb-0"
                  cols="auto"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">Truck {{ idx + 1 }}: </span>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-text-field
                    v-model="truck.quantity"
                    type="number"
                    :rules="[(v) => !!v || 'Quantity is required']"
                  >
                    <template slot="label">
                      Quantity<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  class="pt-0 pb-0 pr-0 pl-0"
                  cols="3"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">{{ truck ? truck.truck_name : '' }}</span>
                </v-col>
                <v-col
                  class="pt-0 pb-0 pr-1 pl-0"
                  cols="auto"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">{{ truck ? truck.start_time_formatted : '' }} -</span>
                </v-col>
                <v-col
                  class="pt-0 pb-0 pl-0"
                  cols="auto"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">{{ truck ? truck.end_time_formatted : '' }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    requestId: null,
    request: null,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
  },
  methods: {

    async getRequest() {
      const submitModel = {
        filters: {
          id: this.requestId,
        },
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.request = res.data[firstIndex];
    },

    async submit() {
      this.$bus.$emit('loading');
      const requestRes = await this.$api.truckScheduleRequest.get(`ship/${this.requestId}`);

      if (!requestRes) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return false;
      }

      const requestTrucks = this.request.trucks.map((x) => ({ id: x.id, quantity: x.quantity }));

      const res = await this.$api.truckScheduleTruckRequestTruck.store(requestTrucks, 'ship');
      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(requestId) {
      this.request = null;
      this.requestId = requestId;
      this.getRequest();
      this.$refs.dialogBox.open();
    },
  },
};
</script>
