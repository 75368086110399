<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="700"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Supplier</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col class="pb-0 pt-5">
          <span style="font-size: 18px; font-weight: 400;">Main information</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="4"
          class="pb-0 pt-0"
        >
          <inventory-class-select-element
            v-model="item.inventory_class_ids"
            :multiple="true"
            :is-required="true"
            :is-add-all="false"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.code"
            :rules="[(v) => !!v || 'Code is required', (v) => !!v && v.length <= 10 || 'Max 10 characters']"
            counter="10"
            clearable
          >
            <template slot="label">
              Code<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="4"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || 'Name is required', (v) => !!v && v.length <= 50 || 'Max 50 characters']"
            counter="50"
            clearable
          >
            <template slot="label">
              Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="4"
          class="pb-0 pt-0"
          @click="openContactForm"
        >
          <v-autocomplete
            v-model="supplierContactIds"
            readonly
            :items="supplierContacts"
            multiple
            item-value="id"
            item-text="name"
          >
            <template slot="label">
              Contacts
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="5"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.phone_number"
            label="Phone Number"
            :rules="[(v) => (v === null || v.length <= 20) || 'Max 20 characters']"
            counter="20"
            clearable
          />
        </v-col>
        <v-col
          cols="3"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.term"
            label="Term"
            type="number"
            :rules="[(v) => (v === null || v.length <= 5) || 'Max 5 characters']"
            counter="5"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 pt-5">
          <span style="font-size: 18px; font-weight: 400;">Address</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.unit"
            label="Unit"
            required
            clearable
            autocomplete="preventAutocomplete"
            counter="5"
            :rules="[(v) => (v === null || v.length <= 5) || 'Max 5 characters']"
          />
        </v-col>
        <v-col
          cols="3"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.building"
            :rules="[(v) => !!v || 'Building is required', (v) => !!v && v.length <= 10 || 'Max 10 characters']"
            counter="10"
            required
            clearable
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Building<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.street"
            :rules="[(v) => !!v || 'Street is required', (v) => !!v && v.length <= 50 || 'Max 50 characters']"
            counter="50"
            required
            clearable
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Street<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.city"
            :rules="[(v) => !!v || 'City is required', (v) => !!v && v.length <= 20 || 'Max 20 characters']"
            counter="20"
            required
            clearable
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              City<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.address.region_id"
            :items="regions"
            item-text="name"
            clearable
            item-value="id"
            :rules="[(v) => !!v || 'Province/State is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Province/State<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.postal_code"
            label="Postal Code"
            counter="10"
            clearable
            autocomplete="preventAutocomplete"
            :rules="[(v) => (v === null || v.length <= 10) || 'Max 10 characters']"
          />
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.address.country_id"
            :items="countries"
            item-text="name"
            item-value="id"
            clearable
            :rules="[(v) => !!v || 'Country is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Country<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="getRoles.includes(userRoleIdAdmin) || getRoles.includes(userRoleIdProductionAdmin)">
        <v-col class="pb-0 pt-5">
          <span style="color: #000000; font-size: 18px; font-weight: 400;">Functionality</span>
        </v-col>
      </v-row>
      <v-row v-if="getRoles.includes(userRoleIdAdmin) || getRoles.includes(userRoleIdProductionAdmin)">
        <v-col>
          <v-select
            v-model="item.inventory_po_type_ids"
            :items="inventoryPoTypes"
            label="Inventory PO Types"
            item-text="name"
            item-value="id"
            persistent-hint
            multiple
            hint="IMPORTANT! Determines the type of Inventory PO that will be created when an order is placed for this supplier."
          >
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : ''"
              >
                {{ item ? item.name : '' }}
              </v-chip>
            </template>
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item ? item.color : 'white'"
              >
                {{ item ? item.name : '' }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row v-if="getRoles.includes(userRoleIdAdmin) || getRoles.includes(userRoleIdProductionAdmin)">
        <v-col>
          <v-select
            v-model="item.inventory_po_calculation_type_id"
            :items="inventoryPoSupplierCalculationTypes"
            label="Inventory PO Calculation Type"
            item-text="name"
            item-value="id"
            clearable
            persistent-hint
            hint="IMPORTANT! Determines the type of Inventory PO Calculation that will be applied when an order is placed for this supplier."
          >
            <template #item="{ item }">
              <v-row align="center">
                <v-col cols="auto">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : ''"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </v-col>
                <v-col>
                  <span style="font-weight: 600;">{{ item.formula }}</span>
                </v-col>
              </v-row>
            </template>
            <template #selection="{ item }">
              <v-row align="center">
                <v-col cols="auto">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : ''"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </v-col>
                <v-col>
                  <span style="font-weight: 600;">{{ item.formula }}</span>
                </v-col>
              </v-row>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <SupplierContactForm
        ref="contactForm"
        @success="getSupplierContacts"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import SupplierContactForm from '@/components/forms/suppliers/SupplierContactForm';

export default {
  components: {
    SupplierContactForm,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: null,
      code: null,
      phone_number: null,
      term: null,
      contact: null,
      inventory_class_ids: [],
      inventory_po_type_ids: [1],
      inventory_po_calculation_type_id: null,
      address: {
        unit: null,
        building: null,
        street: null,
        city: null,
        region_id: 9,
        postal_code: null,
        country_id: 1,
      },
    },
    regions: [],
    countries: [],
    inventoryPoTypes: [],
    inventoryPoSupplierCalculationTypes: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    supplierTempId: null,
    supplierContacts: [],
    supplierContactIds: [],
    inventoryPoTypeIdNew: 1,
  }),
  computed: {
    ...mapState({
    }),
    ...mapGetters([
      'getRoles',
    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };

    this.getCountries();
    this.getRegions();
    this.getInventoryPoTypes();
    this.getInventoryPoSupplierCalculationTypes();
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.supplier.read(submitModel);
      if (!res || !res.data || !res.data[0]) return;
      const firstIndex = 0;
      this.item = res.data[firstIndex];

      if (!this.item.address || this.item.address.length === 0) {
        this.item.address = {
          unit: null,
          building: null,
          street: null,
          city: null,
          region_id: 9,
          postal_code: null,
          country_id: 1,
        };
      }
    },

    async getCountries() {
      this.countries = await this.$api.country.get();
    },
    async getRegions() {
      this.regions = await this.$api.region.get();
    },

    async getInventoryPoTypes() {
      this.inventoryPoTypes = await this.$api.inventoryPoType.get();
    },

    async getInventoryPoSupplierCalculationTypes() {
      this.inventoryPoSupplierCalculationTypes = await this.$api.inventoryPoSupplierCalculationType.get();
    },

    async getSupplierContacts() {
      const submitModel = {
        filters: {
          supplier_id: this.item.id ? this.item.id : this.supplierTempId,
        },
      };
      const res = await this.$api.supplierContact.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      this.supplierContacts = res;
      this.supplierContactIds = res.map((item) => item.id);
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.supplier.update(this.item);
      } else {
        res = await this.$api.supplier.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
        this.item.inventory_po_type_ids = [this.inventoryPoTypeIdNew];
      }
      this.$refs.dialogBox.open();
      if (!this.item.address.id) {
        this.item.address.region_id = 9;
        this.item.address.country_id = 1;
      }
      const maxId = await this.$api.supplier.get('max-id');
      this.supplierTempId = maxId + 1;
      this.getSupplierContacts();
    },

    openContactForm() {
      this.$refs.contactForm.open(this.item.id ? this.item.id : this.supplierTempId);
    },

    formatPhoneNumber() {
      this.item.phone_number = FormatHelpers.formatPhoneNumber(this.item.phone_number);
    },
  },
};
</script>
