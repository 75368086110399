<template>
  <v-container
    class="pa-0 mt-0"
    fluid
  >
    <v-row>
      <v-col
        cols="auto"
        class="pb-0 mt-4"
      >
        <v-checkbox
          v-model="isHideQtyOnHand"
          class="mt-0 mb-0"
          label="Hide Zero 'Qty On Hand'"
          hide-details
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="filterLocationId"
          label="Location"
          :items="locations"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="filterInventoryLocationId"
          label="Shelf"
          :items="inventoryLocationsFilters"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0"
      >
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="paint-class-data-table"
            fixed-header
            :headers="headers"
            :items="formattedItems"
            :height="windowHeight"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
            disable-sort
            dense
          >
            <template
              v-for="h in headers"
              #[`header.${h.value}`]="{ header }"
            >
              <v-tooltip
                v-if="h.value !== 'paint_code' && h.value !== 'paint_totals'"
                :key="h.value"
                top
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <p
                  v-for="inventory_tag in header.inventory_tags"
                  :key="inventory_tag"
                >
                  <span>
                    QR#: {{ inventory_tag }}
                  </span>
                </p>
              </v-tooltip>
              <span
                v-else
                :key="h.value"
              >
                {{ h.text }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  components: {
  },

  data: () => ({
    items: [],
    paintLocations: [],
    paintCodes: [],
    locations: [],
    inventoryLocations: [],
    inventoryLocationsFilters: [],
    filterLocationId: null,
    filterInventoryLocationId: null,

    loading: true,
    topSpaceHeight: 260,
    windowHeight: 1000,
  }),

  computed: {
    ...mapGetters([
    ]),
    ...mapState({
      inventoryTagsState: (state) => state.inventoryTagsState,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),

    headers() {
      const defaultHeaders = [];
      defaultHeaders.push({
        text: 'Paint Code',
        value: 'paint_code',
        width: '20%',
      });

      this.paintLocations.forEach((locationName) => {
        const inventoryTags = this.items.filter((x) => x.inventory_tag.inventory_location.name === locationName).map((x) => x.inventory_tag.inventory_tag);
        const uniqueInventoryTags = [...new Set(inventoryTags)];
        defaultHeaders.push({
          text: `${locationName}`,
          value: `${locationName}`,
          inventory_tags: uniqueInventoryTags,
        });
      });

      defaultHeaders.push({
        text: 'Totals',
        value: 'paint_totals',
        width: '5%',
      });

      return defaultHeaders;
    },

    formattedItems() {
      const formatted = [];
      this.paintCodes.forEach((code) => {
        const rowData = { paint_code: code };
        this.paintLocations.forEach((location) => {
          let quantity = 0;
          this.items.forEach((item) => {
            if (item.paint_code === code && item.inventory_tag.inventory_location.name === location) {
              quantity += parseFloat(item.quantity);
            }
          });
          if (quantity === 0) quantity = '-';
          rowData[location] = quantity;
        });
        let totalQuantity = 0;
        this.items.forEach((item) => {
          if (item.paint_code === code) {
            totalQuantity += parseFloat(item.quantity);
          }
        });
        rowData.paint_totals = totalQuantity;
        formatted.push(rowData);
      });
      const locationTotals = { paint_code: 'Totals' };
      this.paintLocations.forEach((location) => {
        let totalQuantity = 0;
        this.items.forEach((item) => {
          if (item.inventory_tag.inventory_location.name === location) {
            totalQuantity += parseFloat(item.quantity);
          }
        });
        locationTotals[location] = totalQuantity;
      });
      let totalTotal = 0;
      this.items.forEach((item) => {
        totalTotal += parseFloat(item.quantity);
      });
      locationTotals.paint_totals = totalTotal;
      formatted.push(locationTotals);

      return formatted;
    },

    isHideQtyOnHand: {
      get() { return this.$store.state.isInvTagQtyOnHandEqualsToZero; },
      set(value) { this.$store.commit('storeisInvTagQtyOnHandEqualsToZero', value); this.get(); },
    },
  },
  watch: {
    search() {
      this.get();
    },
    filterLocationId() {
      this.get();
    },
    filterInventoryLocationId() {
      this.get();
    },
  },

  created() {
    this.initialize();
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async initialize() {
      await this.get();
      this.setInventoryLocationsFilter();
      this.getLocations();
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          hide_zero_qty: this.isHideQtyOnHand === true ? 1 : 0,
          location_id: this.filterLocationId,
          inventory_location_id: this.filterInventoryLocationId,
        },
        sort: {
          order_by_first_sort: 'glass_paint_codes.description'
        }
      };
      const res = await this.$api.inventoryMPQR.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || res.length === 0) {
        this.items = [];
        this.groupPaintLocations();
        this.groupPaintCodes();
        this.loading = false;
        return;
      }

      this.items = res;
      this.groupPaintLocations();
      this.groupPaintCodes();
      this.loading = false;
    },

    setInventoryLocationsFilter() {
      const uniqueLocations = new Map();

      this.items.forEach((item) => {
        const loc = item.inventory_tag.inventory_location;
        uniqueLocations.set(loc.id, { name: loc.name, id: loc.id });
      });

      this.inventoryLocationsFilters = Array.from(uniqueLocations.values());
      this.inventoryLocationsFilters.sort((a, b) => this.alphanumericSort(a.name, b.name));
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    groupPaintLocations() {
      this.paintLocations = [...new Set(this.items.map((item) => item.inventory_tag.inventory_location.name))];
      this.paintLocations.sort(this.alphanumericSort);
    },

    alphanumericSort(a, b) {
    // Use regular expressions to separate numbers from letters
      const regex = /(\d+)|(\D+)/g;
      const matchA = a.match(regex);
      const matchB = b.match(regex);
      let i = 0;

      while (i < matchA.length && i < matchB.length) {
        // Compare the non-digit segments as strings and digit segments as numbers
        if (isNaN(matchA[i]) || isNaN(matchB[i])) {
          if (matchA[i] > matchB[i]) return 1;
          if (matchA[i] < matchB[i]) return -1;
        } else {
          const numA = parseInt(matchA[i], 10);
          const numB = parseInt(matchB[i], 10);
          if (numA > numB) return 1;
          if (numA < numB) return -1;
        }
        i++;
      }

      // In case one string is a prefix of the other
      return matchA.length - matchB.length;
    },

    async generatePdf() {
      this.$bus.$emit('loading');
      const webhookModuleIdMPQRSummaryLocation = 38;
      const webhookModuleIdMPQRSummaryAll = 45;
      const moduleId = this.filterLocationId ? webhookModuleIdMPQRSummaryLocation : webhookModuleIdMPQRSummaryAll;
      const webhookData = {
        module_id: moduleId,
        type: 'pdf',
      };

      if (moduleId === webhookModuleIdMPQRSummaryLocation) {
        const location = this.locations.find((x) => x.id === this.filterLocationId);
        if (!location) return;
        webhookData.location = location.name;
      }

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `MPQR_Summary_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    groupPaintCodes() {
      this.paintCodes = [...new Set(this.items.map((item) => item.paint_code))];
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },
  },
};
</script>
<style>
#paint-class-data-table tr:last-child {
  font-weight: bold;
}
</style>
