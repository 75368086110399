<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Glass Specification</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.code"
            :rules="[(v) => !!v || `Code is required`]"
          >
            <template slot="label">
              Code<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="item.description"
            :rules="[(v) => !!v || `Description is required`]"
          >
            <template slot="label">
              Description<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      code: null,
      description: null,
    },
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.glassSpecification.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.glassSpecification.update(this.item);
      } else {
        res = await this.$api.glassSpecification.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
