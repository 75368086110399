var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-end mt-4 pb-0"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.open}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Length ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.classes",fn:function(ref){
var item = ref.item;
return [(item.inventory_classes && item.inventory_classes.length === 11)?_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":"#000000"}},[_vm._v(" ALL ")])],1)],1):(item.inventory_classes && item.inventory_classes.length > 2)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("CLASSES")])]}}],null,true)},[_c('v-row',[_c('v-col',{staticClass:"text-center pt-6 pb-6"},_vm._l((item.inventory_classes),function(inventroyClass){return _c('v-row',{key:inventroyClass.id},[_c('v-col',{staticClass:"pr-1 pb-1 pt-1",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":inventroyClass ? inventroyClass.color : 'white'}},[_vm._v(" "+_vm._s(inventroyClass ? inventroyClass.name : '')+" ")])],1)],1)}),1)],1)],1):_c('v-row',_vm._l((item.inventory_classes),function(inventroyClass){return _c('v-col',{key:inventroyClass.id,staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":inventroyClass ? inventroyClass.color : 'white'}},[_vm._v(" "+_vm._s(inventroyClass ? inventroyClass.name : '')+" ")])],1)}),1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at)+" ")])]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user_name ? item.user_name : '')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('length-form',{ref:"lengthForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }