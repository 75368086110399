<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Color</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.code"
          >
            <template slot="label">
              Code
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field v-model="item.name">
            <template slot="label">
              Name
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
    item: null,
    itemDot: {
      id: 0,
      name: null,
      code: null,
    },
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };

      const res = await this.$api.color.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async submit() {
      let res = false;

      if (this.item.id) {
        res = await this.$api.color.update(this.item);
      } else {
        res = await this.$api.color.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      if (res === '1001') {
        this.$bus.$emit('showError', 'Color already exists');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
<style scoped>
.profile-photo{
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
.profile-p{
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}</style>
