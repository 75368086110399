import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;
class ConsumptionsApi extends Api {
  constructor() {
    super('rmc_consumption', 'rmc_consumptions');
  }

  async getSignleById(consumptionId) {
    let list = [];
    const errorText = 'Could not fetch Consumption Items By Id';

    try {
      list = await axios.get(`/rmc_consumptions/${consumptionId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }

  // async delete(recordId) {
  //   let list = [];
  //   const errorText = 'Could not delete';

  //   try {
  //     list = await axios.post(`/rmc_consumptions/delete/${recordId}`);
  //     if (list.status === 200) {
  //       list = list.data;
  //     } else {
  //       console.error(errorText);
  //     }
  //   } catch (e) {
  //     console.error(errorText);
  //     console.error(e);
  //     list = [];
  //   }

  //   return list;
  // }
}
const consumptionsApi = new ConsumptionsApi();

export default consumptionsApi;
