<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
  >
    <template slot="header">
      <span v-if="windowBreakdown"> <span style="font-weight: bold;"> {{ windowBreakdown.work_order }}</span> - <span> {{ windowBreakdown.project_name }}</span> ; <span style="color: #1976d2;"> {{ windowBreakdown.reference }}</span></span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-data-table
            :headers="headers"
            :items="windowBreakdownItems"
            :items-per-page="-1"
            :loading="loading"
            dense
            hide-default-footer
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    windowBreakdown: null,
    windowBreakdownItems: [],
    typeId: null,
    typeIdSUItems: 1,
    typeIdSPItems: 2,
    typeIdBoxPanels: 3,
    typeIdMetalPanels: 4,
    loading: false,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
    headers() {
      return [
        { text: this.typeId === this.typeIdSUItems || this.typeId === this.typeIdSPItems ? 'Glass Code' : 'Color', value: 'name' },
        { text: 'Quantity', value: 'total_quantity' },
      ];
    },
  },
  watch: {

  },
  created() {
  },
  methods: {

    async getWindowBreakdownItems(windowBreakdown, typeId) {
      this.loading = true;
      this.windowBreakdownItems = [];
      const res = await this.$api.productionSchedule.get(`window-breakdown-items/${windowBreakdown.work_order}/${typeId}`);
      if (!res) {
        this.windowBreakdownItems = [];
        this.loading = false;
        return;
      }
      this.windowBreakdownItems = res;
      this.loading = false;
    },

    open(windowBreakdown, typeId) {
      this.windowBreakdown = windowBreakdown;
      this.typeId = typeId;
      this.getWindowBreakdownItems(windowBreakdown, typeId);
      this.$refs.dialogBox.open();
    },
  },
};
</script>
