<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="1500px"
    >
      <v-card tile>
        <v-card-title>
          RM Supply / Return Records, Inventory Tag {{ inventoryTag }}, Material Id {{ materialId }}
          <span v-if="qrCode">, QR Code {{ qrCode }}</span>

          <v-col class="text-right">
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            class="elevation-1"
            :headers="profilesHeaders"
            :items="supplyRecords"
            :items-per-page="-1"
            :loading="loading"
            hide-default-footer
            fixed-header
            height="70vh"
            dense
          >
            <template #[`item.sheet_id`]="{ item }">
              <a @click="openConsumption(item)">{{ item && item.consumption ? item.consumption.id : '' }}</a>
            </template>
            <template #[`item.sheet_name`]="{ item }">
              <span>{{ item && item.consumption && item.consumption.job ? item.consumption.job.name : '' }}</span>
            </template>
            <template #[`item.sheet_date`]="{ item }">
              {{ item && item.consumption ? item.consumption.sheet_date : '' }}
            </template>
            <template #[`item.material`]="{ item }">
              {{ item && item.inventory_item ? `${item.inventory_item.material_id} ${item.inventory_item.name}` : '' }}
            </template>
            <template #[`item.quantity`]="{ item }">
              {{ item.is_return ? 0 : `-${item.quantity_supplied}` }}
            </template>
            <template #[`item.quantity_returned`]="{ item }">
              {{ item.is_return ? item.quantity_supplied : item.quantity_returned ? item.quantity_returned : 0 }}
            </template>
            <template #[`item.quantity_missed`]="{ item }">
              {{ item.quantity_missed }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props:
  {
    inventoryTag: {
      type: Number,
      default: 0,
    },
    qrCode: {
      type: Number,
      default: 0,
    },
    materialId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    supplyRecords: [],
    loading: false,
    dialog: false,

  }),
  computed: {
    profilesHeaders() {
      const headers = [];
      headers.push({ text: 'Sheet #', value: 'sheet_id' });
      headers.push({ text: 'Sheet Name', value: 'sheet_name' });
      headers.push({ text: 'Sheet Date', value: 'sheet_date' });
      headers.push({ text: 'Material', value: 'material' });
      headers.push({ text: 'Qty. Supplied', value: 'quantity' });
      headers.push({ text: 'Qty. Returned', value: 'quantity_returned' });
      headers.push({ text: 'Qty. Missing', value: 'quantity_missed' });

      return headers;
    },
  },

  watch: {
    inventoryTag() {
      this.getSupplyRecords();
    },
  },

  created() {
  },

  methods: {
    open() {
      this.dialog = true;
    },

    async getSupplyRecords() {
      if (!this.inventoryTag) return;
      this.loading = true;
      const readModel = {
        filters:
        {
          inventory_tag: this.inventoryTag,
        },
      };
      this.supplyRecords = await this.$api.supplyToLineRecord.read(readModel);
      this.loading = false;
      let totalSupplied = 0;
      let totalReturned = 0;
      let totalMissed = 0;
      this.supplyRecords.forEach((x) => {
        if (x.is_return) { totalReturned += x.quantity_supplied; } else { totalSupplied += x.quantity_supplied; }

        totalMissed += x.quantity_missed;
      });
      const totalRecord = {
        inventory_item: {
          material_id: 'Total',
          name: ':',
        },
        quantity_supplied: totalSupplied,
        quantity_returned: totalReturned,
        quantity_missed: totalMissed,
      };
      this.supplyRecords.push(totalRecord);
    },

    openConsumption(item) {
      if (!item && !item.consumption) return;
      this.$router.push(`/consumptions/${item.consumption.id}`);
    },
  },

};
</script>
