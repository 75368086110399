<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="700"
    :submit-btn-text="submitBtn"
  >
    <template slot="header">
      <span>{{ title }} Parameter</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="record.userFriendlyName"
            :rules="[(v) => !!v || 'Parameter Name is required']"
            required
          >
            <template slot="label">
              Parameter Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="record.crystalParameterName"
            :rules="[(v) => hasSpace(v)]"
            required
          >
            <template slot="label">
              Crystal Report Parameter Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="record.description"
            label="Description"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="record.re_parameter_type_id"
            :items="parameterTypes"
            item-text="re_parameter_type"
            item-value="id"
            :rules="[(v) => !!v || 'Format is required']"
          >
            <template slot="label">
              Parameter Type<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="record.re_parameter_type_id === parameterTypeId_Picklist"
          cols="12"
        >
          <v-select
            v-model="record.static_dynamic"
            :items="staticDynamicList"
            item-text="name"
            item-value="value"
          >
            <template slot="label">
              Format
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="record.re_parameter_type_id === parameterTypeId_Picklist"
          cols="12"
        >
          <v-select
            v-model="record.single_multiple"
            :items="singleMultipleList"
            item-text="name"
            item-value="value"
          >
            <template slot="label">
              Parameter Prompt Type
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="record.re_parameter_type_id === parameterTypeId_Picklist && record.static_dynamic === parameterFormatId_Static"
          cols="12"
          class="pt-0"
        >
          <v-textarea
            v-model="record.default_value"
            label="Static Parameters"
            hint="Please Enter Comma-separated values (one, two, three)"
          />
        </v-col>
        <v-col
          v-if="record.re_parameter_type_id === parameterTypeId_Picklist && record.static_dynamic === parameterFormatId_Dynamic"
          cols="12"
          class="pt-0"
        >
          <v-textarea
            v-model="record.dynamic_param_query"
            label="Dynamic Parameter SQL Query"
            hint="Please Enter SQL Query"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    record: null,
    recordId: 0,
    recordDto: {
      id: 0,
      userFriendlyName: '',
      crystalParameterName: '',
      description: '',
      re_parameter_type_id: 0,
      single_multiple: '1',
      static_dynamic: '1',
      dynamic_param_query: '',
      default_value: '',
    },
    parameterTypes: [],
    submitBtn: '',
    title: '',
    staticDynamicList: [{ name: 'Static', value: '1' }, { name: 'Dynamic', value: '2' }],
    singleMultipleList: [{ name: 'Single Choice', value: '1' }, { name: 'Multiple Choice', value: '2' }],
    parameterTypeId_Picklist: 5,
    parameterFormatId_Static: '1',
    parameterFormatId_Dynamic: '2',

  }),
  created() {
    this.record = this.recordDto;
    this.getParameterTypes();
  },
  methods: {

    async get() {
      if (!this.recordId) return;
      this.record = await this.$api.reportParameter.get(this.recordId);
    },

    async getParameterTypes() {
      this.parameterTypes = await this.$api.reportParameterType.get();
    },

    async submit() {
      if (!this.record) return false;
      const userId = this.$store.getters.user.id;
      this.record.updated_by = userId;

      let res;
      if (this.recordId !== 0) {
        res = await this.$api.reportParameter.update(this.record);
      } else {
        res = await this.$api.reportParameter.store(this.record);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      this.$emit('success');
      return res;
    },

    open(id) {
      this.recordId = id;
      if (id) {
        this.title = 'Update';
        this.submitBtn = 'UPDATE';
        this.get();
      } else {
        this.title = 'Create';
        this.submitBtn = 'CREATE';
        this.record = this.recordDto;
      }
      this.$refs.dialogBox.open();
    },

    hasSpace(v) {
      if (!v) return 'Crystal Report Parameter Name is required';
      // if (v.indexOf(' ') >= 0) return 'Crystal Report Parameter Name can not have any empty spaces';
      return true;
    },
  },
};
</script>
