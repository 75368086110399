import Api from './Api';

class LocationsApi extends Api {
  constructor() {
    super('locations', 'locations');
  }
}
const locationsApi = new LocationsApi();

export default locationsApi;
