import Api from './Api';

class RolesApi extends Api {
  constructor() {
    super('role', 'roles');
  }
}
const rolesApi = new RolesApi();

export default rolesApi;
