import Api from './Api';

class FinishingsApi extends Api {
  constructor() {
    super('finishing', 'finishings');
  }
}
const finishingsApi = new FinishingsApi();

export default finishingsApi;
