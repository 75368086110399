<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-select
          v-model="selectedLocationId"
          :items="locations"
          item-value="id"
          item-text="name"
        />
      </v-col>
      <v-spacer />
      <v-col
        class="text-end mt-4 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Shelf
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.location`]="{ item }">
              {{ item.location ? item.location.name : '' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEdit(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <shelf-form
      ref="shelfForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import ShelfForm from '@/components/forms/locations/ShelfForm';
import { mapState } from 'vuex';

export default {
  components: {
    ShelfForm,
  },
  data: () => ({
    items: [],
    locations: [],
    selectedLocationId: 0,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Location', value: 'location' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    loading: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
    selectedLocationId() {
      this.get();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.get();
      this.getLocations();
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          location_id: this.selectedLocationId,
        },
      };
      this.items = await this.$api.shelf.read(submitModel);
      this.loading = false;
    },

    async getLocations() {
      this.locations.push({ name: 'All', id: 0 });
      const res = await this.$api.location.get();
      this.locations.push(...res);
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.shelf.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.shelfForm.open();
    },

    openEdit(id) {
      this.$refs.shelfForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },
  },
};
</script>
