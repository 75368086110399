import Api from './Api';

class UomsApi extends Api {
  constructor() {
    super('uom', 'uoms');
  }
}
const uomsApi = new UomsApi();

export default uomsApi;
