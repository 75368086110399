import Vue from 'vue';
import VueRouter from 'vue-router';
import InventoryQr from '@/views/InventoryQrs/InventoryQr.vue';
import InventoryQrItem from '@/views/InventoryQrs/InventoryQrItem.vue';
import InventoryMPQRItem from '@/views/InventoryQrs/InventoryMPQRItem.vue';
import PhysicalCountItem from '@/views/InventoryQrs/PhysicalCountItem';
import MovingItem from '@/views/Movements/MovingItem';
import Equipment from '@/views/Equipment';
import Inventory from '@/views/Inventory';
import EquipmentItem from '@/views/EquipmentItem';
import SupplyToLines from '@/views/supplyToLine/SupplyToLines';
import SupplyToLineSheetItem from '@/views/supplyToLine/SupplyToLineSheetItem';
import SupplyToPaintView from '@/views/SupplyToPaints/SupplyToPaintView';
import SupplyToPaintItemView from '@/views/SupplyToPaints/SupplyToPaintItemView';
import TruckScheduleSlotView from '@/views/TruckSchedules/TruckScheduleSlotView';
import TruckScheduleRequestView from '@/views/TruckSchedules/TruckScheduleRequestView';
import ProductionScheduleView from '@/views/ProductionSchedules/ProductionScheduleView';
import ProductionScheduleCalendarView from '@/views/ProductionSchedules/ProductionScheduleCalendarView';
import ReportView from '@/views/Reports/ReportView';
import DashboardView from '@/views/DashboardView';
import ReportItem from '@/views/Reports/ReportItem';
import Movement from '@/views/Movements/Movement';
import MovingBatchItem from '@/views/Movements/MovingBatchItem';
import ProjectView from '@/views/Projects/ProjectView';
import ScopeOfProjectView from '@/views/ScopeOfProjects/ScopeOfProjectView';
import TimeOffMainView from '@/views/TimeOffs/TimeOffMainView';
import JobsIndex from '../views/Jobs/Index';
import Clients from '../views/Clients';
import AdminIndex from '../views/Admin/Index';
import Login from '../views/Login';
import store from '../store';
import Alerts from '../views/Alerts';
import CncFiles from '../views/CncFiles';
import CutSheetView from '../views/CutSheets/CutSheetView';
import GlassPaintCodeView from '../views/Glasses/GlassPaintCodeView';
import GlassView from '../views/Glasses/GlassView';
import GlassPoView from '../views/Glasses/GlassPoView';
import GlassItems from '../views/Glasses/GlassItems';
import GlassBatchView from '../views/Glasses/GlassBatchView';
import InventoryPoView from '../views/Inventory/InventoryPoView';
import PartPickListView from '../views/PartPickLists/PartPickListView';
import GlassBatchQrView from '../views/Glasses/GlassBatchQrView';
import GlassPackagingSlipView from '../views/Glasses/GlassPackagingSlipView';
import GlassPackagingSlipQrView from '../views/Glasses/GlassPackagingSlipQrView';
import AccountingView from '../views/Accountings/AccountingView';
import InvoiceView from '../views/Invoices/InvoiceView';
import GlassMoveQr from '../views/Glasses/GlassMoveQr';
import SupplyToLineQrView from '../views/supplyToLine/SupplyToLineQrView';
import SupplyToLineQrItemView from '../views/supplyToLine/SupplyToLineQrItemView';
import BillOfLadingView from '../views/BillOfLadings/BillOfLadingView';
import BillOfLadingQrView from '../views/BillOfLadings/BillOfLadingQrView';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: (to) => {
      const user = store.state.user || {};
      const userRoleIdTimeOffUser = 27;
      const userRoles = user.roles ? user.roles.map((x) => x.id) : [];
      const isTimeOffUser = userRoles.includes(userRoleIdTimeOffUser);

      const isMobile = window.innerWidth <= 768 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      if (user && isTimeOffUser && isMobile) {
        return '/time-offs';
      }
      return '/jobs';
    },
  },

  {
    path: '/clients',
    name: 'clients',
    component: Clients,
    meta: {
      title: store.getters.getAdminModuleValue('Suppliers') ? 'Clients / Suppliers' : 'Clients',
      moduleName: 'Clients',
      authorize: ['Admin', 'Associate', 'Read', 'Read-Write'],
    },
  },

  {
    path: '/jobs',
    name: 'jobs',
    component: JobsIndex,
    meta: {
      title: 'Jobs',
      moduleName: 'Jobs',
      authorize: ['Admin', 'Associate', 'Read', 'Read-Write'],
    },
  },

  /// //////RM Supply / Return
  {
    path: '/consumptions',
    name: 'SupplyToLines',
    component: SupplyToLines,
    meta: {
      title: 'RM Supply / Return',
      moduleName: 'RM Supply / Return',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
  },

  {
    path: '/consumptions/:id',
    name: 'SupplyToLineSheetItem',
    component: SupplyToLineSheetItem,
    meta: {
      title: 'RM Supply / Return',
      moduleName: 'RM Supply / Return',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
  },

  // Supply To Paint
  {
    path: '/supply-to-paints',
    name: 'SupplyToPaints',
    component: SupplyToPaintView,
    meta: {
      title: 'Supply To Paint',
      moduleName: 'Supply To Paint',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
  },

  {
    path: '/supply-to-paints/:id',
    name: 'SupplyToLineSheetItem',
    component: SupplyToPaintItemView,
    meta: {
      title: 'Supply To Paint',
      moduleName: 'Supply To Paint',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
  },

  {
    path: '/stl-qr',
    name: 'SupplyToLineQrView',
    component: SupplyToLineQrView,
    meta: {
      title: 'STL QR',
      moduleName: 'STL QR',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
  },

  {
    path: '/stl-qr/:code',
    name: 'SupplyToLineQrItemView',
    component: SupplyToLineQrItemView,
    meta: {
      title: 'STL QR',
      moduleName: 'STL QR',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
  },

  /// //////Inventory
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    meta: {
      title: 'Inventory',
      moduleName: 'Inventory',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
    props: true,
  },

  {
    path: '/inventory-po',
    name: 'InventoryPoView',
    component: InventoryPoView,
    meta: {
      title: 'Inventory PO',
      moduleName: 'Inventory Po',
      authorize: ['Admin', 'Purchaser'],
    },
  },

  /// //////QR Elements
  {
    path: '/inventory-qr',
    name: 'inventory-qr',
    component: InventoryQr,
    props: { qrType: 'inventory-qr' },
    meta: {
      title: 'Inventory QR',
      moduleName: 'Inventory QR',
      authorize: ['Admin', 'Associate', 'Material Handler', 'Paint Handler'],
    },
  },
  {
    path: '/inventory-qr/:code',
    name: 'inventory-qr-item',
    component: InventoryQrItem,
    meta: {
      title: 'Inventory QR',
      moduleName: 'Inventory QR',
      authorize: ['Admin', 'Associate', 'Material Handler'],
    },
  },

  {
    path: '/inventory-mpqr/:code',
    name: 'inventory-mpqr-item',
    component: InventoryMPQRItem,
    meta: {
      title: 'Inventory MPQR',
      moduleName: 'Inventory QR',
      authorize: ['Admin', 'Associate', 'Material Handler', 'Paint Handler'],
    },
  },

  {
    path: '/physical-count-qr',
    name: 'physical-count-qr',
    component: InventoryQr,
    props: () => (
      { qrType: 'physical-count-qr' }
    ),
    meta: {
      title: 'Physical Count QR',
      moduleName: 'Physical Count QR',
      authorize: ['Admin', 'Associate', 'Physical Count Handler'],
    },
  },

  {
    path: '/physical-count-qr/:code',
    name: 'physical-count-qr',
    component: PhysicalCountItem,
    meta: {
      title: 'Physical Count QR',
      moduleName: 'Physical Count QR',
      authorize: ['Admin', 'Associate', 'Physical Count Handler'],
    },
  },

  {
    path: '/rm-receiving-qr',
    name: 'rm-receiving',
    component: InventoryQr,
    props: () => (
      { qrType: 'rm-receiving-qr' }
    ),
    meta: {
      title: 'RM Receiving',
      moduleName: 'RM Receiving',
      authorize: ['Admin', 'Associate', 'RM Receiving Handler', 'Paint Handler'],
    },
  },

  {
    path: '/moving-qr',
    name: 'moving',
    component: Movement,
    props: () => (
      { qrType: 'moving-qr' }
    ),
    meta: {
      title: 'Moving',
      moduleName: 'Moving',
      authorize: ['Admin', 'Associate', 'Moving Handler'],
    },
  },

  {
    path: '/moving-qr/:code',
    name: 'moving',
    component: MovingItem,
    props: () => (
      { qrType: 'moving-qr' }
    ),
    meta: {
      title: 'Moving',
      moduleName: 'Moving',
      authorize: ['Admin', 'Associate', 'Moving Handler'],
    },
  },

  {
    path: '/moving-qr/mpqr/:code',
    name: 'inventory-mpqr-item',
    component: InventoryMPQRItem,
    props: () => (
      { qrType: 'moving-qr' }
    ),
    meta: {
      title: 'Inventory MPQR',
      moduleName: 'Inventory QR',
      authorize: ['Admin', 'Associate', 'Material Handler', 'Paint Handler'],
    },
  },

  {
    path: '/moving-qr-batch/:id',
    name: 'moving',
    component: MovingBatchItem,
    meta: {
      title: 'Moving',
      moduleName: 'Moving',
      authorize: ['Admin', 'Associate', 'Moving Handler'],
    },
  },

  /// ///////Reports

  {
    path: '/reports',
    name: 'ReportView',
    component: ReportView,
    meta: {
      title: 'Reports',
      moduleName: 'Reports',
      authorize: ['Admin', 'Associate', 'Moving Handler', 'RM Receiving Handler', 'Physical Count Handler', 'Material Handler'],
    },
  },

  {
    path: '/reports/:id',
    name: 'ReportItem',
    component: ReportItem,
    meta: {
      title: 'Reports',
      moduleName: 'Reports',
      authorize: ['Admin', 'Associate', 'Moving Handler', 'RM Receiving Handler', 'Physical Count Handler', 'Material Handler'],
    },
  },

  {
    path: '/dashboards',
    name: 'DashboardView',
    component: DashboardView,
    meta: {
      title: 'Dashboard',
      moduleName: 'Dashboard',
      authorize: ['Admin', 'Associate'],
    },
  },

  {
    path: '/alerts',
    name: 'Alerts',
    component: Alerts,
    meta: {
      title: 'Alerts',
      moduleName: 'Alerts',
      authorize: ['Admin', 'Associate'],
    },
  },

  {
    path: '/cnc-files',
    name: 'CncFiles',
    component: CncFiles,
    meta: {
      title: 'CNC Files',
      moduleName: 'CNC Files',
      authorize: ['Admin', 'Associate', 'Moving Handler', 'RM Receiving Handler', 'Physical Count Handler', 'Material Handler'],
    },
  },
  {
    path: '/cut-sheets',
    name: 'CutSheetView',
    component: CutSheetView,
    meta: {
      title: 'Cut Sheets',
      moduleName: 'Cut Sheets',
      authorize: ['Admin', 'Associate', 'Moving Handler', 'RM Receiving Handler', 'Physical Count Handler', 'Material Handler'],
    },
  },
  // GLASS
  {
    path: '/glass',
    name: 'GlassPaintCodeView',
    component: GlassPaintCodeView,
    props: true,
    meta: {
      title: 'Glass',
      moduleName: 'Glass',
      authorize: ['Admin', 'Associate', 'Glass Handler'],
    },
  },
  {
    path: '/glass/jobs',
    name: 'Glass',
    component: GlassView,
    meta: {
      title: 'Glass Jobs',
      moduleName: 'Glass',
      authorize: ['Admin', 'Associate', 'Glass Handler'],
    },
  },
  {
    path: '/glass/po-items',
    name: 'GlassPoView',
    component: GlassPoView,
    props: true,
    meta: {
      title: 'Glass Purchase Orders',
      moduleName: 'Glass',
      authorize: ['Admin', 'Associate', 'Glass Handler'],
    },
  },
  {
    path: '/glass/glass-items',
    name: 'GlassItems',
    component: GlassItems,
    props: true,
    meta: {
      title: 'Glass Items',
      moduleName: 'Glass',
      authorize: ['Admin', 'Associate', 'Glass Handler'],
    },
  },
  {
    path: '/glass/batches',
    name: 'GlassBatchView',
    component: GlassBatchView,
    props: true,
    meta: {
      title: 'Glass Batches',
      moduleName: 'Glass',
      authorize: ['Admin', 'Associate', 'Glass Handler'],
    },
  },
  {
    path: '/glass/batch-qr',
    name: 'GlassBatchQrView',
    component: GlassBatchQrView,
    props: true,
    meta: {
      title: 'Batch QR',
      moduleName: 'Batch Qr',
      authorize: ['Admin', 'Associate', 'Material Handler', 'Glass Handler', 'Glass Supervisor'],
    },
  },
  {
    path: '/glass/glass-move-qr',
    name: 'GlassBatchQrView',
    component: GlassMoveQr,
    props: true,
    meta: {
      title: 'Glass Move / Rework QR',
      moduleName: 'Glass Move QR',
      authorize: ['Admin', 'Associate', 'Material Handler', 'Glass Handler', 'Glass Supervisor'],
    },
  },
  {
    path: '/glass/packaging-slips',
    name: 'GlassPackagingSlipView',
    component: GlassPackagingSlipView,
    props: true,
    meta: {
      title: 'Packing Slips',
      moduleName: 'Packaging Slip',
      authorize: ['Admin', 'Associate', 'Glass Handler'],
    },
  },
  {
    path: '/glass/packaging-slip-qrs',
    name: 'GlassPackagingSlipQrView',
    component: GlassPackagingSlipQrView,
    props: true,
    meta: {
      title: 'Packing Slip QR',
      moduleName: 'Packaging Slip QR',
      authorize: ['Admin', 'Associate', 'Glass Handler'],
    },
  },

  /// // Truck Schedule
  {
    path: '/truck-schedules/slots',
    name: 'TruckScheduleSlotView',
    component: TruckScheduleSlotView,
    meta: {
      title: 'Truck Schedule Slots',
      moduleName: 'Truck Schedule',
      authorize: ['Admin', 'Associate', 'Approver', 'Shipper', 'Requester', 'Installer'],
    },
  },
  {
    path: '/truck-schedules/requests',
    name: 'TruckScheduleRequestView',
    component: TruckScheduleRequestView,
    props: true,
    meta: {
      title: 'Truck Schedule Requests',
      moduleName: 'Truck Schedule',
      authorize: ['Admin', 'Associate', 'Approver', 'Requester', 'Shipper', 'Installer'],
    },
  },
  {
    path: '/truck-schedules/bill-of-lading',
    name: 'TruckScheduleBillOfLadingView',
    component: BillOfLadingView,
    meta: {
      title: 'Bill Of Lading',
      moduleName: 'Truck Schedule',
      authorize: ['Admin', 'Shipper', 'Production Admin'],
    },
  },
  {
    path: '/truck-schedules/bill-of-lading-qrs',
    name: 'TruckScheduleBillOfLadingQrView',
    component: BillOfLadingQrView,
    meta: {
      title: 'Bill Of Lading QR',
      moduleName: 'Truck Schedule',
      authorize: ['Admin', 'Shipper', 'Production Admin'],
    },
  },

  /// /////Production Schedule
  {
    path: '/production-schedules/:status',
    name: 'ProductionScheduleView',
    component: ProductionScheduleView,
    meta: {
      title: 'Production Schedule',
      moduleName: 'Production Schedule',
      authorize: ['Admin', 'Associate', 'Glass Handler', 'Production Supervisor', 'Read', 'Spandrel Paint Supervisor', 'Production Admin', 'Glass Handler'],
    },
  },
  {
    path: '/production-schedule-calendar',
    name: 'ProductionScheduleCalendarView',
    component: ProductionScheduleCalendarView,
    meta: {
      title: 'Production Schedule Calendar',
      moduleName: 'Production Schedule',
      authorize: ['Admin', 'Associate', 'Glass Handler', 'Production Supervisor', 'Read', 'Spandrel Paint Supervisor', 'Production Admin', 'Glass Handler'],
    },
  },

  /// ///////Equipment
  {
    path: '/equipment',
    name: 'equipment',
    component: Equipment,
    meta: {
      title: 'Equipment',
      moduleName: 'Equipment',
      authorize: ['Admin', 'Associate', 'Equipment Handler', 'Read', 'Read-Write'],
    },
  },
  {
    path: '/equipment/:id',
    name: 'equipmentItem',
    component: EquipmentItem,
    meta: {
      title: 'Equipment',
      moduleName: 'Equipment',
      authorize: ['Admin', 'Associate', 'Equipment Handler', 'Read', 'Read-Write'],
    },
  },

  {
    path: '/projects',
    name: 'ProjectView',
    component: ProjectView,
    meta: {
      title: 'Projects',
      moduleName: 'Projects',
      authorize: ['Admin', 'Associate', 'Estimator', 'Project Manager', 'Read'],
    },
  },

  // Time Offs
  {
    path: '/time-offs',
    name: 'TimeOffMainView',
    component: TimeOffMainView,
    meta: {
      title: 'Time Off',
      moduleName: 'Time Off',
      authorize: ['Admin', 'Associate', 'Accountant', 'HR', 'Time-Off User', 'Time-Off Supervisor'],
    },
  },

  {
    path: '/accounting/orders',
    name: 'AccountingView',
    component: AccountingView,
    meta: {
      title: 'Accounting',
      moduleName: 'Accounting',
      authorize: ['Accountant'],
    },
  },

  {
    path: '/accounting/invoices',
    name: 'InvoiceView',
    component: InvoiceView,
    meta: {
      title: 'Invoices',
      moduleName: 'Invoices',
      authorize: ['Accountant'],
    },
  },

  /// //Scope of Project
  {
    path: '/scope-of-projects',
    name: 'ScopeOfProjectView',
    component: ScopeOfProjectView,
    meta: {
      title: 'Projects Scope',
      moduleName: 'Projects',
      authorize: ['Admin', 'Associate', 'Estimator', 'Project Manager', 'Read'],
    },
  },
  {
    path: '/equipment/:id',
    name: 'equipmentItem',
    component: EquipmentItem,
    meta: {
      title: 'Equipment',
      moduleName: 'Equipment',
      authorize: ['Admin', 'Associate', 'Equipment Handler', 'Read', 'Read-Write'],
    },
  },

  {
    path: '/part-pick-lists',
    name: 'PartPickListView',
    component: PartPickListView,
    meta: {
      title: 'Part Pick List',
      moduleName: 'Part Pick List',
      authorize: ['Admin', 'Associate', 'Picker', 'Requester'],
    },
  },

  {
    path: '/admin',
    name: 'admin',
    component: AdminIndex,
    meta: {
      title: 'Admin',
      moduleName: 'Admin',
      authorize: ['Admin'],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize, moduleName } = to.meta;
  if (to.matched.some((record) => record.meta.requiresAuth === false)) {
    next();
  } else {
    if (!authorize || !moduleName) return;
    if (store.getters.isLoggedIn) {
      const userPermission = store.getters.checkUserPermission(authorize);
      // Admin section is not in admin modules
      const moduleValue = moduleName === 'Admin' ? true : store.getters.getAdminModuleValue(moduleName);
      if (!userPermission || !moduleValue) return;

      store.commit('storeUserState', `/${to.name}`);

      next();
      return;
    }

    next('/login');
  }
});

export default router;
