import axios from 'axios';
import Api from './Api';

class RecordsApi extends Api {
  constructor() {
    super('rmc_consumption_record', 'rmc_consumption_records');
  }

  async delete(recordId) {
    let list = [];
    const errorText = 'Could not delete';

    try {
      list = await axios.post(`/rmc_consumption_records/${recordId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async submitFromMovement(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post(`/${this.endpoint}/moving`, data);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }
}
const recordsApi = new RecordsApi();

export default recordsApi;
