var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"fullScreen":"","is-submit-btn":false,"persistent":""}},[_c('template',{slot:"header"},[_c('span',[_vm._v("Returned Windows")])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"group-by":"finished_good.production_schedule.job.project.name","hide-default-footer":"","items-per-page":-1,"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(group)+" ")],1)]}},_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [(h.fullText)?_c('v-tooltip',{key:h.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.fullText))])]):_c('span',{key:h.value},[_vm._v(" "+_vm._s(h.text)+" ")])]}}}),{key:"item.finished_good.tag_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item && item.finished_good && item.finished_good.tag_id ? item.finished_good.tag_id : '-')+" ")])]}},{key:"item.serial_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.finished_good && item.finished_good.tag_id ? _vm.extractSerialNumber(item) : '-')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item && item.status)?_c('v-chip',{attrs:{"small":"","color":item.status.color ? item.status.color : '#000000',"text-color":"white"}},[_vm._v(" "+_vm._s(item.status.name)+" ")]):_vm._e()]}},{key:"item.finished_good.sqft",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.finished_good && item.finished_good.sqft ? ((item.finished_good.sqft.toFixed(2)) + " sqft") : '-')+" ")]}},{key:"item.finished_good.width",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.finished_good && item.finished_good.width ? ("" + (_vm.displayInchesWithFraction(item.finished_good.width))) : '-')+" ")]}},{key:"item.finished_good.height",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.finished_good && item.finished_good.height ? ("" + (_vm.displayInchesWithFraction(item.finished_good.height))) : '-')+" ")]}},{key:"item.finished_good.weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.finished_good ? ("" + (parseFloat(_vm.getWeight(item)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }))) : '-')+" ")]}}],null,true)})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }