<template>
  <v-container
    fluid
  >
    <v-row class="align-center">
      <v-col class="pt-0">
        <v-row class="align-center">
          <v-col
            cols="auto"
            class="pr-0"
          >
            <v-btn
              outlined
              color="primary"
              class="mr-4"
              :disabled="currentYear === new Date().getFullYear()"
              @click="setToday"
            >
              Current year
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <h3 class="mb-0">
              {{ currentYear }}
            </h3>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="text-end pt-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new Holiday
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="itemsTableFooterProps"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            sort-by="date"
            dense
            fixed-header
            :height="windowHeight"
          >
            <template #[`item.name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.date`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.date_full }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                v-if="item.is_enabled"
                small
                color="success"
                text-color="white"
              >
                ACTIVE
              </v-chip>
              <v-chip
                v-else
                small
                color="error"
                text-color="white"
              >
                DISABLED
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="orange"
                outlined
                small
                @click="changeUserStatus(item)"
              >
                {{ item.is_enabled ? "DISABLE" : "ENABLE" }}
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEdit(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1 mr-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <TimeOffHolidayForm
      ref="itemForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TimeOffHolidayForm from '@/components/forms/timeOffs/TimeOffHolidayForm';

export default {
  components: {
    TimeOffHolidayForm,
  },
  data: () => ({
    loading: true,
    items: [],
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    topSpaceHeight: 290,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    currentYear: 0,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    headers() {
      const returnHeaders = [];

      returnHeaders.push({ text: 'Name', value: 'name' });
      returnHeaders.push({ text: 'Date', value: 'date' });
      returnHeaders.push({ text: 'Status', value: 'status', align: 'center' });
      returnHeaders.push({
        text: 'Actions',
        align: 'end',
        value: 'actions',
      });

      return returnHeaders;
    },
  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
  },
  created() {
    this.currentYear = new Date().getFullYear();
    this.init();
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {

    async init() {
      this.get();
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          year: this.currentYear,
        },
      };
      this.items = await this.$api.timeOffHoliday.read(submitModel);
      this.loading = false;
    },

    open() {
      this.$refs.itemForm.open();
    },

    openEdit(id) {
      this.$refs.itemForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.timeOffHoliday.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async changeUserStatus(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${item.is_enabled ? 'DISABLE' : 'ENABLE'} this Holiday?`,
      });
      if (!confirm) return;
      const res = await this.$api.timeOffHoliday.get(`toggle/${item.id}`);
      if (res) {
        this.$bus.$emit('showSuccess');
        this.get();
      } else {
        this.$bus.$emit('showError');
      }
    },

    prev() {
      // eslint-disable-next-line no-plusplus
      this.currentYear--;
      this.get();
    },
    next() {
      // eslint-disable-next-line no-plusplus
      this.currentYear++;
      this.get();
    },
    setToday() {
      this.currentYear = new Date().getFullYear();
      this.get();
    },
  },
};
</script>
<style scoped></style>
