var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"900px","persistent":true},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-alert',{attrs:{"type":"error"},model:{value:(_vm.errorAlert),callback:function ($$v) {_vm.errorAlert=$$v},expression:"errorAlert"}},[_vm._v(" "+_vm._s(_vm.errorAlertText)+" ")]),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('p',[_vm._v("Sheet Date")]),_c('v-date-picker',{attrs:{"full-width":""},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('inventory-class-select-element',{attrs:{"is-add-all":false,"is-required":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}}),_c('v-autocomplete',{attrs:{"items":_vm.jobs,"item-text":function (item) { return ((item.code) + " - " + (item.name) + "; " + (item.reference)); },"item-value":"id","rules":[function (v) { return !!v || 'Job is required'; }],"required":"","loading":_vm.loadingJob},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openJobForm()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]},proxy:true}]),model:{value:(_vm.selectedJobId),callback:function ($$v) {_vm.selectedJobId=$$v},expression:"selectedJobId"}},[_c('template',{slot:"label"},[_vm._v(" Job"),_c('RedAsterisk')],1)],2),(_vm.inventoryClassId !== _vm.inventoryClassIdVWDGlass && _vm.inventoryClassId !== _vm.inventoryClassIdGlass)?_c('v-autocomplete',{attrs:{"items":_vm.colors,"item-text":"code","item-value":"id","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}}],null,false,2966476124),model:{value:(_vm.ex_color),callback:function ($$v) {_vm.ex_color=$$v},expression:"ex_color"}},[_c('template',{slot:"label"},[_vm._v(" External colour ")])],2):_vm._e(),(_vm.inventoryClassId !== _vm.inventoryClassIdVWDGlass && _vm.inventoryClassId !== _vm.inventoryClassIdGlass)?_c('v-autocomplete',{attrs:{"items":_vm.colors,"item-text":"code","item-value":"id","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}}],null,false,2966476124),model:{value:(_vm.in_color),callback:function ($$v) {_vm.in_color=$$v},expression:"in_color"}},[_c('template',{slot:"label"},[_vm._v(" Internal colour ")])],2):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"normal"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.btnSubmitName)+" ")])],1)],1)],1)],1),_c('job-form',{ref:"jobForm",attrs:{"is-supervisor":false,"is-client":false,"is-work-order":false,"is-address":false,"is-job-name":false,"is-reference":true},on:{"success":_vm.jobSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }