<template>
  <v-select
    v-if="businessRulesGetBoolean('Inventory Classes')"
    v-model="inventoryClassId"
    :items="inventoryClasses"
    item-text="name"
    item-value="id"
    :disabled="disabled"
    :readonly="readonly"
    :rules="[v => !isRequired || (Array.isArray(v) ? (v.length > 0 || 'Class is required') : (!!v || 'Class is required'))]"
    :hide-details="hideDetails"
    :outlined="outlined"
    :loading="loading"
    :multiple="multiple"
    @change="onChange"
  >
    <template #label>
      <span v-if="isLabel">Class<RedAsterisk v-if="isRequired" /></span>
    </template>
    <template #item="{ item }">
      <v-chip
        class="mt-1 mb-1"
        small
        text-color="white"
        style="cursor: pointer; text-transform: uppercase"
        :color="item ? item.color : 'white'"
      >
        {{ item ? item.name : '' }}
      </v-chip>
    </template>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <template #selection="{ item }">
      <v-chip
        class="mt-1 mb-1"
        small
        text-color="white"
        style="cursor: pointer; text-transform: uppercase"
        :color="item ? item.color : 'white'"
      >
        {{ item ? item.name : '' }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    value: {
      type: [Array, Number],
      default: null,
    },
    isAddAll: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    is4500: {
      type: Boolean,
      default: true,
    },
    is4800: {
      type: Boolean,
      default: true,
    },
    isInst: {
      type: Boolean,
      default: true,
    },
    isRailings: {
      type: Boolean,
      default: true,
    },
    isGlass: {
      type: Boolean,
      default: true,
    },
    isCommercial: {
      type: Boolean,
      default: true,
    },
    isVwdGlass: {
      type: Boolean,
      default: true,
    },
    isPanels: {
      type: Boolean,
      default: true,
    },
    isSid: {
      type: Boolean,
      default: true,
    },
    isRailPart: {
      type: Boolean,
      default: true,
    },
    isParts: {
      type: Boolean,
      default: true,
    },
    isPaint: {
      type: Boolean,
      default: true,
    },
    isEquipment: {
      type: Boolean,
      default: true,
    },
    isPatioDoor: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
    isState: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoSelectFirst: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    inventoryClasses: [],
    inventoryClassId: null,
    classAllId: 0,
    class4500Id: 1,
    class4800Id: 2,
    classRailingsId: 3,
    classInstId: 4,
    classGlassId: 5,
    classCommercialId: 6,
    VWDGlassId: 7,
    panelsId: 8,
    sidId: 9,
    railPartId: 10,
    partsId: 11,
    paintId: 12,
    equipmentId: 13,
    patioDoorId: 14,
    loading: false,
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      user: 'user',
    }),
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v !== null && this.isState) {
          this.$store.commit('storeInventoryClass', v);
        }
        this.inventoryClassId = v;
      },
    },
    inventoryClassId() {
      this.$emit('input', this.inventoryClassId);
    },
    is4500() {
      this.getInvClasses();
    },
    is4800() {
      this.getInvClasses();
    },
    isInst() {
      this.getInvClasses();
    },
    isRailings() {
      this.getInvClasses();
    },
    isGlass() {
      this.getInvClasses();
    },
    isCommercial() {
      this.getInvClasses();
    },
    isVwdGlass() {
      this.getInvClasses();
    },
    isPanels() {
      this.getInvClasses();
    },
    isSid() {
      this.getInvClasses();
    },
    isRailPart() {
      this.getInvClasses();
    },
    isParts() {
      this.getInvClasses();
    },
    isPaint() {
      this.getInvClasses();
    },
    isEquipment() {
      this.getInvClasses();
    },
    isPatioDoor() {
      this.getInvClasses();
    },
  },
  created() {
    this.getInvClasses();
  },
  methods: {
    async getInvClasses() {
      try {
        this.loading = true;

        this.inventoryClasses = [];

        const inventoryClasses = this.$store.state.invClasses;

        if (!inventoryClasses) {
          this.loading = false;
          return;
        }

        const filteredClasses = inventoryClasses.filter((x) => (this.isAddAll && x.id === this.classAllId)
        || (this.is4500 && x.id === this.class4500Id)
        || (this.is4800 && x.id === this.class4800Id)
        || (this.isInst && x.id === this.classInstId)
        || (this.isRailings && x.id === this.classRailingsId)
        || (this.isGlass && x.id === this.classGlassId)
        || (this.isCommercial && x.id === this.classCommercialId)
        || (this.isVwdGlass && x.id === this.VWDGlassId)
        || (this.isPanels && x.id === this.panelsId)
        || (this.isSid && x.id === this.sidId)
        || (this.isRailPart && x.id === this.railPartId)
        || (this.isParts && x.id === this.partsId)
        || (this.isPaint && x.id === this.paintId)
        || (this.isEquipment && x.id === this.equipmentId)
        || (this.isPatioDoor && x.id === this.patioDoorId));

        this.inventoryClasses.push(...filteredClasses);

        if (this.isState && filteredClasses.some((el) => el.id === this.$store.state.inventoryClassId)) {
          this.inventoryClassId = this.$store.state.inventoryClassId;
        } else if (this.inventoryClassId === null && filteredClasses.length > 0 && this.autoSelectFirst) {
          this.inventoryClassId = filteredClasses[0].id;
        }

        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    onChange(value) {
      this.$emit("on-change", value);
    },
  },
};
</script>
