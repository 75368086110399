<template>
  <v-container
    v-if="!isMobile"
    fluid
  >
    <the-dialog-box
      v-if="(roles.includes(userRoleIdHrManager) || roles.includes(userRoleIdHR)) && !isAuthenticated"
      ref="dialogBox"
      :submit="timeOffAuthenticate"
      :submit-btn-text="'Submit'"
      :persistent="true"
      :btn-loading="loading"
    >
      <template slot="header">
        <span>Enter password</span>
      </template>
      <template slot="body">
        <v-row>
          <v-col
            class="pb-0"
          >
            <v-text-field
              v-model="passwordForm"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Password is required']"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
    <template v-else>
      <v-row>
        <v-col class="pb-0">
          <v-tabs
            v-model="selectedTab"
          >
            <v-tabs v-model="selectedTab">
              <v-tab
                v-for="(tab, index) in filteredTabs"
                :key="index"
                @click="tabChange(index)"
              >
                {{ tab }}
              </v-tab>
            </v-tabs>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row v-if="(roles.includes(userRoleIdHrManager) || roles.includes(userRoleIdHR)) && isAuthenticated">
        <v-col class="pt-0">
          <v-row v-show="selectedTab == 0">
            <v-col>
              <time-off-calendar-view />
            </v-col>
          </v-row>
          <v-row v-if="selectedTab == 1">
            <v-col>
              <time-off-request-view />
            </v-col>
          </v-row>
          <v-row v-if="selectedTab == 2">
            <v-col>
              <time-off-employee-view />
            </v-col>
          </v-row>
          <v-row v-if="selectedTab == 3">
            <v-col>
              <time-off-holiday-view />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-else-if="(roles.includes(userRoleIdTimeOffUser) || roles.includes(userRoleIdTimeOffSupervisor)) || roles.includes(userRoleIdAccountant) && !roles.includes(userRoleIdHR)">
        <v-col class="pt-0">
          <v-row v-show="selectedTab == 0">
            <v-col>
              <time-off-calendar-view />
            </v-col>
          </v-row>
          <v-row v-if="selectedTab == 1">
            <v-col>
              <time-off-request-view />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-container>
  <TimeOffRequestMobileView v-else-if="isMobile && roles.includes(userRoleIdTimeOffUser) && !roles.includes(userRoleIdTimeOffSupervisor) && !roles.includes(userRoleIdAccountant) && !roles.includes(userRoleIdHR) && !roles.includes(userRoleIdHrManager)" />
  <v-container v-else-if="isMobile && (roles.includes(userRoleIdTimeOffSupervisor) || roles.includes(userRoleIdAccountant) || roles.includes(userRoleIdHR) || roles.includes(userRoleIdHrManager))">
    <h2>Please use a desktop if you are Time Off Supervisor, Accountant, HR or HR Manager</h2>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TimeOffEmployeeView from '@/views/TimeOffs/TimeOffEmployeeView';
import TimeOffRequestView from '@/views/TimeOffs/TimeOffRequestView';
import TimeOffHolidayView from '@/views/TimeOffs/TimeOffHolidayView';
import TimeOffCalendarView from '@/views/TimeOffs/TimeOffCalendarView';
import TimeOffRequestMobileView from '@/views/TimeOffs/TimeOffRequestMobileView';

export default {
  components: {
    TimeOffEmployeeView,
    TimeOffRequestView,
    TimeOffHolidayView,
    TimeOffCalendarView,
    TimeOffRequestMobileView,
  },
  props: {

  },
  data: () => ({
    roles: [],
    tabs: ['calendar', 'requests', 'employees', 'holidays'],
    selectedTab: 0,
    userRoleIdAccountant: 24,
    userRoleIdHR: 25,
    userRoleIdTimeOffUser: 27,
    userRoleIdTimeOffSupervisor: 28,
    userRoleIdHrManager: 29,
    passwordForm: null,
    password: null,
    isAuthenticated: false,
    loading: false,
    showPassword: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
      search: (state) => state.search,
      timeOffState: (state) => state.timeOffState,
    }),
    ...mapGetters([

    ]),
    filteredTabs() {
      if (this.roles.includes(this.userRoleIdHrManager) || this.roles.includes(this.userRoleIdHR)) return this.tabs;
      if (this.roles.includes(this.userRoleIdTimeOffUser) || this.roles.includes(this.userRoleIdTimeOffSupervisor)) return ['calendar', 'requests'];
      return [];
    },
    isMobile() {
      if (window.innerWidth) {
        return window.innerWidth <= 768;
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    timeOffState: {
      handler(newVal) {
        this.selectedTab = newVal.tab;
      },
      deep: true,
    },
  },
  created() {
    this.init();
    this.roles = this.user.roles.map((x) => x.id);
    if (this.timeOffState) {
      if (this.timeOffState.tab) {
        this.selectedTab = this.timeOffState.tab;
      }
    }
  },
  mounted() {
    if (!this.passwordForm && this.$refs.dialogBox) {
      this.$refs.dialogBox.open();
    }
  },
  methods: {
    init() {

    },

    async timeOffAuthenticate() {
      this.loading = true;
      this.password = JSON.parse(JSON.stringify(this.passwordForm));
      const data = {
        password: this.password,
      };
      const res = await this.$api.timeOffAuthenticate.store(data);
      this.loading = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.$bus.$emit('showError', 'Password is incorrect. Please try again.');
        this.isAuthenticated = false;
        return;
      }
      this.isAuthenticated = true;
    },

    tabChange(newTab) {
      this.storeState('tab', newTab);
    },

    storeState(property, value) {
      const state = this.timeOffState;
      state[property] = value;
      this.$store.commit('storeTimeOffState', state);
    },
  },
};
</script>

<style scoped>

</style>
