import XLSX from 'xlsx';
import FileSaver from 'file-saver';

function s2ab(s) {
  const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
  const view = new Uint8Array(buf); // create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; // convert to octet
  return buf;
}

const ExcelHelper = {
  writeJson: (data, sheetName) => {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push(sheetName);
    const ws = XLSX.utils.json_to_sheet(data);
    wb.Sheets[sheetName] = ws;
    return wb;
  },
  saveExcelToBrowser: (wb, fileName) => {
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    FileSaver.saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${fileName}.xlsx`,
    );
  },
  excelWorkbookToExcel: async (wb) => new Promise((resolve) => {
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const file = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    resolve(file);
  }),
  excelToJson: async (file) => new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const row = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
      const jsonObj = JSON.stringify(row);

      resolve(JSON.parse(jsonObj));
    };

    reader.readAsBinaryString(file);
  }),
  excelToJsonNoHeader: async (file) => new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const row = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
        header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'], blankrows: false, raw: false,
      });

      resolve(row);
    };

    reader.readAsBinaryString(file);
  }),
  excelToCSV: async (file) => new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const row = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
      const jsonObj = JSON.stringify(row);

      resolve(JSON.parse(jsonObj));
    };

    reader.readAsBinaryString(file);
  }),

  excelToTxt: async (file) => new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const row = XLSX.utils.sheet_to_txt(workbook.Sheets[workbook.SheetNames[0]]);
      const jsonObj = JSON.stringify(row);

      resolve(JSON.parse(jsonObj));
    };

    reader.readAsBinaryString(file);
  }),
};

export default ExcelHelper;
