<template>
  <v-container>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :submit-btn-text="submitBtn"
      :max-width="700"
    >
      <template slot="header">
        <span>{{ title }} Template {{ recordId ? `#R${recordId}` : '' }}</span>
      </template>
      <template slot="body">
        <v-row>
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <v-text-field
              v-model="record.template_title"
              :rules="[(v) => !!v || 'Template Name is required']"
            >
              <template slot="label">
                Template Name<RedAsterisk />
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <v-text-field
              v-model="record.template_description"
            >
              <template slot="label">
                Template Description
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="6"
            class="pt-0 pb-0"
          >
            <v-autocomplete
              v-model="record.re_category_id"
              :items="categories"
              item-text="category_title"
              item-value="id"
              :rules="[(v) => !!v || 'Category is required']"
            >
              <template slot="label">
                Category<RedAsterisk />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="6"
            class="pt-0 pb-0"
          >
            <v-autocomplete
              v-model="record.user_ids"
              :items="users"
              item-text="full_name"
              item-value="id"
              multiple
            >
              <template slot="label">
                User
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="6"
            class="pt-0 pb-0"
          >
            <v-select
              v-model="record.data_base"
              :items="databases"
              item-text="name"
              item-value="name"
              :rules="[(v) => !!v || 'Database Name is required']"
            >
              <template slot="label">
                Database Name<RedAsterisk />
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="6"
            class="pt-0 pb-0"
          >
            <v-autocomplete
              v-model="record.file_type_ids"
              :items="fileTypes"
              item-text="name"
              item-value="id"
              multiple
              :rules="[(v) => !!v || 'Report File Type is required']"
            >
              <template slot="label">
                Generated Report File Types<RedAsterisk />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="10"
            class="pb-0 pt-0"
          >
            <v-file-input
              id="crystalFile"
              v-model="record.crystal_file"
              accept=".rpt"
              :rules="[(v) => !!recordId || (!!v || 'Crystal Report File is required')]"
            >
              <template slot="label">
                Crystal Report File<RedAsterisk v-if="!recordId" />
              </template>
            </v-file-input>
          </v-col>
          <v-col
            v-if="recordId"
            cols="2"
            class="mt-5"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  text
                  @click="download"
                  v-on="on"
                >
                  <v-icon
                    v-if="recordId"
                    color="primary"
                  >
                    fas fa-download
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ record.template_report_url }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <the-dual-list-box
              v-model="record.parameter_ids"
              :items="parameters"
              item-text="userFriendlyName"
              item-value="id"
              items-title="Parameters"
              selected-items-title="Selected Prameters"
            />
          </v-col>
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <span
              v-if="isParamsChanged"
              style="color: red;"
            >Important! Please make sure that uploaded Crystal Report has all the selected parameters!</span>
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver';
import TheDualListBox from '@/components/TheDualListBox';

export default {
  components: {
    TheDualListBox,
  },
  data: () => ({
    record: null,
    recordId: 0,
    recordDto: {
      id: 0,
      template_title: '',
      template_description: '',
      re_category_id: 0,
      crystal_file: null,
      parameter_ids: [],
      user_ids: [],
      file_type_ids: [],
    },
    categories: [],
    parameters: [],
    users: [],
    fileTypes: [],
    databases: [],
    title: '',
    submitBtn: '',
    isParamsChanged: false,
  }),
  watch: {
    // eslint-disable-next-line func-names
    'record.parameter_ids': function (newVal, oldVal) {
      if (this.recordId && newVal && oldVal && newVal !== oldVal) { this.isParamsChanged = true; } else this.isParamsChanged = false;
    },
  },
  created() {
    this.init();
  },
  methods: {

    init() {
      this.record = this.recordDto;
      this.getCategories();
      this.getParameters();
      this.getUsers();
      this.getFileTypes();
      this.getDatabases();
    },

    async get() {
      if (!this.recordId) return;
      const submitModel = {
        filters: {
          id: this.recordId,
        },
      };
      const res = await this.$api.reportTemplate.read(submitModel);
      if (!res || res.lengt < 1 || !res[0]) return;
      const firstIndex = 0;
      this.record = res[firstIndex];
    },

    async getCategories() {
      this.categories = await this.$api.reportCategory.get();
    },

    async getParameters() {
      this.parameters = await this.$api.reportParameter.get();
    },

    async getUsers() {
      const roleIdAdmin = 3;
      const submitModel = {
        filters: {
          except_role_id: roleIdAdmin,
        },
      };
      this.users = await this.$api.user.read(submitModel);
    },

    async getFileTypes() {
      this.fileTypes = await this.$api.reportFileType.get();
    },

    async getDatabases() {
      this.databases = await this.$api.reportDatabase.get();
    },

    async submit() {
      if (!this.validateFileType()) return false;
      if (!this.record) return false;

      const data = new FormData();

      if (this.record.id) {
        data.append('id', this.record.id);
      }

      if (this.record.template_title) {
        data.append('template_title', this.record.template_title);
      }

      data.append('template_description', this.record.template_description ? this.record.template_description : '');

      if (this.record.re_category_id) {
        data.append('re_category_id', this.record.re_category_id);
      }

      const userId = this.$store.getters.user.id;
      if (userId) {
        data.append('updated_by', userId);
      }

      if (this.record.user_ids) {
        data.append('user_ids', this.record.user_ids);
      }

      if (this.record.file_type_ids) {
        data.append('file_type_ids', this.record.file_type_ids);
      }

      if (this.record.data_base) {
        data.append('data_base', this.record.data_base);
      }

      if (this.record.parameter_ids) {
        const orderedParameters = this.record.parameter_ids.map((x, i) => ({ id: x, re_parameter_order: i }));
        data.append('parameter_ids', JSON.stringify(orderedParameters));
      }

      if (this.record.crystal_file) {
        data.append('crystal_file', this.record.crystal_file);
      }

      let res;
      if (this.recordId) {
        res = await this.$api.reportTemplate.update(data);
      } else {
        res = await this.$api.reportTemplate.store(data);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return true;
    },

    open(id) {
      this.recordId = id;
      if (id) {
        this.title = 'Update';
        this.submitBtn = 'UPDATE';
        this.get();
      } else {
        this.title = 'Create';
        this.submitBtn = 'CREATE';
        this.record = this.recordDto;
      }
      this.$refs.dialogBox.open();
    },

    validateFileType() {
      if (this.recordId) return true;
      const fileName = document.getElementById('crystalFile').value;
      const idxDot = fileName.lastIndexOf('.') + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile !== 'rpt') { this.$bus.$emit('showError', 'Only .rpt files are allowed!'); return false; }
      return true;
    },

    async download() {
      if (!this.recordId || !this.record || !this.record.template_report_url) return;
      const res = await this.$api.reportTemplate.download(this.recordId);
      const file = new Blob([res], {
        type: 'application/octet-stream',
      });
      saveAs(file, this.record.template_report_url);
    },
  },
};
</script>
