import Api from './Api';

class LengthsApi extends Api {
  constructor() {
    super('length', 'lengths');
  }
}
const lengthsApi = new LengthsApi();

export default lengthsApi;
