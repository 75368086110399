
export const isDecimalInFractions = (decimalNumber) => {
    const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
    }

    const decimalPart = decimalNumber - Math.floor(decimalNumber);

    if (decimalPart === 0) return true;

    // Check if the decimalPart exists in the keys of the fractions object
    return Object.keys(fractions).includes(decimalPart.toString());
}

export const isWholeOrHalf = (num) => {
    return /^(0(\.0|\.5)?|[1-9]\d*(\.0|\.5)?|\.5)$/.test(num);
}

export default {
    isDecimalInFractions,
    isWholeOrHalf
};