<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="650px"
      :persistent="true"
    >
      <v-card>
        <v-card-title>
          <span style="font-weight: bold; font-size: 24px;">Confirmation</span>
        </v-card-title>
        <v-card-text>
          <div style="font-size: 18px;">{{ title }}</div>
          <br/>
          <div style="font-size: 16px;" :class="`message-${confirmationType}`">
              {{ messagePrefix }}{{ message }}

          </div>
        </v-card-text>
        <v-card-actions class="pr-2">
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            large
            @click="cancel"
          >
            <span style="font-size: 18px;">{{ cancelBtn }}</span>
          </v-btn>
          <v-btn
            color="primary"
            large
            @click="confirm"
          >
            <span style="font-size: 18px;">{{ confirmBtn }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { mapState } from 'vuex';

export default {
  data: () => ({
    title: '',
    message: '',
    confirmationType: '',
    confirmBtn: 'Submit',
    cancelBtn: 'Cancel',
    dialog: false,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  computed: {
    messagePrefix() {
      switch (this.confirmationType) {
        case 'warning':
          return '*Warning: '
        case 'error':
          return '*Error: '
        default:
          return '';
      }
    },
  },
  mounted() {
    this.$bus.$on('confirm', this.open);
  },
  methods: {

    open(options = {}) {
      if (options.title) this.title = options.title;
      if (options.message) this.message = options.message;
      if (options.confirmationType) this.confirmationType = options.confirmationType;
      if (options.confirmBtn) this.confirmBtn = options.confirmBtn;
      if (options.cancelBtn) this.cancelBtn = options.cancelBtn;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.reset();
      this.dialog = false;
      this.resolvePromise(true);
    },

    cancel() {
      this.reset();
      this.dialog = false;
      this.resolvePromise(false);
    },

    reset() {
      this.title = '';
      this.message = '';
      this.confirmationType = '';
    }
  },
};
</script>
<style scoped>
.message-warning {
  color: orange; 
}
.message-error {
  color: red; 
}
</style>