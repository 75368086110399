<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="itemId ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ itemId ? 'Update' : 'Create' }} Batch</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="item.location_id"
            :items="locations"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Moving To Location is required']"
          >
            <template slot="label">
              Moving To Location<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    item: null,
    itemId: 0,
    itemDto: {
      id: 0,
      location_id: 0,
    },
    locations: [],
  }),
  created() {
    this.item = { ...this.itemDto };
    this.getLocations();
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.inventoryMovementBatch.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getLocations() {
      const locationIdOnTruck = 9;
      const res = await this.$api.location.get();
      // get only on truck location for now
      this.locations = res.filter((x) => x.id === locationIdOnTruck);
    },

    async submit() {
      if (!this.item) return false;
      const userId = this.$store.getters.user.id;
      if (userId) this.item.created_by = userId;
      const inventoryLocationIdTruckCargo = 963;
      this.item.inventory_location_id = inventoryLocationIdTruckCargo;

      let res;
      if (this.itemId) {
        res = await this.$api.inventoryMovementBatch.update(this.item);
      } else {
        res = await this.$api.inventoryMovementBatch.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id) {
      this.itemId = id;
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
