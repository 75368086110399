<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
        />
      </v-col>
      <v-col
        class="text-end mt-4 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="
            () => {
              $refs.itemForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new item
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="itemsHeaders"
            :items="items"
            :footer-props="itemsTableFooterProps"
            :items-per-page="100"
            :search="search"
            :loading="loading"
            dense
          >
            <template
              #[`item.inventory_class`]="{ item }"
            >
              <v-chip
                class="mt-0 mb-0"
                small
                :color="item && item.classes ? item.classes.color : 'black'"
                text-color="white"
              >
                {{ item && item.classes ? item.classes.name : '' }}
              </v-chip>
            </template>
            <template
              #[`item.photo`]="{ item }"
            >
              <a
                v-if="item.photo"
                @click="openItemShowImageForm(item.photo, item.mime_type, item.material_id)"
              > <v-icon
                small
                color="primary"
              >
                fa-image
              </v-icon> </a>
            </template>
            <template
              #[`item.material_id`]="{ item }"
            >
              <text-highlight :queries="search">
                {{ item ? item.material_id : '' }}
              </text-highlight>
            </template>
            <template #[`item.departments`]="{ item }">
              <v-tooltip
                v-if="item.departments && item.departments.length > 1"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    style="color: #1976d2;"
                    v-on="on"
                  >DEPARTMENTS</span>
                </template>
                <span
                  v-for="p in item.departments"
                  :key="p.id"
                >
                  {{ p.name && p.name !== '-'? `${p.name} ` : '' }} <br>
                </span>
              </v-tooltip>
              <span v-else> <span
                v-for="p in item.departments"
                :key="p.id"
              >
                <text-highlight :queries="search">{{ p.name && p.name !== '-' ? `${p.name} ` : '' }}</text-highlight>
              </span></span>
            </template>
            <template #[`item.name`]="{ item }">
              <text-highlight :queries="search">
                {{ item ? item.name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.category`]="{ item }">
              <text-highlight :queries="search">
                {{ item ? item.category : '-' }}
              </text-highlight>
            </template>
            <template #[`item.manufacturers`]="{ item }">
              <text-highlight :queries="search">
                {{ item.manufacturers ? item.manufacturers : '-' }}
              </text-highlight>
            </template>
            <template #[`item.uoms`]="{ item }">
              <v-tooltip
                v-if="item.uom_items && item.uom_items.length > 2"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    style="color: #1976d2;"
                    v-on="on"
                  >UOMS</span>
                </template>
                <span
                  v-for="p in item.uom_items"
                  :key="p.id"
                >
                  {{ p.name && p.name !== '-'? `${p.name} ` : '' }}<br>
                </span>
              </v-tooltip>
              <span v-else> <span
                v-for="p in item.uom_items"
                :key="p.id"
              >
                <text-highlight :queries="search">{{ p.name && p.name !== '-' ? `${p.name} ` : '' }}</text-highlight><br>
              </span></span>
              <!-- <text-highlight :queries="search">
                {{ item ? item.uoms : '-' }}
              </text-highlight> -->
            </template>
            <template #[`item.material_properties`]="{ item }">
              <text-highlight :queries="search">
                {{ item.material_properties ? item.material_properties : '-' }}
              </text-highlight>
            </template>
            <template #[`item.lengths`]="{ item }">
              <v-tooltip
                v-if="item.length_items && item.length_items.length > 2"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    style="color: #1976d2;"
                    v-on="on"
                  >LENGTHS</span>
                </template>
                <span
                  v-for="p in item.length_items"
                  :key="p.id"
                >
                  {{ p.name && p.name !== '-'? `${p.name} ` : '' }}<br>
                </span>
              </v-tooltip>
              <span v-else> <span
                v-for="p in item.length_items"
                :key="p.id"
              >
                <text-highlight :queries="search">{{ p.name && p.name !== '-' ? `${p.name} ` : '' }}</text-highlight>
              </span></span>
            </template>
            <template #[`item.colors`]="{ item }">
              <v-tooltip
                v-if="item.colors && item.colors.length > 2"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    style="color: #1976d2;"
                    v-on="on"
                  >COLORS</span>
                </template>
                <span
                  v-for="p in item.colors"
                  :key="p.id"
                >
                  {{ p.name && p.name !== '-'? `${p.name} ` : '' }} {{ p.code ? p.code : '' }}<br>
                </span>
              </v-tooltip>
              <span v-else> <span
                v-for="p in item.colors"
                :key="p.id"
              >
                <text-highlight :queries="search">{{ p.name && p.name !== '-' ? `${p.name} ` : '' }} {{ p.code ? p.code : '' }}</text-highlight><br>
              </span></span>
            </template>
            <template #[`item.finishings`]="{ item }">
              <text-highlight :queries="search">
                {{ item.finishings ? item.finishings : '-' }}
              </text-highlight>
            </template>
            <template #[`item.weight`]="{ item }">
              <text-highlight :queries="search">
                {{ item.weight ? `${item.weight} kg/m` : '' }}
              </text-highlight>
            </template>
            <template #[`item.price`]="{ item }">
              <text-highlight :queries="search">
                {{ item.price ? `$${item.price}` : '' }}
              </text-highlight>
            </template>
            <template #[`item.updated_at`]="{ item }">
              <text-highlight :queries="search">
                {{ item.updated_at }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openItemForm(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteItem(item)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <InventoryItemForm
      ref="itemForm"
      @item-creation-success="notifyItemCreationSuccess"
      @item-creation-fail="notifyItemCreationFail"
    />
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { mapGetters, mapState } from 'vuex';
import Bus from '../../bus';
import InventoryItemForm from '../../components/forms/InventoryItemForm';
import InventoryItemShowImageForm from '../../components/forms/InventoryItemShowImageForm';

export default {
  name: 'Items',
  components: {
    'text-highlight': TextHighlight,
    InventoryItemForm,
    InventoryItemShowImageForm,
  },
  filters: {},
  data: () => ({
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [100, 300, 500, -1],
    },
    items: [],
    inventoryClassId: 0,
    inventoryClassPartsId: 11,
    inventoryClassAllId: 0,
  }),
  computed: {
    itemsHeaders() {
      const headers = [];

      if (this.businessRulesGetBoolean('Inventory Classes')) headers.push({ text: 'Class', value: 'inventory_class', width: '5%' });

      const materialIdBRName = 'Material Id';
      const materialIdBRValue = this.businessRulesGetBoolean(materialIdBRName);
      if (materialIdBRValue != null && materialIdBRValue !== 0) {
        headers.push({
          text: 'Material Id', value: 'material_id', class: 'material_id', width: '5%',
        });
      }

      headers.push({ text: 'Name', value: 'name' });
      headers.push({ text: 'Colors', value: 'colors', width: '10%' });
      headers.push({ text: 'Lengths', value: 'lengths', width: '10%' });
      headers.push({ text: 'UOM', value: 'uoms', width: '5%' });
      headers.push({ text: 'Weight', value: 'weight', width: '5%' });
      if (this.businessRulesGetBoolean('Manufacturer')) headers.push({ text: 'Category', value: 'category' });

      if (this.inventoryClassId === this.inventoryClassPartsId || this.inventoryClassId === this.inventoryClassAllId) headers.push({ text: 'Price', value: 'price', width: '5%' });

      const manufacturerIdBRName = 'Manufacturer';
      const manufacturerIdBRValue = this.businessRulesGetBoolean(manufacturerIdBRName);
      if (manufacturerIdBRValue != null && manufacturerIdBRValue !== 0) headers.push({ text: 'Manufacturers', value: 'manufacturers' });

      const materialPropertyIdBRName = 'Material Property';
      const materialPropertyIdBRValue = this.businessRulesGetBoolean(materialPropertyIdBRName);
      if (materialPropertyIdBRValue != null && materialPropertyIdBRValue !== 0) headers.push({ text: 'Material Properties', value: 'material_properties' });

      const finishingIdBRName = 'Finishing';
      const finishingIdBRValue = this.businessRulesGetBoolean(finishingIdBRName);
      if (finishingIdBRValue != null && finishingIdBRValue !== 0) headers.push({ text: 'Finishings', value: 'finishings' });

      const itemImageBRName = 'Item Image';
      const itemImageBRValue = this.businessRulesGetBoolean(itemImageBRName);
      if (itemImageBRValue != null && itemImageBRValue !== 0) {
        headers.push({
          text: 'Img', value: 'photo', align: 'center', width: '5%',
        });
      }

      headers.push({ text: 'Updated At', value: 'updated_at' });
      if (this.inventoryClassId === this.inventoryClassPartsId || this.inventoryClassId === this.inventoryClassAllId) headers.push({ text: 'Departments', value: 'departments' });
      headers.push({
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      });

      return headers;
    },
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch:
  {
    inventoryClassId() {
      this.getInventoryItems();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getInventoryItems();
    },
    async getInventoryItems() {
      this.loading = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
        includes: { is_include_item_image: true },
      };
      const res = await this.$api.inventoryItem.readData(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.items = [];
        return;
      }
      this.items = res.data;
    },
    async deleteItem(item) {
      if (!confirm('Are you sure?')) {
        return;
      }

      const res = await this.$api.inventoryItem.softDelete(item.id);
      if (res.err) {
        if (res.errDesc === 'inventory_not_empty') {
          Bus.$emit(
            'alert',
            'error',
            'Error! Inventory for this item is not empty.',
          );
        }
      } else if (res === true) {
        Bus.$emit('alert', 'success', 'Success!');
        this.getInventoryItems();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    openItemForm(item) {
      this.$refs.itemForm.openDialog(item);
    },
    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    notifyItemCreationSuccess() {
      this.$bus.$emit('showSuccess');
      this.getInventoryItems();
    },
    notifyItemCreationFail() {
      this.$bus.$emit('showError');
    },
    openInventoryImageShowForm() {
      this.$refs.inventoryImageShowForm.openDialog();
    },

    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
  },
};
</script>
<style scoped>
.profile-photo{
  max-width: 100px;
}
</style>
