<template>
  <v-container
    class="pa-0"
    fluid
  >
    <form>
      <v-row>
        <v-col align="end">
          <v-btn
            color="primary"
            outlined
            @click="submitForm"
          >
            submit
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="org_name"
            label="Organization Name"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="url"
            label="Organization URL"
            readonly
            disabled
            @input="$v.url.$touch()"
            @blur="$v.url.$touch()"
          />
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col class="pb-0">
        <h4>Locations</h4>
      </v-col>
      <v-col class="text-end">
        <v-btn
          color="primary"
          outlined
          @click="openNewForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new location
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="locationsHeaders"
            :items="locations"
            :items-per-page="-1"
            dense
            :loading="loading"
          >
            <template #[`item.region`]="{ item }">
              {{ item.region ? item.region.name : '' }}
            </template>
            <template #[`item.country`]="{ item }">
              {{ item.country ? item.country.name : '' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn

                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditForm(item)"
              >
                EDIT
              </v-btn>
              <v-btn

                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteLocation(item)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <location-form
      ref="locationForm"
      :location="location"
      :dialog-title="locationFormTitle"
      @location-success="notifySuccess"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import LocationForm from '@/components/forms/locations/LocationForm';
import OrganizationsApi from '../../api/OrganizationsApi';

export default {
  name: 'Organization',
  components: {
    LocationForm,
  },
  data: () => ({
    // Page
    name: '',
    url: '',
    org_name: '',
    locations: [],
    locationsHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Code', value: 'code' },
      { text: 'Street Address', value: 'address' },
      { text: 'City', value: 'city' },
      { text: 'Province/State', value: 'region' },
      { text: 'Postal Code', value: 'postal_code' },
      { text: 'Country', value: 'country' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    location: null,
    locationFormTitle: 'New Location',
    loading: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchOrg();
      this.getlocations();
    },

    async fetchOrg() {
      this.org = await OrganizationsApi.getList();
      this.org_name = this.org[0].name;
      this.url = this.org[0].url;
    },
    async submitForm() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to update organization info?',
      });
      if (!confirm) return;
      const data = {
        id: 1,
        name: this.org_name,
        url: this.url,
      };

      const res = await OrganizationsApi.store(data);
      if (res) {
        this.$emit('profile-creation-success');
      } else {
        this.$emit('profile-creation-fail');
      }
      window.location.reload(true);
    },

    async getlocations() {
      this.loading = true;
      this.locations = await this.$api.location.getList();
      this.loading = false;
    },

    openNewForm() {
      this.location = null;
      this.locationFormTitle = 'New Location';
      this.$refs.locationForm.toggleForm();
    },

    openEditForm(item) {
      this.location = item;
      this.locationFormTitle = `Edit Location #${item.id}`;
      this.$refs.locationForm.toggleForm();
    },

    notifySuccess(msg) {
      this.$bus.$emit('showSuccess', msg);
      this.getlocations();
    },

    async deleteLocation(item) {
      const propVal = confirm(`Are you sure that you want to remove '${item.name}'?`);
      if (!propVal) return;

      const res = await this.$api.location.softDelete(item.id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      const msg = 'Location Deleted successfully!';
      this.$bus.$emit('showSuccess', msg);
      this.getlocations();
    },

  },
};
</script>
<style scoped></style>
