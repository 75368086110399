<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col>
        <v-data-table
          id="statuses-table"
          class="elevation-1"
          :headers="statusesHeaders"
          :items="statuses"
          :footer-props="statusesTableFooterProps"
          :items-per-page="statusesPerPage"
          :search="search"
          :loading="loading"
          calculate-widths
          dense
          :server-items-length="total"
          @pagination="updatePagination"
        >
          <template #item="{ item }">
            <tr>
              <td
                class="status-code"
              >
                <span style="font-weight: bold;"><TextHighlight :queries="search">
                  {{ item.code }}
                </TextHighlight></span>
              </td>
              <td
                class="status-category"
                style="padding: 0 5px"
              >
                <TextHighlight :queries="search">
                  {{ item.category }}
                </TextHighlight>
              </td>
              <td
                class="status-model"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on"><TextHighlight :queries="search">{{ item.model }}</TextHighlight></span>
                  </template>

                  <span>{{ item.manufacturer }}</span>
                </v-tooltip>
              </td>
              <td
                class="status-serial-number"
                style="padding: 0 5px"
              >
                <TextHighlight :queries="search">
                  {{ item.serial_number ? item.serial_number : '-' }}
                </TextHighlight>
              </td>
              <td
                class="status-user"
                style="padding: 0 5px"
              >
                <TextHighlight :queries="search">
                  {{ item.user_name }}
                </TextHighlight>
              </td>
              <td
                class="status-location"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <!-- <TextHighlight v-on="on" :queries="search">
                      {{ item.location_simple }}
                    </TextHighlight> -->
                    <span
                      :style="{color: item.locationColor}"
                      v-on="on"
                    ><TextHighlight :queries="search">{{ item.location_simple }}</TextHighlight></span>
                  </template>
                  <span>{{ item.location }}</span>
                </v-tooltip>
              </td>
              <td
                class="status-condition text-center"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span
                      :style="{ color: item.colour }"
                      v-on="on"
                    ><v-chip
                      small
                      :color="item && item.colour ? item.colour : '#BDBDBD'"
                      text-color="white"
                    >
                      {{ item && item.condition ? item.condition : '' }}
                    </v-chip></span>
                  </template>
                  <span>{{
                    item.description ? item.description : 'No description'
                  }}</span>
                </v-tooltip>
              </td>
              <td
                class="status-type text-center"
                style="padding: 0 5px"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span
                      :style="{ color: item.statusColour }"
                      v-on="on"
                    >
                      <v-chip
                        small
                        :color="item && item.statusColour ? item.statusColour : '#BDBDBD'"
                        text-color="white"
                      >
                        {{ item && item.status ? item.status : '' }}
                      </v-chip></span>
                  </template>
                  <span>{{
                    item.hours_spent
                      ? `${item.hours_spent} hours`
                      : 'No information'
                  }}</span>
                </v-tooltip>
              </td>
              <td
                class="status-date"
                style="padding: 0 5px"
              >
                <v-tooltip left>
                  <template #activator="{ on }">
                    <a
                      href="#"
                      v-on="on"
                      @click="() => openEquipmentInfo(item.equipment_item_id)"
                    >
                      {{ item.created_at_short }}
                    </a>
                  </template>
                  <span>{{ item.created_at_full }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <EquipmentItem ref="equipmentItem" />
  </v-container>
</template>

<script>
import EquipmentItem from '@/components/Equipment/EquipmentItem';
import TextHighlight from 'vue-text-highlight';

import EquipmentMovementsApi from '../../api/EquipmentMovementsApi';
import ExcelHelper from '../../helpers/ExcelHelper';
import PdfHelper from '../../helpers/PdfHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'CurrentStatuses',
  components: {
    EquipmentItem,
    TextHighlight,

  },
  data: () => ({
    search: '',
    loading: true,
    statuses: [],
    statusesHeaders: [
      {
        text: 'QR Code', value: 'code', width: '5%',
      },
      {
        text: 'Category', value: 'category', padding: '0 5px', width: '10%',
      },
      {
        text: 'Model', value: 'model', padding: '0 5px', width: '15%',
      },
      {
        text: 'Serial number',
        value: 'serial_number',
        filterable: false,
        padding: '0 5px',
        width: '10%',
      },
      {
        text: 'User', value: 'user_name', padding: '0 5px', width: '10%',
      },
      {
        text: 'Location',
        value: 'location_simple',
        padding: '0 5px',
        width: '10%',
      },
      {
        text: 'Condition (Comments)',
        value: 'condition',
        padding: '0 5px',
        width: '20%',
        align: 'center',
      },
      {
        text: 'Status (Hours Spent)',
        value: 'status',
        filterable: false,
        padding: '0 5px',
        width: '10%',
        align: 'center',
      },
      {
        text: 'Last update',
        value: 'created_at_full',
        padding: '0 5px',
        width: '8%',
      },
    ],
    statusesTableFooterProps: {
      'items-per-page-options': [20, 100, 500, -1],
    },
    statusesPerPage: 20,
    pagination: {
      itemsPerPage: 20,
      page: 1,
    },
    total: 0,
    jobId: null,
    userId: null,
    shelf: null,
    fromDate: null,
    toDate: null,
  }),

  watch: {
    pagination() {
      this.fetchStatuses();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchStatuses();
    },
    updateSearch(search) {
      this.search = search;
      this.fetchStatuses();
    },
    async loadJobStatuses(jobId) {
      this.jobId = jobId;
      this.fetchStatuses();
    },
    async loadUserPossessionStatuses(userId) {
      this.userId = userId;
      this.fetchStatuses();
    },
    async loadWarehouseShelfStatuses(shelf) {
      this.shelf = shelf;
      this.fetchStatuses();
    },
    async updateFilters(filters) {
      this.fromDate = filters.from;
      this.toDate = filters.to;
      this.fetchStatuses();
      // this.statuses = await EquipmentMovementsApi.getCurrentStatusesBetweenDateRange(filters);
      // this.formatStatuses();
    },
    generateExcel() {
      const excelData = this.statuses.map((el) => ({
        'Unique Item Code': el.code,
        Category: el.category,
        Manufacturer: el.manufacturer,
        Model: el.model,
        'Serial number': el.serial_number,
        Status: el.status,
        'Hours Spent': el.hours_spent,
        Date: el.created_at_full,
        Comment: el.description,
      }));
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Current Status');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Status_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      const pdf = PdfHelper.generateFromTable(
        '#statuses-table > div > table',
        'landscape',
        'Current Equipment Statuses',
        'current-statuses',
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Statuses_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },
    async fetchStatuses() {
      this.loading = true;
      const submitModel = {
        filters: {
          job_id: this.jobId,
          user_id: this.userId,
          warehouse_shelf: this.shelf,
          to_date: this.toDate,
          from_date: this.fromDate,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await EquipmentMovementsApi.read(submitModel, null, 'current_status/read');
      if (res.data) {
        this.statuses = res.data;
        this.total = res.total;
        this.loading = false;
      } else { this.statuses = []; }
      this.formatStatuses();
      this.loading = false;
    },
    formatStatuses() {
      this.statuses.map((item) => {
        if (item.condition === 'Good (Working)') {
          item.colour = 'green';
        } else if (item.condition === 'Bad (Broken)') {
          item.colour = 'red';
        } else {
          item.colour = 'orange';
        }

        item.locationColor = 'black';
        if (item.warehouse_shelf != null) {
          item.location = `Warehouse, shelf ${item.warehouse_shelf}`;
          item.location_simple = `Warehouse, shelf ${item.warehouse_shelf}`;
        } else if (item.job_id == null) {
          item.location_simple = 'User Possession';
          item.location = `User Possession (${item.user_name})`;
          item.locationColor = '#E19300';
        }

        if (item.movement === 'Check - in') {
          item.status = 'Checked - in';
          item.statusColour = '#3DA129';
        } else if (item.movement === 'Check - out') {
          item.status = 'Checked - out';
          item.statusColour = '#BF3434';
        } else if (item.movement === 'Change site') {
          item.status = 'Changed site';
        } else if (item.movement === 'Request Repair') {
          item.status = 'Repair Requested';
          item.statusColour = '#3462BF';
        } else if (item.movement === 'Repair Done') {
          item.status = item.movement;
          item.statusColour = '#562C78';
        } else if (item.movement === 'Change User') {
          item.status = 'Changed user';
          item.statusColour = '#A035EF';
        } else {
          item.status = item.movement;
        }
      });
    },
    openEquipmentInfo(id) {
      this.$refs.equipmentItem.openDialog(id);
    },
    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

  },
};
</script>

<style scoped>
.status-serial-number,
.status-location,
.status-date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-code {
  max-width: '7%';
}

.status-category,
.status-serial-number,
.status-user,
.status-location,
.status-type {
  max-width: '10%';
}

.status-model {
  max-width: '15%';
}

.status-condition {
  max-width: '20%';
}

.status-date {
  max-width: '8%';
}
</style>
