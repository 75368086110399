<template>
  <v-container class="pa-8">
    <v-row>
      <v-text-field
        v-model="searchText"
        prepend-inner-icon="fas fa-search"
        placeholder="Search"
        single-line
        hide-details
        solo
        rounded
        clearable
        class="elevation-0 pa-0"
        style="border: 1px solid #d6d6d6 !important; max-width: 1000px;"
      />
    </v-row>
  </v-container>
</template>
<script>
import OrganizationsApi from '@/api/OrganizationsApi';
import ThemeSwitch from '@/components/elements/ThemeSwitch';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';

import { mapGetters, mapState } from 'vuex';

export default {
  components: { ThemeSwitch },
  data: () => ({
    name: '',
    url: '',
    alertsTotal: 0,
    searchText: '',
  }),
  computed: {
    ...mapState({
      physicalCountList: 'physicalCountList',
      user: (state) => state.user,
      search: (state) => state.search,
    }),
    ...mapGetters([
      'isPhysicalCountActivated',
      'checkUserPermission',
    ]),
    pageTitle() {
      return this.$route.meta.title;
    },
  },
  watch: {
    searchText: debounce(function (val) {
      this.$store.commit('storeSearch', val === null ? '' : val);
    }, 500),
    $route(to, from) {
      this.searchText = '';
    },
    search(newVal) {
      this.searchText = newVal;
    },
  },
  created() {
    this.initialize();
    this.$store.commit('storeSearch', '');
  },
  beforeRouteUpdate(to, from, next) {
    this.clearSearchText();
    next();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchOrg()]);
      this.getAlertsNumber();
    },
    async fetchOrg() {
      this.org = await OrganizationsApi.getList();
      this.name = this.org[0].name;
      this.url = this.org[0].url;
    },
    async getAlertsNumber() {
      const res = await this.$api.alert.get('total');
      if (!res) return;
      this.alertsTotal = res;
    },

    adminClicked() {
      this.$router.push('/admin');
    },

    alertsClicked() {
      this.$router.push('/alerts');
    },

    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },

    clearSearchText() {
      this.searchText = '';
    },

  },
};
</script>
<style>
.zm-toolbar {
  box-shadow: none !important;
  border-bottom: 1px solid #d6d6d6 !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
}
.v-divider--vertical.v-divider--inset {
    border: 1px solid #d6d6d6;
}
div > div > div.v-input__prepend-inner > div > i
{
  font-size: 20px !important;
}
</style>
