import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;

class InventoryTagsApi extends Api {
  constructor() {
    super('inventory_tag', 'inventory_tags');
  }

  async getInventoryTag(tag) {
    const errorText = 'Could not find Inventory Tag';
    let res = null;
    try {
      res = await axios.get(`inventory_tags/${tag}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async addTag(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post('inventory_tags/add-tag', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

  async updateTag(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post('inventory_tags/update', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

  async submitMovement(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post(`/${this.endpoint}/movement`, data);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async getStock() {
    const errorText = 'Could not find Inventory Tag';
    let res = null;
    try {
      res = await axios.get(`/${this.endpoint}/stock`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async addUpc(data) {
    const res = await this.store(data, 'add-upc');
    return res;
  }

  async upcReceiving(data) {
    const res = await this.store(data, 'upc-receiving');
    return res;
  }

  async qrReceiving(data) {
    const res = await this.store(data, 'qr-receiving');
    return res;
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const inventoryTagsApi = new InventoryTagsApi();

export default inventoryTagsApi;
