<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <span style="font-size: 20px; font-weight: 500;">Notes</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="pt-4">
                <span style="font-size: 18px;"> Request#: <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold'}">{{ request ? request.id : '' }}</span></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size: 16px;">Inventory Class: <v-chip
                  small
                  :color="request && request.class ? request.class.color : ''"
                  text-color="white"
                >
                  {{ request && request.class ? request.class.name : '' }}
                </v-chip></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size: 16px;">Request Delivery Date: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.date : '' }}</span></span>
              </v-col>
            </v-row>
            <v-row v-if="request">
              <v-col>
                <span style="font-size: 16px;">Job: </span><span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}"> <span style="font-weight: bold;">{{ request.job_code ? `${request.job_code}` : '' }}</span> - {{ request.job_name ? request.job_name : '' }} <span style="color: #1976D2;">{{ request.reference ? `; ${request.reference}` : '' }}</span></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size: 16px;">Floor: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.floor_number : '' }}</span></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size: 16px;">Total Quantity Required: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.total_windows : '' }}</span></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size: 16px;">Truck Type: <v-chip
                  small
                  :color="request && request.truck_type ? request.truck_type.color : ''"
                  text-color="white"
                >
                  {{ request && request.truck_type ? request.truck_type.name : '' }}
                </v-chip></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size: 16px;">Installer: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.installer_name : '' }}</span></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="isAddBtn"
                class="text-end"
              >
                <v-btn
                  color="primary"
                  outlined
                  @click="isAddBtn = false"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >
                    fas fa-plus
                  </v-icon>
                  New Note
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="pb-0">
                <v-textarea
                  v-model="item.text"
                  outlined
                  rows="3"
                  :rules="[(v) => !!v || 'Note is required']"
                >
                  <template slot="label">
                    Note<RedAsterisk />
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="text-end pt-0 pb-0">
                <v-btn
                  color="normal"
                  class="mr-2"
                  @click="isAddBtn = true"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="submit"
                >
                  SUBMIT
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!isAddBtn">
              <v-col class="pb-0 pt-0 ">
                <zig-zag />
              </v-col>
            </v-row>
            <v-row v-if="request">
              <v-col>
                <v-row>
                  <v-col>
                    <!-- <v-virtual-scroll
                      :items="request.notes"
                      :height="isAddBtn ? 300 : 200"
                      item-height="75"
                      style="border: 1px solid rgba(0 ,0 ,0, 0.2); border-radius: 4px;"
                    > -->
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <!-- <template #default="{ item }">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="wrap-text"
                              v-text="item.text"
                            />
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text v-text="item.created_at" />
                            <v-list-item-action-text v-text="`${item.user.first_name ? item.user.first_name : ''} ${item.user.last_name ? item.user.last_name : ''}`" />
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-virtual-scroll> -->
                    <v-card-text style="max-height: 300px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
                      <v-list two-line>
                        <template v-for="(note, index) in request.notes">
                          <v-list-item :key="note.id">
                            <!-- Display each note -->
                            <v-list-item-content>
                              {{ note.text }}
                              <v-list-item-subtitle style="padding-top: 10px;">{{ note.created_at }}</v-list-item-subtitle>
                              <v-list-item-subtitle>{{ `${note.user.first_name ? note.user.first_name : ''} ${note.user.last_name ? note.user.last_name : ''}` }}</v-list-item-subtitle>
                            
                            </v-list-item-content>
                            <!-- <v-divider v-if="index !== schedule.notes.length - 1"/> -->
                          </v-list-item>
                          <v-divider
                            v-if="index < request.notes.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import ZigZag from '@/components/elements/ZigZag';
import {mapState} from 'vuex';

export default {
  components: {
    ZigZag,
  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      text: '',
      request_id: null,
    },
    dialog: false,
    request: null,
    isAddBtn: true,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    })
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async getRequest(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.request = res.data[firstIndex];
    },

    async submit() {
      if (!this.$refs.form.validate()) return;
      const res = await this.$api.truckScheduleNote.store(this.item);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.getRequest(this.item.request_id);
      this.item.text = '';
      this.isAddBtn = true;
      this.$emit('success');
    },

    open(requestId) {
      this.isAddBtn = true;
      this.item.request_id = requestId;
      this.getRequest(requestId);
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>
