var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pt-0"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"primary","disabled":_vm.currentYear === new Date().getFullYear()},on:{"click":_vm.setToday}},[_vm._v(" Current year ")])],1),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)],1),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.currentYear)+" ")])]),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1),_c('v-col',{staticClass:"text-end pt-0"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.open}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" new Holiday ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.itemsTableFooterProps,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"sort-by":"date","dense":"","fixed-header":"","height":_vm.windowHeight},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.date_full)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.is_enabled)?_c('v-chip',{attrs:{"small":"","color":"success","text-color":"white"}},[_vm._v(" ACTIVE ")]):_c('v-chip',{attrs:{"small":"","color":"error","text-color":"white"}},[_vm._v(" DISABLED ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"orange","outlined":"","small":""},on:{"click":function($event){return _vm.changeUserStatus(item)}}},[_vm._v(" "+_vm._s(item.is_enabled ? "DISABLE" : "ENABLE")+" ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1 mr-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('TimeOffHolidayForm',{ref:"itemForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }