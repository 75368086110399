<template>
  <v-container
    fluid
  >
    <alloy-table-form />
    <tempering-table-form />
  </v-container>
</template>

<script>
import AlloyTableForm from '@/components/forms/alloys/AlloyTableForm';
import TemperingTableForm from '@/components/forms/temperings/TemperingTableForm';

export default {
  components: {
    AlloyTableForm,
    TemperingTableForm,
  },
  data: () => ({
  }),
  methods: {
  },
};
</script>
