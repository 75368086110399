<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        v-if="canEdit"
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          @click="
            () => {
              $refs.InventoryCategoriesForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="movements-table"
            :headers="inventoryTypesHeaders"
            :items="inventoryTypes"
            :footer-props="itemsTableFooterProps"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #item="{ item }">
              <tr>
                <td :class="{ 'red-theme': !item.enabled }">
                  <TextHighlight :queries="search">
                    {{ item.id }}
                  </TextHighlight>
                </td>
                <td :class="{ 'red-theme': !item.enabled }">
                  <TextHighlight :queries="search">
                    {{ item.name }}
                  </TextHighlight>
                </td>
                <td :class="{ 'red-theme': !item.enabled }">
                  <TextHighlight :queries="search">
                    {{ item.created_at }}
                  </TextHighlight>
                </td>
                <td :class="{ 'red-theme': !item.enabled }">
                  <TextHighlight :queries="search">
                    {{ item.updated_at }}
                  </TextHighlight>
                </td>
                <td style="text-align: right">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openProfileForm(item)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    v-if="item.enabled == 1"
                    class="ml-1 mt-1 mb-1"
                    color="purple"
                    outlined
                    small
                    @click="disable(item.id)"
                  >
                    DISABLE
                  </v-btn>
                  <v-btn
                    v-else
                    class="ml-1 mt-1 mb-1 pl-5"
                    color="purple"
                    outlined
                    small
                    @click="enable(item.id)"
                  >
                    ENABLE
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    @click="deleted(item.id)"
                  >
                    DELETE
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
    <InventoryCategoriesForm
      ref="InventoryCategoriesForm"
      @profile-creation-success="notifyProfileCreationSuccess"
      @profile-creation-fail="notifyProfileCreationFail"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { mapState } from 'vuex';
import Snackbar from '../../components/Snackbar';
import InventoryCategoriesForm from '../../components/forms/InventoryCategoriesForm';
import InventoryCategoriesApi from '../../api/InventoryCategoriesApi';

export default {
  name: 'Profiles',
  components: {
    TextHighlight,
    Snackbar,
    InventoryCategoriesForm,
  },
  filters: {},
  data: () => ({
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    inventoryTypesHeaders: [
      { text: 'Type ID', value: 'id' },
      { text: 'Type Name', value: 'name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Actions', align: 'end' },

      // { text: 'Updated At', value: 'updated_at' },
      // { text: 'Actions', value: 'actions', align: 'end' },
    ],
    inventoryTypes: [],
    canEdit: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  created() {
    this.initialize();
    this.canEdit = this.$store.getters.userCanEdit;
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchProfiles()]);
    },
    openProfileForm(item) {
      this.$refs.InventoryCategoriesForm.openDialog(item);
    },
    showBoms(item, type) {
      this.$emit('show-boms', item.id, type);
    },
    notifyProfileCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchProfiles();
    },
    notifyProfileCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    async fetchProfiles() {
      this.loading = true;
      this.inventoryTypes = await InventoryCategoriesApi.getList();

      const arr = this.inventoryTypes;
      const resultsArr = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].deleted !== 1) resultsArr.push(arr[i]);
      }
      this.inventoryTypes = resultsArr;
      this.loading = false;
    },
    openProfile(item) {
      this.$router.push(`/profile/${item.code}`);
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    async deleted(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const data = new FormData();
      data.append('id', id);
      data.append('deleted', 1);
      const res = await InventoryCategoriesApi.store(data);
      if (res) {
        this.$emit('profile-creation-success');
      } else {
        this.$emit('profile-creation-fail');
      }
      this.fetchProfiles();
    },
    async enable(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to enable this record?',
      });
      if (!confirm) return;
      const data = new FormData();
      data.append('id', id);
      data.append('enabled', 1);
      const res = await InventoryCategoriesApi.store(data);
      if (res) {
        this.$emit('profile-creation-success');
      } else {
        this.$emit('profile-creation-fail');
      }
      this.fetchProfiles();
    },
    async disable(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to disable this record?',
      });
      if (!confirm) return;

      const data = new FormData();
      data.append('id', id);
      data.append('enabled', 0);
      const res = await InventoryCategoriesApi.store(data);
      if (res) {
        this.$emit('profile-creation-success');
      } else {
        this.$emit('profile-creation-fail');
      }
      this.fetchProfiles();
    },
  },
};
</script>
<style scoped>
.profile-photo {
  max-width: 100px;
}
.text-right {
  width: 85px !important;
}
.red-theme {
  color: #fa7575 !important;
}
</style>
