<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="1"
        class="mt-4"
      >
        <v-btn
          text
          @click="goBack"
        >
          <v-icon>
            fas fa-arrow-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="5"
        class="text-center mt-5"
      >
        <span style="font-size: 1.5em;">Moving To: <span class="font-weight-bold">{{ batch && batch.location ? batch.location.name : '' }}</span>, {{ batch && batch.inventory_location ? batch.inventory_location.name : '' }}</span>
      </v-col>
      <v-col
        class="text-end mt-4"
      >
        <v-btn
          v-if="webhook"
          class="mr-4"
          color="orange"
          outlined
          @click="runWebhook(batch.id)"
        >
          PDF
        </v-btn>
        <v-btn
          v-if="batch && batch.is_moved === 0"
          color="primary"
          class="mr-4"
          outlined
          @click="openQrCodeForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          Scan QR
        </v-btn>
        <v-btn
          v-if="batch && batch.is_moved === 0"
          color="purple"
          outlined
          @click="move"
        >
          Move
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.inventory_tag`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.inventory_tag }}
              </text-highlight>
            </template>
            <template #[`item.moving_to_tag`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.moving_to_tag }}
              </text-highlight>
            </template>
            <template #[`item.type`]="{ item }">
              <v-chip
                small
                :color="item.type_color"
                text-color="white"
              >
                {{ item.type_name }}
              </v-chip>
            </template>
            <template #[`item.inventory_location`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item && item.inventory_location ? item.inventory_location.name : '' }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item && item.user ? item.user.first_name + ' ' + item.user.last_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.location`]="{ item }">
              {{ item.location ? item.location.name : '' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="batch && batch.is_moved === 0"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                @click="openEditForm(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                v-if="batch && batch.is_moved === 0"
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <movement-batch-item-form
      ref="batchForm"
      @item-success="success"
    />
    <v-dialog
      v-model="qrCodeDialog"
      fullscreen
      persistent
      hide-overlay
    >
      <v-card>
        <v-card-title>
          <v-col class="text-right">
            <v-btn
              icon
              @click="qrCodeDialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-container fluid>
          <inventory-qr
            :is-rm-supply="true"
            @return-qr-code="returnQrCode"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import MovementBatchItemForm from '@/components/forms/movements/MovementBatchItemForm';
import TextHighlight from 'vue-text-highlight';
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import { mapState } from 'vuex';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  components: {
    MovementBatchItemForm,
    TextHighlight,
    InventoryQr,
  },
  data: () => ({
    batchId: 0,
    items: [],
    headers: [
      { text: 'QR Code', value: 'inventory_tag' },
      { text: 'Assign To QR Code', value: 'moving_to_tag' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Type', value: 'type', align: 'center' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    loading: false,
    qrCodeDialog: false,
    batch: null,
    webhook: null,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  created() {
    this.batchId = this.$route.params.id;
    this.init();
  },

  methods: {
    init() {
      this.getItems();
      this.getBatch();
      this.getWebhook();
    },

    async getItems() {
      this.loading = true;
      const submitModel = {
        filters: {
          batch_id: this.batchId,
        },
      };
      this.items = await this.$api.tagToMovementbatch.read(submitModel);
      this.loading = false;
    },

    async getBatch() {
      const submitModel = {
        filters: {
          id: this.batchId,
        },
      };
      const res = await this.$api.inventoryMovementBatch.read(submitModel);
      if (!res || !res[0]) return;

      const firstIndex = 0;
      this.batch = res[firstIndex];
    },

    async getWebhook() {
      const movingBatchModuleId = 19;
      const readModel = {
        filters:
        {
          module_id: movingBatchModuleId,
        },
      };
      const res = await this.$api.webhook.read(readModel);
      if (!res || !res[0]) return;
      const firstIndex = 0;
      this.webhook = res[firstIndex];
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.tagToMovementbatch.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getItems();
    },

    openQrCodeForm() {
      this.qrCodeDialog = true;
    },

    openEditForm(item) {
      this.$refs.batchForm.open(item.inventory_tag, this.batch.location, this.batch, item);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.getItems();
      this.getBatch();
    },

    returnQrCode(code) {
      this.qrCodeDialog = false;
      if (!code || !this.batch || !this.batch.location) return;
      this.$refs.batchForm.open(code, this.batch.location, this.batch);
    },

    async move() {
      if (this.batch.records_number === 0) {
        this.$bus.$emit('showError', 'There are no records to move');
        return;
      }
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to move all items within Record# ${this.batch.id} to Location: ${this.batch.location.name}?`,
      });
      if (!confirm) return;
      if (!this.batch) return;
      this.$bus.$emit('loading');
      const res = await this.$api.inventoryMovementBatch.get(`move/${this.batch.id}`);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      setTimeout(() => {
        this.$router.push('/moving-qr');
      }, 2000);
    },

    goBack() {
      this.$router.push('/moving-qr');
    },

    async runWebhook(id) {
      this.$bus.$emit('loading');
      const movingBatchModuleId = 19;
      const submitModel = { id, module_id: movingBatchModuleId };
      const res = await this.$api.webhook.store(submitModel, 'run');
      if (!res) return;
      const pdf = await this.$api.report.download(res.id, 'pdf');
      if (!pdf) {
        this.$bus.$emit('showError', 'Report Failed');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${res.report_title}_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },
  },
};
</script>
