import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
class ProjectApi extends Api {
  constructor() {
    super('projects', 'projects');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const projectApi = new ProjectApi();

export default projectApi;
