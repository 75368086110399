import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelReadPurchaseOrder;
let cancelReadPurchaseOrderItem;
class SupplyToPaintApi extends Api {
  constructor() {
    super('supply_to_paints', 'supply_to_paints');
  }

  async readPurchaseOrder(data) {
    if (cancelReadPurchaseOrder !== undefined) {
      cancelReadPurchaseOrder();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadPurchaseOrder = c;
      }),

    }, 'read/purchase_orders');
    return res;
  }

  async readPurchaseOrderItem(data) {
    if (cancelReadPurchaseOrderItem !== undefined) {
      cancelReadPurchaseOrderItem();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadPurchaseOrderItem = c;
      }),

    }, 'read/purchase_order_items');
    return res;
  }
}
const supplyToPaintApi = new SupplyToPaintApi();

export default supplyToPaintApi;
