var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.isHrManager || _vm.isHr || _vm.isAccountant || _vm.isTimeOffSupervisor)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Employee","items":_vm.autoCompleteEmployees,"hide-details":"true","item-value":"id","item-text":"full_name","clearable":""},model:{value:(_vm.filterUserId),callback:function ($$v) {_vm.filterUserId=$$v},expression:"filterUserId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Leave Type","items":_vm.leaveTypes,"hide-details":"true","item-value":"id","item-text":"name","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : ''}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.filterLeaveTypeId),callback:function ($$v) {_vm.filterLeaveTypeId=$$v},expression:"filterLeaveTypeId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.statuses,"hide-details":"true","item-value":"id","item-text":"name","clearable":"","disabled":_vm.disableFilterStatus},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : ''}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.filterStatusId),callback:function ($$v) {_vm.filterStatusId=$$v},expression:"filterStatusId"}})],1),_c('v-spacer'),(_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdHr) || _vm.roles.includes(_vm.userRoleIdTimeOffUser) || _vm.roles.includes(_vm.userRoleIdTimeOffSupervisor))?_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openRequestForm()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Time Off Request ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{ref:"parent",attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.timeOffs,"footer-props":_vm.itemsTableFooterProps,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"dense":"","loading":_vm.loading,"fixed-header":"","height":_vm.windowHeight,"server-items-length":_vm.total},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.user_full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user_full_name ? item.user_full_name : '')+" ")])]}},{key:"item.user_employee_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user_employee_number ? item.user_employee_number : '')+" ")])]}},{key:"item.time_period",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.renderTimePeriod(item))+" ")])]}},{key:"item.days_requested",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.days_requested ? item.days_requested : '')+" ")])]}},{key:"item.leave_type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.leave_type && item.leave_type.color ? item.leave_type.color : '',"text-color":"white"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.leave_type && item.leave_type.name ? item.leave_type.name : '')+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.status && item.status.color ? item.status.color : '',"text-color":"white"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.status && item.status.name ? item.status.name : '')+" ")])],1)]}},{key:"item.submitted_at_short",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.submitted_at_short ? item.submitted_at_short : '')+" ")])]}},{key:"item.approved_at_short",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.approved_at_short ? item.approved_at_short : '')+" ")])]}},{key:"item.approved_by_full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.approved_by_full_name ? item.approved_by_full_name : '')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"warning","outlined":"","small":""},on:{"click":function($event){return _vm.openNoteForm(item)}}},[_vm._v(" NOTES ")]),(_vm.renderApproveButton(item))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.approveTimeOffForm(item)}}},[_vm._v(" APPROVE/DENY ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.viewTimeOffForm(item)}}},[_vm._v(" VIEW ")]),(_vm.renderCancelButton(item))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.cancelTimeOffRequest(item)}}},[_vm._v(" CANCEL ")]):_vm._e(),(_vm.renderDeleteButton(item))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteTimeOffRequest(item)}}},[_vm._v(" DELETE ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('time-off-request-form',{ref:"timeOffRequestForm",attrs:{"view-form":_vm.viewForm,"approve-form":_vm.approveForm},on:{"close-form":_vm.closeForm,"success":_vm.get}}),_c('note-form',{ref:"noteForm"}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }