<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card tile>
        <v-card-title>
          Batch History

          <v-col class="text-right">
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model="search"
                append-icon="fas fa-search"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
            <v-col
              cols="2"
            >
              <inventory-class-select-element
                v-model="inventoryClassId"
              />
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="filterJob"
                label="Job"
                :items="jobCodes"
                hide-details="true"
                item-value="code"
                clearable
                :search="search"
              >
                <template #selection="{ item }">
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>

                <template #item="{ item }">
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="filterPo"
                label="PO#"
                :items="poNumbers"
                hide-details="true"
                clearable
              />
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="filterBatchId"
                label="Batch"
                item-value="id"
                item-text="batch_number"
                :items="batches"
                hide-details
                clearable
              />
            </v-col>
            <v-col
              cols="2"
            >
              <v-menu
                ref="menu"
                v-model="dateMenu"
                :return-value.sync="filterDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterDate"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="$refs.menu.save(filterDate)"
                  />
                </template>
                <v-date-picker
                  v-model="filterDate"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="filterDate = null"
                  >
                    Clear
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="batchHistories"
                :items-per-page="-1"
                :loading="loading"
                dense
              >
                <template #item="{ item }">
                  <tr :style="{ backgroundColor: (parseInt(item.index) % 2 == 0 ? 'white' : '#F5F5F5') }">
                    <td class="text-center">
                      <v-chip
                        small
                        :color="item && item.class ? item.class.color : 'black'"
                        text-color="white"
                      >
                        {{ item && item.class ? item.class.name : '' }}
                      </v-chip>
                    </td>

                    <td class="text-center">
                      <v-chip
                        small
                        :color="item && item.history_type ? item.history_type.color : 'black'"
                        text-color="white"
                      >
                        {{ item && item.history_type ? item.history_type.name : '' }}
                      </v-chip>
                    </td>

                    <td>
                      <span>
                        <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                      </span>
                    </td>

                    <td>
                      <span><text-highlight :queries="search">{{ item && item.batch ? item.batch.po_number : '' }}</text-highlight></span>
                    </td>

                    <td>
                      <span
                        :style="`font-size: 14px !important; color: ${item.id ? 'black' : 'red'}`"
                        class="font-weight-bold"
                      >
                        {{ item.batch_number ? item.batch_number : '' }}
                      </span>
                    </td>

                    <td>
                      <span><text-highlight :queries="search">{{ item && item.batch ? item.batch.g_code : '' }}</text-highlight></span>
                    </td>

                    <td>
                      <span><text-highlight :queries="search">{{ item && item.batch ? item.batch.glass_thickness : '' }}</text-highlight></span>
                    </td>

                    <td>
                      <v-row>
                        <v-col v-if="item.from_quantity !== null && item.to_quantity != null && item.from_quantity !== item.to_quantity">
                          <v-row>
                            <v-col
                              class="pa-0 pt-3 text-center"
                            >
                              {{ item.from_quantity }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              class="pa-0 text-center"
                            >
                              <span
                                style="color: red;"
                                class="font-weight-bold"
                              >↓</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="pa-0 pb-3 text-center font-weight-bold">
                              {{ item.to_quantity }}
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          v-else
                          class="text-center"
                        >
                          {{ item.to_quantity }}
                        </v-col>
                      </v-row>
                    </td>
                    <td class="text-center">
                      <span><text-highlight :queries="search">{{ item.max_total_glass }}</text-highlight></span>
                    </td>

                    <td>
                      <v-row v-if="item">
                        <v-col v-if="item.from_status && item.to_status && item.from_glass_batch_status_id !== item.to_glass_batch_status_id">
                          <v-row>
                            <v-col
                              class="pa-0 pt-3 text-center"
                            >
                              <v-chip
                                small
                                :color="item && item.from_status ? item.from_status.color : 'black'"
                                text-color="white"
                              >
                                {{ item.from_status ? item.from_status.name : '' }}
                              </v-chip>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              class="pa-0 text-center"
                            >
                              <span
                                style="color: red;"
                                class="font-weight-bold"
                              >↓</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="pa-0 pb-3 text-center">
                              <v-chip
                                small
                                :color="item && item.to_status ? item.to_status.color : 'black'"
                                text-color="white"
                              >
                                {{ item.to_status ? item.to_status.name: '' }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          v-else
                          class="text-center"
                        >
                          <v-chip
                            small
                            :color="item && item.to_status ? item.to_status.color : 'black'"
                            text-color="white"
                          >
                            {{ item.to_status ? item.to_status.name: '' }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </td>

                    <td>
                      <span><text-highlight :queries="search">{{ item.user_full_name }}</text-highlight></span>
                    </td>
                    <td class="text-end">
                      <span><text-highlight :queries="search">{{ item.created_at }}</text-highlight></span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    batchHistories: [],
    poNumbers: [],
    jobCodes: [],
    batches: [],
    headers: [
      {
        text: 'Class', value: 'inventory_class_id', align: 'center', width: '5%',
      },
      {
        text: 'Type', value: 'history_type', align: 'center', width: '5%',
      },
      { text: 'Job', value: 'job' },
      { text: 'PO#', value: 'po_number' },
      { text: 'Batch#', value: 'batch_number' },
      { text: 'Glass Code', value: 'g_code' },
      { text: 'Glass Thickness', value: 'glass_thickness' },
      { text: 'Glass Qty', value: 'total_glass', align: 'center' },
      { text: 'Max Glass Qty', value: 'max_total_glass', align: 'center' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Created By', value: 'created_by', align: 'end' },
    ],
    loading: false,
    inventoryClassId: null,
    filterJob: null,
    filterPo: null,
    filterBatchId: null,
    dateMenu: false,
    filterDate: null,
    dialog: false,
    search: '',
  }),
  watch: {
    inventoryClassId() {
      // this.getHistory();
      // this.getPoItems();
      // this.getJobs();
      // this.getBatches();
    },
    filterPo() {
      this.getHistory();
      this.getBatches();
    },
    filterJob() {
      this.getHistory();
      this.getBatches();
    },
    filterDate(val) {
      this.$refs.menu.save(val);
      this.getHistory();
      this.getBatches();
    },
    filterBatchId() {
      this.getHistory();
    },
  },
  created() {

  },
  methods: {

    async getHistory() {
      this.loading = true;
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          job_code: this.filterJob,
          po_number: this.filterPo,
          date: this.filterDate,
          batch_id: this.filterBatchId,
        },
      };
      const res = await this.$api.glassBatch.readBatchHistory(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res) {
        this.batchHistories = [];
        return;
      }
      this.batchHistories = res;
      this.loading = false;
    },

    async getPoItems() {
      const res = await this.$api.glass.get(`po-numbers/${this.inventoryClassId}/${null}`);
      if (!res) {
        this.poNumbers = [];
        return;
      }
      this.poNumbers = res;
    },

    async getJobs() {
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId },
      };
      const res = await this.$api.job.readDataSecondary(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobCodes = [];
        return;
      }
      this.jobCodes = res;
    },

    async getBatches() {
      this.batches = await this.$api.glassBatch.get('batch-numbers');
    },

    open(batchId) {
      this.dialog = true;
      this.getHistory();
      this.getBatches();
      this.getPoItems();
      this.getJobs();
      this.filterBatchId = batchId;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

span
{
  font-size: 12px;
}

</style>
