var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"submit-btn-text":"Create","full-screen":true,"warning-text":'*Red ones are still to be created'}},[_c('template',{slot:"header"},[_c('span',[_vm._v("Create Remake PO")])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":-1,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.inventory_class_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.batch_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px !important","color":"red"}},[_vm._v(" "+_vm._s(item.batch_number ? item.batch_number : '')+" ")])]}},{key:"item.po_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.po_number)+" ")]}},{key:"item.g_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.g_code)+" ")]}},{key:"item.glass_thickness",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.glass_thickness)+" ")]}},{key:"item.total_glass",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_glass)+" ")]}},{key:"item.job_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.job && item.job.code ? item.job.code : '-')+" ")]}},{key:"item.job_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.job && item.job.name ? item.job.name : '-')+" ")]}},{key:"item.remake_reason_id",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.remakeReasons,"item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'Remake Reason is required'; }]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":item ? item.color : 'white'}},[_c('span',{staticStyle:{"white-space":"nowrap","color":"#FFFFFF"}},[_vm._v(_vm._s(item.name))])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":item ? item.color : 'white'}},[_c('span',{staticStyle:{"white-space":"nowrap","color":"#FFFFFF"}},[_vm._v(_vm._s(item.name))])])]}}],null,true),model:{value:(item.remake_reason_id),callback:function ($$v) {_vm.$set(item, "remake_reason_id", $$v)},expression:"item.remake_reason_id"}})]}},{key:"item.status",fn:function(ref){return [_c('v-chip',{attrs:{"small":"","color":"error","text-color":"white"}},[_vm._v(" TO BE CREATED ")])]}}],null,true)})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }