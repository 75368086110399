<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-5">
        <h3>Truck Types</h3>
      </v-col>
      <v-col class="text-end">
        <v-btn
          color="primary"
          outlined
          @click="openTruckTypeForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Truck Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="truckTypeHeaders"
            :items="truckTypes"
            :footer-props="truckTypeFooterProps"
            :items-per-page="20"
            :search="search"
            :loading="truckTypeLoading"
            dense
          >
            <template #[`item.id`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.id }}
              </text-highlight>
            </template>
            <template #[`item.name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.weight_limit`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.weight_limit ? item.weight_limit.toLocaleString() : '-' }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditTruckTypeForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteTruckType(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-10">
        <h3>Trucks</h3>
      </v-col>
      <v-col class="text-end mt-8">
        <v-btn
          color="primary"
          outlined
          @click="openTruckForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Truck
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="truckHeaders"
            :items="trucks"
            :footer-props="truckFooterProps"
            :items-per-page="20"
            :search="search"
            :loading="truckLoading"
            dense
          >
            <template #[`item.id`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.id }}
              </text-highlight>
            </template>
            <template #[`item.name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.truck_type`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.truck_type }}
              </text-highlight>
            </template>
            <template #[`item.type_weight_limit`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.type_weight_limit ? item.type_weight_limit.toLocaleString() : '-' }}
              </text-highlight>
            </template>
            <template #[`item.plate_number`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.plate_number }}
              </text-highlight>
            </template>
            <template #[`item.truck_number`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.truck_number }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditTruckForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteTruck(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <truck-schedule-truck-form
      ref="truckForm"
      @success="getTrucks"
    />
    <truck-schedule-truck-type-form
      ref="truckTypeForm"
      @success="getTruckTypes"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TruckScheduleTruckForm from '@/components/forms/truckSchedules/TruckScheduleTruckForm';
import TruckScheduleTruckTypeForm from '@/components/forms/truckSchedules/TruckScheduleTruckTypeForm';

export default {
  components: {
    TruckScheduleTruckForm,
    TruckScheduleTruckTypeForm,
  },
  props: {

  },
  data: () => ({
    truckTypes: [],
    trucks: [],
    truckTypeLoading: false,
    truckLoading: false,
    truckTypeHeaders: [
      { text: 'Type#', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Weight Limit (kg)', value: 'weight_limit' },
      { text: 'Color', value: 'color' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Created By', value: 'user_full_name' },
      {
        text: 'Actions', value: 'actions', width: '15%', align: 'end',
      },
    ],
    truckTypeFooterProps: {
      'items-per-page-options': [20, 50, 100, -1],
    },
    truckHeaders: [
      { text: 'Truck#', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Truck Type', value: 'type_name' },
      { text: 'Weight Limit (kg)', value: 'type_weight_limit' },
      { text: 'Plate Number', value: 'plate_number' },
      { text: 'Truck Number', value: 'truck_number' },
      { text: 'Color', value: 'color' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Created By', value: 'user_full_name' },
      {
        text: 'Actions', value: 'actions', width: '15%', align: 'end',
      },
    ],
    truckFooterProps: {
      'items-per-page-options': [20, 50, 100, -1],
    },
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTruckTypes();
      this.getTrucks();
    },

    async getTruckTypes() {
      const submitModel = {
        filters: {
        },
      };
      this.truckTypes = await this.$api.truckScheduleTruckType.read(submitModel);
    },

    async getTrucks() {
      const submitModel = {
        filters: {
        },
      };
      this.trucks = await this.$api.truckScheduleTruck.read(submitModel);
    },

    openTruckTypeForm() {
      this.$refs.truckTypeForm.open();
    },

    openEditTruckTypeForm(id) {
      this.$refs.truckTypeForm.open(id);
    },

    openTruckForm() {
      this.$refs.truckForm.open();
    },

    openEditTruckForm(id) {
      this.$refs.truckForm.open(id);
    },

    async deleteTruckType(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.truckScheduleTruckType.delete(id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.getTruckTypes();
    },

    async deleteTruck(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.truckScheduleTruck.delete(id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.getTrucks();
    },
  },
};
</script>

<style scoped>

</style>
