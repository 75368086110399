import Api from './Api';

class ManufacturersApi extends Api {
  constructor() {
    super('manufacturer', 'manufacturers');
  }
}
const manufacturersApi = new ManufacturersApi();

export default manufacturersApi;
