<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :is-report-issue="false"
  >
    <template slot="header">
      <span>Report Issue/Bug</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.admin_module_id"
            :items="modules"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'ERP Module is required']"
          >
            <template slot="label">
              ERP Module<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-textarea
            v-model="item.description"
            outlined
            dense
            :rules="[(v) => !!v || 'Description is required']"
          >
            <template slot="label">
              Describe your issue<RedAsterisk />
            </template>
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-file-input
            v-model="item.file"
            capture="user"
            accept="image/*"
            outlined
            small-chips
          >
            <template slot="label">
              Attachment
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      description: null,
      admin_module_id: null,
      file: null,
    },
    modules: [],
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
    this.getAdminModules();
  },
  methods: {

    async getAdminModules() {
      this.modules = await this.$api.adminModule.getList();
    },

    async submit() {
      const formData = new FormData();
      if (this.item.file) { formData.append('file', this.item.file); }
      formData.append('description', this.item.description);
      formData.append('admin_module_id', this.item.admin_module_id);
      const res = await this.$api.reportIssue.store(formData);

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open() {
      this.item = { ...this.itemDto };
      this.$refs.dialogBox.open();
    },
  },
};
</script>
