import Api from './Api';

class ColorsApi extends Api {
  constructor() {
    super('color', 'colors');
  }
}
const colorsApi = new ColorsApi();

export default colorsApi;
