<template>
  <div class="pa-2">
    <v-row>
      <v-col cols="3" />
      <v-col
        cols="6"
        md="6"
        sm="12"
        class="pb-0 pt-0"
        style="font-size: 30px;"
        align="center"
      >
        {{ title }}
      </v-col>
      <v-col
        cols="3"
        md="3"
        sm="12"
        align="end"
      >
        <v-btn
          v-if="!isTyping"
          color="primary"
          align="start"
          class="pa-4"
          outlined
          style="font-size: 1.1em;"
          @click="changeCamera"
        >
          Switch Back/Front Camera
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0 pb-0"
        align="center"
      >
        {{ secondTitle }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="3"
      />
      <v-col
        cols="6"
        align="center"
        md="6"
        sm="12"
      >
        <v-card
          elevation="0"
          height="400px"
          width="700px"
          :style="{backgroundColor: isDarkMode ? '#121212' : ''}"
        >
          <v-row v-if="isTyping">
            <v-col>
              <v-row>
                <v-col
                  class="ml-1 pb-1"
                  style="margin-top: 130px;"
                >
                  <h3 class="mb-5">
                    {{ textfieldTitle }}
                  </h3>

                  <v-row>
                    <v-col
                      cols="3"
                      class="pa-0"
                    />
                    <v-col
                      cols="6"
                      class="pa-0"
                    >
                      <v-text-field
                        v-if="isTyping"
                        v-model="qrCode"
                        outlined
                        style="font-size: 2em;"
                      />
                    </v-col>
                    <v-col cols="1" />
                    <v-col
                      cols="2"
                      class="pa-0"
                    >
                      <v-btn
                        class="pa-7"
                        style="font-size: 1.3em;"
                        color="success"
                        @click="manualEntered()"
                      >
                        Enter
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <qrcode-stream
            v-else
            :camera="camera"
            :track="paintBoundingBox"
            @decode="processResult"
            @init="onInit"
          >
            <canvas
              id="square-canvas"
              class="overlay-line"
            />
          </qrcode-stream>
        </v-card>
      </v-col>
      <v-col
        cols="3"
      />
    </v-row>
    <canvas
      id="myCanvas"
      style="z-index: 100;"
    />
    <glass-packaging-slip-form
      ref="glassPackagingSlipForm"
    />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import { mapGetters, mapState } from 'vuex';
import GlassPackagingSlipForm from '@/components/forms/glasses/GlassPackagingSlipForm';

export default {
  components: {
    QrcodeStream,
    GlassPackagingSlipForm,
  },
  props: {
    msg: {
      type: String,
      default: '',
    },
    qrType: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    packagingSlip: null,
    loading: false,
    camera: 'rear',
    cameraState: '',
    error: '',
    openDialog: false,
    isQrEqualsTag: false,
    isTyping: false,
    qrCode: '',
    inventoryTag: '',
    textfieldTitle: 'QR Code',
    secondTitle: 'Please type QR Code into the field',
    title: 'Packing Slip QR',
    glassPoStatusProducedId: 3,
    glassPoStatusShippedId: 6,

  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      isQRCodeScan: 'isQRCodeScan',
      isDarkMode: 'isDarkMode',
    }),
    validationPending() {
      return this.camera === 'off';
    },
  },
  watch: {
    isTyping(val) {
      if (val) this.turnCameraOff();
      else this.turnCameraOn();

      if (!val) {
        this.$nextTick(async () => {
          this.manualBoundingBox();
        });
      }
      this.$store.commit('storeIsQRCodeScan', val);
    },

    error(message) {
      this.$bus.$emit('showError', message);
    },
  },
  created() {
    this.isTyping = this.isQRCodeScan;
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$nextTick();
      this.turnCameraOn();

      this.manualBoundingBox();

      const QrEqualsTagBRName = 'QR Code Equals Inv. Tag';
      this.isQrEqualsTag = this.businessRulesGetBoolean(QrEqualsTagBRName);
    },

    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        }
      } finally {
        this.loading = false;
      }
    },

    turnCameraOn() {
      this.camera = 'rear';
    },

    turnCameraOff() {
      this.cameraState = this.camera;
      this.camera = 'off';
    },

    changeCamera() {
      this.camera = this.camera === 'front' ? 'rear' : 'front';
    },

    manualBoundingBox() {
      const boxSizes = [];
      boxSizes.push({
        boundingBox: {
          x: 50.5, y: 10.5, width: 200, height: 130,
        },
      });
      const canvas = document.getElementById('square-canvas');
      const ctx = canvas.getContext('2d');
      this.paintBoundingBox(boxSizes, ctx);
    },

    paintBoundingBox(detectedCodes, ctx) {
      // eslint-disable-next-line no-restricted-syntax
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: {
            x, y, width, height,
          },
        } = detectedCode;

        ctx.lineWidth = 1;
        ctx.strokeStyle = '#5cb984';
        ctx.strokeRect(x, y, width, height);
      }
    },

    async processResult(result) {
      this.turnCameraOff();
      const resultArray = result.split('/');
      const qrCodeNumberIdx = 4;
      this.qrCode = resultArray[qrCodeNumberIdx];
      this.getQrCode();
    },

    async getQrCode() {
      if (!this.qrCode || this.qrCode === null) return;

      await this.getPackagingSlip();
      if (!this.packagingSlip) return;
      this.$refs.glassPackagingSlipForm.open(this.packagingSlip.id, this.packagingSlip.status_id);
    },

    async getPackagingSlip() {
      this.loading = true;
      this.packagingSlip = null;
      const submitModel = {
        filters: {
          slip_number: this.qrCode,
        },
      };
      const res = await this.$api.glassPackagingSlip.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.packagingSlip = null;
        this.loading = false;
        this.$bus.$emit('showError', 'Packing slip not found');
        return;
      }
      [this.packagingSlip] = res.data;
      this.loading = false;
    },

    manualEntered() {
      this.getQrCode();
    },

    async submitQRCodeTag(msg) {
      if (!this.isRmReceivingQr) return;

      this.$bus.$emit('showSuccess', msg);

      if (this.isTyping) this.qrCode = '';

      else this.turnCameraOn();
    },

    async getActivePhysicalCountList() {
      this.physicalCountList = await this.$api.physicalCountList.getActive();
    },
  },
};
</script>
<style scoped>
.overlay-line {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.v-text-field--outlined .v-input__prepend-outer, .v-text-field--outlined .v-input__append-outer {
    margin-top: 0px;
}
.v-application--is-ltr .v-input__append-outer {
    margin-left: 1px;
}

</style>
