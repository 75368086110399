import Api from './Api';

class PhysicalCountApi extends Api {
  constructor() {
    super('physical_counts', 'physical_counts');
  }

  async getByList(id, tag) {
    return await this.get(`list/${id}/${tag}`);
  }
}
const physicalCountApi = new PhysicalCountApi();

export default physicalCountApi;
