<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    submit-btn-text="Create"
    :full-screen="true"
  >
    <template slot="header">
      <span>Add To Batch</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="groupedBatches"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
            no-data-text="There are no Batches of this glass type"
            dense
            show-select
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #item.data-table-select="{ item }">
              <v-checkbox
                :key="item.id"
                v-model="selectedBatchId"
                :value="item.id"
              />
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #header.data-table-select />
            <template #[`item.inventory_class_id`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <span
                style="font-size: 14px !important;"
                class="font-weight-bold"
              >

                {{ item.batch_number ? item.batch_number : '' }}
              </span>
            </template>
            <template #[`item.po_number`]="{ item }">
              {{ item.po_number }}
            </template>
            <template #[`item.g_code`]="{ item }">
              {{ item.g_code }}
            </template>
            <template #[`item.glass_thickness`]="{ item }">
              {{ item.glass_thickness }}
            </template>
            <template #[`item.total_glass`]="{ item }">
              {{ item.total_glass }}
            </template>
            <template #[`item.job_code`]="{ item }">
              {{ item.job && item.job.code ? item.job.code : '-' }}
            </template>
            <template #[`item.job_name`]="{ item }">
              {{ item.job && item.job.name ? item.job.name : '-' }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    groupedBatches: [],
    selectedIds: [],
    headers: [
      { text: 'Class', value: 'inventory_class_id' },
      { text: 'Job Code', value: 'job_code' },
      { text: 'Job Name', value: 'job_name' },
      { text: 'PO#', value: 'po_number' },
      { text: 'Batch#', value: 'batch_number' },
      { text: 'Glass Code', value: 'g_code' },
      { text: 'Glass Thickness', value: 'glass_thickness' },
      { text: 'Glass Qty', value: 'total_glass' },
      { text: 'Max Glass Qty', value: 'max_total_glass' },
    ],
    loading: false,
    inventoryClassId: null,
    poNumber: null,
    gCode: null,
    glassThickness: null,
    selectedBatchId: null,
    glassQuantity: 0,
  }),
  methods: {

    async getBatches() {
      this.loading = true;
      const statusIdNew = 1;
      const submitModel = {
        filters: {
          po_number: this.poNumber,
          inventory_class_id: this.inventoryClassId,
          g_code: this.gCode,
          glass_thickness: this.glassThickness,
          status_id: statusIdNew,
        },
      };
      const res = await this.$api.glassBatch.readBatches(submitModel);
      if (!res || !res.data) return;
      // get only records with quantity below 25
      this.groupedBatches = res.data.filter((x) => x.total_glass < 25);
      this.loading = false;
    },

    async submit() {
      if (!this.selectedBatchId) {
        this.$bus.$emit('showError', 'Batch is Required');
        return false;
      }

      this.$bus.$emit('loading');
      const submitModel = {
        batch_id: this.selectedBatchId,
        selected_ids: this.selectedIds,
      };
      const res = await this.$api.glassBatch.store(submitModel, 'store-insert');
      this.$bus.$emit('loading-stop');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      this.getBatches();
      return res;
    },

    open(inventoryClassId, poNumber, gCode, glassThickness, selectedIds, glassQuantity) {
      this.$refs.dialogBox.open();
      this.groupedBatches = [];
      this.inventoryClassId = inventoryClassId;
      this.poNumber = poNumber;
      this.gCode = gCode;
      this.glassThickness = glassThickness;
      this.selectedIds = selectedIds;
      this.glassQuantity = glassQuantity;
      this.getBatches();
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}
::v-deep .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
    color: rgba(0, 0, 0, 0.1) !important;
}
</style>
