<template>
  <the-dialog-box
    ref="dialogBox"
    :submit=" (isProducedState || isShippedState || isReceivedState || isShippedToSiteState) ? submitQr : submit"
    :secondary-submit="undoState"
    :submit-btn-text="submitBtnText()"
    :submit-btn-disabled="item.glass_packaging_slip_items.length < 1"
    :is-submit-btn="!isRecevedToSiteState"
    :is-secondary-btn="isReceivedState || isShippedState || isShippedToSiteState || isRecevedToSiteState"
    :secondary-btn-text="undoBtnText()"
    :full-screen="true"
    :persistent="true"
  >
    <!-- :secondary-btn-text="isReceivedState ? 'Undo Receive' : isShippedState ? 'Undo Ship' : ''" -->
    <template slot="header">
      <span>{{ headlineText() }} Packing Slip {{ item.slip_number ? `#${item.slip_number}` : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          v-if="item && item.status"
          class="text-center"
        >
          <h3 class="h3">
            Status
          </h3>
          <v-chip
            medium
            :color="item && item.status ? item.status.color : 'black'"
            text-color="white"
            class="mt-1"
          >
            {{ item && item.status ? item.status.name : '' }}
          </v-chip>
        </v-col>
        <v-col
          v-if="item && item.created_at"
          class="text-center"
        >
          <h3 class="h3">
            Created At
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.created_at : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.created_by_name"
          class="text-center"
        >
          <h3 class="h3">
            Created By
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.created_by_name : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.shipped_at"
          class="text-center"
        >
          <h3 class="h3">
            Shipped Date
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.shipped_at : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.shipped_by_name"
          class="text-center"
        >
          <h3 class="h3">
            Shipped By
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.shipped_by_name : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.received_at"
          class="text-center"
        >
          <h3 class="h3">
            Received Date
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.received_at : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.received_by_name"
          class="text-center"
        >
          <h3 class="h3">
            Received By
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.received_by_name : '' }}</span>
        </v-col>
        <v-col
          v-if="item && item.received_at"
          class="text-center"
        >
          <h3 class="h3">
            Received Location
          </h3>
          <span class="mt-1 font-weight-bold">{{ item ? item.location_name : '' }}</span>
        </v-col>
      </v-row>
      <v-row
        v-if="isShippedState"
        align="center"
      >
        <v-col
          cols="3"
          class="mt-4 pb-0"
        >
          <v-autocomplete
            v-model="location"
            :items="locations"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Location is required']"
            required
          >
            <template slot="label">
              Location<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row
        v-if="isReceivedState"
        align="center"
      >
        <v-col
          cols="3"
          class="mt-4 pb-0"
        >
          <v-autocomplete
            v-model="project"
            :items="projects"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Project is required']"
            required
          >
            <template slot="label">
              Project<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        v-else-if="!(isProducedState || isShippedState || isReceivedState || isShippedToSiteState || isRecevedToSiteState) && item.status_id !== glassPoStatusReceivedId"
        align="center"
      >
        <v-col
          cols="3"
          class="mt-4 pb-0"
        >
          <v-text-field
            v-model="glassItemQr"
            autofocus
            @keydown.enter.native.prevent
            @input="qrCodeValueChange"
          />
        </v-col>
        <v-col
          cols="auto"
          class="mt-4 pb-0"
        >
          <v-btn
            color="primary"
            outlined
            :disabled="!glassItemQr"
            @click="addGlassItem"
            @keyup.enter="addGlassItem"
          >
            Add Glass Item
          </v-btn>
        </v-col>
        <v-col
          v-if="item.type_id === typeIdSingleGlass"
          cols="2"
          class="mt-4 pb-0"
        >
          <v-autocomplete
            v-model="selectedPoNumber"
            label="PO#"
            :items="poNumbers"
            clearable
            :loading="loadingPo"
          />
        </v-col>
        <v-col
          v-if="item.type_id === typeIdSingleGlass"

          cols="auto"
          class="mt-4 pb-0"
        >
          <v-btn
            color="primary"
            outlined
            :disabled="!selectedPoNumber"
            @click="addSingleGlassItems(selectedPoNumber)"
          >
            Add All Single Glass Items
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col
          class="mt-4 pb-0"
          cols="auto"
        >
          <span style="font-size: 60px; font-weight: bold; color: #000;">Total QTY: {{ item.glass_packaging_slip_items.length }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="item.glass_packaging_slip_items"
              hide-default-footer
              :items-per-page="-1"
              dense
            >
              <template #[`item.class`]="{ item }">
                <v-chip
                  small
                  :color="item && item.glass_purchase_order_item && item.glass_purchase_order_item.class ? item.glass_purchase_order_item.class.color : 'black'"
                  text-color="white"
                >
                  {{ item && item.glass_purchase_order_item && item.glass_purchase_order_item.class ? item && item.glass_purchase_order_item && item.glass_purchase_order_item.class.name : '' }}
                </v-chip>
              </template>
              <template #[`item.po_number`]="{ item }">
                <span class="font-weight-bold">
                  {{ item && item.glass_purchase_order_item ? item.glass_purchase_order_item.po_number : '' }}
                </span>
              </template>
              <template #[`item.item_index`]="{ item }">
                <span>
                  {{ item && item.glass_purchase_order_item && item.glass_purchase_order_item.item_index ? item.glass_purchase_order_item.item_index : '' }}
                </span>
              </template>
              <template #[`item.batch_number`]="{ item }">
                <span
                  class="font-weight-bold"
                >
                  {{ item && item.glass_purchase_order_item && item.glass_purchase_order_item.batch ? item.glass_purchase_order_item.batch.batch_number : '' }}
                </span>
              </template>
              <template #[`item.job_code`]="{ item }">
                {{ item && item.glass_purchase_order_item ? item.glass_purchase_order_item.job_code : '-' }}
              </template>
              <template #[`item.g_code`]="{ item }">
                {{ item && item.glass_purchase_order_item ? item.glass_purchase_order_item.g_code : '-' }}
              </template>
              <template #[`item.code`]="{ item }">
                {{ item && item.glass_purchase_order_item ? item.glass_purchase_order_item.code : '-' }}
              </template>
              <template #[`item.glass_thickness`]="{ item }">
                {{ item && item.glass_purchase_order_item ? item.glass_purchase_order_item.glass_thickness : '-' }}
              </template>
              <template #[`item.tag_number`]="{ item }">
                {{ item && item.glass_purchase_order_item ? item.glass_purchase_order_item.tag_number : '-' }}
              </template>
              <template #[`item.width`]="{ item }">
                {{ item && item.glass_purchase_order_item ? (uomSwitch ? convertToFraction(item.glass_purchase_order_item.width) : item.glass_purchase_order_item.width) : '-' }}
              </template>
              <template #[`item.height`]="{ item }">
                {{ item && item.glass_purchase_order_item ? (uomSwitch ? convertToFraction(item.glass_purchase_order_item.height) : item.glass_purchase_order_item.height) : '-' }}
              </template>
              <template #[`item.quantity`]="{ item }">
                {{ item && item.glass_purchase_order_item ? item.glass_purchase_order_item.quantity : '-' }}
              </template>
              <template #[`item.produced_date`]="{ item }">
                {{ item && item.glass_purchase_order_item && item.glass_purchase_order_item.produced_date ? convertDateFormat(item.glass_purchase_order_item.produced_date) : '-' }}
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  small
                  :color="item && item.glass_purchase_order_item && item.glass_purchase_order_item.status ? item.glass_purchase_order_item.status.color : 'black'"
                  text-color="white"
                >
                  {{ item && item.glass_purchase_order_item && item.glass_purchase_order_item.status ? item.glass_purchase_order_item.status.name : '' }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="isRemoveBtn()"
                  class="ml-1 mt-1 mb-1"
                  color="error"
                  outlined
                  small
                  @click="removeGlassItem(item)"
                >
                  REMOVE
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col
          cols="auto"
          class="pb-0 pt-0"
        >
          <v-row align="center">
            <v-col class="pr-2 pt-6">
              <span :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}">Decimal</span>
            </v-col>
            <v-col class="pl-0">
              <v-switch
                v-model="uomSwitch"
                hide-details
                class="mt-0 pt-3"
                label="Fraction"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="auto"
          class="text-end pr-10"
        >
          <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '18px' }">Total Glass Item Qty: {{ item.glass_packaging_slip_items.length }}</span>
        </v-col>
      </v-row>
      <confirm-form ref="confirmForm" />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      glass_packaging_slip_id: null,
      glass_packaging_slip_items: [],
      type_id: 1,
    },
    glassItemQr: null,
    glassItems: [],
    location: null,
    locations: [],

    headers: [
      {
        text: 'Class', value: 'class', align: 'center',
      },
      { text: 'Job', value: 'job_code' },
      { text: 'PO#', value: 'po_number' },
      { text: 'Line#', value: 'item_index' },
      { text: 'Batch', value: 'batch_number' },
      { text: 'G Code', value: 'g_code' },
      { text: 'Code', value: 'code' },
      { text: 'Glass Thickness', value: 'glass_thickness' },
      { text: 'Tag#', value: 'tag_number' },
      { text: 'Width', value: 'width' },
      { text: 'Height', value: 'height' },
      { text: 'Qty', value: 'quantity' },
      {
        text: 'Status', value: 'status', align: 'center',
      },
      { text: 'Produced At', value: 'produced_date' },
      {
        text: 'Actions', value: 'actions', align: 'end',
      },
    ],
    loading: false,
    uomSwitch: true,
    isProducedState: false,
    isShippedState: false,
    isReceivedState: false,
    isShippedToSiteState: false,
    isRecevedToSiteState: false,
    glassPoStatusNewId: 1,
    glassPoStatusProducedId: 3,
    glassPoStatusShippedId: 6,
    glassPoStatusReceivedId: 7,
    glassPoStatusShippedToSite: 12,
    glassPoStatusReceivedToSite: 13,
    timeoutId: null,
    typeIdpackSlip: 1,
    typeIdSingleGlass: 2,
    typeIdRearrange: 3,
    glassTypeIdSingle: 3,
    poNumbers: [],
    loadingPo: false,
    selectedPoNumber: null,
    projects: [],
    project: null,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
    this.init();
  },
  methods: {

    async init() {
      await this.getLocations();
      this.getPoItems();
      this.getProjects();
    },

    async open(id = null, formState = null, typeId = 1) {
      this.$bus.$emit('loading');
      if (id) {
        this.setFormState(id, formState);
        await this.get(id);
      } else {
        this.item = JSON.parse(JSON.stringify(this.itemDto));
        this.item.type_id = typeId;
      }
      await this.$refs.dialogBox.open(); // for some reason need to add await, otherwise user's location won't be set in v-autocomplete by default
      this.location = this.user.location_id;
      this.$bus.$emit('loading');
    },

    async get(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.glassPackagingSlip.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || res.length === 0) {
        this.$bus.$emit('showError', 'Glass item not found');
        return;
      }
      const firstIndex = 0;
      this.item = res.data[firstIndex];
    },

    async getLocations() {
      const res = await this.$api.location.get();

      if (!res) return;
      this.locations = res;
    },

    async getProjects() {
      const submitModel = {
        filters: {},
      };

      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (!res || !res.data) {
        this.projects = [];
        return;
      }
      this.projects = res.data;
    },

    async getPoItems() {
      this.loadingPo = true;
      const res = await this.$api.glass.get(`po-numbers/${null}/${null}`);
      if (!res) {
        this.poNumbers = [];
        this.loadingPo = false;
        return;
      }
      this.poNumbers = res;
      this.loadingPo = false;
    },

    async submit() {
      this.$bus.$emit('loading');
      let res = false;

      if (this.item.id) {
        res = await this.$api.glassPackagingSlip.update(this.item);
      } else {
        res = await this.$api.glassPackagingSlip.store(this.item);
      }
      if (!res) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return false;
      }
      this.$bus.$emit('loading');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return true;
    },

    async submitQr() {
      let title = '';
      if (this.isProducedState) {
        title = 'Are you sure that you want to ship this packing slip?';
      } else if (this.isShippedState) {
        title = 'Are you sure that you want to receive this packing slip?';
      } else if (this.isReceivedState) {
        title = 'Are you sure that you want to ship this packing slip to site?';
      } else if (this.isShippedToSiteState) {
        title = 'Are you sure that you want to receive this packing slip on site?';
      }
      const confirm = await this.$refs.confirmForm.open({
        title,
      });
      if (!confirm) return false;
      this.$bus.$emit('loading');
      const statusId = this.getStatusId();
      const updateModel = {
        location_id: this.location,
        project_id: this.project,
        status_id: statusId,
        id: this.item.id,
      };
      const res = await this.$api.glassPackagingSlip.update(updateModel, 'status');
      if (!res) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return false;
      }
      this.$bus.$emit('loading');
      this.$bus.$emit('showSuccess');
      return true;
    },

    async undoState() {
      let title = '';
      if (this.isReceivedState) {
        title = 'Are you sure that you want to undo receive this packing slip?';
      } else if (this.isShippedState) {
        title = 'Are you sure that you want to undo ship this packing slip?';
      } else if (this.isShippedToSiteState) {
        title = 'Are you sure that you want to undo ship this packing slip to site?';
      } else if (this.isRecevedToSiteState) {
        title = 'Are you sure that you want to undo receive this packing slip on site?';
      }
      const confirm = await this.$refs.confirmForm.open({
        title,
      });
      if (!confirm) return false;
      this.$bus.$emit('loading');
      const statusId = this.getUndoStatusId();
      const updateModel = {
        status_id: statusId,
        id: this.item.id,
      };
      const res = await this.$api.glassPackagingSlip.update(updateModel, 'undo-status');
      if (!res) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return false;
      }
      this.$bus.$emit('loading');
      this.$bus.$emit('showSuccess');
      return true;
    },

    getStatusId() {
      if (this.isProducedState) {
        return this.glassPoStatusShippedId;
      }
      if (this.isShippedState) {
        return this.glassPoStatusReceivedId;
      }
      if (this.isReceivedState) {
        return this.glassPoStatusShippedToSite;
      }
      if (this.isShippedToSiteState) {
        return this.glassPoStatusReceivedToSite;
      }
      return null;
    },

    getUndoStatusId() {
      if (this.isReceivedState) {
        return this.glassPoStatusShippedId;
      }
      if (this.isShippedState) {
        return this.glassPoStatusProducedId;
      }
      if (this.isShippedToSiteState) {
        return this.glassPoStatusReceivedId;
      }
      if (this.isRecevedToSiteState) {
        return this.glassPoStatusShippedToSite;
      }
      return null;
    },

    setFormState(id = null, formState = null) {
      this.isProducedState = false;
      this.isShippedState = false;
      this.isReceivedState = false;
      this.isShippedToSiteState = false;
      this.isRecevedToSiteState = false;

      if (id && formState === this.glassPoStatusProducedId) {
        this.isProducedState = true;
      } else if (id && formState === this.glassPoStatusShippedId) {
        this.isShippedState = true;
      } else if (id && formState === this.glassPoStatusReceivedId) {
        this.isReceivedState = true;
      } else if (id && formState === this.glassPoStatusShippedToSite) {
        this.isShippedToSiteState = true;
      } else if (id && formState === this.glassPoStatusReceivedToSite) {
        this.isRecevedToSiteState = true;
      }
    },

    async addGlassItem() {
      this.$bus.$emit('loading');
      const poNumberAndItemIndexMap = this.extractPoNumberAndItemIndex(this.glassItemQr);
      if (!poNumberAndItemIndexMap) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'QR code not valid');
        this.glassItemQr = null;
        return;
      }
      const submitModel = {
        filters: {
          po_number: poNumberAndItemIndexMap.po_number,
          item_index: poNumberAndItemIndexMap.item_index,
          is_pack_slip_null: 1,
        },
      };
      if (this.item.type_id === this.typeIdSingleGlass) {
        submitModel.filters.glass_type_id = this.glassTypeIdSingle;
      }
      const res = await this.$api.glass.readGlass(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Glass item not found or already produced');
        this.glassItemQr = null;
        return;
      }
      if (this.isScanned(res.data[0])) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Glass item has already been scanned');
        this.glassItemQr = null;
        return;
      }
      if (this.item.type_id === this.typeIdRearrange && !res.data[0].packaging_slip) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Glass item is not under a packaging slip');
        this.glassItemQr = null;
        return;
      }
      if (res.data[0].packaging_slip && this.item.type_id === this.typeIdSingleGlass) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Glass item is already under a packaging slip');
        this.glassItemQr = null;
        return;
      }
      if (this.item.type_id !== this.typeIdRearrange && res.data[0].packaging_slip) {
        this.$bus.$emit('loading-stop');
        const confirm = await this.$refs.confirmForm.open({
          title: `This glass is under ${res.data[0].packaging_slip} slip - Would you like to move it?`,
        });
        if (!confirm) {
          this.glassItemQr = null;
          return;
        }
        const resDelete = this.$api.glassPackagingSlipItem.delete(`${res.data[0].packaging_slip}/${res.data[0].id}`);
        if (!resDelete) {
          this.$bus.$emit('showError');
          this.glassItemQr = null;
          return;
        }
      }

      this.item.glass_packaging_slip_items.push({ glass_purchase_order_item: res.data[0], key: res.data[0].id });
      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.glassItemQr = null;

      if (this.item.type_id === this.typeIdSingleGlass && this.item.glass_packaging_slip_items.length === 1) {
        const confirm = await this.$refs.confirmForm.open({
          title: `Do you want to add all Single Glass items from PO#${poNumberAndItemIndexMap.po_number}?`,
          confirmBtn: 'Yes',
          cancelBtn: 'No',
        });
        if (!confirm) return;
        this.addSingleGlassItems(poNumberAndItemIndexMap.po_number);
      }
    },

    async addSingleGlassItems(poNumber) {
      this.$bus.$emit('loading');
      const submitModel = {
        filters: {
          po_number: poNumber,
          is_pack_slip_null: 1,
          glass_type_id: this.glassTypeIdSingle,
          is_not_include_with_packing_slip: 1,
        },
      };
      const res = await this.$api.glass.readGlass(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Single Glass items not found or already produced');
        return;
      }
      res.data.forEach((item) => {
        if (!this.isScanned(item)) this.item.glass_packaging_slip_items.push({ glass_purchase_order_item: item, key: item.id });
      });
      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
    },

    extractPoNumberAndItemIndex(glassItemQr) {
      if (!glassItemQr) return false;
      const [poNumber, itemIndex] = glassItemQr.split('-');
      const poNumberAndItemIndexMap = {
        po_number: poNumber,
        item_index: parseInt(itemIndex, 10),
      };
      if (!poNumber || !itemIndex) return false;
      return poNumberAndItemIndexMap;
    },

    isScanned(item) {
      const index = this.item.glass_packaging_slip_items.findIndex((x) => x.glass_purchase_order_item.id === item.id);
      return index >= 0;
    },

    async removeGlassItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (!confirm) return;
      const itemIndex = this.item.glass_packaging_slip_items.findIndex((x) => x.glass_purchase_order_item.id === item.glass_purchase_order_item.id);
      if (itemIndex >= 0) {
        this.item.glass_packaging_slip_items.splice(itemIndex, 1);
      }
    },

    convertToFraction(decimal) {
      // check if  a decimal number has numbers after the decimal point
      const decimalString = decimal.toString();
      const decimalPointIndex = decimalString.indexOf('.');

      if (decimalPointIndex === -1) {
        return decimal;
      }
      //

      const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
      };

      // If the decimal is an exact match to any fraction, return the fraction directly
      if (decimal in fractions) {
        return fractions[decimal];
      }

      const wholeNumber = Math.floor(decimal);
      // eslint-disable-next-line no-param-reassign
      decimal -= wholeNumber; // Remove the whole number part

      let closestFraction = Object.keys(fractions)[0];

      // eslint-disable-next-line no-restricted-syntax
      for (const fraction of Object.keys(fractions)) {
        if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
          closestFraction = fraction;
        }
      }

      if (wholeNumber > 0) {
        return `${wholeNumber} - ${fractions[closestFraction]}"`;
      }
      return fractions[closestFraction];
    },

    convertDateFormat(inputDate) {
      // Parse the input date
      const date = new Date(inputDate);

      // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      return formattedDate;
    },

    qrCodeValueChange() {
      if (!this.glassItemQr) return;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.addGlassItem();
      }, 700);
    },

    headlineText() {
      if (this.isProducedState) {
        return 'Ship';
      }
      if (this.isShippedState) {
        return 'Receive';
      }
      if (this.isReceivedState) {
        return 'Ship To Site';
      }
      if (this.isShippedToSiteState) {
        return 'Receive On Site';
      }
      if (this.item.id && this.item.type_id === this.typeIdRearrange) {
        return 'Update Rearranged';
      }
      if (this.item.id) {
        return 'Update';
      }
      if (this.item.type_id === this.typeIdRearrange) {
        return 'Rearrange';
      }
      return 'Create';
    },

    submitBtnText() {
      if (this.isProducedState) {
        return 'Ship';
      }
      if (this.isShippedState) {
        return 'Receive';
      }
      if (this.isReceivedState) {
        return 'Ship To Site';
      }
      if (this.isShippedToSiteState) {
        return 'Receive On Site';
      }
      if (this.item.id) {
        return 'Update';
      }
      return 'Submit';
    },

    undoBtnText() {
      if (this.isReceivedState) {
        return 'Undo Receive';
      }
      if (this.isShippedState) {
        return 'Undo Ship';
      }
      if (this.isShippedToSiteState) {
        return 'Undo Ship To Site';
      }
      if (this.isRecevedToSiteState) {
        return 'Undo Receive On Site';
      }
      return '';
    },

    isRemoveBtn() {
      return !(this.isProducedState || this.isShippedState || this.isReceivedState) && this.item.status_id !== this.glassPoStatusReceivedId && this.item.type_id !== this.typeIdRearrange;
    },

  },
};
</script>
