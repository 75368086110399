import Api from './Api';

class MaterialPropertiesApi extends Api {
  constructor() {
    super('material_property', 'material_properties');
  }
}
const materialPropertiesApi = new MaterialPropertiesApi();

export default materialPropertiesApi;
