import axios from 'axios';
import Api from './Api';

class EquipmentItemsApi extends Api {
  constructor() {
    super('equipment_item', 'equipment/items');
  }

  async getFilteredList(filters) {
    let list = [];
    const errorText = 'Could not fetch filtered equipment list';

    try {
      list = await axios.post('/equipment/items/filter', filters);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getIdByCode(code) {
    let res = null;
    const errorText = 'Could not get Equipment Item';

    try {
      res = await axios.get(`/equipment/items/${code}`);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = 0;
    }

    return res;
  }

  async softDelete(itemId) {
    const errorText = 'Could not delete equipment item';
    let res = null;
    try {
      res = await axios.get(`/equipment/items/soft_delete/${itemId}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async getPhoto(data) {
    let res = null;
    const errorText = 'Could not fetch stock photo';

    try {
      res = await axios.post('/equipment/items/photo', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }

    return res;
  }
}
const equipmentItemsApi = new EquipmentItemsApi();

export default equipmentItemsApi;
