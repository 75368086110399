<template>
  <v-container>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :submit-btn-text="'Run'"
    >
      <template slot="header">
        <span>Run Report #R{{ recordId ? recordId : '' }}</span>
      </template>
      <template slot="body">
        <v-form

          ref="form"
        >
          <v-row v-if="records.length > 0">
            <template
              v-for="record in records"
            >
              <v-col
                :key="record.id"
                cols="12"
              >
                <template v-if="record.type_id === parameterTypeId_Date">
                  <v-menu
                    v-model="record.menu"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="record.value"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :rules="[(v) => !!v || `${record.label} is required`]"
                        v-on="on"
                      >
                        <template slot="label">
                          {{ record.label }}<RedAsterisk />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="record.value"
                      no-title
                      @input="record.menu = false"
                    />
                  </v-menu>
                </template>
                <template v-if="record.type_id === parameterTypeId_String">
                  <v-text-field
                    v-model="record.value"
                    :rules="[(v) => !!v || `${record.label} is required`]"
                  >
                    <template slot="label">
                      {{ record.label }}<RedAsterisk />
                    </template>
                  </v-text-field>
                </template>
                <template v-if="record.type_id === parameterTypeId_Number">
                  <v-text-field
                    v-model="record.value"
                    type="number"
                    :rules="[(v) => !!v || `${record.label} is required`]"
                  >
                    <template slot="label">
                      {{ record.label }}<RedAsterisk />
                    </template>
                  </v-text-field>
                </template>
                <template v-if="record.type_id === parameterTypeId_Boolean">
                  <v-radio-group v-model="record.value">
                    <template #label>
                      <span style="font-size: 1.2em; color: black;">{{ record.label }}<RedAsterisk /></span>
                    </template>
                    <v-radio
                      label="Yes"
                      value="true"
                    />
                    <v-radio
                      label="No"
                      value="false"
                    />
                  </v-radio-group>
                </template>
                <template v-if="record.type_id === parameterTypeId_Picklist">
                  <v-autocomplete
                    v-model="record.value"
                    :items="record.options"
                    :multiple="record.single_multiple == 2 ? true : false"
                    :rules="[(v) => !!v || `${record.label} is required`]"
                  >
                    <template slot="label">
                      {{ record.label }}<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </template>
                <template v-if="record.type_id === parameterTypeId_CurrentDate">
                  <v-text-field
                    v-model="record.value"
                    disabled
                  >
                    <template slot="label">
                      {{ record.label }}
                    </template>
                  </v-text-field>
                </template>
                <template v-if="record.type_id === parameterTypeId_CurrentTime">
                  <v-text-field
                    v-model="record.value"
                    disabled
                  >
                    <template slot="label">
                      {{ record.label }}
                    </template>
                  </v-text-field>
                </template>
              </v-col>
            </template>
          </v-row>
          <v-row v-else>
            <v-col>
              <h3>No Parameters Required</h3>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </the-dialog-box>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    // records = report parameters
    records: [],
    submitBtn: '',
    recordId: 0,
    title: '',
    parameterTypeId_Date: 1,
    parameterTypeId_String: 2,
    parameterTypeId_Number: 3,
    parameterTypeId_Boolean: 4,
    parameterTypeId_Picklist: 5,
    parameterTypeId_SysParmeter: 6,
    parameterTypeId_CurrentDate: 7,
    parameterTypeId_CurrentTime: 8,
    boolValues: [{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }],
  }),
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
    }),
  },
  created() {
  },
  methods: {

    async get() {
      if (!this.recordId) return;
      const submitModel = {
        filters: {
          id: this.recordId,
        },
      };
      this.records = await this.$api.reportParameter.read(submitModel);
    },

    async submit() {
      if (!await this.$refs.form.validate()) return false;
      if (!this.userId || !this.recordId) return false;

      this.$bus.$emit('loading');

      const submitModel = {
        template_id: this.recordId,
        user_id: this.userId,
        parameters: this.records,
      };

      const res = await this.$api.reportSchedule.run(submitModel);

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      this.$bus.$emit('showSuccess', 'Report executed successfully!');
      this.$emit('success', this.recordId);
      return true;
    },

    open(id) {
      if (!id) return;
      this.recordId = id;
      this.get();
      this.$refs.dialogBox.open();
    },
  },
};
</script>
