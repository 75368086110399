<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-select
          v-model="filterStatusId"
          :items="statuses"
          item-text="name"
          item-value="id"
          label="Status"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobs"
          hide-details="true"
          item-value="id"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-spacer />
      <v-col
        class="mt-4 pb-0 pr-0"
        cols="auto"
      >
        <v-menu
          v-model="optInEmailClassesMenu"
          transition="scale-transition"
          offset-y
          min-width="300"
          max-height="600"
          nudge-left="50"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="warning"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="optInEmailClassesMenu = !optInEmailClassesMenu"
            >
              Opt In Email
            </v-btn>
          </template>
          <v-card
            class="pa-5"
            style="border-radius: 7px;"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="10">
                    <span style="font-size: 22px !important; font-weight: bold;">Select Classes</span>
                  </v-col>
                  <v-col
                    cols="2"
                    class="text-end pr-1"
                  >
                    <v-btn
                      icon
                      :style="{ color: isDarkMode ? '' : '#000000' }"
                      @click="optInEmailClassesMenu = !optInEmailClassesMenu"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-for="(item, index) in optedInEmailClasses"
                      :key="index"
                      v-model="item.checked"
                      :label="item.name"
                    >
                      <template #label>
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer; text-transform: uppercase"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      @click="optInEmailClassesMenu = !optInEmailClassesMenu"
                    >
                      <span style="font-size: 14px !important;">Close</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      @click="updateOptInEmailClasses"
                    >
                      Update
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
      <v-col
        class="mt-4 pb-0"
        cols="auto"
      >
        <v-menu
          v-model="filterMenu"
          transition="scale-transition"
          offset-y
          min-width="350"
          nudge-left="50"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="filterMenu = !filterMenu"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-filter
              </v-icon>
              FILTERS
            </v-btn>
          </template>
          <v-card
            class="pa-5"
            style="border-radius: 7px;"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <span style="font-size: 22px !important; font-weight: bold;">Filters</span>
                  </v-col>
                  <v-col class="text-end pr-1">
                    <v-btn
                      icon
                      :style="{ color: isDarkMode ? '' : '#000000' }"
                      @click="filterMenu = !filterMenu"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterSupplierId"
                      label="Supplier"
                      :items="suppliers"
                      item-value="id"
                      clearable
                      hide-details="true"
                    >
                      <template #selection="{ item }">
                        <span>
                          <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                        </span>
                      </template>
                      <template #item="{ item }">
                        <span>
                          <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterColorId"
                      :items="colors"
                      item-text="code"
                      item-value="id"
                      :clearable="true"
                      hide-details="true"
                    >
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #item="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <template slot="label">
                        Color
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterLocationId"
                      :items="locations"
                      item-text="name"
                      item-value="id"
                      :clearable="true"
                      hide-details="true"
                    >
                      <template slot="label">
                        Location
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menuRequiredBy"
                      v-model="dateMenuRequiredBy"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterRequiredBy"
                          label="Required By Date"
                          readonly
                          v-bind="attrs"
                          append-icon="mdi-calendar"
                          v-on="on"
                          @change="$refs.menuRequiredBy.save(filterRequiredBy)"
                        />
                      </template>
                      <v-date-picker
                        v-model="filterRequiredBy"
                        no-title
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="filterRequiredBy = null"
                        >
                          Clear
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      @click="filterMenu = !filterMenu"
                    >
                      <span style="font-size: 14px !important;">Close</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      @click="clearAllFilters"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="poHeaders"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <span class="font-weight-bold">
                    <text-highlight :queries="search">
                      {{ item.po_number ? item.po_number : '-' }}
                    </text-highlight>
                  </span>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </td>
                <td>
                  <span>
                    <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                  </span>
                </td>

                <td>
                  <text-highlight :queries="search">
                    {{ item.supplier_code ? item.supplier_code : '' }}
                  </text-highlight>
                </td>

                <td>
                  <text-highlight :queries="search">
                    {{ item.color_code ? item.color_code : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.location_name ? item.location_name : '' }}
                  </text-highlight>
                </td>

                <td>
                  <text-highlight :queries="search">
                    {{ item.required_by ? item.required_by : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.total_quantity_items ? item.total_quantity_items : '0' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.created_at_short ? item.created_at_short : '' }}
                  </text-highlight>
                </td>
                <td class="text-center">
                  <v-chip
                    v-if="item.supply_to_paint_status"
                    small
                    :color="item && item.supply_to_paint_status ? item.supply_to_paint_status.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.supply_to_paint_status ? item.supply_to_paint_status.name : '' }}
                  </v-chip>
                </td>
                <td
                  class="text-end"
                  style="white-space: nowrap;"
                >
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="open(item.id)"
                  >
                    OPEN
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="success"
                    outlined
                    small
                    @click="runWebhook(item)"
                  >
                    PDF
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    items: [],
    jobs: [],
    poHeaders: [
      { text: 'PO#', value: 'po_number', width: '5%' },
      {
        text: 'Class', value: 'inventory_class', align: 'center', width: '5%',
      },
      { text: 'Job', value: 'job_full_name', width: '20%' },
      { text: 'Supplier', value: 'supplier_code', width: '10%' },
      { text: 'Color', value: 'color_code', width: '10%' },
      { text: 'Location', value: 'location_name', width: '10%' },
      { text: 'Required By', value: 'required_by', width: '10%' },
      { text: 'Total Qty', value: 'total_glass', width: '5%' },
      { text: 'Created At', value: 'created_at', width: '10%' },
      {
        text: 'Status', value: 'status', width: '10%', align: 'center',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      },
    ],
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    loading: false,
    inventoryClassId: null,
    filterJob: null,
    jobLoading: false,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    topSpaceHeight: 220,
    windowHeight: 1000,
    total: 0,
    loadingJobs: false,
    suppliers: [],
    filterSupplierId: null,
    statuses: [],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    colors: [],
    locations: [],
    optedInEmailClasses: [],
    inventoryPoTypes: [],
    filterColorId: null,
    filterLocationId: null,
    typeIdRepaint: 2,
    filterRequiredBy: null,
    dateMenuRequiredBy: false,
    filterStatusId: null,
    filterMenu: false,
    optInEmailClassesMenu: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      isDarkMode: 'isDarkMode',
      supplyToPaintState: (state) => state.supplyToPaintState,
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    inventoryClassId() {
      this.get();
      this.getJobs();
    },
    filterJob(value) {
      this.storeState('job_code', value);
      this.get();
    },
    filterSupplierId(value) {
      this.storeState('supplier_id', value);
      this.get();
    },
    filterStatusId(value) {
      this.storeState('supply_to_paint_status_id', value);
      this.get();
    },
    search() {
      this.get();
    },
    pagination() {
      // this.getGlass(); //getGlass is not a function
      this.get();
    },
    selectedTab() {
      this.get();
    },
    filterColorId(value) {
      this.storeState('color_id', value);
      this.get();
    },
    filterLocationId(value) {
      this.storeState('location_id', value);
      this.get();
    },
    filterPo(value) {
      this.storeState('po_number', value);
      this.get();
    },
    filterRequiredBy(value) {
      this.storeState('required_by', value);
      this.get();
    },

  },
  created() {
    if (this.supplyToPaintState) {
      if (this.supplyToPaintState.pagination) { this.pagination = this.supplyToPaintState.pagination; }
      if (this.supplyToPaintState.job_code) { this.filterJob = this.supplyToPaintState.job_code; }
      if (this.supplyToPaintState.supplier_id) { this.filterSupplierId = this.supplyToPaintState.supplier_id; }
      if (this.supplyToPaintState.color_id) { this.filterColorId = this.supplyToPaintState.color_id; }
      if (this.supplyToPaintState.location_id) { this.filterLocationId = this.supplyToPaintState.location_id; }
      if (this.supplyToPaintState.po_number) { this.filterPo = this.supplyToPaintState.po_number; }
      if (this.supplyToPaintState.required_by) { this.filterRequiredBy = this.supplyToPaintState.required_by; }
      if (this.supplyToPaintState.supply_to_paint_status_id) { this.filterStatusId = this.supplyToPaintState.supply_to_paint_status_id; }
    }
    this.init();
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.get();
    },

    storeState(property, value) {
      const state = this.supplyToPaintState;
      state[property] = value;
      this.$store.commit('storeSupplyToPaintState', state);
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          job_id: this.filterJob,
          supplier_id: this.filterSupplierId,
          is_archived: this.selectedTab,
          color_id: this.filterColorId,
          location_id: this.filterLocationId,
          po_number: this.filterPo,
          required_by: this.filterRequiredBy,
          type_id: this.typeIdRepaint,
          supply_to_paint_status_id: this.filterStatusId,
        },
        search: this.search,
        pagination: this.pagination,
      };
      const res = await this.$api.inventoryPo.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
      this.getJobs();
      this.getSuppliers();
      this.getStatuses();
      this.getColors();
      this.getLocations();
      this.getOptedInEmailClasses();
    },

    async getStatuses() {
      this.statuses = [];
      this.statuses.push({ id: 0, name: 'ALL', color: 'gray' });
      const res = await this.$api.supplyToPaintStatus.get();
      this.statuses.push(...res);
    },

    async getJobs() {
      if (this.inventoryClassId === null) return;
      this.loadingJobs = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };
      const res = await this.$api.job.readDataSecondary(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobs = [];
        this.loadingJobs = false;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },

    async getSuppliers() {
      this.suppliers = await this.$api.supplier.get();
    },

    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    async getOptedInEmailClasses() {
      const res = await this.$api.supplyToPaintEmail.get();
      if (!res || !res.length) {
        return;
      }
      this.optedInEmailClasses = res;
    },

    open(id) {
      this.$router.push(`/supply-to-paints/${id}`);
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
        this.pagination = pagination;
        this.storeState('pagination', pagination);
      }
    },

    async hide(isActive, poNumber, classId) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${isActive ? 'hide' : 'show'} this record?`,
      });
      if (!confirm) return;
      const res = await this.$api.supplyToPaint.get(`toggle/${isActive === 1 ? 0 : 1}/${classId}/${poNumber}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getPurchaseOrders();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    clearAllFilters() {
      this.filterSupplierId = null;
      this.filterColorId = null;
      this.filterLocationId = null;
      this.filterRequiredBy = null;
      this.filterJob = null;
      this.get();
    },

    async runWebhook(item) {
      this.$bus.$emit('loading');
      const typeIdRepaint = 2;
      const calculationTypeIdWeight = 2;
      const webhookModuleIdInventoryPo = 36;
      const webhookModuleIdInventoryPoRepaint = 37;
      const webhookModuleIdInventoryPoWeigth = 39;
      const webhookData = {};
      webhookData.subtotal = parseFloat(item.subtotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      webhookData.discount = parseFloat(item.discount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      webhookData.tax = parseFloat(item.tax).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      webhookData.total = parseFloat(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      if (item.type_id === typeIdRepaint && item.supplier && item.supplier.inventory_po_calculation_type_id === calculationTypeIdWeight) {
        webhookData.module_id = webhookModuleIdInventoryPoWeigth;
        webhookData.id = item.id;
        webhookData.is_show_price = 'False';
      } else if (item.type_id === typeIdRepaint) {
        webhookData.module_id = webhookModuleIdInventoryPoRepaint;
        webhookData.id = item.id;
        webhookData.is_show_price = 'False';
      } else {
        webhookData.module_id = webhookModuleIdInventoryPo;
        webhookData.po_number = item.po_number;
        webhookData.is_show_price = 'False';
      }

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.po_number}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async updateOptInEmailClasses() {
      const res = await this.$api.supplyToPaintEmail.store({ optedInClasses: this.optedInEmailClasses });
      if (!res) {
        this.$bus.$emit('showError', 'Unable to save classes to opt in');
        return;
      }

      this.$bus.$emit('showSuccess', 'Email selection updated');
      this.optInEmailClassesMenu = false;
    },
  },
};
</script>

<style scoped>
span
{
  font-size: 12px;
}
</style>
