var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',[_vm._v(" ACTIVE ")]),_c('v-tab',[_vm._v(" ARCHIVED ")])],1)],1),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openUserForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" new user ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"footer-props":_vm.usersTableFooterProps,"items-per-page":_vm.usersPerPage,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.username ? item.username : '-')+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(((item.first_name) + " " + (item.last_name)))+" ")])],1),_c('td',{staticClass:"pl-6"},[(item.roles && item.roles.length > 1)?_c('v-row',[_c('v-col',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("ROLES")])]}}],null,true)},_vm._l((item.roles),function(p){return _c('span',{key:p.id},[_vm._v(" "+_vm._s(p.name)),_c('br')])}),0)],1)],1):_c('v-row',_vm._l((item.roles),function(p,idx){return _c('v-col',{key:p.id,staticClass:"pr-0 pl-1",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(p.name)),(idx !== item.roles.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])])}),1)],1),(_vm.businessRulesGetBoolean('Inventory Classes'))?_c('td',[(item && (item.role_ids.includes(_vm.userRoleIdAdmin) || item.inventory_classes && item.inventory_classes.length == _vm.totalClasses - 1))?_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":"#000000"}},[_vm._v(" ALL ")])],1)],1):(item.inventory_classes && item.inventory_classes.length > 2)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("CLASSES")])]}}],null,true)},[_c('v-row',[_c('v-col',{staticClass:"text-center pt-6 pb-6"},_vm._l((item.inventory_classes),function(inventroyClass){return _c('v-row',{key:inventroyClass.id},[_c('v-col',{staticClass:"pr-1 pb-1 pt-1",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":inventroyClass ? inventroyClass.color : 'white'}},[_vm._v(" "+_vm._s(inventroyClass ? inventroyClass.name : '')+" ")])],1)],1)}),1)],1)],1):_c('v-row',_vm._l((item.inventory_classes),function(inventroyClass){return _c('v-col',{key:inventroyClass.id,staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":inventroyClass ? inventroyClass.color : 'white'}},[_vm._v(" "+_vm._s(inventroyClass ? inventroyClass.name : '')+" ")])],1)}),1)],1):_vm._e(),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.email ? item.email : '-')+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.phone_number ? item.phone_number : '-')+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.location ? item.location.name : '-')+" ")])],1),_c('td',[_vm._v(" "+_vm._s(item.updated_at)+" ")]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEditUserForm(item)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.changeUserStatus(item)}}},[_vm._v(" "+_vm._s(item.enabled ? "DISABLE" : "ENABLE")+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mt-1 mb-1",staticStyle:{"border-radius":"7px","width":"28px !important","height":"28px !important"},attrs:{"elevation":"0","fab":"","outlined":"","x-small":"","tile":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-3"},[_c('v-list-item',{on:{"click":function($event){return _vm.softDelete(item)}}},[_c('v-list-item-title',{style:("color: red; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pt-4"},[_vm._v(" DELETE ")])],1)],1)],1)],1)],1)],1)])]}}])})],1)],1)],1),_c('user-form',{ref:"userForm",attrs:{"user-p":_vm.user,"title":_vm.formTitle},on:{"user-creation":_vm.get}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }