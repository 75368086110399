import Api from './Api';

class PhysicalCountListApi extends Api {
  constructor() {
    super('physical_count_lists', 'physical_count_lists');
  }

  async activate(id) {
    return await this.get(`activate/${id}`);
  }

  async getActive() {
    return await this.get('active');
  }

  async delete(id) {
    return await this.softDelete(id);
  }

  async adjust(inventoryClassId) {
    return await this.get(`adjust/${inventoryClassId}`);
  }
}
const physicalCountListApi = new PhysicalCountListApi();

export default physicalCountListApi;
