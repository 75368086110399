<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :persistent="true"
    >
      <v-form
        v-if="!isworker"
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="errorAlert"
              type="error"
            >
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="job"
                    :items="jobs"
                    item-text="id"
                    item-value="id"
                    :rules="[(v) => !!v || 'Job is required']"
                    required
                  >
                    <template #selection="{ item }">
                      <span class="black--text">
                        Job {{ item.id }}: {{ item.address }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span
                        v-if="item.address"
                        class="black--text"
                      >
                        Job {{ item.id }}: {{ item.address }}
                      </span>
                      <span
                        v-else
                        class="black--text"
                      >
                        Job {{ item.id }}: {{ item.supervisor }}
                      </span>
                    </template>
                    <template slot="label">
                      Job<RedAsterisk />
                    </template>
                  </v-autocomplete>

                  <!-- Worker Drop Down Menu-->
                  <v-autocomplete
                    v-model="worker"
                    :items="workers"
                    item="id"
                    item-value="id"
                    :item-text="
                      (item) => item.first_name + ' ' + item.last_name
                    "
                    :rules="[(v) => !!v || 'Worker is required']"
                    required
                  >
                    <template #selection="{ item }">
                      <span class="black--text">
                        {{ item.first_name }} {{ item.last_name }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span class="black--text">
                        {{ item.first_name }} {{ item.last_name }}
                      </span>
                    </template>
                    <template slot="label">
                      Worker<RedAsterisk />
                    </template>

                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="toggle"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--<v-btn color="normal" class="mr-2" @click="toggle">
              {{btnTitle}}
            </v-btn>-->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="!isworker">
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

      <v-form
        v-if="isworker"
        ref="add"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="errorAlert"
              type="error"
            >
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col
                  v-if="isworker"
                  cols="6"
                >
                  <v-text-field
                    v-model="firstName"
                    :rules="[(v) => !!v || 'First Name is required']"
                    required
                  >
                    <template slot="label">
                      First Name<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="isworker"
                  cols="6"
                >
                  <v-text-field
                    v-model="lastName"
                    :rules="[(v) => !!v || 'Last Name is required']"
                    required
                  >
                    <template slot="label">
                      Last Name<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="isworker">
            <v-spacer />
            <v-btn
              color="normal"
              @click="toggle"
            >
              Back
            </v-btn>
            <v-btn
              color="primary"
              @click="submitWorker"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import UsersApi from '../../api/UsersApi';
import bomRmasApi from '../../api/BomRmasApi';
import Bus from '../../bus';

export default {
  components: {
    RedAsterisk,
  },
  data: () => ({
    type: '',
    dialog: false,
    dialogTitle: '',
    btnTitle: 'Add Worker',
    valid: false,
    isworker: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    foremen: [],
    jobs: [],
    workers: [],
    firstName: '',
    lastName: '',
    id: null,
    job: null,
    worker: null,
    client: null,
    tempItem: null,
  }),
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchJobs(), this.fetchWorkers()]);
    },
    openDialog(type, item = null) {
      this.type = type;
      this.dialog = true;
      this.itemTemp = item;

      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          this.dialogTitle = `Edit ${type.toUpperCase()} #${item.code}`;
          this.btnSubmitName = 'Update';
          // this.loadJobToForm(item);
        } else {
          this.dialogTitle = `New ${type.toUpperCase()}`;
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      });
    },
    toggle() {
      this.tempDialog = this.dialogTitle;
      this.isworker = !this.isworker;
      if (this.isworker == true) {
        this.btnTitle = 'Back';
        this.dialogTitle = 'Add New Worker';
      } else {
        this.btnTitle = 'Add Worker';
        if (this.itemTemp) {
          this.dialogTitle = `Edit ${this.type.toUpperCase()} #${
            this.itemTemp.code
          }`;
          this.btnSubmitName = 'Update';
          // this.loadJobToForm(item);
        } else {
          this.dialogTitle = `New ${this.type.toUpperCase()}`;
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      }
    },
    async submitWorker() {
      if (this.$refs.add.validate()) {
        const data = {
          first_name: this.firstName,
          last_name: this.lastName,
        };

        try {
          const res = await UsersApi.createSimple(data);
          if (res == 'Error! Name already taken') {
            Bus.$emit('alert', 'error', 'User Already Exists!');
          } else {
            Bus.$emit(
              'alert',
              'success',
              `User ${this.firstName} created successfully!`,
            );
            this.$emit('user-creation');
          }
          this.firstName = '';
          this.lastName = '';
          this.toggle();
          await this.fetchWorkers();
          this.worker = res.id;
        } catch (err) {
          Bus.$emit('alert', 'error', '');
        }
      }
    },
    /* check(first,last){
      string orker = this.workers.filter(test => test.first_name == first);
    }, */
    async submitForm() {
      if (this.$refs.form.validate()) {
        let isRma = false;
        if (this.type === 'rma') {
          isRma = true;
        }

        const data = {
          id: this.id,
          job_id: this.job,
          worker_id: this.worker,
          is_rma: isRma,
        };
        const res = await bomRmasApi.store(data);
        if (res) {
          Bus.$emit(
            'alert',
            'success',
            `${this.type.toUpperCase()} created successfully!`,
          );
          this.$emit('bom-creation', res);
        } else {
          Bus.$emit('alert', 'error', '');
        }
        this.dialog = false;
      }
    },

    // async loadJobToForm(item) {
    //   await this.$refs.form.reset();
    //   this.id = item.id;
    // },
    async clearForm() {
      if (this.isworker == true) {
        this.firstName = '';
        this.lastName = '';
      } else {
        await this.$refs.form.reset();
        this.id = null;
        this.job = null;
        this.worker = null;
      }
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    async fetchJobs() {
      const readModel = {
        filters:
        {
        },
      };
      this.jobs = await this.$api.job.read(readModel);
    },
    async fetchWorkers() {
      this.test = await UsersApi.getList();
      this.workers = this.test.filter((test) => test.roles.some((e) => e.name === 'Equipment Handler'));
      // currentUser.roles.some(e => e.name === 'Associate') ? true : false
      // this.workers = this.test.filter((test) => test.role_id == 5);
    },
  },
};
</script>
