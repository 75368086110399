<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.index != null ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.index != null ? 'Update' : 'Create' }} Charge</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0 pt-5"
        >
          <v-textarea
            v-model="item.description"
            label="Description"
            outlined
            dense
            :rules="[(v) => !!v || 'Description is required']"
          >
            <template slot="label">
              Description<RedAsterisk />
            </template>
          </v-textarea>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
          <v-text-field
            v-model="item.quantity"
            label="Quantity"
            dense
            type="number"
            :rules="[(v) => !!v || 'Quantity is required']"
          >
            <template slot="label">
              Quantity<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
          <v-text-field
            v-model="item.price"
            label="Price"
            dense
            type="number"
            :rules="[(v) => !!v || 'Price is required']"
          >
            <template slot="label">
              Price<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      index: null,
      description: null,
      quantity: null,
      price: null,
      sub_total: null,
      tax: null,
      total: null,
    },
    tax: null,

  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
    this.getTaxes();
  },
  methods: {

    async getTaxes() {
      const res = await this.$api.tax.get();
      if (!res) return;
      const firstIndex = 0;
      this.tax = res[firstIndex];
    },

    async submit() {
      this.$bus.$emit('showSuccess');
      this.item.price = parseFloat(this.item.price);
      this.item.sub_total = parseFloat(this.item.price * this.item.quantity);
      this.item.tax = parseFloat(this.item.sub_total * this.tax.rate);
      this.item.total = parseFloat(this.item.sub_total + this.item.tax);

      this.$emit('success', { ...this.item });
      return true;
    },

    open(item) {
      this.$refs.dialogBox.open();
      if (item) {
        this.item = { ...item };
      } else {
        this.item = { ...this.itemDto };
      }
    },
  },
};
</script>
