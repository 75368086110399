<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <!-- <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        class="text-end mt-4 pr-0 pb-0"
      >
        <v-btn
          color="purple"
          outlined
          @click="startSync()"
        >
          SYNC
        </v-btn>
      </v-col> -->
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="mt-4 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Auto Batch
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="jobHeaders"
            :items="jobs"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.code`]="{ item }">
              <span class="font-weight-bold"><text-highlight :queries="search">
                {{ item.code ? item.code : '-' }}
              </text-highlight></span>
            </template>
            <template #[`item.name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.name ? item.name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.total_po`]="{ item }">
              <text-highlight :queries="search">
                {{ item.total_po ? item.total_po : '0' }}
              </text-highlight>
            </template>
            <template #[`item.total_glass`]="{ item }">
              <text-highlight :queries="search">
                {{ item.total_glass ? item.total_glass : '0' }}
              </text-highlight>
            </template>
            <template #[`item.total_batch`]="{ item }">
              <text-highlight :queries="search">
                {{ item.total_batch ? item.total_batch : '0' }}
              </text-highlight>
            </template>
            <template #[`item.created_at`]="{ item }">
              <text-highlight :queries="search">
                {{ item.created_at ? item.created_at : '' }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="purple"
                    outlined
                    small
                    v-on="on"
                    @click="viewPoItems(item)"
                  >
                    PO
                  </v-btn>
                </template>
                <span>View POs</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="success"
                    outlined
                    small
                    v-on="on"
                    @click="viewGlass(item)"
                  >
                    Glass
                  </v-btn>
                </template>
                <span>View Glass</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
                    :disabled="!item.total_batch > 0"
                    class="ml-1 mt-1 mb-1"
                    color="orange"
                    outlined
                    small
                    v-on="on"
                    @click="viewBatch(item)"
                  >
                    Batch
                  </v-btn>
                </template>
                <span>View Batch</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-batch-form
      ref="batchForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import GlassBatchForm from '@/components/forms/glasses/GlassBatchForm';

export default {
  components: {
    GlassBatchForm,
  },
  data: () => ({
    poNumber: '',
    jobs: [],
    glassFiles: [],
    glassFileHeaders: [
      { text: 'Id', value: 'id' },
      { text: 'PO#', value: 'name' },
      { text: 'Created At', value: 'created_at' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '70%',
      },
    ],
    footer: {
      'items-per-page-options': [20, 100, 500, 1000, -1],
    },
    types: [],
    loading: false,
    inventoryClassId: null,
    pagination: {
      itemsPerPage: 20,
      page: 1,
    },
    total: 0,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
  }),
  computed: {
    jobHeaders() {
      const headers = [];
      headers.push({ text: 'Class', value: 'inventory_class', align: 'center' });
      headers.push({ text: 'Job Code', value: 'code' });
      headers.push({ text: 'Job name', value: 'name' });
      headers.push({ text: 'POs', value: 'total_po' });
      headers.push({ text: 'Glass Qty', value: 'total_glass' });
      headers.push({ text: 'Batches', value: 'total_batch' });
      headers.push({ text: 'Created At', value: 'created_at' });
      headers.push({
        text: 'Actions', value: 'actions', width: '30%', align: 'end',
      });

      return headers;
    },
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
  },
  watch: {
    inventoryClassId() {
      this.getJobs();
    },
    pagination() {
      this.getJobs();
    },
    search() {
      this.getJobs();
    },
  },
  created() {
    this.init();
    this.roles = this.user.roles.map((x) => x.id);
  },
  methods: {
    init() {
      this.getJobs();
    },
    async getJobs() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.jobs = [];
      this.total = 0;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.glass.readJobs(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.jobs = [];
        this.total = 0;
        return;
      }
      this.jobs = res.data;
      this.total = res.total;
    },

    viewPoItems(item) {
      this.$router.push({
        name: 'GlassPoView',
        params: {
          jobCode: item.code,
        },
      });
    },

    viewGlass(item) {
      this.$router.push({
        name: 'GlassItems',
        params: {
          jobCode: item.code,
        },
      });
    },

    viewBatch(item) {
      this.$router.push({
        name: 'GlassBatchView',
        params: {
          jobCode: item.code,
        },
      });
    },

    async startSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to do a Data Sync? This action will bring new Glass Items into ERP and can take up to 2 minutes!',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.glass.get('sync');
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getJobs();
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.glass.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.getGlassFiles();
    },

    open() {
      this.$refs.batchForm.open();
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.getJobs();
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },
  },
};
</script>
