<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    submit-btn-text="Cancel"
    :max-width="800"
  >
    <template slot="header">
      <span>Cancel Request #{{ request ? request.id : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col class="pt-4">
          <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '17px'}"> Submitted by <span style="font-weight: bold;">{{ request ? request.created_by_name : '' }}</span> , on {{ request ? request.created_at : '' }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Inventory Class: <v-chip
            small
            :color="request && request.class ? request.class.color : ''"
            text-color="white"
          >
            {{ request && request.class ? request.class.name : '' }}
          </v-chip></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Status: <v-chip
            small
            :color="request && request.status ? request.status.color : ''"
            text-color="white"
          >
            {{ request && request.status ? request.status.name : '' }}
          </v-chip></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Request Delivery Date: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.date : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row v-if="request">
        <v-col>
          <span style="font-size: 16px;">Job: </span><span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}"> <span style="font-weight: bold;">{{ request.job_code ? `${request.job_code}` : '' }}</span> - {{ request.job_name ? request.job_name : '' }} <span style="color: #1976D2;">{{ request.reference ? `; ${request.reference}` : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Floor: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.floor_number : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Total Quantity Required: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.total_windows : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Total Moving Quantity: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.quantity : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Truck Type: <v-chip
            small
            :color="request && request.truck_type ? request.truck_type.color : ''"
            text-color="white"
          >
            {{ request && request.truck_type ? request.truck_type.name : '' }}
          </v-chip></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Installer: <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px'}">{{ request ? request.installer_name : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row v-if="request && request.trucks">
        <v-col class="pb-0">
          <span style="font-size: 16px;">Trucks:</span>
        </v-col>
      </v-row>
      <v-row v-if="request && request.trucks && request.trucks.length > 0">
        <v-col>
          <v-row
            v-for="(truck, idx) in request.trucks"
            :key="truck.id"
          >
            <v-col
              class="pt-0 pb-0 pl-10"
              cols="auto"
            >
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">Truck {{ idx + 1 }}: </span>
            </v-col>
            <v-col
              class="pt-0 pb-0"
              cols="3"
            >
              <span :style="{color: truck.truck_color, fontSize: '16px'}">{{ truck ? truck.truck_name : '' }}</span>
            </v-col>
            <v-col
              class="pt-0 pb-0 pr-1"
              cols="auto"
            >
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">{{ truck ? truck.start_time_formatted : '' }} -</span>
            </v-col>
            <v-col
              class="pt-0 pb-0 pl-0 pr-1"
              cols="auto"
            >
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">{{ truck ? truck.end_time_formatted : '' }}</span>
            </v-col>
            <v-col
              v-if="(request.status.id === statusIdOnTruck || request.status.id === statusIdDelivered) && truck.quantity"
              class="pt-0 pb-0 pl-0"
              cols="auto"
            >
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">| Quantity: {{ truck ? truck.quantity : '' }} pcs.</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 pt-10">
          <v-textarea
            v-model="item.text"
            outlined
            rows="3"
            label="Cancel Reason"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-4">
          <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">Notes</span>
        </v-col>
      </v-row>
      <v-row v-if="request">
        <v-col>
          <v-row>
            <v-col class="pt-0">
              <!-- <v-virtual-scroll
                :items="request.notes"
                height="200"
                item-height="75"
                style="border: 1px solid rgba(0 ,0 ,0, 0.2); border-radius: 4px;"
              > -->
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <!-- <template #default="{ item }">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                        v-text="item.text"
                      />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.created_at" />
                      <v-list-item-action-text v-text="`${item.user.first_name ? item.user.first_name : ''} ${item.user.last_name ? item.user.last_name : ''}`" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />
                </template>
              </v-virtual-scroll> -->
              <v-card-text style="max-height: 300px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
                <v-list two-line>
                  <template v-for="(note, index) in request.notes">
                    <v-list-item :key="note.id">
                      <!-- Display each note -->
                      <v-list-item-content>
                        {{ note.text }}
                        <v-list-item-subtitle style="padding-top: 10px;">{{ note.created_at }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{ `${note.user.first_name ? note.user.first_name : ''} ${note.user.last_name ? note.user.last_name : ''}` }}</v-list-item-subtitle>
                      
                      </v-list-item-content>
                      <!-- <v-divider v-if="index !== schedule.notes.length - 1"/> -->
                    </v-list-item>
                    <v-divider
                      v-if="index < request.notes.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import {mapState} from 'vuex';
export default {
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      text: '',
    },
    request: null,
    statusIdOnTruck: 6,
    statusIdDelivered: 7,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    })
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async submit() {
      if (!this.item) return false;
      this.$bus.$emit('loading');
      const res = await this.$api.truckScheduleRequest.store(this.item, 'cancel-shipping');
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async getRequest(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.request = res.data[firstIndex];
    },

    open(id) {
      this.item = { ...this.itemDto };
      this.item.id = id;
      this.getRequest(id);
      this.$refs.dialogBox.open();
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>
