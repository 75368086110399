import axios from 'axios';
import Api from './Api';

class BomRmasApi extends Api {
  constructor() {
    super('bom', 'bom_rmas');
  }

  async getList(type, jobId = null) {
    let list = [];
    const errorText = `Could not fetch ${type} list`;

    try {
      list = await axios.get(`/bom_rmas/get_list/${type}/${jobId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async store(data) {
    let res;
    const errorText = 'Could not store BOM / RMA';

    try {
      res = await axios.post('/bom_rmas', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

  async release(bomId) {
    const errorText = 'Could not release bom';
    let entry = null;
    try {
      entry = await axios.get(`/bom_rmas/release/${bomId}`);
      if (entry.status === 200) {
        entry = entry.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      entry = null;
    }

    return entry;
  }

  async softDelete(bomId) {
    const errorText = 'Could not delete BOM / RMA';
    let res = null;
    try {
      res = await axios.get(`/bom_rmas/soft_delete/${bomId}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }
}
const bomRmasApi = new BomRmasApi();

export default bomRmasApi;
