<template>
  <v-container
    fluid
  >
    <v-row align="center">
      <v-col cols="1">
        <v-btn
          text
          @click="goBack"
        >
          <v-icon>
            fas fa-arrow-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="pb-0"
      >
        <span class="text-h5 font-weight-bold">{{ reportTemplate ? 'R' + reportTemplate.id + ' - ' + reportTemplate.template_title : '' }}</span>
      </v-col>
      <v-col
        cols="5"
        class="pb-0 text-end"
      >
        <v-btn
          class="mt-1 mb-1"
          color="success"
          outlined
          large
          @click="openRunForm(reportTemplate.id)"
        >
          RUN
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="recordHeaders"
            :items="records"
            :items-per-page="-1"
            dense
          >
            <template #[`item.status`]="{ item }">
              <v-chip
                small
                :color="item.status ? 'success' : 'error'"
                text-color="white"
              >
                {{ item.status ? 'Succeeded' : 'Failed' }}
              </v-chip>
            </template>
            <template #[`item.params`]="{ item }">
              <span
                v-for="p in item.params"
                :key="p.name"
              >
                <span class="font-weight-bold">{{ p.name }}</span> : {{ p.value }} <br>
              </span>
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ item.user ? item.user.first_name + ' ' + item.user.last_name : '' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="item.status && reportTemplate && reportTemplate.file_type_ids && reportTemplate.file_type_ids.includes(fileTypePdfId)"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openPdf(item)"
              >
                PDF
              </v-btn>
              <v-btn
                v-if="item.status && reportTemplate && reportTemplate.file_type_ids && reportTemplate.file_type_ids.includes(fileTypeXlsId)"
                class="ml-1 mt-1 mb-1"
                color="warning"
                outlined
                small
                @click="openXLS(item)"
              >
                XLS
              </v-btn>
              <v-btn
                v-if="item.status && reportTemplate && reportTemplate.file_type_ids && reportTemplate.file_type_ids.includes(fileTypeCSVId)"
                class="ml-1 mt-1 mb-1"
                color="warning"
                outlined
                small
                @click="openCSV(item)"
              >
                CSV
              </v-btn>
              <v-btn
                v-if="item.status && reportTemplate && reportTemplate.file_type_ids && reportTemplate.file_type_ids.includes(fileTypeTxtId)"
                class="ml-1 mt-1 mb-1"
                color="warning"
                outlined
                small
                @click="openTxt(item)"
              >
                TXT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteRecord(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <report-run-form
      ref="reportRunForm"
      @success="showSuccess"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';
import ReportRunForm from '@/components/forms/reports/ReportRunForm';
import ExcelHelper from '@/helpers/ExcelHelper';

export default {
  components: {
    ReportRunForm,
  },
  data: () => ({
    records: [],
    recordHeaders: [
      { text: 'Submitted On', value: 'created_at', width: '20%' },
      { text: 'Parameters', value: 'params' },
      {
        text: 'Status', value: 'status', align: 'center', width: '5%',
      },
      { text: 'Submitted By', value: 'created_by', width: '10%' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '30%',
      },
    ],
    templateId: 0,
    reportTemplate: null,
    recordsType: '',
    fileTypePdfId: 1,
    fileTypeXlsId: 2,
    fileTypeCSVId: 3,
    fileTypeTxtId: 4,
  }),
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
    }),
  },
  created() {
    this.templateId = this.$route.params.id;
    if (!this.templateId) return;
    this.init();
  },
  methods: {
    init() {
      this.get();
      this.getTemplate();
    },
    async get() {
      const readModel = {
        filters:
        {
          template_id: this.templateId,
        },
      };
      this.records = await this.$api.report.read(readModel);
    },

    async getTemplate() {
      const readModel = {
        filters: {
          id: this.templateId,
        },
      };
      const res = await this.$api.reportTemplate.read(readModel);
      if (!res || !res[0]) return;
      const firstIndex = 0;
      this.reportTemplate = res[firstIndex];
    },

    async deleteRecord(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;
      const res = await this.$api.report.delete(id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.get();
    },

    openRunForm(id) {
      this.$refs.reportRunForm.open(id);
    },

    showSuccess() {
      this.get();
    },

    async openPdf(item) {
      const pdf = await this.$api.report.download(item.id, 'pdf');
      if (!pdf) return;
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.report_title}_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
    },

    async openXLS(item) {
      const xls = await this.$api.report.download(item.id, 'xls');
      if (!xls) return;
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.report_title}_${now}`;
      const file = new Blob([xls], {
        type: 'application/vnd.ms-excel',
      });
      saveAs(file, fileName);
    },

    async openCSV(item) {
      const csv = await this.$api.report.download(item.id, 'csv');
      if (!csv) return;
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.report_title}_${now}`;
      const file = new Blob([csv], {
        type: 'text/csv',
      });
      saveAs(file, fileName);
    },

    async openTxt(item) {
      const xls = await this.$api.report.download(item.id, 'xls');
      if (!xls) return;
      const file = new File([xls], 'name');
      const result = await ExcelHelper.excelToCSV(file);
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${item.report_title}_${now}`;
      const blobFile = new Blob([result], {
        type: 'text/plain',
      });
      saveAs(blobFile, fileName);
    },

    goBack() {
      this.$router.push('/reports');
    },

  },

};

</script>
