<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Create'"
  >
    <template slot="header">
      <span>Confirmation</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <span style="color: black; font-size: 18px;">Are you sure that you want to FINISH GLAZING?</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="productionBacklog"
            rows="3"
            type="number"
            label="Production Backlog"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    itemId: null,
    productionBacklog: null,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
  },
  methods: {

    async submit() {
      const submitItem = {
        id: this.itemId,
        production_backlog: this.productionBacklog,
      };
      const res = await this.$api.productionSchedule.store(submitItem, 'last-stage');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id, productionBacklog) {
      this.itemId = id;
      this.productionBacklog = productionBacklog;
      this.$refs.dialogBox.open();
    },
  },
};
</script>
