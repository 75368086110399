import Api from '@/api/Api';

class ReportScheduleApi extends Api {
  constructor() {
    super('reports/schedules', 'reports/schedules');
  }

  async run(data) {
    const res = await this.store(data, 'run');
    return res;
  }
}
const reportScheduleApi = new ReportScheduleApi();

export default reportScheduleApi;
