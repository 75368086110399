var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-state":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),((_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdAssociate)))?_c('v-col',{staticClass:"mt-4 pb-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.open}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Auto Batch ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.jobHeaders,"items":_vm.jobs,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":"","server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.inventory_class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.code ? item.code : '-')+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name ? item.name : '-')+" ")])]}},{key:"item.total_po",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.total_po ? item.total_po : '0')+" ")])]}},{key:"item.total_glass",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.total_glass ? item.total_glass : '0')+" ")])]}},{key:"item.total_batch",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.total_batch ? item.total_batch : '0')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at ? item.created_at : '')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.viewPoItems(item)}}},on),[_vm._v(" PO ")])]}}],null,true)},[_c('span',[_vm._v("View POs")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"success","outlined":"","small":""},on:{"click":function($event){return _vm.viewGlass(item)}}},on),[_vm._v(" Glass ")])]}}],null,true)},[_c('span',[_vm._v("View Glass")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdAssociate)))?_c('v-btn',_vm._g({staticClass:"ml-1 mt-1 mb-1",attrs:{"disabled":!item.total_batch > 0,"color":"orange","outlined":"","small":""},on:{"click":function($event){return _vm.viewBatch(item)}}},on),[_vm._v(" Batch ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("View Batch")])])]}}],null,true)})],1)],1)],1),_c('glass-batch-form',{ref:"batchForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }