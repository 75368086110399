import Api from './Api';

class BusinessRulesApi extends Api {
  constructor() {
    super('business_rule', 'business_rules');
  }
}
const businessRulesApi = new BusinessRulesApi();

export default businessRulesApi;
