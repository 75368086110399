import Api from './Api';

class EquipmentCategoriesApi extends Api {
  constructor() {
    super('equipment_category', 'equipment/categories');
  }
}
const equipmentCategoriesApi = new EquipmentCategoriesApi();

export default equipmentCategoriesApi;
