<template>
  <the-dialog-box
    ref="dialogBox"
    fullScreen
    :is-submit-btn="false"
    persistent
  >
    <template slot="header">
      <span>Returned Windows</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              group-by="finished_good.production_schedule.job.project.name"
              hide-default-footer
              :items-per-page="-1"
              fixed-header
              dense
            >
              <template #[`group.header`]="{ group, headers, toggle, isOpen }">
                  <td :colspan="headers.length">
                      <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                          <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                          <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      {{ group }}
                  </td>
              </template>
              <template
                v-for="h in headers"
                #[`header.${h.value}`]="{ header }"
              >
                <v-tooltip
                  v-if="h.fullText"
                  :key="h.value"
                  top
                >
                  <template #activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>{{ header.fullText }}</span>
                </v-tooltip>
                <span
                  v-else
                  :key="h.value"
                >
                  {{ h.text }}
                </span>
              </template>
              <template #[`item.finished_good.tag_id`]="{ item }">
                <span style="font-weight: bold">
                  {{ item && item.finished_good && item.finished_good.tag_id ? item.finished_good.tag_id : '-' }}
                </span>
              </template>
              <template #[`item.serial_number`]="{ item }">
                {{ item && item.finished_good && item.finished_good.tag_id ? extractSerialNumber(item) : '-' }}
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item && item.status"
                  small
                  :color="item.status.color ? item.status.color : '#000000'"
                  text-color="white"
                >
                  {{ item.status.name }}
                </v-chip>
              </template>
              <template #[`item.finished_good.sqft`]="{ item }">
                {{ item && item.finished_good && item.finished_good.sqft ? `${item.finished_good.sqft.toFixed(2)} sqft` : '-' }}
              </template>
              <template #[`item.finished_good.width`]="{ item }">
                {{ item && item.finished_good && item.finished_good.width ? `${displayInchesWithFraction(item.finished_good.width)}` : '-' }}
              </template>
              <template #[`item.finished_good.height`]="{ item }">
                {{ item && item.finished_good && item.finished_good.height ? `${displayInchesWithFraction(item.finished_good.height)}` : '-' }}
              </template>
              <template #[`item.finished_good.weight`]="{ item }">
                {{ item && item.finished_good ? `${parseFloat(getWeight(item)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}` : '-' }}
              </template>
            </v-data-table>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  data: () => ({
    items: [],

    headers: [
        { text: 'Tag ID', value: 'finished_good.tag_id', sortable: false },
        { text: 'Tag #', fullText: 'Window Number on Drawing', value: 'finished_good.tag_number', sortable: false },
        { text: 'WO Serial#', fullText: 'Line Serial Number on Work Order', value: 'serial_number', sortable: false },
        { text: 'Work Order', value: 'finished_good.work_order', sortable: false },
        { text: 'Reference', value: 'finished_good.floor_number', sortable: false },
        { text: 'Square Feet', value: 'finished_good.sqft', align: 'end', width: '10%', sortable: false},
        { text: 'Width (inches)', value: 'finished_good.width', align: 'end', width: '10%', sortable: false},
        { text: 'Height (inches)', value: 'finished_good.height', align: 'end', width: '10%', sortable: false},
        { text: 'Weight (kg)', value: 'finished_good.weight', align: 'end', width: '10%', sortable: false},
        { text: 'Return Reason', value: 'return_reason', align: 'end', width: '10%', sortable: false},
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false
        },
    ],

    finishedGoodStatusIdReturned: 12,
    windowHeight: 1000,
    topSpaceHeight: 64,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    })
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {

    async init() {
    },

    async get() {
      const submitModel = {
        filters: {
            status_id: this.finishedGoodStatusIdReturned,
        },
      };
      const res = await this.$api.billOfLadingItem.read(submitModel);
      if (!res || !res.data || res.data.length === null) {
        this.$bus.$emit('showError', 'Unable to retrieve returned windows...');
        return;
      }
      this.items = res.data;
    },

    async open() {
      this.$refs.dialogBox.open();
      await this.get();
    },

    extractSerialNumber(item) {
      const tagId = item.finished_good.tag_id;
      const serialNumber = tagId.match(/(\d+)$/);
      return serialNumber ? serialNumber[0] : '-';
    },

    displayInchesWithFraction(inches) {
      // Convert height to the raw number with rounding
      const raw = Math.round(inches / 0.0625) * 0.0625;

      // Extract the whole number part
      const wholeNumber = Math.floor(raw);

      // Calculate the decimal part
      const decInput = parseFloat((raw - wholeNumber).toFixed(4));

      // Initialize numerator and denominator
      let numerator = 1;
      let denominator = 1;

      // Calculate the fraction
      let decimalFraction = numerator / denominator;
      while (decimalFraction !== decInput) {
        if (decimalFraction < decInput) {
          numerator += 1;
        } else {
          denominator += 1;
          numerator = Math.floor(decInput * denominator);
        }
        decimalFraction = numerator / denominator;
      }

      // Create the final output string
      let result = '';
      if (wholeNumber > 0) {
        result += `${wholeNumber}"`;
      }

      if (numerator > 0) {
        result += `-${numerator}/${denominator}''`;
      }

      return result;
    },

    getWeight(item) {
      return item.finished_good.sqft * item.finished_good.type.weight_ratio;
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },
  },
};
</script>
