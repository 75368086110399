var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-4",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.statusesFilter,"label":"Status","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : ''}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.generateExcel()}}},[_vm._v(" EXCEL ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function () {
            _vm.$refs.equipmentItemForm.openDialog();
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" new equipment ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.itemsHeaders,"items":_vm.items,"footer-props":_vm.itemsTableFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.code",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.category",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.category_name)+" ")])]}},{key:"item.manufacturer",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.manufacturer_name)+" ")])]}},{key:"item.model",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.model)+" ")])]}},{key:"item.serial_number",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.serial_number ? item.serial_number : '-')+" ")])]}},{key:"item.item_reference",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.item_reference ? item.item_reference : '-')+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-select',{staticClass:"select-status",staticStyle:{"max-width":"140px","border-radius":"20px","font-size":"12px","color":"white !important"},attrs:{"items":_vm.statuses,"hide-details":"","item-text":"name","item-value":"id","dense":"","solo":"","background-color":item.is_active ? '#4CAF50' : '#F44336'},on:{"change":function($event){return _vm.changeStatus(item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":item ? item.color : 'white'}},[_c('span',{staticStyle:{"white-space":"nowrap","color":"#FFFFFF"}},[_vm._v(_vm._s(item.name))])])]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"color":"white","white-space":"nowrap"}},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.updated_by",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.updated_by_name)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.open(item, true)}}},[_vm._v(" CLONE ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.open(item)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('EquipmentItemForm',{ref:"equipmentItemForm",on:{"item-creation-success":_vm.success,"item-creation-fail":_vm.fail}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }