import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
class PartPickList extends Api {
  constructor() {
    super('part-pick-lists', 'part-pick-lists');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const partPickList = new PartPickList();

export default partPickList;
