<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="700"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>Client Address for {{ clientName }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="3"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.type_id"
            :items="types"
            item-text="name"
            item-value="id"
            label="Type"
            autocomplete="preventAutocomplete"
          >
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                v-if="item"
                small
                :color="item.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.name }}
              </v-chip>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <v-chip
                v-if="item"
                small
                :color="item.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <v-text-field
            v-model="item.unit"
            label="Unit"
            :rules="[(v) => (v === null || v.length <= 45) || 'Max 45 characters']"
            counter="45"
            required
            autocomplete="preventAutocomplete"
          />
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <v-text-field
            v-model="item.building"
            :rules="[(v) => !!v || 'Building is required', (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Building<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="5"
          class="pb-0"
        >
          <v-text-field
            v-model="item.street"
            :rules="[(v) => !!v || 'Street is required', (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Street<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.city"
            :rules="[(v) => !!v || 'City is required', (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              City<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.region_id"
            :items="regions"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Province/State is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Province/State<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.postal_code"
            label="Postal Code"
            :rules="[(v) => (v === null || v.length <= 10) || 'Max 10 characters']"
            counter="10"
            autocomplete="preventAutocomplete"
          />
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.country_id"
            :items="countries"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Country is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Country<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  data: () => ({
    item: null,
    itemId: 0,
    itemDto: {
      id: 0,
      type_id: null,
      unit: null,
      building: null,
      street: '',
      city: '',
      region_id: 9,
      country_id: 1,
      postal_code: '',
      client_id: null,
    },
    countries: [],
    regions: [],
    clientName: '',
    types: [],
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    init() {
      this.getCountries();
      this.getRegions();
      this.getTypes();
    },

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.address.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getCountries() {
      this.countries = await this.$api.country.get();
    },
    async getRegions() {
      this.regions = await this.$api.region.get();
    },
    async getTypes() {
      this.types = await this.$api.addressType.get();
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.address.update(this.item);
      } else {
        const userId = this.$store.getters.user.id;
        if (userId) this.item.created_by = userId;
        res = await this.$api.address.store(this.item);
      }

      // eslint-disable-next-line eqeqeq
      if (res == 'exist') {
        this.$bus.$emit('showError', 'This Address already exists');
        return false;
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(clientId, clientName, addressId) {
      this.clientName = clientName || '';
      if (addressId) {
        this.item.id = addressId;
        this.get(addressId);
      } else {
        this.item = { ...this.itemDto };
      }
      this.item.client_id = clientId;
      this.$refs.dialogBox.open();
      this.getCountries();
      this.getRegions();
      this.getTypes();
    },

    formatPhoneNumber() {
      this.item.phone_number = FormatHelpers.formatPhoneNumber(this.item.phone_number);
    },
  },
};
</script>
