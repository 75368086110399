var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Parse File")]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-inst":false,"is-glass":false,"is-panels":false,"is-sid":false,"is-add-all":false,"is-rail-part":false,"is-railings":false,"is-commercial":false,"is-vwd-glass":false,"is-required":false},model:{value:(_vm.item.inventory_class_id),callback:function ($$v) {_vm.$set(_vm.item, "inventory_class_id", $$v)},expression:"item.inventory_class_id"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"rules":[function (v) { return (!!v || 'Work Order is required'); }]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}},[_c('template',{slot:"label"},[_vm._v(" Work Order"),_c('RedAsterisk')],1)],2)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","outlined":""},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-0 mb-0",attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user? item.user.first_name + ' ' + item.user.last_name : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.files)?_c('v-row',[_c('v-spacer'),_vm._l((item.files),function(file){return _c('v-col',{key:file.id,staticClass:"pt-5 pb-5 pl-0 pr-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.download(file)}}},[_vm._v(" "+_vm._s(file.type ? file.type.name : '')+" ")])],1)}),_c('v-col',{staticClass:"pt-5 pb-5 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")])],1)],2):_vm._e()]}}],null,true)})],1)],1)],1),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }