<template>
  <v-container>
    <scanner-qr-form
      :qr-type="'bill-of-lading-qr'"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ScannerQrForm from '@/components/elements/ScannerQrForm';

export default {
  components: {
    ScannerQrForm,
  },
  props: {

  },
  data: () => ({

  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.init();
  },
  methods: {
    init() {

    },
  },
};
</script>

<style scoped>

</style>