<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      :persistent="true"
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <!-- <span class="headline">Adjust Inventory with Physical Count "{{ physicalCount ? physicalCount.name: '' }}"</span> -->
            <span>Are you sure that you want to Adjust ERP Inventory based on Physical Count "{{ physicalCount ? physicalCount.name: '' }}"?</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span style="color: red; font-size: 16px;">IMPORTANT! It can take up to 15 MINUTES to finish the adjustment!</span>
                <br><br>
                <span style="color: red; font-size: 16px;">IMPORTANT! This action will create RM Supply / Return Sheet (Physical Count) where all adjusted records can be found.</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="4"
                class="pb-0"
              >
                <v-select
                  v-model="inventoryClassId"
                  :items="inventoryClasses"
                  item-text="name"
                  item-value="id"
                >
                  <template #label>
                    Class<RedAsterisk />
                  </template>
                  <template #item="{ item }">
                    <v-chip
                      class="mt-1 mb-1"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item ? item.color : 'white'"
                    >
                      {{ item ? item.name : '' }}
                    </v-chip>
                  </template>
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #selection="{ item }">
                    <v-chip
                      class="mt-1 mb-1"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item ? item.color : 'white'"
                    >
                      {{ item ? item.name : '' }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              Adjust
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>

export default {

  data: () => ({
    dialog: false,
    physicalCount: null,
    inventoryClassId: 0,
    inventoryClasses: [{ name: 'All', id: 0 }],
  }),
  created() {
  },
  methods: {
    open(physicalCount) {
      this.inventoryClasses = [{ name: 'All', id: 0 }];
      this.inventoryClassId = 0;
      this.physicalCount = null;
      this.dialog = true;
      this.physicalCount = physicalCount;
      this.inventoryClasses.push(...this.physicalCount.classes);
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.$bus.$emit('loading');
        const response = await this.$api.physicalCountList.adjust(this.inventoryClassId);
        this.$bus.$emit('loading');
        if (!response) {
          this.$bus.$emit('showError');
          return;
        }
        this.$bus.$emit('showSuccess');
        this.dialog = false;
      }
    },
  },
};
</script>
