<template>
  <v-container>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :submit-btn-text="'Submit'"
      :max-width="1200"
      :max-height="1000"
    >
      <template slot="header">
        <span class="headline">QR Receiving</span>
      </template>
      <template slot="body">
        <v-container
          style="font-size: 1.3em !important;"
          fluid
          class="mt-3"
        >
          <v-row>
            <v-col
              class="pa-0"
              cols="1"
              md="1"
              sm="2"
              style="text-align:right"
            >
              <v-row>
                <v-col class="text-center">
                  <h5 class="h5">
                    QR Code
                  </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-chip
                    class="mt-1 mb-1"
                    text-color="white"
                    style="cursor: pointer;"
                    :color="inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.color : 'white'"
                  >
                    <span style="font-size: 20px; color: #fff !important;">{{ inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.name : '-' }}</span>
                  </v-chip>
                </v-col>
              </v-row>
              <v-row v-if="inventoryTag && inventoryTag.supplier_label">
                <v-col class="ml-3 mb-5 text-center">
                  <h6 class="mb-3">
                    Supplier Label
                  </h6>
                  <a

                    @click="openItemShowImageForm(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
                  >
                    <v-img
                      :src="convertedPhoto(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
                      class="profile-photo-mini ml-3"
                    />
                  </a>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="pa-0"
              cols="1"
              md="1"
              sm="2"
              style="text-align:center"
            >
              <div
                style="border-left: 1px solid;
            height: 90%; margin-left:30px;"
              />
            </v-col>
            <v-col
              class="pa-0"
              cols="3"
              md="3"
              sm="8"
            >
              <v-img
                :src="convertedPhoto(inventoryTag && inventoryTag.item ? inventoryTag.item.photo : '', inventoryTag && inventoryTag.item ? inventoryTag.item.mime_type : '')"
                contain
                height="100%"
                width="100%"
                class="profile-photo"
              />
            </v-col>
            <v-col
              :class="`pa-0 text-center pt-${$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '0' : '10'}`"
              md="7"
              sm="12"
            >
              <v-row>
                <v-col
                  cols="4"
                  class="text-center"
                >
                  <h5 class="h5">
                    Inventory Item
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.item_full_name : '' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass && inventoryTag.item.classes.id !== inventoryClassIdSID && inventoryTag.item.classes.id !== inventoryClassIdPatioDoor && inventoryTag.item.classes.id !== inventoryClassIdEquipment)"
                  cols="4"
                  class="text-center"
                >
                  <h5 class="h5">
                    Color
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.color_code : '' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass && inventoryTag.item.classes.id !== inventoryClassIdPanels && inventoryTag.item.classes.id !== inventoryClassIdSID && inventoryTag.item.classes.id !== inventoryClassIdPatioDoor && inventoryTag.item.classes.id !== inventoryClassIdEquipment)"
                  cols="2"
                  class="text-center"
                >
                  <h5 class="h5">
                    Length
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.length_name : '' }}</span>
                </v-col>
                <v-col
                  cols="2"
                  class="text-center"
                >
                  <h5 class="h5">
                    UOM
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.uom_name : '' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag != null && inventoryTag.door_size"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Size
                  </h5>
                  <span class="value"> {{ inventoryTag.door_size.name }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag != null && inventoryTag.door_hinge"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Hinge
                  </h5>
                  <span class="value"> {{ inventoryTag.door_hinge.name }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag != null && inventoryTag.door_swing"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Swing
                  </h5>
                  <span class="value"> {{ inventoryTag.door_swing.name }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag != null && inventoryTag.door_lite"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Lite
                  </h5>
                  <span class="value"> {{ inventoryTag.door_lite.name }}</span>
                </v-col>
                <v-col
                  cols="3"
                  class="text-center"
                >
                  <h5 class="h5">
                    Location
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.location_name : '' }}</span>
                </v-col>
                <v-col
                  cols="3"
                  class="text-center"
                >
                  <h5 class="h5">
                    Shelf
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.inventory_location_name : '' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.is_painted != null"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Paint
                  </h5>
                  <span class="value"> {{ inventoryTag.is_painted == 1 ? 'Painted' : 'Non-Painted' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Door Height
                  </h5>
                  <span class="value"> {{ inventoryTag.height }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Door Width
                  </h5>
                  <span class="value"> {{ inventoryTag.width }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.color && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdSID || inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Int Color
                  </h5>
                  <span class="value"> {{ inventoryTag.color.code }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.ex_color && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdSID || inventoryTag.item.classes.id === inventoryClassIdPatioDoor)"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Ext Color
                  </h5>
                  <span class="value"> {{ inventoryTag.ex_color.code }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
                  cols="3"
                  class="text-center"
                >
                  <h5 class="h5">
                    Vendor Batch#
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.batch_number : '' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.is_fritted != null && inventoryTag.item && inventoryTag.item.classes && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Fritted
                  </h5>
                  <span class="value"> {{ inventoryTag.is_fritted == 1 ? 'Fritted' : 'Non-Fritted' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.door_screen && inventoryTag.item && inventoryTag.item.classes && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                  cols="2"
                  style="text-align:center"
                >
                  <h5 class="h5">
                    Screen
                  </h5>
                  <span class="value"> {{ inventoryTag.door_screen.name }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
                  cols="3"
                  class="text-center"
                >
                  <h5 class="h5">
                    Production Date
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.production_date_simple : '' }}</span>
                </v-col>
                <v-col
                  cols="3"
                  class="text-center"
                >
                  <h5 class="h5">
                    Qty on Hand
                  </h5> <span> {{ inventoryTag ? inventoryTag.quantity_on_hand : '' }}</span>
                </v-col>
                <v-col
                  v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes && inventoryTag.item.classes.id !== inventoryClassIdPatioDoor && inventoryTag.item.classes.id !== inventoryClassIdEquipment"
                  cols="3"
                  class="text-center"
                >
                  <h5 class="h5">
                    Project
                  </h5> <span> {{ inventoryTag && inventoryTag.project_name ? inventoryTag.project_name : '' }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="4"
                  class="pt-12 text-center"
                >
                  <v-text-field
                    v-model="quantity"
                    :rules="[(v) => !!v || 'Quantity Received is required']"
                    type="number"
                  >
                    <template slot="label">
                      Quantity Received<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  class="pt-12 text-center"
                >
                  <!-- <v-text-field
                    v-model="poNumber"
                    :rules="[(v) => !!v || 'PO# is required']"
                  >
                    <template slot="label">
                      PO#<RedAsterisk />
                    </template>
                  </v-text-field> -->
                  <v-autocomplete
                    v-model="poNumber"
                    :items="inventoryPos"
                    :rules="[(v) => !!v || 'PO# is required']"
                  >
                    <template slot="label">
                      PO#<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  class="pt-12 text-center"
                >
                  <v-file-input
                    v-model="supplierLabel"
                    accept="image/*"
                    capture="user"
                    label="Supplier Label"
                    item-text="name"
                    item-value="id"
                  >
                    <template slot="label">
                      Supplier Label<RedAsterisk />
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </the-dialog-box>
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';

export default {
  components: {
    InventoryItemShowImageForm,
  },
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    supplierLabel: null,
    inventoryPos: [],
    poNumber: '',
    quantity: 0,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdEquipment: 13,
    inventoryClassIdPatioDoor: 14,
    inventoryIdSlab: 1100,
    inventoryIdSweep: 1101,
    inventoryIdInsert: 1102,
  }),
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
    }),
  },
  created() {
  },
  methods: {

    open(qrCode) {
      this.$refs.dialogBox.open();
      this.supplierLabel = null;
      this.poNumber = '';
      this.quantity = 0;
      this.qrCode = qrCode;
      this.getInventoryTag();
      this.getInventoryPos();
    },

    async getInventoryTag() {
      if (!this.qrCode) return;

      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
          is_qty_on_hand_o: 0,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data || !res.data[0]) {
        return;
      }

      const firstIndex = 0;
      this.inventoryTag = res.data[firstIndex];
    },

    async getInventoryPos() {
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryTag && this.inventoryTag.item && this.inventoryTag.item.classes ? this.inventoryTag.item.classes.id : null,
        },
      };
      const res = await this.$api.inventoryPo.read(submitModel, null, 'read-simple');
      if (!res) {
        return;
      }
      this.inventoryPos = ['Not Applicable', ...res];
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },

    async submit() {
      if (!this.inventoryTag) return false;

      this.$bus.$emit('loading');
      const data = new FormData();

      // Qr Code is treated as inv tag for now
      if (this.qrCode) {
        data.append('inventory_tag', this.qrCode);
      }

      if (this.quantity) {
        data.append('quantity_added', this.quantity);
      }

      if (this.userId) {
        data.append('created_by', this.userId);
      }

      if (this.poNumber) {
        data.append('po_number', this.poNumber);
      }

      if (this.supplierLabel) {
        data.append('supplier_label', this.supplierLabel);
      }

      const res = await this.$api.inventoryTag.qrReceiving(data);
      this.$bus.$emit('loading-stop');
      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      this.$bus.$emit('showSuccess');
      return true;
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },
  },
};
</script>

<style scoped>

</style>
