<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="request && request.slot_id ? 'Update' : 'Submit'"
    :max-width="1200"
    :persistent="true"
  >
    <template slot="header">
      <v-row>
        <v-col cols="6">
          <span v-if="isProductionSchedule">Add Truck Schedule</span>
          <span v-else>{{ request && request.slot_id ? 'Update' : 'Approve' }} Truck Request {{ request ? `#${request.id}` : '' }}</span>
        </v-col>
        <v-col cols="6">
          <span>Assign Truck(s)</span>
        </v-col>
      </v-row>
    </template>
    <template slot="body">
      <v-row v-if="request && !isProductionSchedule">
        <v-col class="pt-4">
          <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '17px'}"> Submitted by <span style="font-weight: bold;">{{ request.created_by_name }}</span> , on {{ request.created_at }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          style="border-right: 1px solid rgba(0, 0, 0, 0.42)"
        >
          <v-row>
            <v-col
              cols="6"
              class="pb-0"
            >
              <inventory-class-select-element
                v-model="request.inventory_class_id"
                :is-add-all="false"
                :is-required="true"
                @on-change="inventoryClassChange"
              />
            </v-col>
            <v-col
              class="pb-0"
              cols="6"
            >
              <v-menu
                ref="menu"
                v-model="dateMenu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="request.date"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="$refs.menu.save(request.date)"
                  >
                    <template slot="label">
                      Request Delivery Date<RedAsterisk />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="request.date"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="request.date = null"
                  >
                    Clear
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col class="pb-0">
                  <v-autocomplete
                    v-model="request.job_id"
                    :items="jobs"
                    :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
                    item-value="id"
                    clearable
                    :rules="[(v) => !!v || 'Job is required']"
                    :loading="loadingJobs"
                    :disabled="loadingJobs && (jobs && jobs.length === 0)"
                  >
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #selection="{ item }">
                      <v-chip
                        v-if="item.sub_class"
                        class="mt-1 mb-1 mr-3"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item && item.sub_class ? item.sub_class.color : 'white'"
                      >
                        {{ item && item.sub_class ? item.sub_class.name : '' }}
                      </v-chip>
                      <span>
                        <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                      </span>
                    </template>
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #item="{ item }">
                      <v-chip
                        v-if="item.sub_class"
                        class="mt-1 mb-1 mr-3"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item && item.sub_class ? item.sub_class.color : 'white'"
                      >
                        {{ item && item.sub_class ? item.sub_class.name : '' }}
                      </v-chip>
                      <span>
                        <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                      </span>
                    </template>
                    <template slot="label">
                      Job<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <!-- <v-row v-else>
                <v-col class="pb-0">
                  <v-text-field
                    v-model="request.job_name"
                    :rules="[(v) => !!v || 'Job is required']"
                  >
                    <template slot="label">
                      Job<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row> -->
            </v-col>
            <!-- <v-col
              class="pb-0"
              cols="3"
            >
              <v-text-field
                v-model="request.floor_number"
                :rules="[(v) => !!v || 'Floor# is required']"
              >
                <template slot="label">
                  Floor#<RedAsterisk />
                </template>
              </v-text-field>
            </v-col> -->
          </v-row>

          <v-row
            align="center"
            justify="center"
          >
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-text-field
                v-model="request.quantity"
                :rules="[(v) => !!v || 'Shipping Quantity is required']"
                type="number"
              >
                <template slot="label">
                  Shipping Quantity<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">Total Quantity Required: <span style="font-weight: bold;">{{ getJobTotalWindows() }}</span></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-autocomplete
                v-model="request.installer_id"
                :items="installers"
                item-text="full_name"
                item-value="id"
                clearable
                :rules="[(v) => !!v || 'Installer is required']"
              >
                <template slot="label">
                  Installer<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!request.slot_id">
            <v-col class="pb-0">
              <v-textarea
                v-model="request.comment"
                outlined
                rows="3"
                label="Note"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-2">
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">Notes</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="pt-0">
                  <!-- <v-virtual-scroll
                    :items="request.notes"
                    height="200"
                    item-height="75"
                    style="border: 1px solid rgba(0 ,0 ,0, 0.2); border-radius: 4px;"
                  > -->
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <!-- <template #default="{ item }">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="wrap-text"
                            v-text="item.text"
                          />
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text v-text="item.created_at" />
                          <v-list-item-action-text v-text="`${item.user.first_name ? item.user.first_name : ''} ${item.user.last_name ? item.user.last_name : ''}`" />
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider />
                    </template>
                  </v-virtual-scroll> -->
                  <v-card-text style="max-height: 300px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
                      <v-list two-line>
                        <template v-for="(note, index) in request.notes">
                          <v-list-item :key="note.id">
                            <!-- Display each note -->
                            <v-list-item-content>
                              {{ note.text }}
                              <v-list-item-subtitle style="padding-top: 10px;">{{ note.created_at }}</v-list-item-subtitle>
                              <v-list-item-subtitle>{{ `${note.user.first_name ? note.user.first_name : ''} ${note.user.last_name ? note.user.last_name : ''}` }}</v-list-item-subtitle>
                            
                            </v-list-item-content>
                            <!-- <v-divider v-if="index !== schedule.notes.length - 1"/> -->
                          </v-list-item>
                          <v-divider
                            v-if="index < request.notes.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col
              class="pb-0"
              cols="4"
            >
              <v-select
                v-model="filterTruckType"
                label="Status"
                :items="truckTypes"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Truck Type is required']"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <template slot="label">
                  Truck Type<RedAsterisk />
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="!isAddTruck"
              class="text-end pt-6"
            >
              <v-btn
                color="primary"
                outlined
                @click="openTruckForm()"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  fas fa-plus
                </v-icon>
                New Truck
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!isAddTruck || (request && request.trucks && request.trucks.length > 0)">
            <v-col />
          </v-row>
          <v-row v-if="request && request.trucks && request.trucks.length > 0">
            <v-col>
              <v-row
                v-for="(requestTruck, idx) in request.trucks"
                :key="idx"
              >
                <v-col cols="auto">
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">Truck {{ idx + 1 }}: </span>
                </v-col>
                <v-col
                  class=""
                  cols="auto"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px', fontWeight: 'bold'}">{{ requestTruck && requestTruck.truck ? requestTruck.truck.name : '' }}</span>
                </v-col>
                <v-col
                  cols="auto"
                  class="pr-0"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">{{ convert24to12(requestTruck.start_time) }} -</span>
                </v-col>
                <v-col
                  cols="auto"
                  class="pl-1"
                >
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px'}">{{ convert24to12(requestTruck.end_time) }}</span>
                </v-col>
                <v-spacer />
                <v-col
                  v-if="requestTruck.id !== scheduleTruck.id"
                  cols="auto"
                  class="pt-2 pr-0"
                >
                  <v-btn
                    color="primary"
                    outlined
                    small
                    @click="openTruckForm(idx)"
                  >
                    EDIT
                  </v-btn>
                </v-col>
                <v-col
                  v-if="requestTruck.id !== scheduleTruck.id"
                  cols="auto"
                  class="pt-2"
                >
                  <v-btn
                    color="error"
                    outlined
                    small
                    @click="removeTruck(requestTruck.id)"
                  >
                    DELETE
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="isAddTruck">
            <v-col>
              <v-form
                ref="truckForm"
              >
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="scheduleTruck.truck_id"
                      :items="trucks"
                      item-value="id"
                      item-text="name"
                      clearable
                      :rules="[(v) => !!v || 'Truck is required']"
                    >
                      <template slot="label">
                        Truck<RedAsterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pb-0"
                  >
                    <v-text-field
                      v-model="scheduleTruck.start_time"
                      type="time"
                      :rules="[(v) => !!v || 'Start Time is required']"
                    >
                      <template slot="label">
                        Start Time<RedAsterisk />
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pb-0"
                  >
                    <v-text-field
                      v-model="scheduleTruck.end_time"
                      type="time"
                      :rules="[(v) => !!v || 'End Time is required']"
                    >
                      <template slot="label">
                        End Time<RedAsterisk />
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pt-0 pr-1"
                  >
                    <v-btn
                      color="error"
                      outlined
                      @click="cancelTruck()"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="pt-0"
                  >
                    <v-btn
                      color="primary"
                      outlined
                      @click="scheduleTruck.id ? updateTruck() : addTruck()"
                    >
                      {{ scheduleTruck.id ? 'Update' : 'Add' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    request: null,
    requestDto:
    {
      id: null,
      date: new Date().toISOString().slice(0, 10),
      truck_type_id: null,
      job_id: null,
      job_name: null,
      inventory_class_id: null,
      comment: null,
      quantity: null,
      trucks: [],
      slot_id: null,
      installer_id: null,
    },
    slot: null,
    slotDto: {
      id: 0,
      date: new Date().toISOString().slice(0, 10),
    },
    scheduleTruck: null,
    truckDto: {
      id: null,
      truck: null,
      truck_id: null,
      start_time: '06:00',
      end_time: '08:00',
      is_temp: true,
    },
    dateMenu: false,
    jobs: [],
    truckTypes: [],
    trucks: [],
    inventoryClassId4500: 1,
    inventoryClassId4800: 2,
    roles: [],
    installers: [],
    isAddTruck: false,
    deleteTruckIds: [],
    filterTruckType: null,
    isProductionSchedule: false,
    isInit: false,

    loadingJobs: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    // 'request.inventory_class_id': function (value, oldValue) {
    //   if (!value || !oldValue || value === oldValue) return;
    //   if (!value || oldValue === undefined || (this.request.id && !oldValue) || value === oldValue) return;
    //   this.request.job_name = null;
    //   this.request.job_id = null;
    //   if (value === this.inventoryClassId4500 || value === this.inventoryClassId4800) {
    //     this.getJobs();
    //   }
    // },
    // eslint-disable-next-line func-names
    filterTruckType(value) {
      if (!value) return;
      this.getTrucks();
    },

  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.request = { ...this.requestDto };
    this.slot = { ...this.slotDto };
  },
  methods: {

    init() {
      this.getJobs();
      this.getTruckTypes();
      this.getTrucks();
      this.getInstallers();
    },

    async getRequest(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.request = res.data[firstIndex];
      if (this.request.slot_id) {
        this.getSlot(this.request.slot_id);
      }

      if (!this.request.trucks || this.request.trucks.length === 0) {
        this.isAddTruck = true;
      }
      this.filterTruckType = this.request.truck_type_id;
    },

    async getSlot(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleSlot.read(submitModel);
      const firstIndex = 0;
      if (!res || !res[firstIndex]) return;
      this.slot = res[firstIndex];
    },

    async getJobs() {
      this.loadingJobs = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.request.inventory_class_id,
          is_truck_schedule: 1,
          is_sub_class: 1,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      const res = await this.$api.job.readDataSecondary(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        this.loadingJobs = false;
        return;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },

    async getTruckTypes() {
      const submitModel = {
        filters: {
        },
      };
      this.truckTypes = await this.$api.truckScheduleTruckType.read(submitModel);
    },

    async getTrucks() {
      const submitModel = {
        filters: {
          truck_type_id: this.filterTruckType,
        },
      };
      this.trucks = await this.$api.truckScheduleTruck.read(submitModel);
    },

    async getInstallers() {
      const roleIdInstaller = 17;
      const submitModel = {
        filters: {
          role_id: roleIdInstaller,
        },
      };
      this.installers = await this.$api.user.read(submitModel);
    },

    async submit() {
      if (this.request.total_windows && parseInt(this.request.total_windows, 10) < parseInt(this.request.quantity, 10)) {
        this.$bus.$emit('showError', 'Shipping Quantity cannot be greater than Total Quantity Required');
        return false;
      }

      if (!this.request.trucks || this.request.trucks.length === 0) {
        this.$bus.$emit('showError', 'Trucks are required!');
        return false;
      }

      this.$bus.$emit('loading');
      if (this.isProductionSchedule) {
        const submitModel = {
          date: this.request.date,
          truck_type_id: this.filterTruckType,
          job_id: this.request.job_id,
          inventory_class_id: this.request.inventory_class_id,
          comment: this.request.comment,
          installer_id: this.request.installer_id,
        };
        this.request.truck_type_id = this.filterTruckType;

        const res = await this.$api.truckScheduleRequest.store(submitModel);
        if (!res) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        this.request.id = res;
        if (this.request.trucks) {
          this.request.trucks.forEach((x) => {
            // eslint-disable-next-line no-param-reassign
            x.request_id = this.request.id;
          });
        }
      }
      // On Request / Slot update
      if (this.request.slot_id) {
        this.slot.date = this.request.date;

        // Update Request
        const slotRes = await this.$api.truckScheduleSlot.update(this.slot);
        if (!slotRes) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        // Update Request

        // Update Slot
        const requestRes = await this.$api.truckScheduleRequest.update(this.request);

        if (!requestRes) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        // Update Slot

        // Update trucks
        const truckRequests = [];
        this.request.trucks.forEach((x) => {
          if (x.is_temp) {
            truckRequests.push(this.$api.truckScheduleTruckRequestTruck.store(x));
          } else {
            truckRequests.push(this.$api.truckScheduleTruckRequestTruck.update(x));
          }
        });

        const res = await Promise.all(truckRequests);
        if (res.includes(false)) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        // Update trucks

        // Delete removed trucks
        const deleteTruckRequests = [];
        this.deleteTruckIds.forEach((x) => {
          deleteTruckRequests.push(this.$api.truckScheduleTruckRequestTruck.delete(x));
        });
        const deleteRes = await Promise.all(deleteTruckRequests);
        if (deleteRes.includes(false)) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        // Delete removed trucks

        // On Request / Slot Approve
      } else {
        // Create Slot
        this.slot.date = this.request.date;
        const slotResId = await this.$api.truckScheduleSlot.store(this.slot);
        if (!slotResId) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        // Create Slot

        // Update Request
        this.request.slot_id = slotResId;
        const requestRes = await this.$api.truckScheduleRequest.store(this.request, 'approve');

        if (!requestRes) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        // Update Request

        // Create trucks
        const truckRequests = [];
        this.request.trucks.forEach((x) => {
          truckRequests.push(this.$api.truckScheduleTruckRequestTruck.store(x));
        });

        const res = await Promise.all(truckRequests);
        if (res.includes(false)) {
          this.$bus.$emit('loading');
          this.$bus.$emit('showError');
          return false;
        }
        // Create trucks
      }

      this.$bus.$emit('loading');

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return true;
    },

    async open(id) {
      this.request = { ...this.requestDto };
      this.slot = { ...this.slotDto };
      this.scheduleTruck = { ...this.truckDto };
      this.deleteTruckIds = [];
      this.isAddTruck = false;
      this.getRequest(id);
      this.$refs.dialogBox.open();
      this.init();
    },

    async openProductionSchedule(item) {
      this.request = { ...this.requestDto };
      this.slot = { ...this.slotDto };
      this.scheduleTruck = { ...this.truckDto };
      this.deleteTruckIds = [];
      this.isAddTruck = false;
      this.getJobs();
      this.isProductionSchedule = true;
      this.$refs.dialogBox.open();
      this.request.inventory_class_id = item.inventory_class_id;
      this.request.job_id = item.job_id;
      this.request.date = item.planned_ship_date;
      this.request.quantity = item.total_release_qty;
    },

    openTruckForm(idx) {
      this.scheduleTruck = { ...this.truckDto };
      if (idx !== undefined) {
        const truck = this.request.trucks.find((x, i) => i === idx);
        if (!truck) return;
        this.scheduleTruck = { ...truck };
      }
      this.isAddTruck = true;
    },

    async addTruck() {
      if (!await this.$refs.truckForm.validate()) return;

      const truck = this.trucks.find((x) => x.id === this.scheduleTruck.truck_id);
      if (!truck) return;
      // Id - Temporary Id

      this.scheduleTruck.truck = truck;
      this.scheduleTruck.request_id = this.request.id;

      const checkRes = await this.checkTruck();
      if (!checkRes) return;

      this.scheduleTruck.id = Math.floor(Date.now() / 1000);
      this.request.trucks.push(this.scheduleTruck);
      this.scheduleTruck = { ...this.truckDto };
      this.isAddTruck = false;
    },

    async updateTruck() {
      if (!await this.$refs.truckForm.validate()) return;

      const checkRes = await this.checkTruck();
      if (!checkRes) return;

      const editTruckIdx = this.request.trucks.findIndex((x) => x.id === this.scheduleTruck.id);
      if (editTruckIdx === -1) return;
      const truck = this.trucks.find((x) => x.id === this.scheduleTruck.truck_id);
      if (!truck) return;
      this.scheduleTruck.truck = truck;
      this.$set(this.request.trucks, editTruckIdx, this.scheduleTruck);
      this.scheduleTruck = { ...this.truckDto };
      this.isAddTruck = false;
    },

    async removeTruck(id) {
      const removeTruckIdx = this.request.trucks.findIndex((x) => x.id === id);
      if (removeTruckIdx === -1) return;

      // delete if not temporary
      if (!this.request.trucks[removeTruckIdx].is_temp) {
        this.deleteTruckIds.push(id);
      }
      this.request.trucks.splice(removeTruckIdx, 1);
    },

    cancelTruck() {
      this.scheduleTruck = { ...this.truckDto };
      this.isAddTruck = false;
    },

    getJobTotalWindows() {
      if (!this.request.job_id) return '';
      const job = this.jobs.find((x) => x.id === this.request.job_id);
      if (!job || !job.total_windows) return '';
      return job.total_windows;
    },

    convert24to12(time24) {
      // Check correct time format and split into components
      let time = time24.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time24];

      if (time.length > 1) { // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    },

    async checkTruck() {
      const startTime = Math.floor(new Date(`${this.request.date} ${this.scheduleTruck.start_time}:00`).getTime() / 1000);
      const endTime = Math.floor(new Date(`${this.request.date} ${this.scheduleTruck.end_time}:00`).getTime() / 1000);
      if (startTime > endTime) {
        this.$bus.$emit('showError', 'Start Time cannot be greater than End Time');
        return false;
      }

      if (startTime === endTime) {
        this.$bus.$emit('showError', 'Start Time cannot be equal to End Time');
        return false;
      }

      // check if truck with the same date and time in the list
      const checkTruck = this.request.trucks.find((x) => {
        const truckStartTime = Math.floor(new Date(`${this.request.date} ${x.start_time}:00`).getTime() / 1000);
        const truckEndTime = Math.floor(new Date(`${this.request.date} ${x.end_time}:00`).getTime() / 1000);
        if (x.truck_id === this.scheduleTruck.truck_id && startTime >= truckStartTime && endTime <= truckEndTime) return true;
        return false;
      });
      if (checkTruck) {
        this.$bus.$emit('showError', `Truck '${this.scheduleTruck.truck.name}' is scheduled for this Date and Time!`);
        return false;
      }
      // check if truck with the same date and time in the list

      // check if truck for this time and date is available
      const checkAvailable = await this.$api.truckScheduleTruckRequestTruck.store(this.scheduleTruck, 'availability');
      if (!checkAvailable) {
        this.$bus.$emit('showError', `Truck '${this.scheduleTruck.truck.name}' is scheduled for this Date and Time!`);
        return false;
      }
      // check if truck for this time and date is available

      return true;
    },

    inventoryClassChange(inventoryClassId) {
      if(inventoryClassId == null) return;
      this.request.job_name = null;
      this.request.job_id = null;
      if (inventoryClassId === this.inventoryClassId4500 || inventoryClassId === this.inventoryClassId4800) {
        this.getJobs();
      }
    }
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>
