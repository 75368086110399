<template>
  <v-container
    class="mt-3"
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-row>
          <v-col
            cols="auto"
            class="pb-0 pr-0"
          >
            <v-btn
              outlined
              color="primary"
              class="mr-4"
              @click="setToday"
            >
              Today
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pt-4 pr-0 pl-0"
          >
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="type"
          :items="types"
          dense
          hide-details
          label="Type"
          item-value="type"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-calendar
            ref="calendar"
            v-model="focusDay"
            :weekdays="weekday"
            :type="type"
            :event-more="false"
            :events="events"
            event-overlap-mode="column"
            :event-overlap-threshold="30"
            :event-height="30"
            :event-color="getEventColor"
            color="primary"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="openViewForm"
          >
            <template #day-header="dayHeader">
              <v-row :class="isHolidayOrWeekend(dayHeader.date) || isHolidayOrWeekend(dayHeader.weekday) ? 'pink-background' : ''">
                <v-col
                  class="pa-5"
                  style="display: flex;flex-direction: column;justify-content: flex-end;"
                >
                  <span
                    v-if="getCountOfFullDayTimeOffsPerDay(dayHeader.date)"
                    style="
                      color: #000;
                      font-size: 1rem;
                    "
                  >Full Days:
                    <v-chip
                      class="white-chip"
                      style="background-color: #388E3C"
                    >{{ getCountOfFullDayTimeOffsPerDay(dayHeader.date) }}</v-chip></span>
                  <span
                    v-if="getCountOfHalfDayTimeOffsPerDay(dayHeader.date)"
                    style="
                      color: #000;
                      font-size: 1rem;
                    "
                  >Half Days:
                    <v-chip
                      class="white-chip"
                      style="background-color: #03A9F4"
                    >{{ getCountOfHalfDayTimeOffsPerDay(dayHeader.date) }}</v-chip></span>
                </v-col>
              </v-row>
            </template>
            <template #day-body="dayBody">
              <span :class="isHolidayOrWeekend(dayBody.date) || isHolidayOrWeekend(dayBody.weekday) ? 'pink-background' : ''" />
            </template>
            <template #day-label-header="dayLabelHeader">
              <v-row>
                <v-col>
                  {{ dayLabelHeader.day }}
                </v-col>
              </v-row>
              <v-row>
                <v-col style="font-weight: bold">
                  {{ holidaysObj[dayLabelHeader.date] }}
                </v-col>
              </v-row>
              <!-- need this empty div here otherwise won't be able to see the holiday name -->
              <div style="height: 2rem" />
            </template>
            <template #day-label="dayLabel">
              <v-row>
                <v-col>
                  {{ dayLabel.day }}
                </v-col>
              </v-row>
              <v-row>
                <v-col style="font-weight: bold">
                  {{ holidaysObj[dayLabel.date] }}
                </v-col>
              </v-row>
              <!-- need this empty div here otherwise won't be able to see the holiday name -->
              <div style="height: 2rem" />
            </template>
            <template #event="event">
              <v-row
                v-if="type === 'day'"
              >
                <v-col class="pl-5 pb-0 pr-0">
                  <span
                    :style="{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      color: (event.eventParsed.input.department ? updateTextColor(event.eventParsed.input.department.color) : updateTextColor('#FFA500')),
                    }"
                  >{{ `${event.eventParsed.input.name} - ${event.eventParsed.input.user_employee_number} - ${event.eventParsed.input.user.phone_number ? event.eventParsed.input.user.phone_number : ''} ${event.eventParsed.input.user.email ? '- ' + event.eventParsed.input.user.email : ''}` }}</span>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="pl-5 pb-0 pr-0">
                  <span
                    :style="{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      color: (event.eventParsed.input.department ? updateTextColor(event.eventParsed.input.department.color) : updateTextColor('#FFA500')),
                    }"
                  >{{ `${event.eventParsed.input.name}` }}</span>
                </v-col>
              </v-row>
            </template>
            <template #day="day">
              <v-row :class="isHolidayOrWeekend(day.date) ? 'pink-background-month' : ''">
                <v-col
                  class="pa-5"
                  style="display: flex;flex-direction: column;justify-content: flex-end;"
                >
                  <span
                    v-if="getCountOfFullDayTimeOffsPerDay(day.date)"
                    style="
                      color: #000;
                      font-size: 1rem;
                    "
                  >Full Days:
                    <v-chip
                      class="white-chip"
                      style="background-color: #388E3C"
                    >{{ getCountOfFullDayTimeOffsPerDay(day.date) }}</v-chip></span>
                  <span
                    v-if="getCountOfHalfDayTimeOffsPerDay(day.date)"
                    style="
                      color: #000;
                      font-size: 1rem;
                    "
                  >Half Days:
                    <v-chip
                      class="white-chip"
                      style="background-color: #03A9F4"
                    >{{ getCountOfHalfDayTimeOffsPerDay(day.date) }}</v-chip></span>
                </v-col>
              </v-row>
            </template>
          </v-calendar>
        </v-card>
      </v-col>
    </v-row>
    <time-off-request-form
      ref="timeOffRequestForm"
      :view-form="viewForm"
      @close-form="closeForm"
      @success="get"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TimeOffRequestForm from '@/components/forms/timeOffs/TimeOffRequestForm';

export default {
  components: {
    TimeOffRequestForm,
  },

  props: {},
  data: () => ({
    viewForm: false,
    statusIdApproved: 3,
    type: 'month',
    types: [
      { type: 'month', name: 'MONTH', color: '#388E3C' },
      { type: 'week', name: 'WEEK', color: '#B71C1C' },
      { type: 'day', name: 'DAY', color: '#FBC02D' },
    ],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    focusDay: '',
    loading: false,
    selectedSlot: null,
    selectedOpen: false,
    selectedEvent: null,
    start: null,
    end: null,
    filterStatusRequest: null,
    statuses: [],
    filterJob: null,
    jobs: [],
    filterTruck: null,
    timeOffs: [],
    countOfEventsPerDayObj: {},
    holidays: [],
    holidaysObj: {},
  }),

  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
    ...mapGetters([]),
    events() {
      const events = [];
      this.timeOffs.forEach((timeOff) => {
        const timeOffObj = {
          ...timeOff, name: timeOff.user_full_name, start: timeOff.start_date, end: timeOff.back_to_work_date, event_type: 'time_off',
        };
        if (timeOff.start_date === timeOff.end_date && timeOff.start_time && timeOff.end_time) {
          timeOffObj.start = `${timeOffObj.start} ${timeOff.start_time}`;
          timeOffObj.end = `${timeOffObj.end} ${timeOff.end_time}`;
        }
        events.push(timeOffObj);
      });
      return events;
    },
  },
  watch: {
    search() {
      this.get();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.get();
      await this.getHolidays();
      this.setCountOfTimeOffsPerDay();
      this.setHolidaysObj();
    },

    async get() {
      this.loading = true;
      this.countOfEventsPerDayObj = {};
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          status_id: this.statusIdApproved,
          is_calendar: true,
        },
        search: this.search,
      };
      const res = await this.$api.timeOffRequest.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (!res || !res.data) {
        this.$bus.$emit('showError');
        this.timeOffs = [];
        this.loading = false;
        return;
      }
      this.timeOffs = res.data;
      this.setCountOfTimeOffsPerDay();
      this.loading = false;
    },

    async getHolidays() {
      this.loading = true;
      const submitModel = {
        filters: {
        },
      };
      this.holidays = await this.$api.timeOffHoliday.read(submitModel);
      this.loading = false;
    },

    setHolidaysObj() {
      if (this.holidays.length > 0) {
        this.holidays.forEach((holiday) => {
          this.holidaysObj[holiday.date] = holiday.name;
        });
      } else {
        this.holidaysObj = {};
        this.holidays = [];
      }
    },

    getHolidayName(data) {
      if (this.holidaysObj[data.date]) {
        return this.holidaysObj[data.date];
      }
      return '';
    },

    // THIS FUNCTION WILL GET A COUNT FOR ALL TIMEOFFS - might want to limit it to just the month we selected
    setCountOfTimeOffsPerDay() {
      const countOfEventsPerDayObj = {};
      this.timeOffs.forEach((timeOff) => {
        const startDate = new Date(`${timeOff.start_date}T00:00:00`);
        // check half day
        if (this.checkIsHalfDay(timeOff)) {
          const formattedStartDate = startDate.toISOString().split('T')[0];
          if (countOfEventsPerDayObj[formattedStartDate] && countOfEventsPerDayObj[formattedStartDate].halfDays) {
            countOfEventsPerDayObj[formattedStartDate].halfDays += 1;
          } else {
            countOfEventsPerDayObj[formattedStartDate] = { ...countOfEventsPerDayObj[formattedStartDate], halfDays: 1 };
          }
        } else {
          // iterate through the start_date to end_date and add 1 for every day
          const endDate = new Date(`${timeOff.back_to_work_date}T00:00:00`);
          while (startDate <= endDate) {
            // Format the current date as 'YYYY-MM-DD'
            const i = startDate.toISOString().split('T')[0];
            // console.log(`date: ${startDate}\n` + `formatted Date: ${i}\n` + `timeOffRequest: ${JSON.stringify(timeOff, null, 4)}`);
            if (countOfEventsPerDayObj[i] && countOfEventsPerDayObj[i].fullDays) {
              countOfEventsPerDayObj[i].fullDays += 1;
            } else {
              countOfEventsPerDayObj[i] = { ...countOfEventsPerDayObj[i], fullDays: 1 };
            }
            // Move to the next day
            startDate.setDate(startDate.getDate() + 1);
          }
        }
      });
      this.countOfEventsPerDayObj = { ...countOfEventsPerDayObj };
    },

    checkIsHalfDay(timeOff) {
      return (timeOff.start_date === timeOff.end_date && timeOff.start_time && timeOff.end_time);
    },

    getCountOfFullDayTimeOffsPerDay(date) {
      if (this.countOfEventsPerDayObj[date] && this.countOfEventsPerDayObj[date].fullDays) {
        return this.countOfEventsPerDayObj[date].fullDays;
      }
      return 0;
    },

    getCountOfHalfDayTimeOffsPerDay(date) {
      if (this.countOfEventsPerDayObj[date] && this.countOfEventsPerDayObj[date].halfDays) {
        return this.countOfEventsPerDayObj[date].halfDays;
      }
      return 0;
    },

    openViewForm({ event }) {
      //   this.$refs.viewForm.open(event.request_id, event.request_to_truck_id);
      if (event.user_id === this.user.id) {
        this.viewForm = true;
        this.$refs.timeOffRequestForm.open(event.id);
      }
    },

    closeForm() {
      this.filledTimeOffFormData = null;
      this.viewForm = false;
    },

    openEditForm(id) {
      this.$refs.approveForm.open(id);
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    setToday() {
      this.type = 'day';
      this.focusDay = '';
    },

    success() {
      this.getSlots({ start: this.start, end: this.end });
    },

    getEventColor(item) {
      if (item.event_type === 'holiday') {
        return 'red';
      }
      return item.user && item.user.department ? item.user.department.color : 'orange';
    },

    viewDay({ date }) {
      this.focusDay = date;
      this.type = 'day';
    },

    getBrightness(color) {
      // Convert color to RGB
      const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      const r = parseInt(rgb[1], 16);
      const g = parseInt(rgb[2], 16);
      const b = parseInt(rgb[3], 16);

      // Calculate brightness using luminance formula
      return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    },

    updateTextColor(backgroundColor) {
      const brightness = this.getBrightness(backgroundColor);
      const textColor = brightness > 0.5 ? 'black' : 'white';
      return textColor;
    },

    isHolidayOrWeekend(item) {
      if (this.holidaysObj[item]) {
        return true;
      }
      if (item === 0 || item === 6) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
/deep/.v-calendar-weekly__week .v-calendar-weekly__day:first-child {
  background-color: #ffecef;
}
/deep/.v-calendar-weekly__week .v-calendar-weekly__day:last-child {
  background-color: #ffecef;
}
/deep/.v-calendar-weekly__head .v-calendar-weekly__head-weekday:first-child {
  background-color: #ffecef;
}
/deep/.v-calendar-weekly__head .v-calendar-weekly__head-weekday:last-child {
  background-color: #ffecef;
}
/deep/.v-calendar-daily__head .v-calendar-daily_head-day:nth-child(8) {
  background-color: #ffecef;
}
/deep/.v-calendar-daily__head > .v-calendar-daily_head-day:nth-child(2):nth-last-child(7) {
  background-color: #ffecef;
}
/deep/.v-calendar-daily__day-container > .v-calendar-daily__day:nth-child(2):nth-last-child(7) {
  background-color: #ffecef;
}
/deep/.v-calendar-daily__day:nth-child(8) {
  background-color: #ffecef;
}
/deep/.v-calendar-weekly__head-weekday {
  background-color: rgb(224, 224, 224) !important;
}
/deep/.v-calendar-monthly.v-calendar-weekly.v-calendar.theme--dark.v-calendar-events > .v-calendar-weekly__head > .v-calendar-weekly__head-weekday {
  background-color: #000000 !important;
  font-size: 18px !important;
}
/* /deep/.v-calendar-daily__day:has(.holiday) {
  background-color: #ffecef;
}
/deep/.v-calendar-daily_head-day:has(.holiday) {
  background-color: #ffecef;
} */
/deep/.v-calendar-daily__day:has(.pink-background) {
  background-color: #ffecef;
}
/deep/.v-calendar-daily_head-day:has(.pink-background) {
  background-color: #ffecef;
}
/* /deep/.v-calendar-weekly__day-label:has(.pink-background-month-header) {
  background-color: #ffecef;
  margin-top: 0;
  padding-top: 4px;
  border-left: 1px solid rgb(224, 224, 224);
} */
/deep/.v-calendar-weekly__day:has(.pink-background-month) {
  background-color: #ffecef;
  border-left: 1px solid rgb(224, 224, 224);
  border-right: 1px solid rgb(224, 224, 224);
}
.white-chip {
  height: 24px;
  color: white !important;
  font-size: 95%;
  /* font-weight: bold; */
  margin: 0px !important;
}
/deep/.v-calendar-weekly__day-label > .v-btn > .v-btn__content {
  font-size: 1.5rem;
}
/deep/.v-calendar-weekly__day {
  display: flex;
  flex-direction: column;
}
/deep/.v-calendar-daily_head-day {
  display: flex;
  flex-direction: column;
}
/* /deep/.v-event {
  width: 99% !important;
} */
::v-deep .wrap-text {
  white-space: normal !important;
}

/* ::v-deep .v-calendar .v-event {
  margin-left: 2px !important;
} */

::v-deep .v-calendar-daily_head-weekday {
  font-size: 20px !important;
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
  font-size: 18px !important;
}

::v-deep .v-calendar-weekly__day-label .v-btn {
  font-size: 18px !important;
}
</style>
