<template>
  <v-container
    fluid
    class="px-4"
  >
    <v-row>
      <v-col
        cols="auto"
        class="pb-0"
      >
        <v-tabs
          v-if="enabled_bom_rma == 1"
          v-model="selectedTab"
          @change="tabChanged"
        >
          <v-tabs-slider :class="selectedTab === 3 ? 'empty-class-tab' : selectedTab === 2 ? 'rma-class' : selectedTab === 1 ? 'bom-class' : 'primary'" />
          <v-tab> Jobs </v-tab>
          <v-tab
            v-if="enabled_bom_rma == 1"
            class="success--text"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">BOMs</span>
              </template>
              <span>Bill of Materials</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            v-if="enabled_bom_rma == 1"
            class="error--text"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">RMAs</span>
              </template>
              <span>Return Material Authorization</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            v-if="enabled_bom_rma == 1"
            is-show="false"
            disabled
          />
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-if="enabled_bom_rma == 1">
      <v-col class="pt-0">
        <v-tabs
          v-model="selectedTabInner"
          optional
        >
          <v-tabs-slider :class="`${selectedTab === 3 ? innerTabType === 'bom' ? 'bom-class' : 'rma-class' : 'transparent'} `" />
          <v-tab
            v-for="(tab, index) in bomRmaTabs"
            :key="index"
            :class="`${tab.type === 'bom' ? 'success--text' : 'error--text'}`"
            @click="innerTabChanged(index)"
          >
            {{ tab.code }}
            <v-btn
              color="grey"
              small
              icon
              @click="closeTab(index)"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                  >
                    mdi-close
                  </v-icon>
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-btn>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <Jobs
      v-if="selectedTab === 0"
      @show-boms="showBoms"
    />
    <Boms
      v-else-if="selectedTab === 1 || selectedTab === 2"
      ref="boms"
      @open-bom="openBom"
    />
    <Bom
      v-show="selectedTab === 3"
      ref="bom"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Jobs from './Jobs';
import Boms from './Boms';
import Bom from './Bom';

export default {
  name: 'Index',
  components: {
    Jobs,
    Boms,
    Bom,
  },
  filters: {},
  data: () => ({
    selectedTab: 0,
    selectedTabInner: 0,
    enabled_bom_rma: 0,
    innerTabType: '',
  }),
  computed:
  {
    ...mapState({
      bomRmaTabs: 'bomRmaTabs',
    }),
  },
  created() {
    this.initialize();
    const { businessRules } = this.$store.getters;
    this.enabled_bom_rma = businessRules.find((x) => x.name === 'Bom/rma code').value;
  },
  methods: {
    async initialize() {
      this.selectedTab = this.$store.getters.selectedJobsTab;
      if (this.selectedTab === null) this.selectedTab = 0;
      this.selectedTabInner = this.$store.state.innerBomRmaTab;
      if (this.selectedTab !== 3) {
        this.tabChanged();
      } else {
        this.innerTabChanged(this.selectedTabInner);
      }
    },
    async openBom(item, type) {
      const bomRmaTabs = [...this.bomRmaTabs];
      bomRmaTabs.push({ code: item.code, type, id: item.id });
      this.$store.commit('storeBomRmaTabs', bomRmaTabs);
      this.$nextTick(async () => {
        this.$refs.bom.renderBom(item);
      });
      this.innerTabChanged(bomRmaTabs.length - 1);
    },

    innerTabChanged(index) {
      this.selectedTab = 3;
      this.selectedTabInner = index;
      this.tabChanged();
      const selectedTab = this.bomRmaTabs[index];
      if (!selectedTab) return;
      this.innerTabType = selectedTab.type;
      this.showBom(selectedTab.type, selectedTab.id);
    },

    closeTab(index) {
      const bomRmaTabs = [...this.bomRmaTabs];
      bomRmaTabs.splice(index, 1);
      this.$store.commit('storeBomRmaTabs', bomRmaTabs);
      if (bomRmaTabs.length !== 0) {
        this.innerTabChanged(index - 1);
      } else {
        this.selectedTab = 1;
        this.selectedTabInner = 0;
        this.tabChanged();
        this.storeTabChanged();
      }
    },

    async showBoms(jobId, type) {
      if (type === 'bom') {
        this.selectedTab = 1;
        await this.$nextTick();
        await this.$refs.boms.initialize('bom', jobId);
      } else {
        this.selectedTab = 2;
        await this.$nextTick();
        await this.$refs.boms.initialize('rma', jobId);
      }
      this.storeTabChanged();
    },

    async showBom(type, id) {
      if (type === 'bom') {
        this.$nextTick(async () => {
          this.$bus.$emit('loading');
          await this.$refs.bom.initialize('bom', id);
          this.$bus.$emit('loading');
        });
      } else {
        this.$nextTick(async () => {
          this.$bus.$emit('loading');
          await this.$refs.bom.initialize('rma', id);
          this.$bus.$emit('loading');
        });
      }
      this.storeTabChanged();
    },
    async tabChanged() {
      await this.$nextTick();
      if (this.selectedTab === 1) {
        await this.$refs.boms.initialize('bom');
      } else if (this.selectedTab === 2) {
        await this.$refs.boms.initialize('rma');
      }
      this.storeTabChanged();
    },
    storeTabChanged() {
      // Keep current tab in local storage
      this.$store.commit('storeJobsTab', this.selectedTab);
      this.$store.commit('storeInnerBomRmaTab', this.selectedTabInner);
    },
  },
};
</script>
<style scoped>
.v-tab {
  text-transform: none !important;
}

::v-deep .bom-class.v-tabs-slider {
    background-color: #4CAF50 !important;
}

::v-deep .empty-class-tab.v-tabs-slider {
    background-color: transparent !important;
}

::v-deep .rma-class.v-tabs-slider {
    background-color: #FF5252 !important;
}
</style>
