import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const state = {
  token: '',
  clientEnv: '',
  user: {},
  selected_bom: null,
  selected_jobs_tab: 0,
  selected_inventory_tab: null,
  selectedInventoryTabId: 0,
  selected_equipment_tab: null,
  equipment_list: [],
  jobs_list: [],
  conditions_list: [],
  equipment_movement_form_open: false,
  equipment_movement_form_type: null,
  package_version: process.env.PACKAGE_VERSION || '0',
  excel_file: null,
  business_rules: null,
  physicalCountList: null,
  admin_modules: [],
  countries: [],
  regions: [],
  user_state: '',
  isInvTagQtyOnHandEqualsToZero: true,
  invClasses: [],
  movementTab: 0,
  clientsState: {},
  suppliersState: {},
  projectsState: {},
  currentLevelsState: {},
  inventoryTagsState: {},
  transactionsState: {},
  projectsScopeState: {},
  jobsState: {},
  movingBatchState: {},
  inventoryPoState: {},
  glassPoState: {},
  glassItemsState: {},
  glassBatchState: {},
  supplyToLinesState: {},
  partPickListState: {},
  supplyToPaintState: {},
  truckScheduleRequestState: {},
  truckScheduleSlotState: {},
  productionScheduleState: {},
  productionScheduleCalendarState: {},
  timeOffState: {},
  alertsState: {},
  search: '',
  isDarkMode: false,
  bomRmaTabs: [],
  innerBomRmaTab: 0,
  isQRCodeScan: true,
  inventoryClassId: null,
};
// eslint-disable-next-line new-cap
const vuexPersisted = new createPersistedState({
  key: 'zitaMrp',
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {},
  plugins: [vuexPersisted],
});
