<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    submit-btn-text="Submit"
    :full-screen="true"
    :warning-text="warningText"
    :is-submit-btn="isSubmitBtn"
    :persistent="true"
  >
    <template slot="header">
      <v-row align="center">
        <v-col
          cols="auto"
          class="pr-6 pb-0"
        >
          <span>Imported Glass Items</span>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <inventory-class-select-element
            v-model="inventoryClassId"
            :is4500="false"
            :is4800="false"
            :is-inst="false"
            :is-glass="false"
            :is-panels="false"
            :is-sid="false"
            :is-add-all="false"
            :is-rail-part="false"
            :is-railings="inventoryClassId !== classVWDGlass"
            :is-commercial="inventoryClassId !== classVWDGlass"
            :is-vwd-glass="inventoryClassId !== classCommercialId && inventoryClassId !== classRailingsId"
            :is-parts="false"
            :is-required="true"
          />
        </v-col>
        <v-col
          v-if="inventoryClassId === classCommercialId || inventoryClassId === classRailingsId"
          cols="3"
          class="pb-0"
        >
          <v-autocomplete
            v-model="supplierId"
            label="Supplier"
            :items="suppliers"
            :rules="[(v) => !!v || 'Supplier is required']"
            :item-text="item => `${item.code} ${item.name}`"
            item-value="id"
            clearable
            :single-select="false"
          >
            <template #selection="{ item }">
              <span>
                <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
              </span>
            </template>
            <template #item="{ item }">
              <span>
                <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
              </span>
            </template>
            <template slot="label">
              Supplier<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
              :items-per-page="-1"
              dense
              :loading="loading"
            >
              <template #[`item.g_code`]="{ item }">
                <span
                  v-if="item.g_code"
                  style="color: green; font-weight: bold;"
                >{{ item.g_code }}</span>
                <v-tooltip
                  v-else
                  top
                  color="black"
                >
                  <template #activator="{ on }">
                    <v-icon
                      color="error"
                      dark
                      v-on="on"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                  <span>This Glass Code is not in the System!</span>
                </v-tooltip>
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item.id"
                  small
                  :color="item.status ? item.status.color : ''"
                  text-color="white"
                >
                  {{ item.status ? item.status.name : '' }}
                </v-chip>
                <v-chip
                  v-else
                  small
                  color="success"
                  text-color="white"
                >
                  READY FOR IMPORT
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-end pr-10">
          <span style="font-weight: bold; color: black; font-size: 18px;">Total Records: {{ items.length }}</span>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import ExcelHelper from '@/helpers/ExcelHelper';

export default {
  data: () => ({
    items: [],
    rawItems: [],
    inventoryClassId: null,
    class4500Id: 1,
    class4800Id: 2,
    classRailingsId: 3,
    classInstId: 4,
    classGlassId: 5,
    classCommercialId: 6,
    classVWDGlass: 7,
    classPanelsId: 8,
    classSidgId: 9,
    classPanelPartsId: 10,
    warningText: null,
    isSubmitBtn: true,
    loading: false,
    supplierId: null,
    suppliers: [],
  }),
  computed: {
    headers() {
      const data = [];

      if (this.inventoryClassId === this.classVWDGlass) {
        data.push({ text: 'Job Code', value: 'job_code' });
      }

      data.push({ text: 'Job Name', value: 'job_name' });
      if (this.inventoryClassId === this.classVWDGlass) {
        data.push({ text: 'PO Number', value: 'po_number' });
      }
      data.push({ text: 'G Code', value: 'g_code', align: 'center' });
      if (this.inventoryClassId === this.classVWDGlass) {
        data.push({ text: 'Glass', value: 'glass' });
      }

      data.push({ text: 'Code', value: 'code' });
      data.push({ text: 'Glass Thickness', value: 'glass_thickness' });
      data.push({ text: 'Tag#', value: 'tag_number' });
      data.push({ text: 'Width', value: 'width' });
      data.push({ text: 'Height', value: 'height' });
      data.push({ text: 'Qty', value: 'quantity' });

      if (this.inventoryClassId !== this.classVWDGlass) {
        data.push({ text: 'Note', value: 'note' });
      }
      data.push({ text: 'Status', value: 'status', align: 'center' });

      return data;
    },
  },
  watch: {
    inventoryClassId() {
      this.getItems();
    },
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async submit() {
      this.$bus.$emit('loading');
      if (this.inventoryClassId === this.classCommercialId || this.inventoryClassId === this.classRailingsId) {
        this.items = this.items.map((item) => {
          // eslint-disable-next-line no-param-reassign
          item.supplier_id = this.supplierId;
          return item;
        });
      }
      const res = await this.$api.glass.store(this.items, 'import');
      if (res !== true) {
        this.$bus.$emit('showError', res);
        this.$bus.$emit('loading');
        return false;
      }

      this.$emit('success');
      this.$bus.$emit('loading');
      return res;
    },

    getItems() {
      this.items = [];
      this.isSubmitBtn = true;
      this.warningText = null;
      if (this.inventoryClassId === this.classVWDGlass) {
        this.getItemsVWDGlass();
      } else if (this.inventoryClassId === this.classCommercialId || this.inventoryClassId === this.classRailingsId) {
        this.getItemsCommRail();
      }
    },

    async getSuppliers() {
      this.suppliers = await this.$api.supplier.get();
    },

    async getItemsVWDGlass() {
      this.loading = true;

      const firstIndex = 0;
      const poNumber = this.rawItems[firstIndex].I;
      for (let i = 0; i < this.rawItems.length; i++) {
        const element = this.rawItems[i];
        const res = this.validateAndFormatName(element.I);
        if (res === false) {
          this.$bus.$emit('showError', `Invalid PO Number format: ${element.I}`);
          this.$nextTick(async () => {
            this.$refs.dialogBox.close();
          });

          break;
        }
        this.rawItems[i].I = res;
      }
      // check if this po number in db
      const submitModel = {
        filters: { inventory_class_id: this.classVWDGlass, po_number: poNumber },
      };
      const resPo = await this.$api.glass.readPoGrouped(submitModel);
      if (!resPo || resPo.length !== 0) {
        this.$bus.$emit('showError', `PO Number '${poNumber} is already in the system!'`);
        this.warningText = `PO Number '${poNumber} is already in the system!'`;
        this.isSubmitBtn = false;
      }

      const items = this.rawItems.map((x) => {
        // glass code format - 4TT - 4MM TEMP COMFORT SELECT 73
        const glassCodeIndex = x.E.indexOf('-');
        const item = {
          width: x.F,
          height: x.G,
          quantity: x.D,
          po_number: x.I,
          glass: x.E,
          vwd_code: x.E.slice(0, glassCodeIndex).trim(),
          job_code: x.I,
          job_name: 'VINYL WINDOW DESIGNS LTD',
          glass_thickness: 'SINGLE GLASS',
          inventory_class_id: this.classVWDGlass,
          tag_number: x.B,
          code: 'GL',
          order_date: this.convertToDate(x.J),
          required_date: this.convertToDate(x.K),
        };

        console.log(x);
        return item;
      });

      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const x in items) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const res = await this.$api.glass.get(`vwd-code/${items[x].vwd_code}`);
          if (!res || !res.id) {
            this.warningText = 'Glass Codes are not in the System!';
            this.isSubmitBtn = false;
          }
          // eslint-disable-next-line no-param-reassign
          items[x].g_code = res.row;
        } catch (ex) {
          console.log(ex);
        }
      }
      this.items = items;
      this.loading = false;
    },

    validateAndFormatName(name) {
    // Regular expression to match PWD 27188, VWD 27189, VWD 27470p format
      const validPattern = /^[A-Z]{3} \d+[a-z]?$/;
      // Regular expression to match VWD VINYLBILT followed by various formats
      const specialPattern = /^VWD VINYLBILT (\d{6,8}[a-zA-Z]*|\d{4}|\d{6}-\d{2}|RE|(\d{1,6}))$/;

      if (specialPattern.test(name)) {
        // If name matches the special pattern, convert to VB 03152024 format
        return name.replace('VWD VINYLBILT', 'VB');
      } if (validPattern.test(name)) {
        // If name matches the valid pattern, return the name as it is
        return name;
      }
      // If name does not match any of the patterns, return an error
      return false;
    },

    async getItemsCommRail() {
      this.loading = true;

      this.items = this.rawItems.map((x) => {
        const item = {
          width: x.G,
          height: x.H,
          quantity: x.I,
          g_code: x.E,
          job_name: `${x.A}; ${x.B}`,
          glass_thickness: x.D,
          inventory_class_id: this.inventoryClassId,
          tag_number: x.F,
          code: x.C,
          note: x.J,
        };
        return item;
      });
      this.loading = false;
    },

    async open(importFile) {
      this.items = [];
      this.importFile = importFile;
      this.rawItems = await ExcelHelper.excelToJsonNoHeader(importFile);
      // file name is po number, format: VWD 22448.csv (only number is required)
      if (importFile.name.includes('VWD') || importFile.name.includes('PWD')) {
        this.inventoryClassId = this.classVWDGlass;
      } else {
        this.inventoryClassId = this.classCommercialId;
        this.rawItems.splice(0, 1);
      }
      this.$refs.dialogBox.open();
      this.getSuppliers();
    },

    convertToDate(dateString) {
      // Split the date string into parts using '/'
      const dateParts = dateString.split('/');

      // Extract the month, day, and year from the parts
      const month = parseInt(dateParts[0], 10);
      const day = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);

      // Convert the year to a 4-digit format
      const fullYear = year < 100 ? 2000 + year : year;

      // Create a new Date object with the extracted values
      const convertedDate = new Date(fullYear, month - 1, day + 1);

      // Format the date as "YYYY-MM-DD"
      const formattedDate = convertedDate.toISOString().split('T')[0];

      return formattedDate;
    },
  },
};
</script>
