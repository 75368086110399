<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :max-width="1000"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Request {{ item.id ? `#${item.id}` : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <span style="font-size: 18px;">Request Delivery Date<RedAsterisk /></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-date-picker
                v-model="item.date"
                full-width
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <inventory-class-select-element
                v-model="item.inventory_class_id"
                :is-add-all="false"
                :is-required="true"
                @on-change="inventoryClassChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-autocomplete
                v-model="item.job_id"
                :items="jobs"
                :item-text="item => `${item.code} - ${item.name}`"
                item-value="id"
                clearable
                :no-data-text="!item.inventory_class_id ? 'Select an Inventory Class first' : 'No data available'"
                :rules="[(v) => !!v || 'Job is required']"
                :loading="loadingJobs"
                :disabled="loadingJobs && (jobs && jobs.length === 0)"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    v-if="item.sub_class"
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item && item.sub_class ? item.sub_class.color : 'white'"
                  >
                    {{ item && item.sub_class ? item.sub_class.name : '' }}
                  </v-chip>
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #item="{ item }">
                  <v-chip
                    v-if="item.sub_class"
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item && item.sub_class ? item.sub_class.color : 'white'"
                  >
                    {{ item && item.sub_class ? item.sub_class.name : '' }}
                  </v-chip>
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>
                <template slot="label">
                  Job<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- <v-row v-else>
            <v-col class="pb-0">
              <v-text-field
                v-model="item.job_name"
                :rules="[(v) => !!v || 'Job is required']"
              >
                <template slot="label">
                  Job<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col class="pt-0 pb-0">
              <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px' }">Total Quantity Required: <span style="font-weight: bold;">{{ getJobTotalWindows() }}</span></span>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col class="pb-0">
              <v-text-field
                v-model="item.floor_number"
                :rules="[(v) => !!v || 'Floor# is required']"
              >
                <template slot="label">
                  Floor#<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col class="pb-o">
              <v-select
                v-model="item.truck_type_id"
                label="Status"
                :items="truckTypes"
                item-text="name"
                item-value="id"
                hide-details="true"
                :rules="[(v) => !!v || 'Truck Type is required']"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <template slot="label">
                  Truck Type<RedAsterisk />
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-autocomplete
                v-model="item.installer_id"
                :items="installers"
                item-text="full_name"
                item-value="id"
                clearable
                :rules="[(v) => !!v || 'Installer is required']"
              >
                <template slot="label">
                  Installer<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!item.id">
            <v-col class="pt-4">
              <v-textarea
                v-model="item.comment"
                outlined
                rows="3"
                label="Note"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="pt-6">
              <v-row>
                <v-col class="pt-4">
                  <span :style="{color: isDarkMode ? '' : '#000000', fontSize: '16px'}">Notes</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <!-- <v-virtual-scroll
                    :items="item.notes"
                    height="200"
                    item-height="75"
                    style="border: 1px solid rgba(0 ,0 ,0, 0.2); border-radius: 4px;"
                  > -->
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <!-- <template #default="{ item }">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="wrap-text"
                            v-text="item.text"
                          />
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text v-text="item.created_at" />
                          <v-list-item-action-text v-text="`${item.user.first_name ? item.user.first_name : ''} ${item.user.last_name ? item.user.last_name : ''}`" />
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider />
                    </template>
                  </v-virtual-scroll> -->
                  <v-card-text style="max-height: 300px; overflow-y: auto; border: 1px solid #d3d3d3; border-radius: 3px;">
                    <v-list two-line>
                      <template v-for="(note, index) in item.notes">
                        <v-list-item :key="note.id">
                          <!-- Display each note -->
                          <v-list-item-content>
                            {{ note.text }}
                            <v-list-item-subtitle style="padding-top: 10px;">{{ note.created_at }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ `${note.user.first_name ? note.user.first_name : ''} ${note.user.last_name ? note.user.last_name : ''}` }}</v-list-item-subtitle>
                          
                          </v-list-item-content>
                          <!-- <v-divider v-if="index !== schedule.notes.length - 1"/> -->
                        </v-list-item>
                        <v-divider
                          v-if="index < item.notes.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      date: new Date().toISOString().slice(0, 10),
      truck_type_id: null,
      job_id: null,
      job_name: null,
      inventory_class_id: null,
      comment: null,
      installer_id: null,
    },
    jobs: [],
    truckTypes: [],
    trucks: [],
    installers: [],
    inventoryClassId4500: 1,
    inventoryClassId4800: 2,
    filterTruckTypeId: null,
    loadingJob: false,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    })
  },
  watch: {
    // eslint-disable-next-line func-names
    // 'item.inventory_class_id': function (value, oldValue) {
    //   if (!value || oldValue === undefined || (this.item.id && !oldValue) || value === oldValue) return;
    //   this.item.job_name = null;
    //   this.item.job_id = null;
    //   if (value === this.inventoryClassId4500 || value === this.inventoryClassId4800) {
    //     this.getJobs();
    //   }
    // },
  },
  created() {
    this.item = { ...this.itemDto };
    this.getJobs();
    this.getTruckTypes();
    this.getInstallers();
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.item = res.data[firstIndex];
      this.filterTruckTypeId = this.item.truck_id;
    },

    async getJobs() {
      this.loadingJobs = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.item.inventory_class_id,
          is_truck_schedule: 1,
          is_sub_class: 1,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      const res = await this.$api.job.readDataSecondary(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        this.loadingJobs = false;
        return;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },

    async getTruckTypes() {
      const submitModel = {
        filters: {
        },
      };
      this.truckTypes = await this.$api.truckScheduleTruckType.read(submitModel);
    },

    async getInstallers() {
      const roleIdInstaller = 17;
      const submitModel = {
        filters: {
          role_id: roleIdInstaller,
        },
      };
      this.installers = await this.$api.user.read(submitModel);
    },

    async submit() {
      if (!this.item) return false;
      const date = new Date();
      const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
      if (new Date(this.item.date) < currentDate) {
        this.$bus.$emit('showError', 'Past dates are not allowed!');
        return false;
      }
      let res;
      if (this.item.id) {
        res = await this.$api.truckScheduleRequest.update(this.item);
      } else {
        res = await this.$api.truckScheduleRequest.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      // this.getJobs();
      this.$refs.dialogBox.open();
    },

    getJobTotalWindows() {
      if (!this.item.job_id) return 0;
      const job = this.jobs.find((x) => x.id === this.item.job_id);
      if (!job || !job.total_windows) return 0;
      return job.total_windows;
    },

    inventoryClassChange(inventoryClassId) {
      if(inventoryClassId == null) return;
      console.log('inventoryClassChange')
      this.request.job_name = null;
      this.request.job_id = null;
      if (inventoryClassId === this.inventoryClassId4500 || inventoryClassId === this.inventoryClassId4800) {
        this.getJobs();
      }
    }
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>
