var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"submit-btn-text":'Update',"max-width":600}},[_c('template',{slot:"header"},[_c('span',[_vm._v("Update Job")])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.jobsSecondary,"item-text":function (item) { return ((item.code) + " - " + (item.name) + "; " + (item.reference)); },"item-value":"id","disabled":true,"clearable":"","loading":_vm.loadingSecondary},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item && item.sub_class)?_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":item.sub_class.color,"text-color":"white"}},[_vm._v(" "+_vm._s(item.sub_class.name)+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item && item.sub_class)?_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":item.sub_class.color,"text-color":"white"}},[_vm._v(" "+_vm._s(item.sub_class.name)+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}}]),model:{value:(_vm.jobIdOld),callback:function ($$v) {_vm.jobIdOld=$$v},expression:"jobIdOld"}},[_c('template',{slot:"label"},[_vm._v(" Project/Job"),_c('RedAsterisk')],1)],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.jobs,"item-text":function (item) { return ((item.code) + " - " + (item.name) + "; " + (item.reference)); },"item-value":"id","rules":[function (v) { return !!v || 'Project/Job is required'; }],"clearable":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item && item.sub_class)?_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":item.sub_class.color,"text-color":"white"}},[_vm._v(" "+_vm._s(item.sub_class.name)+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item && item.sub_class)?_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":item.sub_class.color,"text-color":"white"}},[_vm._v(" "+_vm._s(item.sub_class.name)+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}}]),model:{value:(_vm.jobIdNew),callback:function ($$v) {_vm.jobIdNew=$$v},expression:"jobIdNew"}},[_c('template',{slot:"label"},[_vm._v(" New Project/Job"),_c('RedAsterisk')],1)],2)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }