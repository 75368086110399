var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"value":_vm.panel}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Location Quantities and Danger Level ")]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.currentAndDangerLevels,"hide-default-footer":"","items-per-page":-1,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.limit_level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.limit_level ? parseFloat(item.limit_level).toLocaleString() : '-')+" ")]}},{key:"item.caution_level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.caution_level ? parseFloat(item.caution_level).toLocaleString() : '-')+" ")]}},{key:"item.danger_level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.danger_level ? parseFloat(item.danger_level).toLocaleString() : '-')+" ")]}},{key:"item.quantity_on_hand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity_on_hand ? parseFloat(item.quantity_on_hand).toLocaleString() : 0)+" ")]}},{key:"item.ordered_quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ordered_quantity ? parseFloat(item.ordered_quantity).toLocaleString() : 0)+" ")]}},{key:"item.recommended_quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.recommended_quantity ? parseFloat(item.recommended_quantity).toLocaleString() : '-')+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v("TOTALS: ")])]),_c('th',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(_vm._s(_vm.getTotalLimitLevel().toLocaleString()))])]),_c('th',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(_vm._s(_vm.getTotalCautionLevel().toLocaleString()))])]),_c('th',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(_vm._s(_vm.getTotalDangerLevel().toLocaleString()))])]),_c('th',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(_vm._s(_vm.getTotalQuantityOnHand().toLocaleString()))])]),_c('th',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(_vm._s(_vm.getTotalOrderedQuantity().toLocaleString()))])]),_c('th',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(_vm._s(_vm.getTotalRecommendedQuantity().toLocaleString()))])])])])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }