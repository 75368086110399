<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="pickState ? closeForm : submit"
    :full-screen="true"
    persistent
    :submit-btn-text="pickState ? 'Confirm' : 'Submit'"
  >
    <template slot="header">
      <span>{{ item.id ? pickState ? '' : 'Edit' : 'Create' }} Part Pick List {{ item.id ? `#${item.id}` : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
          cols="4"
        >
          <v-autocomplete
            v-model="item.project_id"
            :items="projects"
            item-value="id"
            item-text="name"
            label="Project"
            :rules="[(v) => !!v || 'Project is required']"
            :disabled="!roles.includes(userRoleIdAdmin) && !roles.includes(userRoleIdAssociate) && !roles.includes(userRoleIdRequester) || pickState"
            :loading="loadingProjects"
          >
            <template #label>
              Project<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="pb-0"
          cols="4"
        >
          <v-autocomplete
            v-model="item.department_id"
            :items="departments"
            item-value="id"
            label="Department"
            item-text="name"
            :rules="[(v) => !!v || 'Department is required']"
            :disabled="(!roles.includes(userRoleIdAdmin) && !roles.includes(userRoleIdAssociate) && !roles.includes(userRoleIdRequester)) || !!item.id"
            :loading="loadingDepartments"
            @change="(department) => getDefaultQrCodes(department)"
          >
            <template #label>
              Department<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-spacer />
        <v-col
          v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)) && !pickState"
          class="text-end mt-4 pb-0"
        >
          <v-btn
            color="primary"
            outlined
            @click="add"
          >
            <v-icon
              small
              class="mr-1"
            >
              fas fa-plus
            </v-icon>
            New Part
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="item.items"
              hide-default-footer
              disable-pagination
              dense
              item-key="inventory_tag"
              :loading="loading"
            >
              <template #[`item.inventory_tag`]="{ item }">
                <span><span style="font-weight: bold;">{{ item.inventory_tag ? getQRCode(item.inventory_tag) : '' }}</span> - {{ item.inventory_tag ? getItemName(item.inventory_tag) : '' }}</span>
              </template>
              <template #[`item.quantity_required`]="{ item }">
                <v-text-field
                  v-model="item.quantity_required"
                  :disabled="pickState"
                  outlined
                  :hide-details="true"
                  dense
                  single-line
                  type="number"
                  min="0"
                  :max="item.quantity_on_hand"
                  label="Enter Quantity"
                  :rules="[(v) => item.quantity_required <= parseInt(item.quantity_on_hand) || 'Quantity should not exceed quantity on hand']"
                  class="mt-1 mb-1"
                  clearable
                />
              </template>
              <template #[`item.photo`]="{ item }">
                <a
                  @click="openItemShowImageForm(item.inventory_tag)"
                >
                  <v-img
                    :src="convertedPhoto(item.inventory_tag)"
                    class="profile-photo"
                  />
                </a>
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status"
                  class="mt-1 mb-1 mr-3"
                  small
                  text-color="white"
                  style="cursor: pointer;"
                  :color="item.status ? item.status.color : ''"
                >
                  {{ item.status ? item.status.name : '' }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdPicker)) && item.id && item.status && item.status.id == itemStatusIdNotSupplied && pickState"
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="pick(item)"
                >
                  PICK
                </v-btn>
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdPicker)) && item.id && item.status && item.status.id != itemStatusIdNotSupplied && pickState"
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="pick(item)"
                >
                  EDIT PICK
                </v-btn>
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)) && item.status_id != statusIdPartiallySupplied && item.status_id != statusIdSupplied && !item.is_default && !pickState"
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="openEditItem(item.inventory_tag)"
                >
                  EDIT
                </v-btn>
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)) && item.status_id != statusIdPartiallySupplied && item.status_id != statusIdSupplied && !item.is_default && !pickState"
                  class="ml-1 mt-1 mb-1"
                  color="error"
                  outlined
                  small
                  @click="deleteItem(item)"
                >
                  DELETE
                </v-btn>
              </template>
              <template slot="body.append">
                <tr>
                  <th />
                  <th />
                  <th />
                  <th>
                    <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '14px' }">Totals:</span>
                    <span
                      :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '14px' }"
                      style="color: #000000; font-weight: bold; font-size: 14px;"
                    >{{ getTotalRequired() }}</span>
                  </th>
                  <th>
                    <span
                      :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '14px' }"
                      style="color: #000000; font-weight: bold; font-size: 14px;"
                    >{{ getTotalSupplied() }}</span>
                  </th>
                  <th />
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <part-pick-list-item-form
        ref="itemForm"
        @success="save"
      />
      <part-pick-list-item-view-form
        ref="itemViewForm"
        @success="supplySuccess"
      />
      <confirm-form ref="confirmForm" />
      <inventory-item-show-image-form
        ref="itemShowImageForm"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PartPickListItemForm from '@/components/forms/partPickLists/PartPickListItemForm';
import PartPickListItemViewForm from '@/components/forms/partPickLists/PartPickListItemViewForm';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';

export default {
  components: {
    PartPickListItemForm,
    PartPickListItemViewForm,
    InventoryItemShowImageForm,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      project_id: null,
      department_id: null,
      items: [],
    },
    departments: [],
    projects: [],
    qrCodes: [],
    inventoryClassIdParts: 11,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdRequester: 15,
    userRoleIdPicker: 23,
    loading: false,
    statusIdNotSupplied: 1,
    statusIdPartiallySupplied: 2,
    statusIdSupplied: 3,
    itemStatusIdSupplied: 1,
    itemStatusIdNotSupplied: 2,
    itemStatusIdPartiallySupplied: 3,
    loadingDepartments: false,
    loadingProjects: false,
    pickState: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
    headers() {
      const items = [];

      items.push({ text: 'Image', value: 'photo', width: '10%' });
      items.push({ text: 'QR Code - Item', value: 'inventory_tag', width: '30%' });
      items.push({ text: 'Qty on Hand', value: 'quantity_on_hand', width: '10%' });
      items.push({ text: 'Qty Required', value: 'quantity_required', width: '10%' });
      items.push({ text: 'Qty Supplied', value: 'quantity_supplied', width: '10%' });
      items.push({ text: 'Status', value: 'status', width: '10%', align: 'center' });
      items.push({
        text: 'Actions', value: 'actions', align: 'end', width: '20%',
      });

      return items;
    },
  },
  watch: {

  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.item = { ...this.itemDto };
    this.init();
  },
  methods: {
    async init() {
      await this.getQrCodes();
    },

    async open(id, pickState = false) {
      this.pickState = pickState;
      this.item = { ...this.itemDto };
      this.item.items = [];
      this.$refs.dialogBox.open();
      this.$bus.$emit('loading');
      if (id) {
        this.item.id = id;
        const res = await this.get(id);
      }
      this.getProjects();
      this.getDepartments();
      this.$bus.$emit('loading');
    },

    async get(id) {
      if (!id) return;
      this.loading = true;
      this.item = { ...this.itemDto };
      const submitModel = {
        filters: {
          id,
          include_items: true,
        },
      };
      const res = await this.$api.partPickList.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      this.loading = false;
      if (!res || !res.data) {
        this.item = { ...this.itemDto };
        return;
      }
      const firstIndex = 0;
      this.item = res.data[firstIndex];
      if (this.item.items.length > 0) {
        this.item.items.forEach((item, index) => {
          const inventoryTag = this.qrCodes.find((qrCode) => qrCode.inventory_tag === item.inventory_tag); // slow way
          item.quantity_on_hand = inventoryTag.quantity_on_hand;
          item.index = index;
          item.is_default = inventoryTag.item.departments && inventoryTag.item.departments.length > 0;
        });
      }
    },

    async getQrCodes() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassIdParts,
          is_qty_on_hand_o: 0,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };
      const res = await this.$api.inventoryTag.readData(readModel);

      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.qrCodes = [];
        return;
      }
      this.qrCodes = res.data;
    },

    async getProjects() {
      this.loadingProjects = true;
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.projects = [];
        this.loadingProjects = false;
        return;
      }
      this.projects = res.data;
      this.loadingProjects = false;
    },

    async getDepartments() {
      this.loadingDepartments = true;
      const submitModel = {
        filters: {

        },
      };
      this.departments = await this.$api.department.read(submitModel);
      if (!this.pickState && this.item.id && this.item.department_id) {
        this.getDefaultQrCodes(this.item.department_id);
      }
      this.loadingDepartments = false;
    },

    async getDefaultQrCodes(departmentId) {
      if (!this.item.id) this.item.items = [];

      const department = this.departments.find((x) => x.id === departmentId);

      const inventoryItemIds = department.inventory_items.map((x) => x.id);

      if (inventoryItemIds.length === 0) return;

      this.$bus.$emit('loading');

      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassIdParts,
          inventory_item_ids: inventoryItemIds,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };
      const res = await this.$api.inventoryTag.readData(readModel);

      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.$bus.$emit('loading');
        return;
      }
      this.$bus.$emit('loading');

      // add default inventory tags to data-table
      const inventoryTags = res.data;
      inventoryTags.forEach((inventoryTag) => {
        const addItemDto = {
          id: 0,
          inventory_tag: inventoryTag.inventory_tag,
          quantity_required: null,
          quantity_on_hand: inventoryTag.quantity_on_hand,
          is_default: true,
          index: this.item.items.length,
        };
        this.save(addItemDto, false);
      });
    },

    async submit() {
      if (this.pickState && (this.item.status_id === this.statusIdPartiallySupplied || this.item.status_id === this.statusIdSupplied)) {
        this.$emit('success');
        return true;
      }

      if (this.item.items.length === 0) {
        this.$bus.$emit('showError', 'Please add at least one part.');
        return false;
      }
      let res;
      this.removeZeroQuantityRequiredRecords();
      if (this.item.id) {
        res = await this.$api.partPickList.update(this.item);
      } else {
        res = await this.$api.partPickList.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async supply() {
      const res = await this.$api.partPickList.get(`supply/${this.item.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
    },

    async submitPick() {
      const isNotSupplied = this.item.items.some((item) => item.status && item.status.id !== this.itemStatusIdSupplied);
      if (isNotSupplied) {
        this.$bus.$emit('showError', 'All items must be fully supplied');
        return;
      }
      const res = await this.$api.partPickList.store(this.item, 'supply');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
    },

    add() {
      this.$refs.itemForm.open(null, this.item.items.length);
    },

    save(item, isShowError = true) {
      if (!item) return;
      // eslint-disable-next-line no-param-reassign
      const existingItemIndex = this.item.items.findIndex((x) => x.inventory_tag === item.inventory_tag);
      if (existingItemIndex >= 0 && existingItemIndex !== item.index) {
        if (isShowError) this.$bus.$emit('showError', 'Item already exists.');
        return;
      }
      // check to see if we're editing an existing item's inventory_tag or quantity_required. If yes, then change status and quantity_supplied to null
      if (item.index < this.item.items.length) {
        const editItem = this.item.items[item.index];
        if (editItem.inventory_tag !== item.inventory_tag || editItem.quantity_required !== item.quantity_required) {
          item.status = null;
          item.quantity_supplied = null;
        }
      }
      if (item.index !== undefined && item.index !== null) {
        this.$set(this.item.items, item.index, JSON.parse(JSON.stringify(item)));
      } else {
        this.item.items.push(JSON.parse(JSON.stringify(item)));
      }
    },

    openEditItem(inventoryTag) {
      const itemIndex = this.item.items.findIndex((x) => x.inventory_tag === inventoryTag);
      if (itemIndex < 0) return;
      this.$refs.itemForm.open(this.item.items[itemIndex], itemIndex);
    },

    async deleteItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const itemIndex = this.item.items.findIndex((x) => x.inventory_tag === item.inventory_tag);
      if (itemIndex === -1) return;
      this.item.items.splice(itemIndex, 1);
    },

    getQRCode(qrCode) {
      // eslint-disable-next-line eqeqeq
      const item = this.qrCodes.find((x) => x.inventory_tag == qrCode);
      if (!item) return '';
      return item.inventory_tag;
    },

    getItemName(qrCode) {
      // eslint-disable-next-line eqeqeq
      const item = this.qrCodes.find((x) => x.inventory_tag == qrCode);
      if (!item) return '';
      return item.item_full_name;
    },

    pick(item) {
      this.$refs.itemViewForm.open(item);
    },

    getTotalRequired() {
      let total = 0;
      this.item.items.forEach((item) => {
        if (item.quantity_required) total += parseInt(item.quantity_required, 10);
      });
      return total;
    },

    getTotalSupplied() {
      let total = 0;
      this.item.items.forEach((item) => {
        if (item.quantity_supplied) total += parseInt(item.quantity_supplied, 10);
      });
      return total;
    },

    openItemShowImageForm(qrCode) {
      const item = this.qrCodes.find((x) => x.inventory_tag === qrCode);
      if (!item || !item.item) return;
      this.$refs.itemShowImageForm.openDialog(item.item.photo, item.item.mimeType, qrCode);
    },

    convertedPhoto(qrCode) {
      const item = this.qrCodes.find((x) => x.inventory_tag === qrCode);
      if (!item || !item.item) return '';

      return `data:${item.item.mimeType};base64, ${item.item.photo}`;
    },

    removeZeroQuantityRequiredRecords() {
      this.item.items = this.item.items.filter((item) => item.quantity_required > 0 && item.quantity_required !== null);
    },

    async supplySuccess() {
      const res = await this.getQrCodes();
      this.get(this.item.id);
    },

    async closeForm() {
      this.$emit('success');
      return true;
    },
  },
};
</script>

<style scoped>
.profile-photo {
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
</style>
