var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"1500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" RM Supply / Return Records, Inventory Tag "+_vm._s(_vm.inventoryTag)+", Material Id "+_vm._s(_vm.materialId)+" "),(_vm.qrCode)?_c('span',[_vm._v(", QR Code "+_vm._s(_vm.qrCode))]):_vm._e(),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.profilesHeaders,"items":_vm.supplyRecords,"items-per-page":-1,"loading":_vm.loading,"hide-default-footer":"","fixed-header":"","height":"70vh","dense":""},scopedSlots:_vm._u([{key:"item.sheet_id",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openConsumption(item)}}},[_vm._v(_vm._s(item && item.consumption ? item.consumption.id : ''))])]}},{key:"item.sheet_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.consumption && item.consumption.job ? item.consumption.job.name : ''))])]}},{key:"item.sheet_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.consumption ? item.consumption.sheet_date : '')+" ")]}},{key:"item.material",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.inventory_item ? ((item.inventory_item.material_id) + " " + (item.inventory_item.name)) : '')+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_return ? 0 : ("-" + (item.quantity_supplied)))+" ")]}},{key:"item.quantity_returned",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_return ? item.quantity_supplied : item.quantity_returned ? item.quantity_returned : 0)+" ")]}},{key:"item.quantity_missed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity_missed)+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }