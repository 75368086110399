<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Limit, Caution, Danger Levels</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="6"
          class="pb-0"
        >
          <inventory-class-select-element
            v-model="inventoryClassId"
            :is-add-all="false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 pt-0">
          <v-autocomplete
            v-if="isPaintClass"
            v-model="item.glass_paint_code_id"
            :items="inventoryItems"
            clearable
            item-value="id"
            :item-text="
              (item) => item.code + ': ' + item.description
            "
            :rules="[(v) => !!v || 'Paint Code  is required']"
          >
            <template slot="label">
              Paint Code<RedAsterisk />
            </template>
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                color="#8dbf8d"
              >
                PAINT
              </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                color="#8dbf8d"
              >
                PAINT
              </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-else
            v-model="item.inventory_item_id"
            :items="inventoryItems"
            :item-text="item => `${item.material_id ? item.material_id : item.item_code} ${item.name}`"
            item-value="id"
            clearable
            :rules="[(v) => !!v || 'Inventory Item is required']"
          >
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item && item.classes ? item.classes.color : 'white'"
              >
                {{ item && item.classes ? item.classes.name : '' }}
              </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item && item.classes ? item.classes.color : 'white'"
              >
                {{ item && item.classes ? item.classes.name : '' }}
              </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
            </template>
            <template slot="label">
              Inventory Item<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pt-0"
        >
          <v-autocomplete
            v-model="item.location_id"
            label="Location"
            :items="locations"
            item-text="name"
            item-value="id"
            hide-details="true"
            clearable
          />
        </v-col>
        <v-col
          cols="3"
          class="pt-0"
        >
          <v-autocomplete
            v-model="item.length_id"
            label="Length"
            :items="lengths"
            item-text="name"
            item-value="id"
            hide-details="true"
            clearable
          />
        </v-col>
        <v-col
          cols="3"
          class="pt-0"
        >
          <v-autocomplete
            v-model="item.uom_id"
            label="UOM"
            :items="uoms"
            item-text="name"
            item-value="id"
            hide-details="true"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-autocomplete
            v-model="item.color_id"
            label="Colour"
            :items="colors"
            :item-text="item => item.code ? item.code : item.name ? item.name: ''"
            item-value="id"
            hide-details="true"
            clearable
          >
            <template #selection="{ item }">
              <span>
                {{ item.code ? item.code : item.name ? item.name: '' }}
              </span>
            </template>
            <template #item="{ item }">
              <span>
                {{ item.code ? item.code : item.name ? item.name: '' }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="businessRulesGetBoolean('Manufacturer')"
          cols="6"
        >
          <v-autocomplete
            v-model="item.manufacturer_id"
            label="Manufacturer"
            :items="manufacturers"
            item-text="name"
            item-value="id"
            hide-details="true"
            clearable
          />
        </v-col>
        <v-col
          v-if="businessRulesGetBoolean('Material Property')"
          cols="6"
        >
          <v-autocomplete
            v-model="item.material_property_id"
            label="Material Property"
            :items="materialProperties"
            item-text="name"
            item-value="id"
            hide-details="true"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="businessRulesGetBoolean('Finishing')"
          cols="6"
        >
          <v-autocomplete
            v-model="item.finishing_id"
            label="Finishing"
            :items="finishings"
            item-text="name"
            item-value="id"
            hide-details="true"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="item.limit_level"
            type="number"
            :rules="[(v) => !!v || 'Limit Level is required']"
          >
            <template slot="label">
              Limit Level<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="item.caution_level"
            type="number"
            label="Caution Level"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="item.danger_level"
            type="number"
            :rules="[(v) => !!v || 'Danger Level is required']"
          >
            <template slot="label">
              Danger Level<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    item: null,
    itemDto: {
      id: null,
      inventory_item_id: null,
      glass_paint_code_id: null,
      uom_id: null,
      length_id: null,
      color_id: null,
      location_id: null,
      manufacturer_id: null,
      material_property_id: null,
      finishing_id: null,
      danger_level: null,
      caution_level: null,
      created_by: null,
    },
    inventoryClassId: null,
    inventoryItems: [],
    colors: [],
    lengths: [],
    uoms: [],
    locations: [],
    manufacturers: [],
    materialProperties: [],
    finishings: [],
    inventoryClassIdPaint: 12,
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),

    isPaintClass() {
      return this.inventoryClassId === this.inventoryClassIdPaint;
    },
  },
  watch: {
    inventoryClassId(newVal) {
      if(this.isInit) return;
      if(!newVal) return;
      this.getItems();
    },
  },
  created() {
    this.item = { ...this.itemDto };
    // this.getItems();
    this.getUoms();
    this.getColors();
    this.getLengths();
    this.getLocations();
    this.getManufacturers();
    this.getFinishings();
    this.getMaterialProperties();
  },
  methods: {
    async get(id) {
      if (!id) return;
      this.isInit = true;
      const readModel = {
        filters:
        {
          id,
        },
      };
      const res = await this.$api.dangerLevel.read(readModel);
      if (!res || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
      this.inventoryClassId = this.item.item.inventory_class_id;
      this.isInit = false;
    },

    async getItems() {
      this.inventoryItems = [];
      const readModel = {
          filters:
          {
          },
        };
      if(this.isPaintClass) {
        readModel.filters.is_awd_paint = true;
        let res = await this.$api.glassPaintCode.read(readModel);
        const cancelCode = 3001;
        if (res === cancelCode) return;
        if (!res || res.length === 0) {
          this.inventoryItems = [];
          return;
        }
        this.inventoryItems = res;
      } else {
        readModel.filters.inventory_class_id = this.inventoryClassId;
        let res = await this.$api.inventoryItem.readData(readModel);
        const cancelCode = 3001;
        if (res === cancelCode) return;
        if (!res || !res.data || res.data.length === 0) {
          this.inventoryItems = [];
          return;
        }
        this.inventoryItems = res.data;
      }
    },
    async getUoms() {
      this.uoms = await this.$api.uom.getList();
    },
    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
    },
    async getLengths() {
      this.lengths = await this.$api.length.getList();
    },
    async getLocations() {
      const res = await this.$api.location.get();

      if (!res) return;
      this.locations = res;
    },

    async getManufacturers() {
      this.manufacturers = await this.$api.manufacturer.getList();
    },
    async getFinishings() {
      this.finishings = await this.$api.finishing.getList();
    },
    async getMaterialProperties() {
      this.materialProperties = await this.$api.materialProperty.getList();
    },

    async submit() {
      if (!this.item) return false;
      if (parseInt(this.item.caution_level, 10) > 0 && parseInt(this.item.danger_level, 10) > parseInt(this.item.caution_level, 10)) {
        this.$bus.$emit('showError', 'Danger Level cannot be greater than Caution Level');
        return false;
      }
      if (parseInt(this.item.limit_level, 10) < parseInt(this.item.caution_level, 10) || parseInt(this.item.limit_level, 10) < parseInt(this.item.danger_level, 10)) {
        this.$bus.$emit('showError', 'Limit Level cannot be less than Caution Level or Danger Level');
        return false;
      }
      const userId = this.$store.getters.user.id;
      if (userId) this.item.created_by = userId;

      this.isPaintClass ? this.item.inventory_item_id = null : this.item.glass_paint_code_id = null;

      let res;
      if (this.item.id) {
        res = await this.$api.dangerLevel.update(this.item);
      } else {
        res = await this.$api.dangerLevel.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
