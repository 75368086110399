import axios from 'axios';
import Api from './Api';

class EquipmentMovementsApi extends Api {
  constructor() {
    super('equipment_movement', 'equipment_movements');
  }

  async getFilteredList(filters) {
    let list = [];
    const errorText = 'Could not fetch equipment movement list';

    try {
      list = await axios.post('/equipment_movements', filters);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getCurrentStatuses() {
    let list = [];
    const errorText = 'Could not fetch current statuses';

    try {
      list = await axios.get('/equipment_movements/current_statuses');
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getActiveJobs() {
    let list = [];
    const errorText = 'Could not fetch active jobs';

    try {
      list = await axios.get('/equipment_movements/active_jobs');
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getActiveUserPossessions() {
    let list = [];
    const errorText = 'Could not fetch active jobs';

    try {
      list = await axios.get('/equipment_movements/active_user_possessions');
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getActiveWarehouseShelfs() {
    let list = [];
    const errorText = 'Could not fetch active jobs';

    try {
      list = await axios.get('/equipment_movements/active_warehouse_shelfs');
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getCurrentJobStatuses(jobId) {
    let res = null;
    const errorText = 'Could not fetch current statuses by job';
    const data = {
      job_id: jobId,
    };
    try {
      res = await axios.post('/equipment_movements/current_statuses_by_job', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }

    return res;
  }

  async getCurrentUserPossessionStatuses(userId) {
    let res = null;
    const errorText = 'Could not fetch current statuses by user possession id';
    const data = {
      user_id: userId,
    };
    try {
      res = await axios.post('/equipment_movements/current_statuses_by_user_possession', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }

    return res;
  }

  async getCurrentWarehouseShelfStatuses(shelf) {
    let res = null;
    const errorText = 'Could not fetch current statuses by warehouse shelf';
    const data = {
      warehouse_shelf: shelf,
    };
    try {
      res = await axios.post('/equipment_movements/current_statuses_by_warehouse', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }

    return res;
  }

  async getCurrentStatusesBetweenDateRange(filters) {
    let res = null;
    const errorText = 'Could not fetch current statuses between dates';
    const data = {
      from: filters.from,
      to: filters.to,
    };
    try {
      res = await axios.post('/equipment_movements/current_statuses_between_dates', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async getCurrentStatus(data) {
    let res = null;
    const errorText = 'Could not fetch current status';

    try {
      res = await axios.post('/equipment_movements/current_status', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }

    return res;
  }

  async getSingleMovement(data) {
    let res = null;
    const errorText = 'Could not fetch equipment movement';

    try {
      res = await axios.post('/equipment_movements/single_movement', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }

    return res;
  }

  async getLatestMovement(data) {
    let res = null;
    const errorText = 'Could not fetch latest equipment movement';
    try {
      res = await axios.post('/equipment_movements/latest_movement', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async getPhoto(data) {
    let res = null;
    const errorText = 'Could not fetch equipment photo';

    try {
      res = await axios.post('/equipment_movements/photo', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }

    return res;
  }
}
const equipmentMovementsApi = new EquipmentMovementsApi();

export default equipmentMovementsApi;
