<template>
  <v-container>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :submit-btn-text="'Submit'"
      :max-width="1200"
      :max-height="1000"
    >
      <template slot="header">
        <span class="headline">UPC Receiving</span>
      </template>
      <template slot="body">
        <v-container
          style="font-size: 1.3em !important;"
          fluid
          class="mt-3"
        >
          <v-row>
            <v-col
              class="pa-0"
              cols="1"
              md="1"
              sm="2"
              style="text-align:right"
            >
              <v-row>
                <v-col class="text-center">
                  <h5 class="h5">
                    UPC Code
                  </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <h6>
                    Inventory Class
                  </h6>
                  <v-chip
                    class="mt-1 mb-1"
                    text-color="white"
                    style="cursor: pointer;"
                    :color="inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.color : 'white'"
                  >
                    <span style="font-size: 20px; color: #fff !important;">{{ inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.name : '-' }}</span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="pa-0"
              cols="1"
              md="1"
              sm="2"
              style="text-align:center"
            >
              <div
                style="border-left: 1px solid;
            height: 90%; margin-left:30px;"
              />
            </v-col>
            <v-col
              class="pa-0"
              cols="3"
              md="3"
              sm="8"
            >
              <v-img
                :src="convertedPhoto(inventoryTag && inventoryTag.item ? inventoryTag.item.photo : '', inventoryTag && inventoryTag.item ? inventoryTag.item.mime_type : '')"
                contain
                height="100%"
                width="100%"
                class="profile-photo"
              />
            </v-col>
            <v-col
              class="pa-0 text-center"
              md="7"
              sm="12"
            >
              <v-row>
                <v-col
                  lg="6"
                  md="6"
                  sm="8"
                  class="text-center pt-0"
                >
                  <h5 class="h5">
                    Inventory Item
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.item_full_name : '' }}</span>
                </v-col>
                <v-col
                  lg="6"
                  md="6"
                  sm="4"
                  class="text-center pt-0"
                >
                  <h5 class="h5">
                    UOM
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.uom_name : '' }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col

                  class="text-center"
                >
                  <h5 class="h5">
                    Location
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.location_name : '' }}</span>
                </v-col>
                <v-col
                  class="text-center"
                >
                  <h5 class="h5">
                    Shelf
                  </h5>
                  <span>{{ inventoryTag ? inventoryTag.inventory_location_name : '' }}</span>
                </v-col>
                <v-col
                  class="text-center"
                >
                  <h5 class="h5">
                    Qty on Hand
                  </h5> <span> {{ inventoryTag ? inventoryTag.quantity_on_hand : '' }}</span>
                </v-col>
                <v-col
                  v-if="dangerLevel && dangerLevel.limit_level"
                  class="text-center"
                >
                  <h5 class="h5">
                    Limit Level
                  </h5> <span> {{ dangerLevel ? dangerLevel.limit_level : '' }}</span>
                </v-col>
                <v-col
                  v-if="dangerLevel && dangerLevel.danger_level"
                  class="text-center"
                >
                  <h5 class="h5">
                    Danger Level
                  </h5> <span> {{ dangerLevel ? dangerLevel.danger_level : '' }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="3"
                  sm="6"
                  class="pt-12 text-center"
                >
                  <v-text-field
                    v-model="quantity"
                    :rules="[(v) => !!v || 'Quantity is required']"
                    type="number"
                  >
                    <template slot="label">
                      Quantity Received<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  md="4"
                  sm="6"
                  class="pt-12 text-center"
                >
                  <v-autocomplete
                    v-model="poNumber"
                    :items="inventoryPos"
                    :clearable="true"
                    label="PO#"
                    :rules="[(v) => !!v || 'PO# is required']"
                  >
                    <template slot="label">
                      PO#<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  md="5"
                  sm="6"
                  class="pt-12 text-center"
                >
                  <v-file-input
                    v-model="supplierLabel"
                    accept="image/*"
                    capture="user"
                    label="Supplier Label"
                    item-text="name"
                    item-value="id"
                  >
                    <template slot="label">
                      Supplier Label<RedAsterisk />
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </the-dialog-box>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    supplierLabel: null,
    dangerLevel: null,
    poNumber: '',
    quantity: 0,
    // maxInputQuantity: 0,

    inventoryPos: [],

    classPartsId: 11,
  }),
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
    }),
  },
  created() {
  },
  methods: {

    async open(qrCode) {
      this.$refs.dialogBox.open();
      this.supplierLabel = null;
      this.dangerLevel = null;
      this.poNumber = '';
      this.quantity = 0;
      this.qrCode = qrCode;
      await this.getInventoryTag();
      await this.getDangerLevel();
      await this.getInventoryPos();
      // this.setMaxInputQuantity();
    },

    async getInventoryTag() {
      if (!this.qrCode) return;

      const isQtyOnHandZero = 0;

      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
          is_qty_on_hand_o: isQtyOnHandZero,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data || !res.data[0]) {
        return;
      }

      const firstIndex = 0;
      this.inventoryTag = res.data[firstIndex];
    },

    async getDangerLevel() {
      if (this.inventoryTag && this.inventoryTag.item && this.inventoryTag.item.classes && this.inventoryTag.item.classes.id !== this.classPartsId) return;
      if (!this.qrCode) return;

      const submitModel = {
        filters: {
          inventory_item_id: this.inventoryTag.inventory_item_id,
        },
      };

      const res = await this.$api.dangerLevel.read(submitModel);
      if (!res || res.length < 1) {
        this.dangerLevel = null;
        return;
      }

      const firstIndex = 0;
      this.dangerLevel = res[firstIndex];
    },

    async getInventoryPos() {
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryTag && this.inventoryTag.item && this.inventoryTag.item.classes && this.inventoryTag.item.classes.id,
        },
      };
      const res = await this.$api.inventoryPo.read(submitModel, null, 'read-simple');
      if (!res) {
        return;
      }
      this.inventoryPos = ['Not Applicable', ...res];
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },

    setMaxInputQuantity() {
      this.maxInputQuantity = this.dangerLevel && this.inventoryTag ? this.dangerLevel.limit_level - this.inventoryTag.quantity_on_hand : 0;
    },

    async submit() {
      if (!this.inventoryTag) return false;

      this.$bus.$emit('loading');
      const data = new FormData();

      // Qr Code is treated as inv tag for now
      if (this.qrCode) {
        data.append('inventory_tag', this.qrCode);
      }

      if (this.quantity) {
        data.append('quantity', this.quantity);
      }

      if (this.userId) {
        data.append('created_by', this.userId);
      }

      if (this.poNumber) {
        data.append('po_number', this.poNumber);
      }

      if (this.supplierLabel) {
        data.append('supplier_label', this.supplierLabel);
      }

      const res = await this.$api.inventoryTag.upcReceiving(data);
      this.$bus.$emit('loading-stop');
      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      this.$bus.$emit('showSuccess');
      return true;
    },
  },
};
</script>

<style scoped>
span{
  color: black !important;
}
</style>
