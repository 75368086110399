<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        v-if="businessRulesGetBoolean('Inventory Classes')"
        cols="1"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
          :is-inst="false"
          :is-glass="false"
          :is-panels="false"
          :is-sid="false"
          :is-add-all="false"
          :is-rail-part="false"
          :is-railings="false"
          :is-commercial="false"
          :is-vwd-glass="false"
          :is-paint="false"
          :is-parts="false"
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterItemId"
          label="Item"
          :items="inventoryItems"
          :item-text="item => `${item.material_id} ${item.name}`"
          item-value="id"
          hide-details="true"
          clearable
          :loading="loadingInventoryItem"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterColorId"
          label="Colour"
          :items="colors"
          :item-text="item => item.code ? item.code : item.name ? item.name: ''"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #selection="{ item }">
            <span>
              {{ item.code ? item.code : item.name ? item.name: '' }}
            </span>
          </template>
          <template #item="{ item }">
            <span>
              {{ item.code ? item.code : item.name ? item.name: '' }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterLengthId"
          label="Length"
          :items="lengths"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterUomId"
          label="UOM"
          :items="uoms"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        v-if="businessRulesGetBoolean('Inventory Classes')"
        cols="auto"
        class="pb-0 mt-4"
      >
        <v-checkbox
          v-model="isHideFpgestTotals"
          class="mt-0 mb-0"
          label="Hide FPGEST Totals"
        />
      </v-col>
      <v-col
        v-if="businessRulesGetBoolean('Inventory Classes')"
        cols="auto"
        class="pb-0 mt-4"
      >
        <v-checkbox
          v-model="isHideZeroOnHand"
          class="mt-0 mb-0"
          label="Hide Zero 'Qty On Hand'"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="levels-table"
            :headers="levelsHeaders"
            :items="levels"
            :footer-props="levelsTableFooterProps"
            :items-per-page="levelsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            @pagination="updatePagination"
          >
            <template
              v-for="h in levelsHeaders"
              #[`header.${h.value}`]="{ header }"
            >
              <v-tooltip
                :key="h.value"
                bottom
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>{{ header.fullText }}</span>
              </v-tooltip>
            </template>
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                class="mt-0 mb-0"
                small
                :color="item.item && item.item.classes ? item.item.classes.color : 'white'"
                text-color="white"
              >
                {{ item.item && item.item.classes ? item.item.classes.name : '' }}
              </v-chip>
            </template>
            <template #[`item.unique_code`]="{ item }">
              <TextHighlight :queries="search">
                {{ item.unique_code ? item.unique_code : '-' }}
              </TextHighlight>
            </template>
            <template #[`item.item_full_name`]="{ item }">
              <TextHighlight :queries="search">
                {{ item.item_full_name ? item.item_full_name : '-' }}
              </TextHighlight>
            </template>
            <template #[`item.category`]="{ item }">
              <TextHighlight :queries="search">
                {{ item.item_category }}
              </TextHighlight>
            </template>
            <template #[`item.manufacturer_name`]="{ item }">
              <TextHighlight :queries="search">
                {{ item.manufacturer_name ? item.manufacturer_name : '-' }}
              </TextHighlight>
            </template>
            <template #[`item.material_property_name`]="{ item }">
              <TextHighlight :queries="search">
                {{
                  item.material_property_name
                    ? item.material_property_name
                    : '-'
                }}
              </TextHighlight>
            </template>
            <template #[`item.length`]="{ item }">
              <TextHighlight :queries="search">
                {{ item.length_name ? item.length_name : '-' }}
              </TextHighlight>
            </template>
            <template #[`item.color`]="{ item }">
              <TextHighlight :queries="search">
                {{ item.color_code ? item.color_code : '-' }} {{ item.color_name ? item.color_name : '-' }}
              </TextHighlight>
            </template>
            <template #[`item.finishing_name`]="{ item }">
              <TextHighlight :queries="search">
                {{ item.finishing_name ? item.finishing_name : '-' }}
              </TextHighlight>
            </template>
            <template #[`item.uom`]="{ item }">
              {{ item.uom_name }}
            </template>
            <template #[`item.fp_gest_quantity_on_hand`]="{ item }">
              <span :class="item.is_total ? 'font-weight-bold' : ''">{{ item && item.fp_gest_quantity_on_hand ? item.fp_gest_quantity_on_hand.toLocaleString() : '0' }}</span>
            </template>
            <template #[`item.fp_gest_quantity_committed`]="{ item }">
              <span :class="item.is_total ? 'font-weight-bold' : ''">{{ item && item.fp_gest_quantity_committed ? item.fp_gest_quantity_committed.toLocaleString() : '0' }}</span>
            </template>
            <template #[`item.fp_gest_quantity_available`]="{ item }">
              <span :class="item.is_total ? 'font-weight-bold' : ''">{{ item && item.fp_gest_quantity_available ? item.fp_gest_quantity_available.toLocaleString() : '0' }}</span>
            </template>
            <template #[`item.erp_quantity_on_hand`]="{ item }">
              <span :class="item.is_total ? 'font-weight-bold' : ''">{{ item && item.erp_quantity_on_hand ? item.erp_quantity_on_hand.toLocaleString() : '0' }}</span>
            </template>
            <template #[`item.erp_quantity_available`]="{ item }">
              <span :class="item.is_total ? 'font-weight-bold' : ''">{{ item && item.erp_quantity_available ? item.erp_quantity_available.toLocaleString() : '0' }}</span>
            </template>
            <template #[`item.variance`]="{ item }">
              <v-row>
                <v-col>
                  <span :class="[parseInt(item.on_hand_difference) < 0 ? 'red--text' : '', item.is_total ? 'font-weight-bold' : '']">{{ item && item.on_hand_difference ? item.on_hand_difference.toLocaleString() : '0' }}</span>
                </v-col>
                <v-col :class="[parseInt(item.on_hand_difference) < 0 || item.on_hand_difference_percent === '?' ? 'red--text' : '', 'text-center', item.is_total ? 'font-weight-bold' : '']">
                  <span>{{ item && item.on_hand_difference_percent ? item.on_hand_difference_percent.toLocaleString() : '0' }}{{ item.on_hand_difference_percent == 0 || item.on_hand_difference_percent === '?' ? '' : '%' }}</span>
                </v-col>
              </v-row>
            </template>
            <template #[`item.total_quantity`]="{ item }">
              <span>{{ item && item.total_quantity ? item.total_quantity : '' }}</span>
            </template>
            <template #[`item.levels`]="{ item }">
              <v-row v-if="businessRulesGetBoolean('Inventory Classes')">
                <v-col>
                  <v-tooltip
                    v-if="item.erp_quantity_on_hand < item.danger_level"
                    top
                  >
                    <template #activator="{ on }">
                      <v-icon
                        color="red"
                        dark
                        class="py-1"
                        v-on="on"
                      >
                        fas fa-exclamation-circle
                      </v-icon>
                    </template>
                    <span>DANGER: Quantity is lower than
                      {{ item.danger_level }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="item.erp_quantity_on_hand < item.caution_level"
                    top
                  >
                    <template #activator="{ on }">
                      <v-icon
                        color="orange"
                        dark
                        class="py-1"
                        v-on="on"
                      >
                        fas fa-exclamation-circle
                      </v-icon>
                    </template>
                    <span>CAUTION: Quantity is lower than
                      {{ item.caution_level }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-tooltip
                    v-if="item.total_quantity < item.danger_level"
                    top
                  >
                    <template #activator="{ on }">
                      <v-icon
                        color="red"
                        dark
                        class="py-1"
                        v-on="on"
                      >
                        fas fa-exclamation-circle
                      </v-icon>
                    </template>
                    <span>DANGER: Quantity is lower than
                      {{ item.danger_level }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="item.total_quantity < item.caution_level"
                    top
                  >
                    <template #activator="{ on }">
                      <v-icon
                        color="orange"
                        dark
                        class="py-1"
                        v-on="on"
                      >
                        fas fa-exclamation-circle
                      </v-icon>
                    </template>
                    <span>CAUTION: Quantity is lower than
                      {{ item.caution_level }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <webhook-inventory-form ref="webhookForm" />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { mapGetters, mapState } from 'vuex';
import TransactionsApi from '@/api/TransactionsApi';
import WebhookInventoryForm from '@/components/forms/webhooks/WebhookInventoryForm';
import ExcelHelper from '../../helpers/ExcelHelper';
import PdfHelper from '../../helpers/PdfHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'CurrentLevels',
  components: {
    TextHighlight,
    WebhookInventoryForm,
  },
  data: () => ({
    search: '',
    loading: false,
    levels: [],
    levelsTableFooterProps: {
      'items-per-page-options': [20, 50, 100, 500, 1000, -1],
    },
    levelsPerPage: 20,
    inventoryClassId: null,
    inventoryItems: [],
    lengths: [],
    colors: [],
    uoms: [],
    locations: [],
    shelves: [],
    filterItemId: null,
    filterLengthId: null,
    filterColorId: null,
    filterUomId: null,
    filterLocationId: null,
    filterShelfId: null,
    isHideFpgestTotals: false,
    isHideZeroOnHand: false,
    topSpaceHeight: 290,
    windowHeight: 1000,
    loadingInventoryItem: false,
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      currentLevelsState: (state) => state.currentLevelsState,
    }),
    levelsHeaders() {
      const headers = [];

      const inventoryClassBRName = 'Inventory Classes';
      const inventoryClassBRValue = this.businessRulesGetBoolean(inventoryClassBRName);
      if (inventoryClassBRValue != null && inventoryClassBRValue !== 0) {
        headers.push({
          text: 'Class', value: 'inventory_class', width: '5%', sortable: false,
        });
      }

      const uniqueItemCodeBRName = 'Unique Item Code';
      const uniqueItemCodeBRValue = this.businessRulesGetBoolean(uniqueItemCodeBRName);
      if (uniqueItemCodeBRValue != null && uniqueItemCodeBRValue !== 0) headers.push({ text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' });

      headers.push({ text: 'Item Name', value: 'item_full_name' });

      const inventoryTagBRName = 'Inventory tags';
      const inventoryTagBRValue = this.businessRulesGetBoolean(inventoryTagBRName);
      if (inventoryTagBRValue === null || inventoryTagBRValue === 0) { headers.push({ text: 'Category', value: 'category' }); }

      const manufacturerIdBRName = 'Manufacturer';
      const manufacturerIdBRValue = this.businessRulesGetBoolean(manufacturerIdBRName);
      if (manufacturerIdBRValue != null && manufacturerIdBRValue !== 0) {
        headers.push({ text: 'Manufacturer', value: 'manufacturer_name' });
      }

      const materialPropertyIdBRName = 'Material Property';
      const materialPropertyIdBRValue = this.businessRulesGetBoolean(materialPropertyIdBRName);
      if (materialPropertyIdBRValue != null && materialPropertyIdBRValue !== 0) {
        headers.push({
          text: 'Material Property',
          value: 'material_property_name',
        });
      }
      headers.push({ text: 'Colour', value: 'color' });
      headers.push({ text: 'Length', value: 'length' });

      const finishingIdBRName = 'Finishing';
      const finishingIdBRValue = this.businessRulesGetBoolean(finishingIdBRName);
      if (finishingIdBRValue != null && finishingIdBRValue !== 0) {
        headers.push({ text: 'Finishing', value: 'finishing_name' });
      }
      headers.push({ text: 'UOM', value: 'uom' });

      const fpGestQtyBRName = 'FP GEST Qty';
      const fpGestQtyBRValue = this.businessRulesGetBoolean(fpGestQtyBRName);
      if (fpGestQtyBRValue != null && fpGestQtyBRValue !== 0) {
        if (this.currentLevelsState && !this.currentLevelsState.isHideFpgestTotals) {
          headers.push({
            text: 'FPGEST On Hand', fullText: 'FPGEST Quantity On Hand', value: 'fp_gest_quantity_on_hand', width: '5%',
          });
          headers.push({
            text: 'FPGEST Comm', fullText: 'FPGEST Quantity Committed', value: 'fp_gest_quantity_committed', width: '5%',
          });
          headers.push({
            text: 'FPGEST Av', fullText: 'FPGEST Quantity Available', value: 'fp_gest_quantity_available', width: '5%',
          });
        }

        headers.push({
          text: 'ERP On Hand', fullText: 'ERP Quantity On Hand', value: 'erp_quantity_on_hand', width: '5%',
        });
        headers.push({ text: 'ERP Av', fullText: 'ERP Quantity Available', value: 'erp_quantity_available' });
        if (this.currentLevelsState && !this.currentLevelsState.isHideFpgestTotals) {
          headers.push({
            text: 'Variance', value: 'variance', align: 'center', width: '10%',
          });
        }
      } else {
        headers.push({ text: 'Quantity', value: 'total_quantity' });
      }
      headers.push({ text: '', value: 'levels' });
      return headers;
    },
  },
  watch: {
    inventoryClassId(value) {
      const state = this.currentLevelsState;
      state.class_id = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
      this.getItems();
    },
    filterItemId(value) {
      const state = this.currentLevelsState;
      state.inventory_item_id = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
    },
    filterLengthId(value) {
      const state = this.currentLevelsState;
      state.length_id = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
    },
    filterColorId(value) {
      const state = this.currentLevelsState;
      state.color_id = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
    },
    filterUomId(value) {
      const state = this.currentLevelsState;
      state.uom_id = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
    },
    filterLocationId(value) {
      const state = this.currentLevelsState;
      state.location_id = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
      this.getShelves();
    },
    filterShelfId(value) {
      const state = this.currentLevelsState;
      state.shelf_id = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
    },
    isHideFpgestTotals(value) {
      const state = this.currentLevelsState;
      state.isHideFpgestTotals = value;
      this.$store.commit('storeCurrentLevelsState', state);
    },
    isHideZeroOnHand(value) {
      const state = this.currentLevelsState;
      state.isHideZeroOnHand = value;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
    },
    pagination() {
      const state = this.currentLevelsState;
      state.perPage = this.pagination.itemsPerPage;
      this.$store.commit('storeCurrentLevelsState', state);
      this.get();
    },

  },
  created() {
    this.initialize();
    if (this.currentLevelsState) {
      if (this.currentLevelsState.perPage) { this.levelsPerPage = this.currentLevelsState.perPage; }
      if (this.currentLevelsState.inventory_item_id) { this.filterItemId = this.currentLevelsState.inventory_item_id; }
      if (this.currentLevelsState.length_id) { this.filterLengthId = this.currentLevelsState.length_id; }
      if (this.currentLevelsState.color_id) { this.filterColorId = this.currentLevelsState.color_id; }
      if (this.currentLevelsState.uom_id) { this.filterUomId = this.currentLevelsState.uom_id; }
      if (this.currentLevelsState.location_id) { this.filterLocationId = this.currentLevelsState.location_id; }
      if (this.currentLevelsState.shelf_id) { this.filterShelfId = this.currentLevelsState.shelf_id; }
      if (this.currentLevelsState.class_id) { this.inventoryClassId = this.currentLevelsState.class_id; }
      if (this.currentLevelsState.isHideZeroOnHand) { this.isHideZeroOnHand = this.currentLevelsState.isHideZeroOnHand; }
      if (this.currentLevelsState.isHideFpgestTotals) { this.isHideFpgestTotals = this.currentLevelsState.isHideFpgestTotals; }
    }
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async initialize() {
      this.get();
      this.getItems();
      this.getUoms();
      this.getColors();
      this.getLengths();
      this.getShelves();
      this.getLocations();
    },

    async get() {
      const bomBRName = 'Bom/rma code';
      const bomBRValue = this.businessRulesGetBoolean(bomBRName);
      if (bomBRValue != null && bomBRValue !== 0) {
        this.loading = true;
        this.levels = await TransactionsApi.getCurrentLevels();
        this.loading = false;
      } else {
        if (this.inventoryClassId === null) return;
        this.loading = true;
        this.levels = [];
        const readModel = {
          filters:
          {
            inventory_class_id: this.inventoryClassId,
            inventory_item_id: this.filterItemId,
            color_id: this.filterColorId,
            length_id: this.filterLengthId,
            uom_id: this.filterUomId,
            is_zero_on_hand: this.isHideZeroOnHand ? 1 : 0,
          },
        };
        const res = await TransactionsApi.readLevels(readModel);
        const cancelCode = 3001;
        if (res === cancelCode) return;
        this.loading = false;
        this.levels = res;
      }
    },

    async getItems() {
      this.loadingInventoryItem = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },
    async getUoms() {
      this.uoms = await this.$api.uom.getList();
    },
    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
    },
    async getLengths() {
      this.lengths = await this.$api.length.getList();
    },
    async getShelves() {
      if (!this.filterLocationId) return;
      this.$nextTick(async () => {
        const readModel = {
          filters:
          {
            location_id: this.filterLocationId,
          },
        };
        this.shelves = await this.$api.inventoryLocation.read(readModel);
      });
    },
    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    updateSearch(search) {
      this.search = search;
    },
    generateExcel() {
      let excelData = null;
      if (this.businessRulesGetBoolean('Inventory tags')) {
        excelData = this.levels.map((el) => ({
          'Inventory Class': el.item && el.item.classes ? el.item.classes.name : '',
          'Die #': el.material_id,
          'Item Name': el.item_name,
          Color: el.color_code ? el.color_code : el.color_name,
          Length: el.length_name,
          UOM: el.uom_name,
          'FPGEST Quantity On Hand': parseInt(el.fp_gest_quantity_on_hand ? el.fp_gest_quantity_on_hand : 0, 10),
          'FPGEST Quantity Committed': parseInt(el.fp_gest_quantity_committed ? el.fp_gest_quantity_committed : 0, 10),
          'FPGEST Quantity Available': parseInt(el.fp_gest_quantity_available ? el.fp_gest_quantity_available : 0, 10),
          'ERP Quantity On Hand': parseInt(el && el.erp_quantity_on_hand ? el.erp_quantity_on_hand : 0, 10),
          'ERP Quantity Available': parseInt(el.erp_quantity_available ? el.erp_quantity_available : 0, 10),
          'On Hand Difference': parseInt(el.on_hand_difference ? el.on_hand_difference : 0, 10),
          'On Hand Difference %': `${el && el.on_hand_difference_percent ? el.on_hand_difference_percent : '0'} ${el.on_hand_difference_percent === 0 || el.on_hand_difference_percent === '?' ? '' : '%'}`,
        }));
      } else {
        excelData = this.levels.map((el) => ({
          'Unique Item Code': el.unique_code,
          'Die #': el.material_id,
          'Item Name': el.item_name,
          Manufacturer: el.manufacturer,
          'Material Property': el.material_property,
          Length: el.length_name,
          Color: el.color_code ? el.color_code : el.color_name,
          Finishing: el.finishing,
          Quantit: parseInt(el.quantity, 10),
          UOM: el.uom_name,
        }));
      }
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Current Levels');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Levels_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      this.levelsPerPage = -1;
      await this.$nextTick();
      const pdf = PdfHelper.generateFromTable(
        '#levels-table > div > table',
        'landscape',
        'Current Inventory Levels',
        'current-levels',
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Current-Levels_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },

    openWebhookFormPdf(subModuleId) {
      const moduleIdCurrentLevels = 18;
      const fileTypePdf = 'pdf';
      this.$refs.webhookForm.open(moduleIdCurrentLevels, fileTypePdf, subModuleId);
    },

    openWebhookFormExcel(subModuleId) {
      const moduleIdCurrentLevels = 18;
      const fileTypePdf = 'xls';
      this.$refs.webhookForm.open(moduleIdCurrentLevels, fileTypePdf, subModuleId);
    },

    updatePagination(pagination) {
      const state = this.currentLevelsState;
      state.perPage = pagination.itemsPerPage;
      this.$store.commit('storeCurrentLevelsState', state);
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },
  },
};
</script>
<style scoped>

::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}
</style>
