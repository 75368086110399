<template>
  <v-container>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :submit-btn-text="'Submit'"
      :max-width="600"
      :btn-loading="loading"
    >
      <template slot="header">
        <span class="headline">Multi-Product QR Receiving</span>
      </template>
      <template slot="body">
        <v-container
          style="font-size: 1.3em !important;"
          fluid
          class="mt-3"
        >
          <v-row
            class="pa-3"
            style="background-color: #E8F5E9; border: 2px solid rgba(0,0,0,0.2); border-radius: 4px;"
          >
            <v-col
              class="text-center"
              cols="6"
            >
              <h5 class="h5">
                MPQR Code
              </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
            </v-col>
            <v-col
              class="text-center"
              cols="6"
            >
              <h5 class="h5">
                Inventory Class
              </h5>
              <v-chip
                class="mt-1 mb-1"
                text-color="white"
                style="cursor: pointer;"
                color="#8dbf8d"
              >
                <span style="font-size: 20px; color: #fff !important;">PAINT</span>
              </v-chip>
            </v-col>
            <v-col
              class="pb-0"
              cols="6"
            >
              <v-autocomplete
                v-model="inventoryTag.location_id"
                :disabled="inventoryTag.id != 0"
                :items="locations"
                :loading="loading"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Location is required']"
              >
                <template slot="label">
                  Location<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              class="pb-0"
              cols="6"
            >
              <v-autocomplete
                v-model="inventoryTag.inventory_location_id"
                :items="shelfs"
                :disabled="inventoryTag.id != 0"
                :loading="loading"
                item-text="name"
                item-value="id"
                :clearable="true"
                :rules="[(v) => !!v || 'Shelf is required']"
              >
                <template slot="label">
                  Shelf<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-6">
              <span style="font-size: 18px;">Add Items to the location</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-autocomplete
                v-model="mpqrItem.po_number"
                :items="inventoryPos"
                :clearable="true"
                label="PO#"
                :rules="[(v) => !!v || 'PO# is required']"
                @change="setProject(mpqrItem.po_number)"
              >
                <template slot="label">
                  PO#<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-autocomplete
                v-model="mpqrItem.project_id"
                clearable
                :items="projects"
                :loading="loadingProject"
                item-text="name"
                item-value="id"
                label="Project"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-autocomplete
                v-model="mpqrItem.glass_paint_code_id"
                clearable
                :items="glassPaintCodes"
                item-value="id"
                :loading="loadingItem"
                :item-text="
                  (item) => item.code + ': ' + item.description
                "
                :rules="[(v) => !!v || 'Paint Code is required']"
              >
                <template slot="label">
                  Paint Code<RedAsterisk />
                </template>
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    color="#8dbf8d"
                  >
                    PAINT
                  </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    color="#8dbf8d"
                  >
                    PAINT
                  </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model="mpqrItem.uom_id"
                :items="uoms"
                clearable
                :loading="loadingUom"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'UOM is required']"
              >
                <template slot="label">
                  UOM<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="mpqrItem.quantity"
                :rules="[(v) => !!v || 'Quantity is required', (!mpqrItem.id || mpqrItem.id === 0) ? rules.wholeNumber : rules.wholeAndHalfNumber]"
                type="number"
                min="0"
                :step="(!mpqrItem.id || mpqrItem.id === 0) ? '1' : '0.5'"
              >
                <template slot="label">
                  Quantity<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </the-dialog-box>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    qrCode: null,
    inventoryTag: null,
    inventoryTagDto: {
      id: 0,
      inventory_tag: null,
      location_id: null,
      inventory_location_id: null,
    },
    mpqrItem: null,
    mpqrItemDto: {
      id: 0,
      inventory_tag: null,
      glass_paint_code_id: null,
      uom_id: null,
      quantity: null,
      project_id: null,
      po_number: null,
    },
    inventoryClassIdPaint: 12,
    glassPaintCodes: [],
    locations: [],
    shelfs: [],
    uoms: [],
    colors: [],
    projects: [],
    inventoryPos: [],
    roles: [],
    loadingItem: false,
    loadingUom: false,
    loadingShelf: false,
    loadingLocation: false,
    loadingColor: false,
    loading: false,
    loadingProject: false,
    uomIdBucket: 9,
    userRoleIdAdmin: 3,

    rules: {
      wholeNumber: (v) => /^\d+$/.test(v) || 'Please enter a whole number',
      wholeAndHalfNumber: (v) => /^(0?(\.5)?|[1-9]\d*(\.0|\.5)?)$/.test(v) || 'Enter a whole number or a number ending in .5'
    }
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isAdmin() {
      return this.roles.includes(this.userRoleIdAdmin);
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'inventoryTag.location_id'() {
      this.getShelves();
    },
  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.inventoryTag = { ...this.inventoryTagDto };
    this.mpqrItem = { ...this.mpqrItemDto };
  },
  methods: {

    async open(qrCode, mpqrItemId = null) {
      this.qrCode = qrCode;
      this.inventoryTag = { ...this.inventoryTagDto };
      this.mpqrItem = { ...this.mpqrItemDto };
      this.mpqrItem.uom_id = this.uomIdBucket;
      this.$refs.dialogBox.open();       
      await this.getInventoryTag(mpqrItemId);
      if ((!this.inventoryTag.id || this.inventoryTag.id === 0) && !this.isAdmin) {
        this.$bus.$emit('showError', 'MPQR creation restricted to admins');
        this.$refs.dialogBox.close(); // opening then closing if restricted is weird UI/UX because the user will see the form for like 1ms but if we don't open it first, then vue won't register the v-models so if you get an existing inventory tag then open the dialog box, the fields won't be populated
        return
      }
      this.getGlassPaintCodes();
      this.getLocations();
      this.getProjects();
      this.getUoms();
      this.getInventoryPos();
    },

    async getInventoryTag(mpqrItemId = null) {
      if (!this.qrCode) return;
      this.loading = true;

      const isQtyOnHandZero = 0;

      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
          is_qty_on_hand_o: isQtyOnHandZero,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data || !res.data[0]) {
        this.loading = false;
        return;
      }

      const firstIndex = 0;
      this.inventoryTag = res.data[firstIndex];
      if (mpqrItemId) {
        this.mpqrItem = this.inventoryTag.multi_products.find((item) => item.id === mpqrItemId);
      }
      this.loading = false;
    },

    async getUoms() {
      this.loadingUom = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.mpqrItem.inventory_item_id,
        },
      };
      this.uoms = await this.$api.uom.read(submitData);
      this.loadingUom = false;
    },

    async getGlassPaintCodes() {
      this.loading = true;
      const submitModel = {
        filters: {
          is_awd_paint: true,
        },
      };
      this.glassPaintCodes = await this.$api.glassPaintCode.read(submitModel);
      this.loading = false;
    },

    async getLocations() {
      this.loadingLocation = true;
      this.locations = await this.$api.location.getList();
      this.loadingLocation = false;
    },

    async getShelves() {
      this.loadingShelf = true;
      const readModel = {
        filters:
        {
          location_id: this.inventoryTag.location_id,
        },
      };
      this.shelfs = await this.$api.inventoryLocation.read(readModel);
      this.loadingShelf = false;
    },

    async getProjects() {
      this.loadingProject = true;
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.loadingProject = false;
        return;
      }
      this.projects = res.data;
      this.loadingProject = false;
    },

    async getInventoryPos() {
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassIdPaint,
        },
      };
      const res = await this.$api.inventoryPo.read(submitModel, null, 'read-simple');
      if (!res) {
        return;
      }
      this.inventoryPos = ['Not Applicable', ...res];
    },

    async submit() {
      this.$bus.$emit('loading');
      if (!this.inventoryTag.id) {
        this.inventoryTag.inventory_tag = this.qrCode;
        const resTag = await this.$api.inventoryTag.addTag(this.inventoryTag);

        if (!resTag) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading-stop');
          return false;
        }
      }
      this.mpqrItem.inventory_tag = this.qrCode;
      let res = false;
      if (this.mpqrItem.id) {
        res = await this.$api.inventoryMPQR.update(this.mpqrItem);
      } else {
        res = await this.$api.inventoryMPQR.store(this.mpqrItem);
      }

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading-stop');
        return false;
      }
      if (res == 'exist') {
        this.$bus.$emit('showError', 'An MPQR already exists with these parameters');
        this.$bus.$emit('loading-stop');
        return false;
      }

      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async setProject(poNumber) {
      if (poNumber === 'Not Applicable' || poNumber === null) return;
      this.loadingProject = true;
      const submitData = {
        filters:
        {
          po_number: poNumber,
        },
      };
      const res = await this.$api.inventoryPo.read(submitData);
      if (!res || !res.data || !res.total) {
        this.loadingProject = false;
        return;
      } 
      const firstIndex = 0;
      this.mpqrItem.project_id = res.data[firstIndex] && res.data[firstIndex].job ? res.data[firstIndex].job.project_id : null;
      this.loadingProject = false;
    },
  },
};
</script>

<style scoped>
span{
  color: black !important;
}

/* ::v-deep .theme--light.v-input--is-disabled
{
  color: black !important;
} */
</style>
