<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-tabs
          v-if="dashboardUser"
          v-model="selectedTab"
        >
          <v-tab
            v-for="item in items"
            :key="item.name"
            :disabled="item.is_active == 0 || !dashboardUser.dashboard_ids.includes(item.id)"
            @click="tabChanges(item.index)"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === glassPurchseOrderIndex">
      <v-col class="pb-0">
        <div
          id="2dd6b8ba-ad4c-4105-8f59-01e5f7faf129"
          class="reportContainer"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === chrisleaInventoryIndex">
      <v-col class="pb-0">
        <div
          id="6c382cb1-d915-4b35-8997-b705dd105e6e"
          class="reportContainer"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === procoreCorrespondenceIndex">
      <v-col class="pb-0">
        <div
          id="3479a8f7-2b47-4c96-8e24-9d02831a312b"
          class="reportContainer"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === awdBreakdownIndex">
      <v-col class="pb-0">
        <div
          id="ac5d6f2c-33ac-459b-b5bf-94702202c62f"
          class="reportContainer"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === awdPaintConsumptionIndex">
      <v-col class="pb-0">
        <div
          id="662c65a1-fb51-4d73-bff7-830492c3e935"
          class="reportContainer"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as pbi from 'powerbi-client';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    selectedTab: null,
    items: [],
    dashboardUser: null,
    glassPurchseOrderIndex: 0,
    glassPurchaseOrderId: '2dd6b8ba-ad4c-4105-8f59-01e5f7faf129',
    chrisleaInventoryIndex: 1,
    chrisleaInventoryId: '6c382cb1-d915-4b35-8997-b705dd105e6e',
    procoreCorrespondenceIndex: 2,
    procoreCorrespondenceId: '3479a8f7-2b47-4c96-8e24-9d02831a312b',
    awdBreakdownIndex: 3,
    awdBreakdownId: 'ac5d6f2c-33ac-459b-b5bf-94702202c62f',
    awdPaintConsumptionIndex: 4,
    awdPaintConsumptionId: '662c65a1-fb51-4d73-bff7-830492c3e935',
    previousIdreport: null,
    reports: [],
  }),

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
  },
  mounted() {
    this.getEmbedReports();
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      this.getUsers();
    },

    async get() {
      this.items = await this.$api.dashboards.get();
      // eslint-disable-next-line no-return-assign, no-param-reassign
      this.items.forEach((item, index) => item.index = index);

      const firstItem = this.items.find((item) => item.is_active === 1 && this.dashboardUser.dashboard_ids.includes(item.id));
      if (firstItem) {
        this.selectedTab = firstItem.index;
      }
    },

    async getUsers() {
      const submitModel = {
        filters: {
          user_id: this.user.id,
        },
      };
      const res = await this.$api.dashboards.read(submitModel, null, 'read/users');
      if (!res) return;
      const firstIndex = 0;
      this.dashboardUser = res[firstIndex];
      this.get();
    },
    tabChanges(index) {
      const selectedReport = this.reports[index];
      this.embedReport(selectedReport);
    },
    async getEmbedReports() {
      const reportMappings = [
        { index: this.glassPurchseOrderIndex, id: this.glassPurchaseOrderId },
        { index: this.chrisleaInventoryIndex, id: this.chrisleaInventoryId },
        { index: this.procoreCorrespondenceIndex, id: this.procoreCorrespondenceId },
        { index: this.awdBreakdownIndex, id: this.awdBreakdownId },
        { index: this.awdPaintConsumptionIndex, id: this.awdPaintConsumptionId },
      ];

      const res = await this.$api.dashboards.get('auth');
      if (!res) return;

      // Filter and map the reports based on the defined mappings
      this.reports = reportMappings
        .map(({ id }) => res.find((item) => item.report_id === id) || null)
        .filter((report) => report !== null);

      if (this.reports.length > 0) {
        this.embedReport(this.reports[0]); // Embed the first report if available
      }
    },

    embedReport(report) {
      const { models } = pbi;
      const config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: report.embed_token,
        embedUrl: report.embed_url,
        id: report.report_id,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
          },
          bars: {
            statusBar: {
              visible: false,
            },
          },
        },
      };
      if (report.report_id === this.glassPurchaseOrderId || report.report_id === this.awdPaintConsumptionId) {
        config.settings.zoomLevel = 1.25;
      }

      if (this.previousIdreport) {
        const prevReportContainer = document.getElementById(this.previousIdreport);
        if (prevReportContainer) {
        // eslint-disable-next-line no-undef
          powerbi.reset(prevReportContainer);
        }
      }

      this.previousIdreport = report.report_id;

      const reportContainer = document.getElementById(`${report.report_id}`);

      if (reportContainer) {
        // eslint-disable-next-line no-undef
        powerbi.embed(reportContainer, config);
      }
    },
  },
};
</script>

<style scoped>
::v-deep iframe {
        border: none !important;
    }

    .reportContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
</style>
