<template>
  <v-container class="pa-10 fluid">
    <!-- <v-row class="w-100 m-0"> -->
    <v-card
      v-if="itemCode != null"
      fluid
      class="w-100"
    >
      <v-card-title class="pa-6">
        <span class="headline">Equipment #{{ itemCode }}</span>
      </v-card-title>
      <v-divider class="mr-10 ml-10 pr-10 pl-10" />
      <v-card-text>
        <v-list>
          <v-list-item
            cols="10"
            class="pr-10 pl-10"
          >
            <v-list-item-content>
              <v-list-item-title class="ml-4 mb-4">
                Equipment
              </v-list-item-title>
              <v-list-item-subtitle class="ml-4">
                {{
                  equipment
                }}
              </v-list-item-subtitle>
              <v-divider class="ma-3" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="maintenanceDone"
            cols="12"
            class="pr-10 pl-10"
          >
            <v-list-item-content>
              <v-list-item-title
                class="ml-4 mb-4"
              >
                Maintenance
              </v-list-item-title>
              <v-list-item-subtitle class="ml-4">
                {{
                  maintenanceDone
                }}
              </v-list-item-subtitle>
              <v-divider class="ma-3" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            cols="8"
            class="pr-10 pl-10"
          >
            <v-list-item-content>
              <v-list-item-title class="ml-4 mb-4">
                Job
              </v-list-item-title>
              <v-list-item-subtitle class="ml-4">
                {{
                  job
                }}
              </v-list-item-subtitle>
              <v-divider class="ma-3" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            cols="4"
            class="pr-10 pl-10"
          >
            <v-list-item-content>
              <v-list-item-title class="ml-4 mb-4">
                Condition
              </v-list-item-title>
              <v-list-item-subtitle class="ml-4">
                {{
                  condition
                }}
              </v-list-item-subtitle>
              <v-divider class="ma-3" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            cols="12"
            class="pr-10 pl-10"
          >
            <v-list-item-content>
              <v-list-item-title
                class="ml-4 mb-4"
              >
                Last comment
              </v-list-item-title>
              <v-list-item-subtitle class="ml-4">
                {{
                  lastComment
                }}
              </v-list-item-subtitle>
              <v-divider class="ma-3" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            cols="12"
            class="pr-10 pl-10"
          >
            <v-list-item-content>
              <v-list-item-title
                class="ml-4 mb-4"
              >
                Last movement
              </v-list-item-title>
              <v-list-item-subtitle class="ml-4">
                {{
                  lastMovement
                }}
              </v-list-item-subtitle>
              <v-divider class="ma-3" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="lastMovement == 'Check-in'"
            cols="12"
          >
            <v-btn>Check out</v-btn>
            <v-btn>Change Site</v-btn>
          </v-list-item>
          <v-list-item
            v-else
            cols="12"
            class="pb-0"
          >
            <v-btn
              color="success"
              raised
            >
              Check in
            </v-btn>
          </v-list-item>

          <!-- <v-list-item cols="12" class="pb-0">
              <v-list-item-content>
                <v-list-item-title class="ml-4 mb-4">Hours spent</v-list-item-title>
                <v-list-item-subtitle>{{ hoursSpent }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->

          <v-list-item
            v-if="photo"
            cols="12"
            class="pb-0"
          >
            <v-list-item-content>
              <v-list-item-title class="ml-4 mb-4">
                Photo
              </v-list-item-title>
              <v-img
                :src="convertedPhoto"
                class="equipment-photo"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <!-- </v-row> -->

    <v-card
      v-if="itemCode == null"
      fluid
      class="w-50 pa-6"
    >
      <v-card-title class="pa-6 justify-center">
        <h2>Sorry... No Equipment Found!</h2>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import EquipmentMovementsApi from '@/api/EquipmentMovementsApi';
import EquipmentItemsApi from '@/api/EquipmentItemsApi';

export default {
  name: 'EquipmentItem',
  components: {},
  data: () => ({
    // Form
    dialog: false,
    valid: false,
    showHoursSpent: false,
    // Equipment item parameters
    itemCode: null,
    equipment: null,
    job: null,
    condition: null,
    photo: null,
    photoMimeType: null,
    lastComment: null,
    hoursSpent: null,
    maintenanceTypes: [],
    maintenanceDone: null,
    equipmentId: null,
    lastMovementId: null,
    lastMovement: null,
  }),
  computed: {
    convertedPhoto() {
      return `data:${this.photoMimeType};base64, ${this.photo}`;
    },
  },
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // this.equipmentId = this.$route.params.id - 99; //TODO this is just a temp workaround
      this.equipmentId = await this.getEquipmentIdByCode(this.$route.params.id);

      if (!this.equipmentId && this.equipmentId === 0) return;
      await this.fetchEquipmentItem(this.equipmentId);
      await this.fetchStatus(this.equipmentId);
      await this.fetchLatestMovement(this.equipmentId);
    },
    async openDialog(equipmentId, movementId = false) {
      this.dialog = true;
      this.clearForm();
      if (movementId) {
        await this.fetchSingleMovement(equipmentId, movementId);
      } else {
        await this.fetchStatus(equipmentId);
      }

      await this.fetchEquipmentItem(equipmentId);

      // if (type === 'MAINTENANCE DONE') {
      //   this.showHoursSpent = true;
      // }
    },
    async closeDialog() {
      this.dialog = false;
    },
    async getEquipmentIdByCode(code) {
      return await EquipmentItemsApi.getIdByCode(code);
    },
    async clearForm() {
      this.itemCode = null;
      this.equipment = null;
      this.condition = null;
      this.job = null;
      this.lastComment = null;
      this.photo = null;
      this.photoMimeType = null;
      this.maintenanceTypes = [];
      this.maintenanceDone = null;
    },
    async fetchSingleMovement(equipmentId, movementId) {
      const movementData = {
        id: movementId,
      };

      const movement = await EquipmentMovementsApi.getSingleMovement(
        movementData,
      );

      await this.fetchCondition(movement.equipment_condition_id);
      await this.fetchJob(movement.job_id);

      this.itemCode = movement.code;

      movement.description
        ? (this.lastComment = movement.description)
        : (this.lastComment = 'No description');

      if (movement.is_inspect) {
        this.maintenanceTypes.push('Inspected');
      }

      if (movement.is_maintenance) {
        this.maintenanceTypes.push('Maintenance done');
      }

      if (movement.is_repair) {
        this.maintenanceTypes.push('Repaired');
      }

      if (movement.is_service) {
        this.maintenanceTypes.push('Serviced');
      }

      if (this.maintenanceTypes.length > 0) {
        this.maintenanceDone = this.maintenanceTypes.join(', ');
        this.showMaintenanceTypes = true;
      }

      const equipmentData = {
        equipment_item_id: equipmentId,
      };

      const response = await EquipmentMovementsApi.getPhoto(equipmentData);

      if (response) {
        this.photo = response.photo;
        this.photoMimeType = response.mime_type;
      }
    },
    async fetchLatestMovement(equipmentId) {
      const data = {
        equipmentId,
      };
      // TODO
      const movement = await EquipmentMovementsApi.getLatestMovement(data);
      if (movement == null) {
        return;
      }
      this.lastMovement = movement.movement;

      /*

      this.itemCode = movement.code;

      movement.description
        ? (this.lastComment = movement.description)
        : (this.lastComment = 'No description');

      if (movement.is_inspect) {
        this.maintenanceTypes.push('Inspected');
      }

      if (movement.is_maintenance) {
        this.maintenanceTypes.push('Maintenance done');
      }

      if (movement.is_repair) {
        this.maintenanceTypes.push('Repaired');
      }

      if (movement.is_service) {
        this.maintenanceTypes.push('Serviced');
      }

      if (this.maintenanceTypes.length > 0) {
        this.maintenanceDone = this.maintenanceTypes.join(', ');
        this.showMaintenanceTypes = true;
      }

      const equipmentData = {
        equipment_item_id: equipmentId,
      };
*/
      const response = await EquipmentMovementsApi.getPhoto(equipmentData);
      if (response) {
        this.photo = response.photo;
        this.photoMimeType = response.mime_type;
      }
    },
    async fetchStatus(equipmentId) {
      const data = {
        equipment_item_id: equipmentId,
      };
      const status = await EquipmentMovementsApi.getCurrentStatus(data);

      await this.fetchCondition(status.equipment_condition_id);
      await this.fetchJob(status.job_id);

      this.itemCode = status.code;

      this.lastComment = status.description
        ? `${status.created_at_short}: ${status.description}`
        : 'No comments';

      if (status.is_inspect) {
        this.maintenanceTypes.push('Inspected');
      }

      if (status.is_maintenance) {
        this.maintenanceTypes.push('Maintenance done');
      }

      if (status.is_repair) {
        this.maintenanceTypes.push('Repaired');
      }

      if (status.is_service) {
        this.maintenanceTypes.push('Serviced');
      }

      if (this.maintenanceTypes.length > 0) {
        this.maintenanceDone = this.maintenanceTypes.join(', ');
        this.showMaintenanceTypes = true;
      }

      const response = await EquipmentMovementsApi.getPhoto(data);
      if (response) {
        this.photo = response.photo;
        this.photoMimeType = response.mime_type;
      }
    },
    async fetchEquipmentItem(equipmentId) {
      const equipmentList = await this.$store.getters.equipmentList;
      for (const item of equipmentList) {
        if (item.id === equipmentId) {
          return (this.equipment = `${item.category} / ${item.manufacturer} / ${item.model} / ${item.serial_number}`);
        }
      }
    },
    async fetchCondition(conditionId) {
      const conditionsList = await this.$store.getters.conditionsList;

      for (const condition of conditionsList) {
        if (condition.id === conditionId) {
          return (this.condition = condition.name);
        }
      }
    },
    async fetchJob(jobId) {
      const jobsList = await this.$store.getters.jobsList;
      this.job = 'In the warehouse';

      for (const job of jobsList) {
        if (job.id === jobId) {
          return (this.job = job.name);
        }
      }
    },
  },
};
</script>

<style scoped>
.equipment-photo {
  border: 1px solid;
  max-height: 20rem;
  max-width: 20rem;
}
</style>
