<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row class="mt-2">
      <v-col
        v-if="bomStatus"
        cols="3"
        class="d-flex justify-start"
      >
        <div class="headline d-flex align-self-start ">
          {{ type.toUpperCase() }} : <span class="font-weight-bold ml-1"> {{ bomCode }}</span>
        </div>
      </v-col>

      <v-col
        v-if="bomStatus"
        cols="2"
        class="d-flex justify-start"
      >
        <div class="headline d-flex align-self-start ">
          Status: <span class="font-weight-bold ml-1"> {{ bomStatus }}</span>
        </div>
      </v-col>

      <v-col
        v-if="bomStatus"
        cols="3"
        class="d-flex justify-start"
      >
        <div class="headline d-flex align-self-start mr-3">
          Worker: <span
            v-if="bomWorker!=' '"
            class="font-weight-bold ml-1"
          > {{ bomWorker }} </span>
          <span
            v-if="bomWorker==' '"
            class="font-weight-bold ml-1"
          > NOT SPECIFIED </span>
        </div>
        <a
          class="d-flex align-self-end"
          @click="() => {openWorkerForm();}"
        >
          CHANGE
        </a>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        />
      </v-col>

      <v-col
        cols="3"
        class="d-flex justify-center"
      />
      <v-col
        cols="7"
        class="d-flex justify-end"
      >
        <div class="d-flex align-self-end">
          <v-autocomplete
            v-model="bomId"
            :items="boms"
            item-text="name"
            item-value="id"
            hide-details
            :placeholder="bomFilterPlaceholder"
            class="pa-0"
            @change="changeBom"
          >
            <template #selection="{ item }">
              <span class="black--text">
                {{ type.toUpperCase() }} #{{ item.job_id }} {{ item.code }} ({{
                  item.job_address_simple
                }})
              </span>
            </template>
            <template #item="{ item }">
              <span class="black--text">
                {{ type.toUpperCase() }} #{{ item.job_id }} {{ item.code }} ({{
                  item.job_address_simple
                }})
              </span>
            </template>
          </v-autocomplete>
          <v-btn
            v-if="bomStatus"
            class="ml-4"
            outlined
            color="primary"
            dark
            small
            @click="generatePdf"
          >
            pdf
          </v-btn>
          <v-btn
            v-if="bomStatus"
            class="ml-4"
            outlined
            color="primary"
            dark
            small
            @click="generateExcel"
          >
            excel
          </v-btn>
          <v-btn
            v-if="bomStatus === 'New' && canEdit"
            class="ml-4"
            depressed
            color="primary"
            dark
            small
            @click="releaseBom"
          >
            release
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          id="bom-items-table"
          class="elevation-1"
          :headers="computedHeaders"
          :items="bomItems"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template #item="{ item }">
            <tr
              :class="{
                red: isNotEnoughItems(item),
                'lighten-5': true,
              }"
            >
              <td>
                <template v-if="edit === item.id">
                  {{ editItemCode }}
                </template>
                <template v-else>
                  {{ item.item_code }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id"
                  v-model="editCategoryId"
                  class="mrp-edit-input-margin pa-0"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  required
                  single-line
                  @change="changeEditCategory"
                />
                <template v-else>
                  {{ item.item_category }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && filteredItems.length > 0"
                  v-model="editItemId"
                  class="mrp-edit-input-margin pa-0"
                  :items="filteredItems"
                  item-text="name"
                  item-value="id"
                  required
                  single-line
                  @change="changeEditItem"
                />
                <template v-else>
                  {{ item.item_name }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedManufacturers.length > 0"
                  v-model="editManufacturerId"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedManufacturers"
                  item-text="name"
                  item-value="id"
                  single-line
                  @change="fetchItemQuantity"
                />
                <template v-else>
                  {{
                    item.manufacturer_name ? item.manufacturer_name : '-'
                  }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="
                    edit === item.id && relatedMaterialProperties.length > 0
                  "
                  v-model="editMaterialPropertyId"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedMaterialProperties"
                  item-text="name"
                  item-value="id"
                  single-line
                  @change="fetchItemQuantity"
                />
                <template v-else>
                  {{
                    item.material_property_name
                      ? item.material_property_name
                      : '-'
                  }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedLengths.length > 0"
                  v-model="editLengthId"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedLengths"
                  item-text="name"
                  item-value="id"
                  single-line
                  @change="fetchItemQuantity"
                />
                <template v-else>
                  {{
                    item.length_name ? item.length_name : '-'
                  }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedColors.length > 0"
                  v-model="editColorId"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedColors"
                  item-text="name"
                  item-value="id"
                  single-line
                  @change="fetchItemQuantity"
                />
                <template v-else>
                  {{ item.color_code ? item.color_code + ':' : '' }}
                  {{ item.color_name ? item.color_name : '-' }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedFinishings.length > 0"
                  v-model="editFinishingId"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedFinishings"
                  item-text="name"
                  item-value="id"
                  single-line
                  @change="fetchItemQuantity"
                />
                <template v-else>
                  {{
                    item.finishing_name ? item.finishing_name : '-'
                  }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id"
                  v-model="editWarehouseId"
                  class="mrp-edit-input-margin pa-0"
                  :items="warehouses"
                  item-text="name"
                  item-value="id"
                  required
                  :rules="[(v) => !!v || 'Required']"
                  single-line
                />
                <template v-else>
                  {{ item.warehouse_name }}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id"
                  v-model="editUomId"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedUoms"
                  item-text="name"
                  item-value="id"
                  required
                  :rules="[(v) => !!v || 'Required']"
                  single-line
                  @change="fetchItemQuantity"
                />
                <template v-else>
                  {{
                    item.uom_name ? item.uom_name : '-'
                  }}
                </template>
              </td>
              <td>
                <v-text-field
                  v-if="edit === item.id"
                  v-model="editQuantity"
                  class="mrp-edit-input-margin pa-0"
                  type="number"
                  required
                  :rules="[(v) => v > 0 || 'Required']"
                  single-line
                />
                <template v-else>
                  <div v-if="isNotEnoughItems(item)">
                    {{ item.quantity }}
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon
                          color="red"
                          dark
                          small
                          style="margin-bottom: 2px"
                          v-on="on"
                        >
                          fas fa-exclamation-circle
                        </v-icon>
                      </template>
                      <span>Not enough items in inventory</span>
                    </v-tooltip>
                  </div>
                  <template v-else>
                    {{ item.quantity }}
                  </template>
                </template>
              </td>
              <td v-if="bomStatus === 'New'">
                <template v-if="edit === item.id">
                  {{ editItemQuantityOnHand }}
                </template>
                <template v-else>
                  {{ item.quantity_on_hand }}
                </template>
              </td>
              <td
                v-if="bomStatus === 'New'"
                class="text-right"
              >
                <template v-if="edit === item.id">
                  <a
                    class="mr-2"
                    @click="edit = null"
                  > CANCEL </a>
                  <a @click="saveBomItem"> SAVE </a>
                </template>
                <a
                  v-else-if="item.id === 'new' && canEdit"
                  @click="editBomItem(item)"
                >
                  ADD NEW
                </a>
                <template v-else-if="canEdit">
                  <a
                    class="mr-2"
                    @click="deleteBomItem(item)"
                  > DELETE </a>
                  <a @click="editBomItem(item)"> EDIT </a>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
    <BomItemForm
      ref="bomItemForm"
      :bom-id="bomId"
      @bom-item-creation-success="notifyBomItemCreationSuccess"
      @bom-item-creation-fail="notifyBomItemCreationFail"
    />
    <WorkerForm
      ref="workerForm"
      @bom-creation="notifyBomCreated"
    />
  </v-container>
</template>

<script>
import WorkerForm from '../../components/forms/WorkerForm';
import Snackbar from '../../components/Snackbar';
import BomItemForm from '../../components/forms/BomItemForm';
import BomRmaItemsApi from '../../api/BomRmaItemsApi';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import InventoryCategoriesApi from '../../api/InventoryCategoriesApi';
import BomRmasApi from '../../api/BomRmasApi';
import WarehousesApi from '../../api/WarehousesApi';
import TransactionsApi from '../../api/TransactionsApi';
import Bus from '../../bus';
import ExcelHelper from '../../helpers/ExcelHelper';
import PdfHelper from '../../helpers/PdfHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'Bom',
  components: {
    Snackbar,
    BomItemForm,
    WorkerForm,
  },
  filters: {},
  data: () => ({
    // Page
    type: '',
    search: '',
    loading: true,
    edit: null,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    headers: [
      { text: 'Item Code', value: 'item_code', width: '7%' },
      { text: 'Category', value: 'item_category', width: '12%' },
      { text: 'Item Name', value: 'item_name', width: '12%' },
      { text: 'Manufacturer', value: 'manufacturer_name' },
      {
        text: 'Material Property',
        value: 'material_property_name',
        width: '10%',
      },
      { text: 'Length', value: 'length_name' },
      { text: 'Color', value: 'color_name' },
      { text: 'Finishing', value: 'finishing_name' },
      { text: 'Warehouse', value: 'warehouse_name' },
      { text: 'UOM', value: 'uom_name' },
      { text: 'Qty.', value: 'quantity', width: '6%' },
      { text: 'Qty. On Hand', value: 'quantity_on_hand', hidden: false },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        width: '10%',
        hidden: false,
      },
    ],
    boms: [],
    bomItems: [],
    bomId: null,
    bomStatus: null,
    bomWorker: null,
    bomCode: null,
    warehouses: [],
    canEdit: false,
    // BOM Item
    items: [],
    filteredItems: [],
    categories: [],
    relatedManufacturers: [],
    relatedMaterialProperties: [],
    relatedLengths: [],
    relatedColors: [],
    relatedFinishings: [],
    relatedUoms: [],
    editId: null,
    editItemId: null,
    editItemCode: null,
    editItemQuantityOnHand: '-',
    editNameId: null,
    editCategoryId: null,
    editManufacturerId: null,
    editMaterialPropertyId: null,
    editLengthId: null,
    editColorId: null,
    editFinishingId: null,
    editUomId: null,
    editQuantity: null,
    editWarehouseId: null,
  }),
  computed: {
    bomFilterPlaceholder() {
      return `Select ${this.type.toUpperCase()}`;
    },
    computedHeaders() {
      return this.headers.filter((x) => x.hidden !== true);
    },
  },
  created() {
    this.canEdit = this.$store.getters.userCanEdit;
    // this.initialize();
  },
  methods: {
    async initialize(type, bomId) {
      this.type = type;
      await Promise.all([
        this.fetchBoms(),
        this.fetchItems(),
        this.fetchWarehouses(),
        this.fetchCategories(),
      ]);

      // let savedBom = null;
      // if (type === 'bom') {
      //   savedBom = this.$store.getters.selectedBom;
      // } else {
      //   savedBom = this.$store.getters.selectedRma;
      // }
      if (bomId) {
        const bom = this.boms.find((x) => x.id === bomId);
        if (!bom) {
          Bus.$emit(
            'alert',
            'error',
            `This ${this.type.toUpperCase()} was deleted`,
          );
          this.bomId = null;
          this.bomStatus = null;
          this.bomWorker = null;
          this.bomCode = null;
          this.bomItems = [];
          this.loading = false;
        } else {
          // this.renderBom(savedBom);
          this.renderBom(bom);
        }
      } else {
        this.bomId = null;
        this.bomStatus = null;
        this.bomWorker = null;
        this.bomCode = null;
        this.bomItems = [];
        this.loading = false;
      }
    },
    async openWorkerForm() {
      this.$refs.workerForm.openDialog(this.type, this.bomId);
    },
    async renderBom(bom = null) {
      this.bomId = bom.id;
      this.bomWorker = bom.worker;
      this.bomStatus = bom.status;
      this.bomCode = bom.code;
      // Keep current BOM/RMA id in local storage
      if (this.type === 'bom') {
        this.$store.commit('storeBom', bom);
      } else {
        this.$store.commit('storeRma', bom);
      }

      // Adjust table headers
      if (this.bomStatus !== 'New') {
        this.headers.find((x) => x.value === 'actions').hidden = true;
        this.headers.find((x) => x.value === 'quantity_on_hand').hidden = true;
      } else {
        this.headers.find((x) => x.value === 'actions').hidden = false;
        this.headers.find((x) => x.value === 'quantity_on_hand').hidden = false;
      }

      await this.fetchBomItems();
    },
    async editBomItem(item) {
      this.edit = item.id;

      // New item creation
      // Select first category and item in list by default
      if (item.id === 'new') {
        this.editId = null;
        await this.fetchItemRelatedProperties(this.editItemId);
        // Preselect Local warehouse
        this.editWarehouseId = this.warehouses.filter(
          (x) => x.is_vendor == 0,
        )[0].id;
        this.editItemQuantityOnHand = '-';
      }
      // Edit item
      else {
        this.editCategoryId = item.item_category_id;
        this.filteredItems = this.items.filter(
          (x) => x.category_id === this.editCategoryId,
        );
        this.editItemId = item.item_id;
        this.editItemCode = item.item_code;
        this.editId = item.id;

        await this.fetchItemRelatedProperties(this.editItemId);
        this.editManufacturerId = item.manufacturer_id;
        this.editMaterialPropertyId = item.material_property_id;
        this.editLengthId = item.length_id;
        this.editColorId = item.color_id;
        this.editFinishingId = item.finishing_id;
        this.editUomId = item.uom_id;
        this.editQuantity = item.quantity;
        this.editWarehouseId = item.warehouse_id;
        this.fetchItemQuantity();
      }
    },
    async changeEditItem() {
      this.fetchItemRelatedProperties(this.editItemId);
    },
    async changeEditCategory() {
      this.filteredItems = this.items.filter(
        (x) => x.category_id === this.editCategoryId,
      );
      this.editItemId = null;
      this.resetItemProperties();
    },
    async changeBom() {
      const bom = this.boms.find((x) => x.id === this.bomId);
      const storeBom = {
        id: bom.id,
        worker: bom.worker,
        code: bom.code,
        status: bom.status,
      };
      this.renderBom(storeBom);
      this.$emit('change-bom', storeBom, this.type);
    },
    notifyBomItemCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchBomItems();
    },
    notifyBomItemCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    async releaseBom() {
      if (confirm('Are you sure?')) {
        // Check if all items are in stock
        if (this.type === 'bom') {
          const notEnough = this.bomItems.some((x) => !!(
            x.quantity > x.quantity_on_hand && x.warehouse_is_vendor === 0
          ));
          if (notEnough) {
            Bus.$emit('alert', 'error', 'Some items are out of stock');
            return;
          }
        }

        const res = await BomRmasApi.release(this.bomId);
        if (res) {
          // Refresh BOM info
          this.$refs.snackbar.showSuccess('Success!');
          await this.fetchBoms();
          this.changeBom();
        } else {
          this.$refs.snackbar.showError('Something went wrong!');
        }
      }
    },
    async saveBomItem() {
      if (Number(this.editQuantity) <= 0) {
        this.$refs.snackbar.showError('Quantity is required!');
        return;
      }
      if (!this.editUomId) {
        this.$refs.snackbar.showError('UOM is required!');
        return;
      }
      if (!this.editWarehouseId) {
        this.$refs.snackbar.showError('Warehouse is required!');
        return;
      }

      const data = {
        id: this.editId || null,
        bom_rma_id: this.bomId,
        inventory_item_id: this.editItemId,
        manufacturer_id: this.editManufacturerId,
        material_property_id: this.editMaterialPropertyId,
        length_id: this.editLengthId,
        color_id: this.editColorId,
        finishing_id: this.editFinishingId,
        quantity: this.editQuantity,
        uom_id: this.editUomId,
        warehouse_id: this.editWarehouseId,
      };

      const res = await BomRmaItemsApi.store(data);
      if (res) {
        this.edit = false;
        this.$refs.snackbar.showSuccess('Success!');
        this.fetchBomItems();
      } else {
        this.edit = false;
        this.$refs.snackbar.showError('Something went wrong!');
      }
    },
    async deleteBomItem(bomItem) {
      if (!confirm('Are you sure?')) {
        return;
      }

      const res = await BomRmaItemsApi.softDelete(bomItem.id);
      if (res === true) {
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchBomItems();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    generateExcel() {
      const excelData = this.bomItems.map((el) => ({
        'Item Code': el.item_code,
        'Item Name': el.item_name,
        Manufacturer: el.manufacturer_name,
        'Material Property': el.material_property_name,
        Length: el.length_name,
        Color: el.color_name,
        Finishing: el.finishing_name,
        UOM: el.uom_name,
        Quantity: el.quantity,
        'Quantity On Hand': el.quantity_on_hand,
        Warehouse: el.warehouse_name,
      }));

      let workBookName = '';
      if (this.type === 'bom') {
        workBookName = `BOM-${this.bomCode}`;
      } else {
        workBookName = `RMA-${this.bomCode}`;
      }

      const excelWorkbook = ExcelHelper.writeJson(excelData, workBookName);
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${workBookName}_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      // Hide actions column for render
      if (this.bomStatus === 'New') {
        this.headers.find((x) => x.value === 'actions').hidden = true;
      }

      await this.$nextTick();

      const now = FormatHelpers.getFileTimestamp();
      let docName = '';
      if (this.type === 'bom') {
        docName = `BOM-${this.bomCode}`;
      } else {
        docName = `RMA-${this.bomCode}`;
      }
      const fileName = `${docName}_${now}`;

      // Exctract job data from BOM
      const jobData = (({ job_address, job_code, supervisor }) => ({
        job_address,
        job_code,
        supervisor,
      }))(this.boms.find((x) => x.id === this.bomId));

      const pdf = PdfHelper.generateFromTable(
        '#bom-items-table > div > table',
        'landscape',
        docName,
        'bom',
        jobData,
      );
      PdfHelper.savePdfToBrowser(pdf, fileName);

      if (this.bomStatus === 'New') {
        this.headers.find((x) => x.value === 'actions').hidden = false;
      }
    },
    isNotEnoughItems(item) {
      return Boolean(
        item.quantity > item.quantity_on_hand
          && this.type === 'bom'
          && this.bomStatus === 'New'
          && item.warehouse_is_vendor === 0,
      );
    },
    async fetchItemQuantity() {
      if (!this.editWarehouseId) {
        return '-';
      }

      // Quantity unknown if item comes from vendor
      if (
        this.warehouses.find((x) => x.id === this.editWarehouseId).is_vendor
      ) {
        this.editItemQuantityOnHand = '-';
      } else {
        this.editItemQuantityOnHand = await TransactionsApi.getCurrentLevel(
          this.editItemId,
          this.editManufacturerId,
          this.editMaterialPropertyId,
          this.editLengthId,
          this.editColorId,
          this.editFinishingId,
          this.editUomId,
        );
      }
    },
    async fetchBomItems() {
      this.loading = true;
      this.bomItems = await BomRmaItemsApi.getListByBom(this.bomId);
      // Placeholder for ADD NEW row
      if (this.bomStatus === 'New') {
        this.bomItems.push({
          id: 'new',
          manufacturer_name: ' ',
          material_property_name: ' ',
          length_name: ' ',
          color_name: ' ',
          finishing_name: ' ',
          uom_name: ' ',
        });
      }
      this.loading = false;
      const timeStamp = FormatHelpers.currentDateTimeSimple();
      this.headers.find(
        (x) => x.value === 'quantity_on_hand',
      ).text = `Qty. On Hand (${timeStamp})`;
    },
    async fetchItemRelatedProperties(itemId = null) {
      if (itemId == null) return;
      const entries = await InventoryItemsApi.getRelatedProperties(itemId);
      if (entries) {
        this.editItemCode = entries.item_code;
        this.relatedManufacturers = entries.manufacturers;
        this.relatedMaterialProperties = entries.material_properties;
        this.relatedLengths = entries.lengths;
        this.relatedColors = entries.colors;
        this.relatedFinishings = entries.finishings;
        this.relatedUoms = entries.uoms;
      } else {
        this.editItemCode = null;
        this.relatedManufacturers = [];
        this.relatedMaterialProperties = [];
        this.relatedLengths = [];
        this.relatedColors = [];
        this.relatedFinishings = [];
        this.relatedUoms = [];
      }

      this.resetItemProperties();
    },
    resetItemProperties() {
      // Reset selected values
      this.editManufacturerId = null;
      this.editMaterialPropertyId = null;
      this.editLengthId = null;
      this.editColorId = null;
      this.editFinishingId = null;
      this.editUomId = null;
      this.editQuantity = null;
    },
    async notifyBomCreated(res) {
      // Redirect to BOM page and show new BOM
      await this.fetchBoms();
      const bom = this.boms.find((x) => x.id === res.id);
      this.showBom(bom);
    },
    showBom(item) {
      const bom = {
        id: item.id,
        code: item.code,
        status: item.status,
        worker: item.worker,
      };
      this.renderBom(bom);
      this.$emit('open-bom', bom, this.type);
    },
    async fetchItems() {
      this.items = await InventoryItemsApi.getList();
    },
    async fetchCategories() {
      this.categories = await InventoryCategoriesApi.getList();
    },
    async fetchBoms() {
      this.boms = await BomRmasApi.getList(this.type);
    },
    async fetchWarehouses() {
      this.warehouses = await WarehousesApi.getList();
    },
  },
};
</script>
<style scoped>
.mrp-edit-input-margin {
  margin-top: 23px !important;
  margin-bottom: 1px !important;
}
</style>
