<template>
  <v-container
    class="mt-3"
    fluid
  >
    <v-row align="center">
      <v-col cols="3">
        <v-row>
          <v-col
            cols="auto"
            class="pb-0 pr-0"
          >
            <v-btn
              outlined
              color="primary"
              class="mr-4"
              @click="setToday"
            >
              Today
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pr-0 pl-0"
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="pt-4 pr-0 pl-0"
          >
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col
        class="pt-0"
        cols="2"
      >
        <v-select
          v-model="type"
          :items="types"
          hide-details="true"
          label="Type"
          item-value="type"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="pt-0"
        cols="2"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :hide-details="true"
          :is-inst="false"
          :is-glass="false"
          :is-commercial="false"
          :is-vwd-glass="false"
          :is-panels="false"
          :is-parts="false"
          :is-sid="false"
          :is-rail-part="false"
          :is-railings="false"
          :is-state="true"
        />
      </v-col>
      <v-col
        class="pt-0"
        cols="3"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobs"
          hide-details="true"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          item-value="id"
          clearable
          :single-select="false"
        >
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              v-if="item.sub_class"
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.sub_class ? item.sub_class.color : 'white'"
            >
              {{ item && item.sub_class ? item.sub_class.name : '' }}
            </v-chip>
            <span><span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span></span>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #item="{ item }">
            <v-chip
              v-if="item.sub_class"
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.sub_class ? item.sub_class.color : 'white'"
            >
              {{ item && item.sub_class ? item.sub_class.name : '' }}
            </v-chip>
            <span><span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span></span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col
        v-for="tab, idx in tabs"
        :key="idx"
        cols="auto"
        :style="`cursor: pointer; border-bottom: ${selectedTab == tab ? '2px solid #1976d2' : 'none'};`"
        class="ml-2 mr-2"
        @click="tabChange(tab)"
      >
        <span :style="`font-size: 16px !important; color: ${selectedTab == tab ? '#1976d2' : 'rgba(0,0,0,0.8)'}; font-weight: ${selectedTab == tab ? 'bold' : 'normal'};`">{{ tab.name }}</span>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="pr-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="runWebhook('pdf')"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-download
          </v-icon>
          REPORT
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
      >
        <v-menu
          v-model="filterMenu"
          transition="scale-transition"
          offset-y
          min-width="350"
          nudge-left="50"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="filterMenu = !filterMenu"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-filter
              </v-icon>
              FILTERS
            </v-btn>
          </template>
          <v-card
            class="pa-5"
            style="border-radius: 7px;"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <span style="font-size: 22px !important; font-weight: bold;">Filters</span>
                  </v-col>
                  <v-col class="text-end pr-1">
                    <v-btn
                      icon
                      :style="{ color: isDarkMode ? '' : '#000000'}"
                      @click="filterMenu = !filterMenu"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="filterRecordType"
                      label="Records Type"
                      :items="recordTypes"
                      hide-details="true"
                      item-text="name"
                      item-value="id"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="filterStage"
                      label="Production Stage"
                      :items="stages"
                      hide-details="true"
                      item-text="name"
                      item-value="id"
                      clearable
                    >
                      <template #selection="{ item }">
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 20px; height: 20px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >C</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 14px !important;">{{ item.name }}</span>
                          </v-col>
                        </v-row>
                      </template>
                      <template #item="{ item }">
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 20px; height: 20px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >
                                {{ item.code }}</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 14px !important;">{{ item.name }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterPo"
                      label="SP+S/U PO#"
                      :items="poNumbers"
                      hide-details
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      @click="filterMenu = !filterMenu"
                    >
                      <span style="font-size: 14px !important;">Close</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      @click="clearAllFilters"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-calendar
            ref="calendar"
            v-model="focusDay"
            :weekdays="weekday"
            :type="type"
            :events="items"
            event-overlap-mode="stack"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            color="primary"
            style="min-width: 1400px;"
            :event-more="false"
            @change="get"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="openViewForm"
            @mousedown:event="startDrag"
            @mousemove:day="mouseMove"
            @mouseup:day="endDrag"
            @mouseleave.native="endDrag"
          >
            <template #event="event">
              <v-card
                v-if="type === 'month'"
                width="200"
                :height="event.eventParsed.input.job_name && event.eventParsed.input.job_name.length > 25 ? '66' : '50'"
                :color="getItemColor(event.eventParsed.input)"
                class="rounded v-event-draggable"
                style="padding: 1px 2px 0px 2px;"
                tile
              >
                <v-card-title style="padding-top: 5px; padding-bottom: 22px;">
                  <v-row>
                    <v-col
                      class="pa-0 pl-1"
                      style="height: 22px;"
                    >
                      <span :style="`color: ${getFontColor(event.eventParsed.input)}; font-size: 14px; font-weight: bold;`">{{ event.eventParsed.input.class ? event.eventParsed.input.class.name : '' }}</span>
                    </v-col>
                    <v-col
                      class="text-end pa-0"
                    >
                      <v-chip
                        x-small
                        color="#FFFFFF"
                        text-color="#01579B"
                      >
                        <span style="font-size: 12px;">{{ getItemName(event.eventParsed.input) }}</span>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text
                  style="background-color: #FFFFFF;"
                >
                  <v-row :style="`min-height: ${event.eventParsed.input.job_name && event.eventParsed.input.job_name.length > 25 ? '36' : '20'}px; font-weight: bold;`">
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-0"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px; font-weight: bold;"><text-highlight :queries="search">{{ event.eventParsed.input.job_name }}</text-highlight></span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row
                v-if="type === 'month'"
                v-show="event.eventParsed.input.latest"
                class="text-end pt-3 pr-2"
              >
                <v-col>
                  <span
                    style="color: #000; font-size: 14px !important; font-weight: bold;"
                  >Total Release Qty: {{ getTotalWindows(event.eventParsed) }}</span>
                </v-col>
              </v-row>

              <v-card
                v-if="type === 'week'"
                width="200"
                :height="event.eventParsed.input.job_name && event.eventParsed.input.job_name.length > 25 ? '66' : '50'"
                :color="getItemColor(event.eventParsed.input)"
                class="rounded"
                style="padding: 1px 2px 0px 2px;"
                tile
              >
                <v-card-title style="padding-top: 5px; padding-bottom: 22px;">
                  <v-row>
                    <v-col
                      class="pa-0 pl-1"
                      style="height: 22px;"
                    >
                      <span :style="`color: ${getFontColor(event.eventParsed.input)}; font-size: 14px; font-weight: bold;`">{{ event.eventParsed.input.class ? event.eventParsed.input.class.name : '' }}</span>
                    </v-col>
                    <v-col
                      class="text-end pa-0"
                    >
                      <v-chip
                        x-small
                        color="#FFFFFF"
                        text-color="#01579B"
                      >
                        <span style="font-size: 12px;">{{ getItemName(event.eventParsed.input) }}</span>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text
                  style="background-color: #FFFFFF;"
                >
                  <v-row :style="`min-height: ${event.eventParsed.input.job_name && event.eventParsed.input.job_name.length > 25 ? '36' : '20'}px;`">
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-0"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px; font-weight: 500;"><text-highlight :queries="search">{{ event.eventParsed.input.job_name }}</text-highlight></span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row
                v-if="type === 'week'"
                v-show="event.eventParsed.input.latest"
                class="text-end pt-3 pr-2"
              >
                <v-col>
                  <span
                    style="color: #000; font-size: 14px !important; font-weight: bold;"
                  >Total Windows: {{ getTotalWindows(event.eventParsed) }}</span>
                </v-col>
              </v-row>

              <v-card
                v-if="type === 'day'"
                width="200"
                :color="getItemColor(event.eventParsed.input)"
                class="rounded day-item"
                style="padding: 1px 2px 2px 2px;"
                tile
              >
                <v-card-title style="padding-top: 5px; padding-bottom: 22px;">
                  <v-row>
                    <v-col
                      class="pa-0 pl-1"
                      style="height: 22px;"
                    >
                      <span :style="`color: ${getFontColor(event.eventParsed.input)}; font-size: 14px; font-weight: bold;`"><text-highlight :queries="search">{{ event.eventParsed.input.class ? event.eventParsed.input.class.name : '' }}</text-highlight></span>
                    </v-col>
                    <v-col
                      class="text-end pa-0"
                    >
                      <v-chip
                        x-small
                        color="#FFFFFF"
                        text-color="#01579B"
                      >
                        <span style="font-size: 12px;">{{ getItemName(event.eventParsed.input) }}</span>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text
                  style="background-color: #FFFFFF;"
                >
                  <v-row style="min-height: 41px; border-bottom: 1px solid rgba(0,0,0,0.1);">
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px; font-weight: bold;"><text-highlight :queries="search">{{ event.eventParsed.input.project }}</text-highlight></span>
                    </v-col>
                  </v-row>
                  <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">Floor</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px;"><text-highlight :queries="search">{{ event.eventParsed.input.project_floor }}</text-highlight></span>
                    </v-col>
                  </v-row>
                  <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">#WO</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px;"><text-highlight :queries="search">{{ event.eventParsed.input.wo_number }}</text-highlight></span>
                    </v-col>
                  </v-row>
                  <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">Win.Vert</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px;">{{ event.eventParsed.input.window_verticals }}</span>
                    </v-col>
                  </v-row>
                  <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">Patio Doors</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px;">{{ event.eventParsed.input.patio_doors }}</span>
                    </v-col>
                  </v-row>
                  <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">Euro Doors</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px;">{{ event.eventParsed.input.euro_doors }}</span>
                    </v-col>
                  </v-row>
                  <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">Total Rel.Qty</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: #000000; line-height: 10px;">{{ event.eventParsed.input.total_release_qty }}</span>
                    </v-col>
                  </v-row>
                  <v-row
                    style="border-bottom: 1px solid rgba(0,0,0,0.1);"
                    align="center"
                  >
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">Stage</span>
                    </v-col>
                  </v-row>
                  <v-row
                    style="border-bottom: 1px solid rgba(0,0,0,0.1);"
                    align="center"
                  >
                    <v-col
                      cols="auto "
                      style="line-height: 16px;"
                      class="pr-1 pl-0"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        :ripple="false"
                        color="#FFD84D"
                        :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D; background-color: ${event.eventParsed.input.is_material_available ? '#FFD84D' : ''}`"
                        @click="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor)) ? !event.eventParsed.input.is_material_available ? finishStage(event.eventParsed.input.id, materialAvailableId) : cancelStage(event.eventParsed.input.id, materialAvailableId) : ''"
                      >
                        <span
                          v-if="event.eventParsed.input.is_material_available"
                          style="color: #000000;"
                        >M</span>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      style="line-height: 16px;"
                      class="pr-1 pl-0"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        :ripple="false"
                        color="#FFD84D"
                        :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${event.eventParsed.input.total_cut > 0 ? '#FFD84D' : event.eventParsed.input.is_assemble == 1 ? '#4CAF50' : ''}; background-color: ${event.eventParsed.input.total_cut > 0 ? '#FFD84D' : event.eventParsed.input.is_assemble == 1 ? '#4CAF50' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.total_cut > 0"
                          style="color: #000000;"
                        >{{ event.eventParsed.input.total_cut }}</span>
                        <span
                          v-else-if="event.eventParsed.input.is_assemble == 1"
                          style="color: #FFFFFF;"
                        >C</span>
                        <span
                          v-else
                          style="color: #000000;"
                        />
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto "
                      style="line-height: 16px;"
                      class="pr-1 pl-0"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        color="#FFD84D"
                        :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${event.eventParsed.input.total_assemble > 0 ? '#FFD84D' : event.eventParsed.input.is_t_and_b == 1 ? '#4CAF50' : ''}; background-color: ${event.eventParsed.input.total_assemble > 0 ? '#FFD84D' : event.eventParsed.input.is_t_and_b == 1 ? '#4CAF50' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.total_assemble > 0"
                          style="color: #000000;"
                        >{{ event.eventParsed.input.total_assemble }}</span>
                        <span
                          v-else-if="event.eventParsed.input.is_t_and_b == 1"
                          style="color: #FFFFFF;"
                        >A</span>
                        <span
                          v-else
                          style="color: #000000;"
                        />
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto "
                      style="line-height: 16px;"
                      class="pr-1 pl-0"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        color="#FFD84D"
                        :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${event.eventParsed.input.total_t_and_b > 0 ? '#FFD84D' : event.eventParsed.input.is_glaze == 1 ? '#4CAF50' : ''}; background-color: ${event.eventParsed.input.total_t_and_b > 0 ? '#FFD84D' : event.eventParsed.input.is_glaze == 1 ? '#4CAF50' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.total_t_and_b > 0"
                          style="color: #000000;"
                        >{{ event.eventParsed.input.total_t_and_b }}</span>
                        <span
                          v-else-if="event.eventParsed.input.is_glaze == 1"
                          style="color: #FFFFFF;"
                        >T</span>
                        <span
                          v-else
                          style="color: #000000;"
                        />
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto "
                      style="line-height: 16px;"
                      class="pr-1 pl-0"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        color="#FFD84D"
                        :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${event.eventParsed.input.total_glaze > 0 ? '#FFD84D' : event.eventParsed.input.is_prod_backlog == 1 ? '#4CAF50' : ''}; background-color: ${event.eventParsed.input.total_glaze > 0 ? '#FFD84D' : event.eventParsed.input.is_prod_backlog == 1 ? '#4CAF50' : ''}`"
                        @click="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor)) && event.eventParsed.input.total_glaze > 0 ? openFinishedGoodForm(event.eventParsed.input, 6): ''"
                      >
                        <span
                          v-if="event.eventParsed.input.total_glaze > 0"
                          style="color: #000000;"
                        >{{ event.eventParsed.input.total_glaze }}</span>
                        <span
                          v-else-if="event.eventParsed.input.is_prod_backlog == 1"
                          style="color: #FFFFFF;"
                        >G</span>
                        <span
                          v-else
                          style="color: #000000;"
                        />
                      </v-btn>
                    </v-col>
                    <!-- <v-col
                      class="pa-1 pl-0"
                      cols="auto "
                      style="line-height: 16px;"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        :ripple="false"
                        color="#FFD84D"
                        :style="`cursor: default; border-radius: 4px; width: 20px; height: 20px; border: 1.5px solid #FFD84D; background-color: ${event.eventParsed.input.is_material_available ? '#FFD84D' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.is_material_available"
                          style="color: #000000;"
                        >M</span>
                      </v-btn>
                    </v-col>
                    <v-col
                      class="pa-1 pl-0"
                      cols="auto "
                      style="line-height: 16px;"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        :ripple="false"
                        color="#FFD84D"
                        :style="`cursor: default; border-radius: 4px; width: 20px; height: 20px; border: 1.5px solid #FFD84D; background-color: ${event.eventParsed.input.is_cut ? '#FFD84D' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.is_cut"
                          style="color: #000000;"
                        >C</span>
                      </v-btn>
                    </v-col>
                    <v-col
                      class="pa-1 pl-0"
                      cols="auto "
                      style="line-height: 16px;"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        color="#FFD84D"
                        :style="`cursor: default; border-radius: 4px; width: 20px; height: 20px; border: 1.5px solid #FFD84D; background-color: ${event.eventParsed.input.is_assemble ? '#FFD84D' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.is_assemble"
                          style="color: #000000;"
                        >A</span>
                      </v-btn>
                    </v-col>
                    <v-col
                      class="pa-1 pl-0"
                      cols="auto "
                      style="line-height: 16px;"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        color="#FFD84D"
                        :style="`cursor: default; border-radius: 4px; width: 20px; height: 20px; border: 1.5px solid #FFD84D; background-color: ${event.eventParsed.input.is_t_and_b ? '#FFD84D' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.is_t_and_b"
                          style="color: #000000;"
                        >T</span>
                      </v-btn>
                    </v-col>
                    <v-col
                      class="pa-1 pl-0 pr-0"
                      cols="auto "
                      style="line-height: 16px;"
                    >
                      <v-btn
                        elevation="0"
                        fab
                        outlined
                        tile
                        readonly
                        color="#FFD84D"
                        :style="`cursor: default; border-radius: 4px; width: 20px; height: 20px; border: 1.5px solid #FFD84D; background-color: ${event.eventParsed.input.is_glaze ? '#FFD84D' : ''}`"
                      >
                        <span
                          v-if="event.eventParsed.input.is_glaze"
                          style="color: #000000;"
                        >G</span>
                      </v-btn>
                    </v-col> -->
                  </v-row>
                  <v-row
                    style="border-bottom: 1px solid rgba(0,0,0,0.1);"
                    align="center"
                  >
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">SP PO#</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-1"
                      style="line-height: 16px;"
                    >
                      <span
                        v-if="event.eventParsed.input.su_po_number"
                        style="font-size: 11px; color: #000000; line-height: 10px;"
                      ><text-highlight :queries="search">{{ event.eventParsed.input.su_po_number }}</text-highlight></span>
                      <span v-else>N/A</span>
                    </v-col>
                  </v-row>
                  <v-row
                    style=""
                    align="center"
                  >
                    <v-col
                      cols="4"
                      class="pl-1 pr-0 pt-1 pb-0"
                      style="line-height: 16px;"
                    >
                      <span style="font-size: 11px; color: rgba(0,0,0,0.8)">S/U PO#</span>
                    </v-col>
                    <v-col
                      class="pl-1 pr-1 pt-1 pb-0"
                      style="line-height: 16px;"
                    >
                      <span
                        v-if="event.eventParsed.input.su_po_number"
                        style="font-size: 11px; color: #000000; line-height: 10px;"
                      ><text-highlight :queries="search">{{ event.eventParsed.input.su_po_number }}</text-highlight></span>
                      <span v-else>N/A</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row
                v-if="type === 'day'"
                v-show="event.eventParsed.input.latest"
                class="text-end pt-3 pr-2"
              >
                <v-col>
                  <span
                    style="color: #000; font-size: 14px !important; font-weight: bold;"
                  >Total Windows: {{ getTotalWindows(event.eventParsed) }}</span>
                </v-col>
              </v-row>
            </template>
          </v-calendar>
        </v-card>
      </v-col>
    </v-row>
    <production-schedule-view-form
      ref="viewForm"
      @success="get({ start: start, end: end })"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProductionScheduleViewForm from '@/components/forms/productionSchedules/ProductionScheduleViewForm';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';

export default {
  components: {
    ProductionScheduleViewForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    type: 'month',
    types: [{ type: 'month', name: 'MONTH', color: '#388E3C' }, { type: 'week', name: 'WEEK', color: '#B71C1C' }, { type: 'day', name: 'DAY', color: '#FBC02D' }],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    focusDay: '',
    loading: false,
    selectedSlot: null,
    selectedOpen: false,
    selectedEvent: null,
    start: null,
    end: null,
    filterStatusRequest: null,
    statuses: [],
    filterJob: null,
    filterTruck: null,
    trucks: [],
    filterPo: null,
    filterStage: null,
    filterGlassReqBy: null,
    filterPlannedShipDate: null,
    filterJobSubClass: null,
    filterRecordType: null,
    dateMenu: false,
    inventoryClassId: null,
    jobs: [],
    poNumbers: [],
    dateMenuRequiredBy: false,
    dateMenuShipDate: false,
    filterMenu: false,
    stages: [{ name: 'Cut', id: 1, code: 'C' }, { name: 'Assembled', id: 2, code: 'A' }, { name: 'T&B', id: 3, code: 'T' }, { name: 'Glazed', id: 4, code: 'G' }],
    totalWindows: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    userRoleIdProductionSupervisor: 18,
    userRoleIdSplanderPaintSupervisor: 21,
    userRoleIdProductionAdmin: 22,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    dragDate: null,
    isDrag: false,
    roles: [],
    locations: [],
    tabs: [],
    locationTabFilter: null,
    selectedTab: null,
    recordTypes: [{ id: 1, name: 'Default Records' }, { id: 2, name: 'Manual Records' }],
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
      productionScheduleCalendarState: (state) => state.productionScheduleCalendarState
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    inventoryClassId(val) {
      if (val === null) return;
      this.get({ start: this.start, end: this.end });
      this.getJobs();
    },
    filterJob(value) {
      this.storeState('job_id', value)
      this.get({ start: this.start, end: this.end });
    },
    filterPo(value) {
      this.storeState('po_number', value)
      this.get({ start: this.start, end: this.end });
    },
    filterStage(value) {
      this.storeState('stage', value)
      this.get({ start: this.start, end: this.end });
    },
    filterJobSubClass() {
      this.get({ start: this.start, end: this.end });
    },
    filterRecordType(value) {
      this.storeState('record_type', value)
      this.get({ start: this.start, end: this.end });
    },
    locationTabFilter() {
      if (this.locationTabFilter === 0) {
        this.filterLocationId = null;
        this.get({ start: this.start, end: this.end });
        return;
      }
      const location = this.tabLocations[this.locationTabFilter];
      if (!location) return;
      this.filterLocationId = location.id;
      this.get({ start: this.start, end: this.end });
    },
    search() {
      this.get({ start: this.start, end: this.end });
    },
  },
  created() {
    if(this.productionScheduleCalendarState){
      if(this.productionScheduleCalendarState.job_id) {this.filterJob = this.productionScheduleCalendarState.job_id}
      if(this.productionScheduleCalendarState.po_number) {this.filterPo = this.productionScheduleCalendarState.po_number}
      if(this.productionScheduleCalendarState.stage) {this.filterStage = this.productionScheduleCalendarState.stage}
      if(this.productionScheduleCalendarState.record_type) {this.filterRecordType = this.productionScheduleCalendarState.record_type}
    }
    this.init();
    this.roles = this.user.roles.map((x) => x.id);
  },
  methods: {
    init() {
      this.getJobs();
      this.getGlassPos();
      this.getLocations();
    },

    storeState(property, value){
      const state = this.productionScheduleCalendarState;
      state[property] = value;
      this.$store.commit('storeProductionScheduleCalendarState', state);
    },

    async get({ start, end }) {
      if (!start || !end) return;
      this.$bus.$emit('loading');
      this.start = start;
      this.end = end;
      const statusIdCurrent = 1;
      const submitModel = {
        filters: {
          start_date: start.date,
          end_date: end.date,
          status_id: statusIdCurrent,
          inventory_class_id: this.inventoryClassId,
          stage: this.filterStage,
          job_id: this.filterJob,
          po_number: this.filterPo,
          job_sub_class_id: this.filterJobSubClass,
          location_id: this.filterLocationId,
          record_type: this.filterRecordType,
        },
        search: this.search,
      };
      const res = await this.$api.productionSchedule.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.items = [];
        this.$bus.$emit('loading-stop');
        return;
      }
      this.items = res.data;
      this.setGroupedItems();
      this.$bus.$emit('loading-stop');
    },

    setGroupedItems() {
      this.totalWindows = [];
      this.items.forEach((x) => {
        const totalWindowItem = this.totalWindows.findIndex((y) => y.date === x.planned_ship_date);
        if (totalWindowItem !== -1) {
          this.totalWindows[totalWindowItem].totalReleaseQty += x.total_release_qty;
        } else {
          this.totalWindows.push({
            date: x.planned_ship_date, totalReleaseQty: x.total_release_qty,
          });
        }
      });

      // Create a dictionary to store grouped items
      const groupedItems = {};

      // Iterate through the sorted list of items
      this.items.forEach((item) => {
        // eslint-disable-next-line camelcase
        const { planned_ship_date } = item;

        // Check if the planned_ship_date date already exists as a key in the dictionary
        // eslint-disable-next-line no-prototype-builtins
        if (groupedItems.hasOwnProperty(planned_ship_date)) {
          // Append the item to the existing group
          groupedItems[planned_ship_date].push(item);
        } else {
          // Create a new group with the current item
          groupedItems[planned_ship_date] = [item];
        }
      });

      // Set the latest parameter to true for the last item in each group
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const groupKey in groupedItems) {
        const group = groupedItems[groupKey];
        const lastItemIndex = group.length - 1;
        if (lastItemIndex >= 0) {
          group[lastItemIndex].latest = true;
          const latestIndex = this.items.findIndex((x) => x.id === group[lastItemIndex].id);
          if (latestIndex !== -1) {
            this.items[latestIndex].latest = true;
          }
        }
      }
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();

      const locationId99Vinyl = 3;
      const locationId141Adesso = 8;
      const locationId300Chrislea = 2;
      this.tabs.push(...this.locations.filter((x) => x.id === locationId99Vinyl || x.id === locationId141Adesso || x.id === locationId300Chrislea));
      this.tabs.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        x.type = 'location';
      });
      this.tabs.push({
        id: 2, name: 'CORNER POST', color: '#E53935', type: 'sub-class',
      }, {
        id: 3, name: 'EURO DOOR', color: '#FDD835', type: 'sub-class',
      });
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    setToday() {
      this.type = 'day';
      this.focusDay = '';
    },

    success() {
      this.getSlots({ start: this.start, end: this.end });
    },

    getEventColor(item) {
      return item.color;
    },

    viewDay({ date }) {
      this.focusDay = date;
      this.type = 'day';
    },

    openViewForm({ event }) {
      this.$refs.viewForm.open(event.id);
    },

    getItemColor(item) {
      if (item.is_glaze === 1 && item.total_glaze === 0) {
        return '#0BDE79';
      } if (item.is_t_and_b === 1 && item.total_t_and_b === 0) {
        return '#F7E800';
      }
      if (item.is_assemble === 1 && item.total_assemble === 0) {
        return '#9C27B0';
      } if (item.is_cut === 1 && item.total_cut === 0) {
        return '#00BFA5';
      } if (item.is_material_available === 1 && item.total_material === 0) {
        return '#1870CB';
      }
      return '#F7A51C';
    },

    getItemName(item) {
      if (item.is_glaze === 1 && item.total_glaze === 0) {
        return 'COMPLETE';
      } if (item.is_t_and_b === 1 && item.total_t_and_b === 0) {
        return 'GLAZE';
      }
      if (item.is_assemble === 1 && item.total_assemble === 0) {
        return 'T & B';
      } if (item.is_cut === 1 && item.total_cut === 0) {
        return 'ASSEMBLY';
      } if (item.is_material_available === 1 && item.total_material === 0) {
        return 'CUT';
      }
      return 'MATERIAL';
    },

    getFontColor(item) {
      if (item.is_glaze === 1 && item.total_glaze === 0) {
        return '#000';
      } if (item.is_t_and_b === 1 && item.total_t_and_b === 0) {
        return '#000';
      }
      if (item.is_assemble === 1 && item.total_assemble === 0) {
        return '#000';
      } if (item.is_cut === 1 && item.total_cut === 0) {
        return '#FFF';
      } if (item.is_material_available === 1 && item.total_material === 0) {
        return '#FFF';
      }
      return '#FFF';
    },

    clearAllFilters() {
      this.filterJob = null;
      this.filterPo = null;
      this.filterStage = null;
      this.filterGlassReqBy = null;
      this.filterPlannedShipDate = null;
    },

    async getJobs() {
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId, is_sub_class: 1 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
    },

    async getGlassPos() {
      const res = await this.$api.glass.get(`po-numbers/${this.inventoryClassId}/${null}`);
      if (!res) {
        return;
      }
      this.poNumbers.push('N/A');
      this.poNumbers.push(...res);
    },

    tabChange(item) {
      if (this.selectedTab === item) { this.selectedTab = null; this.filterJobSubClass = null; this.filterLocationId = null; this.get(); return; }
      this.selectedTab = item;
      if (item.type === 'sub-class') {
        this.filterJobSubClass = item.id;
        this.filterLocationId = null;
      } else if (item.type === 'location') {
        this.filterLocationId = item.id;
        const jobSubClass4800Windows = 1;
        const tab300Chrislea = 2;
        if (item.id === tab300Chrislea) {
          this.filterJobSubClass = jobSubClass4800Windows;
        } else { this.filterJobSubClass = null; }
      }
      this.get({ start: this.start, end: this.end });
    },

    getTotalWindows(data) {
      const item = this.totalWindows.find((x) => x.date === data.input.planned_ship_date);
      if (!item) return 0;
      return item.totalReleaseQty;
    },

    startDrag({ event }) {
      if (!this.roles.includes(this.userRoleIdAdmin) && !this.roles.includes(this.userRoleIdProductionAdmin) && !this.roles.includes(this.userRoleIdAssociate)) return;
      if (event) {
        this.dragEvent = event;
        this.isDrag = true;
      }
    },

    mouseMove(tms) {
      if (!this.roles.includes(this.userRoleIdAdmin) && !this.roles.includes(this.userRoleIdProductionAdmin) && !this.roles.includes(this.userRoleIdAssociate)) return;
      if (!this.isDrag) return;
      const index = this.items.findIndex((x) => x.id === this.dragEvent.id);
      if (index !== -1) {
        this.items[index].start = tms.date;
        this.items[index].end = tms.date;
        this.$set(this.items, index, this.items[index]);
      }
    },

    async endDrag() {
      if (!this.roles.includes(this.userRoleIdAdmin) && !this.roles.includes(this.userRoleIdProductionAdmin) && !this.roles.includes(this.userRoleIdAssociate)) return;
      if (!this.dragEvent || this.isDrag === false) return;
      this.isDrag = false;
      const res = await this.$api.productionSchedule.update({ id: this.dragEvent.id, planned_ship_date: this.dragEvent.start, planned_ship_time: this.dragEvent.planned_ship_time }, 'planned-ship-date');
      if (!res) return;
      this.dragEvent = null;
      this.get({ start: this.start, end: this.end });
    },

    async runWebhook(documentType) {
      if (this.inventoryClassId === null) {
        this.$bus.$emit('showError', 'Please select an inventory class');
        return;
      }
      this.$bus.$emit('loading');
      const REPORT_MODULE_ID_PROD_SCHEDULE_CALENDAR = 31;
      const REPORT_MODULE_ID_PROD_SCHEDULE_CALENDAR_WEEKLY = 30;
      const reportModuleId = this.type === 'week' ? REPORT_MODULE_ID_PROD_SCHEDULE_CALENDAR_WEEKLY : REPORT_MODULE_ID_PROD_SCHEDULE_CALENDAR;
      const submitModel = {
        inventory_class_id: this.inventoryClassId, year: this.start.year, month: this.getMonthName(this.start.month), week: this.getWeekNumber(this.start.date), module_id: reportModuleId, location_id: this.filterLocationId ? this.filterLocationId : 0, sub_class: this.filterJobSubClass ? this.filterJobSubClass : 0,
      };
      const res = await this.$api.webhook.store(submitModel, 'run');
      this.$bus.$emit('loading');
      if (!res) return;
      const pdf = await this.$api.report.download(res.id, documentType);
      if (!pdf) {
        this.$bus.$emit('showError', 'Report Failed');
        this.$bus.$emit('loading');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${res.report_title}_${now}`;
      const file = new Blob([pdf], {
        type: documentType === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
      this.$bus.$emit('loading-stop');
    },

    getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
      return date.toLocaleString('default', { month: 'long' });
    },

    getWeekNumber(date) {
      // Convert the input string to a Date object
      const inputDate = new Date(date);

      // Set the target date to January 4th (week 1) of the same year
      const targetDate = new Date(inputDate.getFullYear(), 0, 4);

      // Calculate the time difference between the input date and the target date
      const timeDiff = inputDate - targetDate;

      // Calculate the week number using the time difference and the milliseconds in a week
      const weekNumber = Math.ceil(timeDiff / (7 * 24 * 60 * 60 * 1000));

      return weekNumber;
    },
  },
};
</script>

  <style scoped lang="scss">
  ::v-deep .wrap-text {
    white-space: normal !important;
  }

  ::v-deep .v-calendar .v-event {
      min-width: 195px !important;
  }

  ::v-deep .v-calendar-daily_head-weekday {
      font-size: 20px !important;
  }

  ::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
      font-size: 18px !important;
  }

  ::v-deep .v-calendar-weekly__day-label .v-btn   {
      font-size: 18px !important;
  }

  ::v-deep .v-calendar-daily_head-day-label .v-btn .v-btn__content{
    font-size: 20px !important;
}

::v-deep .theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  display: none !important;
}

::v-deep .theme--light.v-calendar-daily .v-calendar-daily__intervals-head {
  display: none !important;
}

::v-deep .v-calendar-daily__head
{
  margin-right: 0px !important;
}
::v-deep .v-calendar-daily__scroll-area {
    overflow-y: hidden !important;
}

::v-deep .v-calendar-daily__body{
  display: none;
}

::v-deep .v-calendar .v-event {
    max-width: 200px;
    float: left;
    height: auto !important;
    margin: 3px 0px 0px 3px;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

</style>
