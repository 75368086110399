<template>
  <v-container>
    <h4>Access Modules</h4>
    <v-row>
      <v-col
        v-for="(item, index) in adminModules"
        :key="index"
        sm="2"
      >
        <v-checkbox
          :key="'m' + index"
          :label="item.name.toUpperCase()"
          :input-value="item.enabled"
          @click="submit(item)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4>Functionality Elements</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(item, index) in booleanRules"
        :key="index"
        sm="2"
      >
        <v-checkbox
          :key="'b' + index"
          :label="item.name"
          :input-value="item.value"
          @click="changeBusinessRule(item)"
        />
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import BusinessRulesApi from '../../api/BusinessRulesApi';

export default {
  computed: {
    ...mapState({
      adminModules: 'admin_modules',
      businessRules: 'business_rules',
    }),
    booleanRules() {
      const filteredRules = this.businessRules.filter((x) => x.data_type === 'boolean');
      filteredRules.forEach((x) => {
        x.value = parseInt(x.value, 10);
      });
      return filteredRules;
    },
  },
  methods: {

    async changeBusinessRule(item) {
      const record = item;
      record.value = !item.value;
      const res = await BusinessRulesApi.store(record);
      if (res === true) {
        const businessRules = await BusinessRulesApi.getList();
        this.$store.commit('storeBusinessRules', businessRules);
      }
    },

    async submit(item) {
      const record = item;
      record.enabled = !item.enabled;
      this.$store.commit('storeAdminModules', this.adminModules);
      const res = await this.$api.adminModule.store(record);
      if (!res) {
        this.$refs.snackbar.showError();
      }
    },

  },

};

</script>
