<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          @click="open()"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Colour
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="colorsHeaders"
            :items="colors"
            :footer-props="itemsTableFooterProps"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            fluid
          >
            <template #item="{ item }">
              <tr>
                <td :class="{ 'red-theme': item.enabled == 0 }">
                  <text-highlight :queries="search">
                    {{ item.id }}
                  </text-highlight>
                </td>
                <td :class="{ 'red-theme': item.enabled == 0 }">
                  <text-highlight :queries="search">
                    {{ item.code }}
                  </text-highlight>
                </td>
                <td :class="{ 'red-theme': item.enabled == 0 }">
                  <text-highlight :queries="search">
                    {{ item.name }}
                  </text-highlight>
                </td>
                <td :class="{ 'red-theme': item.enabled == 0 }">
                  <text-highlight :queries="search">
                    {{ item.created_at }}
                  </text-highlight>
                </td>
                <td :class="{ 'red-theme': item.enabled == 0 }">
                  <text-highlight :queries="search">
                    {{ item.created_by_full_name }}
                  </text-highlight>
                </td>
                <td style="text-align:right">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="open(item.id)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1 pl-5"
                    color="purple"
                    outlined
                    small
                    @click="toggle(item)"
                  >
                    {{ item.enabled === 1 ? 'DISABLE' : 'ENABLE' }}
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    @click="softDelete(item.id)"
                  >
                    DELETE
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <color-form
      ref="itemFrom"
      @success="get()"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ColorForm from '../../components/forms/ColorForm';

export default {
  components: {
    ColorForm,
  },
  data: () => ({
    colors: [],
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    colorsHeaders: [
      { text: 'Id', value: 'id' },
      { text: 'Code', value: 'code' },
      { text: 'Name', value: 'name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Actions', align: 'end' },
    ],

  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.get();
    },

    async get() {
      this.loading = true;

      const submitModel = {
        filters: {
          is_not_enabled_filter: 1,
        },
      };

      this.colors = await this.$api.color.read(submitModel);
      this.loading = false;
    },

    open(id) {
      this.$refs.itemFrom.open(id);
    },

    async toggle(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure you want to ${item.enabled === 0 ? 'Disable' : 'Enable'} this record?`,
      });
      if (!confirm) return;
      const res = await this.$api.color.get(`toggle/${item.id}`);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;

      const res = await this.$api.color.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

  },

};
</script>
<style scoped>
.profile-photo{
  max-width: 100px;
}
.text-right{
  width: 85px!important;
}
.red-theme{
  color: #fa7575!important;;
}</style>
