import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
class InvoiceApi extends Api {
  constructor() {
    super('invoices', 'invoices');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const invoiceApi = new InvoiceApi();

export default invoiceApi;
