<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Update'"
    :max-width="600"
  >
    <template slot="header">
      <span>Update Job</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-autocomplete
            v-model="jobIdOld"
            :items="jobsSecondary"
            :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
            item-value="id"
            :disabled="true"
            clearable
            :loading="loadingSecondary"
          >
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                v-if="item && item.sub_class"
                small
                :color="item.sub_class.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.sub_class.name }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <v-chip
                v-if="item && item.sub_class"
                small
                :color="item.sub_class.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.sub_class.name }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <template slot="label">
              Project/Job<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-autocomplete
            v-model="jobIdNew"
            :items="jobs"
            :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
            item-value="id"
            :rules="[(v) => !!v || 'Project/Job is required']"
            clearable
            :loading="loading"
          >
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                v-if="item && item.sub_class"
                small
                :color="item.sub_class.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.sub_class.name }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <v-chip
                v-if="item && item.sub_class"
                small
                :color="item.sub_class.color"
                text-color="white"
                class="mr-2"
              >
                {{ item.sub_class.name }}
              </v-chip>
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
            <template slot="label">
              New Project/Job<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    productionSchedule: null,
    jobs: [],
    jobsSecondary: [],
    jobIdOld: null,
    jobIdNew: null,
    loading: false,
    loadingSecondary: false,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
  },
  methods: {

    async submit() {
      const job = this.jobsSecondary.find((item) => item.id === this.jobIdOld);
      if (job && job.code.includes('PENDING')) {
        const resJob = await this.$api.job.delete(job.id);
        if (!resJob) {
          this.$bus.$emit('showError');
          return false;
        }
      }

      this.productionSchedule.job_id = this.jobIdNew;
      const res = await this.$api.productionSchedule.update(this.productionSchedule);

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      if (res !== true) {
        this.$bus.$emit('showError', res);
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async getJobs() {
      this.loading = true;
      const submitModel = {
        filters: { inventory_class_id: this.productionSchedule.inventory_class_id, is_sub_class: 1, is_manual: 0 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
      this.loading = false;
    },

    async getJobsSecondary() {
      this.loadingSecondary = true;
      const submitModel = {
        filters: { inventory_class_id: this.productionSchedule.inventory_class_id, is_sub_class: 1, is_manual: 1 },
      };
      const res = await this.$api.job.readDataSecondary(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobsSecondary = res;
      this.loadingSecondary = false;
    },

    open(item) {
      this.$refs.dialogBox.open();
      this.productionSchedule = item;
      this.jobIdOld = item.job_id;
      this.jobIdNew = null;
      this.getJobs();
      this.getJobsSecondary();
    },
  },
};
</script>
