<template>
  <div
    class="zigzag"
    :style="cssVars"
  />
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  data: () => ({
    bgColorDataBefore: 'linear-gradient(-135deg, #337ab7 10px, transparent 0) 0 10px, linear-gradient(135deg, #337ab7 10px, transparent 0)',
    bgColorDataAfter: 'linear-gradient(-135deg, #FFFFFF 10px, transparent 0) 0 10px, linear-gradient(135deg, #FFFFFF 10px, transparent 0)',
  }),
  computed: {
    cssVars() {
      return {
        '--bg-color-before': this.bgColorDataBefore,
        '--bg-color-after': this.bgColorDataAfter,
        '--height': `${this.height}px`,
      };
    },
  },
  watch: {
    bgColor: {
      immediate: true,
      handler(value) {
        switch (value) {
          case 'yellow':
            this.bgColorDataAfter = 'linear-gradient(-135deg, #FFFDE7 10px, transparent 0) 0 10px, linear-gradient(135deg, #FFFDE7 10px, transparent 0)';
            break;
          case 'blue':
            this.bgColorDataAfter = 'linear-gradient(-135deg, #e5f8ff 10px, transparent 0) 0 10px, linear-gradient(135deg, #e5f8ff 10px, transparent 0)';
            break;
          case 'orange':
            this.bgColorDataBefore = 'linear-gradient(-135deg, #337ab7 10px, transparent 0) 0 10px, linear-gradient(135deg, #337ab7 10px, transparent 0)';
            this.bgColorDataAfter = 'linear-gradient(-135deg, #fdefe4 10px, transparent 0) 0 10px, linear-gradient(135deg, #fdefe4 10px, transparent 0)';
            break;
          case 'green':
            this.bgColorDataAfter = 'linear-gradient(-135deg, #e5fcec 10px, transparent 0) 0 10px, linear-gradient(135deg, #e5fcec 10px, transparent 0)';
            break;
          default:
            break;
        }
      },
    },
  },
};
</script>

<style>
/*** Zigzag Line Styling ***/
.zigzag {
  margin-top: 10px;
  margin-bottom: 40px;
  position: relative;
  height: 10px;
  z-index: 1;
}
.zigzag:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  /* Line thickness */
  top: 110%;
  background: var(--bg-color-before) 0 10px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}
.zigzag:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  top: 100%;
  background: var(--bg-color-after) 0 10px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}
.zigzagYellow:after{
  background: var(--bg-color-after) 0 10px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}
.zigzagGreen:after{
  background: var(--bg-color-after) 0 10px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}
.zigzagBlue:after{
  background: var(--bg-color-after) 0 10px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}
.zigzagOrange:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  /* Line thickness */
  top: 110%;
  background: var(--bg-color-before) 0 10px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}
.zigzagOrange:after{
  background: var(--bg-color-after) 0 10px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 20px 20px;
}
/*** End Zigzag Line Styling ***/
</style>
