<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="600px"
        :persistent="true"
      >
        <validation-observer
          ref="userValidationObserver"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.first_name"
                        :error-messages="errors"
                      >
                        <template slot="label">
                          First Name<RedAsterisk />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.last_name"
                        :error-messages="errors"
                      >
                        <template slot="label">
                          Last Name<RedAsterisk />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Roles"
                      rules="required|list"
                    >
                      <v-autocomplete
                        v-model="user.roles"
                        :items="roles"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        multiple
                        return-object
                        :search-input.sync="roleSearchInput"
                        @change="roleSearchInput=''"
                      >
                        <template slot="label">
                          Roles<RedAsterisk />
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-if="isInventoryClasses"
                      v-model="user.inventory_class_ids"
                      clearable
                      multiple
                      label="Default Classes"
                      :items="inventoryClasses"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ bottom: true, offsetY: true }"
                      @change="onChangeInventoryClass"
                    >
                      <template #item="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Supervisor"
                    >
                      <v-autocomplete
                        v-model="user.supervisors"
                        :items="supervisors"
                        item-text="full_name"
                        item-value="id"
                        :error-messages="errors"
                        label="Supervisors"
                        clearable
                        multiple
                        return-object
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <VuePhoneNumberInput
                      v-model="user.phone_number"
                      default-country-code="CA"
                      @update="onUpdate"
                    />
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Location"
                    >
                      <v-autocomplete
                        v-model="user.location_id"
                        :items="locations"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        label="Location"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <!-- <validation-provider
                      v-slot="{ errors }"
                      name="Start Date"
                    > -->
                    <v-menu
                      v-model="startDateMenu"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      offset-overflow
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          :value="user.start_date"
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          label="Start Date"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="user.start_date"
                        no-title
                        scrollable
                      />
                    </v-menu>
                    <!-- </validation-provider> -->
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Username"
                      :rules="user.id ? '': 'required|username'"
                    >
                      <v-text-field
                        v-model="user.username"
                        :error-messages="errors"
                      >
                        <template slot="label">
                          Username<RedAsterisk />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="email"
                    >
                      <v-text-field
                        v-model="user.email"
                        type="email"
                        :error-messages="errors"
                      >
                        <template slot="label">
                          Email
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      :rules="user.id ? '' : 'required|password:@confirm'"
                    >
                      <v-text-field
                        v-model="user.password"
                        type="password"
                        :error-messages="errors"
                        autocomplete="new-password"
                      >
                        <template slot="label">
                          Password<RedAsterisk v-if="!user.id" />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="confirm"
                      :rules="user.id ? '' : 'required'"
                    >
                      <v-text-field
                        v-model="user.confirmPassword"
                        type="password"
                        :error-messages="errors"
                      >
                        <template slot="label">
                          Confirm Password<RedAsterisk v-if="!user.id" />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="normal"
                class="mr-2"
                @click="closeForm"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="submitForm"
              >
                {{ user.id !== 0 ? 'Update' : 'Create' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
        <Snackbar ref="snackbar" />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import RolesApi from '@/api/RolesApi';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { required, email } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
import UsersApi from '@/api/UsersApi';
import { mapGetters, mapState } from 'vuex';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('email', {
  ...email,
  message: 'Email must be valid',
});

extend('username', {
  async validate(value) {
    return UsersApi.checkUsername(value);
  },
  message: 'Email is already registered. Please try another one.',
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match',
});

extend('list', {
  async validate(value) {
    return value.length > 0;
  },
  message: '{_field_} can not be empty',
});

export default {
  components: {
    VuePhoneNumberInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userP: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dialog: false,
    phvalid: false,
    roles: [],
    user: null,
    defaultUser:
    {
      id: 0,
      first_name: '',
      username: '',
      last_name: '',
      email: '',
      roles: [],
      phone_number: '',
      password: null,
      confirmPassword: null,
      inventory_class_id: null,
      supervisors: [],
      start_date: null,
    },
    isInventoryClasses: false,
    inventoryClasses: [],
    locations: [],
    supervisors: [],
    class_id_all: 100,
    userRoleIdTimeOffSupervisor: 28,
    userRoleIdHrManager: 29,
    startDateMenu: false,
    roleSearchInput: '',
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({

    }),
  },
  watch: {
    userP: {
      immediate: true,
      handler(value) {
        this.user = value || JSON.parse(JSON.stringify(this.defaultUser));
      },
    },
  },
  created() {
    this.initialize();
    this.getClasses();
    this.getLocations();
    this.getSupervisors();
  },
  methods: {
    async initialize() {
      this.getRoles();
      const invClassesBRName = 'Inventory Classes';
      const invClassesBRValue = this.businessRulesGetBoolean(invClassesBRName);
      if (invClassesBRValue != null && invClassesBRValue !== 0) this.isInventoryClasses = true;
    },
    openDialog() {
      this.dialog = true;
      this.disableHrManagerRoleSelection();
    },
    closeForm() {
      this.dialog = false;
      this.clearForm();
    },
    async getClasses() {
      const submitData = {
        filters: {
          is_remove_all: false,
        },
      };

      const res = await this.$api.inventoryClass.store(submitData, 'read');

      if (!res) return;

      const classIdAll = 0;
      this.inventoryClasses = res.filter((x) => x.id !== classIdAll);
    },
    async getLocations() {
      const res = await this.$api.location.get();

      if (!res) return;
      this.locations = res;
    },
    async getSupervisors() {
      const submitModel = {
        filters: {
          role_id: this.userRoleIdTimeOffSupervisor,
        },
      };
      this.supervisors = await this.$api.user.read(submitModel);
    },
    onUpdate(payload) {
      this.phvalid = payload.isValid;
    },

    async submitForm() {
      if (!await this.$refs.userValidationObserver.validate()) return;
      // if (!this.phvalid) {
      //   this.$refs.snackbar.showError('Please Enter Valid Phone number');
      //   return;
      // }
      const response = await UsersApi.store(this.user);

      if (!response) {
        this.$refs.snackbar.showError();
        return;
      }

      if (this.user.id !== 0) {
        this.$refs.snackbar.showSuccess('User Updated');
      } else {
        this.$refs.snackbar.showSuccess('User Created');
      }
      this.$emit('user-creation');
      this.closeForm();
    },
    async clearForm() {
      await this.$refs.userValidationObserver.reset();

      this.user = JSON.parse(JSON.stringify(this.defaultUser));
    },
    async getRoles() {
      this.roles = await RolesApi.getList();
    },
    onChangeInventoryClass() {
      if (this.user.inventory_class_ids.includes(this.class_id_all)) {
        this.user.inventory_class_ids = [this.class_id_all];
      }
    },
    async disableHrManagerRoleSelection() {
      const submitModel = {
        filters: {
          role_id: this.userRoleIdHrManager,
        },
      }

      const res = await this.$api.user.read(submitModel);
      if (!res) {
        return;
      }

      if(res.length > 0) {
        //if hr manager exists
        const userIsHrManager = res.some(user => user.id === this.user.id);
        const index = this.roles.findIndex(x => x.id === this.userRoleIdHrManager)
        if (!userIsHrManager) {
          this.roles[index].disabled = true;
        } else {
          this.roles[index].disabled = false;
        }
      }
    }
  },
};
</script>
<style>
  .v-input--checkbox .v-label {
    font-size: 0.9em;
  }
</style>
