import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;
let cancelReadSecondary;
let cancelFilterRead;

class InventoryItemsApi extends Api {
  constructor() {
    super('inventory_item', 'inventory/items');
  }

  async getRelatedProperties(nameId) {
    let list = [];
    const errorText = 'Could not fetch related entries';

    try {
      list = await axios.get(`/inventory/items/related_properties/${nameId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getSingleByProperties(
    nameId,
    manufacturerId,
    materialProperty,
    length,
    colorId,
    finishingId,
  ) {
    const errorText = 'Could not fetch inventory item';
    const data = {
      inventory_name_id: nameId,
      manufacturer_id: manufacturerId,
      material_property: materialProperty,
      length,
      color_id: colorId,
      finishing_id: finishingId,
    };
    let entry = null;
    try {
      entry = await axios.post(
        '/inventory/items/get_single_by_properties',
        data,
      );
      if (entry.status === 200) {
        entry = entry.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      entry = null;
    }

    return entry;
  }

  async softDelete(itemId) {
    const errorText = 'Could not delete inventory item';
    let res = null;
    try {
      res = await axios.get(`/inventory/items/soft_delete/${itemId}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }

  async readDataSecondary(data) {
    if (cancelReadSecondary !== undefined) {
      cancelReadSecondary();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadSecondary = c;
      }),
    });
    return res;
  }

  async readFilterData(data) {
    if (cancelFilterRead !== undefined) {
      cancelFilterRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelFilterRead = c;
      }),
    });
    return res;
  }
}
const inventoryItemsApi = new InventoryItemsApi();

export default inventoryItemsApi;
