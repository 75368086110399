import jsPDF from 'jspdf';
import jsPDFAutotable from 'jspdf-autotable';
import FileSaver from 'file-saver';
import moment from 'moment';

const PdfHelper = {
  fontSize: 7,

  generateFromTable(
    tableSelector,
    orientation,
    title,
    type,
    headerData = null,
  ) {
    const doc = new jsPDF(orientation, 'px', 'letter');
    const totalPagesExp = '{total_pages_count_string}';
    let topMargin = 45;

    if (type === 'bom') {
      topMargin = 65;
    }

    doc.autoTable({
      html: tableSelector,
      styles: {
        fontSize: this.fontSize,
      },
      didDrawPage: (data) => {
        getHeader(doc, data, title, type, headerData);
        getFooter(doc, totalPagesExp, data);
      },
      margin: { top: topMargin },
    });

    doc.putTotalPages(totalPagesExp);
    return doc.output('blob');
  },

  savePdfToBrowser(pdfBlob, fileName) {
    const file = new Blob([pdfBlob], {
      type: 'application/pdf',
    });
    FileSaver.saveAs(file, `${fileName}.pdf`);
  },

  savePdfToBrowser64(b64Data, fileName) {
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {
      type: 'application/pdf',
    });
    FileSaver.saveAs(file, `${fileName}.pdf`);
  },
};

const getHeader = (doc, data, title, type, headerData) => {
  const fontColor = 40;
  const fontStyle = 'normal';
  const fontSize = 20;
  const y = 15;

  doc.setFontSize(fontSize);
  doc.setTextColor(fontColor);
  doc.setFont(fontStyle);

  // Logo
  const logo = require('@/assets/logo.png');
  const logoData = new Image();
  logoData.src = logo;
  doc.addImage(logoData, 'JPEG', data.settings.margin.left, y, 125, 30);

  // Center title
  doc.text(getCenterOffset(doc, title), y + 11, title);

  if (type === 'bom') {
    const jobCodeLine = `Job Code: ${headerData.job_code}`;
    const supervisorLine = `Supervisor: ${headerData.supervisor}`;

    doc.setFontSize(PdfHelper.fontSize);
    doc.myText(jobCodeLine, { align: 'right', pageData: data }, 0, y + 4);
    doc.myText(supervisorLine, { align: 'right', pageData: data }, 0, y + 14);

    if (headerData.job_address) {
      const jobAddrArr = headerData.job_address.split(',');
      let addrLineOne = '';
      let addrLineTwo = '';
      let addrLineThree = '';

      if (jobAddrArr[1].includes('Unit')) {
        addrLineOne = `${jobAddrArr[0]},${jobAddrArr[1]}`;
        addrLineTwo = `${jobAddrArr[2]},${jobAddrArr[3]}`;
        addrLineThree = jobAddrArr[4];
      } else {
        addrLineOne = jobAddrArr[0];
        addrLineTwo = `${jobAddrArr[1]},${jobAddrArr[2]}`;
        addrLineThree = jobAddrArr[3];
      }

      doc.myText(addrLineOne, { align: 'right', pageData: data }, 0, y + 24);
      doc.myText(addrLineTwo, { align: 'right', pageData: data }, 0, y + 34);
      doc.myText(addrLineThree, { align: 'right', pageData: data }, 0, y + 44);
    }
  }
};

const getFooter = (doc, totalPagesExp, data) => {
  let str = `Page ${doc.internal.getNumberOfPages()}`;

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === 'function') {
    str = `${str} of ${totalPagesExp}`;
  }

  doc.setFontSize(10);
  const { pageSize } = doc.internal;
  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
  const pageHeight = pageSize.getHeight();
  const poweredLine = 'Powered by Zita MRP';
  doc.text(str, data.settings.margin.left, pageHeight - 10);
  doc.text(getCenterOffset(doc, poweredLine), pageHeight - 10, poweredLine);

  const timestamp = moment().format('YYYY-MMM-DD h:mm a');
  const timestampLine = `Generated at: ${timestamp}`;
  // Align timestamp to the right
  doc.text(
    timestampLine,
    doc.internal.pageSize.width
      - ((doc.getStringUnitWidth(timestampLine) + 3)
        * doc.internal.getFontSize())
        / doc.internal.scaleFactor,
    pageHeight - 10,
  );
};

const getCenterOffset = (doc, text) => {
  const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize())
    / doc.internal.scaleFactor;
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
  return textOffset;
};

(function (API) {
  API.myText = function (txt, options, x, y) {
    options = options || {};
    /* Use the options align property to specify desired text alignment
     * Param x will be ignored if desired text alignment is 'center'.
     * Usage of options can easily extend the function to apply different text
     * styles and sizes
     */
    if (options.align == 'center') {
      // Get current font size
      var fontSize = this.internal.getFontSize();

      // Get page width
      var pageWidth = this.internal.pageSize.width;

      // Get the actual text's width
      /* You multiply the unit width of your string by your font size and divide
       * by the internal scale factor. The division is necessary
       * for the case where you use units other than 'pt' in the constructor
       * of jsPDF.
       */
      const txtWidth = (this.getStringUnitWidth(txt) * fontSize) / this.internal.scaleFactor;

      // Calculate text's x coordinate
      x = (pageWidth - txtWidth) / 2;
    } else if (options.align === 'right') {
      // Get current font size
      var fontSize = this.internal.getFontSize();

      // Get page width
      var pageWidth = this.internal.pageSize.width;
      const txtWidth = (this.getStringUnitWidth(txt) * fontSize) / this.internal.scaleFactor;

      // Calculate text's x coordinate
      if (options.pageData) {
        if (options.pageData.settings.margin) {
          x = pageWidth - txtWidth - options.pageData.settings.margin.right;
        }
      } else {
        x = pageWidth - txtWidth;
      }
    }

    // Draw text at x,y
    this.text(txt, x, y);
  };
}(jsPDF.API));

export default PdfHelper;
