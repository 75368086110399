import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;
class TruckScheduleRequestApi extends Api {
  constructor() {
    super('truck-schedules/requests', 'truck-schedules/requests');
  }

  async readRequests(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const truckScheduleRequestApi = new TruckScheduleRequestApi();

export default truckScheduleRequestApi;
