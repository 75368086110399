/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
export const auth_request = (state) => {
  state.status = 'loading';
};

export const auth_success = (state, data) => {
  state.user = data.user;
  state.token = data.token;
};

// export const set_client_env = (state, clientEnv) => {
//   state.clientEnv = clientEnv;
// };

export const logout = (state) => {
  state.token = '';
  state.user_state = '';
};

export const storeBom = (state, bomId) => {
  state.selected_bom = bomId;
};

export const storeRma = (state, rmaId) => {
  state.selected_rma = rmaId;
};

export const storeJobsTab = (state, tab) => {
  state.selected_jobs_tab = tab;
};

export const storeInventoryTab = (state, tab) => {
  state.selected_inventory_tab = tab;
};

export const storeInventoryTabId = (state, tab) => {
  state.selectedInventoryTabId = tab;
};

export const storeEquipmentTab = (state, tab) => {
  state.selected_equipment_tab = tab;
};

export const storeEquipmentList = (state, list) => {
  state.equipment_list = list;
};

export const storeJobsList = (state, list) => {
  state.jobs_list = list;
};

export const storeConditionsList = (state, list) => {
  state.conditions_list = list;
};

export const storeIsQRCodeScan = (state, data) => {
  state.isQRCodeScan = data;
};

export const openEquipmentMovementForm = (state, type) => {
  state.equipment_movement_form_open = true;
  state.equipment_movement_form_type = type;
};

export const closeEquipmentMovementForm = (state) => {
  state.equipment_movement_form_open = false;
  state.equipment_movement_form_type = null;
};

export const storeUpdateExcelFile = (state, file) => {
  state.excel_file = file;
};

export const storeBusinessRules = (state, rules) => {
  state.business_rules = rules;
};

export const storeActivatedPhysicalCount = (state, data) => {
  state.physicalCountList = data;
};

export const storeAdminModules = (state, data) => {
  state.admin_modules = data;
};

export const storeCountries = (state, data) => {
  state.countries = data;
};

export const storeRegions = (state, data) => {
  state.regions = data;
};

export const storeUserState = (state, data) => {
  state.user_state = data;
  state.search = '';
};

export const storeisInvTagQtyOnHandEqualsToZero = (state, data) => {
  state.isInvTagQtyOnHandEqualsToZero = data;
};

export const storeInvClasses = (state, data) => {
  state.invClasses = data;
};

export const storeInventoryClass = (state, data) => {
  state.inventoryClassId = data;
};

export const storeMovementTab = (state, data) => {
  state.movementTab = data;
};

export const storeClientsState = (state, data) => {
  state.clientsState = data;
};

export const storeSuppliersState = (state, data) => {
  state.suppliersState = data;
};

export const storeProjectsState = (state, data) => {
  state.projectsState = data;
};

export const storeCurrentLevelsState = (state, data) => {
  state.currentLevelsState = data;
};

export const storeInventoryTagsState = (state, data) => {
  state.inventoryTagsState = data;
};

export const storeTransactionsState = (state, data) => {
  state.transactionsState = data;
};

export const storeProjectsScopeState = (state, data) => {
  state.projectsScopeState = data;
};

export const storeJobsState = (state, data) => {
  state.jobsState = data;
};

export const storeInventoryPoState = (state, data) => {
  state.inventoryPoState = data;
};

export const storeMovingBatchState = (state, data) => {
  state.movingBatchState = data;
};

export const storeGlassPoState = (state, data) => {
  state.glassPoState = data;
};

export const storeGlassItemsState = (state, data) => {
  state.glassItemsState = data;
};

export const storeGlassBatchState = (state, data) => {
  state.glassBatchState = data;
};

export const storeSupplyToLinesState = (state, data) => {
  state.supplyToLinesState = data;
};

export const storePartPickListState = (state, data) => {
  state.partPickListState = data;
};

export const storeSupplyToPaintState = (state, data) => {
  state.supplyToPaintState = data;
};

export const storeTruckScheduleRequestState = (state, data) => {
  state.truckScheduleRequestState = data;
};

export const storeTruckScheduleSlotState = (state, data) => {
  state.truckScheduleSlotState = data;
};

export const storeProductionScheduleState = (state, data) => {
  state.productionScheduleState = data;
};

export const storeProductionScheduleCalendarState = (state, data) => {
  state.productionScheduleCalendarState = data;
};

export const storeTimeOffState = (state, data) => {
  state.timeOffState = data;
};

export const storeAlertsState = (state, data) => {
  state.alertsState = data;
};

export const storeSearch = (state, data) => {
  state.search = data;
};

export const storeDarkMode = (state, data) => {
  state.isDarkMode = data;
};

export const storeBomRmaTabs = (state, data) => {
  state.bomRmaTabs = data;
};

export const storeInnerBomRmaTab = (state, data) => {
  state.innerBomRmaTab = data;
};

export default {
  auth_request,
  auth_success,
  logout,
  storeBom,
  storeRma,
  storeJobsTab,
  storeInventoryTab,
  storeEquipmentTab,
  storeEquipmentList,
  storeJobsList,
  storeConditionsList,
  openEquipmentMovementForm,
  closeEquipmentMovementForm,
  storeUpdateExcelFile,
  storeBusinessRules,
  storeActivatedPhysicalCount,
  storeAdminModules,
  storeCountries,
  storeRegions,
  storeUserState,
  storeisInvTagQtyOnHandEqualsToZero,
  storeInvClasses,
  storeMovementTab,
  storeInventoryTabId,
  storeClientsState,
  storeSuppliersState,
  storeProjectsState,
  storeCurrentLevelsState,
  storeInventoryTagsState,
  storeTransactionsState,
  storeProjectsScopeState,
  storeJobsState,
  storeInventoryPoState,
  storeGlassPoState,
  storeMovingBatchState,
  storeGlassItemsState,
  storeGlassBatchState,
  storeSupplyToLinesState,
  storePartPickListState,
  storeSupplyToPaintState,
  storeTruckScheduleRequestState,
  storeTruckScheduleSlotState,
  storeProductionScheduleState,
  storeProductionScheduleCalendarState,
  storeTimeOffState,
  storeAlertsState,
  storeSearch,
  storeDarkMode,
  storeBomRmaTabs,
  storeInnerBomRmaTab,
  storeIsQRCodeScan,
  storeInventoryClass,
};
