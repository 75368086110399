import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;
let cancelRead;
class ProductionScheduleApi extends Api {
  constructor() {
    super('production-schedules', 'production-schedules');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }

  async readWindowBreakdown(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    }, 'read-window-breakdown');
    return res;
  }
}
const productionScheduleApi = new ProductionScheduleApi();

export default productionScheduleApi;
