<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Submit'"
    :max-width="700"
    :warning-text="!isQrCodeScanned ? 'QR Code has to be scanned first before submitting.' : null"
    :submit-btn-disabled="!isQrCodeScanned"
    :btn-loading="loadingBtn"
  >
    <template slot="header">
      <v-row align="center">
        <v-col cols="auto">
          <span>QR Code #{{ item.inventory_tag }} - <span
            v-if="qrCode"
            style="font-weight: normal !important;"
          >{{ qrCode.item.full_name }}</span></span>
        </v-col>
      </v-row>
    </template>
    <template slot="body">
      <v-row v-if="item && qrCode && !loadingQrCode">
        <v-col>
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                v-if="!isQrCodeScanned"
                class="ml-1 mt-1 mb-1"
                color="primary"
                @click="scanQr"
              >
                SCAN QR
              </v-btn>
              <v-btn
                v-else
                class="ml-1 mt-1 mb-1"
                color="success"
                elevation="0"
              >
                QR CODE CONFIRMED
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="qrCode.item && qrCode.item.photo && qrCode.item.mime_type">
            <v-spacer />
            <v-col class="pb-0">
              <v-img
                :src="convertedPhoto(qrCode.item.photo, qrCode.item.mime_type)"
                contain
                height="100%"
                width="100%"
                class="profile-photo"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <!-- <v-row>
            <v-col
              cols="4"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span style="color: rgba(0,0,0, 0.6); font-size: 16px;">Color</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span style="color: #000000; font-size: 16px;">{{ qrCode.color_code }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span style="color: rgba(0,0,0, 0.6); font-size: 16px;">Length</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span style="color: #000000; font-size: 16px;">{{ qrCode.length_name }}</span>
                </v-col>
              </v-row>
            </v-col>

          </v-row> -->
          <v-row>
            <v-col
              cols="3"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0, 0.6)', fontSize: '16px' }">UOM</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px' }">{{ qrCode.uom_name }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="3"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0, 0.6)', fontSize: '16px' }">Location</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px' }">{{ qrCode.location_name }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="3"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0, 0.6)', fontSize: '16px' }">Shelf</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px' }">{{ qrCode.inventory_location_name }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="3"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0, 0.6)', fontSize: '16px' }">PO#</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '16px' }">{{ qrCode.po_number }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0, 0.6)', fontSize: '20px' }">Quantity On Hand</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '22px' }">{{ qrCode.quantity_on_hand }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <v-row>
                <v-col class="pb-0">
                  <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0, 0.6)', fontSize: '20px' }">Quantity Required</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '22px' }">{{ item.quantity_required }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="pb-0">
                  <span :style="{ color: isDarkMode ? '' : 'rgba(0,0,0, 1)', fontSize: '20px' }">Enter Supplied Quantity</span><RedAsterisk />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="pt-2"
                >
                  <v-text-field
                    v-model="quantitySupplied"
                    class="pt-0"
                    :rules="[(v) => !!v && quantitySupplied <= parseInt(qrCode.quantity_on_hand) || 'Supplied Quantity is required and should not exceed quantity on hand']"
                    type="number"
                    filled
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-progress-linear
        v-else
        color="primary"
        indeterminate
      />
      <v-dialog
        v-model="qrCodeDialog"
        fullscreen
        hide-overlay
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col class="text-right">
              <v-btn
                icon
                @click="qrCodeDialog = false"
              >
                <v-icon
                  large
                  dark
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-card-title>
          <v-container fluid>
            <inventory-qr
              :is-part-pick-list="true"
              @return-qr-code="returnQrCode"
            />
          </v-container>
        </v-card>
      </v-dialog>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InventoryQr from '@/views/InventoryQrs/InventoryQr';

export default {
  components: {
    InventoryQr,
  },
  props: {

  },
  data: () => ({
    item: null,
    qrCode: null,
    quantitySupplied: null,
    loadingQrCode: false,
    isQrCodeScanned: false,
    qrCodeDialog: false,
    loadingBtn: false,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async submit() {
      this.loadingBtn = true;
      const res = await this.$api.partPickListItem.update({
        id: this.item.id,
        quantity_supplied: this.quantitySupplied,
      }, 'supply');

      if (!res) {
        this.$bus.$emit('showError');
        this.loadingBtn = false;
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.loadingBtn = false;
      return res;
    },

    open(item) {
      this.$refs.dialogBox.open();
      this.item = item;
      this.isQrCodeScanned = false;
      this.qrCode = null;
      if (item.quantity_supplied) {
        this.quantitySupplied = item.quantity_supplied;
      }
      this.getQrCode(item.inventory_tag);
    },

    async getQrCode(qrCode) {
      this.loadingQrCode = true;
      const readModel = {
        filters:
        {
          inventory_tag: qrCode,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };
      const res = await this.$api.inventoryTag.readData(readModel);

      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loadingQrCode = false;
      if (!res || !res.data || res.data.length === 0) {
        this.qrCode = null;
        return;
      }
      const firstIndex = 0;
      this.qrCode = res.data[firstIndex];
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },

    scanQr() {
      this.qrCodeDialog = true;
    },

    returnQrCode(qrCode) {
      this.qrCodeDialog = false;
      // eslint-disable-next-line eqeqeq
      if (this.item.inventory_tag != qrCode) {
        this.$bus.$emit('showError', 'QR Code does not match.');
        this.isQrCodeScanned = false;
        return;
      }
      this.isQrCodeScanned = true;
    },
  },
};
</script>

<style scoped>
.profile-photo-mini{
  max-width: 80px;
}
.profile-photo{
  max-height: 400px;
  max-width: 400px;
}
</style>
