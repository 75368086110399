var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[(_vm.businessRulesGetBoolean('Inventory Classes'))?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"1"}},[_c('inventory-class-select-element',{attrs:{"is-state":true,"is-inst":false,"is-glass":false,"is-panels":false,"is-sid":false,"is-add-all":false,"is-rail-part":false,"is-railings":false,"is-commercial":false,"is-vwd-glass":false,"is-paint":false,"is-parts":false},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Item","items":_vm.inventoryItems,"item-text":function (item) { return ((item.material_id) + " " + (item.name)); },"item-value":"id","hide-details":"true","clearable":"","loading":_vm.loadingInventoryItem},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.classes ? item.classes.color : 'white'}},[_vm._v(" "+_vm._s(item && item.classes ? item.classes.name : '')+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.material_id))]),_c('span',[_vm._v(_vm._s(': ' + item.name))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.classes ? item.classes.color : 'white'}},[_vm._v(" "+_vm._s(item && item.classes ? item.classes.name : '')+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.material_id))]),_c('span',[_vm._v(_vm._s(': ' + item.name))])]}}]),model:{value:(_vm.filterItemId),callback:function ($$v) {_vm.filterItemId=$$v},expression:"filterItemId"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":"Colour","items":_vm.colors,"item-text":function (item) { return item.code ? item.code : item.name ? item.name: ''; },"item-value":"id","hide-details":"true","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code ? item.code : item.name ? item.name: '')+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code ? item.code : item.name ? item.name: '')+" ")])]}}]),model:{value:(_vm.filterColorId),callback:function ($$v) {_vm.filterColorId=$$v},expression:"filterColorId"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"1"}},[_c('v-autocomplete',{attrs:{"label":"Length","items":_vm.lengths,"item-text":"name","item-value":"id","hide-details":"true","clearable":""},model:{value:(_vm.filterLengthId),callback:function ($$v) {_vm.filterLengthId=$$v},expression:"filterLengthId"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"1"}},[_c('v-autocomplete',{attrs:{"label":"UOM","items":_vm.uoms,"item-text":"name","item-value":"id","hide-details":"true","clearable":""},model:{value:(_vm.filterUomId),callback:function ($$v) {_vm.filterUomId=$$v},expression:"filterUomId"}})],1),(_vm.businessRulesGetBoolean('Inventory Classes'))?_c('v-col',{staticClass:"pb-0 mt-4",attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-0 mb-0",attrs:{"label":"Hide FPGEST Totals"},model:{value:(_vm.isHideFpgestTotals),callback:function ($$v) {_vm.isHideFpgestTotals=$$v},expression:"isHideFpgestTotals"}})],1):_vm._e(),(_vm.businessRulesGetBoolean('Inventory Classes'))?_c('v-col',{staticClass:"pb-0 mt-4",attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-0 mb-0",attrs:{"label":"Hide Zero 'Qty On Hand'"},model:{value:(_vm.isHideZeroOnHand),callback:function ($$v) {_vm.isHideZeroOnHand=$$v},expression:"isHideZeroOnHand"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-1"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"id":"levels-table","headers":_vm.levelsHeaders,"items":_vm.levels,"footer-props":_vm.levelsTableFooterProps,"items-per-page":_vm.levelsPerPage,"search":_vm.search,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.windowHeight},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([_vm._l((_vm.levelsHeaders),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.fullText))])])]}}}),{key:"item.inventory_class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-0 mb-0",attrs:{"small":"","color":item.item && item.item.classes ? item.item.classes.color : 'white',"text-color":"white"}},[_vm._v(" "+_vm._s(item.item && item.item.classes ? item.item.classes.name : '')+" ")])]}},{key:"item.unique_code",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.unique_code ? item.unique_code : '-')+" ")])]}},{key:"item.item_full_name",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.item_full_name ? item.item_full_name : '-')+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.item_category)+" ")])]}},{key:"item.manufacturer_name",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.manufacturer_name ? item.manufacturer_name : '-')+" ")])]}},{key:"item.material_property_name",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.material_property_name ? item.material_property_name : '-')+" ")])]}},{key:"item.length",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.length_name ? item.length_name : '-')+" ")])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.color_code ? item.color_code : '-')+" "+_vm._s(item.color_name ? item.color_name : '-')+" ")])]}},{key:"item.finishing_name",fn:function(ref){
var item = ref.item;
return [_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.finishing_name ? item.finishing_name : '-')+" ")])]}},{key:"item.uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uom_name)+" ")]}},{key:"item.fp_gest_quantity_on_hand",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_total ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item && item.fp_gest_quantity_on_hand ? item.fp_gest_quantity_on_hand.toLocaleString() : '0'))])]}},{key:"item.fp_gest_quantity_committed",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_total ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item && item.fp_gest_quantity_committed ? item.fp_gest_quantity_committed.toLocaleString() : '0'))])]}},{key:"item.fp_gest_quantity_available",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_total ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item && item.fp_gest_quantity_available ? item.fp_gest_quantity_available.toLocaleString() : '0'))])]}},{key:"item.erp_quantity_on_hand",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_total ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item && item.erp_quantity_on_hand ? item.erp_quantity_on_hand.toLocaleString() : '0'))])]}},{key:"item.erp_quantity_available",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_total ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item && item.erp_quantity_available ? item.erp_quantity_available.toLocaleString() : '0'))])]}},{key:"item.variance",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('span',{class:[parseInt(item.on_hand_difference) < 0 ? 'red--text' : '', item.is_total ? 'font-weight-bold' : '']},[_vm._v(_vm._s(item && item.on_hand_difference ? item.on_hand_difference.toLocaleString() : '0'))])]),_c('v-col',{class:[parseInt(item.on_hand_difference) < 0 || item.on_hand_difference_percent === '?' ? 'red--text' : '', 'text-center', item.is_total ? 'font-weight-bold' : '']},[_c('span',[_vm._v(_vm._s(item && item.on_hand_difference_percent ? item.on_hand_difference_percent.toLocaleString() : '0')+_vm._s(item.on_hand_difference_percent == 0 || item.on_hand_difference_percent === '?' ? '' : '%'))])])],1)]}},{key:"item.total_quantity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.total_quantity ? item.total_quantity : ''))])]}},{key:"item.levels",fn:function(ref){
var item = ref.item;
return [(_vm.businessRulesGetBoolean('Inventory Classes'))?_c('v-row',[_c('v-col',[(item.erp_quantity_on_hand < item.danger_level)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"py-1",attrs:{"color":"red","dark":""}},on),[_vm._v(" fas fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v("DANGER: Quantity is lower than "+_vm._s(item.danger_level))])]):(item.erp_quantity_on_hand < item.caution_level)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"py-1",attrs:{"color":"orange","dark":""}},on),[_vm._v(" fas fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v("CAUTION: Quantity is lower than "+_vm._s(item.caution_level))])]):_vm._e()],1)],1):_c('v-row',[_c('v-col',[(item.total_quantity < item.danger_level)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"py-1",attrs:{"color":"red","dark":""}},on),[_vm._v(" fas fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v("DANGER: Quantity is lower than "+_vm._s(item.danger_level))])]):(item.total_quantity < item.caution_level)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"py-1",attrs:{"color":"orange","dark":""}},on),[_vm._v(" fas fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v("CAUTION: Quantity is lower than "+_vm._s(item.caution_level))])]):_vm._e()],1)],1)]}}],null,true)})],1)],1)],1),_c('webhook-inventory-form',{ref:"webhookForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }