<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Truck</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col class="pb-0">
          <v-autocomplete
            v-model="item.truck_type_id"
            :items="truckTypes"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Type is required']"
          >
            <template #item="{ item }">
              {{
                `${item.name ? item.name : ''} (${
                  item.weight_limit
                    ? item.weight_limit + 'kg'
                    : 'Weight Limit Not Available'
                })`
              }}
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              {{
                `${item.name ? item.name : ''} (${
                  item.weight_limit
                    ? item.weight_limit + 'kg'
                    : 'Weight Limit Not Available'
                })`
              }}
            </template>
            <template slot="label">
              Type<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || 'Name is required']"
          >
            <template slot="label">
              Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0"
        >
          <v-text-field
            v-model="item.plate_number"
            :rules="[(v) => !!v || 'Plate number is required']"
          >
            <template slot="label">
              Plate Number<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
          <v-text-field
            v-model="item.truck_number"
            :rules="[(v) => !!v || 'Truck number is required']"
          >
            <template slot="label">
              Truck Number<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-color-picker
            v-model="color"
            hide-canvas
            mode="hexa"
            hide-inputs
            hide-mode-switch
            swatches-max-height="100"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: '',
      truck_type_id: null,
      plate_number: null,
      truck_number: null,
      color: null,
    },
    truckTypes: [],
    color: null,
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleTruck.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getTruckTypes() {
      const submitModel = {
        filters: {
        },
      };
      this.truckTypes = await this.$api.truckScheduleTruckType.read(submitModel);
    },

    async submit() {
      if (!this.item) return false;
      let res;
      this.item.color = this.color.hex;
      if (this.item.id) {
        res = await this.$api.truckScheduleTruck.update(this.item);
      } else {
        res = await this.$api.truckScheduleTruck.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.getTruckTypes();
      this.$refs.dialogBox.open();
    },
  },
};
</script>
