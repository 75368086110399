var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"submit-btn-text":"Create","full-screen":true}},[_c('template',{slot:"header"},[_c('span',[_vm._v("Add To Batch")])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groupedBatches,"hide-default-footer":"","items-per-page":-1,"loading":_vm.loading,"no-data-text":"There are no Batches of this glass type","dense":"","show-select":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{key:item.id,attrs:{"value":item.id},model:{value:(_vm.selectedBatchId),callback:function ($$v) {_vm.selectedBatchId=$$v},expression:"selectedBatchId"}})]}},{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.inventory_class_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.batch_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(item.batch_number ? item.batch_number : '')+" ")])]}},{key:"item.po_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.po_number)+" ")]}},{key:"item.g_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.g_code)+" ")]}},{key:"item.glass_thickness",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.glass_thickness)+" ")]}},{key:"item.total_glass",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_glass)+" ")]}},{key:"item.job_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.job && item.job.code ? item.job.code : '-')+" ")]}},{key:"item.job_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.job && item.job.name ? item.job.name : '-')+" ")]}}],null,true)})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }