var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-end pt-0 pr-1",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdHR))?_c('v-btn',{attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.generateExcel()}}},[_vm._v(" employees report ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Download CSV File")])])],1),_c('v-col',{staticClass:"text-end pt-0",attrs:{"cols":"auto"}},[(_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdHR))?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.open}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" new employee ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"id":"time-off-employee-table","headers":_vm.headers,"items":_vm.items,"footer-props":_vm.itemsTableFooterProps,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"fixed-header":"","height":_vm.windowHeight,"dense":"","server-items-length":_vm.total},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.full_name ? item.full_name : '')+" ")])]}},{key:"item.employee_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.employee_number ? item.employee_number : '')+" ")])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [(item && item.department)?_c('v-chip',{attrs:{"small":"","color":item && item.department && item.department.color ? item.department.color : '',"text-color":"white"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.department && item.department.name ? item.department.name : '')+" ")])],1):_vm._e()]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.start_date ? item.start_date : '')+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.email ? item.email : '')+" ")])]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.phone_number ? item.phone_number : '')+" ")])]}},{key:"item.supervisors",fn:function(ref){
var item = ref.item;
return [(item.supervisors && item.supervisors.length > 1)?_c('v-row',[_c('v-col',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("SUPERVISORS")])]}}],null,true)},_vm._l((item.supervisors),function(p){return _c('span',{key:p.id},[_vm._v(" "+_vm._s(((p.first_name) + " " + (p.last_name)))),_c('br')])}),0)],1)],1):_c('v-row',_vm._l((item.supervisors),function(p,idx){return _c('v-col',{key:p.id,staticClass:"pr-0 pl-1",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(((p.first_name) + " " + (p.last_name)))),(idx !== item.supervisors.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])])}),1)]}},{key:"item.available_day_off",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.available_day_off ? item.available_day_off : '')+" ")])]}},{key:"item.vacation_days_taken",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.vacation_days_taken ? item.vacation_days_taken : '')+" ")])]}},{key:"item.vacation_days_remaining",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.vacation_days_remaining ? item.vacation_days_remaining : '')+" ")])]}},{key:"item.vacation_days_exceeded",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.vacation_days_exceeded ? item.vacation_days_exceeded : '')+" ")])]}},{key:"item.created_at_short",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at_short ? item.created_at_short : '')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"warning","outlined":"","small":""},on:{"click":function($event){return _vm.viewForms(item)}}},[_vm._v(" VIEW FORMS ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEdit(item)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('TimeOffEmployeeForm',{ref:"timeOffEmployeeForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }