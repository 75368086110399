<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="submitBtn"
  >
    <template slot="header">
      <span>{{ title }} Category</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="record.category_title"
            :rules="[(v) => !!v || 'Category Name is required']"
            required
          >
            <template slot="label">
              Category Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-color-picker
            v-model="record.color"
            hide-canvas
            mode="hexa"
            hide-inputs
            hide-mode-switch
            swatches-max-height="100"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="record.category_desc"
            label="Category Description"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    record: null,
    recordId: 0,
    recordDto: {
      id: 0,
      category_title: '',
      color: '',
      category_desc: '',
    },
    submitBtn: '',
    title: '',

  }),
  watch: {
    // eslint-disable-next-line func-names
    'record.color': function (value) {
      if (value.toString().match(/#[a-zA-Z0-9]{8}/)) {
        this.record.color = value.substr(0, 7);
      }
    },
  },
  created() {
    this.record = this.recordDto;
  },
  methods: {

    async get() {
      if (!this.recordId) return;
      this.record = await this.$api.reportCategory.get(this.recordId);
    },

    async submit() {
      if (!this.record) return false;

      let res;
      if (this.recordId !== 0) {
        res = await this.$api.reportCategory.update(this.record);
      } else {
        res = await this.$api.reportCategory.store(this.record);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }
      this.$emit('success');
      return res;
    },

    open(id) {
      this.recordId = id;
      if (id) {
        this.title = 'Update';
        this.submitBtn = 'UPDATE';
        this.get();
      } else {
        this.title = 'Create';
        this.submitBtn = 'CREATE';
        this.record = this.recordDto;
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
