var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('h4',[_vm._v("Glass Codes Max Quantities")])]),_c('v-spacer'),_c('v-col',{staticClass:"text-end mt-4"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openMaxQtyForm()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Max Quantity ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.glassCodesHeaders,"items":_vm.gCodes,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.g_code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-size":"14px !important"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.g_code)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-size":"14px !important"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-size":"14px !important"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at)+" ")])]}},{key:"item.user_full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-size":"14px !important"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user_full_name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEditMaxQtyForm(item.id)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteMaxQty(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('h4',[_vm._v("Glass Codes")])]),_c('v-spacer'),_c('v-col',{staticClass:"text-end mt-4"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openGlassForm()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Glass Code ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.activated",fn:function(ref){
var item = ref.item;
return [(!item.is_activated)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error","dark":""}},on),[_vm._v(" fas fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v("IMPORTANT! Record is missing Details")])]):_vm._e()]}},{key:"item.row",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.row)+" ")])]}},{key:"item.col",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.col)+" ")])]}},{key:"item.part_number_suffix",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.part_number_suffix)+" ")])]}},{key:"item.awd_gas",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.awd_gas)+" ")])]}},{key:"item.awd_spacer",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.awd_spacer)+" ")])]}},{key:"item.cen_heat_treatment",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.cen_heat_treatment)+" ")])]}},{key:"item.center_glass",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.center_glass)+" ")])]}},{key:"item.double_triple",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.double_triple)+" ")])]}},{key:"item.ext_heat_treatment",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.ext_heat_treatment)+" ")])]}},{key:"item.exterior_glass",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.exterior_glass)+" ")])]}},{key:"item.fritted",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.fritted)+" ")])]}},{key:"item.glass_code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.glass_code)+" ")])]}},{key:"item.int_heat_treatment",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.int_heat_treatment)+" ")])]}},{key:"item.interior_glass",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.interior_glass)+" ")])]}},{key:"item.le_surface",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.le_surface)+" ")])]}},{key:"item.middle_glass",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.middle_glass)+" ")])]}},{key:"item.sgl_heat_treatment",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.sgl_heat_treatment)+" ")])]}},{key:"item.single_glass",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.single_glass)+" ")])]}},{key:"item.spandrel_paint",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.spandrel_paint)+" ")])]}},{key:"item.stc",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.stc)+" ")])]}},{key:"item.vwd_code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.vwd_code)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.openGlassEditForm(item.id)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Record")])])],1),_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.deleteGlass(item.id)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" fa-trash ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true)})],1)],1)],1),_c('glass-code-max-quantity-form',{ref:"codeQtyForm",on:{"success":_vm.success}}),_c('glass-code-form',{ref:"glassForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }