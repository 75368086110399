<template>
  <v-container
    fluid
  >
    <v-row v-if="isActivatedVisible">
      <v-col>
        <v-row>
          <v-col
            class="text-center"
          >
            <span style="color:red; font-size: 1.5em">{{ topErrorMessage }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="text-center"
          >
            <v-btn
              color="success"
              x-large
              outlined
              @click="scanNewQrCode"
            >
              Scan new qr
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container
      v-if="isActivated"
      style="font-size: 1.3em !important;"
      class="mt-1"
      fluid
    >
      <v-row>
        <v-col
          class="pa-0"
          cols="1"
          md="1"
          sm="2"
          style="text-align:right"
        >
          <v-row>
            <v-col class="text-center">
              <h5 class="h5">
                QR Code
              </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-chip
                class="mt-1 mb-1"
                text-color="white"
                style="cursor: pointer;"
                :color="inventoryTag && inventoryTag.item.classes ? inventoryTag.item.classes.color : 'white'"
              >
                <span style="font-size: 20px;">{{ inventoryTag && inventoryTag.item.classes ? inventoryTag.item.classes.name : '-' }}</span>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mb-5 text-center">
              <h6 class="mb-3">
                Supplier Label
              </h6>
              <a

                @click="openItemShowImageForm(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
              >
                <v-img
                  :src="convertedPhoto(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
                  class="profile-photo-mini ml-3"
                />
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="`pl-0 pt-0 pb-0 ${inventoryTag.item.photo && inventoryTag.item.mime_type ? '' : 'pr-6'}`"
          cols="auto"
          style=""
        >
          <div
            style="border-left: 1px solid; height: 90%; margin-left:30px;"
          />
        </v-col>
        <v-col
          v-if="inventoryTag.item.photo && inventoryTag.item.mime_type"
          class="pa-0 pr-3 pb-3"
          cols="auto"
          md="3"
          sm="8"
        >
          <v-img
            :src="convertedPhoto(inventoryTag.item.photo, inventoryTag.item.mime_type)"
            contain
            height="100%"
            width="100%"
            class="profile-photo"
          />
        </v-col>
        <v-col
          class="pa-0"
        >
          <v-form ref="editForm">
            <v-row>
              <v-col
                md="2"
                sm="4"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Class
                </h5>
                <inventory-class-select-element
                  v-model="inventoryTag.inventory_class_id"
                  :readonly="true"
                  :is-label="false"
                  :is-required="true"
                  :outlined="true"
                  :is-state="false"
                  :style="'pointer-events: none'"
                />
              </v-col>
              <v-col
                md="6"
                sm="8"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Inventory Item
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.inventory_item_id"
                  :items="inventoryItems"
                  outlined
                  :readonly="isEdit && inventoryTag.inventory_class_id == inventoryClassIdGlass ? false : true"
                  append-icon=""
                  :item-text="
                    (item) => item.material_id + ': ' + item.name
                  "
                  item-value="id"
                  required
                  :rules="[(v) => !!v || 'Inventory Item is required']"
                >
                  <template #item="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item && item.classes ? item.classes.color : 'white'"
                    >
                      {{ item && item.classes ? item.classes.name : '' }}
                    </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                  </template>
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #selection="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item && item.classes ? item.classes.color : 'white'"
                    >
                      {{ item && item.classes ? item.classes.name : '' }}
                    </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="!isUpc && inventoryTag && inventoryTag.inventory_class_id && (inventoryTag.inventory_class_id !== inventoryClassIdGlass && inventoryTag.inventory_class_id !== inventoryClassIdVWDGlass && inventoryTag.inventory_class_id !== inventoryClassIdPanels && inventoryTag.inventory_class_id !== inventoryClassIdSID && inventoryTag.inventory_class_id !== inventoryClassIdPatioDoor && inventoryTag.inventory_class_id !== inventoryClassIdEquipment)"
                md="2"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Length<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.length_id"
                  :items="lengthsList"
                  append-icon=""
                  :readonly="isEdit ? false : true"
                  outlined
                  required
                  item-text="name"
                  :loading="loadingLength"
                  item-value="id"
                  :rules="[(v) => !!v || 'Length is required']"
                />
              </v-col>
              <v-col
                md="2"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  UOM<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.uom_id"
                  :items="uomsList"
                  :readonly="isEdit ? false : true"
                  item-text="name"
                  item-value="id"
                  append-icon=""
                  outlined
                  required
                  :loading="loadingUom"
                  :rules="[(v) => !!v || 'UOM is required']"
                />
              </v-col>
              <v-col
                v-if="!isUpc && inventoryTag && inventoryTag.inventory_class_id && (inventoryTag.inventory_class_id !== inventoryClassIdGlass && inventoryTag.inventory_class_id !== inventoryClassIdVWDGlass && inventoryTag.inventory_class_id !== inventoryClassIdSID && inventoryTag.inventory_class_id !== inventoryClassIdPatioDoor && inventoryTag.inventory_class_id !== inventoryClassIdEquipment)"
                md="4"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Colour<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.color_id"
                  :items="colorsList"
                  :readonly="isEdit ? false : true"
                  :item-text="
                    (item) => item.code
                  "
                  item-value="id"
                  append-icon=""
                  outlined
                  required
                  :rules="[(v) => !!v || 'Colour is required']"
                />
              </v-col>
              <v-col
                md="3"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Location<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.location_id"
                  :items="locationsList"
                  :readonly="isEdit ? false : true"
                  item-text="name"
                  item-value="id"
                  append-icon=""
                  outlined
                  required
                  :rules="[(v) => !!v || 'Location is required']"
                />
              </v-col>
              <v-col
                md="3"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Shelf<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.inventory_location_id"
                  :items="inventoryLocations"
                  :readonly="isEdit ? false : true"
                  item-text="name"
                  item-value="id"
                  append-icon=""
                  outlined

                  :rules="[(v) => !!v || 'Shelf is required']"
                />
              </v-col>
              <v-col
                v-if="!isUpc"
                md="2"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  PO#
                </h5>
                <!-- <v-text-field
                  v-model="inventoryTag.po_number"
                  outlined
                  :readonly="isEdit ? false : true"
                /> -->
                <v-autocomplete
                  v-model="inventoryTag.po_number"
                  outlined
                  append-icon=""
                  :items="inventoryPos"
                  :readonly="isEdit ? false : true"
                  clearable
                />
              </v-col>
              <v-col
                v-if="inventoryTag.inventory_class_id === inventoryClassIdSID"
                md="3"
                sm="2"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Size<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_size_id"
                  :items="doorSizes"
                  item-text="name"
                  :readonly="isEdit ? false : true"
                  append-icon=""
                  item-value="id"
                  outlined
                  :rules="[(v) => !!v || 'Size is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag.inventory_class_id === inventoryClassIdSID && inventoryTag.inventory_item_id === inventoryIdSlab"
                md="3"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Hinge<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_hinge_id"
                  :items="doorHinges"
                  item-text="name"
                  :readonly="isEdit ? false : true"
                  append-icon=""
                  item-value="id"
                  outlined
                  :rules="[(v) => !!v || 'Hinge is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag.inventory_class_id === inventoryClassIdSID && inventoryTag.inventory_item_id === inventoryIdSlab"
                md="3"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Swing<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_swing_id"
                  :items="doorSwings"
                  item-text="name"
                  append-icon=""
                  item-value="id"
                  :readonly="isEdit ? false : true"
                  outlined
                  :rules="[(v) => !!v || 'Swing is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag.inventory_class_id === inventoryClassIdSID && inventoryTag.inventory_item_id === inventoryIdInsert"
                md="3"
                sm="6"
                style=""
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Lite<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_lite_id"
                  :items="doorLites"
                  item-text="name"
                  append-icon=""
                  item-value="id"
                  :readonly="isEdit ? false : true"
                  outlined
                  :rules="[(v) => !!v || 'Lite is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag && (inventoryTag.inventory_class_id === inventoryClassIdGlass || inventoryTag.inventory_class_id === inventoryClassIdVWDGlass)"
                md="2"
                sm="6"
                class="text-center pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Ven. Batch#<RedAsterisk v-if="isEdit" />
                </h5>
                <v-text-field
                  v-model="inventoryTag.batch_number"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Vendor Batch# is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag && (inventoryTag.inventory_class_id === inventoryClassIdGlass || inventoryTag.inventory_class_id === inventoryClassIdVWDGlass)"
                md="2"
                sm="6"
                class="text-center pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Prod. Date<RedAsterisk v-if="isEdit" />
                </h5>
                <v-menu
                  ref="menu"
                  v-model="dateMenu"
                  :return-value.sync="inventoryTag.production_date"
                  transition="scale-transition"
                  offset-y
                  left
                  :disabled="isEdit ? false : true"
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="inventoryTag.production_date"
                      outlined
                      :readonly="isEdit ? false : true"
                      v-bind="attrs"
                      :rules="[(v) => !!v || ' Production Date is required']"
                      v-on="on"
                      @change="$refs.menu.save(inventoryTag.production_date)"
                    />
                  </template>
                  <v-date-picker
                    v-model="inventoryTag.production_date"
                    no-title
                    scrollable
                    :readonly="isEdit ? false : true"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="inventoryTag.production_date = null"
                    >
                      Clear
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                v-if="inventoryTag && inventoryTag.inventory_class_id !== inventoryClassIdPatioDoor && inventoryTag.inventory_class_id !== inventoryClassIdEquipment"
                md="6"
                sm="12"
                class="pt-0 pr-1 pl-1 pb-0"
                style=""
              >
                <h5 class="h5">
                  Project
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.project_id"
                  :items="projects"
                  item-text="name"
                  append-icon=""
                  item-value="id"
                  :readonly="isEdit ? false : true"
                  outlined
                />
              </v-col>
              <v-col
                v-if="inventoryTag.inventory_class_id === inventoryClassIdRailings || inventoryTag.inventory_class_id === inventoryClassIdRailParts || inventoryTag.inventory_class_id === inventoryClassIdPanels"
                md="3"
                sm="6"
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Paint On
                </h5>
                <v-select
                  v-model="inventoryTag.paint_on_option_id"
                  :items="paintOnOptions"
                  item-text="name"
                  item-value="id"
                  :clearable="true"
                  :readonly="isEdit ? false : true"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row
              v-if="inventoryTag.inventory_class_id === inventoryClassIdSID"
            >
              <v-col
                class="pa-4 mb-2"
                cols="11"
                style="background-color: #E8F5E9; border: 2px solid rgba(0,0,0,0.2)"
              >
                <v-row>
                  <v-col
                    v-if="inventoryTag.inventory_class_id === inventoryClassIdSID"
                    class="pt-0 pr-6"
                    cols="auto"
                  >
                    <v-radio-group
                      v-model="inventoryTag.is_painted"
                      hide-details
                      class="mt-0"
                      :readonly="isEdit ? false : true"
                    >
                      <template #label>
                        <span style="font-size: 17.264px; color: #000; font-weight: bold;">Paint<RedAsterisk v-if="isEdit" /></span>
                      </template>
                      <v-row>
                        <v-col cols="auto">
                          <v-radio
                            label="PAINTED"
                            :value="1"
                          />
                        </v-col>
                        <v-col cols="auto">
                          <v-radio
                            label="NON-PAINTED"
                            :value="0"
                          />
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    v-if="inventoryTag.inventory_class_id === inventoryClassIdSID && inventoryTag.is_painted == 1"
                    md="3"
                    sm="6"
                    class="pt-0 pr-1 pl-1 pb-0"
                    style=""
                  >
                    <h5 class="h5">
                      {{ inventoryTag.inventory_item_id !== inventoryIdSweep ? 'Interior Color' : 'Color' }}<RedAsterisk v-if="isEdit" />
                    </h5>
                    <v-autocomplete
                      v-model="inventoryTag.color_id"
                      :items="colorsList"
                      item-text="code"
                      append-icon=""
                      item-value="id"
                      outlined
                      :readonly="isEdit ? false : true"
                      :rules="[(v) => !!v || `${inventoryTag.inventory_item_id !== inventoryIdSweep ? 'Interior Color' : 'Color'} is required`]"
                    >
                      <template #selection="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <template #item="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="inventoryTag.inventory_class_id === inventoryClassIdSID && inventoryTag.is_painted == 1 && inventoryTag.inventory_item_id !== inventoryIdSweep"
                    md="3"
                    sm="6"
                    class="pt-0 pr-1 pl-1 pb-0"
                    style=""
                  >
                    <h5 class="h5">
                      Exterior Color<RedAsterisk v-if="isEdit" />
                    </h5>
                    <v-autocomplete
                      v-model="inventoryTag.ex_color_id"
                      :items="colorsList"
                      item-text="code"
                      append-icon=""
                      item-value="id"
                      :readonly="isEdit ? false : true"
                      outlined
                      :rules="[(v) => !!v || 'Exterior Color is required']"
                    >
                      <template #selection="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <template #item="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              v-if="inventoryTag && (inventoryTag.inventory_class_id === inventoryClassIdPatioDoor)"
            >
              <v-col
                md="2"
                sm="6"
                class="text-center pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Door Height<RedAsterisk v-if="isEdit" />
                </h5>
                <v-text-field
                  v-model="inventoryTag.height"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Door height is required']"
                />
              </v-col>
              <v-col
                md="2"
                sm="6"
                class="text-center pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Door Width<RedAsterisk v-if="isEdit" />
                </h5>
                <v-text-field
                  v-model="inventoryTag.width"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Door width is required']"
                />
              </v-col>
              <v-col
                md="2"
                sm="6"
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Interior Color<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.color_id"
                  :items="colorsList"
                  item-text="code"
                  append-icon=""
                  item-value="id"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Interior Color is required']"
                >
                  <template #selection="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                md="2"
                sm="6"
                class="pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Exterior Color<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.ex_color_id"
                  :items="colorsList"
                  item-text="code"
                  append-icon=""
                  item-value="id"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Exterior Color is required']"
                >
                  <template #selection="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- <v-col
                md="2"
                sm="6"
                class="text-center pt-0 pr-1 pl-1 pb-0"
              >
                <h5 class="h5">
                  Glass
                </h5>
                  <v-autocomplete
                    v-model="inventoryTag.glass"
                    :items="glassCodes"
                    :loading="loadingGlassCodes"
                    outlined
                    auto-select-first
                    item-text="row"
                    item-value="row"
                    clearable
                    :readonly="isEdit ? false : true"
                    @change="(g_code) => getGlass(g_code)"
                  >
                    <template slot="append-outer">
                      <v-icon
                        v-if="inventoryTag.glass !== null"
                        @click="openGlassPoItemForm(inventoryTag.glass)"
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                  </v-autocomplete>
              </v-col> -->
            </v-row>
            <v-row
              v-if="inventoryTag && (inventoryTag.inventory_class_id === inventoryClassIdPatioDoor)"
            >
              <v-col
                class="pt-0 pr-6"
                cols="auto"
              >
                <v-radio-group
                  v-model="inventoryTag.door_screen_id"
                  hide-details
                  class="mt-0"
                  :readonly="isEdit ? false : true"
                >
                  <template #label>
                    <span style="font-size: 17.264px; color: #000; font-weight: bold;">Screen<RedAsterisk v-if="isEdit" /></span>
                  </template>
                  <v-row>
                    <v-col cols="auto">
                      <v-radio
                        label="WITH SCREEN"
                        :value="1"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-radio
                        label="WITHOUT SCREEN"
                        :value="2"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col
                class="pt-0 pr-6"
                cols="auto"
              >
                <v-radio-group
                  v-model="inventoryTag.is_fritted"
                  hide-details
                  class="mt-0"
                  :readonly="isEdit ? false : true"
                >
                  <template #label>
                    <span style="font-size: 17.264px; color: #000; font-weight: bold;">Fritted<RedAsterisk v-if="isEdit" /></span>
                    <!-- <span style="font-size: .8em; color: rgba(0, 0, 0, 0.65);" v-if="isEdit && !!inventoryTag.glass"> (auto selected from Glass)</span> -->
                  </template>
                  <v-row>
                    <v-col cols="auto">
                      <v-radio
                        label="FRITTED"
                        :value="1"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-radio
                        label="NON-FRITTED"
                        :value="0"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pt-0 pr-1 pl-1 pb-0"
                md="2"
                sm="3"
              >
                <h5 class="h5">
                  Received Qty
                </h5>
                <v-text-field
                  v-model="inventoryTag.quantity"
                  outlined
                  :readonly="true"
                />
              </v-col>
              <v-col
                class="pt-0 pr-1 pl-1 pb-0"
                style=""
                md="2"
                sm="4"
              >
                <h5 class="h5">
                  Qty on Hand
                </h5>
                <!-- inventoryTag.inventory_class_id -->
                <v-text-field
                  v-model="inventoryTag.quantity_on_hand"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[inventoryTag.inventory_class_id === inventoryClassIdPaint ? rules.wholeAndHalfNumber : rules.wholeNumber]"
                  type="number"
                  :step="inventoryTag.inventory_class_id === inventoryClassIdPaint ? '0.5' : '1'"
                  min="0"
                />
              </v-col>
              <v-col
                v-if="isEdit && !isUpc"
                md="2"
                sm="4"
                class="mr-3 pt-0 pr-1 pl-1 pb-0"
                style=""
              >
                <h5 class="h5">
                  Supplier Label
                </h5>
                <v-file-input
                  v-model="inventoryTag.supplierLabel"
                  accept="image/*"
                  capture="user"
                  outlined
                  label="Supplier Label"
                  item-text="name"
                  item-value="id"
                  prepend-icon=""
                >
                  <template slot="label">
                    Supplier Label
                  </template>
                </v-file-input>
              </v-col>
              <v-col
                v-if="!isEdit"
                style=""
                class="mb-5 pt-0 pr-1 pl-1 pb-0"
                md="2"
                sm="4"
              >
                <h5 class="h5">
                  Qty Available
                </h5>
                <v-text-field
                  v-model="inventoryTag.quantity_available"
                  outlined
                  :readonly="true"
                  hide-details
                />
              </v-col>
              <v-spacer />
              <v-col
                v-if="!isEdit"
                cols="auto"
                class="pt-7 pr-6"
              >
                <v-btn
                  color="primary"
                  outlined
                  x-large
                  @click="toggleEdit"
                >
                  edit
                </v-btn>
              </v-col>
              <v-col
                v-if="isEdit"
                cols="auto"
                class="pt-7"
              >
                <v-btn
                  color="primary"
                  x-large
                  class="mr-2 elevation-0"
                  @click="submit"
                >
                  submit
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  x-large
                  @click="redirectBack"
                >
                  cancel
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-1" />
            </v-row>
            <v-divider class="pb-3" />
            <v-row align="center">
              <v-col
                v-if="inventoryTag.last_note"
                cols="4"
                class="pl-1 pr-1"
              >
                <h5 class="h5">
                  Notes
                </h5>
                <v-textarea
                  v-model="inventoryTag.last_note"
                  outlined
                  rows="1"
                  readonly
                  hide-details
                />
              </v-col>
              <v-col
                cols="auto"
                class="pt-9 pl-1 pr-1"
              >
                <v-btn
                  color="orange"
                  outlined
                  x-large
                  @click="openNotes"
                >
                  Notes
                </v-btn>
              </v-col>
              <v-col
                cols="auto"
                class="pt-9 pl-1 pr-1"
              >
                <v-btn
                  color="purple"
                  x-large
                  outlined
                  @click="historyRedirect"
                >
                  History
                </v-btn>
              </v-col>
              <v-col
                cols="auto"
                class="pt-9 pl-1 pr-1"
              >
                <v-btn
                  color="success"
                  x-large
                  outlined
                  @click="scanNewQrCode"
                >
                  Scan new qr
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-row
      v-if="errorMessage != null"
      class="mt-5"
    >
      <v-col
        class="pa-0"
        style=""
      >
        <span style="color:red; font-size: 1.5em">{{ errorMessage }}</span>
      </v-col>
    </v-row>
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
    <inventory-tag-note-form
      ref="noteForm"
      @success="getInventoryTag"
    />
    <!-- <glass-po-item-form
      v-if="!loadingGlassCodes"
      ref="glassPoItemForm"
      :disabled="true"
    /> -->
  </v-container>
</template>

<script>
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import { mapState } from 'vuex';
import InventoryTagNoteForm from '@/components/forms/inventoryTags/InventoryTagNoteForm';
// import GlassPoItemForm from '@/components/forms/glasses/GlassPOItemForm';
import * as ValidationHelpers from '@/helpers/ValidationHelpers';

export default {
  components: {
    InventoryItemShowImageForm,
    InventoryTagNoteForm,
    // GlassPoItemForm,
  },
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    lengthsList: [],
    uomsList: [],
    colorsList: [],
    inventoryItemsList: [],
    inventoryItems: [],
    inventoryLocations: [],
    locationsList: [],
    isActivated: false,
    isActivatedVisible: false,
    selectedTag: '',
    valid: false,
    activeQrBtn: 'Activate Qr Code',
    errorMessage: null,
    addNewInventoryTag: false,
    isQrEqualsTag: false,
    isEdit: false,
    inventoryClassId: null,
    isUpc: false,
    dateMenu: false,

    inventoryClassIdRailings: 3,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdRailParts: 10,
    inventoryClassIdPaint: 12,
    inventoryClassIdEquipment: 13,
    inventoryClassIdPatioDoor: 14,
    inventoryIdSlab: 1100,
    inventoryIdSweep: 1101,
    inventoryIdInsert: 1102,
    glassCodeTypeIdSealedUnit: 2,

    doorHinges: [],
    doorSwings: [],
    doorLites: [],
    doorSizes: [],
    projects: [],
    inventoryPos: [],
    paintOnOptions: [],
    // glassCodes: [],

    topErrorMessage: 'THIS QR CODE HAS NOT BEEN ACTIVATED YET. PLEASE ACTIVATE IT FIRST.',
    loadingLength: true,
    loadingUom: false,
    // loadingGlassCodes: false,

    rules: {
      wholeNumber: (v) => /^\d+$/.test(v) || 'Please enter a whole number',
      wholeAndHalfNumber: (v) => /^(0?(\.5)?|[1-9]\d*(\.0|\.5)?)$/.test(v) || 'Enter a whole number or a number ending in .5'
    }
  }),
  computed: {
    ...mapState({
      physicalCountList: 'physicalCountList',
      user: 'user',
      isDarkMode: 'isDarkMode',
    }),
  },
  watch: {
    inventoryTag(value) {
      if (!value) return;
      if (value.item.class_names) {
        this.inventoryClasses = value.item.class_names;
      }
    },
    // eslint-disable-next-line func-names
    'inventoryTag.inventory_class_id': function (val) {
      if (this.inventoryTag && this.inventoryTag.inventory_class_id !== val) {
        this.inventoryTag.inventory_item_id = null;
      }
      this.inventoryClassId = val;
      this.getInventoryItems();
    },
    // eslint-disable-next-line func-names
    'inventoryTag.inventory_item_id': function (val) {
      if (val === this.inventoryIdSlab || val === this.inventoryIdSweep || val === this.inventoryIdInsert) {
        this.getDoorSizes();
      }

      if (val === this.inventoryIdSlab) {
        this.inventoryTag.door_lite_id = null;
      } else if (val === this.inventoryIdSweep) {
        this.inventoryTag.door_hinge_id = null;
        this.inventoryTag.door_swing_id = null;
        this.inventoryTag.door_lite_id = null;
        this.inventoryTag.ex_color_id = null;
      } else if (val === this.inventoryIdInsert) {
        this.inventoryTag.door_hinge_id = null;
        this.inventoryTag.door_swing_id = null;
      }

      this.getUoms();
      this.getLengths();
      this.getInventoryPos();
    },
    // eslint-disable-next-line func-names
    'inventoryTag.is_painted': function (val) {
      if (val === 0) {
        this.inventoryTag.color_id = null;
        this.inventoryTag.ex_color_id = null;
      }
    },
    // eslint-disable-next-line func-names
    'inventoryTag.location_id': function (value) {
      if (!value) return;
      this.getInventoryLocations();
    },
    // eslint-disable-next-line func-names
    'inventoryTag.production_date': function (val) {
      if (this.$refs.menu) { this.$refs.menu.save(val); }
    },
  },

  created() {
    this.qrCode = this.$route.params.code;
    if (parseInt(this.qrCode, 10) > 99999 && parseInt(this.qrCode, 10) < 199999) {
      this.isUpc = true;
    } else {
      this.isUpc = false;
    }
    this.init();
  },

  methods: {
    async init() {
      this.getUoms();
      this.getColors();
      this.getLengths();
      this.getInventoryItems();
      this.getLocations();
      this.getInventoryLocations();
      this.getInventoryTag();
      this.getDoorHinges();
      this.getDoorSwings();
      this.getDoorLites();
      this.getProjects();
      this.getInventoryPos();
      this.getPaintOnOptions();
      // this.getGlassCodes();
    },

    // QR CODE IS THE SAME AS INVENTORY TAG
    async getInventoryTag() {
      if (!this.qrCode) return;
      // QR Codes that used for UPCs are from 100000 tp 199999
      if (parseInt(this.qrCode, 10) > 99999 && parseInt(this.qrCode, 10) < 199999) {
        // Include records with qty on hand 0 for INSTALATION Class records
      }

      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
          is_qty_on_hand_o: 0,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);

      if (res && res.status === 505) {
        this.topErrorMessage = res.message;
        this.isActivatedVisible = true;
        return;
      }

      if (!res || !res.data || !res.data[0]) {
        this.isActivatedVisible = true;
        return;
      }

      const data = res.data[0];
      this.isActivatedVisible = false;
      this.inventoryTag = data;
      this.inventoryClassId = this.inventoryTag.item.inventory_class_id;
      this.isActivated = true;
      this.getDoorSizes();
    },

    async getUoms() {
      this.loadingUom = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.inventoryTag ? this.inventoryTag.inventory_item_id : null,
        },
      };
      this.uomsList = await this.$api.uom.read(submitData);
      this.loadingUom = false;
    },
    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colorsList = await this.$api.color.read(submitModel);
    },
    async getLengths() {
      this.loadingLength = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.inventoryTag ? this.inventoryTag.inventory_item_id : null,
          inventory_class_id: this.inventoryClassId,
        },
      };
      this.lengthsList = await this.$api.length.read(submitData);
      this.loadingLength = false;
    },
    async getInventoryItems() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },

    async getInventoryLocations() {
      this.$nextTick(async () => {
        const locationId = this.inventoryTag && this.inventoryTag.location_id ? this.inventoryTag.location_id : 1;
        const readModel = {
          filters:
        {
          location_id: locationId,
        },
        };
        this.inventoryLocations = await this.$api.inventoryLocation.read(readModel);
      });
    },

    async getLocations() {
      this.locationsList = await this.$api.location.getList();
    },

    async getDoorHinges() {
      this.doorHinges = await this.$api.doorHinge.getList();
    },

    async getDoorSwings() {
      this.doorSwings = await this.$api.doorSwing.getList();
    },

    async getDoorLites() {
      this.doorLites = await this.$api.doorLite.getList();
    },

    async getDoorSizes() {
      this.doorSizes = await this.$api.doorSize.get(`${this.inventoryTag.inventory_item_id}`);
    },

    async getProjects() {
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        return;
      }
      this.projects = res.data;
    },

    async getInventoryPos() {
      const submitModel = {
        filters: {
          // inventory_class_id: this.inventoryClassId,
        },
      };
      const res = await this.$api.inventoryPo.read(submitModel, null, 'read-simple');
      if (!res) {
        return;
      }
      this.inventoryPos = ['Not Applicable', ...res];
    },

    async getPaintOnOptions() {
      this.paintOnOptions = await this.$api.paintOnOption.get();
    },

    async submit() {
      if (!await this.$refs.editForm.validate()) return;
      if (this.inventoryClassId === this.inventoryClassIdPatioDoor && (!this.isDecimalInFractions(this.inventoryTag.width) || !this.isDecimalInFractions(this.inventoryTag.height))) {
        this.$bus.$emit('showError', 'Incorrect Width or Height value');
        return;
      }
      this.$bus.$emit('loading');
      const data = new FormData();

      // QR CODE IS THE SAME AS INVENTORY TAG
      if (this.qrCode) {
        data.append('inventory_tag', this.qrCode);
      }

      if (this.inventoryTag.inventory_item_id) {
        data.append('inventory_item_id', this.inventoryTag.inventory_item_id);
      }

      if (this.inventoryTag.uom_id) {
        data.append('uom_id', this.inventoryTag.uom_id);
      }

      if (this.inventoryTag.length_id) {
        data.append('length_id', this.inventoryTag.length_id);
      }

      if (this.inventoryTag.color_id) {
        data.append('color_id', this.inventoryTag.color_id);
      }

      if (this.inventoryTag.inventory_location_id) {
        data.append('inventory_location_id', this.inventoryTag.inventory_location_id);
      }

      if (this.inventoryTag.quantity_on_hand) {
        data.append('quantity_on_hand', this.inventoryTag.quantity_on_hand);
      }

      if (this.inventoryTag.po_number) {
        data.append('po_number', this.inventoryTag.po_number);
      }

      if (this.user.id) {
        data.append('updated_by', this.user.id);
      }

      if (this.inventoryTag.location_id) {
        data.append('location_id', this.inventoryTag.location_id);
      }

      if (this.inventoryTag.batch_number) {
        data.append('batch_number', this.inventoryTag.batch_number);
      }

      if (this.inventoryTag.production_date) {
        data.append('production_date', this.inventoryTag.production_date);
      }

      if (this.inventoryTag.supplierLabel) {
        data.append('supplier_label', this.inventoryTag.supplierLabel);
      }

      if (this.inventoryTag.door_hinge_id) {
        data.append('door_hinge_id', this.inventoryTag.door_hinge_id);
      }

      if (this.inventoryTag.door_swing_id) {
        data.append('door_swing_id', this.inventoryTag.door_swing_id);
      }

      if (this.inventoryTag.door_lite_id) {
        data.append('door_lite_id', this.inventoryTag.door_lite_id);
      }

      if (this.inventoryTag.door_size_id) {
        data.append('door_size_id', this.inventoryTag.door_size_id);
      }

      if (this.inventoryTag.is_painted) { data.append('is_painted', this.inventoryTag.is_painted); }

      if (this.inventoryTag.ex_color_id) {
        data.append('ex_color_id', this.inventoryTag.ex_color_id);
      }

      if (this.inventoryTag.project_id) {
        data.append('project_id', this.inventoryTag.project_id);
      }

      if (this.inventoryTag.height) {
        data.append('height', this.inventoryTag.height);
      }

      if (this.inventoryTag.width) {
        data.append('width', this.inventoryTag.width);
      }

      if (this.inventoryTag.door_screen_id) {
        data.append('door_screen_id', this.inventoryTag.door_screen_id);
      }

      if (this.inventoryTag.is_fritted !== undefined && this.inventoryTag.is_fritted !== null) {
        data.append('is_fritted', this.inventoryTag.is_fritted);
      }

      if (this.inventoryTag.paint_on_option_id) {
        data.append('paint_on_option_id', this.inventoryTag.paint_on_option_id);
      }

      // if (this.inventoryTag.glass) {
      //   data.append('glass', this.inventoryTag.glass);
      // }

      const res = await this.$api.inventoryTag.updateTag(data);
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading-stop');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.$bus.$emit('loading-stop');
      this.isEdit = false;
      this.getInventoryTag();
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },
    scanNewQrCode() {
      this.$router.push('/inventory-qr');
    },
    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    redirectBack() {
      this.getInventoryTag();
      this.toggleEdit();
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },

    historyRedirect() {
      const transactionsTabIndex = 2;
      const transactionsTabId = 3;
      this.$store.commit('storeInventoryTab', transactionsTabIndex);
      this.$store.commit('storeInventoryTabId', transactionsTabId);
      this.$router.push({
        name: 'Inventory',
        params: {
          searchParam: `qr=${this.qrCode}`,
          inventoryClassIdProp: this.inventoryClassId,
        },
      });
    },

    openNotes() {
      this.$refs.noteForm.open(this.qrCode);
    },

    isDecimalInFractions(decimalNumber) {
      return ValidationHelpers.isDecimalInFractions(decimalNumber);
    },

    // archived functions for setting Glass
    // async openGlassPoItemForm(g_code) {
    //   const glass = {
    //     glass: {},
    //     g_code,
    //     glass_thickness: '1 INCH OVERALL',
    //     glass_type: 'SEALED UNIT'
    //   };
    //   this.$refs.glassPoItemForm.open(glass, null, true);
    // },

    // async getGlass(gCode) {
    //   this.$bus.$emit('loading');
    //   const submitModel = {
    //     filters: {
    //       g_code: gCode,
    //     },
    //   };
    //   const res = await this.$api.glass.read(submitModel);
    //   if (!res || res.length < 1 || !res[0]) {
    //     this.$bus.$emit('loading-stop');
    //     return;
    //   }
    //   const firstIndex = 0;
    //   const glass = res[firstIndex];
    //   this.inventoryTag.is_fritted = glass && glass.fritted ? 1 : 0;

    //   this.$bus.$emit('loading-stop');
    // },

    // async getGlassCodes() {
    //   this.loadingGlassCodes = true;
    //   const submitModel = {
    //     filters: {
    //       glass_type_id: this.glassCodeTypeIdSealedUnit,
    //       is_patio_door: true,
    //     },
    //     sort: {
    //       direction_sort: true,
    //     }
    //   };
    //   const res = await this.$api.glass.readGlassPaintCode(submitModel);
    //   const cancelCode = 3001;
    //   if (res === cancelCode) {
    //     this.loadingGlassCodes = false;
    //     return
    //   };
    //   if (!res) {
    //     this.loadingGlassCodes = false;
    //     return
    //   };
    //   this.glassCodes = res;
    //   this.loadingGlassCodes = false;
    // },
  },
};
</script>
<style scoped>
.profile-photo-mini{
  max-width: 80px;

}
.profile-photo{

  max-height: 400px;
  max-width: 400px;

}
.v-text-field >>> input {
    font-size: 1.5em !important;
}

.v-autocomplete >>> input{
    font-size: 1.5em !important;
}

.v-btn {
    font-size: 1.1em !important;
}

::v-deep .v-input--radio-group--column .v-input--radio-group__input > .v-label {
    padding-bottom: 17px;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details
{
  margin-bottom: 0px;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details[data-v-c940035e] {

}
</style>
