var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-end pr-6",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.jobs,"item-text":"name","item-value":"id","hide-details":"","placeholder":"Filter by Job","clearable":true},on:{"change":_vm.fetchBoms},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v("JOB #"+_vm._s(item.id)+" ("+_vm._s(item.address_simple)+")")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v("JOB #"+_vm._s(item.id)+" ("+_vm._s(item.address_simple)+")")])]}}]),model:{value:(_vm.jobFilter),callback:function ($$v) {_vm.jobFilter=$$v},expression:"jobFilter"}}),(_vm.canEdit)?_c('v-btn',{staticClass:"d-flex align-self-end ml-4",attrs:{"color":"primary","outlined":""},on:{"click":function () {
            _vm.$refs.bomForm.openDialog(_vm.type);
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" new "+_vm._s(_vm.type)+" ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bomsHeaders,"items":_vm.boms,"footer-props":_vm.itemsTableFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code))])]}},{key:"item.worker",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.worker != ' ' ? item.worker : '-')+" ")])]}},{key:"item.job_address",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.job_address)+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.status_id === 1 ? 'light-blue' : 'green',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.status === 'Released')?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"success","outlined":"","small":""},on:{"click":function($event){return _vm.showBom(item)}}},[_vm._v(" View ")]):[(_vm.canEdit)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.showBom(item)}}},[_vm._v(" EDIT ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteBom(item)}}},[_vm._v(" DELETE ")])]]}}],null,true)})],1)],1),_c('BomForm',{ref:"bomForm",on:{"bom-creation":_vm.notifyBomCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }