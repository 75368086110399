<template>
  <v-container
    fluid
    class="px-4 pt-0"
  >
    <v-row class="py-3">
      <v-col
        cols="auto"
        class="pb-0"
      >
        <v-tabs
          v-model="selectedTab"
          optional
          @change="changeTab"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.id"
            @click="changeTabId(tab.id)"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer />
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-row fill-height>
          <v-col class="d-flex justify-end align-end">
            <v-btn
              v-if="tabId === 1"
              class="mr-2"
              color="primary"
              outlined
              @click="generatePdfErp"
            >
              pdf erp
            </v-btn>
            <v-btn
              v-if="tabId === 1"
              class="mr-2"
              color="primary"
              outlined
              @click="generateExcelErp"
            >
              excel erp
            </v-btn>
            <v-btn
              v-if="inventoryClassId != inventoryClassIdPaint"
              class="mr-2"
              color="primary"
              outlined
              @click="generatePdf"
            >
              pdf
            </v-btn>
            <v-btn
              v-if="tabId != 4 && inventoryClassId != inventoryClassIdPaint"
              color="primary"
              outlined
              @click="generateExcel"
            >
              excel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <CurrentLevels
      v-if="tabId === 1"
      ref="currentLevels"
    />
    <InventoryTags
      v-if="tabId === 2"
      ref="inventory"
      @toggle-inventory-class="toggleInventoryClass"
    />
    <Transactions
      v-if="tabId === 3"
      ref="transactions"
      :inventory-class-id-prop="inventoryClassIdProp"
    />
    <MPQRSummary
      v-if="tabId === 4"
      ref="mpqrSummary"
    />
  </v-container>
</template>

<script>
import Transactions from '@/components/Inventory/Transactions';
import CurrentLevels from '@/components/Inventory/CurrentLevels';
import InventoryTags from '@/components/Inventory/InventoryTags';
import MPQRSummary from '@/components/Inventory/MPQRSummary';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    Transactions,
    CurrentLevels,
    InventoryTags,
    MPQRSummary,
  },
  props: {
    searchParam: {
      type: String,
      default: '',
    },
    inventoryClassIdProp: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    selectedTab: 1,
    isInventoryTags: 0,
    inventoryClassId: null,
    inventoryClassIdPaint: 12,
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),

    tabs() {
      const tabs = [];
      tabs.push({ name: 'Current Levels', id: 1 });
      if (this.businessRulesGetBoolean('Inventory tags')) tabs.push({ name: 'Inventory Tags', id: 2 });
      tabs.push({ name: 'Transactions', id: 3 });
      tabs.push({ name: 'MPQR Summary', id: 4 });
      return tabs;
    },
    ...mapState({
      tabId: (state) => state.selectedInventoryTabId,
      selectedInventoryTab: (state) => state.selected_inventory_tab,
      search: (state) => state.search,
    }),
  },
  watch: {
    search: {
      handler() {
        this.updateSearch();
      },
    },
  },
  created() {
    if (this.tabId === null || this.tabId === 0) {
      this.changeTabId(2);
    }
    if (this.searchParam) {
      this.$store.commit('storeSearch', this.searchParam);
    }
    this.initialize();
  },
  methods: {
    async initialize() {
      const selectedTab = this.selectedInventoryTab;
      if (selectedTab !== null) {
        this.selectedTab = selectedTab;
      } else {
        this.selectedTab = 1;
      }
    },
    changeTab() {
      // Keep current tab in local storage
      this.$store.commit('storeInventoryTab', this.selectedTab);
    },

    changeTabId(tabId) {
      // WorkAround to get selected tab id
      this.$store.commit('storeInventoryTabId', tabId);
    },

    generatePdfErp() {
      if (this.tabId === 1) {
        const subModuleIdERP = 107;
        if (this.businessRulesGetBoolean('Webhooks')) { this.$refs.currentLevels.openWebhookFormPdf(subModuleIdERP); } else this.$refs.currentLevels.generatePdf();
      }
    },

    generateExcelErp() {
      if (this.tabId === 1) {
        const subModuleIdERP = 107;
        if (this.businessRulesGetBoolean('Webhooks')) { this.$refs.currentLevels.openWebhookFormExcel(subModuleIdERP); } else this.$refs.currentLevels.generatePdf();
      }
    },

    generatePdf() {
      if (this.tabId === 1) {
        if (this.businessRulesGetBoolean('Webhooks')) { this.$refs.currentLevels.openWebhookFormPdf(); } else this.$refs.currentLevels.generatePdf();
      } else if (this.tabId === 2) {
        this.$refs.inventory.generatePdf();
      } else if (this.tabId === 3) {
        this.$refs.transactions.generatePdf();
      } else if (this.tabId === 4) {
        this.$refs.mpqrSummary.generatePdf();
      }
    },
    generateExcel() {
      if (this.tabId === 1) {
        if (this.businessRulesGetBoolean('Webhooks')) { this.$refs.currentLevels.openWebhookFormExcel(); } else this.$refs.currentLevels.generateExcel();
      } else if (this.tabId === 2) {
        this.$refs.inventory.generateExcel();
      } else if (this.tabId === 3) {
        this.$refs.transactions.generateExcel();
      }
    },
    updateSearch() {
      if (this.tabId === 1) {
        this.$refs.currentLevels.updateSearch(this.search);
      } else if (this.tabId === 2) {
        this.$refs.inventory.updateSearch(this.search);
      } else if (this.tabId === 3) {
        this.$refs.transactions.updateSearch(this.search);
      }
    },

    toggleInventoryClass(inventoryClassId) {
      this.inventoryClassId = inventoryClassId;
    },

  },
};
</script>

<style lang="scss" scoped>
.date-filter {
  display: flex;
  flex: 0 1 10rem;
}
</style>
