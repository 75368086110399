import Api from './Api';

class InventoryLocationsApi extends Api {
  constructor() {
    super('inventory_locations', 'inventory_locations');
  }
}
const inventoryLocationsApi = new InventoryLocationsApi();

export default inventoryLocationsApi;
