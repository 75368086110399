<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="formDialog"
        max-width="900px"
      >
        <v-container :style="{backgroundColor: isDarkMode ? '#121212' : 'white'}">
          <v-form ref="form">
            <h1
              class="pt-6"
            >
              {{ titleProp }}
            </h1>
            <v-date-picker
              v-model="physicalCount.date"
              full-width
            />
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="physicalCount.name"
                  :rules="[(v) => !!v || 'Name is required', (v) => !!v && v.length <= 45 || 'Max 45 characters']"
                  counter="45"
                  required
                >
                  <template #label>
                    Name<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <inventory-class-select-element
                  v-model="physicalCount.class_ids"
                  :is-add-all="false"
                  :is-required="true"
                  :multiple="true"
                />
                <!-- <v-select
                  v-model="physicalCount.class_ids"
                  :items="invClasses"
                  item-text="name"
                  item-value="id"
                  multiple
                  :rules="[(v) => !!v || 'Class is required']"
                >
                  <template #label>
                    Class<RedAsterisk />
                  </template>
                </v-select> -->
              </v-col>
            </v-row>
          </v-form>
          <v-btn
            class="mr-4"
            color="primary"
            x-large
            @click="submit"
          >
            submit
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            outlined
            x-large
            @click="closeForm"
          >
            cancel
          </v-btn>
        </v-container>
      </v-dialog>
    </v-row>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import PhysicalCountListApi from '@/api/PhysicalCountListApi';
import { mapState } from 'vuex';

export default {
  components: {

  },
  props:
    {
      physicalCountProp: {
        type: Object,
        default: () => {},
      },
      titleProp: {
        type: String,
        default: '',
      },

    },
  data: () => ({
    formDialog: false,
    physicalCount: null,

  }),
  computed: {
    ...mapState({
      invClasses: 'invClasses',
      isDarkMode: 'isDarkMode',
    }),
  },
  watch: {
    physicalCountProp: {
      immediate: true,
      handler(value) {
        if (value) {
          this.physicalCount = value;
          this.physicalCount.class_ids = value.classes.map((x) => x.id);
        } else {
          this.physicalCount = {
            id: 0,
            name: '',
            date: new Date().toISOString().substr(0, 10),
            created_by: this.$store.getters.user.id,
            class_ids: [],
          };
        }
      },
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {

    },

    async submit() {
      if (!await this.$refs.form.validate()) return;
      const data = {
        id: this.physicalCount.id,
        name: this.physicalCount.name,
        date: this.physicalCount.date,
        created_by: this.physicalCount.created_by,
        class_ids: this.physicalCount.class_ids,
      };
      let res;
      if (this.physicalCount.id) {
        res = await PhysicalCountListApi.update(data);
      } else {
        res = await PhysicalCountListApi.store(data);
      }
      if (!res) {
        this.$refs.snackbar.showError();
        return;
      }

      if (this.physicalCount.id !== 0) { this.$emit('list-form-success', 'Physical Count Updated'); } else { this.$emit('list-form-success', 'Physical Count Created'); }
      this.closeForm();
    },

    openPhysicalCountForm() {
      this.formDialog = true;
    },
    closeForm() {
      this.reset();
      this.formDialog = false;
    },

    reset() {
      this.physicalCount = {
        id: 0,
        name: '',
        date: new Date().toISOString().substr(0, 10),
        created_by: this.$store.getters.user.id,
        class_ids: [],
      };
    },
  },

};

</script>

<style lang="scss" scoped>
::v-deep .v-date-picker-table .v-btn{
    font-size: 20px;
}

::v-deep .v-date-picker-table--date .v-btn{
    height: 40px;
    width: 40px;
}

::v-deep .v-date-picker-header__value button {
    font-size: 30px;
}
</style>
