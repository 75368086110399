<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :min-width="600"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Time Off Leave type</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || 'Name is required']"
          >
            <template slot="label">
              Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="color: #000000; font-size: 16px;">Select Color</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-color-picker
            v-model="color"
            hide-canvas
            mode="hexa"
            hide-inputs
            hide-mode-switch
            swatches-max-height="100"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: null,
    },
    color: null,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.timeOffLeaveType.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async submit() {
      let res = false;
      this.item.color = this.color.hex;
      if (this.item.id) {
        res = await this.$api.timeOffLeaveType.update(this.item);
      } else {
        res = await this.$api.timeOffLeaveType.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
