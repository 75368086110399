var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px","persistent":true},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"userValidationObserver"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}},[_c('template',{slot:"label"},[_vm._v(" First Name"),_c('RedAsterisk')],1)],2)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}},[_c('template',{slot:"label"},[_vm._v(" Last Name"),_c('RedAsterisk')],1)],2)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Roles","rules":"required|list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.roles,"item-text":"name","item-value":"id","error-messages":errors,"multiple":"","return-object":"","search-input":_vm.roleSearchInput},on:{"update:searchInput":function($event){_vm.roleSearchInput=$event},"update:search-input":function($event){_vm.roleSearchInput=$event},"change":function($event){_vm.roleSearchInput=''}},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}},[_c('template',{slot:"label"},[_vm._v(" Roles"),_c('RedAsterisk')],1)],2)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.isInventoryClasses)?_c('v-select',{attrs:{"clearable":"","multiple":"","label":"Default Classes","items":_vm.inventoryClasses,"item-text":"name","item-value":"id","menu-props":{ bottom: true, offsetY: true }},on:{"change":_vm.onChangeInventoryClass},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}],null,false,1615892956),model:{value:(_vm.user.inventory_class_ids),callback:function ($$v) {_vm.$set(_vm.user, "inventory_class_ids", $$v)},expression:"user.inventory_class_ids"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Supervisor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.supervisors,"item-text":"full_name","item-value":"id","error-messages":errors,"label":"Supervisors","clearable":"","multiple":"","return-object":""},model:{value:(_vm.user.supervisors),callback:function ($$v) {_vm.$set(_vm.user, "supervisors", $$v)},expression:"user.supervisors"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('VuePhoneNumberInput',{attrs:{"default-country-code":"CA"},on:{"update":_vm.onUpdate},model:{value:(_vm.user.phone_number),callback:function ($$v) {_vm.$set(_vm.user, "phone_number", $$v)},expression:"user.phone_number"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.locations,"item-text":"name","item-value":"id","error-messages":errors,"label":"Location"},model:{value:(_vm.user.location_id),callback:function ($$v) {_vm.$set(_vm.user, "location_id", $$v)},expression:"user.location_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","offset-overflow":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.user.start_date,"append-icon":"mdi-calendar","label":"Start Date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.user.start_date),callback:function ($$v) {_vm.$set(_vm.user, "start_date", $$v)},expression:"user.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Username","rules":_vm.user.id ? '': 'required|username'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}},[_c('template',{slot:"label"},[_vm._v(" Username"),_c('RedAsterisk')],1)],2)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}},[_c('template',{slot:"label"},[_vm._v(" Email ")])],2)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"password","rules":_vm.user.id ? '' : 'required|password:@confirm'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"autocomplete":"new-password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}},[_c('template',{slot:"label"},[_vm._v(" Password"),(!_vm.user.id)?_c('RedAsterisk'):_vm._e()],1)],2)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"confirm","rules":_vm.user.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}},[_c('template',{slot:"label"},[_vm._v(" Confirm Password"),(!_vm.user.id)?_c('RedAsterisk'):_vm._e()],1)],2)]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"normal"},on:{"click":_vm.closeForm}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.user.id !== 0 ? 'Update' : 'Create')+" ")])],1)],1)],1),_c('Snackbar',{ref:"snackbar"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }