var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.open}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Color Price ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.extrusion_price",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.extrusion_price)+"/ft ")])],1)],1)]}},{key:"item.sheet_price",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.sheet_price)+"/sqft ")])],1)],1)]}},{key:"item.min_setup_price",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.min_setup_price ? parseFloat(item.min_setup_price).toFixed(2) : '0.00')+" ")])],1)],1)]}},{key:"item.min_order_price",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.min_order_price ? parseFloat(item.min_order_price).toFixed(2) : '0.00')+" ")])],1)],1)]}},{key:"item.additional_expense",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.additional_expense)+" ")])],1)],1)]}},{key:"item.inventory_hollow_price",fn:function(ref){
var item = ref.item;
return [(item.inventory_hollow_price)?_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.inventory_hollow_price)+" ")])],1)],1):_vm._e()]}},{key:"item.inventory_solid_price",fn:function(ref){
var item = ref.item;
return [(item.inventory_solid_price)?_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.inventory_solid_price)+" ")])],1)],1):_vm._e()]}},{key:"item.weight_adjustment_price",fn:function(ref){
var item = ref.item;
return [(item.weight_adjustment_price)?_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.weight_adjustment_price)+" ")])],1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_vm._v(" EDIT ")])]}}],null,true)})],1)],1)],1),_c('inventory-po-color-price-form',{ref:"form",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }