import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
let cancelReadSecondary;
class JobsApi extends Api {
  constructor() {
    super('job', 'jobs');
  }

  async softDelete(jobId) {
    const errorText = 'Could not delete job';
    let res = null;
    try {
      res = await axios.get(`/jobs/delete/${jobId}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        }
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }

  async readDataSecondary(data) {
    if (cancelReadSecondary !== undefined) {
      cancelReadSecondary();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelReadSecondary = c;
      }),
    });
    return res;
  }
}
const jobsApi = new JobsApi();

export default jobsApi;
